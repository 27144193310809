import styled from 'styled-components';

import successSvg from '../../../../../../../../../dashboard/static/success.svg';

const Icon = styled.div`
  height: 61px;
  width: 61px;

  background: url(${successSvg});
`;

const IconWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin-bottom: 38px;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  text-align: center;

  margin-bottom: 36px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const StyledFieldsImportSuccessModal = {
  Title,
  Icon,
  IconWrapper,
  Wrapper,
};

export default StyledFieldsImportSuccessModal;
