import { AxiosError } from 'axios';

import { EFieldsImportModalName } from '../../../../modals/import/configs/fieldsImportModalsConfig';

const getImportedFieldsSaveError = (error: AxiosError): EFieldsImportModalName => {
  const errorStatus = error?.response?.status;

  switch (errorStatus) {
    case 400:
      return EFieldsImportModalName.FileImport;
    case 401:
      return EFieldsImportModalName.NotAuthorized;
    case 403:
      return EFieldsImportModalName.NotEnoughRights;
    case 422:
      return EFieldsImportModalName.SomethingWentWrongWithoutUpload;
    case 500:
      return EFieldsImportModalName.UnexpectedError;
    default:
      return null;
  }
};

export default getImportedFieldsSaveError;
