import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../../../../../../shared/constans/colors';

import clearSvg from './assets/images/clear-12x12.svg';
import minusSvg from './assets/images/minus-16x16.svg';

const { secondaryGray, secondaryDarkGray, borderGray, generalBlack, generalWhite } = Colors;

const Clear = styled.div`
  flex: 0 0 12px;

  width: 12px;
  height: 12px;

  cursor: pointer;

  background: url(${clearSvg});
`;

const Minus = styled.div`
  flex: 0 0 16px;

  width: 16px;
  height: 16px;

  background: url(${minusSvg});
`;

const Input = styled.input<{ $isDisabled?: boolean; $isBlocked?: boolean }>`
  width: 100%;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  outline: none;
  border: none;

  background: ${({ $isBlocked }) => ($isBlocked ? '#ffffff' : secondaryGray)};
  color: ${generalBlack};

  ::placeholder {
    color: ${secondaryDarkGray};
  }

  ${({ $isDisabled, $isBlocked }) =>
    $isDisabled || $isBlocked
      ? css`
          pointer-events: none;
        `
      : null}
`;

const InputWrapper = styled.div`
  flex: 1 1 50%;
`;

const getWrapWidth = ($isBlocked?: boolean, $hasLongValue?: boolean): string => {
  if ($isBlocked && $hasLongValue) {
    return '198.5px';
  }

  if ($isBlocked) {
    return '93px';
  }

  if ($hasLongValue) {
    return '185.5px';
  }

  return '86.5px';
};

const Wrapper = styled.div<{ $isBlocked?: boolean; $hasLongValue?: boolean }>`
  width: ${({ $isBlocked, $hasLongValue }) => getWrapWidth($isBlocked, $hasLongValue)};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 16px;

  border: 1px solid ${borderGray};
  border-radius: 12px;

  background-color: ${({ $isBlocked }) => ($isBlocked ? generalWhite : secondaryGray)};

  color: black;
`;

const StyledAverageAttributeItem = {
  Minus,
  Clear,
  Input,
  InputWrapper,
  Wrapper,
};

export default StyledAverageAttributeItem;
