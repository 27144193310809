import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { IGetChecklistStage as IStage } from '../../../../../../../../api/models/checklist/stage/checklist.stage.model';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../mobx/controllers';
import { ChecklistsStageContainer as StageContainer } from '../elements';
import { ChecklistsStore } from '../../mobx/stores';
import { useSortBy } from '../../../../../../../shared/features/utils/hooks/sort';

import Styled from './ChecklistsChecklistContainer.styles';

const ChecklistsChecklistContainer: FC = () => {
  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);

  const [stageList, setStageList] = useState<IStage[]>([]);

  useEffect(() => {
    (async () => {
      const fetchedStageList = await checklistsController.fetchStageList();
      setStageList(fetchedStageList);
    })();
  }, []);

  useEffect(() => {
    return () => {
      checklistsController.clearStore();
      setStageList([]);
    };
  }, []);

  useEffect(() => {
    if (!checklistsStore.attrIdWithError) return;

    const attrWithErrorNode = document.querySelector(`[id='${checklistsStore.attrIdWithError}']`);

    if (!attrWithErrorNode) return;

    attrWithErrorNode.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });

    checklistsStore.clearIsAttrIdWithErrorTargeted();
  }, [checklistsStore.isAttrIdWithErrorTargeted]);

  const orderedStageList = useSortBy(stageList);

  return (
    <Styled.Wrapper>
      {orderedStageList.map(stage => (
        <StageContainer key={stage.id} stage={stage} />
      ))}
    </Styled.Wrapper>
  );
};

ChecklistsChecklistContainer.displayName = 'ChecklistsChecklistContainer';

export default observer(ChecklistsChecklistContainer);
