import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';

export const Fullscreen = styled.div`
  padding: 20px;
  /* height: 200%; */
  max-width: 912px;
  box-sizing: content-box;
  margin: 0 auto;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const BackButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  margin-top: 7px;
  width: 100%;
  max-width: 934px;
  margin: 7px auto 0px;
  transform: translateX(-10px);
`;

export const ActionButton = styled.div`
  width: 160px;
  height: 40px;
  border: 1px solid #3fb65f;
  border-radius: 12px;
  background: #ffffff;
  text-align: center;
  size: 14px;
  line-height: 40px;
  color: #3fb65f;
`;

export const CancelButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: ${Colors.lightPink};
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: 0.3s;

  & svg > path {
    color: ${Colors.pink};
    fill: ${Colors.pink};
  }

  &:hover {
    & svg > path {
      color: ${Colors.pinkHover};
      fill: ${Colors.pinkHover};
    }
  }
`;
