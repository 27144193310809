import { NoOperationsPlaceholderWrapper, NoOperationsText, NoOperationsTitle } from "./style";

export const NoOperationsPlaceholder = () => {
    return (
        <NoOperationsPlaceholderWrapper>
            <NoOperationsTitle>
                Здесь будут отображаться задачи для сотрудников
            </NoOperationsTitle>
            <NoOperationsText>
                Добавьте операции, для создания задач
            </NoOperationsText>
        </NoOperationsPlaceholderWrapper>
    );
};