import styled from 'styled-components';

import { Colors } from '../../../../../../../../../../shared/constans/colors';

const { generalBlack } = Colors;

const Title = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  margin-bottom: 12px;

  color: ${generalBlack};
`;

const TitleWrapper = styled.div`
  width: 100%;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  gap: 12px;

  margin-top: 12px;
`;

const Wrapper = styled.div`
  margin-top: 24px;
`;

const StyledAverageAttributeCulledItemsBody = {
  Title,
  TitleWrapper,
  ItemsWrapper,
  Wrapper,
};

export default StyledAverageAttributeCulledItemsBody;
