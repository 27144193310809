import { TypeApiRoute } from '../../../models/type.api.request';

type TSaveImportedFieldListReq = {
  token: string;
};

type TSaveImportedFieldListRes = {};

const saveImportedFieldList: TypeApiRoute & {
  request: TSaveImportedFieldListReq;
  response: TSaveImportedFieldListRes;
} = {
  url: () => '/api/as-fields/import/transfer',
  method: 'POST',
  headers: {},
  request: {} as TSaveImportedFieldListReq,
  response: {} as TSaveImportedFieldListRes,
};

export type { TSaveImportedFieldListReq, TSaveImportedFieldListRes };

export { saveImportedFieldList };
