import styled, { css } from 'styled-components';

import { Colors } from '../../../../../constans/colors';

type TStyledDropdownSearchFieldInputProps = {
  isDisabled: boolean;
  $isBlocked: boolean;
};

const Input = styled.input<TStyledDropdownSearchFieldInputProps>`
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: ${({ isDisabled }) => (isDisabled ? '#7E8B9D' : Colors.generalBlack)};
  outline: none;
  border: none;
  background: transparent;
  caret-color: ${Colors.green};
  width: 100%;
  padding: 0;
  text-overflow: ellipsis;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: pointer;
      caret-color: transparent;
    `}

  ::placeholder {
    color: ${Colors.secondaryDarkGray};
  }
  z-index: 99;
`;

const ShadowInput = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  padding: 0;
  white-space: nowrap;
  position: absolute;
  visibility: hidden;
  left: -999999px;
`;

const InputWrapper = styled.div`
  pointer-events: all;
`;

const StyledDropdownSearchField = {
  Input,
  ShadowInput,
  InputWrapper,
};

export default StyledDropdownSearchField;
