import { IGetChecklistAttribute } from '../../../models/checklist/attribute/checklist.attribute.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TRequest = {
  publicId: string;
  attributeName?: string;
  stageId?: string;
  activeOnly?: boolean;
  size?: number;
  page?: number;
  taskId?: string;
  phenophaseId?: string;
};

type TResponse = { content: IGetChecklistAttribute[] };

export const getChecklistAttributeByCheckListPublicId: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ publicId }) => `/api/as-fields/checklist/attribute/byCheckListPublicId/${publicId}`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
