import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';

import { Axios, TypeApiRequest } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import {
  EBiImportDelimiterType,
  EBiImportFormat,
} from '../../operationsAndTasks/utils/constants/BiImportEnums';
import { TasksFilterStore } from '../stores/tasks.filter.store';
import { downloadBlobAsFile } from '../../../../shared/utils/downloadBlobAsFile';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { TableFiltersBuilderStore } from '../../../../shared/features/TableFiltersBuilder/mobx/stores';
import { IReportDataFilter } from '../../../../../api/models/as-fields/reports/biConfig.model';
import { SeasonsStore } from '../../../stores/seasons.store';
import { TTableFiltersBuilderFilterType as FilterType } from '../../../../shared/features/TableFiltersBuilder/types/filters';
import { ITableFiltersBuilderValue } from '../../../../shared/features/TableFiltersBuilder/models/data';

import { TaskStatuses } from './../../operations/stores/tasks.store';

export enum EReportUploadingStatus {
  Idle = 'IDLE',
  Pending = 'PENDING',
  Fulfilled = 'FULFILLED',
  Rejected = 'REJECTED',
}

@provide.singleton()
export class TasksReportUploader {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(TasksFilterStore)
  tasksFilterStore: TasksFilterStore;

  @lazyInject(OrganizationsStore)
  organizationsStore: OrganizationsStore;

  @lazyInject(SeasonsStore)
  seasonsStore: SeasonsStore;

  @lazyInject(TableFiltersBuilderStore)
  tableFiltersBuilderStore: TableFiltersBuilderStore;

  constructor() {
    makeAutoObservable(this);
  }

  private _status: EReportUploadingStatus = EReportUploadingStatus.Idle;

  get status() {
    return this._status;
  }

  setStatus = (status: EReportUploadingStatus) => {
    this._status = status;
  };

  getPowerBiReportCsvFile = async () => {
    const {
      startDate,
      endDate,
      season,
      asignee,
      field,
      status,
      culture,
      organizationId,
    } = this.tasksFilterStore;

    const { currentOrganization } = this.organizationsStore;

    const defaultData: TypeApiRequest<'getPowerBiReportCsvFile'> & {
      noCulture?: boolean;
    } = {
      format: EBiImportFormat.Excel,
      charsetName: 'UTF-8',
      delimiterType: EBiImportDelimiterType.Comma,
      organizationId,
      withId: false,
      dateFrom: moment(startDate).format('YYYY-MM-DD'),
      dateTo: moment(endDate).format('YYYY-MM-DD'),
      seasonYear: Number(season),
      fieldId: field.length > 0 ? field : null,
      // TODO после добавления поддержки массивов на беке переделать на передачу массивов
      statusIn: (status as unknown) as TaskStatuses[],
      // assigneeId: asignee.length > 0 ? asignee : null,
      // cultureId: !culture?.length ? null : culture,
    };

    if (defaultData.cultureId === 'emptyCulture') {
      defaultData.noCulture = true;
      delete defaultData.cultureId;
    }

    try {
      this.setStatus(EReportUploadingStatus.Pending);

      const response = await this.axios.api.getPowerBiReportCsvFile(
        _.omitBy(defaultData, _.isNil) as TypeApiRequest<'getPowerBiReportCsvFile'>
      );

      downloadBlobAsFile(
        response as Blob,
        `Отчёт ${currentOrganization.name || 'организации'} ${moment(startDate).format(
          'DD.MM.YYYY'
        )}-${moment(endDate).format('DD.MM.YYYY')}.xls`
      );
    } catch (error) {
      this.setStatus(EReportUploadingStatus.Rejected);
      console.error(error);

      return Promise.reject(error);
    } finally {
      this.setStatus(EReportUploadingStatus.Idle);
    }
  };

  getArrayFilterPowerBiReportCsvFile = async (builderId: string) => {
    const filterValue = this.tableFiltersBuilderStore.getAppliedValueList(builderId);

    const { currentOrganization } = this.organizationsStore;
    const { selectedSeason } = this.seasonsStore;

    const parsedValues = this.parseArrayFilterData(
      filterValue,
      currentOrganization?.organizationId,
      Number(selectedSeason)
    );

    const payload: TypeApiRequest<'getPowerBiReportCsvFileZip'> = {
      reportDataFilter: parsedValues,
      reportFileFormatFilter: {
        format: EBiImportFormat.Excel,
      },
    };

    try {
      this.setStatus(EReportUploadingStatus.Pending);

      const response = await this.axios.api.getPowerBiReportCsvFileZip(payload);

      downloadBlobAsFile(
        response as Blob,
        `Отчёт ${currentOrganization.name || 'организации'} ${moment(parsedValues.dateFrom).format(
          'DD.MM.YYYY'
        )}-${moment(parsedValues.dateTo).format('DD.MM.YYYY')}.zip`
      );
    } catch (error) {
      this.setStatus(EReportUploadingStatus.Rejected);
      console.error(error);

      return Promise.reject(error);
    } finally {
      this.setStatus(EReportUploadingStatus.Idle);
    }
  };

  private parseArrayFilterData(
    data: ITableFiltersBuilderValue<any>[],
    organizationId: string,
    seasonYear: number
  ): IReportDataFilter {
    const parsedValues: Partial<IReportDataFilter> = {
      organizationId,
      seasonYear,
    };

    data.forEach(value => {
      this.parseIterateValue(value, parsedValues);
    });

    return parsedValues as IReportDataFilter;
  }

  private parseIterateValue(
    value: ITableFiltersBuilderValue,
    parsedValues: Partial<IReportDataFilter>
  ) {
    const nullificationOptionList = ['noAssignee', 'noCulture'];

    const parseMapper: Record<FilterType, any> = {
      select: () =>
        value.selectOptionList.flatMap(item => {
          if (nullificationOptionList.includes(item.value)) {
            return null;
          } else {
            return item.value;
          }
        }),
      boolean: () => value.booleanValue,
      'date-range': () => value.dateValue,
    };

    const normalizeKey = key => {
      const normalMap: Record<any, keyof Partial<IReportDataFilter>> = {
        planDateFrom: 'dateFrom',
        planDateTo: 'dateTo',
        status: 'statusIn',
        assigneeId: 'assigneeIds',
        fieldId: 'fieldIds',
        cultureId: 'cultureIds',
      };

      return Object.hasOwn(normalMap, key) ? normalMap[key] : key;
    };

    parsedValues[normalizeKey(value.filterId)] = parseMapper[value.type]?.() ?? null;
  }
}
