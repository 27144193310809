import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import {
  EChecklistAttributeType as EAttrType,
  IGetChecklistAttribute as IAttribute,
  IGetChecklistAttributeValue as IAttributeValue,
} from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { TChecklistsDictionaryAttrToDraw as TDictionaryAttrToDraw } from '../../../../models';
import { ChecklistsStore } from '../../../stores';
import { ChecklistsAttrsFormulasService } from '../../ChecklistsAttrsFormulasService';
import { ISelectOption } from '../../../../../../../../../../types/selectOption';
import { EChecklistMode } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { getChecklistsAttrRequiredText as getAttrRequiredText } from '../../../../helpers';
import { TChecklistsDictionarySelectOption as TDictionarySelectOption } from '../../../../types';

@provide.transient()
class ChecklistsDictionaryAttrsService {
  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(ChecklistsAttrsFormulasService)
  protected formulasService: ChecklistsAttrsFormulasService;

  createAttrToDraw = (
    groupId: string,
    attr: IAttribute,
    initialValueList: IAttributeValue[],
    options?: {
      isBlocked?: boolean;
      nestedInstanceId?: string;
      dependentFileAttrId?: string;
    }
  ): TDictionaryAttrToDraw => {
    // Формируем "рабочую" модель атрибута.
    const attrToDraw: TDictionaryAttrToDraw = {
      id: attr.id,
      groupId,
      isVisible: true,
      order: attr.order,
      initialModel: attr,
      value: {
        checkListAttributeId: attr.id,
        dictionaryValueList: [],
      },
      options: {
        selectOptionList: [],
        selectedSelectOptionList: [],
      },
      isBlocked: options?.isBlocked || this.checklistsStore.mode === EChecklistMode.View,
      validationScheme: {
        isShowError: false,
        errorTitle: getAttrRequiredText(EAttrType.DictionaryLink),
      },
      isEdited: false,
      nestedInstanceId: options?.nestedInstanceId,
      dependentFileAttrId: options?.dependentFileAttrId,
    };

    const initialValueOfThisAttr = initialValueList.find(
      ({ checkListAttributeId }) => checkListAttributeId === attrToDraw.id
    );

    if (!initialValueOfThisAttr) {
      return attrToDraw;
    }

    attrToDraw.value = {
      ...attrToDraw.value,
      dictionaryValueList: initialValueOfThisAttr.dictionaryValueList.map(({ id }) => id),
    };

    // Приводим значения в формат, с которым работает дропдаун.
    const selectOptionList = initialValueOfThisAttr.dictionaryValueList.map<TDictionarySelectOption>(
      el => ({
        label: el.name,
        value: el.id,
        initialModel: el,
      })
    );

    // Заполняем выбранные опции.
    attrToDraw.options = { ...attrToDraw.options, selectedSelectOptionList: selectOptionList };

    return attrToDraw;
  };

  changeValue = (groupId: string, value: TDictionaryAttrToDraw['value']): void => {
    this.checklistsStore.updateAttrToDraw(groupId, value.checkListAttributeId, {
      value,
      isEdited: true,
    });

    this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.DictionaryLink>(
      groupId,
      value.checkListAttributeId,
      {
        isShowError: false,
      }
    );
  };

  setOptionList = (groupId: string, id: string, selectOptionList: ISelectOption[]): void => {
    this.checklistsStore.updateAttrToDrawOptions<EAttrType.DictionaryLink>(groupId, id, {
      selectOptionList,
    });
  };

  setSelectedOptionList = (
    groupId: string,
    id: string,
    selectOptionList: ISelectOption[]
  ): void => {
    this.checklistsStore.updateAttrToDrawOptions<EAttrType.DictionaryLink>(groupId, id, {
      selectedSelectOptionList: selectOptionList,
    });
  };
}

export default ChecklistsDictionaryAttrsService;
