import styled from 'styled-components';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledChecklistsChecklistContainer = {
  Wrapper,
};

export default StyledChecklistsChecklistContainer;
