import { useCallback } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { DashboardRoutes } from '../../../../dashboard.routes';

const useOperationsRouteActions = () => {
  const navigate = useNavigate();
  const params = useParams<{ orgId: string }>();

  const goToOperations = useCallback(() => {
    navigate(
      generatePath(DashboardRoutes.Operations, {
        orgId: params.orgId,
      })
    );
  }, [params]);

  return {
    goToOperations,
  };
};

export default useOperationsRouteActions;
