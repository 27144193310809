import { FC, memo, MutableRefObject, useLayoutEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';

import { useDataTestId } from '../../../utils/hooks/locators';

import Styled from './ContentLoader.styles';

const DEFAULT_DATA_TEST_ID = 'content-loader';

type TSizeValue = `${number}%` | `${number}px` | 0;

interface IProps {
  parentRef?: MutableRefObject<HTMLElement>;
  overlayStyles?: CSSProperties;
  dataTestId?: string;
}

const ContentLoader: FC<IProps> = ({ parentRef, overlayStyles, dataTestId }) => {
  const [overlaySizes, setOverlaySizes] = useState<{
    height: TSizeValue;
    width: TSizeValue;
    left: TSizeValue;
  }>({ height: 0, width: 0, left: 0 });

  useLayoutEffect(() => {
    if (parentRef?.current) {
      const bounds = parentRef?.current?.getBoundingClientRect();

      if (bounds) {
        setOverlaySizes({
          width: `${bounds.width}px`,
          height: `${bounds.height}px`,
          left: `${bounds.left}px`,
        });
      }
    }
  }, []);

  const getDataTestId = useDataTestId(dataTestId || DEFAULT_DATA_TEST_ID);

  return (
    <Styled.Overlay
      $styles={{ ...overlaySizes, ...(overlayStyles ?? {}) }}
      {...getDataTestId('overlay')}
    >
      <Styled.Wrapper {...getDataTestId('content-wrapper')}>
        <Styled.Spinner {...getDataTestId('spinner')} />
        <Styled.Title {...getDataTestId('title')}>Загружаем ...</Styled.Title>
      </Styled.Wrapper>
    </Styled.Overlay>
  );
};

export default memo(ContentLoader);
