import { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';

import { useOnClickOutside } from '../../../../../hooks/useOnClickOutside';
import { useCommonState } from '../../../hooks';
import { InputFieldError } from '../../InputFieldError';
import { Tooltipster } from '../../Tooltipster/Tooltipster';

import { DropdownBody } from './components/DropdownBody';
import { DropdownIcons } from './components/DropdownIcons';
import { DropdownMultiselect } from './components/DropdownMultiselect';
import { DropdownSearchField } from './components/DropdownSearchField';
import Styled from './Dropdown.styles';
import { TDropdownProps, TDropdownState } from './Dropdown.types';
import { useDropdownBodyHandlers } from './hooks/useDropdownBodyHandlers';
import { useDropdownCreateTextByMatch } from './hooks/useDropdownCreateTextByMatch';
import { useDropdownGetAvailableOptionList } from './hooks/useDropdownGetAvailableOptionList';
import { useDropdownHandlers } from './hooks/useDropdownHandlers';
import { useDropdownIconHandlers } from './hooks/useDropdownIconHandler';
import { useDropdownSearchFieldHandlers } from './hooks/useDropdownSearchFieldHandlers';
import { useHandleModalResult } from './hooks/useHandleModalResult';
import { useShowError } from './hooks/useShowError';
import { SELECT_ALL_OPTION } from './constants';

const Dropdown = forwardRef<HTMLInputElement, TDropdownProps>((props, inputRef) => {
  const {
    config: { field, body, visual, validation, other },
  } = props;

  const [state, setState] = useCommonState<TDropdownState>({
    values: {
      selectValue: null,
      selectValueList: [],
      searchValue: null,
    },
    settings: {
      isDropped: false,
      isSearchFieldFocused: false,
      isValueContainerCentered: true,
      autocompleteWidth: null,
      isExactCompareOfSearchValue: false,
      isFirstOpen: false,
    },
    temporaryData: {
      onChangeData: null,
      preventSortingValues: [],
    },
  });

  const [isSelectedSelectAllOption, setIsSelectedSelectAllOption] = useState<boolean>(false);

  // Определяет доступный список опций
  const availableOptionList = useDropdownGetAvailableOptionList({
    body,
    type: field?.type,
    state,
    isKeepSelectedOptions: body?.isKeepSelectedOptions,
  });

  // Создает значение для автокомплита
  const autocompleteText = useDropdownCreateTextByMatch({
    option: availableOptionList?.[0],
    isSearch: Boolean(field?.type?.search),
    searchValue: state.values.searchValue,
    isCreateFullMatch: true,
  });

  // Отслеживает появление и скрытие тела выбора
  const fieldWrapperRef = useRef<HTMLDivElement>(null);

  const searchFieldWrapperRef = useRef<HTMLDivElement>(null);
  const valueContainerRef = useRef<HTMLDivElement>(null);

  // Обработчики событий для компонента 'Dropdown' (по общей части)
  const dropdownHandlers = useDropdownHandlers({ state, setState, props });

  // Обрабатывает появление и скрытие тела выбора
  useOnClickOutside(fieldWrapperRef, dropdownHandlers.onClickOutside);

  // Обработчики событий для поисковой строки
  const searchFieldHandlers = useDropdownSearchFieldHandlers({
    state,
    setState,
    props,
    availableOptionList,
  });

  // Обработчики событий для тела выбора
  const bodyHandlers = useDropdownBodyHandlers({ state, setState, props });

  // Обработчики событий для иконок
  const iconsHandlers = useDropdownIconHandlers({ state, setState, props });

  // Отвечает за появление и скрытие ошибок
  const isError = useShowError(validation?.error?.errorList);

  // Следит за результатом модального окна (если оно есть)
  useHandleModalResult({ state, setState, props });

  // Следит за шириной автокомпилта
  useEffect(() => {
    if (!field?.type?.search) {
      return;
    }

    setState({
      settings: {
        ...state.settings,
        autocompleteWidth: searchFieldWrapperRef?.current?.offsetWidth,
      },
    });
  }, [searchFieldWrapperRef, field?.type?.search]);

  useEffect(() => {
    const height = valueContainerRef?.current?.offsetHeight;

    if (height) {
      if (height <= 20) {
        setState({ settings: { ...state.settings, isValueContainerCentered: true } });
      } else {
        setState({ settings: { ...state.settings, isValueContainerCentered: false } });
      }
    }
  }, [state.values.selectValueList]);

  // Следит за значением по умолчанию
  useEffect(() => {
    if (field?.defaultValue) {
      setState({
        values: {
          ...state.values,
          selectValue: field?.defaultValue,
        },
      });
    } else if (_.isArray(field?.defaultValueList) && !field?.type?.multiselect) {
      setState({
        values: {
          ...state.values,
          selectValue: field?.defaultValueList?.[0] || null,
        },
      });
    } else if (_.isArray(field?.defaultValueList)) {
      setState({
        values: {
          ...state.values,
          selectValueList: field?.defaultValueList,
        },
      });
    }
  }, [field?.defaultValue, field?.defaultValueList]);

  const isShownSearchField = useMemo<boolean>(() => {
    const isMultiselect = field?.type?.multiselect;
    const isSearch = field?.type?.search;
    const { isDropped } = state.settings;
    const isNotEmpty = state.values.selectValueList.length;

    if (isSearch) {
      if (!isDropped && isMultiselect && isNotEmpty) {
        return false;
      } else {
        return true;
      }
    } else if (!isSearch) {
      if (isMultiselect && isNotEmpty) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [
    state.settings.isDropped,
    field?.type?.multiselect,
    field?.type?.search,
    state.values.selectValueList,
  ]);

  useEffect(() => {
    if (field?.type?.multiselect?.selectAll) {
      const clearedOptionList = availableOptionList.filter(
        option => option.value !== SELECT_ALL_OPTION.value
      );

      const clearedSelectedOptionList = state.values.selectValueList.filter(
        option => option.value !== SELECT_ALL_OPTION.value
      );

      setIsSelectedSelectAllOption(clearedSelectedOptionList.length === clearedOptionList.length);
    }
  }, [availableOptionList, state.values.selectValueList, field?.type?.multiselect?.selectAll]);

  const isSearchReplaceAggregationHeader = useMemo(() => {
    const isCounter = field?.type?.multiselect?.counter;

    return isShownSearchField && isCounter;
  }, [isShownSearchField, field?.type?.multiselect?.counter]);

  const isIncludeSelectAllOption = useMemo(() => {
    return (
      field?.type?.multiselect?.selectAll &&
      state.values.selectValueList.includes(SELECT_ALL_OPTION)
    );
  }, [field?.type?.multiselect?.selectAll, state.values.selectValueList]);

  const isMultiSelect = Boolean(field?.type?.multiselect && state.values.selectValueList.length);

  return (
    <Styled.Wrapper
      $isDropped={state.settings.isDropped}
      $isError={isError}
      $isBlocked={visual?.isBlocked}
      $isDisabled={field?.isDisabled}
      $styles={props?.config?.styles}
      data-test-id={`dropdown-wrapper-${other?.dataTestId || field.id}`}
      data-is-open={state.settings.isDropped}
      data-disabled={field?.isDisabled || visual?.isBlocked}
    >
      {visual?.label ? (
        <Styled.LabelWrapper
          data-test-id={`dropdown-label-wrapper-${other?.dataTestId || field.id}`}
        >
          <Styled.Label
            $isRequired={field?.isRequired}
            data-test-id={`dropdown-label-${other?.dataTestId || field.id}`}
          >
            {visual?.label}
          </Styled.Label>

          {visual?.tooltip && (
            <Tooltipster
              id={`tooltip-${field?.id}`}
              title={visual?.tooltip}
              placement={'top-start'}
              data-test-id={`dropdown-label-tooltip-${other?.dataTestId || field.id}`}
            >
              <Styled.LabelInfoIcon />
            </Tooltipster>
          )}
        </Styled.LabelWrapper>
      ) : null}

      <Styled.FieldWrapper
        onClick={dropdownHandlers.onClick}
        ref={fieldWrapperRef}
        // $isRemovePaddingBottom={!isShownSearchField}
        $isCentered={state.settings.isValueContainerCentered}
        $styles={props.config?.styles}
        data-test-id={`dropdown-field-wrapper-${other?.dataTestId || field.id}`}
      >
        <Styled.ValueContainer
          ref={valueContainerRef}
          data-test-id={`dropdown-search-field-wrapper-value-container-${
            other?.dataTestId || field.id
          }`}
          data-has-value={
            isMultiSelect ||
            Boolean(state?.values?.selectValue?.value) ||
            Boolean(state?.values?.selectValueList.length)
          }
        >
          {isMultiSelect && !isSearchReplaceAggregationHeader ? (
            <DropdownMultiselect
              selectValueList={state.values.selectValueList}
              onCloseClick={iconsHandlers.onCloseClick}
              multiselectType={field?.type?.multiselect?.counter ? 'counter' : 'chips'}
              aggregationHeader={
                field?.type?.multiselect?.counter?.label || visual?.label || 'Выбрано:'
              }
              isIncludeSelectAllOption={isIncludeSelectAllOption}
              isBlocked={visual?.isBlocked}
            />
          ) : null}

          {!isShownSearchField ? null : (
            <Styled.SearchFieldWrapper
              ref={searchFieldWrapperRef}
              data-test-id={`dropdown-search-field-wrapper-${other?.dataTestId || field.id}`}
            >
              {state.values.searchValue && autocompleteText?.firstLabelPart ? (
                <Styled.Autocomplete
                  $width={state.settings.autocompleteWidth}
                  data-test-id={`dropdown-search-autocomplete-${other?.dataTestId || field.id}`}
                >
                  <Styled.AutocompleteMatchedValue
                    data-test-id={`dropdown-search-autocomplete-first-${
                      other?.dataTestId || field.id
                    }`}
                  >
                    {autocompleteText.firstLabelPart}
                  </Styled.AutocompleteMatchedValue>
                  {autocompleteText.lastLabelPart}
                </Styled.Autocomplete>
              ) : null}

              <DropdownSearchField
                handlers={{ ...searchFieldHandlers, onClick: dropdownHandlers.onClick }}
                values={state.values}
                configFieldProps={field}
                configVisualProps={visual ?? {}}
                ref={inputRef}
                dataTestId={`dropdown-search-field-input-${other?.dataTestId || field.id}`}
              />
            </Styled.SearchFieldWrapper>
          )}
        </Styled.ValueContainer>

        <DropdownIcons
          field={field}
          visual={visual}
          state={state}
          handlers={iconsHandlers}
          isMultiSelect={isMultiSelect}
          dataTestId={`dropdown-icons-${other?.dataTestId || field.id}`}
        />

        {state.settings.isDropped ? (
          <DropdownBody
            type={field?.type}
            handlers={{ ...bodyHandlers, onCloseClick: iconsHandlers.onCloseClick }}
            optionList={availableOptionList}
            values={state.values}
            dataTestId={other?.dataTestId || field.id}
            isSelectedSelectAllOption={isSelectedSelectAllOption}
          />
        ) : null}
      </Styled.FieldWrapper>

      {isError && !state.settings.isDropped ? <InputFieldError error={validation?.error} /> : null}
    </Styled.Wrapper>
  );
});

Dropdown.displayName = 'Dropdown';

export default memo(Dropdown);
