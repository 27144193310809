import { TypeApiRoute } from '../../models/type.api.request';
import { CreateFieldModel } from '../../models/create.field.model';

type TypeRequest = Array<CreateFieldModel>;

type TypeInputData = {
  fields: Array<CreateFieldModel>;
  organizationId: string;
  seasonYear: number;
};

type TypeResponse = Array<string>;

export const createFields: TypeApiRoute & {
  request: TypeInputData | ((request: any) => TypeRequest);
  response: TypeResponse;
} = {
  url: req =>
    `/api/as-fields/fields/create?seasonYear=${req.seasonYear}${
      req.organizationId !== 'my' ? `&organizationId=${req.organizationId}` : ''
    }`,
  method: 'POST',
  headers: {},
  request: req => req.fields,
  response: {} as TypeResponse,
};
