import { FC, ReactNode } from 'react';

import Styled from './Widget.styles';

export interface IWidgetProps {
  title?: ReactNode;
  sideContent?: ReactNode;
  style?: any;
  className?: string;
  displayTitle?: boolean;
  dataTestId?: string;
  $displayCentered?: boolean;
  $smallTitle?: boolean;
}

const Widget: FC<IWidgetProps> = ({
  title,
  children,
  displayTitle,
  dataTestId,
  sideContent,
  $smallTitle,
  ...otherProps
}) => (
  <Styled.Wrapper {...otherProps}>
    {title && displayTitle && (
      <Styled.Header>
        <Styled.Title $smallTitle={$smallTitle} data-test-id={dataTestId}>
          {title}
        </Styled.Title>
        {sideContent}
      </Styled.Header>
    )}
    {children}
  </Styled.Wrapper>
);

export default Widget;
