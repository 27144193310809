export enum DashboardRoutes {
  Fields = '/:orgId/fields',
  Information = '/info',
  Personal = '/personal',
  Profile = '/:orgId/profile',
  Operations = '/:orgId/operations',
  Tasks = '/:orgId/tasks',
}

export enum EDashboardExternalRoutes {
  Experiments = 'experiments',
  Audits = 'audits',
  Contracts = 'contracts',
}
