import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { LeaveBeforeSaveContent } from './components';

export const LEAVE_BEFORE_SAVE_MODAL_ID = 'leaveBeforeSaveModal';

export const laveBeforeSaveModalConfig: TModalConfig = {
  id: LEAVE_BEFORE_SAVE_MODAL_ID,
  name: LEAVE_BEFORE_SAVE_MODAL_ID,
  children: LeaveBeforeSaveContent,
  type: EModalType.Custom,
  dataTestId: LEAVE_BEFORE_SAVE_MODAL_ID,
  title: null,
  styledProps: {
    $size: EModalSize.Medium,
  },
  successButton: {
    title: 'Покинуть экран',
    color: 'secondary',
  },
  denyButton: {
    title: 'Остаться',
  },
  styles: {
    title: {
      $size: 'large',
      $withoutDescription: true,
    },
  },
};
