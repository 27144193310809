import { useCallback, useMemo } from 'react';

import { useStore } from '../../../../../shared/utils/IoC';
import { TaskStore } from '../../mobx/stores';
import { useOperationsRouteActions } from '../../../operations/hooks/useOperationsRouteActions';
import { useTasksRouteActions } from '../index';

const useTasksBackButtonData = () => {
  const taskStore = useStore(TaskStore);

  const operationsRouteActions = useOperationsRouteActions();
  const tasksRouteActions = useTasksRouteActions();

  const arrowText = useMemo(() => {
    switch (taskStore.fromPage) {
      case 'operations':
        return 'К списку операций';
      case 'tasks':
        return 'К списку задач';
      default:
        return 'К списку задач';
    }
  }, [taskStore.fromPage]);

  const handler = useCallback(() => {
    switch (taskStore.fromPage) {
      case 'operations':
        return operationsRouteActions.goToOperations();
      case 'tasks':
        return tasksRouteActions.goToTasks();
      default:
        return tasksRouteActions.goToTasks();
    }
  }, [operationsRouteActions.goToOperations, tasksRouteActions.goToTasks]);

  return {
    arrowText,
    handler,
  };
};

export default useTasksBackButtonData;
