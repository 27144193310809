import { TypeApiRoute } from '../../../models/type.api.request';
import { ITask, ITaskCreate } from '../../../models/as-fields/task/task.model';

type TCreateTaskReq = Omit<ITaskCreate, 'cultureId' | 'bindingDate'>;

type TCreateTaskRes = ITask;

export const createTask: TypeApiRoute & {
  request: TCreateTaskReq;
  response: TCreateTaskRes;
} = {
  url: () => `/api/as-fields/task`,
  method: 'POST',
  headers: {},
  request: {} as TCreateTaskReq,
  response: {} as TCreateTaskRes,
};
