import { IGetChecklist } from '../../models/checklist/checklist.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  instanceId: string;
};

type TypeResponse = {} & IGetChecklist;

export const getChecklistByInstanceId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: ({ instanceId }) => `/api/as-fields/checklist/byInstance/${instanceId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
