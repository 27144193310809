import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../shared/constans/colors';
import { ETaskStatus } from '../../../../../../../../api/models/as-fields/task/task.model';

const { lightPink, pink, lightBlue, sky, lightGreen, green, gray, black } = Colors;

const getWrapperColors = (taskStatus: ETaskStatus, daysLeft: number): Colors[] => {
  const pinkColors = [lightPink, pink];

  switch (taskStatus) {
    case ETaskStatus.New: {
      return daysLeft < 0 ? pinkColors : [lightBlue, sky];
    }
    case ETaskStatus.InWork: {
      return daysLeft < 0 ? pinkColors : [lightGreen, green];
    }
    case ETaskStatus.Completed:
      return [gray, black];
    case ETaskStatus.Canceled:
      return pinkColors;
    default:
      return pinkColors;
  }
};

interface IWrapperProps {
  $taskStatus: ETaskStatus;
  $daysLeft: number;
}

const Wrapper = styled.div<IWrapperProps>`
  position: relative;
  min-height: 52px;
  display: flex;
  align-items: center;
  padding: 16px 5px 16px 24px;
  width: 100%;
  border-radius: 12px;
  ${({ $taskStatus, $daysLeft }) => {
    const [backgroundColor] = getWrapperColors($taskStatus, $daysLeft);

    return `
      background-color: ${backgroundColor};
    `;
  }}

  ${({ $taskStatus }) =>
    $taskStatus === ETaskStatus.Canceled &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}

  &:before {
    content: '';
    height: calc(100% - 10px);
    width: 4px;
    position: absolute;
    left: 0;
    top: 5px;
    border-radius: 10px 0px 0px 10px;
    ${({ $taskStatus, $daysLeft }) => {
      const [_, borderColor] = getWrapperColors($taskStatus, $daysLeft);

      return `
      background-color: ${borderColor};
    `;
    }}
  }
`;

const StyledTaskStatusBlock = {
  Wrapper,
};

export default StyledTaskStatusBlock;
