import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  organizationId: string;
  codePrefix?: string;
};

export interface AccessRule {
  id: string;
  code: string;
  name: string;
  available: boolean;
}

type TypeResponse = Array<AccessRule>;

export const checkAccessByCodePrefix: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/access/action/byCodePrefix`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
