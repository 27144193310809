import { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import { ITaskTab } from '../../../../models';

import Styled from './TaskTabsHeader.styles';

interface ITaskTabHeaderProps {
  tabList: ITaskTab[];
  currentTab: string;
}

const TaskTabsHeader: FC<ITaskTabHeaderProps> = ({ tabList, currentTab }) => {
  return (
    <Styled.HeaderTabWrapper>
      {tabList?.map(tab =>
        tab?.isHided ? null : (
          <Styled.HeaderTab
            $isActive={(tab.id as string) === currentTab}
            $isDisabled={tab?.isDisabled}
            key={tab.id}
            onClick={() => {
              if (tab?.isDisabled) {
                return;
              }

              tab?.onSelectTab?.(tab.id, tab);
            }}
          >
            <Link
              data-test-id={`task-tab-${tab.id}`}
              onClick={e => e.preventDefault()}
              to={tab.route}
            >
              {tab.tabHeaderContent}
            </Link>
          </Styled.HeaderTab>
        )
      )}
    </Styled.HeaderTabWrapper>
  );
};

export default memo(TaskTabsHeader);
