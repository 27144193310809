import { FC } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../utils/IoC';
import { TableBuilderStore as Store } from '../../../mobx/stores';
import { useTableBuilderUIContext as useUIContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { TableBuilderGrid as Grid } from '../../shared';
import { useSortBy } from '../../../../utils/hooks/sort';
import { TableBuilderColumn as Column } from '../../../components/header';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';

import Styled from './TableBuilderHeader.styles';

const TableBuilderHeader: FC = () => {
  const store = useStore(Store);

  const UIContext = useUIContext();

  const columnList = useSortBy(store.getColumnList(UIContext.builderId));

  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper {...getDataTestId('header')}>
      <Grid>
        {columnList.map(column => (
          <Column
            key={column.id as string}
            name={column.name}
            dataTestId={getDataTestId(`column-with-key-${column.id as string}`)['data-test-id']}
          />
        ))}
      </Grid>
    </Styled.Wrapper>
  );
};

TableBuilderHeader.displayName = 'TableBuilderHeader';

export default observer(TableBuilderHeader);
