import { observer } from 'mobx-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useModal } from '@farmlink/farmik-ui';
import moment from 'moment';

import { ReactComponent as UploadIcon } from '../../../../../../../static/share24x24.svg';
import {
  REPORT_UPLOAD_MODAL_ERROR_ID,
  REPORT_UPLOAD_MODAL_SUCCESS_ID,
  uploadReportModalError,
  uploadReportModalSuccess,
} from '../../../../../modals/uploadReportModals';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { TasksReportUploader } from '../../../../../services/tasks.reportUploader';
import { TableFiltersBuilderStore } from '../../../../../../../../shared/features/TableFiltersBuilder/mobx/stores';

import Styled from './TasksFiltersUploadButton.styles';

const TasksFiltersUploadButton: FC = () => {
  const tasksReportUploader = useStore(TasksReportUploader);
  const tableFiltersBuilderStore = useStore(TableFiltersBuilderStore);

  const [isReportLoading, setIsReportLoading] = useState(false);

  const modalActions = useModal();

  const tableFiltersAppliedValueList = tableFiltersBuilderStore.getAppliedValueList('tasks');

  const successModal = useMemo(() => {
    let planDateFrom;
    let planDateTo;

    tableFiltersAppliedValueList.forEach(value => {
      if (value.filterId === 'planDateFrom') planDateFrom = value.dateValue;
      if (value.filterId === 'planDateTo') planDateTo = value.dateValue;
    }, {});

    return uploadReportModalSuccess(
      moment(planDateFrom).format('DD.MM.YYYY'),
      moment(planDateTo).format('DD.MM.YYYY')
    );
  }, [tableFiltersAppliedValueList]);

  useEffect(() => {
    modalActions.registerModalList([uploadReportModalError, successModal], 'taskListingModals');
  }, [successModal]);

  const handleDownloadReport = () => {
    setIsReportLoading(true);
    tasksReportUploader
      .getArrayFilterPowerBiReportCsvFile('tasks')
      .then(() => {
        modalActions.openModalByModalId(REPORT_UPLOAD_MODAL_SUCCESS_ID);
      })
      .catch(() => {
        modalActions.openModalByModalId(REPORT_UPLOAD_MODAL_ERROR_ID);
      })
      .finally(() => {
        setIsReportLoading(false);
      });
  };

  return (
    <Styled.Button
      type="button"
      data-test-id={'tasks-table-filters-upload-button'}
      onClick={handleDownloadReport}
      disabled={isReportLoading}
    >
      <UploadIcon />
    </Styled.Button>
  );
};

TasksFiltersUploadButton.displayName = 'TasksFiltersUploadButton';

export default observer(TasksFiltersUploadButton);
