import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/constans/colors';

const { green } = Colors;

const Wrapper = styled.div`
  margin-top: 24px;
  width: 100%;
`;

const Label = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

const viewingPointRowHeight = '44px';

interface IViewingPointsWrapperProps {
  $isShowAllRows: boolean;
}

const ViewingPointsWrapper = styled.div<IViewingPointsWrapperProps>`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: ${({ $isShowAllRows }) =>
    $isShowAllRows ? 'unset' : `calc(${viewingPointRowHeight} * 4)`};
`;

const Button = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: ${green};
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

const StyledViewingPoints = {
  Wrapper,
  Label,
  ViewingPointsWrapper,
  Button,
  ButtonWrapper,
};

export default StyledViewingPoints;
