import {
  IGetChecklistInstanceByTaskId,
  IPutChecklistInstance,
} from '../../../models/checklist/instance/checklist.instance.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TCreateChecklistInstanceRequest = IPutChecklistInstance;

type TCreateChecklistInstanceResponse = IGetChecklistInstanceByTaskId;

const createChecklistInstance: TypeApiRoute & {
  request: TCreateChecklistInstanceRequest;
  response: TCreateChecklistInstanceResponse;
} = {
  url: () => `/api/as-fields/checklist/instance`,
  method: 'POST',
  headers: {},
  request: {} as TCreateChecklistInstanceRequest,
  response: {} as TCreateChecklistInstanceResponse,
};

export type { TCreateChecklistInstanceRequest, TCreateChecklistInstanceResponse };

export { createChecklistInstance };
