import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  flex-wrap: wrap;

  gap: 16px 12px;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;

const StyledChecklistsAttr = {
  Wrapper,
};

export default StyledChecklistsAttr;
