import { FC, memo, PropsWithChildren } from 'react';

import { EChecklistAttributeWidth } from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { ChecklistsContent as Content } from '../ChecklistsContent';

import Styled from './ChecklistsAttr.styles';

interface IProps {
  width: EChecklistAttributeWidth;
  isNewLine?: boolean;
  id?: string;
}

const ChecklistsAttr: FC<PropsWithChildren<IProps>> = ({ width, isNewLine, id, children }) => {
  if (isNewLine) {
    return (
      <Styled.Wrapper $width={EChecklistAttributeWidth.Full}>
        <Content>
          <Styled.Wrapper id={id} $width={width}>
            {children}
          </Styled.Wrapper>
        </Content>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper id={id} $width={width}>
      {children}
    </Styled.Wrapper>
  );
};

ChecklistsAttr.displayName = 'ChecklistsAttr';

export default memo(ChecklistsAttr);
