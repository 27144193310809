import { makeAutoObservable, toJS } from 'mobx';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { booleanContains, difference, intersect, multiPolygon, polygon } from '@turf/turf';
import { doBBoxOverlap } from '@turf/boolean-contains';
import area from '@turf/area';
import { Feature, Polygon } from '@turf/helpers/dist/js/lib/geojson';
import calcBbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { getGeom } from '@turf/invariant';
import _, { throttle } from 'lodash';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../../stores/seasons.store';
import { Axios, TypeApiResponse } from '../../../../../../shared/utils/axios2';
import { CultureModel } from '../../../../../../../api/models/culture.model';
import { CultureZoneModel } from '../../../../../../../api/models/culture.zone.model';
import { FieldsStore } from '../../../../../modules/fields/stores/fields.store';
import { Field } from '../../../../../../../api/models/field.model';
import MapStore, { MapMode } from '../../../../../../map/stores/map.store';
import { RenderPolygonOption } from '../../../../../../map/consts/enum.render.option';
import { OrganizationsStore } from '../../../../../stores/organizations.store';
import { UiStore } from '../../../../../stores/ui.store';
import { FieldSeasonsStore } from '../../../../../stores/field.seasons.store';
import { createCultureZoneEventName } from '../../../../../../map/events/create.culture.zone.event';
import { clickMapEventName } from '../../../../../../map/events/click.map.event';
import { EMapValidationError } from '../../../../../../shared/constants/map/map.constants';

const AVAILABLE_MEASUREMENTS_ERROR = 5;

@provide.singleton()
export class CultureZoneStore {
  @lazyInject(SeasonsStore)
  protected seasons: SeasonsStore;

  @lazyInject(FieldsStore)
  fields: FieldsStore;

  @lazyInject(UiStore)
  ui: UiStore;

  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(OrganizationsStore)
  protected organizations: OrganizationsStore;

  @lazyInject(FieldSeasonsStore)
  fieldAndSeason: FieldSeasonsStore;

  @lazyInject(MapStore)
  map: MapStore;

  lastValidatedGeometry: any = '';

  isCultureZoneEditing = false;

  isCultureZoneCreating = false;

  isZoneNotificationWarning = false;

  idToCultureZone: Map<string, CultureZoneModel> = new Map();

  initialIdToCultureZone: Map<string, CultureZoneModel> = new Map();

  initialGeometryOfSelectedZone: CultureZoneModel['geometry'] = null;

  selectedCultureZoneModel: CultureZoneModel = null;

  constructor() {
    makeAutoObservable(this);
    window.addEventListener(createCultureZoneEventName, this.handleCreateNewZone);
    window.addEventListener(clickMapEventName, this.handleMapClick);
  }

  get initialCultureZonesList(): CultureZoneModel[] {
    return Array.from(this.initialIdToCultureZone.values());
  }

  // culture zone
  resetIdToCultureZone = (): void => {
    this.clearIdToCultureZone();
    Array.from(this.initialIdToCultureZone).forEach(([key, value]) => {
      this.idToCultureZone.set(key, value);
    });
    this.clearInitialIdToCultureZone();
  };

  clearIdToCultureZone = (): void => {
    this.idToCultureZone.clear();
  };

  setInitialIdToCultureZone = (): void => {
    this.clearInitialIdToCultureZone();

    Array.from(this.idToCultureZone).forEach(([key, value]) => {
      this.initialIdToCultureZone.set(key, _.cloneDeep(value));
    });
  };

  setSelectedCultureZoneModel = (zoneModel: CultureZoneModel): void => {
    this.selectedCultureZoneModel = zoneModel;
  };

  clearInitialIdToCultureZone = (): void => {
    this.initialIdToCultureZone.clear();
  };

  setLastValidateGeometry = (geometry: CultureZoneModel['geometry']): void => {
    this.lastValidatedGeometry = geometry;
  };

  setInitialGeometryOfSelectedZone = (geometry: CultureZoneModel['geometry']): void => {
    this.initialGeometryOfSelectedZone = geometry;
  };

  setIdToCultureZone = (id: string, zone: CultureZoneModel): void => {
    this.idToCultureZone.set(id, zone);
  };

  // culture zone editing and creating toggle

  setIsCultureZoneCreating = (value: boolean): void => {
    this.isCultureZoneCreating = value;
  };

  setIsCultureZoneEditing = (value: boolean): void => {
    this.isCultureZoneEditing = value;
  };

  // zone notification toggle
  setIsZoneNotificationWarning = (value: boolean): void => {
    this.isZoneNotificationWarning = value;
  };

  handleMapClick = event => {
    // todo: Handle map layer click if need
  };

  centerMap = () => {
    if (!this.field) {
      return;
    }
    this.map.instance.invalidateSize();
    this.map.highlightPolygon(this.field.polyId);
  };

  getMapIdByPolygonId = (polyId: string) => {
    const zones = this.cultureZones.filter(zone => zone.polyId === polyId);

    return zones[0] ? zones[0].mapId : '';
  };

  isCreateNewZoneTooltipVisible = false;
  tooltipPosition: {
    x: number;
    y: number;
  } = { x: 0, y: 0 };

  idToCulture: Map<string, CultureModel> = new Map();

  field: Field;

  selectedPolygonId: number = null;

  get cultureZones() {
    return Array.from(this.idToCultureZone.values());
  }

  handleExistingZoneClick = event => {
    const { target } = event;
    console.log('zone id', target._leaflet_id);
    const specificZoneMapId = this.getMapIdByPolygonId(target._leaflet_id);
    const zoneModel = this.idToCultureZone.get(specificZoneMapId);

    if (!zoneModel || this.selectedCultureZoneModel || this.isCultureZoneCreating) return;

    const selectedPolygon = this.map.getLayer(target._leaflet_id);

    if (!selectedPolygon) {
      return;
    }

    this.setInitialGeometryOfSelectedZone(zoneModel.geometry);
    this.setIsCultureZoneEditing(true);
    this.lastValidatedGeometry = zoneModel.geometry;
    this.selectedCultureZoneModel = zoneModel;
    this.setIsZoneNotificationWarning(true);

    this.map.setMode(MapMode.CZEditing);

    selectedPolygon.pm.enable();
    selectedPolygon.on('pm:markerdragend', this.handleChangeGeometry);
    selectedPolygon.on('pm:vertexclick', throttle(this.map.selectVertex, 300));
    selectedPolygon.on('pm:vertexadded', e => {
      this.map.selectVertex(e, true);
    });
    selectedPolygon.on('pm:markerdrag', throttle(this.map.handleLayerMarkerDrag, 300));
  };

  handleEditClick = (mapId: string | string[]) => {
    if (!Array.isArray(mapId)) {
      const model = this.idToCultureZone.get(mapId);

      if (!model || this.selectedCultureZoneModel) {
        return;
      }

      const selectedPolygon = this.map.getLayer(Number(model.polyId));

      if (!selectedPolygon) {
        return;
      }
      this.lastValidatedGeometry = model.geometry;
      this.selectedCultureZoneModel = model;

      this.map.setMode(MapMode.CZEditing);

      selectedPolygon.pm.enable();
      selectedPolygon.on('pm:markerdragend', this.handleChangeGeometry);
      selectedPolygon.on('pm:vertexclick', throttle(this.map.selectVertex, 300));
      selectedPolygon.on('pm:vertexadded', e => {
        this.map.selectVertex(e, true);
      });
      selectedPolygon.on('pm:markerdrag', throttle(this.map.handleLayerMarkerDrag, 300));

      this.setIsZoneNotificationWarning(true);
    }
  };

  isAcceptChangesAvailable = true;

  handleChangeGeometry = (event: any) => {
    const { target } = event;
    const leafletId = target._leaflet_id;
    const geoJson = target.toGeoJSON();
    this.validateEditCultureZone(geoJson, leafletId);
  };

  isPolyInPoly = (feature1: Feature<Polygon> | Polygon, feature2: Feature<Polygon> | Polygon) => {
    // Handle Nulls
    if (feature1.type === 'Feature' && feature1.geometry === null) {
      return false;
    }
    if (feature2.type === 'Feature' && feature2.geometry === null) {
      return false;
    }

    const poly1Bbox = calcBbox(feature1);
    const poly2Bbox = calcBbox(feature2);
    if (!doBBoxOverlap(poly1Bbox, poly2Bbox)) {
      return false;
    }

    const coords = getGeom(feature2).coordinates;
    for (const ring of coords) {
      for (const coord of ring) {
        if (!booleanPointInPolygon(coord, feature1, { ignoreBoundary: false })) {
          console.log('OUT COORDINATE', coord);
          return false;
        }
      }
    }
    return true;
  };
  undoLastEditChange = () => {
    const { culture, polyId } = this.selectedCultureZoneModel;

    const polygonLayer = this.map.getLayer(Number(polyId));

    if (!polygonLayer) return;

    polygonLayer.remove();

    const newPolyId = this.map.setPolygon(this.lastValidatedGeometry.coordinates, {
      renderOption: RenderPolygonOption.CultureZone,
      fill: culture?.attrs?.assistanceColorLegend || 'NONE',
    });

    const selectedPolygon = this.map.getLayer(Number(newPolyId));

    if (!selectedPolygon) return;

    this.selectedCultureZoneModel.polyId = newPolyId;
    selectedPolygon.pm.enable();
    selectedPolygon.on('pm:markerdragend', this.handleChangeGeometry);
    selectedPolygon.on('pm:vertexclick', throttle(this.map.selectVertex, 300));
    selectedPolygon.on('pm:vertexadded', e => {
      this.map.selectVertex(e, true);
    });
    selectedPolygon.on('pm:markerdrag', throttle(this.map.handleLayerMarkerDrag, 300));
  };

  validateEditCultureZone = (geoJson, leafletId, options?: { isPreventUndo?: boolean }) => {
    const { isPreventUndo } = options ?? {};

    console.log('geoJson', geoJson);

    const geometries = geoJson.geometry.coordinates[0].map(item => {
      console.log('item', item);
      return [Number(item[0].toFixed(6)), Number(item[1].toFixed(6))];
      // return item[0][0][0].toFixed(6);
    });

    // const zonePolygon = polygon(geoJson.geometry.coordinates);

    const zonePolygon = polygon([geometries]);
    const fieldPolygon = polygon(this.field.geometry.coordinates);
    const zoneArea = area(zonePolygon);
    const fieldArea = area(fieldPolygon);
    const FIELD_AREA_RELATIVITY_ERROR = Math.pow(Math.log(fieldArea), 2);

    console.log('validate ', zonePolygon, fieldPolygon, difference(fieldPolygon, zonePolygon));
    const differenceArea = difference(fieldPolygon, zonePolygon)
      ? area(difference(fieldPolygon, zonePolygon))
      : 0;
    const zoneInMap = this.idToCultureZone.get(this.selectedCultureZoneModel.mapId);
    console.log('CHECK OUT OF FIELD', Math.abs(fieldArea - zoneArea - differenceArea));
    if (Math.abs(fieldArea - zoneArea - differenceArea) > FIELD_AREA_RELATIVITY_ERROR) {
      console.log('OUT FIELD ERROR', Math.abs(fieldArea - zoneArea - differenceArea));

      if (!isPreventUndo) this.undoLastEditChange();

      return EMapValidationError.OutOfField;
    }

    const filteredList = this.cultureZones.filter(
      zone => zone.polyId !== leafletId && !zone.isHole
    );

    for (let i = 0; i < filteredList.length; i++) {
      const zone = filteredList[i];

      const cultureZonePoly = polygon(this.normalizeCoordsForTurf(zone.geometry.coordinates));
      const intersectionArea = intersect(zonePolygon, cultureZonePoly)
        ? area(intersect(zonePolygon, cultureZonePoly))
        : 0;
      console.log('CHECK ZONE', intersectionArea, FIELD_AREA_RELATIVITY_ERROR);
      if (intersectionArea > FIELD_AREA_RELATIVITY_ERROR) {
        console.log('OUT ZONE', intersectionArea, FIELD_AREA_RELATIVITY_ERROR);

        if (!isPreventUndo) this.undoLastEditChange();

        return EMapValidationError.OutOfZone;
      }
    }

    if (!zoneInMap) {
      if (!isPreventUndo) this.undoLastEditChange();
      return EMapValidationError.OutOfMap;
    }

    zoneInMap.geometry = geoJson.geometry;
    this.lastValidatedGeometry = geoJson.geometry;
    this.isAcceptChangesAvailable = true;
  };

  cancelDrawing = () => {
    this.map.instance.pm.disableDraw();
  };

  // todo: add right typing for nested array
  normalizeCoordsForTurf = (coords: any) => {
    if (coords[0][0][0]) {
      return coords;
    }

    return [coords];
  };

  updateExistingZonesData = (
    differenceOfAnotherZoneWithNew: any,
    zone: CultureZoneModel
  ): CultureZoneModel => ({
    ...zone,
    culture: {
      id: zone.culture.id,
      query: zone.culture.query,
      name: zone.culture.name,
      attrs: {
        ...zone.culture.attrs,
      },
    },
    polyId: '',
    mapId: uuidv4(),
    area: area(multiPolygon([differenceOfAnotherZoneWithNew.geometry.coordinates])) / 10000,
    geometry: differenceOfAnotherZoneWithNew.geometry as any,
  });

  handleCreateNewZone = event => {
    const tempPolygon = polygon(this.normalizeCoordsForTurf(event.detail.geometry));
    const fieldPolygon = polygon(this.normalizeCoordsForTurf(this.field.geometry.coordinates));

    const intersectionOfNewPolyWithField = intersect(fieldPolygon, tempPolygon);

    if (!intersectionOfNewPolyWithField) {
      return;
    }

    // Вызывает баги в работе turf. Не учтёны мультиполигоны интерсекции
    // const newZonePolygon = polygon(
    //   this.normalizeCoordsForTurf(intersectionOfNewPolyWithField.geometry.coordinates)
    // );

    const createCultureZone = (zonePolygon: Feature<Polygon>) => {
      const tempCultureZoneList: Array<CultureZoneModel> = [];
      // Add new zone to temp array

      const newZoneMapId = uuidv4();
      const areaInHectars = area(zonePolygon) / 10000;
      const season = this.seasons.getSeasonDataByYear();

      const newZone: CultureZoneModel = {
        culture: {
          attrs: {
            assistanceColorLegend: '',
          },
          id: '',
          name: '',
          query: '',
        },
        id: '',
        geometry: zonePolygon.geometry,
        isHole: false,
        mapId: newZoneMapId,
        polyId: '',
        area: areaInHectars,
        variety: '',
        harvestDate: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
        sowingDate: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
      };

      tempCultureZoneList.push(newZone);

      this.cultureZones.forEach(zone => {
        const anotherZonePoly = polygon(this.normalizeCoordsForTurf(zone.geometry.coordinates));

        const differenceOfAnotherZoneWithNew = difference(anotherZonePoly, zonePolygon);

        if (!differenceOfAnotherZoneWithNew) {
          tempCultureZoneList.push(newZone);
          return;
        }

        const isNewPolyInsideInExistingPoly = booleanContains(anotherZonePoly, tempPolygon);

        // If a new cultural zone divides another into two
        const isThroughField =
          differenceOfAnotherZoneWithNew.geometry.coordinates?.[0]?.length <= 2;

        if (isNewPolyInsideInExistingPoly || !isThroughField) {
          tempCultureZoneList.push(
            this.updateExistingZonesData(differenceOfAnotherZoneWithNew, zone)
          );
        } else {
          differenceOfAnotherZoneWithNew.geometry.coordinates.forEach(coords => {
            tempCultureZoneList.push({
              ...zone,

              culture: {
                id: zone.culture.id,
                query: zone.culture.query,
                name: zone.culture.name,
                attrs: {
                  ...zone.culture.attrs,
                },
              },
              polyId: '',
              mapId: uuidv4(),
              area: area(polygon(this.normalizeCoordsForTurf(coords))) / 10000,
              geometry: {
                coordinates: this.normalizeCoordsForTurf(coords),
                type: 'Polygon',
              },
              id: '',
            });
          });
        }
      });

      this.idToCultureZone.clear();

      tempCultureZoneList.forEach(zone => {
        this.idToCultureZone.set(zone.mapId, zone);
      });

      return tempCultureZoneList;
    };

    this.cancelDrawing();

    if (intersectionOfNewPolyWithField.geometry.type === 'Polygon') {
      const newZones = createCultureZone(intersectionOfNewPolyWithField as Feature<Polygon>);

      this.createHoles();
      this.renderCultureZones();

      this.handleEditClick(newZones[0].mapId);
    } else {
      const tempZones: CultureZoneModel[][] = [];
      intersectionOfNewPolyWithField.geometry.coordinates.forEach(zonePolygon => {
        tempZones.push(createCultureZone(polygon(zonePolygon)));
      });

      this.createHoles();
      this.renderCultureZones();

      this.handleEditClick(tempZones.flatMap(item => item[0].mapId));
    }
  };

  setDefaultDates = () => {
    // const season = this.seasons.getSeasonDataByYear();
    // this.cultureZones[0].sowingDate = moment(
    //   moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
    //   'YYYY-MM-DD'
    // );
    // this.cultureZones[0].harvestDate = moment(
    //   moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
    //   'YYYY-MM-DD'
    // );
  };

  get seasonBorder() {
    const season = this.seasons.getSeasonDataByYear();
    console.log('season ', season);
    console.log({
      min: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
      max: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
    });
    return {
      min: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
      max: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
    };
  }

  initiateCulturePolygon = () => {
    if (this.ui.functionFireForm === 'sowing/harvest') {
      const obj = this.fieldAndSeason.yearToField.get(Number(this.seasons.selectedSeason));
      if (!obj) {
        return;
      }
      this.field = obj.fieldVersion;
    } else {
      this.field = this.fields.idToFields.get(this.fields.selectedFieldId);
    }

    if (!this.field) {
      return;
    }
    this.idToCultureZone.clear();
    this.replicateCultureZoneData();
    this.createHoles();
    this.renderCultureZones();
    this.centerMap();
    this.setInitialIdToCultureZone();
  };

  removeHoles = () => {
    this.cultureZones.forEach(zone => {
      if (!zone.isHole) {
        return;
      }
      this.idToCultureZone.delete(zone.mapId);
    });
  };

  mapIdToCultureList: Map<string, Array<CultureModel>> = new Map();

  createHoles = () => {
    this.removeHoles();
    // Here we emit holes in field border excluding culture zones from it
    console.log('create holes ', this.cultureZones.length);
    const fieldPolygon = polygon(this.normalizeCoordsForTurf(this.field.geometry.coordinates[0]));
    const season = this.seasons.getSeasonDataByYear();
    if (!Boolean(this.cultureZonesCoordinates.length)) {
      const mapId = uuidv4();
      this.idToCultureZone.set(mapId, {
        culture: {
          attrs: {
            assistanceColorLegend: '',
          },
          id: '',
          name: '',
          query: '',
        },
        geometry: this.field.geometry,
        isHole: false,
        id: '',
        harvestDate: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
        variety: '',
        mapId,
        polyId: '',
        area: area(fieldPolygon) / 10000,
        sowingDate: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
      });
      return;
    }

    let freeSpaceExcludingCultureZones = polygon(
      this.normalizeCoordsForTurf(this.field.geometry.coordinates[0])
    ) as any;

    this.cultureZonesCoordinates.forEach((zone, index) => {
      const shitPoly = polygon(this.normalizeCoordsForTurf(zone));

      if (freeSpaceExcludingCultureZones) {
        freeSpaceExcludingCultureZones = difference(freeSpaceExcludingCultureZones, shitPoly);
      }
    });

    if (!freeSpaceExcludingCultureZones) {
      return;
    }

    if (freeSpaceExcludingCultureZones.geometry.type === 'MultiPolygon') {
      freeSpaceExcludingCultureZones.geometry.coordinates.forEach(coords => {
        const mapId = uuidv4();
        this.idToCultureZone.set(mapId, {
          culture: {
            attrs: {
              assistanceColorLegend: '',
            },
            id: '',
            name: '',
            query: '',
          },
          geometry: {
            coordinates: coords,
            type: 'Polygon',
          },
          isHole: true,
          id: '',
          harvestDate: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
          variety: '',
          mapId,
          polyId: '',
          area:
            area(
              freeSpaceExcludingCultureZones.geometry.type === 'Polygon'
                ? polygon(freeSpaceExcludingCultureZones.geometry.coordinates)
                : multiPolygon(freeSpaceExcludingCultureZones.geometry.coordinates)
            ) / 10000,
          sowingDate: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
        });
      });
      return;
    }

    const mapId = uuidv4();
    this.idToCultureZone.set(mapId, {
      culture: {
        attrs: {
          assistanceColorLegend: '',
        },
        id: '',
        name: '',
        query: '',
      },
      geometry: freeSpaceExcludingCultureZones.geometry,
      isHole: true,
      id: '',
      harvestDate: moment(moment(season.endDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
      variety: '',
      mapId,
      polyId: '',
      area:
        area(
          freeSpaceExcludingCultureZones.geometry.type === 'Polygon'
            ? polygon(freeSpaceExcludingCultureZones.geometry.coordinates)
            : multiPolygon(freeSpaceExcludingCultureZones.geometry.coordinates)
        ) / 10000,
      sowingDate: moment(moment(season.startDate, 'YYYY-MM-DD'), 'YYYY-MM-DD'),
    });
  };

  get cultureZonesCoordinates() {
    return this.cultureZones
      .filter(zone => !zone.isHole)
      .map(zone => {
        return zone.geometry.coordinates;
      });
  }

  renderCultureZones = () => {
    this.map.clearAll();
    // Render field borders;
    this.field.polyId = this.map.setPolygon(this.field.geometry.coordinates[0], {
      renderOption: RenderPolygonOption.CultureZoneBorder,
    });

    this.holePolyIdToMapId.clear();

    // Render culture zone
    this.cultureZones.forEach(zone => {
      console.log('render cultureZone', toJS(zone));

      if (zone.isHole) {
        console.log('ДЫРКА', JSON.stringify(zone.geometry), zone, this.field);

        const polyId = this.map.setPolygon(zone.geometry.coordinates, {
          renderOption: RenderPolygonOption.CultureZoneHole,
        });
        this.holePolyIdToMapId.set(polyId, zone.mapId);
        this.idToCultureZone.get(zone.mapId).polyId = polyId;

        const holePolygon = this.map.getLayer(polyId);

        holePolygon.on('click', this.handleHoleClick);
        return;
      }

      zone.polyId = this.map.setPolygon(zone.geometry.coordinates, {
        renderOption: RenderPolygonOption.CultureZone,
        fill: zone?.culture?.attrs?.assistanceColorLegend || '',
      });

      this.map.getLayer(Number(zone.polyId)).on('click', this.handleExistingZoneClick);
    });
  };

  holePolyIdToMapId: Map<string, string> = new Map();

  handleHoleClick = event => {
    if (this.isCultureZoneCreating || this.isCultureZoneEditing) {
      return;
    }

    const { target, containerPoint } = event;

    this.selectedPolygonId = target._leaflet_id;

    this.tooltipPosition = {
      x: containerPoint.x - 110,
      y: containerPoint.y - 80,
    };

    this.isCreateNewZoneTooltipVisible = true;
  };

  continiueHoleClick = () => {
    const holeZoneMapId = this.holePolyIdToMapId.get(this.selectedPolygonId as any);
    if (!holeZoneMapId) {
      return;
    }
    const holeZone = this.idToCultureZone.get(holeZoneMapId);
    if (!holeZone) {
      return;
    }
    holeZone.isHole = false;
    this.renderCultureZones();

    this.denyHoleClick();
  };

  denyHoleClick = () => {
    this.selectedPolygonId = null;
    this.isCreateNewZoneTooltipVisible = false;
  };

  replicateCultureZoneData = () => {
    if (!(this.field && this.field.cultureZones?.length > 0)) {
      return;
    }

    this.field.cultureZones.forEach(zone => {
      const mapId = uuidv4();
      const model: CultureZoneModel = {
        culture: { ...zone.culture, query: zone.culture.name },
        id: zone.id,
        area: zone.area,
        sowingDate: moment(zone.sowingDate),
        harvestDate: moment(zone.harvestDate),
        geometry: zone.geometry,
        variety: zone.variety,
        mapId,
        experimentReady: zone.experimentReady,
      };
      this.idToCultureZone.set(mapId, model);
    });
  };

  get harvestDate() {
    return this.cultureZones[0].harvestDate;
  }

  get sowingDate() {
    return this.cultureZones[0].harvestDate;
  }

  startCultureState: CultureZoneModel | null = null;

  deleteZone = (mapId: string) => {
    if (!this.idToCultureZone.has(mapId) || this.selectedCultureZoneModel) {
      return;
    }

    this.idToCultureZone.delete(mapId);
    this.mapIdToCultureList.delete(mapId);
    this.createHoles();
    this.renderCultureZones();
  };

  saveCultureZones = async (): Promise<void> => {
    const zoneList = this.cultureZones.filter(zone => !zone.isHole && zone.culture.id);

    try {
      await this.axios.api.updateCultureZones({
        zones: zoneList.map(zone => {
          return {
            id: zone.id,
            cultureZoneId: zone.id,
            variety: zone.variety,
            seasonYear: Number(this.seasons.selectedSeason),
            cultureId: zone.culture.id,
            geoJson: {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: zone.geometry.coordinates,
              },
            },
            sowingDate: zone.sowingDate.format('YYYY-MM-DD'),
            harvestDate: zone.harvestDate.format('YYYY-MM-DD'),
            experimentReady: zone.experimentReady,
          };
        }),
        seasonYear: Number(this.seasons.selectedSeason),
        fieldId: this.field.id,
      });
    } catch (e) {
      throw new Error('CULTURE ZONE');
    }
  };

  changeColor = (mapId: string) => {
    const zone = this.idToCultureZone.get(mapId);
    if (!zone) {
      return;
    }
    this.map.removePolygon(Number(zone.polyId));

    const options = {
      renderOption: RenderPolygonOption.CultureZone,
      fill: zone.culture.attrs.assistanceColorLegend || '',
    };

    zone.polyId = this.map.setPolygon(zone.geometry.coordinates, options);
  };

  setCultureSort = (mapId: string, v: string) => {
    const regexp = /^[а-яёА-ЯЁa-zA-Z0-9 ]+$/iu;
    if (!regexp.test(v) && v !== '') {
      return;
    }

    const zone = this.idToCultureZone.get(mapId);
    if (!zone) {
      return;
    }

    zone.variety = v;
  };

  setCultureArea = (mapId: string, v: string) => {
    const zone = this.idToCultureZone.get(mapId);
    if (!zone) {
      return;
    }

    zone.area = Number(v);
  };

  setCultureHarvestDate = (mapId: string, v: any) => {
    const zone = this.idToCultureZone.get(mapId);
    if (!zone) {
      return;
    }

    zone.harvestDate = v;
  };

  setCultureSewDate = (mapId: string, v: any) => {
    const zone = this.idToCultureZone.get(mapId);
    if (!zone) {
      return;
    }

    zone.sowingDate = v;
  };

  selectCulture = (mapId: string, v: string) => {
    const zone = this.idToCultureZone.get(mapId);

    if (!zone) {
      return;
    }
    console.log('new value', v);
    zone.culture.id = v;
    const cultureList = this.mapIdToCultureList.get(mapId);
    if (!cultureList || !cultureList.length) {
      return;
    }
    const cultures = cultureList.filter(culture => culture.id === v);
    console.log(toJS(cultureList), cultures);
    if (!Boolean(cultures.length)) {
      // console.log('нихуя', cultures.length);
      // zone.culture.attrs.assistanceColorLegend = '';
      // zone.culture.name = '';
      // this.changeColor(mapId);
      return;
    }
    zone.culture.name = cultures[0].name;
    zone.culture.attrs.assistanceColorLegend = cultures[0].attrs.assistanceColorLegend;
    this.idToCultureZone.set(mapId, zone);

    // this.changeColor(mapId);
    this.renderCultureZones();
  };

  setCultureExperimentReady = (mapId: string, v: boolean) => {
    const cultureZone = this.idToCultureZone.get(mapId);

    if (cultureZone) {
      cultureZone.experimentReady = v;
    }
  };

  fetchCultures = async (mapId: string, query: string) => {
    const zone = this.idToCultureZone.get(mapId);
    if (!zone) {
      return;
    }

    zone.culture.query = query;
    let response: TypeApiResponse<'findAllCultures'>;

    try {
      response = await this.axios.api.findAllCultures(
        {
          nameFilter: query,
          fetchAttributes: true,
          attrs: {
            useInAssistance: true,
          },
          limit: 50,
          page: 0,
        },
        { omit: ['limit', 'page'] }
      );
    } catch (e) {
      console.log(e);
      return;
    }
    console.log('response ');
    this.mapIdToCultureList.delete(mapId);
    this.mapIdToCultureList.set(mapId, response.content);
    console.log(this.mapIdToCultureList.get(mapId));
  };

  getCultureOptions = (mapId: string) => {
    const list = this.mapIdToCultureList.get(mapId);
    if (!list) {
      return [];
    }
    const zone = this.idToCultureZone.get(mapId);

    if (!zone) {
      return;
    }

    return list.map(culture => {
      return {
        label: culture.name,
        value: culture.id,
        isActive: culture.id === zone.culture.id,
      };
    });
  };

  get isAvailableSave() {
    // if (!this.cultureZones[0].sowingDate || !this.cultureZones[0].harvestDate) {
    //   return false;
    // }
    // return this.cultureZones[0].sowingDate.isBefore(this.cultureZones[0].harvestDate);
    return true;
  }

  clear = () => {
    this.clearIdToCultureZone();
    this.clearInitialIdToCultureZone();
    this.idToCulture.clear();
    this.mapIdToCultureList.clear();
    this.holePolyIdToMapId.clear();
    this.selectedCultureZoneModel = null;
    this.map.clearAll();
    this.setIsCultureZoneEditing(false);
    this.setIsCultureZoneCreating(false);
    this.setIsZoneNotificationWarning(false);
    this.map.setMode(MapMode.Listing);
    this.map.clearVertexCoordinates();
  };

  get isZoneChanged() {
    return false;
  }

  get seasonLabel() {
    return `Сезон ${Number(this.seasons.selectedSeason) - 1}-${this.seasons.selectedSeason}`;
  }
}
