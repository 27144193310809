export enum EContextualParentPath {
  Tasks = 'assistance/tasks',
  Operations = 'assistance/operations',
  TasksTaskCreateOne = 'assistance/tasks/task/create/one',
  TasksTaskCreateGroup = 'assistance/tasks/task/create/group',
}

export const ContextualPaths = {
  TasksHeaderTitle: `${EContextualParentPath.Tasks}/header__title`,
  OperationsHeaderTitle: `${EContextualParentPath.Operations}/header__title`,
  TasksTaskCreateOneHeaderTitle: `${EContextualParentPath.TasksTaskCreateOne}/header__title`,
  TasksTaskCreateGroupHeaderTitle: `${EContextualParentPath.TasksTaskCreateGroup}/header__title`,
};
