export const toFixedWithCeil = (num: number): string => {
  const preNumber = num * 1000;
  
  if (preNumber > 0 && preNumber < 100) {
    return '0.1';
  }
  if (preNumber > 1000) {
    return Math.trunc(num).toString();
  }

  return num?.toFixed(1);
};

export const toFixedWithCeilBackEnd = (num: number): string => {
  const preNumber = num * 1000;
  
  if (preNumber > 0 && preNumber < 100) {
    return '0.1';
  }

  return num?.toFixed(1);
};
