import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useNavigate } from 'react-router';
import { DropdownButton, TButtonMultiAction, useModal, useNotificator } from '@farmlink/farmik-ui';
import moment from 'moment/moment';

import { Label, LabelWrapper } from '../../../../../style';
import { ButtonGroup, UploadButton } from '../../../../listing/style';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';
import {
  REPORT_UPLOAD_MODAL_ERROR_ID,
  REPORT_UPLOAD_MODAL_SUCCESS_ID,
  uploadReportModalError,
  uploadReportModalSuccess,
} from '../../../../../modals/uploadReportModals';
import { TasksReportUploader } from '../../../../../services/tasks.reportUploader';
import { TasksStore } from '../../../../../../operations/stores/tasks.store';
import { CheckAccessStore } from '../../../../../../../../authorization/stores/checkAccess.store';
import { useTasksRouteActions } from '../../../../../hooks';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../../../../shared/constants/access-rules-action';
import { DashboardRoutes } from '../../../../../../../dashboard.routes';
import { ReactComponent as UploadIcon } from '../../../../../../../static/share24x24.svg';
import { TableFiltersBuilderController as FiltersBuilderController } from '../../../../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { TableFiltersBuilderStore } from '../../../../../../../../shared/features/TableFiltersBuilder/mobx/stores';

import { TasksFiltersHeaderToggleFiltersButton as ToggleFiltersButton } from './components/buttons';

const TasksFiltersHeader: FC = () => {
  const tasksStore = useStore(TasksStore);
  const { selectedOrganizationId } = useStore(OrganizationsStore);
  const { getArrayFilterPowerBiReportCsvFile } = useStore(TasksReportUploader);
  const { accessRules, getAccessRuleValue } = useStore(CheckAccessStore);
  const tableFiltersBuilderStore = useStore(TableFiltersBuilderStore);

  const tableFiltersBuilderController = useStore(FiltersBuilderController);

  const [isReportLoading, setIsReportLoading] = useState(false);

  const navigate = useNavigate();
  const notificator = useNotificator();

  const { goToTaskCreateOne, goToTaskCreateGroup } = useTasksRouteActions();
  const { registerModalList, openModalByModalId } = useModal();

  const isShownTableFilters = tableFiltersBuilderStore.getIsShowFilters('tasks');
  const tableFiltersPresetList = tableFiltersBuilderStore.getPresetList('tasks');
  const tableFiltersFilterList = tableFiltersBuilderStore.getFilterList('tasks');
  const tableFiltersAppliedValueList = tableFiltersBuilderStore.getAppliedValueList('tasks');

  const successModal = useMemo(() => {
    let planDateFrom;
    let planDateTo;

    tableFiltersAppliedValueList.forEach(value => {
      if (value.filterId === 'planDateFrom') planDateFrom = value.dateValue;
      if (value.filterId === 'planDateTo') planDateTo = value.dateValue;
    }, {});

    return uploadReportModalSuccess(
      moment(planDateFrom).format('DD.MM.YYYY'),
      moment(planDateTo).format('DD.MM.YYYY')
    );
  }, [tableFiltersAppliedValueList]);

  // useEffect(() => notificator.clearAll(), []);

  useEffect(() => {
    tasksStore.fromTasksPage = true;
    tasksStore.setFullScreenMode(null);

    registerModalList([uploadReportModalError, successModal], 'taskListingModals');

    return () => {
      tasksStore.fromTasksPage = false;
    };
  }, [successModal]);

  const isOrgMy = useMemo(() => selectedOrganizationId === 'my', [selectedOrganizationId]);

  useEffect(() => {
    if (isOrgMy || !accessRules.length) return;
    const isAllowTasks =
      getAccessRuleValue(`${SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS}`) ||
      getAccessRuleValue(`${SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS}`);
    if (!isAllowTasks) {
      const url = generatePath(DashboardRoutes.Fields, {
        orgId: selectedOrganizationId,
      });
      navigate(url);
    }
  }, [accessRules, selectedOrganizationId]);

  const handleDownloadReport = () => {
    setIsReportLoading(true);
    getArrayFilterPowerBiReportCsvFile('tasks')
      .then(() => {
        openModalByModalId(REPORT_UPLOAD_MODAL_SUCCESS_ID);
      })
      .catch(() => {
        openModalByModalId(REPORT_UPLOAD_MODAL_ERROR_ID);
      })
      .finally(() => {
        setIsReportLoading(false);
      });
  };
  const handleCreateOneTaskClick = useCallback(() => {
    goToTaskCreateOne();
  }, [goToTaskCreateOne]);

  const handleCreateMultipleTasksClick = useCallback(() => {
    goToTaskCreateGroup();
  }, [goToTaskCreateGroup]);

  const btnActionForCreateOneTask = useMemo<TButtonMultiAction>(() => {
    return {
      title: 'Одну',
      onClick: handleCreateOneTaskClick,
    };
  }, [handleCreateOneTaskClick]);

  const btnActionForCreateMultipleTask = useMemo<TButtonMultiAction>(() => {
    return {
      title: 'Группу',
      onClick: handleCreateMultipleTasksClick,
    };
  }, [handleCreateMultipleTasksClick]);

  const multiActionList = useMemo<TButtonMultiAction[]>(() => {
    return [btnActionForCreateOneTask, btnActionForCreateMultipleTask];
  }, [btnActionForCreateOneTask, btnActionForCreateMultipleTask]);

  return (
    <div>
      <LabelWrapper
        $isNeedMarginBottom={isShownTableFilters || Boolean(tableFiltersAppliedValueList.length)}
      >
        <Label data-test-id={'tasks-container-header-label'}>Задачи</Label>

        <ButtonGroup>
          {!isOrgMy && (
            <UploadButton
              type="button"
              data-test-id={'tasks-container-header-upload-button'}
              onClick={handleDownloadReport}
              disabled={isReportLoading}
            >
              <UploadIcon />
            </UploadButton>
          )}

          {!tableFiltersPresetList.length && tableFiltersFilterList.length ? (
            <ToggleFiltersButton
              isSelected={isShownTableFilters}
              onClick={() => {
                tableFiltersBuilderController.toggleFiltersVisibility('tasks');
              }}
            />
          ) : null}
          <DropdownButton
            title={'Создать задачу'}
            multiActionList={multiActionList}
            style={{
              color: 'primary',
              size: 'sm',
              placement: 'bottom',
              width: '170px',
            }}
          />
        </ButtonGroup>
      </LabelWrapper>
    </div>
  );
};

TasksFiltersHeader.displayName = 'TasksFiltersHeader';

export default observer(TasksFiltersHeader);
