import React, { useState } from 'react';
import { Button, ButtonLink } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../shared/utils/IoC';
import { OperationsStore } from '../../stores/operations.store';
import { OperationsUIStore } from '../../stores/operations.ui.store';
import { NoOperations } from '../NoOperations';
import { CreateOperationModal } from '../../modals/CreateOperationModal';
import { EditOperationModal } from '../../modals/EditOperationModal';
import { OperationItem } from '../OperationItem';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonCultureStore } from '../../stores/season.culture.store';
import { TasksStore } from '../../stores/tasks.store';
import { TasksController } from '../../controllers/tasks.controller';

import {
  Wrapper,
  Header,
  Body,
  Footer,
  OperationsListWrapper,
  Spinner,
  SpinnerWrapper,
} from './style';

export const OperationsList = observer(() => {
  const store = useStore(OperationsStore);
  const uiStore = useStore(OperationsUIStore);
  const organizationsStore = useStore(OrganizationsStore);
  const seasonCultureStore = useStore(SeasonCultureStore);
  const tasksStore = useStore(TasksStore);
  const tasksController = useStore(TasksController);

  const [isOperationCreationModalOpen, setIsOperationCreationModalOpen] = useState<boolean>(false);
  const [isOperationEditModalId, setIsOperationEditModalId] = useState<string>('');

  let lastInterval;

  const handleOperationClick = async (operationId: string) => {
    uiStore.selectMarkId(operationId);
    store.selectOperationId(operationId);
    store.resetOperationsFieldsList();
    store.fetchOperationsFieldsList();
    tasksController.clear();
    tasksController.resetPageSettings();
    await tasksController.fetchTasks({
      organizationId: organizationsStore.selectedOrganizationId,
      seassonCultureId: seasonCultureStore.selectedCultureId,
      operationId: store.selectedOperationId,
      fieldId: '',
      noCulture: seasonCultureStore.selectedCultureId === 'emptyCulture' ? true : undefined,
    });
  };

  const loadAdditionalOperations = async () => {
    await store.getOperations(true);
  };

  const updateScrollRef = ref => {
    if (lastInterval) {
      clearInterval(lastInterval);
    }
    lastInterval = setInterval(() => {
      if (
        ref &&
        store.operationPageNumber < store.operationTotalPages &&
        window.innerHeight - ref.getBoundingClientRect().top > 50
      ) {
        loadAdditionalOperations();
      }
    }, 500);
  };

  return (
    <Wrapper data-test-id={'operation-module-wrapper'}>
      <Header data-test-id={'operation-module-header'}>
        <span data-test-id={'operation-module-header-label'}>
          Операции ({store.totalOperationsNumber})
        </span>
        {store.OperationsList.length ? (
          <ButtonLink
            color={'accent'}
            dataTestId={'operation-module-header-add-operation'}
            onClick={() => {
              setIsOperationCreationModalOpen(true);
            }}
          >
            Новая операция
          </ButtonLink>
        ) : null}
      </Header>
      <Body data-test-id={'operation-module-body'}>
        {store.OperationsList.length ? (
          <OperationsListWrapper data-test-id={'operation-module-body-operations-list-wrapper'}>
            {store.OperationsList.map(operation => (
              <OperationItem
                onClick={handleOperationClick.bind(this, operation.id)}
                operation={operation}
                isActive={operation.id === uiStore.selectedMarkId}
                onMenuClick={() => setIsOperationEditModalId(operation.id)}
              />
            ))}
            {store.operationPageNumber < store.operationTotalPages ? (
              <SpinnerWrapper>
                <Spinner ref={ref => updateScrollRef(ref)} />
              </SpinnerWrapper>
            ) : null}
          </OperationsListWrapper>
        ) : (
          <NoOperations />
        )}
        {isOperationCreationModalOpen && (
          <CreateOperationModal
            closeModal={() => {
              setIsOperationCreationModalOpen(false);
            }}
          />
        )}

        {Boolean(isOperationEditModalId) && (
          <EditOperationModal
            closeModal={() => {
              setIsOperationEditModalId('');
            }}
            operationId={isOperationEditModalId}
          />
        )}
      </Body>
      {!store.OperationsList.length && (
        <Footer data-test-id={'operation-module-footer'}>
          <Button
            color={'primary'}
            type={'button'}
            className={'add-operations-button'}
            onClick={() => {
              setIsOperationCreationModalOpen(true);
            }}
            dataTestId={'operation-module-footer-add-button'}
          >
            Добавить операцию
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
});
