import { FC, ReactNode, memo } from 'react';
import { Header, DefaultTheme } from '@farmlink/farmik-ui';
import { ThemeProvider } from 'styled-components';

import { ETaskStatus } from '../../../../../../api/models/as-fields/task/task.model';

import * as Styled from './styles';

export type IFullscreenBackButtonProps = {
  backButtonHandler: () => void;
  backButtonText: ReactNode;

  arrowText?: string;
  status?: ETaskStatus;
  mode?: 'view' | 'edit' | 'create';
  actionButton?: React.ReactNode;
  rightSideContent?: React.ReactNode;
  titleAdditionalElement?: React.ReactNode;
};

const FullscreenBackButton: FC<IFullscreenBackButtonProps> = props => {
  const {
    backButtonHandler,
    backButtonText,
    rightSideContent,
    titleAdditionalElement,
    arrowText,
  } = props;

  return (
    <Styled.BackButton>
      <ThemeProvider theme={DefaultTheme}>
        <Header
          onArrowClick={backButtonHandler}
          title={backButtonText}
          goBackText={arrowText}
          dataTestId={'header-back-button'}
          fullWidth
          rightSideContent={rightSideContent}
          titleAdditionalElement={titleAdditionalElement}
          dataTestIdConfig={{
            headerTitle: 'operation-type-name',
          }}
        />
      </ThemeProvider>
    </Styled.BackButton>
  );
};

export default memo(FullscreenBackButton);
