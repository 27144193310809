import { makeAutoObservable } from 'mobx';
import { v4 as uuid } from 'uuid';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import {
  IMapCreateMarkerHandler as ICreateMarkerHandler,
  IMapGetMarkerOptionsHandler as IGetMarkerOptionsHandlerI,
  IMapMarkerElement as IMarketElement,
} from '../../../../../../../../shared/features/map/models';
import { ChecklistInstancesStore } from '../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { drawChecklistInstanceHelpers } from '../../../../../../operationsAndTasks/utils/helpers/checklists';
import {
  ECheckListInstanceType,
  IDrawChecklistInstance,
  IPutChecklistInstance,
} from '../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { InspectionPointsElementHelpers as ElementHelpers } from '../../../utils/helpers';
import { InspectionPointsStore } from '../../stores';

const { createPlanPointOptions } = ElementHelpers;

@provide.transient()
class InspectionPointsController {
  @lazyInject(ChecklistInstancesStore)
  protected checklistInstancesStore: ChecklistInstancesStore;

  @lazyInject(InspectionPointsStore)
  protected inspectionPointsStore: InspectionPointsStore;

  constructor() {
    makeAutoObservable(this);
  }

  getMarkerOptions: IGetMarkerOptionsHandlerI = () => {
    const { getTheBiggestDrawInstPosNum } = this.checklistInstancesStore;

    const positionNumber = getTheBiggestDrawInstPosNum() + 1;

    return createPlanPointOptions(positionNumber);
  };

  createMarker: ICreateMarkerHandler = marker => {
    const { getTheBiggestDrawInstPosNum, setDrawInst } = this.checklistInstancesStore;
    const { setPointElement } = this.inspectionPointsStore;

    const { createDrawInst } = drawChecklistInstanceHelpers;

    if (!marker?.leafletMarker) {
      return;
    }

    const { lat, lng } = marker.leafletMarker.getLatLng();

    const newInst: IPutChecklistInstance = {
      taskId: this.checklistInstancesStore.taskId,
      type: ECheckListInstanceType.PlannedPoint,
      isActive: true,
      planCoords: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
      },
      createOrUpdateDate: new Date().toISOString(),
      clientOnlyId: uuid(),
      clientLeafletId: marker.id,
    };

    const positionNumber = getTheBiggestDrawInstPosNum() + 1;

    const newDrawInstance = createDrawInst(newInst, positionNumber, true);

    setDrawInst(newDrawInstance);

    setPointElement({ element: newDrawInstance, marker });

    return { element: newDrawInstance, marker };
  };

  removePointElementList = (idList: string[]): void => {
    const { deletePointElement } = this.inspectionPointsStore;

    idList.forEach(id => {
      deletePointElement(id);
    });
  };

  changePointElementList = (elementList: IMarketElement<IDrawChecklistInstance>[]): void => {
    const { setPointElementList } = this.inspectionPointsStore;

    setPointElementList(elementList);
  };
}

export default InspectionPointsController;
