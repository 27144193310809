import { CultureZone, Field } from '../../field.model';
import { CultureModel } from '../../culture.model';
import { IRemoteDictionary } from '../../da-dictionary';
import { ISelectOption } from '../../../../types/selectOption';
import { Operation } from '../../operations/operation.model';

export interface ITask {
  planStartDate: string;
  planEndDate: string;
  comment: string;
  status: ETaskStatus;
  cancellationReason: string;
  externalId: string;
  id: string;
  operationInfo: ITaskTechOperation;
  field: Field;
  assignee: {
    id: string;
    fullName: string;
  };
  availableStatuses: ETaskStatus[];
  deletable: boolean;
  startedDate: string;
  executedDate: string;
  canceledDate: string;
  bindingDate: string;
  daysLeft: number;
  isExpired: boolean;
  createdBy: {
    id: string;
    fullName: string;
  };
  creationDate: string;
  organizationId: string;
  culture: CultureModel;
  intensityRequired: boolean;
  checklistsAvailable: boolean;
  checklistsMachineryAvailable: boolean;
  checklistsFieldAvailable: boolean;
  cultureZone?: CultureZone;
  availableActions?: ETaskAction[];
}

export interface ITaskTechOperation {
  startDate: string;
  endDate: string;
  seasonYear: number;
  name: string;
  cultureId: string;
  servicePricePerArea: number;
  experimentStepId: string;
  technology: any;
  id: string;
  iconLink: string;
  noCulture: boolean;
  organizationId: string;
  operationTypeInfo: IRemoteDictionary;
  phenophase: any;
  plan: boolean;
}

export interface ITaskCreate {
  planStartDate: string;
  planEndDate: string;
  comment: string;
  status: ETaskStatus;
  assigneeId: string;
  operationId: string;
  /**
   * Данное свойство только для создания одной задачи.
   */
  cultureZoneId?: string;
  /**
   * Данное свойство только для создания одной задачи.
   */
  fieldId?: string;
  /**
   * Данное свойство только для создания группы задач.
   */
  taskItems?: ITaskItem[];
  /**
   * Данное свойство требуется для предустановки даты при создании задачи из операций.
   */
  bindingDate?: string;
  cultureId?: string;
  externalId?: string;
  cancellationReason?: string;
  checkListInstances?: any[];
}

export type TTaskUpdate = Omit<ITaskCreate, 'operationId' | 'cultureZoneId' | 'checkListInstances'>;

export interface ITaskItem {
  fieldId: string;
  cultureZoneId: string;
  externalId?: string;
}

export interface ITaskPreview {
  id: string;
  order: number;
  operationName: string;
  fieldName: string;
  cultureName: string;
  assigneeName: string;
  dates: string;
  operation: Operation | null;
}

export enum ETaskStatus {
  New = 'NEW',
  InWork = 'IN_WORK',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  Impossible = 'IMPOSSIBLE',
}

export enum ETaskAction {
  Task = 'task',
  TaskView = 'task.view',
  TaskViewCommonInfo = 'task.view.commonInfo',
  TaskViewPoints = 'task.view.points',
  TaskViewCheckLists = 'task.view.checkLists',
  TaskCreate = 'task.create',
  TaskSetStatus = 'task.setStatus',
  TaskEdit = 'task.edit',
  TaskEditCommonInfo = 'task.edit.commonInfo',
  TaskEditCommonInfoComment = 'task.edit.commonInfo.comment',
  TaskEditCommonInfoDates = 'task.edit.commonInfo.dates',
  TaskEditCommonInfoAssignee = 'task.edit.commonInfo.assignee',
  TaskEditCommonInfoSelfAssignee = 'task.edit.commonInfo.selfAssignee',
  TaskEditPoints = 'task.edit.points',
  TaskEditCheckLists = 'task.edit.checkLists',
  TaskDelete = 'task.delete',
}
