import { makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { SeasonModel } from '../../../../../api/models/season.model';
import { Axios, TypeApiResponse } from '../../../../shared/utils/axios2';
import { OrganizationsStore } from '../../../stores/organizations.store';

type TCopySeasonParams = {
  organizationId?: string;
  startYear?: number;
  endYear?: number;
};
@provide.singleton()
export class CreateSeasonStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(OrganizationsStore)
  protected organizations: OrganizationsStore;

  constructor() {
    makeAutoObservable(this);
  }

  selectedStubYear = '';

  get isButtonAvailable() {
    return (
      this.selectedStubYear &&
      this.startDate &&
      this.endDate &&
      this.startDate.isBefore(this.endDate)
    );
  }

  get isActiveCopyCheckbox(): boolean {
    return this.isButtonAvailable && Boolean(this.copyYear);
  }

  get dateBorders() {
    return {
      start: {
        min: moment(`01/01/${Number(this.selectedStubYear) - 1}`, 'DD/MM/YYYY'),
        max: moment(`31/12/${Number(this.selectedStubYear)}`, 'DD/MM/YYYY'),
      },
      end: {
        min: moment(`01/01/${Number(this.selectedStubYear) - 1}`, 'DD/MM/YYYY'),
        max: moment(`31/12/${Number(this.selectedStubYear)}`, 'DD/MM/YYYY'),
      },
    };
  }

  copyYear = '';

  setCopyYear = (v: string) => {
    this.copyYear = v;
  };

  selectStubYear = (v: string) => {
    this.selectedStubYear = v;
    this.setStartDate(moment(`01/01/${Number(v) - 1}`, 'DD/MM/YYYY'));
    this.setEndDate(moment(`31/12/${Number(v)}`, 'DD/MM/YYYY'));
  };

  stubYears: Array<SeasonModel> = [];

  get stubYearsOptions() {
    return this.stubYears
      .filter(s => {
        let notIntersect = true;
        this.copyYears.forEach(y => {
          if (y.year === s.year) {
            notIntersect = false;
          }
        });
        return notIntersect;
      })
      .filter(item => item.isStub)
      .map(year => {
        return {
          label: year.label,
          value: year.year.toString(),
        };
      });
  }

  get copyYearsOptions() {
    return this.copyYears.map(({ fieldsCount, label, year }) => {
      return {
        label,
        value: year.toString(),
        fieldsCount,
      };
    });
  }
  copyYears: Array<SeasonModel> = [];

  fetchSeasonsForCopy = async (
    copySeasonParams: TCopySeasonParams = { startYear: 2010, endYear: 2040 }
  ) => {
    let response: TypeApiResponse<'getSeasons'>;
    try {
      response = await this.axios.api.getSeasons(
        {
          allowStub: false,
          limit: 40,
          sort: 'DESC',
          ...copySeasonParams,
        },
        { query: { withFieldsCount: true } }
      );
    } catch (e) {
      console.warn(e);
      return;
    }

    this.copyYears = response;
  };

  fetchStubSeasons = async () => {
    let response: TypeApiResponse<'getSeasons'>;
    const currentYear = moment().year();

    try {
      response = await this.axios.api.getSeasons({
        allowStub: true,
        startYear: currentYear - 10,
        endYear: currentYear + 10,
        sort: 'DESC',
        organizationId:
          this.organizations.selectedOrganizationId !== 'my'
            ? this.organizations.selectedOrganizationId
            : undefined,
      });
    } catch (e) {
      console.warn(e);
      return;
    }

    this.stubYears = response;
  };

  startDate: Moment | null;
  endDate: Moment | null;

  setStartDate = (v: Moment) => {
    this.startDate = v;
  };

  setEndDate = (v: Moment) => {
    this.endDate = v;
  };

  clear = () => {
    this.startDate = null;
    this.endDate = null;
    this.clearCopyYear();
    this.selectedStubYear = '';
  };

  clearCopyYear = (): void => {
    this.copyYear = '';
  };
}
