import React, { FC, useEffect, useState, useMemo } from 'react';
import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { toJS } from 'mobx';

import { Footer, Header, Label, StyledLabelWrapper, Wrapper } from '../../style';
import { ListWrapper } from '../listing/style';
import { CreationFieldItem } from '../../components/CreationgFieldItem/CreationFieldItem';
import { ButtonWrapper } from '../add/style';
import { DialogModal } from '../../../../../../components/DialogModal/DialogModal';
import { FieldsRoute } from '../../fields.route';
import { useStore } from '../../../../../shared/utils/IoC';
import { FieldsStore } from '../../stores/fields.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import MapStore from '../../../../../map/stores/map.store';
import { FieldsErrors } from '../../constants/fields.errors';
// import { RenderPolygonOption } from '../../../../../map/consts/enum.render.option';
import { ButtonLoader } from '../../../../../shared/components/ButtonLoader/ButtonLoader';
import { FieldsEditingController } from '../../controllers/fields.editing.controller';
import { PopupSliderController } from '../../../../components/PopupSlider/controlles/popupSlider.controller';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { timerDelay } from '../../../../../shared/utils/timerDelay';
import { MapController } from '../../../../../map/controllers/map.controller';
import { useWarningBeforeLeavingThePage } from '../../../../../shared/hooks/useWarningBeforeLeavingThePage';

export const FieldsEdit: FC = observer(() => {
  const fieldsEditingController = useStore(FieldsEditingController);
  const popupSliderController = useStore(PopupSliderController);

  const { editableField, initialEditableField, map, isLoading } = useStore(FieldsStore);

  const { selectedSeason } = useStore(SeasonsStore);
  const { setTooltipContentForLayer, deletePolygon, cultureZoneOutOfFieldError } = useStore(
    MapStore
  );
  const { setCultureZoneOutOfFieldError } = useStore(MapController);
  const organizationsStore = useStore(OrganizationsStore);

  const [isAreaModalOpen, setIsAreaModalOpen] = useState<boolean>(false);
  const [isIntersectionModalOpen, setIsIntersectionModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const { fieldId } = useParams<{ fieldId: string }>();
  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage();

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const fieldListingRoute = useMemo(
    () => generatePath(FieldsRoute.Listing, { orgId: organizationsStore.selectedOrganizationId }),
    [organizationsStore.selectedOrganizationId]
  );

  useEffect(() => {
    return setCultureZoneOutOfFieldError(null);
  }, []);

  useEffect(() => {
    if (
      !map.instance ||
      !organizationsStore.isFirstLoadOrganizationSelected ||
      Number(selectedSeason) === 0
    ) {
      return;
    }

    try {
      // Задержка в связи с рейсингом разной загрузки полей.
      timerDelay(500).then(() => {
        fieldsEditingController.startEditingMode(fieldId);
      });
    } catch (e) {
      console.error(e);
    }
  }, [map.instance, organizationsStore.isFirstLoadOrganizationSelected, selectedSeason]);

  const handleNameChange = (v: string) => {
    fieldsEditingController.changeEditableFieldName(v);
    setTooltipContentForLayer(editableField.polyId, v, true);
  };

  const handleClose = () => {
    if (JSON.stringify(toJS(editableField)) !== JSON.stringify(toJS(initialEditableField))) {
      showWarningBeforeLeavingThePage(() => {
        fieldsEditingController.resetEditMode();
        navigate(fieldListingRoute);
      });
    } else {
      fieldsEditingController.resetEditMode();
      navigate(fieldListingRoute);
    }
  };

  const handleSave = async () => {
    try {
      const seasonYear = Number(selectedSeason);
      await fieldsEditingController.saveEditableField(fieldId, seasonYear);
    } catch (e) {
      console.error(e.message);
      if (e.message === FieldsErrors.AreaTobBig) {
        setIsAreaModalOpen(true);
      }
      if (e.message === FieldsErrors.Intersection || e.message === FieldsErrors.CrossingGeometry) {
        setIsIntersectionModalOpen(true);
      }
      return;
    }
    if (!cultureZoneOutOfFieldError) {
      fieldsEditingController.resetEditMode();
      navigate(fieldListingRoute);
    }
    setCultureZoneOutOfFieldError(false);
  };

  return (
    <Wrapper data-test-id={'fields-edit-section'} $expanded>
      <Header>
        <StyledLabelWrapper>
          <Label data-test-id={'fields-edit-section-label'}>Редактирование поля</Label>
        </StyledLabelWrapper>
        <div></div>
      </Header>
      <ListWrapper>
        {editableField && (
          <CreationFieldItem
            key={editableField.id}
            area={editableField.areaInHectare ? editableField.areaInHectare : 0}
            name={editableField.name}
            onNameChange={handleNameChange}
            onDelete={handleDelete}
            image={editableField.icon ? editableField.icon.downloadUrl : ''}
            data-test-id={'fields-edit-props'}
          />
        )}
      </ListWrapper>
      <Footer>
        <ButtonWrapper>
          <Button
            type={'button'}
            color={'secondary'}
            onClick={handleClose}
            dataTestId={'fields-edit-cancel'}
          >
            Отменить
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <ButtonLoader
            isLoading={isLoading}
            onClick={handleSave}
            disabled={cultureZoneOutOfFieldError}
            data-test-id={'fields-edit-save'}
          >
            Сохранить
          </ButtonLoader>
        </ButtonWrapper>
      </Footer>
      {isDeleteModalOpen && (
        <DialogModal
          status={'warning'}
          title={'Вы уверены, что хотите удалить поле? Данные поля будут потеряны'}
          cancel={{
            name: 'Нет, отменить',
            handler: () => setIsDeleteModalOpen(false),
          }}
          accept={{
            name: 'Да, удалить',
            handler: async () => {
              try {
                const _fieldId = await fieldsEditingController.deleteFieldById(fieldId);
                deletePolygon(Number(_fieldId));
              } catch (e) {
                console.error(e);
              } finally {
                fieldsEditingController.resetEditMode(true);
                navigate(fieldListingRoute);
              }
            },
          }}
          onClose={() => setIsDeleteModalOpen(false)}
          data-test-id={'fields-edit-modal-delete-confirm'}
        />
      )}
      {isIntersectionModalOpen && (
        <DialogModal
          status={'warning'}
          title={'Границы полей не могут пересекаться. Измените границы для сохранения изменений!'}
          accept={{
            name: 'Продолжить',
            handler: () => setIsIntersectionModalOpen(false),
          }}
          onClose={() => setIsIntersectionModalOpen(false)}
          data-test-id={'fields-edit-modal-intersecting-areas'}
          size={'medium'}
          paddings={'alerts'}
        />
      )}
      {isAreaModalOpen && (
        <DialogModal
          status={'warning'}
          // неразрывный пробел для избежания переноса
          title={
            'Площадь поля не может быть больше 1000 га. Уменьшите площадь поля для сохранения изменений!'
          }
          accept={{
            name: 'Продолжить',
            handler: () => setIsAreaModalOpen(false),
          }}
          onClose={() => setIsAreaModalOpen(false)}
          data-test-id={'fields-edit-modal-area-too-big'}
          size={'medium'}
          paddings={'alerts'}
        />
      )}
    </Wrapper>
  );
});
