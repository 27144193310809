import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';

import Dummy_map_bg from './assets/dummy.svg';

type PropType = {
  imgUrl?: any;
};

export const AreaLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  max-width: 67.5px;
  color: ${Colors.black};
  overflow: hidden;
  white-space: nowrap;
`;

export const FieldName = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  line-height: 16px;
  white-space: nowrap;
  color: ${Colors.black};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;

  @media (max-width: 1024px) {
    width: 108px;
  }
`;

export const FieldCultures = styled.p``;

export const RightColumn = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: auto;
  flex-shrink: 0;
`;

export const RightButton = styled.div`
  display: none;
  height: 100%;
  justify-content: flex-end;
  svg {
    /* margin-top: 6px; */
    width: 20px;
  }
`;
export const Wrapper = styled.div<{ isActive: boolean; isCardFocused?: boolean }>`
  height: 80px;
  width: 100%;
  background-color: ${({ isActive, isCardFocused }) =>
    `${isActive || isCardFocused ? Colors.backgroundSecondaryGray : Colors.white}`};
  transition: 300ms;
  position: relative;

  ${FieldName} {
    ${({ isActive }) => isActive && `color: ${Colors.green}`};
  }

  ${AreaLabel} {
    ${({ isActive }) => isActive && `color: ${Colors.green}`};
  }
  &:hover {
    background: ${Colors.backgroundSecondaryGray};
    ${RightColumn} {
      justify-content: center;
      align-items: center;
    }
    ${RightButton} {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
    ${AreaLabel} {
      /* display: none; */
      opacity: 0;
    }
  }

  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
  //justify-content: center;
`;

export const ActiveLine = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  border-radius: 0px 5px 5px 0px;
  background: ${Colors.green};
`;

export const MapImage = styled.div<PropType>`
  width: 40px;
  height: 40px;
  border: 0.5px solid ${Colors.grayBorder};
  border-radius: 12px;
  margin-right: 8px;
  background: url(${({ imgUrl }) => (imgUrl && imgUrl.length ? imgUrl : Dummy_map_bg)}) no-repeat,
    ${Colors.grayBorder};
  background-size: ${({ imgUrl }) => (imgUrl && imgUrl.length ? 'cover' : '40% 40%')};
  background-position: center;
  flex-shrink: 0;
`;

export const Content = styled.div`
  width: 100%;

  height: 40px;
  display: flex;
  margin: 0;
  margin-left: 16px;
  margin-right: 16px;
  align-items: center;
  //justify-content: center;
`;

export const CheckboxWrapper = styled.div`
  margin-right: 8px;
`;
export const LabelsWrapper = styled.div`
  height: 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px;
  /* max-width: 130px; */
  flex-shrink: 1;
  overflow: hidden;
`;

export const CultureLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.black};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 1024px) {
    width: 108px;
  }
`;

export const LabelToolTip = styled.div<{ isShort: boolean }>`
  position: absolute;
  transition: 300ms;
  margin: 0;
  left: ${({ isShort }) => (isShort ? '100px' : '300px')};
  background: #ffffff;
  top: -10px;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  z-index: 9999;
  color: ${Colors.black};
`;

export const CulturePlaceholder = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const ContentInformation = styled.div<{ isEdit: boolean }>`
  width: 100%;
  height: 40px;
  display: flex;
  ${({ isEdit }) => isEdit && 'max-width: 228px;'}
`;

export const Line = styled.div`
  position: absolute;
  bottom: 0.5px;
  width: 252px;
  height: 0.5px;
  background: ${Colors.grayBorder};
  margin-left: 16px;
`;

export const FieldNameTipWrapper = styled.div`
  position: absolute;
  /* right: 0; */
`;

export const TipContent = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background: ${Colors.white};
  filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.1));
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    margin: 0;
    color: ${Colors.black};
  }
`;

export const TipTail = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  left: -5px;
  top: 15px;
  border-right: 5px solid ${Colors.white};
`;
export const LabelTooltipWrapper = styled.div`
  padding: 12px;
  border-radius: 8px;
  background: ${Colors.white};
  position: relative;
  p {
    margin: 0;
    color: ${Colors.black};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
`;
