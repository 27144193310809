import { TypeApiRoute } from '../../models/type.api.request';
import { Field } from '../../models/field.model';

type GetFieldByIdReq = {
  fieldId: string;
  seasonYear?: number;
};

type TypeResponse = Field;

export const getFieldById: TypeApiRoute & {
  request: GetFieldByIdReq;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/fields/${request.fieldId}`,
  method: 'GET',
  headers: {},
  request: {} as GetFieldByIdReq,
  response: {} as TypeResponse,
};

export type { GetFieldByIdReq };
