import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../../../../../../shared/constans/colors';

const { borderGray, secondaryGray, red, generalBlack } = Colors;

const Wrapper = styled.div<{ $isSelected: boolean }>`
  width: 121px;

  padding: 10px 12px;

  border: 1px solid ${borderGray};
  border-radius: 12px;

  overflow: hidden;
  text-overflow: ellipsis;

  background-color: ${secondaryGray};
  color: ${generalBlack};

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border-color: ${red};
      color: ${red};
    `}
`;

const StyledChecklistsAverageAttrCullNumbersModalItem = { Wrapper };

export default StyledChecklistsAverageAttrCullNumbersModalItem;
