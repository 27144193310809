import styled from 'styled-components';
import { Button } from '@farmlink/farmik-ui';

export const Wrapper = styled.div`
  text-align: center;
  cursor: pointer;
`;

export const ButtonInner = styled(Button)`
  height: 40px;
  padding: 0 40px;
  border-radius: 12px;
  display: inline-block;
  outline: none;
`;
