import styled, { CSSProperties } from 'styled-components';

const Wrapper = styled.div<{ $styles: CSSProperties }>(props => ({
  ...(props?.$styles ?? {}),
}));

const StyledChecklistsCSSContainer = {
  Wrapper,
};

export default StyledChecklistsCSSContainer;
