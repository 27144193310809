import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { OperationsStore } from '../stores/operations.store';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { SeasonsStore } from '../../../stores/seasons.store';
import { SeasonCultureStore } from '../stores/season.culture.store';
import { Operation } from '../../../../../api/models/operations/operation.model';

type FormData = {
  operationTypeId: string;
  startDate: string;
  endDate: string;
  name: string;
};

@provide.singleton()
export class CreateOperationFormController {
  @lazyInject(OperationsStore)
  operationsStore: OperationsStore;

  @lazyInject(OrganizationsStore)
  organizationStore: OrganizationsStore;

  @lazyInject(SeasonsStore)
  seasonStore: SeasonsStore;

  @lazyInject(SeasonCultureStore)
  seasonCultureStore: SeasonCultureStore;

  handleCreateOperation = async (formData: FormData) => {
    const organizationId =
      this.organizationStore.selectedOrganizationId === 'my'
        ? ''
        : this.organizationStore.selectedOrganizationId;

    try {
      return await this.operationsStore.createOperation({
        organizationId,
        operationTypeId: formData.operationTypeId,
        startDate: formData.startDate,
        endDate: formData.endDate,
        seasonYear: this.seasonStore.selectedSeassonData.year,
        name: formData.name,
        cultureId:
          this.seasonCultureStore.selectedCultureId !== 'emptyCulture'
            ? this.seasonCultureStore.selectedCultureId
            : undefined,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  handleEditOperation = async (formData: FormData, operationId: string) => {
    try {
      return await this.operationsStore.editOperation({
        operationTypeId: formData.operationTypeId,
        startDate: formData.startDate,
        endDate: formData.endDate,
        seasonYear: this.seasonStore.selectedSeassonData.year,
        name: formData.name,
        cultureId:
          this.seasonCultureStore.selectedCultureId !== 'emptyCulture'
            ? this.seasonCultureStore.selectedCultureId
            : undefined,
        operationId,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getOperationById = (operationId: string): Operation => {
    return this.operationsStore.OperationsList.find(operation => operation.id === operationId);
  };
}
