import { TypeApiRoute } from '../../models/type.api.request';
import { SeasonModel } from '../../models/season.model';

type TypeRequest = {
  organizationId?: string;
  startYear?: number;
  endYear?: number;
  allowStub: boolean;
  sort?: 'ASC' | 'DESC';
  limit?: number;
  withFieldsCount?: boolean;
};

type TypeResponse = Array<SeasonModel>;

export const getSeasons: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  // todo Костыль, чтобы сделать хотфикс на прод - убрать при мерже теста в стейдж
  url: req =>
    req.withFieldsCount
      ? `/api/as-fields/seasons/fetch?withFieldsCount=true`
      : `/api/as-fields/seasons/fetch`,
  method: 'POST',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
