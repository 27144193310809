import { observer } from 'mobx-react';
import { FC } from 'react';

import { ChecklistsFileAttr } from '../ChecklistsFileAttr';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsStore } from '../../../../mobx/stores';

interface IProps {
  groupId: string;
  attrId: string;
}

const ChecklistsFileAttrContainer: FC<IProps> = ({ groupId, attrId }) => {
  const checklistsStore = useStore(ChecklistsStore);

  const attrToDraw = checklistsStore.getAttrToDraw(groupId, attrId);

  if (!attrId) return <></>;

  return <ChecklistsFileAttr attrToDraw={attrToDraw} />;
};

ChecklistsFileAttrContainer.displayName = 'ChecklistsFileAttrContainer';

export default observer(ChecklistsFileAttrContainer);
