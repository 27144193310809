import { lazyInject, provide } from '../../../../../utils/IoC';
import { PermissionsBuilderStore as Store } from '../../stores';

@provide.transient()
class PermissionsBuilderPermissionsService<P = string, B = string> {
  @lazyInject(Store)
  protected store: Store<P, B>;

  addPermissionList: Store<P, B>['setPermissionList'] = (builderId, permissionList, options) => {
    this.store.setPermissionList(builderId, permissionList, options);
  };
}

export default PermissionsBuilderPermissionsService;
