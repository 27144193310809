import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../shared/utils/axios2';
import { OperationsStore } from '../../operations/stores/operations.store';

export enum TaskStatuses {
  New = 'NEW',
  InWork = 'IN_WORK',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
}

export enum TaskPrioretees {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
}

export const taskLabels = {
  [TaskStatuses.New]: 'Новая',
  [TaskStatuses.InWork]: 'В работе',
  [TaskStatuses.Completed]: 'Завершена',
  [TaskStatuses.Canceled]: 'Отменена',
};

export const taskPrioreteeLabels = {
  [TaskPrioretees.Low]: 'Низкий',
  [TaskPrioretees.Medium]: 'Средний',
  [TaskPrioretees.High]: 'Высокий',
};

export interface Task {
  id: string;
  operationId: string;
  status: TaskStatuses;
  availableStatuses: TaskStatuses[];
  isExpired: boolean;
  priority: TaskPrioretees;
  assignee: {
    id: string;
    fullName: string;
  };
  assigneeId: string;
  planStartDate: moment.Moment | string;
  planEndDate: moment.Moment | string;
  executedDate: moment.Moment | string;
  comment: string;
  field: any;
  fieldId: string;
}

@provide.singleton()
export class TasksStore {
  tasksMap: Map<string, Task> = new Map([]);
  selectedTaskId = '';
  selectedTask: Partial<Task> | null = {};

  prevSettings = {
    selectedSeason: '',
    selectedOperationId: '',
    selectedCultureId: '',
    selectedOperationFieldId: '',
  };

  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(OperationsStore)
  protected seasons: OperationsStore;

  constructor() {
    makeAutoObservable(this);
  }
}
