import { TypeUser } from '../../models/user.model';
import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/type.response.pageable';

type TGetOrganizationUserListReq = {
  role?: string;
  status?: string;
  fullname?: string;
  organizationID: string;
};

type TypeResponse = {
  content: Array<TypeUser>;
} & TypeResponsePageable;

export const getOrganizationUsers: TypeApiRoute & {
  request: TGetOrganizationUserListReq;
  response: TypeResponse;
} = {
  url: `/api/da-profile/organizations/userList`,
  method: 'GET',
  headers: {},
  request: {} as TGetOrganizationUserListReq,
  response: {} as TypeResponse,
};

export type { TGetOrganizationUserListReq };
