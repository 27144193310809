import { ISelectOption } from '../../../../../../types/selectOption';
import { Operation } from '../../../../../../api/models/operations/operation.model';

const createOperationSelectOption = (
  operation: Operation,
  config?: {
    isAddInitialModel?: boolean;
  }
): ISelectOption<Operation> => {
  const option: ISelectOption<Operation> = {
    label: operation?.operationTypeInfo?.name,
    value: operation.id,
  };

  if (config?.isAddInitialModel) {
    option.initialModel = operation;
  }

  return option;
};

const createOperationSelectOptionList = (
  cultureList: Operation[],
  config?: {
    isAddInitialModel?: boolean;
  }
): ISelectOption<Operation>[] => {
  return cultureList.map<ISelectOption<Operation>>(operation =>
    createOperationSelectOption(operation, config)
  );
};

export { createOperationSelectOption, createOperationSelectOptionList };
