import { forwardRef, MouseEvent, useLayoutEffect, useRef, useState } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import Styled from './DropdownSearchField.styles';
import { TDropdownSearchFieldProps } from './DropdownSearchField.types';

const DropdownSearchField = forwardRef<HTMLInputElement, TDropdownSearchFieldProps>(
  (
    {
      handlers: { onChange, onClick, onBlur, onFocus, onKeyUp },
      values: { searchValue, selectValue },
      configFieldProps: { id, placeholder, type, isDisabled },
      configVisualProps: { isBlocked },
      dataTestId,
    },
    inputRef
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isInputEllipsis, setIsInputEllipsis] = useState(false);

    const handleInputClick = (event: MouseEvent<HTMLInputElement>): void => {
      event.stopPropagation();

      onClick();
    };

    useLayoutEffect(() => {
      const parentWidth = (ref?.current.parentNode as HTMLDivElement).clientWidth;

      setIsInputEllipsis(ref?.current?.clientWidth > parentWidth);
    }, [ref?.current, selectValue?.label, searchValue]);

    /**
     * Добавил дублирующий элемент за границами экрана для расчёт реальной длинны инпута.
     */

    return (
      <AutoTooltip
        position="top"
        content={searchValue ?? (selectValue?.label || '')}
        disabled={!isInputEllipsis}
      >
        <Styled.InputWrapper>
          <Styled.Input
            readOnly={isBlocked || !type?.search}
            isDisabled={isDisabled}
            placeholder={placeholder}
            value={searchValue ?? (selectValue?.label || '')}
            id={id}
            ref={inputRef}
            maxLength={100}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            onClick={handleInputClick}
            autoComplete={'off'}
            $isBlocked={isBlocked}
            data-test-id={dataTestId}
            disabled={isBlocked || isDisabled}
          />

          <Styled.ShadowInput ref={ref}>
            {searchValue ?? (selectValue?.label || '')}
          </Styled.ShadowInput>
        </Styled.InputWrapper>
      </AutoTooltip>
    );
  }
);

export default DropdownSearchField;
