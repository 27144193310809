import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { TNewDropdownConfig } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';
import { NewDropdown as Dropdown } from '@farmlink/farmik-ui';
import { v4 as uuid } from 'uuid';

import { TChecklistsUserDictionaryAttrToDraw as TUserDictionaryAttrToDraw } from '../../../../models';
import {
  ChecklistsAttr as Attribute,
  ChecklistsCSSContainer as CSSContainer,
} from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import {
  EChecklistAttributeType as EAttrType,
  IGetChecklistAttributeUserDictionary,
} from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { useTasksParams } from '../../../../../../hooks';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import {
  useChecklistAttrPlaceholder as useAttrPlaceholder,
  useChecklistsAttrErrorList,
} from '../../../../hooks';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { ChecklistsStore } from '../../../../mobx/stores';

interface IProps {
  attrToDraw: TUserDictionaryAttrToDraw;
}

const ChecklistsUserDictionaryAttr: FC<IProps> = ({
  attrToDraw: {
    id,
    groupId,
    initialModel,
    value,
    options,
    isBlocked,
    validationScheme,
    dependentFileAttrId,
  },
}) => {
  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);

  const params = useTasksParams();

  const dependentFileAttr = checklistsStore.getAttrToDraw<EAttrType.FileLink>(
    groupId,
    dependentFileAttrId
  );

  const isDependentFileEmpty = !dependentFileAttr?.value?.fileValue?.length;

  const placeholder = useAttrPlaceholder(
    initialModel.attribute.type,
    initialModel.attribute.placeholder
  );

  const visibilityResult = initialModel.visibility
    ? checklistsController.calculateAttrVisibility(groupId, id)
    : { value: true };

  useEffect(() => {
    checklistsController.toggleAttrVisibility(groupId, id, visibilityResult.value as boolean);

    if (!dependentFileAttr) return;

    checklistsController.toggleAttrVisibility(
      groupId,
      dependentFileAttrId,
      visibilityResult.value as boolean
    );
  }, [JSON.stringify(visibilityResult)]);

  const errorList = useChecklistsAttrErrorList(
    validationScheme,
    dependentFileAttr?.validationScheme
  );

  useEffect(() => {
    (async () => {
      await checklistsController.fetchUserDictionaryList(groupId, id, {
        attributeId: id,
        organizationId: params.orgId,
        size: 2000,
      });
    })();
  }, []);

  const handleChange = useCallback<TNewDropdownConfig['field']['onChange']>(
    (_, otherData): void => {
      if (initialModel.attribute?.isMultiselect) {
        checklistsController.changeAttrValue(EAttrType.UserDictionaryLink, groupId, {
          ...value,
          userDictionaryValues: !otherData?.selectedValueList
            ? []
            : otherData.selectedValueList.map<IGetChecklistAttributeUserDictionary>(option => {
                const isNewOption = option.value === option.label;

                return {
                  id: option.value,
                  value: option.label,
                  clientId: isNewOption ? uuid() : null,
                };
              }) || [],
        });

        checklistsController.addSelectedSelectOptionList(
          EAttrType.UserDictionaryLink,
          groupId,
          id,
          !otherData?.selectedValueList ? [] : otherData.selectedValueList
        );

        return;
      }

      const isNewOption = otherData?.option?.value === otherData?.option?.label;

      const newValue = {
        id: otherData?.option?.value,
        value: otherData?.option?.label,
        clientId: isNewOption ? uuid() : null,
      };

      checklistsController.changeAttrValue(EAttrType.UserDictionaryLink, groupId, {
        ...value,
        userDictionaryValues: !otherData ? [] : [newValue],
      });

      checklistsController.addSelectedSelectOptionList(
        EAttrType.UserDictionaryLink,
        groupId,
        id,
        !otherData
          ? []
          : [
              {
                value: newValue.id,
                label: newValue.value,
              },
            ]
      );
    },
    []
  );

  const dropdownConfig: TNewDropdownConfig = {
    field: {
      onChange: handleChange,
      isRequired: initialModel.isRequired,
      defaultValueList: options.selectedSelectOptionList,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isAllowNewValueToBeAdded: true,
            isFullTextSearch: true,
          },
        },
        multiselect: initialModel.attribute?.isMultiselect ? {} : null,
      },
      placeholder,
    },
    body: {
      optionList: options.selectOptionList,
    },
    visual: {
      label: initialModel.attribute?.name,
      isBlocked,
      tooltip: initialModel.toolTip,
    },
    validation: {
      error: {
        errorList,
        options: {
          isDoNotShowErrorText: true,
        },
      },
    },
  };

  return (
    <>
      {visibilityResult.value ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
        >
          <Dropdown config={dropdownConfig} />

          <CSSContainer display={'flex'} justifyContent={'space-between'}>
            <CSSContainer>
              <InputFieldError error={{ errorList }} />
            </CSSContainer>

            <CSSContainer {...(isDependentFileEmpty ? {} : { flex: '1 1 auto' })}>
              {dependentFileAttrId ? (
                <FileAttrContainer groupId={groupId} attrId={dependentFileAttrId} />
              ) : null}
            </CSSContainer>
          </CSSContainer>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsUserDictionaryAttr.displayName = 'ChecklistsUserDictionaryAttr';

export default observer(ChecklistsUserDictionaryAttr);
