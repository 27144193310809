import styled from 'styled-components';

import { Colors } from '../../../shared/constans/colors';
import { BorderRadius, FontSizes } from '../../../shared/constans/sizes';

// import cultureBg from './assets/culture.png';

export const FieldInfoWrapper = styled.div`
  display: block;
  /* height: 228px; */
  border-radius: ${BorderRadius.default};
  position: relative;
  background: ${Colors.white};
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FieldCulturePic = styled.img.attrs(props => ({
  src: props.src,
}))`
  ${({ src }) => {
    if (!src) return `display: none;`;
  }}
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotateY(180deg);
  z-index: 0;
  width: 120px;
  max-width: 100%;
  border-bottom-left-radius: 16px;
  @media (min-width: 1366px) {
    display: flex;
  }
`;

export const Label = styled.div<{ fieldname: string; showtip: boolean }>`
  @media (max-width: 1365px) {
    height: 52px;
  }
  height: 56px;
  padding: 0 16px 0 24px;

  @media (max-width: 1024px) {
    padding: 0 2px 0 16px;
  }
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.lightnessGray};
  &:before {
    content: '${({ fieldname }) => fieldname}';
    display: ${({ showtip }) => (showtip ? 'inline-block' : 'none')};
    position: absolute;
    top: 0;
    left: 24px;
    background: ${Colors.white};
    width: 158px;
    white-space: normal;
    padding: 12px;
    word-break: break-all;
    border-radius: ${BorderRadius.xsmall};
    filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.1));
    ${FontSizes.medium}
    z-index: 1;
  }
  &:after {
    content: '';
    display: ${({ showtip }) => (showtip ? 'inline-block' : 'none')};
    width: 10px;
    height: 10px;
    position: absolute;
    left: 200px;
    z-index: 1;
    transform: rotate(45deg);
    background: ${Colors.white};
  }

  p {
    @media (max-width: 1365px) {
      ${FontSizes.mediumBold}
    }
    ${FontSizes.largeBold}
    max-width: 100%;
    white-space: nowrap;
    margin: 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    transform: translateY(6px);
  }
`;

export const Content = styled.div`
  padding: 24px;
  @media (max-width: 1024px) {
    padding: 16px 16px 20px;
  }
  position: relative;
  z-index: 1;
`;

export const FieldSquare = styled.div`
  ${FontSizes.medium}
  @media (max-width: 1024px) {
    ${FontSizes.default}
    margin-bottom: 4px;
  }
`;

export const FieldEdit = styled.div`
  ${FontSizes.mediumBold}
  color: ${Colors.green};
  margin-bottom: 36px;
  @media (max-width: 1365px) {
    margin-bottom: 24px;
    ${FontSizes.default}
    font-weight: 400;
  }
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FieldCulturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 40px;
  max-width: 192px;
`;

export const FieldCultures = styled.div`
  ${FontSizes.medium}
  @media (max-width: 1024px) {
    ${FontSizes.default}
    margin-bottom: 4px;
  }
  max-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 192px;
`;

export const FieldSeazonsView = styled.div`
  ${FontSizes.mediumBold}
  @media (max-width: 1366px) {
    ${FontSizes.default}
    font-weight: 400;
  }
  color:${Colors.green};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
