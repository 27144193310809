import styled from 'styled-components';

import { Colors } from '../../../../../constans/colors';

const CreateNewValueLabel = styled.div`
  color: ${Colors.generalBlack};
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 0 60%;
`;

const CreateNewValueButton = styled.span`
  color: ${Colors.secondaryDarkGray};
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-right: 4px;

  cursor: pointer;

  &:hover {
    color: ${Colors.hoverDarkGray};
  }
`;

const CreateNewValueWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const NothingFoundLabel = styled.div`
  width: 100%;
  color: ${Colors.secondaryDarkGray};
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;

const OptionList = styled.div`
  padding-top: 15px;
  border-top: 0.5px solid ${Colors.borderGray};
  background: ${Colors.secondaryGray};
  width: 100%;
  max-height: 160px;
  box-sizing: content-box !important;
  overflow-x: hidden;
  overflow-y: scroll;

  & > :not(:first-child, :last-child) {
    padding: 6px 0px;
  }

  & > :first-child {
    padding-bottom: 6px;
  }

  & > :last-child {
    padding-top: 6px;
  }

  & {
    overflow: -moz-scrollbars-none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  border: 1px solid ${Colors.borderGray};
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: ${Colors.secondaryGray};
  z-index: 100;
`;

const StyledDropdownBody = {
  CreateNewValueButton,
  CreateNewValueLabel,
  CreateNewValueWrapper,
  NothingFoundLabel,
  OptionList,
  Wrapper,
};

export default StyledDropdownBody;
