import { FC } from 'react';

import Styled from './TaskCommonData.styles';
import {
  AssigneeSelect,
  Comment,
  CultureSelect,
  FieldSelect,
  OperationSelect,
  PlanEndDate,
  PlanStartDate,
} from './components';

const TaskCommonData: FC = () => {
  return (
    <Styled.Form>
      <Styled.Col $variant="calc(50% - 8px) calc(50% - 8px)">
        <Styled.Row>
          <CultureSelect />
        </Styled.Row>
        <Styled.Row>
          <OperationSelect />
        </Styled.Row>
        <Styled.Row>
          <FieldSelect />
        </Styled.Row>
        <Styled.Row>
          <AssigneeSelect />
        </Styled.Row>
        <Styled.Row>
          <PlanStartDate />
        </Styled.Row>
        <Styled.Row>
          <PlanEndDate />
        </Styled.Row>
      </Styled.Col>

      <Styled.Col $variant="1fr">
        <Styled.Row>
          <Comment />
        </Styled.Row>
      </Styled.Col>
    </Styled.Form>
  );
};

TaskCommonData.displayName = 'TaskCommonData';

export default TaskCommonData;
