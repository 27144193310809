import { TypeApiRoute } from '../../models/type.api.request';
import { UserInfoType } from '../../models/user.model';
import { TypeResponsePageable } from '../../models/type.response.pageable';
import { Operation } from '../../models/operations/operation.model';

type TypeRequest = {
  organizationId?: string;
  operationId: string;
};

export interface OperationField {
  fieldId: string;
  fieldName: string;
  tasks: number;
}

type TypeResponse = Array<OperationField>;

export const getOperationFields: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/operation/fields/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
