import { IGetChecklistStage } from '../../../models/checklist/stage/checklist.stage.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = { checklistId: string };

type TypeResponse = IGetChecklistStage[];

export const getChecklistStageList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/as-fields/checklist/stage/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
