import { FC, useMemo, useState } from 'react';

import Styled from './TextExpand.styles';

interface IProps {
  text: string;
  dataTestId?: string;
}

const maxLength = 150;

const TextExpand: FC<IProps> = ({ text, dataTestId: _dataTestId }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const dataTestId = useMemo(() => _dataTestId ?? 'text-expand', []);

  const isLongText = useMemo(() => text.length > maxLength, [text]);

  return (
    <Styled.Wrapper
      $isExpanded={isExpanded}
      $isLongText={isLongText}
      data-test-id={`${dataTestId}`}
    >
      <Styled.TextWrapper data-test-id={`${dataTestId}-text`}>{text}</Styled.TextWrapper>
      {isLongText && (
        <Styled.ExpandButtonWrapper $isExpanded={isExpanded}>
          {!isExpanded && '...'}
          <Styled.ExpandButton
            $isExpanded={isExpanded}
            $isLongText={isLongText}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            data-test-id={`${dataTestId}-expand-button`}
            data-is-expanded={isExpanded}
          >
            {isExpanded ? 'Свернуть' : 'Развернуть'}
          </Styled.ExpandButton>
        </Styled.ExpandButtonWrapper>
      )}
    </Styled.Wrapper>
  );
};

TextExpand.displayName = 'TextExpand';

export default TextExpand;
