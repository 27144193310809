import { FC, PropsWithChildren } from 'react';
import { CSSProperties } from 'styled-components';

import Styled from './ChecklistsCSSContainer.styles';

type TProps = CSSProperties;

const ChecklistsCSSContainer: FC<PropsWithChildren<TProps>> = ({ children, ...styles }) => {
  return <Styled.Wrapper $styles={styles}>{children}</Styled.Wrapper>;
};

ChecklistsCSSContainer.displayName = 'ChecklistsCSSContainer';

export default ChecklistsCSSContainer;
