import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios } from '../../../../utils/axios2';
import { OperationField } from '../../../../../../api/models/operations/operation.field.model';
import { TGetOperationListReq } from '../../../../../../api/endpoints/operations/operations.get';
import { Operation } from '../../../../../../api/models/operations/operation.model';

@provide.singleton()
class OperationsService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  getOperationList = async (payload: TGetOperationListReq): Promise<Operation[]> => {
    const { getOperations } = this.axiosService.api;

    try {
      const { content } = await getOperations(payload);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getFieldList = async (operationId: string): Promise<OperationField[]> => {
    const { getOperationFields } = this.axiosService.api;

    try {
      const fieldList = await getOperationFields({ operationId });

      return fieldList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default OperationsService;
