import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Container } from '../../../../../../../shared/features/UI/Container';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../mobx/stores';
import { TaskController } from '../../../../mobx/controllers';
import { TableBuilder } from '../../../../../../../shared/features/TableBuilder';
import { ETasksTableBuilderId } from '../../../../utils/constants';

const PreviewTasksTable: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);

  useEffect(() => {
    taskController.initiatePreviewTasksTable();

    return () => {
      taskStore.clearPreviewTasksById();
    };
  }, []);

  return (
    <Container
      header={{
        title: `Будет создано задач: ${taskStore.previewTaskList.length}`,
        description:
          'Проверьте список, подтвердите создание. Если требуется что-то поправить, то вернитесь к редактированию.',
      }}
    >
      <TableBuilder builderId={ETasksTableBuilderId.PreviewTasks} stylePreset={'cleared'} />
    </Container>
  );
};

PreviewTasksTable.displayName = 'PreviewTasksTable';

export default observer(PreviewTasksTable);
