import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { FieldsImportLoader, FieldsImportModalContainer } from '../containers';

const FIELDS_IMPORT_MODALS_UNIQUE_KEY = 'fieldsImport';

enum EFieldsImportModalName {
  FileImport = 'fileImport',
  Loader = 'loader',
  NotEnoughRights = 'notEnoughRights',
  SomethingWentWrongWithoutUpload = 'somethingWentWrongWithoutUpload',
  UnexpectedError = 'UnexpectedError',
  NotAuthorized = 'NotAuthorized',
  // system
  _Success = '_success',
}

const fileImportModalConfig: TModalConfig = {
  name: EFieldsImportModalName.FileImport,
  title: '',
  type: EModalType.Custom,
  id: EFieldsImportModalName.FileImport,
  children: FieldsImportModalContainer,
  dataTestId: EFieldsImportModalName.FileImport,
};

const loaderModalConfig: TModalConfig = {
  name: EFieldsImportModalName.Loader,
  title: '',
  type: EModalType.Custom,
  id: EFieldsImportModalName.Loader,
  children: FieldsImportLoader,
  dataTestId: EFieldsImportModalName.Loader,
};

const notEnoughRightsModalConfig: TModalConfig = {
  name: EFieldsImportModalName.NotEnoughRights,
  title: 'У вас недостаточно прав для операции',
  type: EModalType.Warning,
  id: EFieldsImportModalName.NotEnoughRights,
  dataTestId: EFieldsImportModalName.NotEnoughRights,
  successButton: {
    title: 'Вернуться к редактированию',
    color: 'primary',
  },
};

const somethingWentWrongWithoutUploadModalConfig: TModalConfig = {
  name: EFieldsImportModalName.SomethingWentWrongWithoutUpload,
  title: 'Кажется, что-то пошло не так. Проверьте данные, чтобы продолжить',
  type: EModalType.Warning,
  id: EFieldsImportModalName.SomethingWentWrongWithoutUpload,
  dataTestId: EFieldsImportModalName.SomethingWentWrongWithoutUpload,
  successButton: {
    title: 'Вернуться к редактированию',
    color: 'primary',
  },
};

const unexpectedErrorModalConfig: TModalConfig = {
  name: EFieldsImportModalName.UnexpectedError,
  title: 'Кажется, что-то пошло не так. Попробуйте ещё раз',
  type: EModalType.Warning,
  id: EFieldsImportModalName.UnexpectedError,
  dataTestId: EFieldsImportModalName.UnexpectedError,
  successButton: {
    title: 'Вернуться к редактированию',
    color: 'primary',
  },
};

const fieldsImportModalConfigList = [
  fileImportModalConfig,
  loaderModalConfig,
  notEnoughRightsModalConfig,
  somethingWentWrongWithoutUploadModalConfig,
  unexpectedErrorModalConfig,
];

export { EFieldsImportModalName, FIELDS_IMPORT_MODALS_UNIQUE_KEY, fieldsImportModalConfigList };
