import styled from 'styled-components';

import { Colors } from '../../modules/shared/constans/colors';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  background: ${Colors.white};
  padding: 40px;
  margin: 20px auto;
  text-align: justify;

  ul {
    list-style: none;
  }
`;

export const MobileHardFix = styled.div`
  display: none;
  height: 200px;
  width: 100px;

  @media (max-width: 768px) {
    display: block;
  }
`;
