import React, { FC, MouseEvent, useCallback } from 'react';
import { observer } from 'mobx-react';
import { ButtonLink, TButtonLinkOnClick } from '@farmlink/farmik-ui';

import { TableFiltersBuilderController as BuilderController } from '../../../mobx/controllers';
import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { useTableFiltersBuilderUIContext as useUIContext } from '../../../context/UI/TableFiltersBuilderUIContext/TableFiltersBuilderUI.context';
import { useStore } from '../../../../../utils/IoC';

const TableFiltersBuilderClearButton: FC = () => {
  const builderController = useStore(BuilderController);

  const UIContext = useUIContext();

  const handleClick = useCallback<TButtonLinkOnClick>((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    builderController.removeAllValues(UIContext.builderId);
  }, []);

  const getDataTestId = useDataTestId();

  return (
    <ButtonLink
      color={'critical'}
      onClick={handleClick}
      dataTestId={getDataTestId('clear-filters-button')['data-test-id']}
    >
      Очистить
    </ButtonLink>
  );
};

TableFiltersBuilderClearButton.displayName = 'TableFiltersBuilderClearButton';

export default observer(TableFiltersBuilderClearButton);
