import { FC, memo } from 'react';

import { IChecklistsAttrToDraw } from '../../../../models';
import { ChecklistsCalculatedDoubleAttr as CalculatedDoubleAttr } from '../ChecklistsCalculatedDoubleAttr';
import { ChecklistsDoubleAttr as DoubleAttr } from '../ChecklistsDoubleAttr';
import AverageAttributeHelpers from '../../ChecklistsAverageAttribute/helpers/AverageAttribute.helpers';
import { ChecklistsAverageAttribute as AverageAttribute } from '../../ChecklistsAverageAttribute';

const { isAverageChecklistAttr } = AverageAttributeHelpers;

interface IProps {
  attrToDraw: IChecklistsAttrToDraw;
}

const ChecklistsDoubleAttrByEnteringMethodContainer: FC<IProps> = ({ attrToDraw }) => {
  if (isAverageChecklistAttr(attrToDraw.initialModel.attribute)) {
    return <AverageAttribute attrToDraw={attrToDraw} />;
  }

  if (attrToDraw.initialModel.calculate) {
    return <CalculatedDoubleAttr attrToDraw={attrToDraw} />;
  }

  return <DoubleAttr attrToDraw={attrToDraw} />;
};

ChecklistsDoubleAttrByEnteringMethodContainer.displayName =
  'ChecklistsDoubleAttrByEnteringMethodContainer';

export default memo(ChecklistsDoubleAttrByEnteringMethodContainer);
