import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const BinColorWrapper = styled.div`
  transition: 0.3s;

  & > svg {
    path {
      fill: ${Colors.pink};
    }
  }

  :hover {
    & > svg {
      path {
        fill: ${Colors.hoverPink};
      }
    }
  }
`;

const Styled = {
  BinColorWrapper,
};

export default Styled;
