import { Tooltip } from '@mui/material';
import { FC, ReactElement } from 'react';
import './style.css';

export interface TooltipsterProps {
  title: string;
  id: string;
  placement:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  children: ReactElement;
}

export const Tooltipster: FC<TooltipsterProps> = ({ title, placement, id, children }) => (
  <Tooltip title={title} arrow id={id} placement={placement}>
    {children}
  </Tooltip>
);
