import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  resetToken: string;
};

type TypeResponse = {
  token: string;
};

export const verifyResetPasswordToken: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-profile/auth/verifyPasswordReset`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
