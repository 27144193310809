import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/constans/colors';

const { black, green, white } = Colors;

const textWrapperLineHeight = '20px';

interface IWrapperProps {
  $isExpanded: boolean;
  $isLongText: boolean;
}

const getWrapperHeight = (isExpanded: boolean, isLongText: boolean): string => {
  if (!isLongText) {
    return 'unset';
  }

  return isExpanded ? 'unset' : `calc(${textWrapperLineHeight} * 2)`;
};

const Wrapper = styled.div<IWrapperProps>`
  overflow: ${({ $isExpanded }) => ($isExpanded ? 'visible' : 'hidden')};
  width: 100%;
  position: relative;
  overflow-wrap: break-word;
  height: ${({ $isExpanded, $isLongText }) => getWrapperHeight($isExpanded, $isLongText)};
`;

const TextWrapper = styled.span`
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.1px;
  color: ${black};
  line-height: ${textWrapperLineHeight};
`;

interface IExpandButtonWrapperProps {
  $isExpanded: boolean;
}

const ExpandButtonWrapper = styled.div<IExpandButtonWrapperProps>`
  position: absolute;
  bottom: ${({ $isExpanded }) => ($isExpanded ? `-${expandButtonHeight}` : 0)};
  right: 0;
  background-color: ${white};
`;

interface IExpandButtonProps {
  $isExpanded: boolean;
  $isLongText: boolean;
}

const expandButtonHeight = '19px';

const ExpandButton = styled.button.attrs({ type: 'button' })<IExpandButtonProps>`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: ${green};
  height: ${expandButtonHeight};
  margin-left: ${({ $isExpanded }) => ($isExpanded ? '0' : '30px')};
  font-size: 14px;
`;

const StyledTextExpand = {
  Wrapper,
  TextWrapper,
  ExpandButton,
  ExpandButtonWrapper,
};

export default StyledTextExpand;
