import { css } from 'styled-components';

type noScrollBarProps = {
  direction: 'x' | 'y';
};

export const NoScrollBar = ({ direction }: noScrollBarProps) => css`
  overflow-x: ${direction === 'x' ? 'scroll' : 'hidden'};
  overflow-y: ${direction === 'y' ? 'scroll' : 'hidden'};
  /* ie 10+ */
  & {
    -ms-overflow-style: none;
  }

  /* фф (свойство больше не работает, других способов тоже нет)*/
  & {
    overflow: -moz-scrollbars-none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
