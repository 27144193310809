import { useStore } from '../../../../utils/IoC';
import { MapController } from '../../mobx/controllers';

const useMapActions = () => {
  const {
    centerMapOnPoint,
    displayPolygon,
    displayPolygonList,
    displayMarkerList,
    removePolygon,
    removePolygonList,
    removeMarker,
    removeMarkerList,
    changeMarkerStyle,
    addCreateMarkersOptions,
  } = useStore(MapController);

  return {
    centerMapOnElement: centerMapOnPoint,
    displayPolygon,
    displayPolygonList,
    displayMarkerList,
    removePolygon,
    removePolygonList,
    removeMarker,
    removeMarkerList,
    changeMarkerStyle,
    addCreateMarkersOptions,
  };
};

export default useMapActions;
