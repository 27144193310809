import { FC } from 'react';
import { observer } from 'mobx-react';

import { IChecklistsAttrToDraw } from '../../../../models';
import { ChecklistsFileAttr as FileAttr } from '../ChecklistsFileAttr';

interface IProps {
  attrToDraw: IChecklistsAttrToDraw;
}

const ChecklistsFileAttrByPositionContainer: FC<IProps> = ({ attrToDraw }) => {
  if (attrToDraw.initialModel.position?.separate) {
    return <FileAttr attrToDraw={attrToDraw} isSeparate />;
  }

  return <></>;
};

ChecklistsFileAttrByPositionContainer.displayName = 'ChecklistsFileAttrByPositionContainer';

export default observer(ChecklistsFileAttrByPositionContainer);
