import styled, { keyframes } from 'styled-components';

export const NoSeasonsPlaceHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    & > img {
        padding: 10px;
        margin-right: 12px;
    }
`;

export const NoSeasonsPlaceHolderTextBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const NoSeasonsText1 = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`;

export const NoSeasonsText2 = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: #9AA0AD;
`;