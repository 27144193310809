import { FC, PropsWithChildren, useRef } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../utils/IoC';
import { TableBuilderStore as Store } from '../../mobx/stores';
import { useTableBuilderDataTestId as useDataTestId } from '../../hooks';
import { ContentLoader } from '../../../UI/loaders/ContentLoader';
import { useTableBuilderUIContext as useUIContext } from '../../context/UI/TableBuilderUIContext/hooks';

import Styled from './TableBuilderPlugContainer.styles';

const TableBuilderPlugContainer: FC<PropsWithChildren<{ isIgnoreFetching?: boolean }>> = ({
  children,
}) => {
  const store = useStore(Store);
  const UIContext = useUIContext();

  const getDataTestId = useDataTestId(UIContext.builderId);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const isFetchingElements = store.getIsFetchingElements(UIContext.builderId);
  const loaderDataTestId = getDataTestId('content-loader')['data-test-id'];

  return (
    <Styled.Wrapper ref={wrapperRef} {...getDataTestId('plug-wrapper')}>
      {isFetchingElements ? (
        <ContentLoader
          parentRef={wrapperRef}
          overlayStyles={{ borderRadius: '16px', zIndex: '3' }}
          dataTestId={loaderDataTestId}
        />
      ) : null}

      {children}
    </Styled.Wrapper>
  );
};

TableBuilderPlugContainer.displayName = 'TableBuilderPlugContainer';

export default observer(TableBuilderPlugContainer);
