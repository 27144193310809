import { observer } from 'mobx-react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, DropdownButton, TButtonMultiAction, useModal } from '@farmlink/farmik-ui';
import { generatePath, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { ButtonWrapper, Footer, Header, Label, StyledLabelWrapper, Wrapper } from '../../style';
import { ReactComponent as NoSeasonsIconSvg } from '../../static/map-add-entity-mock.svg';
import { useStore } from '../../../../../shared/utils/IoC';
import { FieldsStore } from '../../stores/fields.store';
import { FieldItem } from '../../components/FieldItem/FieldItem';
import MarkerSvg from '../../../../../shared/static/map.mark.svg';
import { FieldsRoute } from '../../fields.route';
import MapStore from '../../../../../map/stores/map.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { CreateSeasonModal } from '../../../seasons/modals/CreateSeasonModal/CreateSeasonModal';
import { ProfileStore } from '../../../profile/stores/ProfileStore';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../../components/AccessRulesWrapper/ControlAccessRulesWrapper';
import { FieldsListingController } from '../../controllers/fields.listing.controller';
import { FieldsController } from '../../controllers/fields.controller';
import { LeaveUnitModalStore } from '../../../../stores/leave.unit.modal.store';
import { UiStore } from '../../../../stores/ui.store';
import { PopupPages } from '../../../../constants/popup.pages';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import alertSvg from '../../static/multipleButtonIcons/alert-black-16x16.svg';
import plusSvg from '../../static/multipleButtonIcons/plus-black-16x16.svg';
import downloadSvg from '../../static/multipleButtonIcons/download-black-16x16.svg';
import {
  EFieldsImportModalName,
  fieldsImportModalConfigList,
  FIELDS_IMPORT_MODALS_UNIQUE_KEY,
} from '../../modals/import/configs/fieldsImportModalsConfig';
import { FieldsImportController } from '../../controllers/import';
import { importedFieldsSessionStorageHelpers } from '../../utils/helpers/import';
import { Field } from '../../../../../../api/models/field.model';
import { CREATE_SEASON_MODAL_ID } from '../../../seasons/modals/CreateSeasonModal/CreateSeasonModal.config';

import {
  ListWrapper,
  NoFieldsDescription,
  NoFieldsImage,
  NoFieldsTitle,
  NoFieldsWrapper,
  NoSeasonsDescription,
  NoSeasonsTitle,
  NoSeasonsWrapper,
} from './style';

export const FieldsListing: FC<{ visible?: boolean }> = observer(({ visible = true }) => {
  const fieldsListingController = useStore(FieldsListingController);

  const fieldsStore = useStore(FieldsStore);
  const fieldsController = useStore(FieldsController);
  const mapStore = useStore(MapStore);
  const seasons = useStore(SeasonsStore);
  const profile = useStore(ProfileStore);
  const leaveUnitModalStore = useStore(LeaveUnitModalStore);
  const uiStore = useStore(UiStore);
  const organizations = useStore(OrganizationsStore);
  const fieldListClickDetected = useRef<boolean>(false);

  const { registerModalList, openModalByModalId } = useModal();

  const [isSeasonCreationModalOpen, setIsSeasonCreationModalOpen] = useState(false);

  const hasSelectedSeason = Boolean(seasons.selectedSeason);

  const { fields, isLoading, selectedFieldId } = fieldsStore;

  const fieldsImportController = useStore(FieldsImportController);
  const { fetchImportedList } = fieldsImportController;

  const {
    setFieldRef,
    startViewMode,
    deleteFieldById,
    deleteFieldFromCurrentSeason,
  } = fieldsController;

  const navigate = useNavigate();

  const fieldsCount = fields.length;

  useEffect(() => {
    registerModalList(fieldsImportModalConfigList, FIELDS_IMPORT_MODALS_UNIQUE_KEY);
  }, []);

  useEffect(() => {
    // todo может быть дубль запросы
    if (profile.user && seasons.selectedSeason) {
      fieldsListingController.fetchFieldsList();
    }

    const handleOutOfPortalBoundClick = () => {
      if (uiStore.currentPortal) uiStore.closePortal();
    };

    const handleScrollFields = () => {
      if (uiStore.currentPortal) uiStore.closePortal();
      if (uiStore.isTipOpen) uiStore.closeTip();
    };

    document.addEventListener('click', handleOutOfPortalBoundClick);
    document.addEventListener('wheel', handleScrollFields);

    startViewMode();
    return () => {
      fieldsListingController.clearListing();
      document.removeEventListener('click', handleOutOfPortalBoundClick);
      document.removeEventListener('wheel', handleScrollFields);
    };
  }, []);

  useEffect(() => {
    fieldsListingController.setMapModeToListining();
  }, [mapStore.instance]);

  useEffect(() => {
    if (selectedFieldId) {
      if (!fieldListClickDetected.current) scrollToActive();
      if (fieldListClickDetected.current) fieldListClickDetected.current = false;
    }
  }, [selectedFieldId]);

  const scroll = useRef<HTMLDivElement>();

  const scrollToActive = () => {
    if (!scroll || !scroll.current) return;
    const activeSeason = scroll.current.getElementsByClassName('active');
    if (!activeSeason[0]) {
      return;
    }
    // @ts-ignore
    const { top } = activeSeason[0].getBoundingClientRect();

    // @ts-ignore
    // scroll.current.scroll({
    //   top: top === 68 ? 0 : top,
    // });

    const scrollBounds = scroll.current.getBoundingClientRect();
    const scrollCenterPos = scrollBounds.height / 2 + scrollBounds.top;
    if (scrollCenterPos < top)
      scroll.current.scrollTo(0, scroll.current.scrollTop + Math.abs(scrollCenterPos - top) + 40);
    else
      scroll.current.scrollTo(0, scroll.current.scrollTop - Math.abs(scrollCenterPos - top) + 40);
  };
  // const editHandler = (id: string) => {
  //   editField();
  //   history.push(`${FieldsRoute.Edit}/${id}`);
  //   console.log('EDIT HANDLER');
  // };

  const isPopupPageStateCultureZone = useMemo(
    () => uiStore.popupPageState === PopupPages.CultureZone,
    [uiStore.popupPageState]
  );

  const handleClickOnFieldCreation = (): void => {
    const routePath = generatePath(FieldsRoute.Add, {
      orgId: organizations.selectedOrganizationId,
    });
    if (isPopupPageStateCultureZone) {
      leaveUnitModalStore.setDialogSettings({
        actionHandler: () => {
          leaveUnitModalStore.setIsShow(false);
          fieldsListingController.goToFieldCreating();
          navigate(routePath);
        },
        isShow: true,
      });
    } else {
      fieldsListingController.goToFieldCreating();
      navigate(routePath);
    }
  };

  const handleClickOnUploadFields = (): void => {
    if (isPopupPageStateCultureZone) {
      leaveUnitModalStore.setDialogSettings({
        actionHandler: () => {
          leaveUnitModalStore.setIsShow(false);
          openModalByModalId(EFieldsImportModalName.FileImport, { isSomethingWentWrong: false });
        },
        isShow: true,
      });
    } else {
      openModalByModalId(EFieldsImportModalName.FileImport, { isSomethingWentWrong: false });
    }
  };

  const fieldsImportRoute = useMemo<string>(() => {
    return generatePath(FieldsRoute.Import, {
      orgId: organizations.selectedOrganizationId,
    });
  }, [organizations.selectedOrganizationId]);

  const importedFieldsToken = importedFieldsSessionStorageHelpers.getToken(
    organizations.selectedOrganizationId,
    seasons.selectedSeason
  );

  const buttonMultiActionList: TButtonMultiAction[] = [
    {
      title: 'Продолжить с черновиком',
      description: 'Вернитесь к работе с загруженным файлом',
      onClick: async (): Promise<void> => {
        if (importedFieldsToken) {
          const isSuccess = await fetchImportedList(importedFieldsToken);

          if (isSuccess) {
            openModalByModalId(EFieldsImportModalName.Loader);

            navigate(fieldsImportRoute);
          } else {
            openModalByModalId(EFieldsImportModalName.FileImport, { isSomethingWentWrong: true });
          }
        }
      },
      icon: alertSvg,
      isHidden: !importedFieldsToken,
    },
    {
      title: 'Добавить поля',
      description: 'Соедините точки на карте для создания поля',
      onClick: () => {
        handleClickOnFieldCreation();
      },
      icon: plusSvg,
    },
    {
      title: 'Загрузить файлы',
      description: 'Добавьте контуры полей в форматах KML, geoJSON, shape',
      onClick: () => {
        handleClickOnUploadFields();
      },
      icon: downloadSvg,
    },
  ];

  const sortedFieldsList = useMemo<Field[]>(() => {
    return _.sortBy(fields, f => f?.name?.toLowerCase());
  }, [fields]);

  return (
    <Wrapper visible={visible} data-test-id={'fields-list-section'}>
      <Header>
        <StyledLabelWrapper>
          <Label data-test-id={'fields-list-section-label'}>Поля</Label>
        </StyledLabelWrapper>
        <div />
        {/* {isLoading ? (
        ) : fields.length > 0 ? (
          <EditIcon data-test-id={'fields-list-section-edit'}>
            <EditSvg />
          </EditIcon>
        ) : (
          <div />
        )} */}
      </Header>
      {isSeasonCreationModalOpen && (
        <CreateSeasonModal closeModal={() => setIsSeasonCreationModalOpen(false)} />
      )}
      {hasSelectedSeason ? (
        <ListWrapper data-test-id={'fields-list-section-wrapper'} ref={scroll}>
          {isLoading ? null : fields.length > 0 ? (
            sortedFieldsList.map((field, index) => {
              return (
                <FieldItem
                  className={field.id === fieldsStore.selectedFieldId && 'active'}
                  dataTestId={`fields-list-section-item-${index}`}
                  key={field.id}
                  fieldModel={field}
                  onClick={() => {
                    fieldListClickDetected.current = true;
                    fieldsListingController.selectField(field.id);
                  }}
                  setRef={setFieldRef}
                  isActive={selectedFieldId === field.id}
                  isLast={index === fieldsCount - 1}
                  deleteField={async () => {
                    await deleteFieldById(field.id);
                    mapStore.deletePolygon(Number(field.id));
                  }}
                  onEdit={() => 1}
                  // onEdit={editHandler}
                  deleteFieldFromCurrentSeason={deleteFieldFromCurrentSeason}
                />
              );
            })
          ) : (
            <NoFieldsWrapper data-test-id={'fields-list-no-fields'}>
              <NoFieldsImage src={MarkerSvg} />
              <NoFieldsTitle>У вас ещё нет полей</NoFieldsTitle>
              <NoFieldsDescription>
                Добавьте поля, чтобы получить доступ к снимкам вегетации и данным погоды
              </NoFieldsDescription>
            </NoFieldsWrapper>
          )}
        </ListWrapper>
      ) : (
        <NoSeasonsWrapper data-test-id={'fields-list-no-seasons'}>
          <NoSeasonsIconSvg />
          <NoSeasonsTitle>У вас ещё нет сезонов</NoSeasonsTitle>
          <NoSeasonsDescription>
            Добавьте сезон, чтобы получить возможность добавлять поля
          </NoSeasonsDescription>
        </NoSeasonsWrapper>
      )}

      <Footer>
        {hasSelectedSeason ? (
          <ControlAccessRulesWrapper
            mode={AccessRuleVisibility.Hide}
            actionName="field.createFieldOrSeason"
          >
            <DropdownButton
              onClick={handleClickOnFieldCreation}
              title={'Добавить поле'}
              disabled={isPopupPageStateCultureZone}
              multiActionList={buttonMultiActionList}
              style={{ color: 'primary' }}
              dataTestId={'add-field-button'}
            />
          </ControlAccessRulesWrapper>
        ) : (
          <ControlAccessRulesWrapper
            mode={AccessRuleVisibility.Hide}
            actionName="field.createFieldOrSeason"
          >
            <ButtonWrapper>
              <Button
                color={'primary'}
                type={'button'}
                onClick={() => openModalByModalId(CREATE_SEASON_MODAL_ID)}
                dataTestId={'add-season-button'}
              >
                Добавить сезон
              </Button>
            </ButtonWrapper>
          </ControlAccessRulesWrapper>
        )}
      </Footer>
    </Wrapper>
  );
});
