import styled, { css } from 'styled-components';

import { Colors } from '../../../constans/colors';

import { ReactComponent as InfoIconSvg } from './assets/images/info-default-16x16.svg';
import StyledDropdownBody from './components/DropdownBody/DropdownBody.styles';
import StyledDropdownIcons from './components/DropdownIcons/DropdownIcons.styles';
import { TDropdownProps } from './Dropdown.types';

type TDropdownAutocompleteProps = {
  $width: number;
};

const Autocomplete = styled.span<TDropdownAutocompleteProps>`
  width: ${({ $width }) => `${$width}px` || '100%'};
  color: ${Colors.secondaryDarkGray};
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  padding-left: 2px;
  padding-top: 2px;

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      padding-left: 1px;
      padding-top: 3px;
    }
  }
  /* Test website on real Safari 11+ */

  /* Safari 10.1 */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
      padding-left: 1px;
      padding-top: 3px;
    }
  }

  /* Safari 6.1-10.0 (but not 10.1) */
  @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
    @media {
      padding-left: 1px;
      padding-top: 3px;
    }
  }
  white-space: nowrap;
  overflow: hidden;
  z-index: 98;
  pointer-events: none;
`;

const AutocompleteMatchedValue = styled.span`
  color: ${Colors.generalBlack};
`;

const LabelInfoIcon = styled(InfoIconSvg)`
  width: 14px;
  height: 14px;
  margin: 0 4px 4px 4px;
  cursor: pointer;
`;

type TStyledDropdownLabelProps = {
  $isRequired: boolean;
};

const Label = styled.p<TStyledDropdownLabelProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 4px;
  color: ${Colors.secondaryDarkGray};

  &:after {
    color: red;
    visibility: ${({ $isRequired }) => ($isRequired ? 'visible' : 'hidden')};
    content: '*';
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchFieldWrapper = styled.div`
  flex: 1 0 auto;
  position: relative;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1 1 50%;
`;

type TStyledDropdownFieldWrapperProps = {
  $isCentered: boolean;
  $styles?: TDropdownProps['config']['styles'];
};

const centerValueContainer = (condition: boolean) => {
  if (condition) {
    return css`
      & ${StyledDropdownIcons.Wrapper} {
        align-self: center;
      }
    `;
  }
};

const FieldWrapper = styled.div<TStyledDropdownFieldWrapperProps>`
  background: ${Colors.secondaryGray};
  border-radius: ${({ $styles }) => $styles?.borderRadius || '12px'};
  border: 1px solid ${Colors.borderGray};
  box-sizing: border-box;
  display: flex;
  padding: ${({ $styles }) => $styles?.fieldPadding || '9px 16px'};
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  ${({ $isCentered }) => centerValueContainer($isCentered)}
  ${({ $styles }) => $styles?.fieldMinHeight && `min-height: ${$styles?.fieldMinHeight};`};

  &:hover {
    border-color: ${Colors.secondaryDarkGray};
  }
`;

type StyledDropdownWrapperProps = {
  $isDropped: boolean;
  $isError: boolean;
  $isBlocked: boolean;
  $isDisabled: boolean;
  $styles?: TDropdownProps['config']['styles'];
};

const Wrapper = styled.div<StyledDropdownWrapperProps>`
  position: relative;
  outline: none;
  user-select: none;

  ${({ $isDisabled, $isBlocked }) =>
    ($isDisabled || $isBlocked) &&
    css`
      & ${FieldWrapper} {
        pointer-events: none;

        :hover {
          border-color: ${Colors.borderGray};
        }
      }
    `};

  & * {
    box-sizing: border-box;
  }

  ${({ $isBlocked }) =>
    $isBlocked &&
    css`
      ${FieldWrapper} {
        background: ${Colors.generalWhite};
      }
    `}

  ${({ $isDropped, $styles }) =>
    $isDropped &&
    css`
      & ${FieldWrapper} {
        border-radius: ${$styles?.borderRadius || '12px'} ${$styles?.borderRadius || '12px'} 0 0;
        border-color: ${Colors.secondaryDarkGray};
        border-bottom-color: transparent;
      }

      & ${StyledDropdownBody.Wrapper} {
        border-color: ${Colors.secondaryDarkGray};
      }
    `}

  ${({ $isError, $isDropped }) =>
    $isError &&
    css`
      & ${FieldWrapper} {
        border-color: ${Colors.red};

        ${$isDropped &&
        css`
          border-bottom-color: transparent;
        `}
      }

      & ${StyledDropdownBody.Wrapper} {
        border-color: ${Colors.red};
      }
    `}
`;

const StyledDropdown = {
  Autocomplete,
  AutocompleteMatchedValue,
  LabelInfoIcon,
  Label,
  LabelWrapper,
  SearchFieldWrapper,
  ValueContainer,
  FieldWrapper,
  Wrapper,
};

export default StyledDropdown;
