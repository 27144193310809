import { TaskStatuses } from '../../../../modules/dashboard/modules/operations/stores/tasks.store';
import {
  EBiImportDelimiterType,
  EBiImportFormat,
} from '../../../../modules/dashboard/modules/operationsAndTasks/utils/constants/BiImportEnums';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  organizationId: string;
  dateFrom: string;
  dateTo: string;
  seasonYear: number;
  assigneeId?: string;
  fieldId?: string;
  statusIn?: Array<TaskStatuses>;
  cultureId?: string;
  withId?: boolean;
  format?: EBiImportFormat;
  charsetName?: string;
  delimiterType?: EBiImportDelimiterType;
};

type TypeResponse = {};

export const getPowerBiReportCsvFile: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/reports/power-bi/csv`,
  method: 'GET',
  responseType: 'blob',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
