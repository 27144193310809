import { ChangeEvent, forwardRef, memo, useMemo } from 'react';

import { Tooltipster } from '../../../../../../../../../shared/components/Tooltipster/Tooltipster';

import Styled from './styles';
import { TInputProps } from './types';

const MAXIMAL_LENGTH_DEFAULT = 100;

const Input = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      onChange,
      label,
      isRequired,
      className,
      isDisabled,
      isBlocked,
      value,
      placeholder,
      type,
      error,
      isWithoutErrorText,
      maxLength,
      dataTestId,
      id,
      tooltip,
      onBlur,
      as = 'input',
      isResizable,
      height,
      hideScrollbar,
      Icon,
    },
    ref
  ) => {
    const IconToDraw = useMemo(() => {
      if (as === 'textarea') return;

      if (Icon) {
        return (
          <Styled.IconWrapper $isLabel={Boolean(label)} $align={'end'}>
            {Icon}
          </Styled.IconWrapper>
        );
      }

      if (isBlocked) {
        return (
          <Styled.IconWrapper $isLabel={Boolean(label)} $align={'end'}>
            <Styled.IconMinus />
          </Styled.IconWrapper>
        );
      }
    }, [isBlocked, as, Icon]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
      onChange?.(event.target.value.toString());
    };

    return (
      <Styled.Wrapper>
        <Styled.TopBlock>
          {label && (
            <Styled.LabelWrapper>
              <Styled.Label required={isRequired}>{label}</Styled.Label>
              {tooltip && (
                <>
                  <Tooltipster id={`tooltip-${id}`} title={tooltip} placement={'top-start'}>
                    <Styled.IconInfo />
                  </Tooltipster>
                </>
              )}
            </Styled.LabelWrapper>
          )}
        </Styled.TopBlock>
        <Styled.InputField
          className={className}
          disabled={isDisabled || isBlocked}
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={handleChange}
          maxLength={maxLength ? maxLength : MAXIMAL_LENGTH_DEFAULT}
          data-test-id={dataTestId}
          id={id}
          ref={ref}
          onBlur={onBlur}
          as={as}
          autoComplete={'off'}
          $error={error}
          $isBlocked={isBlocked}
          $isResizable={isResizable}
          $height={height}
          $hideScrollbar={hideScrollbar}
        />

        {IconToDraw}

        {error && !isWithoutErrorText && (
          <Styled.ErrorMessage data-test-id={`${dataTestId}_errorMessage`}>
            {error}
          </Styled.ErrorMessage>
        )}
      </Styled.Wrapper>
    );
  }
);

Input.displayName = 'Input';

export default memo(Input);
