import { FC, useRef, useCallback } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Colors, IconButton } from '@farmlink/farmik-ui';

import { isImgUrl } from '../../../../../../../../../shared/utils/helpers';
import { BorderRadius } from '../../../../../../../../../shared/constans/sizes';

import {
  ActionClose,
  ActionDelete,
  ActionDownload,
  ActionsBlock,
  ImageBlock,
  ImageCarouselBlock,
  LargeImage,
  Overlay,
  Wrapper,
} from './style';
import CloseIcon from './assets/Close.svg';
import DeleteIcon from './assets/Delete.svg';
import DownloadIcon from './assets/Download.svg';

export interface ImageCarouselProps {
  onSwitchLeft: () => void;
  onSwitchRight: () => void;
  imgUrl: string;
  isBlocked?: boolean;
  onClose: () => void;
  onDelete: () => void;
  isDeletionModalOpen?: boolean;
}

const ARROW_BUTTON_PROPS = {
  $borderRadius: BorderRadius.small,
  size: 40,
  $padding: '12px',
  colorScheme: {
    default: {
      background: Colors.secondaryGray,
      icon: Colors.secondaryDarkGray,
    },
    hover: {
      background: Colors.secondaryGray,
      icon: Colors.black,
    },
  },
};

export const ImageCarousel: FC<ImageCarouselProps> = ({
  onSwitchLeft,
  onSwitchRight,
  imgUrl,
  isBlocked,
  onClose,
  onDelete,
  isDeletionModalOpen,
}) => {
  const getBlob = (value: string) => {
    const BASE64_MARKER = ';base64,';
    const parts = value.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; i++) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  };

  const onDownloadClick = () => {
    const blob = getBlob(imgUrl);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'file';

    a.style.display = 'none';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        removeEventListener('click', clickHandler);
      }, 150);
    };

    a.addEventListener('click', clickHandler, false);
    a.click();
  };

  const onClickOutsideRef = useCallback(
    useOnclickOutside(() => {
      if (!isDeletionModalOpen) onClose();
    }),
    []
  );

  return (
    <Wrapper>
      <Overlay>
        <ImageCarouselBlock ref={onClickOutsideRef}>
          <IconButton icon="arrow_left" {...ARROW_BUTTON_PROPS} onClick={onSwitchLeft} />
          <ImageBlock>
            <ActionsBlock>
              {!isBlocked && <ActionDelete src={DeleteIcon} onClick={onDelete} />}
              {isImgUrl(imgUrl) ? (
                <a href={imgUrl}>
                  <ActionDownload src={DownloadIcon} />
                </a>
              ) : (
                <ActionDownload src={DownloadIcon} onClick={onDownloadClick} />
              )}
              <ActionClose src={CloseIcon} onClick={onClose} />
            </ActionsBlock>
            <LargeImage src={imgUrl} />
          </ImageBlock>
          <IconButton icon="arrow_right" {...ARROW_BUTTON_PROPS} onClick={onSwitchRight} />
        </ImageCarouselBlock>
      </Overlay>
    </Wrapper>
  );
};
