import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../utils/IoC';

@provide.singleton()
class PermissionsBuilderStore<P = string, B = string> {
  /**
   * Коллекция доступов и разрешений [P] согласно сущности (билдеру) [B].
   */
  private _permissionsByBuilderId: Map<B, Set<P>> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  hasPermission = (builderId: B, permission: P): boolean => {
    const permissions = this._permissionsByBuilderId.get(builderId);

    return permissions?.has?.(permission);
  };

  getPermissionList = (builderId: B): P[] => {
    const permissions = this._permissionsByBuilderId.get(builderId);

    if (permissions) {
      return [...permissions.values()];
    }

    return [];
  };

  setPermissionList = (
    builderId: B,
    permissionList: P[],
    options?: {
      isClearPreviousList?: boolean;
    }
  ): void => {
    const previousCollection = this._permissionsByBuilderId.get(builderId);

    const previousList = options?.isClearPreviousList
      ? []
      : [...(previousCollection?.values?.() || [])];

    const newCollection = new Set<P>([...previousList, ...permissionList]);

    this._permissionsByBuilderId.set(builderId, newCollection);
  };

  deletePermissions = (builderId: B): void => {
    this._permissionsByBuilderId.delete(builderId);
  };
}

export default PermissionsBuilderStore;
