import { TypeApiRoute } from '../../../models/type.api.request';
import { EReferenceType, IReference } from '../../../models/as-fields/references/Reference.model';

type TRequest = {
  size?: number;
  page?: number;
  path?: string;
  parentPath?: string;
  type?: EReferenceType;
  active?: boolean;
};

type TResponse = { content: IReference[] };

export const getReferenceList: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/references/list`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
