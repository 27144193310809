import { FC, memo } from 'react';

import { IExtendedAverageValue } from '../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

import Styled from './AverageAttributeCulledItem.styles';

interface IProps {
  item: IExtendedAverageValue;
  onDeleteItem: (item: IExtendedAverageValue) => void;
  isBlocked?: boolean;
}

const AverageAttributeCulledItem: FC<IProps> = ({ item, isBlocked, onDeleteItem }) => {
  const isValueTooLong = String(item.value).length > 3;

  return (
    <Styled.Wrapper $isBlocked={isBlocked} $isValueTooLong={isValueTooLong}>
      <Styled.Value>{item.value}</Styled.Value>

      {!isBlocked ? <Styled.Clear onClick={() => onDeleteItem(item)} /> : null}
    </Styled.Wrapper>
  );
};

AverageAttributeCulledItem.displayName = 'AverageAttributeCulledItem';

export default memo(AverageAttributeCulledItem);
