import { makeAutoObservable } from 'mobx';

import { IGetImportedField } from '../../../../../../api/models/fields/getImportedField.model';
import { provide } from '../../../../../shared/utils/IoC';
import { EFieldsImportModalName } from '../../modals/import/configs/fieldsImportModalsConfig';

@provide.singleton()
class FieldsImportStore {
  private _isDataBeingProcessed = false;

  private _idToImportedField: Map<string, IGetImportedField> = new Map<string, IGetImportedField>();

  private _focusTargetId: string | null = null;

  private _isFieldFocused = false;

  private _saveErrorModal: EFieldsImportModalName | null = null;

  private _isWaitingForEditRes = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isDataBeingProcessed() {
    return this._isDataBeingProcessed;
  }

  get listOfImportedField() {
    return Array.from(this._idToImportedField.values());
  }

  get focusTargetId() {
    return this._focusTargetId;
  }

  get isFieldFocused() {
    return this._isFieldFocused;
  }

  get saveErrorModal() {
    return this._saveErrorModal;
  }

  get isWaitingForEditRes() {
    return this._isWaitingForEditRes;
  }

  setIsDataBeingProcessed = (value: boolean): void => {
    this._isDataBeingProcessed = value;
  };

  setFocusTargetId = (id: string): void => {
    this._focusTargetId = id;
  };

  setIsFieldFocused = (value: boolean): void => {
    this._isFieldFocused = value;
  };

  setIdToImportedField = (listOfImportedField: IGetImportedField[] = []): void => {
    const newIdToImportedField: Map<string, IGetImportedField> = new Map<
      string,
      IGetImportedField
    >();

    if (!listOfImportedField?.length) {
      return;
    }

    listOfImportedField.forEach(field => newIdToImportedField.set(field.id, field));

    this._idToImportedField = newIdToImportedField;
  };

  setSaveErrorModal = (errorName: EFieldsImportModalName): void => {
    this._saveErrorModal = errorName;
  };

  setIsWaitingForEditRes = (val: boolean): void => {
    this._isWaitingForEditRes = val;
  };

  clearIsDataBeingProcessed = (): void => {
    this._isDataBeingProcessed = false;
  };

  clearIdToImportedField = (): void => {
    this._idToImportedField.clear();
  };

  clearFocusTargetId = (): void => {
    this._focusTargetId = null;
  };

  clearIsFieldFocused = (): void => {
    this._isFieldFocused = false;
  };

  clearSaveErrorModal = (): void => {
    this._saveErrorModal = null;
  };

  clearIsWaitingForEditRes = (): void => {
    this._isWaitingForEditRes = false;
  };

  clearStore = (): void => {
    this.clearIsDataBeingProcessed();
    this.clearSaveErrorModal();
    this.clearIsWaitingForEditRes();

    this.clearFocusTargetId();
    this.clearIsFieldFocused();

    this.clearIdToImportedField();
  };
}

export default FieldsImportStore;
