import styled from 'styled-components';

import { FontSizes } from '../../../../../shared/constans/sizes';
import { Colors } from '../../../../../shared/constans/colors';

export const Field = styled.div<{ isActive: boolean }>`
  display: flex;
  padding: 16px;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  cursor: pointer;
  position: relative;
  background: ${({ isActive }) => (isActive ? Colors.lightGray : 'transparent')};
  &:hover {
    background: rgba(0, 0, 0, 0.01);
  }

  &:before {
    content: '';
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    width: 4px;
    height: 100%;
    background: ${Colors.green};
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0px 5px 5px 0px;
  }
`;

export const FieldImg = styled.img.attrs(props => ({
  src: props.src,
}))`
  ${({ src }) => {
    if (!src) return `display: none;`;
  }}
  width: 40px;
  height: 40px;
  /* border: 0.5px solid #e9e6f0; */
  border-radius: 12px;
  margin-right: 8px;
  /* background: url('http://dev1.assistance.da.local/api/da-files/files/78ad327e8c183b3b0c704cd8188f100b15769e414899f0fa')
      no-repeat,
    #e9e6f0; */
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
`;

export const FieldName = styled.p`
  ${FontSizes.medium};
  overflow: hidden;
  white-space: nowrap;
  margin: 0 12px 0 0;
  text-overflow: ellipsis;
  margin: 0 12px 0 0;
`;

export const FieldTasks = styled.p`
  color: ${Colors.darkGray};
  ${FontSizes.default};
  margin: 5px 0 0 0;
`;

export const FieldArea = styled.div`
  ${FontSizes.medium};
  white-space: nowrap;
`;

export const Spacer = styled.div`
  flex: 1 1 0;
`;

export const FieldInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
`;
