import { FC, memo, PropsWithChildren } from 'react';

import Styled from './ChecklistsButton.styles';

interface IProps {
  onClick: () => void;
}

const ChecklistsButton: FC<PropsWithChildren<IProps>> = ({ onClick, children }) => {
  return (
    <Styled.Wrapper type={'button'} onClick={onClick}>
      {children}
    </Styled.Wrapper>
  );
};

ChecklistsButton.displayName = 'ChecklistsButton';

export default memo(ChecklistsButton);
