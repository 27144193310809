import { EFieldsUploadErrorType } from '../../../../modules/dashboard/modules/fields/utils/constants/import';
import { TypeApiRoute } from '../../../models/type.api.request';

type TImportFieldsFromZipShapeReq = {
  seasonYear: number;
  file: FormData;
  organizationId?: string;
};

type TImportFieldsFromZipShapeRes = {
  token: 'string';
  errorType?: EFieldsUploadErrorType;
  errorDescription?: 'string';
};

const importFieldsFromZipShape: TypeApiRoute & {
  request: TImportFieldsFromZipShapeReq;
  response: TImportFieldsFromZipShapeRes;
} = {
  url: () => '/api/as-fields/import/zipshp',
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  request: {} as TImportFieldsFromZipShapeReq,
  response: {} as TImportFieldsFromZipShapeRes,
};

export type { TImportFieldsFromZipShapeReq, TImportFieldsFromZipShapeRes };

export { importFieldsFromZipShape };
