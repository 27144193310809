import { FC } from 'react';

import Styled from './FieldsImportLoader.styles';

const FieldsImportLoader: FC = () => {
  return (
    <Styled.Wrapper>
      <Styled.LoaderWrapper>
        <Styled.Loader>
          <Styled.CenterCircle />
        </Styled.Loader>
      </Styled.LoaderWrapper>
    </Styled.Wrapper>
  );
};

export default FieldsImportLoader;
