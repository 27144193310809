import styled from 'styled-components';

import startIcon from '../../../assets/icons/start.svg';
import finishIcon from '../../../assets/icons/finish.svg';
import clockIcon from '../../../assets/icons/clockAccentPink.svg';
import checkIcon from '../../../assets/icons/check.svg';
import calendarIcon from '../../../assets/icons/calendar.svg';

type TIcon = 'start' | 'finish' | 'clock' | 'check' | 'calendar';

const getIcon = (icon: TIcon) => {
  switch (icon) {
    case 'start':
      return startIcon;
    case 'finish':
      return finishIcon;
    case 'clock':
      return clockIcon;
    case 'check':
      return checkIcon;
    case 'calendar':
      return calendarIcon;
    default:
      return '';
  }
};

const Icon = styled.div<{ $icon: TIcon }>`
  width: 16px;
  height: 16px;

  background: url(${({ $icon }) => getIcon($icon)});
`;

const Title = styled.span<{ $isAccentPink?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: ${({ $isAccentPink }) => ($isAccentPink ? '#DF2E22' : '#151f32')};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const StyledTasksListDaysLeftCellToday = {
  Icon,
  Title,
  Wrapper,
};

export default StyledTasksListDaysLeftCellToday;
