import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import {
  EChecklistAttributeType as EAttrType,
  IGetChecklistAttribute as IAttribute,
  IGetChecklistAttributeValue as IAttributeValue,
} from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { TChecklistsFileAttrToDraw as TFileAttrToDraw } from '../../../../models';
import { ChecklistsStore } from '../../../stores';
import { ChecklistsAttrsFormulasService } from '../../ChecklistsAttrsFormulasService';
import { EChecklistMode } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { getChecklistsAttrRequiredText as getAttrRequiredText } from '../../../../helpers';
import { Axios, TypeApiResponse } from '../../../../../../../../../shared/utils/axios2';

@provide.transient()
class ChecklistsFileAttrsService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(ChecklistsAttrsFormulasService)
  protected formulasService: ChecklistsAttrsFormulasService;

  createAttrToDraw = (
    groupId: string,
    attr: IAttribute,
    initialValueList: IAttributeValue[],
    options?: {
      isBlocked?: boolean;
      nestedInstanceId?: string;
    }
  ): TFileAttrToDraw => {
    const attrToDraw: TFileAttrToDraw = {
      id: attr.id,
      groupId,
      isVisible: true,
      order: attr.order,
      initialModel: attr,
      value: {
        checkListAttributeId: attr.id,
        fileValue: [],
      },
      options: {
        extendedFileList: [],
      },
      isBlocked: options?.isBlocked || this.checklistsStore.mode === EChecklistMode.View,
      validationScheme: {
        isShowError: false,
        errorTitle: getAttrRequiredText(EAttrType.FileLink),
      },
      isEdited: false,
      nestedInstanceId: options?.nestedInstanceId,
    };

    const initialValueOfThisAttr = initialValueList.find(
      ({ checkListAttributeId }) => checkListAttributeId === attrToDraw.id
    );

    if (initialValueOfThisAttr) {
      const formattedFileValue = initialValueOfThisAttr.fileValue.map(value => value.id);

      attrToDraw.value = {
        ...attrToDraw.value,
        fileValue: formattedFileValue,
      };

      attrToDraw.options.extendedFileList = initialValueOfThisAttr.fileValue;
    }

    return attrToDraw;
  };

  changeValue = (groupId: string, value: TFileAttrToDraw['value']): void => {
    this.checklistsStore.updateAttrToDraw(groupId, value.checkListAttributeId, {
      value,
      isEdited: true,
    });

    this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.FileLink>(
      groupId,
      value.checkListAttributeId,
      {
        isShowError: false,
      }
    );
  };

  uploadFile = async (file: File): Promise<TypeApiResponse<'uploadFile'>> => {
    try {
      const uploadedData = await this.axiosService.api.uploadFile({
        fileContentType: file.type,
        fileName: file.name,
        fileSize: file.size,
        serviceName: 'da-profile',
      });

      await this.axiosService.api.uploadFileFinish({
        data: file,
        url: uploadedData.uploadUrl,
      });

      return uploadedData;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default ChecklistsFileAttrsService;
