import { TaskStatuses } from '../../../modules/dashboard/modules/operations/stores/tasks.store';
import { Task } from '../../../modules/dashboard/modules/tasks/stores/tasks.store';
import { TypeApiRoute } from '../../models/type.api.request';

type TCreateMultipleTasksReq = {
  planStartDate: string;
  planEndDate: string;
  comment: string;
  status: TaskStatuses;
  operationId: string;
  assigneeId: string;
  fieldId: string[];
  externalId?: string;
  cancellationReason?: string;
};

type TCreateMultipleTasksRes = Task[];

const createMultipleTasks: TypeApiRoute & {
  request: TCreateMultipleTasksReq;
  response: TCreateMultipleTasksRes;
} = {
  url: () => '/api/as-fields/tasks',
  method: 'POST',
  headers: {},
  request: {} as TCreateMultipleTasksReq,
  response: {} as TCreateMultipleTasksRes,
};

export type { TCreateMultipleTasksReq, TCreateMultipleTasksRes };

export { createMultipleTasks };
