// import { PageNotFound } from '@farmlink/farmik-ui';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { FC, memo, useEffect } from 'react';

import { Agreement } from '../pages/agreement/Agreement';
import { withBrowserTitle } from '../hocs/withBrowserTitle/withBrowserTitle';

import { AdminView } from './admin-view/AdminView';
import {
  AuthorizationWrapper,
  ExternalRedirect,
} from './authorization/containters/AuthorizationWrapper';
import { DashboardRouter } from './dashboard/dashboard.router';
import { ApplicationRoutes } from './routes';
import TaskViewPage from './dashboard/modules/tasks/modules/TaskView/TaskViewPage';
import TaskEditPage from './dashboard/modules/tasks/modules/TaskEdit/TaskEdit';
import { TasksRoute } from './dashboard/modules/tasks/tasks.route';
import { OperationsListing } from './dashboard/modules/operations/containers/listing/operations.listing';
import { FieldsRoute } from './dashboard/modules/fields/fields.route';
import { FieldsAdd } from './dashboard/modules/fields/containers/add/fields.add';
import OrganizationRouter from './dashboard/organization.router';
import { FieldsRouter } from './dashboard/modules/fields/fields.router';
import FieldsImportContainer from './dashboard/modules/fields/containers/import/FieldsImportContainer';
import { FieldsEdit } from './dashboard/modules/fields/containers/edit/fields.edit';
import { TaskMiddleware } from './dashboard/modules/tasks/modules/TaskMiddleware';
import { Tasks } from './dashboard/modules/tasks/containers/Tasks';
import TaskCreate from './dashboard/modules/tasks/modules/TaskCreate/TaskCreate';
import TaskCreateOne from './dashboard/modules/tasks/modules/TaskCreateOne/TaskCreateOne';
import TaskCreateGroup from './dashboard/modules/tasks/modules/TaskCreateGroup/TaskCreateGroup';
import { EErrorStatusName } from './shared/constans/errorStatus';
import PageNotFound from './shared/components/PageNotFound/PageNotFound';

const FieldsWithTitle = withBrowserTitle('Поля')(FieldsRouter);
const OperationsWithTitle = withBrowserTitle('Операции')(OperationsListing);
const TasksWithTitle = withBrowserTitle('Задачи')(Tasks);

const AppRouter: FC<{
  needAdditionalInfo: boolean;
  redirectPath: string;
  registrationStep2Url: string;
}> = ({ needAdditionalInfo, redirectPath, registrationStep2Url }) => {
  const navigate = useNavigate();

  const authPath =
    process.env?.NODE_ENV === 'development'
      ? ApplicationRoutes.ExternalAuthorizationDev
      : `${window._env_.AUTH_URL}${ApplicationRoutes.ExternalAuthorization}`;

  useEffect(() => {
    if (window?.location?.search?.includes('authKey')) {
      navigate(redirectPath);
    }
  }, []);

  return (
    <AuthorizationWrapper excludedRoutes={[]} isZoneForAuthorized={true} redirectTo={authPath}>
      <Routes>
        <Route path="/" element={<DashboardRouter />}>
          <Route path=":orgId" element={<OrganizationRouter />}>
            <Route path="fields" element={<FieldsWithTitle />}>
              <Route path={FieldsRoute.Add} element={<FieldsAdd />} />
              <Route path={FieldsRoute.Import} element={<FieldsImportContainer />} />
              <Route path={FieldsRoute.Edit} element={<FieldsEdit />} />
            </Route>

            <Route path="operations" element={<OperationsWithTitle />} />

            <Route path="tasks">
              <Route path={TasksRoute.Listing} element={<TasksWithTitle />} />
              <Route path={TasksRoute.Task} element={<TaskMiddleware />}>
                <Route path={TasksRoute.Task} element={<TaskViewPage />} />
                <Route path={TasksRoute.TaskMap} element={<TaskViewPage />} />

                <Route path={TasksRoute.TaskMapCreatePoint} element={<TaskEditPage />} />

                <Route path={TasksRoute.TaskEdit} element={<TaskEditPage />} />

                <Route path={TasksRoute.ChecklistEdit} element={<TaskEditPage />} />
                <Route path={TasksRoute.ChecklistList} element={<TaskViewPage />} />
                <Route path={TasksRoute.Checklist} element={<TaskViewPage />} />
              </Route>

              <Route path={TasksRoute.TaskCreate} element={<TaskCreate />}>
                <Route path={TasksRoute.TaskCreateOne} element={<TaskCreateOne />} />
                <Route path={TasksRoute.TaskCreateGroup} element={<TaskCreateGroup />} />
              </Route>
            </Route>
          </Route>

          <Route path={ApplicationRoutes.Agreement} element={<Agreement />} />
          <Route path={ApplicationRoutes.AdminView} element={<AdminView />} />
          <Route path={ApplicationRoutes.PageNotFound} element={<PageNotFound />} />
          <Route
            path={ApplicationRoutes.PageForbidden}
            element={<PageNotFound type={EErrorStatusName.NotEnoughRights} />}
          />

          {needAdditionalInfo ? <ExternalRedirect redirectTo={registrationStep2Url} /> : null}

          <Route path="*" element={<Navigate to="/my" replace />} />
        </Route>
      </Routes>
    </AuthorizationWrapper>
  );
};

export default memo(AppRouter);
