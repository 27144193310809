import styled from 'styled-components';

import { FontSizes } from '../../modules/shared/constans/sizes';
import { Colors } from '../../modules/shared/constans/colors';

export const DeviceScreenDummyElem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(21, 31, 50, 0.5);
  backdrop-filter: blur(8px);
  z-index: 100001;
  ${FontSizes.xlargeBold}
  color: ${Colors.white};
`;

export const DeviceScreenImg = styled.img`
  width: 80px;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  max-width: 352px;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${Colors.generalWhite};
  text-align: center;
  margin: 0 0 20px;
`;

export const Description = styled.h2`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  & > p {
    max-width: 400px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${Colors.generalWhite};
    text-align: center;
    margin: 0 0 16px;
  }
`;
