import { FC, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { EModalType, ModalFooter, TModalConfig, TModalButton, useModal } from '@farmlink/farmik-ui';

import {
  EChecklistAttributeType as EAttrType,
  IExtendedAverageValue,
} from '../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import AverageAttributeHelpers from '../../helpers/AverageAttribute.helpers';
import { useIsOverflow } from '../../../../../../../../../../shared/hooks';
import { useStore } from '../../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../../mobx/controllers';
import {
  TChecklistsDoubleAttrToDraw as TDoubleAttrToDraw,
  TChecklistsIntegerAttrToDraw as TIntegerAttrToDraw,
} from '../../../../../models';

import Styled from './ChecklistsAverageAttrCullNumbersModal.styles';
import ChecklistsAverageAttrCullNumbersModalItem from './components/ChecklistsAverageAttrCullNumbersModalItem/ChecklistsAverageAttrCullNumbersModalItem';

type TValue = IExtendedAverageValue;

const { getValueListToCull, cullValues } = AverageAttributeHelpers;

const ChecklistsAverageAttrCullNumbersModal: FC = () => {
  const checklistsController = useStore(ChecklistsController);

  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  const [selectedItemList, setSelectedItemList] = useState<TValue[]>([]);

  const { closeModal, getModalPayload } = useModal();
  const attrToDraw = getModalPayload()?.attrToDraw as TIntegerAttrToDraw | TDoubleAttrToDraw;

  const itemList: IExtendedAverageValue[] = useMemo(() => {
    if (attrToDraw?.options?.averageData?.rowList) {
      const { rowList } = attrToDraw.options.averageData;

      return getValueListToCull(rowList);
    } else {
      return [];
    }
  }, [attrToDraw?.options?.averageData?.rowList]);

  const toggleItem = (item: TValue, isSelected: boolean): void => {
    if (isSelected) {
      setSelectedItemList(selectedItemList.filter(({ id }) => id !== item.id));
    } else {
      setSelectedItemList([...selectedItemList, item]);
    }
  };

  const handleCullClick = (): void => {
    if (attrToDraw.initialModel.attribute.type === EAttrType.Int) {
      const { value, averageData } = cullValues<EAttrType.Int>(
        attrToDraw as TIntegerAttrToDraw,
        selectedItemList
      );

      checklistsController.changeAttrValue(EAttrType.Int, attrToDraw.groupId, value);
      checklistsController.changeAttrOptions<EAttrType.Int>(attrToDraw.groupId, attrToDraw.id, {
        averageData,
      });
    } else if (attrToDraw.initialModel.attribute.type === EAttrType.Double) {
      const { value, averageData } = cullValues<EAttrType.Double>(
        attrToDraw as TDoubleAttrToDraw,
        selectedItemList
      );

      checklistsController.changeAttrValue(EAttrType.Double, attrToDraw.groupId, value);
      checklistsController.changeAttrOptions<EAttrType.Double>(attrToDraw.groupId, attrToDraw.id, {
        averageData,
      });
    }

    closeModal();
  };

  const denyButton: TModalButton = {
    title: 'Отменить',
    dataTestId: 'average-attribute-cull-numbers-deny-button',
    color: 'default',
    handler: closeModal,
  };

  const successButton: TModalButton = {
    title: 'Выбраковать',
    dataTestId: 'average-attribute-cull-numbers-success-button',
    color: 'primary',
    handler: handleCullClick,
  };

  return (
    <Styled.Wrapper>
      <Styled.Title>Выбраковать значения</Styled.Title>

      <Styled.Description>
        Нажмите на ячейки, которые хотите исключить из списка значений.
      </Styled.Description>

      <Styled.ItemsBody ref={ref}>
        {itemList.map(item => (
          <ChecklistsAverageAttrCullNumbersModalItem
            key={item.id}
            item={item}
            onToggleItem={toggleItem}
            isSelected={selectedItemList.some(({ id }) => id === item.id)}
          />
        ))}
      </Styled.ItemsBody>

      <Styled.FooterWrapper $isOverflow={isOverflow}>
        <ModalFooter denyButton={denyButton} successButton={successButton} />
      </Styled.FooterWrapper>
    </Styled.Wrapper>
  );
};

ChecklistsAverageAttrCullNumbersModal.displayName = 'ChecklistsAverageAttrCullNumbersModal';

const averageAttributeCullNumbersModalConfig: TModalConfig = {
  name: 'averageAttributeCullNumbers',
  title: '',
  type: EModalType.Custom,
  id: 'averageAttributeCullNumbers',
  children: observer(ChecklistsAverageAttrCullNumbersModal),
  dataTestId: 'average-attribute-cull-numbers-modal',
  styledProps: {
    $modalPaddings: '40px',
    $size: '600px',
    $height: '100vh',
    $fullHeight: true,
  },
};

export default averageAttributeCullNumbersModalConfig;
