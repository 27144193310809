import {
  IGetChecklistInstanceByTaskId,
  IPutChecklistInstance,
} from '../../../models/checklist/instance/checklist.instance.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TSaveChecklistInstanceListReq =
  | (IPutChecklistInstance | IGetChecklistInstanceByTaskId)[]
  | { data: IPutChecklistInstance[]; taskId };

type TSaveChecklistInstanceListRes = IGetChecklistInstanceByTaskId[];

const saveChecklistInstanceList: TypeApiRoute & {
  request: TSaveChecklistInstanceListReq;
  response: TSaveChecklistInstanceListRes;
} = {
  url: () => `/api/as-fields/checklist/instance`,
  method: 'PUT',
  headers: {},
  request: {} as TSaveChecklistInstanceListReq,
  response: {} as TSaveChecklistInstanceListRes,
};

export type { TSaveChecklistInstanceListReq, TSaveChecklistInstanceListRes };

export { saveChecklistInstanceList };
