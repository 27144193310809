import { useState, useCallback } from 'react';
import moment from 'moment';

import { useStore } from '../../../../../../utils/IoC';
import { IndicesController, IndicesStore } from '../../components/Indices/mobx';
import { CultureFillEnum } from '../../../../../../../dashboard/constants/culture.fill.enum';
import { EIndexType, IndexModel } from '../../../../../../../../api/models/indices.model';
import findClosestDateInPast from '../../helpers/findNearestDateInCollection';

interface IHookConfig {
  fieldId: string;
  fillMode: CultureFillEnum;
  fieldSeason: number;
  status: EIndexType[];
  targetDate: moment.Moment;
}

const useDefaultIndex = ({ fieldId, fillMode, fieldSeason, status, targetDate }: IHookConfig) => {
  const { fetchIndicesYearList, fetchIndices } = useStore(IndicesController);
  const { setCurrentYear } = useStore(IndicesStore);

  const [yearList, setYearList] = useState<number[]>([]);

  const searchIndex = useCallback(
    async (searchingYear: number, searchInFuture?: boolean) => {
      const indexList = await fetchIndices(
        fieldId,
        fieldSeason,
        fillMode,
        status,
        `${searchingYear}-01-01`,
        `${searchingYear}-12-31`
      );

      if (searchInFuture) {
        return { index: indexList[indexList.length - 1], arrayIndex: indexList.length - 1 };
      }

      const indexListIndex = indexList.findIndex(index =>
        moment(index.toDate).isSame(targetDate, 'day')
      );

      if (indexListIndex >= 0) {
        return { index: indexList[indexListIndex], arrayIndex: indexListIndex };
      }

      const closestDate = findClosestDateInPast(
        indexList.flatMap(item => moment(item.toDate).toDate()),
        targetDate.toDate()
      );

      if (closestDate?.closestDate) {
        return {
          index: indexList[closestDate.closestDateIndex],
          arrayIndex: closestDate.closestDateIndex,
        };
      }

      return null;
    },
    [fieldId, fillMode, status]
  );

  const findDefaultIndex = useCallback(
    async (searchYear?: number) => {
      const _season = searchYear ?? fieldSeason;

      const _yearList = await fetchIndicesYearList(fieldId, fillMode, fieldSeason, status);

      if (_yearList?.length === 0) {
        return null;
      }

      setYearList(_yearList);

      let targetYear = targetDate.get('year');
      let isSearchInFuture = false;

      if (!_yearList.includes(targetYear)) {
        // не нашли год в списке годов, выясняем в какую сторону делать смещение
        targetYear = _season < _yearList[0] ? _yearList[0] : _yearList[_yearList.length - 1];
        isSearchInFuture = _season < _yearList[0];
      }

      setCurrentYear(targetYear);

      // ищем индекс в этом году
      const indexInCurrentYear = await searchIndex(targetYear);

      if (indexInCurrentYear?.index) {
        return indexInCurrentYear;
      }

      // искомого года нет в списке годов. Проверяем есть ли года в прошлом
      const targetDateYearIndex = _yearList.findIndex(year => year === targetYear);

      if (targetDateYearIndex > 0) {
        // есть год в прошлом с индексами

        setCurrentYear(_yearList[targetDateYearIndex - 1]);
        return searchIndex(_yearList[targetDateYearIndex - 1]);
      }
      // в прошлом искать нечего. Ищем в будущем
      // ищем в текущем году, но в будущем
      const indexInCurrentYearFuture = await searchIndex(targetYear, true);

      if (indexInCurrentYearFuture?.arrayIndex) {
        return indexInCurrentYearFuture;
      }

      if (targetDateYearIndex < _yearList.length - 1) {
        // есть год в будущем
        setCurrentYear(_yearList[targetDateYearIndex + 1]);
        return searchIndex(_yearList[targetDateYearIndex + 1], isSearchInFuture);
      }
      console.warn('Нет подходящих индексов');
      return null;
    },
    [searchIndex]
  );

  return { yearList, findDefaultIndex };
};

export default useDefaultIndex;
