import { IGetChecklistAttributeUserDictionary } from '../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { IListOfAddedUserDictLinkValue } from '../../../../models/attribute';
import { LIST_OF_ADDED_USER_DICT_LINK_VALUE_KEY } from '../../../../models/attribute/ListOfAddedUserDictLinkValueAttr/ListOfAddedUserDictLinkValueAttr.model';

const getStoredData = (): IListOfAddedUserDictLinkValue | null => {
  const storedData: IListOfAddedUserDictLinkValue = JSON.parse(
    sessionStorage.getItem(LIST_OF_ADDED_USER_DICT_LINK_VALUE_KEY)
  );

  return storedData;
};

const clearStoredData = (): void => {
  sessionStorage.removeItem(LIST_OF_ADDED_USER_DICT_LINK_VALUE_KEY);
};

const getStoredValueList = (attrId: string): IGetChecklistAttributeUserDictionary[] => {
  const storedData = getStoredData();

  return storedData?.listOfChecklistUserDictLinkValue?.[attrId] || [];
};

const addDataIntoSessionStorage = (data: IListOfAddedUserDictLinkValue): void => {
  const transformedStoredData: string = JSON.stringify(data);

  sessionStorage.setItem(LIST_OF_ADDED_USER_DICT_LINK_VALUE_KEY, transformedStoredData);
};

const isAddedNewValue = (
  attrId: string,
  newUserDictLinkValue: IGetChecklistAttributeUserDictionary
): boolean => {
  const storedData = getStoredData();

  if (storedData) {
    const valueList = storedData.listOfChecklistUserDictLinkValue?.[attrId];

    const hasAlready = valueList?.find(
      value =>
        value.id === newUserDictLinkValue.id || value.clientId === newUserDictLinkValue.clientId
    );

    if (hasAlready) {
      return false;
    }

    if (valueList) {
      storedData.listOfChecklistUserDictLinkValue[attrId].push(newUserDictLinkValue);

      addDataIntoSessionStorage(storedData);

      return true;
    } else {
      storedData.listOfChecklistUserDictLinkValue[attrId] = [newUserDictLinkValue];

      addDataIntoSessionStorage(storedData);

      return true;
    }
  } else {
    const newData: IListOfAddedUserDictLinkValue = {
      listOfChecklistUserDictLinkValue: {
        [attrId]: [newUserDictLinkValue],
      },
    };

    addDataIntoSessionStorage(newData);

    return true;
  }
};

const userDictLinkHelpers = { getStoredData, clearStoredData, getStoredValueList, isAddedNewValue };

export default userDictLinkHelpers;
