import { lazyInject, provide } from '../../../../../utils/IoC';
import { TableBuilderStore as Store } from '../../stores';
import { ITableBuilderCell as ICell } from '../../../models/data';
import { ITableBuilderCellConfig as ICellConfig } from '../../../models/configs';

@provide.transient()
class TableBuilderCellsService<E = any, B = string> {
  @lazyInject(Store)
  protected store: Store<E, B>;

  addCellList = (
    builderId: B,
    configList: ICellConfig<E>[],
    options?: { isClearPreviousList?: boolean }
  ): void => {
    const cellList = this.createCellList(builderId, configList);

    this.store.setCellList(builderId, cellList, options);
  };

  createCellList = (builderId: B, configList: ICellConfig<E>[]): ICell<E, B>[] => {
    const cellList = configList.map((config, index) =>
      this.createCell(builderId, config, index + 1)
    );

    return cellList;
  };

  createCell = (builderId: B, config: ICellConfig<E>, order: number): ICell<E, B> => {
    const cell: ICell<E, B> = {
      ...config,
      builderId,
      order,
      renderType: null,
    };

    if (config?.autoRenderConfig) {
      cell.renderType = 'auto-render';
    } else if (config?.customRenderConfig) {
      cell.renderType = 'custom-render';
    }

    return cell;
  };
}

export default TableBuilderCellsService;
