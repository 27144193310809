import { TypeApiRoute } from '../../models/type.api.request';
import { CultureZone } from '../../models/update.culture.zone';

type TypeRequest = Array<Partial<CultureZone>>;

type InputData = {
  zones: TypeRequest;
  seasonYear: number;
  fieldId: string;
};

type TypeResponse = Array<string>;

export const updateCultureZones: TypeApiRoute & {
  request: InputData | ((request: any) => TypeRequest);
  response: TypeResponse;
} = {
  url: req =>
    `/api/as-fields/fields/${req.fieldId}/cultureZones/update?seasonYear=${req.seasonYear}`,
  method: 'PUT',
  headers: {},
  request: req => req.zones,
  response: {} as TypeResponse,
};
