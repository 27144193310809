// eslint-disable-next-line import/order
import React, { useEffect, FC } from 'react';
import {
  ENotificationType,
  ENotificatorTheme,
  useNotificator,
  ENotificationHeight,
} from '@farmlink/farmik-ui';
import 'leaflet';
import '@farmlink/gefim';
import '@farmlink/gefim/dist/leaflet-geoman.css';
import 'leaflet.gridlayer.googlemutant';
import styled from 'styled-components';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { useStore } from '../shared/utils/IoC';
import { fillLeafletShapeWithImageExtension } from '../shared/utils/fillLeafletShapeWithImageExtension';
import { FieldsStore } from '../dashboard/modules/fields/stores/fields.store';

import './style.css';
import MapStore, { MapMode } from './stores/map.store';

const defaultLatLng = [55.751244, 37.618423];

const zoom = 19;

const StyledMapContainer = styled.div`
  && {
    height: 100%;
    max-height: 100%;
    flex: 1 1 0;
  }
`;

export const Map: FC<any> = observer(() => {
  const store = useStore(MapStore);
  const fieldsStore = useStore(FieldsStore);

  const { coordinates, instance, setInstance, pointsOfUnfinishedContour } = store;
  const { setNotification, clearAll: clearAllNotifications } = useNotificator();

  useEffect(() => {
    // @ts-ignore
    L.SVG.include(fillLeafletShapeWithImageExtension);

    // @ts-ignore
    if (L.DomUtil.get('mapId') != null && instance) {
      instance.off();
      instance.remove();
    }

    // @ts-ignore
    const map = L.map('mapId', {
      // @ts-ignore
      center: defaultLatLng,
      zoom,
      zoomControl: false,
      minZoom: 3,
      maxZoom: 20,
    });
    setInstance(map);

    return () => {
      setInstance(null);
    };
  }, []);

  useEffect(() => {
    if (!instance || coordinates.lat === 0 || coordinates.lng === 0) return;
    // @ts-ignore

    instance.setView({ lat: coordinates.lat, lng: coordinates.lng }, 12);
  }, [coordinates]);

  useEffect(() => {
    if (pointsOfUnfinishedContour === 1) {
      setNotification({
        message: 'Замкните контур, чтобы завершить создание поля',
        style: {
          type: ENotificationType.Info,
          height: ENotificationHeight.BIG,
          hideProgressBar: true,
          autoClose: 10000000,
          placement: 'top-right',
          theme: ENotificatorTheme.Light,
        },
      });
    }
  }, [pointsOfUnfinishedContour]);

  useEffect(() => {
    clearAllNotifications();
  }, [store.drawEndTrigger]);

  useEffect(() => {
    clearAllNotifications();

    if (fieldsStore.mapMode !== MapMode.Editing) {
      return;
    }

    if (store.cultureZoneOutOfFieldError) {
      setNotification({
        message: 'Культурная зона выходит за пределы контура поля',
        style: {
          type: ENotificationType.Warning,
          height: ENotificationHeight.BIG,
          hideProgressBar: true,
          autoClose: 10000,
          placement: 'top-right',
        },
      });
    } else if (store.cultureZoneOutOfFieldError === false) {
      setNotification({
        message: 'Культурные зоны в пределах контура поля',
        style: {
          type: ENotificationType.Success,
          height: ENotificationHeight.BIG,
          hideProgressBar: true,
          autoClose: 10000,
          placement: 'top-right',
        },
      });
    }
  }, [store.cultureZoneOutOfFieldError, fieldsStore.mapMode]);

  return <StyledMapContainer id="mapId" />;
});
