import { makeAutoObservable } from 'mobx';

import { provide } from '../../shared/utils/IoC';
import { IndexModel } from '../../../api/models/indices.model';
import { CultureFillEnum } from '../constants/culture.fill.enum';

interface IPrevState {
  prevSelectedFieldId: string;
  prevCulturesFillPanel: CultureFillEnum;
}

@provide.singleton()
export class IndicesStore {
  isLoading = true;

  indices: IndexModel[] = [];

  selectedIndex: IndexModel | null = null;

  layoutId: number;

  prevState: IPrevState = {
    prevSelectedFieldId: null,
    prevCulturesFillPanel: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedIndex = (index: IndexModel): void => {
    this.selectedIndex = index;
  };

  setIndices = (indicesList: IndexModel[]): void => {
    this.indices = indicesList;
  };

  setLayoutId = (id: number): void => {
    this.layoutId = id;
  };

  setLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  setPrevState = (fieldId: string, cultureFill: CultureFillEnum): void => {
    this.prevState = {
      prevSelectedFieldId: fieldId,
      prevCulturesFillPanel: cultureFill,
    };
  };

  clear = () => {
    this.layoutId = 0;
  };
}
