import styled from 'styled-components';

import { Colors } from '../../constans/colors';

export const Item = styled.div<{ disabled?: boolean }>`
  padding-left: 17px;
  padding-right: 16px;
  height: 48px;
  width: 100%;
  background: ${Colors.white};
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${Colors.backgroundSecondaryGray};
  }
  
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  cursor: ${({ disabled }) => (disabled ? `default` : `pointer`)};
  & * {
    opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  }
`;

export const ArrowLabelTooltip = styled.div`
  position: absolute;
  left: -5px;
  top: 15px;
  //background: ${Colors.white};
  z-index: 998;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  border-right: 5px solid ${Colors.white};
`;

export const Arrow = styled.div`
  position: absolute;
  left: -10px;
  bottom: 17px;
  //background: ${Colors.white};
  z-index: 998;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;

  border-right: 10px solid ${Colors.white};
`;

export const Content = styled.div`
  transition: unset;
  & > ${Item}:first-child, & > .control-access-rules-wrapper-el:first-child > ${Item} {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  & > ${Item}:last-child, & > .control-access-rules-wrapper-el:last-child > ${Item} {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 5px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 176px;
  display: flex;
  //overflow: hidden;
  flex-direction: column;
  outline: none;
  background: transparent;
`;

export const IconWrapper = styled.div<{ fill?: string }>`
  display: flex;
  margin-right: 12px;
  svg {
    fill: ${({ fill }) => (fill ? fill : Colors.black)};
    width: 16px;
    height: 16px;
  }
`;

export const Label = styled.p<{ color?: string }>`
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${({ color }) => (color ? color : Colors.black)};
`;
