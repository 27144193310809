import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

import copy from './../../assets/images/copy.svg';
import copy_hover from './../../assets/images/copy_hover.svg';

const CopyIcon = styled.div`
  width: 16px;
  height: 16px;
  border: none;
  transition: 0.3s;
  cursor: pointer;

  background-image: url(${copy});
  background-color: transparent;
`;

const Coordinates = styled.span`
  transition: 0.3s;
`;

const CordButton = styled.button`
  display: flex;
  gap: 8px;
  cursor: pointer;
  border: none;
  background: none;

  &:hover {
    & > ${CopyIcon} {
      background-image: url(${copy_hover});
    }
  }
`;

const Styled = {
  CordButton,
  CopyIcon,
  Coordinates,
};

export default Styled;
