import styled from 'styled-components';

import { BorderRadius } from '../../../../../../../../../shared/constans/sizes';

const Wrapper = styled.div`
  min-width: 128px;
  height: 56px;
  border-radius: ${BorderRadius.small};
`;

const Styled = {
  Wrapper,
};

export default Styled;
