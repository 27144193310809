import { flatMap } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { IChecklistAttributeFileValue } from '../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { provide } from '../../../../shared/utils/IoC';

export interface IChecklistFileItem extends IChecklistAttributeFileValue {
  imagePreview?: string;
  isClientOnly?: boolean;
  attributeId?: string;
  nestedId?: string;
  uploadedImageKey?: string[];
}

export enum EFileLoadingStatus {
  pending,
  fulfilled,
  error,
}
@provide.singleton()
export class ChecklistFileUploaderStore {
  /**
   * Коллекция массивов файлов по атрибутам
   *
   * @private
   * @memberof checklistFileUploaderStore
   */
  private _filesArrayMap = new Map<string, Array<IChecklistFileItem>>();

  private _uploadFileBuffer: File | null = null;

  private _imgsUrls: Array<string> = [];

  private _fileLoadingStatus: EFileLoadingStatus;

  private _fileIdToFile = new Map<string, IChecklistFileItem>();

  constructor() {
    makeAutoObservable(this);
  }

  get filesArrayMap() {
    return this._filesArrayMap;
  }

  get uploadFileBuffer() {
    return this._uploadFileBuffer;
  }

  get imgsUrls() {
    return this._imgsUrls;
  }

  get fileLoadingStatus() {
    return this._fileLoadingStatus;
  }

  /**
   * Возвращает массив файлов, содержащийся в атрибуте с указным id
   *
   * @param {string} attributeId
   * @return {Array<IChecklistFileItem>} Array
   * @memberof checklistFileUploaderStore
   */
  getFilesArrayByAttributeId = (attributeId: string) => {
    return this._filesArrayMap.get(attributeId);
  };

  getImagesByAttributeId = (attributeId: string) => {
    return flatMap(Array.from(this._filesArrayMap.values())).filter(
      item => item.attributeId === attributeId
    );
  };

  fileKeyToFile = (uploadKeysList: string[]): IChecklistFileItem[] => {
    return uploadKeysList.reduce((itemList, item) => {
      const fileValue = this._fileIdToFile.get(item);
      if (fileValue) {
        itemList.push(fileValue);
      }
      return itemList;
    }, []);
  };

  removeFileByFileKey = (fileKey: string) => {
    return this._fileIdToFile.delete(fileKey);
  };

  setFileToFileMapById = (id: string, file: IChecklistFileItem) => {
    this._fileIdToFile.set(id, file);
  };

  setUploadFileBuffer = (file: File) => {
    this._uploadFileBuffer = file;
  };

  setFileLoadingStatus = (status: EFileLoadingStatus) => {
    this._fileLoadingStatus = status;
  };

  setItemToFilesArray = (attributeId: string, files: IChecklistFileItem[]) => {
    if (!attributeId || !files) return;
    this._filesArrayMap.set(attributeId, files);
  };

  addItemToFilesArray = (attributeId: string, file: IChecklistFileItem) => {
    if (!attributeId || !file) return;
    if (this._filesArrayMap.has(attributeId)) {
      const filesArr = this._filesArrayMap.get(attributeId);
      filesArr.unshift(file);
      this._filesArrayMap.set(attributeId, filesArr);
    } else {
      this._filesArrayMap.set(attributeId, [file]);
    }
  };

  addUrlToImgsUrlArray = (url: string) => {
    this._imgsUrls.push(url);
  };

  removeItemFromFilesArrayById = (attributeId: string, fileId: string) => {
    if (this._filesArrayMap.has(attributeId)) {
      const filesArr = this._filesArrayMap.get(attributeId).filter(file => file.id !== fileId);
      this._filesArrayMap.set(attributeId, filesArr);
    }
  };

  clearAttributeFilesArrayById = (attributeId: string) => {
    if (this._filesArrayMap.has(attributeId)) {
      this._filesArrayMap.set(attributeId, []);
    }
  };

  clearFilesArray = () => {
    this._filesArrayMap.clear();
  };

  clearFileBuffer = () => {
    this._uploadFileBuffer = null;
  };

  clearImgUrls = () => {
    this._imgsUrls = [];
  };

  clearUploadKeyToFiles = () => {
    this._fileIdToFile.clear();
  };

  clearStore = () => {
    this.clearFilesArray();
    this.clearFileBuffer();
    this.clearImgUrls();
    this.clearUploadKeyToFiles();
  };
}
