import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../modules/shared/utils/IoC';
import { UiStore } from '../../modules/dashboard/stores/ui.store';

import { Description, DeviceScreenDummyElem, DeviceScreenImg, Title } from './style';
import deviceScreenIcon from './assets/deviceScreenIcon.svg';

export const DeviceScreenDummy = observer(() => {
  const ui = useStore(UiStore);

  if (ui.pageWidth > 1023 || ui.pageWidth <= 428 || ui.pageHeight <= 428) {
    return null;
  }

  // if (ui.pageWidth > 428 && ui.pageHeight > 428)
  return (
    <DeviceScreenDummyElem data-test-id="device-screen-dummy">
      <DeviceScreenImg src={deviceScreenIcon} alt="" />
      <Title>Помощник не работает в таком разрешении экрана</Title>
      <Description>
        <p>Если вы зашли с компьютера — переведите браузер в полноэкранный режим.</p>
        <p>Если вы зашли с планшета — переверните его в горизонтальное положение.</p>
      </Description>
    </DeviceScreenDummyElem>
  );
});
