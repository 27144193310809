import { observer } from 'mobx-react';
import { FC, memo } from 'react';

import { ITableBuilderCell as ICell, ITableBuilderRow as IRow } from '../../../models/data';
import { TableBuilderCell as Cell } from '../../../components/content';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';

interface IProps {
  cell: ICell;
  row: IRow;
}

const TableBuilderCell: FC<IProps> = ({ cell, row }) => {
  const getDataTestId = useDataTestId();

  const dataTestId = getDataTestId(`cell-with-key-${cell.id as string}`)['data-test-id'];

  switch (cell.renderType) {
    case 'auto-render': {
      const value = cell.autoRenderConfig.renderValue(row.element);

      return <Cell key={cell.id as string} value={value} dataTestId={dataTestId} />;
    }

    case 'custom-render': {
      return cell.customRenderConfig.renderCell(row.element);
    }

    default:
      return <></>;
  }
};

TableBuilderCell.displayName = 'TableBuilderRow';

export default memo(observer(TableBuilderCell));
