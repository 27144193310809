export enum RenderPolygonOption {
  Listing = 'listing',
  View = 'view',
  Creating = 'creating',
  Edit = 'edit',
  CultureZone = 'cultureZone',
  CultureZoneBorder = 'cultureZoneBorder',
  CultureZoneHole = 'cultureZoneHole',
  Import = 'import',
  Error = 'error',
}
