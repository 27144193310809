import area from '@turf/area';

const hasTooBigAreaInHectares = (layer: any): boolean => {
  const layerGeoJSON = layer.toGeoJSON();
  const layerAreaInHectares = area(layerGeoJSON) / 10000;

  return layerAreaInHectares > 1000;
};

export default hasTooBigAreaInHectares;
