export enum EChecklistAttributeFormulaPropertyReturnType {
  Double = 'DOUBLE',
  Integer = 'INTEGER',
}

export interface IChecklistAttributeFormulaProperty {
  returnType: EChecklistAttributeFormulaPropertyReturnType;
  attrId: string;
  propertyName: string;
}
