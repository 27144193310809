import styled from 'styled-components';

const Wrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const StyledAverageAttributeItemsBody = {
  Wrapper,
};

export default StyledAverageAttributeItemsBody;
