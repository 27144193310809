import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { EModalSize } from '../../../../../modals/components/Modal/styles';

export const REPORT_UPLOAD_MODAL_ERROR_ID = 'reportUploadModalError';

export const uploadReportModalError: TModalConfig = {
  id: REPORT_UPLOAD_MODAL_ERROR_ID,
  name: REPORT_UPLOAD_MODAL_ERROR_ID,
  title: 'Кажется, что-то пошло не так. Попробуйте ещё раз',
  type: EModalType.Warning,
  styledProps: {
    $size: EModalSize.Medium,
  },
  successButton: {
    title: 'Продолжить',
    color: 'primary',
  },
};
