import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  email: string;
};

type TypeResponse = {};

export const sendEmailVerification: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-profile/auth/sendVerifyEmail?email=${request.email}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
