import { FC } from 'react';

import { IChecklistFileItem } from '../../../../../../../operationsAndTasks/stores/checklist.fileUploader.store';

import { MiniImg, MiniImgWrapper, OutContainerPlaceholder, StyledDeleteIcon } from './style';

interface IImageMiniRowProps {
  imgsArr: IChecklistFileItem[];
  isBlocked: boolean;
  onDeleteByIndex: (number) => () => void;
  setSelectedImgIndex: (number) => void;
  parentElem?: null | Element;
}

const MIN_ELEMENT_WIDTH = 385;

export const ImageMiniRow: FC<IImageMiniRowProps> = ({
  imgsArr,
  isBlocked,
  onDeleteByIndex,
  setSelectedImgIndex,
  parentElem,
}) => {
  if (!parentElem) return null;

  const containerWidth = parseInt(getComputedStyle(parentElem)?.width, 10);
  const maxCountForRendering =
    Math.trunc(
      (containerWidth || 0) <= MIN_ELEMENT_WIDTH ? MIN_ELEMENT_WIDTH / 48 : containerWidth / 48
    ) -
    (imgsArr.length < 10 && !isBlocked ? 2 : 1) +
    1;

  if (maxCountForRendering > 0) {
    return (
      <>
        {imgsArr.map((imgItem, index) => {
          if (!imgItem) return null;
          return (
            index < maxCountForRendering && (
              <MiniImgWrapper key={imgItem.id}>
                {!isBlocked && <StyledDeleteIcon onClick={onDeleteByIndex(index)} />}
                <MiniImg
                  src={imgItem?.downloadUrl ? imgItem?.downloadUrl : imgItem?.imagePreview}
                  onClick={() => setSelectedImgIndex(index)}
                />
              </MiniImgWrapper>
            )
          );
        })}
        {imgsArr?.length > maxCountForRendering && (
          <OutContainerPlaceholder onClick={() => setSelectedImgIndex(maxCountForRendering)}>
            +{imgsArr?.length - maxCountForRendering}
          </OutContainerPlaceholder>
        )}
      </>
    );
  }
  return null;
};
