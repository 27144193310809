import _, { throttle } from 'lodash';
import {
  polygon as turfPolygon,
  point as turfPoint,
  booleanPointInPolygon as turfBooleanPointInPolygon,
  intersect,
} from '@turf/turf';

import { provide, lazyInject } from '../../../../../../shared/utils/IoC';
import { CultureZoneStore } from '../stores/culture.zone.store';
import MapStore, { MapMode } from '../../../../../../map/stores/map.store';
import { CultureZoneModel } from '../../../../../../../api/models/culture.zone.model';

@provide.singleton()
export class CultureZoneController {
  @lazyInject(MapStore)
  protected mapStore: MapStore;

  @lazyInject(CultureZoneStore)
  protected cultureZoneStore: CultureZoneStore;

  startDrawZone = (): void => {
    this.mapStore.startDrawCultureWizard();
    this.cultureZoneStore.setIsCultureZoneCreating(true);
    this.cultureZoneStore.setIsZoneNotificationWarning(true);
  };

  acceptTheCreatedNewZone = (): void => {
    this.cultureZoneStore.setIsCultureZoneCreating(false);
    this.cultureZoneStore.setIsZoneNotificationWarning(false);

    this.calculateHoles();

    this.cultureZoneStore.clearInitialIdToCultureZone();
    this.cultureZoneStore.cancelDrawing();

    this.mapStore.setMode(MapMode.Listing);
    this.mapStore.clearVertexCoordinates();

    this.acceptCultureZoneEditing();
  };

  cancelTheCreatedNewZone = (): void => {
    const isEmptyInitialZonesList = !this.cultureZoneStore.initialCultureZonesList.length;
    this.cultureZoneStore.setIsCultureZoneCreating(false);
    this.cultureZoneStore.setIsZoneNotificationWarning(false);

    if (!isEmptyInitialZonesList) {
      this.cultureZoneStore.resetIdToCultureZone();

      this.cultureZoneStore.createHoles();
      this.cultureZoneStore.renderCultureZones();
    } else {
      this.cultureZoneStore.cancelDrawing();
    }

    this.mapStore.setMode(MapMode.Listing);
    this.mapStore.clearVertexCoordinates();

    this.cultureZoneStore.cancelDrawing();
    this.cultureZoneStore.selectedCultureZoneModel = null;
  };

  acceptCultureZoneEditing = (): void => {
    this.disableChangeGeometryEvent(true);

    this.cultureZoneStore.setIsCultureZoneEditing(false);

    this.mapStore.setMode(MapMode.Listing);
    this.mapStore.clearVertexCoordinates();

    this.resetChangesOfSelectedZone();
  };

  calculateHoles = () => {
    const newZonePoly = turfPolygon([this.cultureZoneStore.lastValidatedGeometry.coordinates[0]]);
    Array.from(this.cultureZoneStore.idToCultureZone.keys()).forEach((key, index) => {
      if (index > 0) {
        const zone = this.cultureZoneStore.idToCultureZone.get(key);
        const culturePoly = turfPolygon(zone.geometry.coordinates);
        const intersection = intersect(newZonePoly, culturePoly);
        if (intersection) {
          const zoneWithHole: CultureZoneModel = {
            ...zone,
            geometry: {
              ...zone.geometry,
              // @ts-ignore
              coordinates: [zone.geometry.coordinates[0], intersection.geometry.coordinates[0]],
            },
          };
          this.cultureZoneStore.setIdToCultureZone(key, zoneWithHole);
        }
      }
    });
  };

  cancelCultureZoneEditing = (): void => {
    this.disableChangeGeometryEvent(true);

    const selectedCultureZone = this.cultureZoneStore.selectedCultureZoneModel;

    const initialSelectedZoneModel = {
      ...selectedCultureZone,
      geometry: this.cultureZoneStore.initialGeometryOfSelectedZone || selectedCultureZone.geometry,
    };

    this.cultureZoneStore.setIdToCultureZone(selectedCultureZone.mapId, initialSelectedZoneModel);

    this.cultureZoneStore.setIsCultureZoneEditing(false);

    this.mapStore.setMode(MapMode.Listing);
    this.mapStore.clearVertexCoordinates();

    this.resetChangesOfSelectedZone();
  };

  getLayerOfSelectedCultureZone = (): any =>
    this.mapStore.getLayer(Number(this.cultureZoneStore.selectedCultureZoneModel?.polyId));

  disableChangeGeometryEvent = (isPmDisable?: boolean): void => {
    const layer = this.getLayerOfSelectedCultureZone();

    if (layer) {
      layer.off('pm:markerdragend', this.cultureZoneStore.handleChangeGeometry);
      layer.off('pm:vertexclick', throttle(this.mapStore.selectVertex, 300));
      layer.off('pm:vertexadded', throttle(this.mapStore.selectVertex, 300));
      layer.off('pm:markerdrag', throttle(this.mapStore.handleLayerMarkerDrag, 300));

      if (isPmDisable) layer.pm.disable();
    }
  };

  isCultureZonesEdited = (): boolean => {
    return !_.isEqual(
      this.cultureZoneStore.initialIdToCultureZone,
      this.cultureZoneStore.idToCultureZone
    );
  };

  resetChangesOfSelectedZone = (): void => {
    this.cultureZoneStore.setInitialGeometryOfSelectedZone(null);

    this.cultureZoneStore.setSelectedCultureZoneModel(null);
    this.cultureZoneStore.setLastValidateGeometry(null);

    this.cultureZoneStore.createHoles();
    this.cultureZoneStore.renderCultureZones();

    this.cultureZoneStore.setIsZoneNotificationWarning(false);
  };
}
