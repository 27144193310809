import { TypeApiRoute } from '../../models/type.api.request';

import { AccessRule } from './checkAccessByCodePrefix';
type TypeRequest = {
  organizationId: string;
  moduleCode: string;
};

type TypeResponse = Array<AccessRule>;

export const checkAccessByModule: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/da-profile/access/action/byModule`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
