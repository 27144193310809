import _ from 'lodash';

import { provide } from '../../../../shared/utils/IoC';
import { TTaskUpdate } from '../../../../../api/models/as-fields/task/task.model';

@provide.transient()
export class TaskValidatingService {
  prepareTaskFields = (taskUpdate: Partial<TTaskUpdate>) => {
    const validatedTask = { ...taskUpdate };

    if (validatedTask?.comment) {
      validatedTask.comment = this.prepareComment(validatedTask.comment);
    }

    if (validatedTask?.assigneeId) {
      validatedTask.assigneeId = this.prepareAssignee(validatedTask.assigneeId);
    }

    return validatedTask;
  };

  prepareAssignee = (assigneeId: string): string | null => {
    if (assigneeId === 'noAssignee') {
      return null;
    } else {
      return assigneeId;
    }
  };

  prepareComment = (comment: string): string => {
    return comment.trim();
  };
}

export default TaskValidatingService;
