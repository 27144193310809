import { makeAutoObservable } from "mobx";

import { OperationField } from "../../../../../api/endpoints/operations/operation.fields.get";
import { IGetChecklistInstanceByTaskId } from "../../../../../api/models/checklist/instance/checklist.instance.model";
import { CultureList } from "../../../../../api/models/operations/operation.culture.list.model";
import { Operation } from "../../../../../api/models/operations/operation.model";
import { OperationType } from "../../../../../api/models/operations/operation.type.model";
import { TypeUser } from "../../../../../api/models/user.model";
import { provide } from "../../../../shared/utils/IoC";
import { PreparedTask } from "../controllers/tasks.listing.controller";

import { Task } from "./tasks.store";

export enum ETaskFormViewMode {
    SideForm = 'side-form',
    FullscreenForm = 'fullscreen-form',
}
@provide.singleton()
export class TaskViewStore {
    cultureId = '';
    cultureList: CultureList;
    operations: Array<Operation> = [];
    operationFields: Array<OperationField> = [];
    taskForView: Partial<Task> | PreparedTask = {};
    taskForViewId: string | null = null;
    checklistInstanceList: IGetChecklistInstanceByTaskId[] = [];

    taskFormLastViewMode: ETaskFormViewMode = ETaskFormViewMode.SideForm;

    constructor() {
        makeAutoObservable(this);
    }
}
