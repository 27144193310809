import { FC, memo } from 'react';
import moment from 'moment';

import { TasksListDaysLeftValue as Value } from '../../values';

interface IProps {
  id: string;
  daysLeft: number;
  executedDate: string;
}

const TasksListDaysLeftWithStatusCompleted: FC<IProps> = ({ id, daysLeft, executedDate }) => {
  if (daysLeft >= 0) {
    return (
      <Value
        id={id}
        icon={'calendar'}
        title={moment(executedDate).format('DD.MM.YYYY')}
        tooltipTitle={'Дата завершения'}
      />
    );
  }

  if (daysLeft < 0) {
    return (
      <Value
        id={id}
        icon={'clock'}
        title={moment(executedDate).format('DD.MM.YYYY')}
        tooltipTitle={'Завершена с просроком'}
      />
    );
  }

  return <></>;
};

TasksListDaysLeftWithStatusCompleted.displayName = 'TasksListDaysLeftWithStatusCompleted';

export default memo(TasksListDaysLeftWithStatusCompleted);
