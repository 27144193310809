import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../../shared/constans/colors';

const { generalBlack, green, red, borderGray } = Colors;

const CalculatedDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 12px;
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: ${generalBlack};
`;

const Action = styled.button<{ $isDisabled?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  background: none;
  border: none;

  padding: 0;
  margin: 0;

  cursor: pointer;

  color: ${green};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      pointer-events: none;
      color: ${borderGray};
      cursor: auto;
    `}
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const Title = styled.span<{ $isRequired?: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: ${generalBlack};

  &:after {
    color: red;
    visibility: ${({ $isRequired }) => ($isRequired ? 'visible' : 'hidden')};
    content: '*';
  }
`;

const TitleAndErrorContainer = styled.div`
  flex: 0 0 70%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-bottom: 16px;
`;

const Header = styled.div``;

const ItemsBodyWrapper = styled.div`
  margin: 24px 0;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Wrapper = styled.div<{ $isError?: boolean }>`
  padding: 16px;

  border: 1px solid #e7f1fd;
  border-radius: 12px;

  color: white;

  ${({ $isError }) =>
    $isError &&
    css`
      border-color: ${red};
    `}
`;

const StyledChecklistsAverageAttribute = {
  CalculatedDataWrapper,
  Description,
  Action,
  ActionWrapper,
  Title,
  TitleAndErrorContainer,
  TitleWrapper,
  ItemsBodyWrapper,
  Header,
  Wrapper,
};

export default StyledChecklistsAverageAttribute;
