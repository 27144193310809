import { FC, memo } from 'react';

import {
  ETaskStatus,
  ITask,
} from '../../../../../../../../../api/models/as-fields/task/task.model';

import {
  TasksListDaysLeftWithStatusNew as New,
  TasksListDaysLeftWithStatusInWork as InWork,
  TasksListDaysLeftWithStatusCompleted as Completed,
  TasksListDaysLeftWithStatusCanceled as Canceled,
} from './components/statuses';

interface IProps {
  row: ITask;
}

const TasksListDaysLeftCell: FC<IProps> = ({ row }) => {
  const { id, status, daysLeft, executedDate, canceledDate } = row;

  switch (status) {
    case ETaskStatus.New:
      return <New id={id} daysLeft={daysLeft} />;
    case ETaskStatus.InWork:
      return <InWork id={id} daysLeft={daysLeft} />;
    case ETaskStatus.Completed:
      return <Completed id={id} daysLeft={daysLeft} executedDate={executedDate} />;
    case ETaskStatus.Canceled:
      return <Canceled id={id} canceledDate={canceledDate} />;
    default:
      return <></>;
  }
};

TasksListDaysLeftCell.displayName = 'TasksListDaysLeftCell';

export default memo(TasksListDaysLeftCell);
