const getFileExtension = (file: File): string => {
  if (file?.name) {
    const [extension] = file.name.split('.').reverse();

    return extension;
  }

  return '';
};

export default getFileExtension;
