import { FC, ReactNode, PropsWithChildren, memo, useMemo } from 'react';

import Styled from './Container.styles';

interface IProps {
  /**
   * Шапка контейнера.
   */
  header?: {
    /**
     * Заглавный текст.
     */
    title?: string;
    /**
     * Иконка контекстной справки.
     */
    ContextualHelpIcon?: ReactNode;
    /**
     * Описание, что находится под заглавным текстом.
     */
    description?: string;
    /**
     * Контент, что отображается под заглавием.
     */
    headerChildren?: ReactNode;
    /**
     * Скрывает разделительную линию контента и хэдера.
     */
    isHideDividingLine?: boolean;
  };
}

const Container: FC<PropsWithChildren<IProps>> = ({ header, children }) => {
  const HeaderContent = useMemo(() => {
    return (
      <>
        {header?.title ? <Styled.Title>{header.title}</Styled.Title> : null}

        {header?.description ? <Styled.Description>{header.description}</Styled.Description> : null}
      </>
    );
  }, [header?.title, header?.description]);

  return (
    <Styled.Wrapper>
      {header ? (
        <Styled.Header $isHideHeaderBorder={header?.isHideDividingLine}>
          {header.headerChildren ? header.headerChildren : HeaderContent}
        </Styled.Header>
      ) : null}

      <Styled.Content>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

Container.displayName = 'Container';

export default memo(Container);
