import { observer } from 'mobx-react';
import { FC, useEffect, useMemo, useRef } from 'react';
import { ButtonLink } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../shared/utils/IoC';
import {
  ChecklistInstancesStore,
  EChecklistMode,
} from '../../../../../operationsAndTasks/stores/checklist.instances.store';
import { ChecklistInstancesController } from '../../../../../operationsAndTasks/controllers/checklist.instances.controller';
import { Intensity } from '../../../../../operationsAndTasks/modules/fullscreen/checklist/components/Intensity';
import { ModalContainer } from '../../../../../../../modals/containers';
import { checklistModalUniqueKey } from '../../../../../operationsAndTasks/modules/fullscreen/checklist/modals/modalsConfig';
import { EModalSize } from '../../../../../../../modals/components/Modal/styles';
import { IGetChecklistInstanceByTaskId } from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../../../shared/constants/access-rules-action';
import { ProfileStore } from '../../../../../profile/stores/ProfileStore';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import { CheckAccessStore } from '../../../../../../../authorization/stores/checkAccess.store';
import { useTasksParams, useTasksRouteActions } from '../../../../hooks';
import {
  ETaskAction,
  ETaskStatus,
} from '../../../../../../../../api/models/as-fields/task/task.model';
import { TaskStore } from '../../../../mobx/stores';
import { ChecklistsChecklistContainer as ChecklistContainer } from '../../../../modules/Checklists/containers';
import { ChecklistsStore } from '../../../../modules/Checklists/mobx/stores';
import { Container } from '../../../../../../../shared/features/UI/Container';
import { ContentLoader } from '../../../../../../../shared/features/UI/loaders/ContentLoader';
import { getTypeForChecklist } from '../../../../../operationsAndTasks/modules/fullscreen/checklist/components/Intensity/Intensity';

import { InstanceCard } from './components/InstanceCard/InstanceCard';
import {
  ButtonWrapper,
  Content,
  NoDataButton,
  NoDataDescription,
  NoDataTitle,
  NoDataWrapper,
  PointTitle,
} from './style';
import { CardList } from './components/CardList';
import { SkeletonInstanceCard } from './components/SkeletonInstanceCard';
import { createInstanceCardId } from './utils/helpers';
import { History } from './components/History';

const Checklists: FC = () => {
  const taskStore = useStore(TaskStore);
  const checklistsStore = useStore(ChecklistsStore);

  const {
    drawInstanceList,
    idOfUnsavedAttr,
    selectedInstance,
    clearSelectedInstanceEventList,
  } = useStore(ChecklistInstancesStore);

  const {
    getModalWarningBeforeChangeInstance,
    isFetching,
    isFetchingInstanceList,
    fetchInstanceData,
    fetchEventList,
    fetchChecklistByKeys,
  } = useStore(ChecklistInstancesController);

  const { user } = useStore(ProfileStore);
  const { selectedOrganizationId } = useStore(OrganizationsStore);
  const { getAccessRuleValue } = useStore(CheckAccessStore);

  const params = useTasksParams();
  const routeActions = useTasksRouteActions();

  const selectedCardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return () => checklistsStore.clearMode();
  }, []);

  useEffect(() => {
    const isViewMode = checklistsStore.mode === 'VIEW';
    const instanceCardId = createInstanceCardId(params.checklistInstanceId);

    if (selectedCardRef.current?.id === instanceCardId && isViewMode) {
      setTimeout(() => {
        if (selectedCardRef.current) {
          selectedCardRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center',
          });
        }
      }, 300);
    }
  }, [params.checklistInstanceId, drawInstanceList, checklistsStore.mode]);

  // Получаем данные точки. Если у точки заполнен чек-лист, то и его данные тоже
  useEffect(() => {
    (async () => {
      if (!params.checklistInstanceId) return;

      const instanceData = await fetchInstanceData(params.checklistInstanceId);

      clearSelectedInstanceEventList();

      if (instanceData?.instance?.isActive) {
        await fetchEventList(instanceData?.instance?.id);
      }
    })();
  }, [params.checklistInstanceId]);

  useEffect(() => {
    (async () => {
      if (selectedInstance?.id !== params.checklistInstanceId) return;
      if (taskStore.selectedTask?.intensityRequired) return;

      await fetchChecklistByKeys({
        organizationId: params.orgId === 'my' ? '' : params.orgId,
        operationTypeId: taskStore.selectedTask?.operationInfo?.operationTypeInfo?.id,
        cultureId: taskStore?.selectedTask?.operationInfo?.cultureId,
        type: getTypeForChecklist(selectedInstance),
      });
    })();
  }, [params.checklistInstanceId, selectedInstance]);

  const handleGoToChecklist = (id: string): void => {
    if (checklistsStore.mode === EChecklistMode.Edit) {
      routeActions.goToChecklistEdit(id);
    } else {
      routeActions.goToChecklist(id);
    }
  };

  const handleClickOnInstanceCard = (id: string): void => {
    if (isFetching) {
      return;
    }

    const isExactlyThisSelectedInst = id === params.checklistInstanceId;

    if (idOfUnsavedAttr && !isExactlyThisSelectedInst) {
      getModalWarningBeforeChangeInstance(() => handleGoToChecklist(id));

      return;
    }

    if (isExactlyThisSelectedInst) {
      return;
    }

    handleGoToChecklist(id);
  };

  const isUserIsAssignee = Boolean(taskStore.selectedTask?.assignee?.id === user?.id);
  const isOrgMy = selectedOrganizationId === 'my';

  const allowGoToEditAndDelete =
    isOrgMy ||
    (getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS) && isUserIsAssignee) ||
    getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS);

  const isDisplayEditButton = useMemo(() => {
    return (
      Boolean(
        taskStore?.selectedTask?.availableActions?.includes(ETaskAction.TaskEditCheckLists)
      ) &&
      Boolean(selectedInstance) &&
      selectedInstance.isComplete
    );
  }, [taskStore?.selectedTask, selectedInstance]);

  const isShowPlug = useMemo(() => {
    return (
      Boolean(selectedInstance) &&
      !selectedInstance.isComplete &&
      checklistsStore.mode !== EChecklistMode.Edit
    );
  }, [selectedInstance, checklistsStore.mode]);

  const plugData = useMemo(() => {
    let description = '';
    let buttonTitle = '';

    if ([ETaskStatus.InWork, ETaskStatus.New].includes(taskStore?.selectedTask?.status)) {
      description = 'Если вы готовы внести данные в чек-лист, нажмите «Заполнить»';
      buttonTitle = 'Заполнить';
    }

    if ([ETaskStatus.Canceled, ETaskStatus.Completed].includes(taskStore?.selectedTask?.status)) {
      description = 'Для того, чтобы внести данные в чек-лист, необходимо задачу вернуть в работу';
    }

    return { description, buttonTitle };
  }, [taskStore?.selectedTask?.status]);

  const isInstanceSelected = useMemo(
    () => Boolean(drawInstanceList?.find(instance => instance.id === params?.checklistInstanceId)),
    [drawInstanceList, params?.checklistInstanceId]
  );

  const isShowChecklistLoader = useMemo(() => {
    if (!selectedInstance) return true;

    if (selectedInstance.isComplete && !checklistsStore.selectedChecklist) return true;
  }, [selectedInstance, checklistsStore.selectedChecklist]);

  const Loader = (
    <Container>
      <ContentLoader
        overlayStyles={{
          position: 'relative',
          height: '352px',
          width: '100%',
        }}
      />
    </Container>
  );

  return (
    <>
      {checklistsStore.mode === EChecklistMode.View && (
        <Content>
          <PointTitle>Точки осмотра</PointTitle>

          <CardList>
            {isFetchingInstanceList
              ? Array(6)
                  .fill(null)
                  .map((_, index) => <SkeletonInstanceCard key={index} />)
              : drawInstanceList.map(({ id, instance: combineInstance, positionNumber }) => {
                  const instance = combineInstance as IGetChecklistInstanceByTaskId;

                  return (
                    <InstanceCard
                      key={id}
                      instance={instance}
                      number={positionNumber}
                      isSelected={instance.id === params.checklistInstanceId}
                      onCardClick={handleClickOnInstanceCard}
                      disabled={!allowGoToEditAndDelete}
                      ref={id === params.checklistInstanceId ? selectedCardRef : null}
                    />
                  );
                })}
          </CardList>

          {isInstanceSelected && (
            <History>
              {isDisplayEditButton && (
                <ButtonWrapper>
                  <ButtonLink
                    color="accent"
                    onClick={() => {
                      routeActions.goToChecklistEdit(params.checklistInstanceId);
                    }}
                  >
                    Редактировать чек-лист
                  </ButtonLink>
                </ButtonWrapper>
              )}
            </History>
          )}
        </Content>
      )}

      {checklistsStore.mode === EChecklistMode.Edit && selectedInstance ? <Intensity /> : null}

      {isShowChecklistLoader ? Loader : null}
      {checklistsStore.selectedChecklist && !isShowPlug ? <ChecklistContainer /> : null}

      {isShowPlug && (
        <NoDataWrapper>
          <NoDataTitle>В этой точке еще нет данных</NoDataTitle>
          <NoDataDescription>{plugData.description}</NoDataDescription>

          {Boolean(plugData.buttonTitle) && (
            <NoDataButton
              onClick={() => {
                routeActions.goToChecklistEdit(params.checklistInstanceId);
              }}
            >
              {plugData.buttonTitle}
            </NoDataButton>
          )}
        </NoDataWrapper>
      )}

      {checklistsStore.mode === EChecklistMode.Edit && (
        <ModalContainer uniqueKey={checklistModalUniqueKey} $size={EModalSize.Medium} />
      )}
    </>
  );
};

Checklists.displayName = 'Checklists';

export default observer(Checklists);
