import { ISelectOption } from '../../../../../../types/selectOption';
import { TypeUser } from '../../../../../../api/models/user.model';

const createUserSelectOption = (user: TypeUser): ISelectOption => {
  return { label: user.userName, value: user.userId };
};

const createUserSelectOptionList = (userList: TypeUser[]): ISelectOption[] => {
  return userList.map<ISelectOption>(user => createUserSelectOption(user));
};

export { createUserSelectOption, createUserSelectOptionList };
