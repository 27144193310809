import Skeleton from 'react-loading-skeleton';

import Styled from './TimeLineSkeleton.styles';

const TimeLineSkeleton = () => (
  <Styled.Wrapper>
    <Styled.Timeline>
      {/* @ts-ignore */}
      <Skeleton />
    </Styled.Timeline>
    <Styled.Calendar>
      {/* @ts-ignore */}
      <Skeleton />
    </Styled.Calendar>
  </Styled.Wrapper>
);
export default TimeLineSkeleton;
