import { useModal } from '@farmlink/farmik-ui';
import { useCallback } from 'react';

import { createWarningBeforeDeleting } from '../../configs/modals/global';

type ShowWarningBeforeDeleting = (title: string, successHandler: () => void) => void;

interface Actions {
  showWarningBeforeDeleting: ShowWarningBeforeDeleting;
}

const useWarningBeforeDeleting = (): Actions => {
  const { registerModalList, openModalByModalId } = useModal();

  const showWarningBeforeDeleting = useCallback<ShowWarningBeforeDeleting>(
    (title, successHandler) => {
      const modalConfig = createWarningBeforeDeleting(title, successHandler);

      registerModalList(modalConfig.configList, modalConfig.key);

      openModalByModalId(modalConfig.name);
    },
    []
  );

  return {
    showWarningBeforeDeleting,
  };
};

export default useWarningBeforeDeleting;
