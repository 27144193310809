import { FC } from 'react';

import { Colors } from '../../modules/shared/constans/colors';

import { ContentWrapper, PageWrapper, MobileHardFix } from './style';

export const Agreement: FC = () => (
  <PageWrapper>
    <ContentWrapper>
      <h2>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h2>
      <p>Дата публикации: «28» октября 2021 г.</p>
      <p>
        Общество с ограниченной ответственностью «Диджитал Агро» (ОГРН 1085260011077; ИНН
        5260231295) (далее – «Владелец Сервиса») предлагает дееспособным физическим лицам,
        юридическим лицам и индивидуальным предпринимателям (далее – «Пользователь») заключить
        настоящее Пользовательское соглашение (далее – «Соглашение»).
      </p>
      <p>
        Безусловным акцептом Пользователем Соглашения является выполнение действий, указанных в
        п.4.2.1, 4.2.2, 4.2.4 Соглашения.
      </p>
      <p>
        С момента совершения акцепта Пользователь считается ознакомившимся и согласившимся с
        Соглашением и вступившим с Владельцем Сервиса в договорные отношения.
      </p>
      <p>
        Пользователь удостоверяет, что условия Соглашения принимаются им без каких-либо возражений,
        Пользователь понял и принял значение используемых в настоящем Соглашении и на Сервисе
        терминов, слов и выражений согласно их нормативно-правовому определению и/или толкованию,
        указанному в Соглашении.
      </p>
      <p>
        Выражая свое согласие с условиями Соглашения, Пользователь также соглашается с Правилами
        модерации Контента, являющимися приложением к Соглашению и его неотъемлемой частью.
      </p>
      <p>Соглашение не требует двустороннего подписания и действительно в электронном виде.</p>
      <p>
        <strong>1. Термины</strong>
      </p>
      <p>Стороны используют указанные термины в следующих значениях:</p>
      <ul>
        <li>
          1.1 <strong>Сервис (Помощник)</strong> – информационная система, включающая
          программно-аппаратный комплекс, состоящий веб-приложения, размещенного на доменном имени
          https://ast.digitalagro.ru (далее – «Приложение»), программ для ЭВМ, баз данных, дискового
          пространства и оборудования.
        </li>
        <li>
          1.2 <strong>Владелец Сервиса</strong> – ООО «Диджитал Агро» (ОГРН 1085260011077; ИНН
          5260231295).
        </li>
        <li>
          1.3 <strong>Личный кабинет</strong> – защищенная часть Сервиса, создаваемая при
          Регистрации, которая позволяет Пользователю взаимодействовать с другими Пользователями и
          пользоваться иным функционалом Сервиса. Доступ к Личному кабинету осуществляется
          Пользователем посредством ввода Идентификационных данных.
        </li>
        <li>
          1.4 <strong>Идентификационные данные</strong> – информация, предназначенная для
          идентификации Пользователя в процессе использования Сервиса. В качестве Идентификационных
          данных выступает логин и пароль.
          <br />
          Логином является номер телефона / адрес электронной почты Пользователя, вводимый для
          получения доступа к Личному кабинету в Сервисе. Действия, совершенные с использованием
          Идентификационных данных Пользователя, считаются совершенными Пользователем.
        </li>
        <li>
          1.5 <strong>Производственные данные (Контент)</strong> — информация, материалы, документы
          и иные объекты, загружаемые Пользователем в Сервисе, а также данные, генерируемые Сервисом
          для Пользователя в результате использования функционала Сервиса.
        </li>
        <li>
          1.6 <strong>Пользователь</strong> – дееспособное физическое лицо, юридическое лицо или
          индивидуальный предприниматель, акцептовавшее Соглашение и использующее Сервис в
          соответствии с Соглашением.
        </li>
        <li>
          1.7 <strong>Аккаунт</strong> − учетная запись Пользователя, создаваемая при Регистрации,
          содержащая Идентификационные данные и иные данные, предоставленные Пользователем в
          процессе использования Сервиса.
        </li>
        <li>
          1.8 <strong>Регистрация</strong> – совершение Пользователем последовательности действий,
          указанных в п.4.2 Соглашения.
        </li>
        <li>
          1.9 <strong>Регистрационная форма</strong> – электронная форма, которую Пользователь
          должен заполнить при прохождении процедуры Регистрации в Сервисе.
        </li>
        <li>
          1.10 <strong>Служба поддержки</strong> – служба поддержки Владельца Сервиса, с которой
          можно связаться по адресу электронной почты: support@digitalagro.ru для получения
          информации или разрешения спорных ситуаций.
        </li>
        <li>
          1.11 <strong>Законодательство</strong> – действующее законодательство РФ.
        </li>
      </ul>
      <p>
        Иные термины трактуются в соответствии с Законодательством и практикой делового оборота.
      </p>
      <p>
        <strong>2. Предмет Соглашения</strong>
      </p>
      <ul>
        <li>
          2.1 Владелец Сервиса безвозмездно на условиях простой (неисключительной) лицензии
          предоставляет Пользователю право использования Сервиса, а Пользователь обязуется
          использовать Сервис в соответствии с условиями Соглашения.
        </li>
        <li>2.2 Территория предоставляемых прав – все страны мира.</li>
        <li>2.3 Срок предоставляемых прав – срок действия Соглашения.</li>
        <li style={{ color: Colors.red }}>
          2.4 Владелец Сервиса осуществляет обновление Сервиса, то есть устранение ошибок и
          совершенствование его работы. Обновления являются неотъемлемой частью Сервиса, поэтому
          действие настоящего Соглашения распространяется на любые обновления, произведенные
          Владельцем Сервиса.
        </li>
        <li>
          2.5 Перед акцептом Соглашения Пользователь обязан ознакомиться с Политикой обработки
          персональных данных, постоянно размещенной в публичном доступе в Сервисе.
        </li>
        <li>
          2.6 Пользователь дает право Владельцу Сервиса распоряжаться всем размещаемым на Сервису
          Контентом на условиях безвозмездной простой (неисключительной) лицензии. Территория
          передаваемых прав – все страны мира. Срок передаваемых прав – срок действия Соглашения.
        </li>
      </ul>
      <p>
        <strong>3. Правила использования Сервиса</strong>
      </p>
      <ul>
        <li>
          3.1 Пользователь вправе: <br />
          <ul>
            <li>
              3.1.1 Получать круглосуточный доступ к Сервису за исключением времени профилактических
              работ. Пользователь получает доступ к Личному кабинету путем авторизации с
              использованием логина и пароля.
            </li>
            <li>
              3.1.2 Совершать любые действия, вытекающие из доступного Пользователю функционала и не
              запрещенные Владельцем Сервиса.
            </li>
            <li>
              3.1.3 При выявлении сбоев в работе Сервиса потребовать от Владельца Сервиса их
              устранения в кратчайшие сроки.
            </li>
            <li>
              3.1.4 Изменять данные учетной записи, в том числе пароль для доступа к Аккаунту.
            </li>
            <li>
              3.1.5 В любой момент удалить свою учетную запись, обратившись в Службу поддержки,
              указав тему письма «Удаление учетной записи».
            </li>
            <li>3.1.6 Взаимодействовать с Владельцем Сервиса по поводу использования Сервиса.</li>
          </ul>
        </li>
        <li>
          3.2 Пользователь обязан:
          <br />
          <ul>
            <li>3.2.1 Предоставлять Сервису достоверные сведения и данные.</li>
            <li>
              3.2.2 Обеспечивать конфиденциальность информации, касающейся доступа в Аккаунт,
              включая пароль.
            </li>
            <li>
              3.2.3 Немедленно уведомить Владельца Сервиса о любом случае несанкционированного (не
              разрешенного Пользователем) доступа к Сервису с использованием Личного кабинета и/или
              о любом нарушении или подозрениях на нарушение конфиденциальности своего пароля и/или
              любом другом нарушении системы безопасности.
            </li>
            <li>3.2.4 Исполнять иные обязанности, предусмотренные настоящим Соглашением.</li>
          </ul>
        </li>
        <li>
          3.3 Пользователю запрещается:
          <br />
          <ul>
            <li>
              3.3.1 Модифицировать Сервис, то есть производить любые его изменения, в том числе
              переводить с одного языка программирования на другой язык.
            </li>
            <li>
              3.3.2 Декомпилировать, дизассемблировать Сервис, осуществлять любые действия,
              направленные на восстановление исходного программного кода Сервиса.
            </li>
            <li>
              3.3.3 Адаптировать Сервис (то есть вносить изменения, осуществляемые исключительно в
              целях функционирования на конкретных технических средствах Пользователя или под
              управлением конкретных программ Пользователя), исправлять ошибки или производить
              какие-либо иные изменения в Сервисе.
            </li>
            <li>
              3.3.4 Использовать Сервис способом, не предусмотренным Соглашением и (или) нарушающим
              Законодательство, в том числе путем размещения на Сервисе материалов, нарушающих
              исключительные права третьих лиц, порочащих честь, достоинство и (или) деловую
              репутацию третьих лиц, нарушающие, включая, но не ограничиваясь, законодательство об
              информации, о рекламе, персональных данных.
            </li>
            <li>
              3.3.5 Передавать доступ к Аккаунту в Сервисе третьим лицам на возмездной или
              безвозмездной основе, в том числе посредством сублицензирования.
            </li>
            <li>3.3.6 Изменять или удалять любую информацию о правообладателе Сервиса.</li>
            <li>
              3.3.7 Предпринимать попытки обойти технические ограничения и средства защиты,
              установленные Сервисом.
            </li>
            <li>
              3.3.8 Использовать любые технические средства для сбора и обработки информации в
              Сервисе, включая персональные данные Пользователей.
            </li>
            <li>
              3.3.9 Размещать Контент, противоречащий требованиям, предусмотренным
              Законодательством. Владелец Сервиса не проводит модерацию (проверку) Контента,
              загружаемого Пользователем посредством функционала Сервиса. Пользователь осознает и
              соглашается, что должен единолично оценивать все риски, связанные с размещением
              Контента в Сервисе, и готов возместить все убытки Владельцу Сервиса в случаях, когда
              Владельцу Сервиса будет предъявлена претензия в связи размещением Пользователем
              комментария в Сервисе.
            </li>
          </ul>
        </li>
        <li>
          3.4 Владелец Сервиса вправе:
          <br />
          <ul>
            <li>
              3.4.1 Предоставлять Сервис Пользователю в объеме, который соответствует
              предоставленному Пользователю доступу к функционалу Сервиса.
            </li>
            <li>
              3.4.2 Вносить любые изменения в структуру и алгоритмы работы Сервиса, приостанавливать
              его работоспособность или работоспособность его функций, изменять или прекращать
              выполнение функций Сервиса без заблаговременного предупреждения Пользователя и без
              выражения последним согласия на это.
            </li>
            <li>
              3.4.3 За неисполнение Пользователем условий Соглашения или требований
              Законодательства:
              <br />
              <ul>
                <li>
                  1) Прекратить доступ Пользователя к Сервису до устранения нарушения, уведомив об
                  этом Пользователя.
                </li>
                <li>
                  2) Расторгнуть Соглашение в одностороннем порядке и удалить учетную запись
                  Пользователя в случае неустранения нарушения в течение месяца с даты уведомления
                  Пользователя. При этом Владелец Сервиса вправе потребовать возмещения убытков,
                  причиненных таким нарушением.
                </li>
              </ul>
            </li>
            <li>
              3.4.4 Владелец Сервиса не осуществляет проверку Контента и (или) действий
              Пользователей на соответствие требованиям Соглашения и применимого Законодательства,
              но в случаях, установленных Правилами модерации Контента, оставляет за собой право
              принимать меры, предусмотренные данными Правилами.
            </li>
          </ul>
        </li>
        <li>
          3.5 Владелец Сервиса обязан:
          <br />
          <ul>
            <li>
              3.5.1 Обеспечить бесперебойную работу Сервиса и предоставить Пользователю
              круглосуточный доступ к Сервису за исключением времени профилактических работ.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <strong>4. Регистрация Пользователя</strong>
      </p>
      <ul>
        <li>
          4.1 Пользователь проходит процедуру Регистрации, в результате которой для Пользователя
          создается уникальная учетная запись.
        </li>
        <li>
          4.2 Регистрация Пользователя возможна посредством заполнения Регистрационной формы в
          следующем порядке: <br />
          <ul>
            <li>
              4.2.1 вводит номер телефона и код подтверждения, указанный в SMS-сообщении,
              направленном Пользователю, или подтверждает адрес электронной почты путем перехода по
              ссылке из электронного письма;
            </li>
            <li>
              4.2.2 подтверждает, что ознакомился с текстом Соглашения, и дает согласие на обработку
              персональных данных путем активации чек-бокса рядом с текстом «Настоящим подтверждаю
              согласие с условиями Пользовательского соглашения и даю свое согласие на обработку
              персональных данных»;
            </li>
            <li>
              4.2.3 Вводя адрес электронной почты в процессе Регистрации, Пользователь соглашается
              на получение на адрес электронной почты письма, содержащего URL-ссылку для
              подтверждения Регистрации, и обязуется пройти по данной ссылке;
            </li>
          </ul>
        </li>
        <li>
          4.3 Регистрация Пользователя считается завершенной с момента совершения всех действий,
          указанных в п.4.2.1, 4.2.2, 4.2.4 Соглашения.
        </li>
        <li>
          4.4 Регистрируясь в Сервисе, Пользователь удостоверяет, что условия Соглашения принимаются
          им без каких-либо возражений, Пользователь понял и принял значение используемых в
          настоящем Соглашении и в Сервисе терминов, слов и выражений согласно их
          нормативно-правовому определению и/или толкованию, указанному в Соглашении.
        </li>
        <li>
          4.5 В случае несогласия Пользователя с какими-либо из пунктов настоящего Соглашения,
          Пользователь не вправе использовать Сервис. Факт использования Пользователем Сервиса после
          Регистрации приравнивается к полному и безоговорочному акцепту настоящего Соглашения.
        </li>
        <li>
          4.6 После прохождения процедуры Регистрации Пользователю предоставляется полный бесплатный
          функционал Сервиса. Доступ к платному функционалу предоставляется после его оплаты.
        </li>
        <li>
          4.7 Для Регистрации пользователь обязуется предоставить достоверную и полную информацию о
          себе по вопросам, предлагаемым в Регистрационной форме, и поддерживать эту информацию в
          актуальном состоянии в Личном кабинете.
        </li>
        <li>
          4.8 Установление факта или наличие у Владельца Сервиса оснований полагать, что
          предоставленные Пользователем данные недостоверны, порождает право Владельца Сервиса
          временно заблокировать или полностью удалить учетную запись Пользователя.
        </li>
        <li>
          4.9 Пользователь самостоятельно несет ответственность за все действия, совершенные под
          учетной записью Пользователя, включая случаи добровольной передачи Пользователем данных
          для доступа к учетной записи пользователя третьим лицам на любых условиях.
        </li>
      </ul>
      <p>
        <strong>5. Информация об Организации и ее сотрудниках</strong>
      </p>
      <ul>
        <li>
          5.1 В Аккаунте Пользователь вправе указать дополнительную информацию об Организации,
          добавить информацию о сотрудниках Организации и давать им различные права на изменение
          информации в Личном кабинете.
        </li>
        <li>
          5.2 Загружая в Сервис персональные данные сотрудников, Пользователь предварительно получил
          их письменное согласие на передачу данных Владельцу Сервиса, а также на осуществление
          коммуникации с потребителями в соответствии с законодательством Российской Федерации.
        </li>
      </ul>
      <p>
        <strong>6. Ответственность Сторон</strong>
      </p>
      <ul>
        <li>
          6.1 За ненадлежащее исполнение условий настоящего Соглашения Стороны несут ответственность
          в соответствии с Законодательством и условиями данных соглашений.
        </li>
        <li>
          6.2 Сервис предоставляется Пользователю «как есть» (as is) в соответствии с общепринятым в
          международной практике принципом. Это означает, что за проблемы, возникающие в процессе
          обновления, поддержки и эксплуатации Сервиса (в том числе проблемы совместимости с другими
          программными продуктами (пакетами, драйверами и др.), несоответствия результатов
          использования Сервиса ожиданиям Пользователя и т. п.), Владелец Сервиса ответственности не
          несет. Пользователь должен понимать, что несет полную ответственность за возможные
          негативные последствия, вызванные несовместимостью или конфликтами Сервиса с другими
          программными продуктами.
        </li>
        <li>
          6.3 Пользователь самостоятельно несет ответственность перед третьими лицами за свои
          действия, связанные с использованием Сервиса, в том числе, если такие действия приведут к
          нарушению прав и законных интересов третьих лиц, а также за соблюдение Законодательства
          при использовании Сервиса.
        </li>
        <li>
          6.4 В случае если по вине Пользователя третьим лицом в отношении Владельца Сервиса
          предъявлены какие-либо требования, Пользователь обязан возместить Владельцу Сервиса все
          убытки, прямые и косвенные, возникшие у Владельца Сервиса в связи с предъявленными
          требованиями, в течение 10 (десяти) рабочих дней после направления Пользователю требования
          о возмещении убытков.
        </li>
        <li>
          6.5 Владелец Сервиса не является стороной договоров, заключаемых между Пользователями с
          использованием функционала Сервиса. Владелец Сервиса не является автором Контента, а также
          правообладателем включенных в Контент результатов интеллектуальной деятельности.
        </li>
        <li>
          6.6 Владелец Сервиса не несет ответственности за любые виды убытков, произошедшие
          вследствие использования Пользователем Сервиса или отдельных частей/функций Сервиса.
        </li>
        <li>
          6.7 Владелец Сервиса прилагает все возможные усилия для обеспечения нормальной
          работоспособности Сервиса, однако не несет ответственности за прямые и косвенные убытки
          Пользователя, включая упущенную выгоду и возможный ущерб, возникший в том числе в
          результате: <br />
          <ul>
            <li>
              6.7.1 неправомерных действий пользователей сети Интернет, направленных на нарушение
              информационной безопасности или нормального функционирования Сервиса;
            </li>
            <li>
              6.7.2 отсутствия (невозможности установления, прекращения и пр.) интернет-соединения
              между сервером Пользователя и сервером, где расположен Сервис;
            </li>
            <li>
              6.7.3 за невозможность использования Сервиса или ее части по причинам, зависящим от
              Пользователя или третьих лиц;
            </li>
            <li>
              6.7.4 проведение государственными и муниципальными органами, а также иными
              организациями мероприятий в рамках оперативно-розыскных мероприятий;
            </li>
            <li>
              6.7.5 за неисправности, ошибки и сбои в работе программно-аппаратного комплекса,
              обеспечивающего функционирование Сервиса;
            </li>
            <li>
              6.7.6 за временное отсутствие у Пользователя доступа к Сервису, Личному кабинету в
              связи с проведением профилактических и иных работ;
            </li>
            <li>
              6.7.7 в других случаях, связанных с действиями (бездействием) пользователей сети
              Интернет и/или других субъектов, направленными на ухудшение общей ситуации с
              использованием сети Интернет и/или компьютерного оборудования.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <strong>7. Разрешение споров</strong>
      </p>
      <ul>
        <li>7.1 Все споры и разногласия разрешаются Сторонами путем переговоров.</li>
        <li>
          7.2 В случае если Стороны не урегулировали спор посредством переговоров, он подлежит
          урегулированию посредством направления претензии. Стороны соглашаются с тем, что
          претензионный порядок является обязательным по всем спорам между Сторонами.
        </li>
        <li>
          7.3 Срок направления ответа на претензию не может превышать 30 (тридцать) календарных
          дней.
        </li>
        <li>
          7.4 Все претензии Сторон и ответы на них направляются в письменном виде по адресу
          электронной почты Стороны.
        </li>
        <li>
          7.5 Неурегулированные споры в рамках Соглашения передаются на рассмотрение в суд по месту
          нахождения Владельца Сервиса.
        </li>
      </ul>
      <p>
        <strong>8. Обстоятельства непреодолимой силы</strong>
      </p>
      <ul>
        <li>
          8.1 Стороны освобождаются от ответственности, если обязательства не выполнены или
          выполнены ненадлежащим образом из-за обстоятельств непреодолимой силы, возникших после
          заключения Соглашения, которые Сторона, ссылающаяся на такие обстоятельства, не могла
          предвидеть и предотвратить разумными мерами.
        </li>
        <li>
          8.2 Под непреодолимой силой понимаются чрезвычайные и непреодолимые при данных условиях
          обстоятельства, препятствующие исполнению Сторонами своих обязательств по Договору. К ним
          относятся стихийные явления (землетрясения, наводнения и т. п.), обстоятельства
          общественной жизни (военные действия, чрезвычайные положения, крупнейшие забастовки,
          эпидемии и т. п.), запретительные меры государственных органов (запрещение перевозок,
          валютные ограничения, международные санкции запрета на торговлю и т. п.).
        </li>
        <li>
          8.3 Сторона, ссылающаяся на обстоятельства непреодолимой силы, подтверждает такие
          обстоятельства документами, выданными уполномоченными государственными органами или иными
          допустимыми доказательствами.
        </li>
        <li>
          8.4 Сторона, ссылающаяся на обстоятельства непреодолимой силы, обязана в кратчайший срок
          известить об этом другую Сторону.
        </li>
        <li>
          8.5 В течение этого времени Стороны не имеют взаимных претензий, и каждая из Сторон
          принимает на себя свой риск последствия обстоятельств непреодолимой силы.
        </li>
      </ul>
      <p>
        <strong>9. Действие Соглашения</strong>
      </p>
      <ul>
        <li>
          9.1 Соглашение вступает в силу с даты акцепта и действует до расторжения Сторонами либо до
          прекращения действия Соглашения.
        </li>
        <li>
          9.2 Соглашение может быть расторгнуто: <br />
          <ul>
            <li>9.2.1 По взаимному соглашению Сторон.</li>
            <li>9.2.2 По инициативе Владельца Сервиса в соответствии с п.3.4.3 Соглашения.</li>
            <li>
              9.2.3 По инициативе Пользователя в одностороннем внесудебном порядке путем удаления
              своей учетной записи.
            </li>
          </ul>
        </li>
        <li>
          9.3 Владелец Сервиса имеет право изменить Соглашение в одностороннем порядке. Изменения
          вступают в силу на следующий день с момента размещения в Сервису новой редакции
          Соглашения, о чем Пользователю направляется соответствующее уведомление.
        </li>
        <li>
          9.4 Использование Сервиса означает согласие Пользователя с новой редакцией Соглашения. В
          случае несогласия с каким-либо положением Соглашения и (или) приложений к нему
          Пользователь обязан прекратить использование Сервиса.
        </li>
        <li>
          9.5 Владелец Сервиса вправе отозвать оферту на основании ст. 436 Гражданского кодекса
          Российской Федерации. Соглашение считается прекращенным с момента получения отзыва. Отзыв
          осуществляется путем размещения соответствующей информации в Сервису.
        </li>
      </ul>
      <p>
        <strong>10. Заключительные положения</strong>
      </p>
      <ul>
        <li>
          10.1 Взаимодействие между Сторонами осуществляется с использованием функционала Сервиса
          или контактов, указанных Пользователями при Регистрации и (или) при использовании
          Мобильного приложения.
        </li>
        <li>10.2 К настоящему Соглашению применяется право Российской Федерации.</li>
        <li>
          10.3 По всем вопросам, не урегулированным настоящим Соглашением, Стороны будут
          руководствоваться Законодательством.
        </li>
      </ul>
      <p>
        <strong> Реквизиты Владельца Сервиса</strong>
      </p>
      <ul>
        <li>Наименование: ООО «Диджитал Агро»</li>
        <li>Контактный телефон: +7 (499) 647–59–74</li>
        <li>Адрес электронной почты: support@digitalagro.ru</li>
        <li>
          Юридический адрес: 123112, город Москва, Пресненская набережная, дом 6 строение 2,
          помещение 101 (34 этаж)
        </li>
        <li>ОГРН: 1085260011077</li>
        <li>ИНН: 5260231295</li>
      </ul>
      <MobileHardFix />
    </ContentWrapper>
  </PageWrapper>
);
