import {
  IReportDataFilter,
  IReportFileFormatFilter,
} from '../../../models/as-fields/reports/biConfig.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  reportDataFilter: IReportDataFilter;
  reportFileFormatFilter: IReportFileFormatFilter;
};

type TypeResponse = {};

export const getArrayFilterBiReportCsvFile: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/reports/power-bi/csv`,
  method: 'POST',
  responseType: 'blob',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
