import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  code: string;
  operId: string;
};

type TypeResponse = {
  token: string;
};

export const checkUserSmsCode: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: `/api/da-profile/users/checkSmsCode`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
