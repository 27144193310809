import { FC, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStore } from '../shared/utils/IoC';
import { TipWrapper } from '../shared/containers/TipWrapper';
import { DeviceScreenDummy } from '../../components/DeviceScreenDummy';
import { AdminPanel } from '../../components/AdminPanel/AdminPanel';
import MapStore from '../map/stores/map.store';
import { MobilePlaceHolder } from '../MobilePlaceHolder';
import { SpinnerLoader } from '../shared/components/loaders';

import { Layout, RightColumn, SideBarWrapper } from './components/DashboardLayout/style';
import { Sidebar } from './components/Sidebar/Sidebar';
import { AdaptiveSidebar } from './components/AdaptiveSidebar/AdaptiveSidebar';
import { LeaveUnitModalDialog } from './components/LeaveUnitModalDialog/LeaveUnitModalDialog';
import { FieldsStore } from './modules/fields/stores/fields.store';
import { useOrganizationMatcher } from './hooks';
import { OrganizationsController } from './controllers/organizations/organizations.controller';

export const DashboardRouter: FC = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const { orgId } = useParams();
  const mapStore = useStore(MapStore);
  const fieldsStore = useStore(FieldsStore);
  const { fetchOrganizations } = useStore(OrganizationsController);

  const { matchOrganization } = useOrganizationMatcher();

  useEffect(() => {
    setIsLoading(true);

    fetchOrganizations().then(orgList => {
      matchOrganization(orgList, orgId).finally(() => setIsLoading(false));
    });
  }, []);

  useEffect(() => {
    mapStore.clearPolygons();
    fieldsStore.clearIdToFields();
  }, [orgId]);

  if (isLoading) {
    return (
      <div>
        <SpinnerLoader needToHideContent={false} />
      </div>
    );
  }

  return (
    <>
      <MobilePlaceHolder />
      <TipWrapper children={null} />
      <AdaptiveSidebar />
      <AdminPanel />
      <Layout>
        <DeviceScreenDummy />
        <LeaveUnitModalDialog />
        <SideBarWrapper>
          <Sidebar isAdaptive={false} />
        </SideBarWrapper>

        <RightColumn>
          <Outlet />
        </RightColumn>
      </Layout>
    </>
  );
});
