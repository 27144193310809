import { useMemo } from 'react';

import { IChecklistsAttrToDraw } from '../../models';

const useChecklistsAttrErrorList = (
  parentSchema: IChecklistsAttrToDraw['validationScheme'],
  dependentFileSchema?: IChecklistsAttrToDraw['validationScheme']
): string[] => {
  return useMemo<string[]>(() => {
    const isShownErrorInParentAttr: boolean = parentSchema.isShowError;
    const isShownErrorInFileAttr: boolean = dependentFileSchema?.isShowError;
    const isShownErrorInBothAttrs = Boolean(isShownErrorInParentAttr && isShownErrorInFileAttr);

    if (isShownErrorInBothAttrs) {
      return [dependentFileSchema.errorTitle, parentSchema.errorTitle];
    }

    if (isShownErrorInParentAttr) {
      return [parentSchema.errorTitle];
    }

    if (isShownErrorInFileAttr) {
      return [dependentFileSchema.errorTitle];
    }

    return [];
  }, [
    parentSchema.isShowError,
    parentSchema.errorTitle,
    dependentFileSchema?.isShowError,
    dependentFileSchema?.errorTitle,
  ]);
};

export default useChecklistsAttrErrorList;
