import { EModalType, IModal } from '../../../../../../../modals/components/Modal/Modal';
import { IModalConfig } from '../../../../../../../modals/config';

export const checklistModalUniqueKey = 'checklist';

export enum EChecklistModalName {
  WarningBeforeChangeInstance = 'WARNING_BEFORE_CHANGE_INSTANCE',
  WarningBeforeChangeIntensity = 'WARNING_BEFORE_CHANGE_INTENSITY',
  WarningBeforeLeavingThePage = 'WARNING_BEFORE_LEAVING_THE_PAGE',
  WarningBeforeDeleteFile = 'WARNING_BEFORE_DELETE_FILE',
}

const warningBeforeChangeInstance: IModal = {
  name: EChecklistModalName.WarningBeforeChangeInstance,
  title: 'Вы уверены, что хотите сменить точку? Данные не сохранятся!',
  type: EModalType.Warning,
  denyButton: {
    title: 'Отменить',
  },
  successButton: {
    title: 'Продолжить',
    color: 'secondary',
  },
};

const warningBeforeChangeIntensity: IModal = {
  name: EChecklistModalName.WarningBeforeChangeIntensity,
  title: 'При смене фенофазы все данные потеряются',
  type: EModalType.Warning,
  denyButton: {
    title: 'Отменить',
  },
  successButton: {
    title: 'Продолжить',
    color: 'secondary',
  },
};

const warningBeforeLeavingThePage: IModal = {
  name: EChecklistModalName.WarningBeforeLeavingThePage,
  title: 'Вы уверены, что хотите покинуть экран? Все несохраненные данные будут потеряны',
  type: EModalType.Warning,
  denyButton: {
    title: 'Нет, отменить',
  },
  successButton: {
    title: 'Да, продолжить',
    color: 'primary',
  },
};

const warningBeforeDeleteFile: IModal = {
  name: EChecklistModalName.WarningBeforeDeleteFile,
  title: 'Вы уверены, что хотите удалить фотографию?',
  type: EModalType.Warning,
  denyButton: {
    title: 'Отменить',
  },
  successButton: {
    title: 'Удалить',
    color: 'secondary',
  },
};

export const checklistModalsConfig: IModalConfig = {
  [checklistModalUniqueKey]: [
    warningBeforeChangeInstance,
    warningBeforeChangeIntensity,
    warningBeforeLeavingThePage,
    warningBeforeDeleteFile,
  ],
};
