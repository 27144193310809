import { useCallback, useMemo } from 'react';
import { generatePath, useLocation, useNavigate, matchPath } from 'react-router-dom';

import useTasksParams from '../useTasksParams/useTasksParams';
import { TasksRoute } from '../../tasks.route';
import { useStore } from '../../../../../shared/utils/IoC';
import { ChecklistInstancesStore } from '../../../operationsAndTasks/stores/checklist.instances.store';
import { ChecklistInstancesController } from '../../../operationsAndTasks/controllers/checklist.instances.controller';
import { TaskStore } from '../../mobx/stores';
import { ETaskTabs } from '../../models';
import { ETaskStatus } from '../../../../../../api/models/as-fields/task/task.model';

interface IDefaultTaskTab {
  tab: ETaskTabs;
  /**
   * Перешёл ли пользователь по чистой ссылке (/tasks/{id})
   *
   * Если переход был осуществлён по ней - должны отрабатывать методы определения нужного таба
   */
  isRedirectFromIndexLink: boolean;
  navigateHandler: (checklistId?: string) => void;
}

const useTasksRouteActions = () => {
  const taskStore = useStore(TaskStore);
  const { idOfUnsavedAttr } = useStore(ChecklistInstancesStore);
  const { warnBeforeLeavingThePage } = useStore(ChecklistInstancesController);

  const navigate = useNavigate();
  const params = useTasksParams();
  const location = useLocation();

  const fromPage = useMemo(() => {
    const pathList = location.pathname.split('/');
    const page = pathList.find(path => path === 'operations' || path === 'tasks');

    return page as any;
  }, [location.pathname]);

  const goToTasks = useCallback(() => {
    navigate(
      generatePath(TasksRoute.Listing, {
        orgId: params.orgId,
      })
    );
  }, [params]);

  const goToTask = useCallback(
    (taskId?: string, goFrom?: string) => {
      if (fromPage) {
        taskStore.setFromPage(fromPage);
      }

      navigate(
        generatePath(TasksRoute.Task, {
          orgId: params.orgId,
          taskId: taskId ?? params?.taskId,
        }),
        { state: { from: goFrom } }
      );
    },
    [params, fromPage]
  );

  const goToTaskEdit = useCallback(
    (selectedTaskId?: string) => {
      if (fromPage) {
        taskStore.setFromPage(fromPage);
      }

      const taskId = selectedTaskId ?? params?.taskId;

      if (!taskId) {
        return;
      }

      navigate(`/${params.orgId}/tasks/${taskId}/edit`);
    },
    [params, fromPage]
  );

  const goToTaskCreateOne = useCallback(() => {
    if (fromPage) {
      taskStore.setFromPage(fromPage);
    }

    navigate(
      generatePath(TasksRoute.TaskCreateOne, {
        orgId: params.orgId,
      })
    );
  }, [params, fromPage]);

  const goToTaskCreateGroup = useCallback(() => {
    if (fromPage) {
      taskStore.setFromPage(fromPage);
    }

    navigate(
      generatePath(TasksRoute.TaskCreateGroup, {
        orgId: params.orgId,
      })
    );
  }, [params, fromPage]);

  const goToChecklist = useCallback(
    (instanceId: string, taskId?: string) => {
      if (fromPage) {
        taskStore.setFromPage(fromPage);
      }

      navigate(
        generatePath(TasksRoute.Checklist, {
          orgId: params.orgId,
          taskId: taskId ?? params?.taskId,
          checklistInstanceId: instanceId ?? '',
        })
      );
    },
    [params, fromPage]
  );

  const goToChecklistEdit = useCallback(
    (instanceId?: string, taskId?: string) => {
      if (fromPage) {
        taskStore.setFromPage(fromPage);
      }

      navigate(
        generatePath(TasksRoute.ChecklistEdit, {
          orgId: params.orgId,
          taskId: taskId ?? params?.taskId,
          checklistInstanceId: instanceId ?? params?.checklistInstanceId,
        })
      );
    },
    [params, fromPage]
  );

  const goToMap = useCallback(
    (taskId?: string) => {
      if (fromPage) {
        taskStore.setFromPage(fromPage);
      }

      navigate(
        generatePath(TasksRoute.TaskMap, {
          orgId: params.orgId,
          taskId: taskId ?? params?.taskId,
        })
      );
    },
    [params, fromPage]
  );

  const goToMapCreatePoint = useCallback(
    (taskId?: string) => {
      if (fromPage) {
        taskStore.setFromPage(fromPage);
      }

      navigate(
        generatePath(TasksRoute.TaskMapCreatePoint, {
          orgId: params.orgId,
          taskId: taskId ?? params?.taskId,
        })
      );
    },
    [params, fromPage]
  );

  const goBackFromChecklist = useCallback(() => {
    if (idOfUnsavedAttr) {
      warnBeforeLeavingThePage(() => navigate(-1));
    } else {
      navigate(-1);
    }
  }, [history, idOfUnsavedAttr, warnBeforeLeavingThePage]);

  const getDefaultTaskTab = useCallback<() => IDefaultTaskTab>(() => {
    const isRedirectFromEdit = location.state?.from === 'edit';

    const isRedirectFromIndexLink =
      Boolean(
        matchPath(
          generatePath(TasksRoute.Task, {
            orgId: params.orgId,
            taskId: taskStore?.selectedTask?.id ?? params?.taskId,
          }),
          location.pathname
        )
      ) && !isRedirectFromEdit;

    /**
     * Задача в работе
     */
    if (taskStore?.selectedTask?.status === ETaskStatus.InWork) {
      return { tab: ETaskTabs.Map, navigateHandler: goToMap, isRedirectFromIndexLink };
    }

    /**
     * Задача завершена
     */
    if (
      taskStore?.selectedTask?.status === ETaskStatus.Completed &&
      (taskStore?.selectedTask?.checklistsAvailable ||
        taskStore?.selectedTask?.checklistsMachineryAvailable ||
        taskStore?.selectedTask?.checklistsFieldAvailable)
    ) {
      return {
        tab: ETaskTabs.Checklists,
        navigateHandler: checklistId =>
          goToChecklist(checklistId || params?.checklistInstanceId, params?.taskId),
        isRedirectFromIndexLink,
      };
    }

    return { tab: ETaskTabs.Summary, navigateHandler: goToTask, isRedirectFromIndexLink, params };
  }, [params, taskStore?.selectedTask, location.pathname]);

  return {
    goToTasks,
    goToTask,
    goToTaskEdit,
    goToTaskCreateOne,
    goToTaskCreateGroup,
    goToChecklist,
    goToChecklistEdit,
    goBackFromChecklist,
    goToMap,
    goToMapCreatePoint,
    getDefaultTaskTab,
  };
};

export default useTasksRouteActions;
