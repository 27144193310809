import { FC, FormEvent, useEffect, useRef, useState } from 'react';

import { timerDelay } from '../../utils/timerDelay';

import {
  Wrapper,
  SuggestHeader,
  SuggestWrapper,
  Label,
  SuggestBody,
  SuggestInnerBody,
  SuggestItem,
} from './style';

type AutosuggestProps = {
  label?: string;
  placeholder?: string;
  onSearch: (v: string) => void;
  options: Array<{ value: string; label: string; active?: boolean }>;
  onChange: (value: string, label: string) => void;
  value: string;
  dataTestId?: string;
  onOpen?: (v: string) => void;
};

export const Autosuggest: FC<AutosuggestProps> = ({
  label,
  onSearch,
  placeholder,
  options,
  onChange,
  value,
  dataTestId,
  onOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const timerRef = useRef();
  const onChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    onSearch(e.currentTarget.value);
  };

  const onSelect = (_value: string, _label: string) => {
    clearTimeout(timerRef.current!);

    onSearch(_label);
    onChange(_value, _label);
    setIsOpen(false);
  };

  const onBlur = () => {
    // setIsOpen(false);
    // await timerDelay(100);
    if (!value) {
      onChange('', '');
    }
    // @ts-ignore
    timerRef.current = setTimeout(() => setIsOpen(false), 100);
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    onOpen(value);
  }, [isOpen]);
  return (
    <Wrapper tabIndex={0} onBlur={onBlur} onFocus={() => clearTimeout(timerRef.current!)}>
      {label && <Label>{label}</Label>}
      <SuggestWrapper isOpen={isOpen && options.length > 0}>
        <SuggestHeader
          type="text"
          value={value}
          onChange={onChangeHandler}
          placeholder={placeholder}
          onFocus={() => setIsOpen(true)}
          data-test-id={dataTestId}
        />
        {options.length > 0 && (
          <SuggestBody>
            <SuggestInnerBody>
              {options.map(item => (
                <SuggestItem
                  active={item.active}
                  key={item.value}
                  onClick={() => !item.active && onSelect(item.value, item.label)}
                  data-test-id={`${dataTestId}-option-${item.value}`}
                >
                  {item.label.length > 30 ? `${item.label.substr(0, 30)}...` : item.label}
                </SuggestItem>
              ))}
            </SuggestInnerBody>
          </SuggestBody>
        )}
      </SuggestWrapper>
    </Wrapper>
  );
};
