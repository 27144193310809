import styled, { css } from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import { FontSizes } from '../../../../../shared/constans/sizes';

const Wrapper = styled.div`
  width: 320px;
`;

const CommentWrapper = styled.div<{ $blocked?: boolean; $isError?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  & textarea {
    background: ${({ $blocked }) => ($blocked ? '#fffffff' : '#faf9fd')};
    color: #151f32;
    border: 1px solid;
    border-radius: 14px;
    font-size: 12px;
    width: 100%;
    min-height: 120px;
    padding: 12px 16px;
    box-sizing: border-box;
    outline: none;
    border-color: #e9e6f0;
    color: #151f32;
    resize: none;
    pointer-events: ${({ $blocked }) => ($blocked ? 'none' : 'auto')};
    scrollbar-width: none;
    ${({ $isError }) =>
      $isError &&
      css`
        border-color: ${Colors.red};
      `}

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const CommentLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #9aa0ad;
  margin-bottom: 4px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 24px;
`;

const Title = styled.h5`
  ${FontSizes.xxlargeBold};
  color: ${Colors.generalBlack};
  margin: 0;
`;

const Delimiter = styled.div`
  margin: 24px 0 20px 0;
  width: 100%;
  height: 1px;
  background-color: ${Colors.lightnessGray};
`;

const Error = styled.p`
  ${FontSizes.default};
  color: ${Colors.pinkHover};
  margin-top: 4px;
`;

const Styled = {
  Wrapper,
  CommentWrapper,
  CommentLabel,
  ButtonsWrapper,
  Title,
  Delimiter,
  Error,
};

export default Styled;
