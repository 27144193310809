import styled from 'styled-components';

import { Colors } from '../../modules/shared/constans/colors';

export const DialogModalTitle = styled.div`
  color: ${Colors.black};
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.15px;
  margin-bottom: 12px;
`;

export const DialogModalDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  white-space: pre-line;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.1px;

  /* general/black */

  color: ${Colors.black};
  margin: 0;
  margin-top: 12px;
`;
export const IconScg = styled.img`
  width: 80px;
  display: block;
  margin: 0 auto 20px;
`;
