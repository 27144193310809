import styled from 'styled-components';

import { Colors } from '../../../shared/constans/colors';

export const MapUserControlsWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  z-index: 1005;
`;

export const MapUserControlsActionElement = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.white};
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    background: #efefef;
  }
`;

export const MapUserControlsZoomActionsWrapper = styled.div`
  width: 48px;
  height: 86px;
  margin-bottom: 10px;

  & ${MapUserControlsActionElement} {
    margin-bottom: 0;
    height: 43px;

    &:first-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;
