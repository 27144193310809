export const FOCUSED_MARKER_CLASS = 'focused-marker';

export const MAX_COORDINATE_MOVE_VALUE = 0.025;

export enum EMapValidationError {
  OutOfField = 'OUT_OF_FIELD',
  OutOfZone = 'OUT_OF_ZONE',
  OutOfMap = 'OUT_OF_MAP',
  BigStep = 'BIG_STEP',
}
