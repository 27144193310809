import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { DropdownButton, TButtonMultiAction } from '@farmlink/farmik-ui';

import { useTasksRouteActions } from '../../../../../hooks';

const TasksFiltersActionButton: FC = () => {
  const tasksRouteActions = useTasksRouteActions();

  const btnActionForCreateOneTask = useMemo<TButtonMultiAction>(() => {
    return {
      title: 'Одну',
      onClick: tasksRouteActions.goToTaskCreateOne,
    };
  }, [tasksRouteActions.goToTaskCreateOne]);

  const btnActionForCreateMultipleTask = useMemo<TButtonMultiAction>(() => {
    return {
      title: 'Группу',
      onClick: tasksRouteActions.goToTaskCreateGroup,
    };
  }, [tasksRouteActions.goToTaskCreateGroup]);

  return (
    <DropdownButton
      title={'Создать задачу'}
      multiActionList={[btnActionForCreateOneTask, btnActionForCreateMultipleTask]}
      style={{
        color: 'primary',
        size: 'sm',
        placement: 'bottom',
        width: '170px',
      }}
    />
  );
};

TasksFiltersActionButton.displayName = 'TasksFiltersActionButton';

export default observer(TasksFiltersActionButton);
