import { ISelectOption } from '../../../../../../types/selectOption';
import { CultureModel } from '../../../../../../api/models/culture.model';

const createCultureSelectOption = (culture: CultureModel): ISelectOption => {
  return { label: culture.name, value: culture.id };
};

const createCultureSelectOptionList = (cultureList: CultureModel[]): ISelectOption[] => {
  return cultureList.map<ISelectOption>(culture => createCultureSelectOption(culture));
};

export { createCultureSelectOption, createCultureSelectOptionList };
