import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  operationId: string;
};

type TypeResponse = {};

export const deleteOperation: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/operation/${request.operationId}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
