export const ACCESS_ACTIONS_MODULES = ['field', 'scouting', 'experiment'];

export enum FIELD_ACCESS_ACTIONS {}

export enum SCOUTING_ACCESS_ACTIONS {
  WORK_WITH_TASKS = 'scouting.workWithTasks',
  MANAGE_TASKS = 'scouting.manageTasks',
}

export enum EExperimentsAccessActions {
  IncludeCultureZones = 'experiments.includeCultureZones',
}
