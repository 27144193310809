import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';

import { useStore } from '../../shared/utils/IoC';
import { Modal } from '../components/Modal';
import { PropsStyledModal, StyledModalWrapper } from '../components/Modal/styles';
import { ModalsStore } from '../store/modals.store';

type PropsModalContainer = {
  uniqueKey: string;
} & PropsStyledModal;

const ModalContainer: FC<PropsModalContainer> = observer(({ uniqueKey, ...styled }) => {
  const { getModal, clearModal } = useStore(ModalsStore);

  const closeModalHandler = useCallback((): void => {
    clearModal(uniqueKey);
  }, []);

  return (
    <StyledModalWrapper>
      {getModal(uniqueKey) && (
        <Modal
          modal={getModal(uniqueKey)}
          uniqueKey={uniqueKey}
          closeModalHandler={closeModalHandler}
          {...styled}
        />
      )}
    </StyledModalWrapper>
  );
});

export default ModalContainer;
