import { observer } from 'mobx-react';
import { Icon, Typography } from '@farmlink/farmik-ui';
import moment from 'moment';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistInstancesStore as store } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';

import Styled from './History.styles';

const SOURCE_MAP: Record<string, { text: string; icon: React.ReactNode }> = {
  WEB: { text: 'Через web-браузер', icon: <Styled.DesktopIcon /> },
  MOBILE: { text: 'C мобильного устройства', icon: <Styled.MobileIcon /> },
};

const History: React.FC = ({ children }) => {
  const { selectedInstanceEventList: eventList } = useStore(store);

  return (
    <Styled.List>
      {eventList?.map((event, i) => {
        const data = SOURCE_MAP[event.source];
        const isLast = i === eventList.length - 1;

        return (
          <Styled.Item key={`${event.creationDate}_${i}`}>
            <Styled.Icon>{data.icon}</Styled.Icon>
            <Typography variant="body">{data.text ?? 'С неопознанного устройства'}</Typography>
            <Styled.Date>
              <Typography variant="body" color="secondaryDark">
                {moment(event.creationDate).format('DD MMMM YYYY в HH:mm')}
              </Typography>
            </Styled.Date>
            {isLast && <Styled.LastElementWrapper>{children}</Styled.LastElementWrapper>}
          </Styled.Item>
        );
      })}
    </Styled.List>
  );
};

export default observer(History);
