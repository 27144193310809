import { v4 as createUUID } from 'uuid';
import moment from 'moment';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { TaskStore } from '../../stores';
import { ITask, ITaskPreview } from '../../../../../../../api/models/as-fields/task/task.model';
import { TableBuilderController } from '../../../../../../shared/features/TableBuilder/mobx/controllers';
import {
  ITableBuilderCellConfig,
  ITableBuilderColumnConfig,
  ITableBuilderConfig,
} from '../../../../../../shared/features/TableBuilder/models/configs';
import { ETasksTableBuilderId } from '../../../utils/constants';
import { PreviewTasksTableOperationCell } from '../../../components/TaskFullscreen/components/PreviewTasksTable/components';
import {
  TasksListDaysLeftCell as DaysLeft,
  TasksListOperationCell as OperationCell,
  TasksListStatusCell as StatusCell,
} from '../../../containers/TasksList/components/cells';
import { createZoneName, toDouble } from '../../stores/TaskStore/Task.store';
import { ProfileStore } from '../../../../profile/stores/ProfileStore';
import { OrganizationsStore } from '../../../../../stores/organizations.store';

@provide.transient()
class TaskCreateService {
  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(OrganizationsStore)
  protected organizationStore: OrganizationsStore;

  @lazyInject(TaskStore)
  protected taskStore: TaskStore;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  addPreviewTasks = (): void => {
    const taskPreviewList = this.createPreviewTaskList();

    this.taskStore.setPreviewTaskList(taskPreviewList);

    this.tableBuilderController.addElementList(
      ETasksTableBuilderId.PreviewTasks,
      taskPreviewList,
      'id',
      { isClearPreviousList: true }
    );
  };

  createPreviewTaskList = (): ITaskPreview[] => {
    const { taskCreate } = this.taskStore;

    const isMyOrganization = this.organizationStore.selectedOrganizationId === 'my';

    const cultureName = this.taskStore.getCultureOption(taskCreate.cultureId).label;
    const operationOption = this.taskStore.getOperationOption(taskCreate.operationId);
    const assigneeName = isMyOrganization
      ? this.profileStore.fullName
      : this.taskStore.getUserOption(taskCreate.assigneeId)?.label || 'Не назначено';

    const formattedStartDate = moment(taskCreate.planStartDate).format('DD.MM.YYYY');
    const formattedEndDate = moment(taskCreate.planEndDate).format('DD.MM.YYYY');
    const dates = `${formattedStartDate} — ${formattedEndDate}`;

    return taskCreate.taskItems.map((item, index) => {
      const id = createUUID();
      const order = index + 1;
      const fieldName = this.taskStore.getFieldOption(item.cultureZoneId)?.label;

      return {
        id,
        order,
        operationName: operationOption.label,
        fieldName,
        cultureName,
        assigneeName,
        dates,
        operation: operationOption.initialModel,
      };
    });
  };

  addPreviewTableConfig = (): void => {
    const config = this.createPreviewTableConfig();

    this.tableBuilderController.initiateTable(config);
  };

  createPreviewTableConfig = (): ITableBuilderConfig<
    ITaskPreview,
    ETasksTableBuilderId.PreviewTasks
  > => {
    const columnList = this.createPreviewTableColumnConfigList();
    const cellList = this.createPreviewTableCellConfigList();

    return {
      id: ETasksTableBuilderId.PreviewTasks,
      columnConfigList: columnList,
      cellConfigList: cellList,
    };
  };

  createPreviewTableColumnConfigList = (): ITableBuilderColumnConfig<ITaskPreview>[] => {
    return [
      {
        id: 'operationName',
        name: 'Операция',
        width: {
          default: '1fr',
        },
      },
      {
        id: 'fieldName',
        name: 'Поле',
        width: {
          default: '1fr',
        },
      },
      {
        id: 'cultureName',
        name: 'Культура',
        width: {
          default: '1fr',
        },
      },
      {
        id: 'assigneeName',
        name: 'Исполнитель',
        width: {
          default: '1fr',
        },
      },
      {
        id: 'dates',
        name: 'Даты',
        width: {
          default: '1fr',
        },
      },
    ];
  };

  createPreviewTableCellConfigList = (): ITableBuilderCellConfig<ITaskPreview>[] => {
    return [
      {
        id: 'operationName',
        customRenderConfig: {
          renderCell: row => <PreviewTasksTableOperationCell taskPreview={row} />,
        },
      },
      {
        id: 'fieldName',
        autoRenderConfig: {
          renderValue: row => row.fieldName,
        },
      },
      {
        id: 'cultureName',
        autoRenderConfig: {
          renderValue: row => row.cultureName ?? 'Культура не указана',
        },
      },
      {
        id: 'assigneeName',
        autoRenderConfig: {
          renderValue: row => row.assigneeName,
        },
      },
      {
        id: 'dates',
        autoRenderConfig: {
          renderValue: row => row.dates,
        },
      },
    ];
  };

  addCreatedTasksTableConfig = (): void => {
    const config = this.createCreatedTasksTableConfig();

    this.tableBuilderController.initiateTable(config);
  };

  createCreatedTasksTableConfig = (): ITableBuilderConfig<
    ITask,
    ETasksTableBuilderId.CreatedTasks
  > => {
    const columnList = this.createCreatedTasksTableColumnConfigList();
    const cellList = this.createCreatedTasksTableCellConfigList();

    return {
      id: ETasksTableBuilderId.CreatedTasks,
      columnConfigList: columnList,
      cellConfigList: cellList,
    };
  };

  createCreatedTasksTableColumnConfigList = (): ITableBuilderColumnConfig<ITask>[] => {
    return [
      {
        id: 'operationInfo',
        name: 'Операция',
        width: {
          default: '1fr',
        },
      },
      {
        id: 'field',
        name: 'Поле',
        width: {
          default: '1fr',
        },
      },
      {
        id: 'culture',
        name: 'Культура',
        width: {
          default: '1fr',
        },
      },
      {
        id: 'assignee',
        name: 'Исполнитель',
        width: {
          default: '1fr',
        },
      },
      {
        id: 'daysLeft',
        name: 'Срок',
        width: {
          default: '110px',
        },
      },
      {
        id: 'status',
        name: 'Статус',
        width: {
          default: '95px',
        },
      },
    ];
  };

  createCreatedTasksTableCellConfigList = (): ITableBuilderCellConfig<ITask>[] => {
    return [
      {
        id: 'operationInfo',
        customRenderConfig: {
          renderCell: row => <OperationCell row={row} />,
        },
      },
      {
        id: 'field',
        autoRenderConfig: {
          renderValue: row => {
            const selectedField = row.field;
            const selectedZone = row?.cultureZone;

            const fieldName = createZoneName(selectedField?.name, selectedField.area);

            if (selectedZone) {
              const zoneName = createZoneName(selectedZone?.name, selectedZone.area);

              return selectedZone?.name
                ? `${fieldName}. ${zoneName}. ${toDouble(selectedZone.area)} га`
                : `${fieldName}. ${toDouble(selectedZone.area)} га`;
            }

            return fieldName;
          },
        },
      },
      {
        id: 'culture',
        autoRenderConfig: {
          renderValue: row => row?.culture?.name ?? 'Культура не указана',
        },
      },
      {
        id: 'assignee',
        autoRenderConfig: {
          renderValue: row => {
            const isMyOrganization = this.organizationStore.selectedOrganizationId === 'my';

            if (isMyOrganization) {
              return this.profileStore.fullName;
            } else {
              return row?.assignee?.fullName || 'Не назначено';
            }
          },
        },
      },
      {
        id: 'daysLeft',
        customRenderConfig: {
          renderCell: row => <DaysLeft row={row} />,
        },
      },
      {
        id: 'status',
        customRenderConfig: {
          renderCell: row => <StatusCell row={row} />,
        },
      },
    ];
  };
}

export default TaskCreateService;
