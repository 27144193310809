import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  id: string;
};

type TypeResponse = {};

export const getIntensityById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req => `/api/as-fields/intensity/${req.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
