export enum EChecklistAttributeFormulaConstantReturnType {
  Boolean = 'BOOLEAN',
  Double = 'DOUBLE',
  Integer = 'INTEGER',
  String = 'STRING',
  Date = 'DATE',
  UUID = 'UUID',
}

export interface IChecklistAttributeFormulaConstant {
  returnType: EChecklistAttributeFormulaConstantReturnType;
  constantValue: boolean | number | string;
}

// export interface IChecklistAttributeFormulaConstant<
//   RT extends EChecklistAttributeFormulaConstantReturnType = any
// > {
//   returnType: RT;
//   constantValue: RT extends EChecklistAttributeFormulaConstantReturnType.Boolean
//     ? boolean
//     : RT extends EChecklistAttributeFormulaConstantReturnType.Double
//     ? number
//     : RT extends EChecklistAttributeFormulaConstantReturnType.Integer
//     ? number
//     : RT extends EChecklistAttributeFormulaConstantReturnType.String
//     ? string
//     : RT extends EChecklistAttributeFormulaConstantReturnType.Date
//     ? string
//     : RT extends EChecklistAttributeFormulaConstantReturnType.UUID
//     ? string
//     : never;
// }
