import { IBreadcrumbsEntity } from '../../models/breadcrumbs/breadcrumbs.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  remoteName: string;
  id: string;
};

type TypeResponse = {} & IBreadcrumbsEntity;

export const getDictionaryItemBreadcrumbs: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: ({ remoteName, id }) => `/api/da-dictionary/dictionary/${remoteName}/breadcrumbs/${id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
