import { FC } from 'react';
import styled from 'styled-components';

import { Container } from '../../Container';
import { TechniqueListContainer } from '../TechniqueListContainer';

interface IProps {
  taskId: string;
}

const TechniqueWidget: FC<IProps> = ({ taskId }) => {
  return (
    <Container title="Техника" $isHideBodyMargin>
      <StyledContainer>
        <TechniqueListContainer taskId={taskId} isFromFullscreen />
      </StyledContainer>
    </Container>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  padding: 8px;
`;

export default TechniqueWidget;
