import { makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../shared/utils/axios2';
import { SessionStore } from '../../../../authorization/stores/session.store';
import { SeasonsStore } from '../../../stores/seasons.store';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { SeasonModel } from '../../../../../api/models/season.model';
import { EditSeasonModel } from '../../../../../api/models/edit.season.model';
import { SeasonsController } from '../../../controllers/seasons/seasons.controller';

@provide.singleton()
export class EditSeasonStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(OrganizationsStore)
  protected organizations: OrganizationsStore;

  @lazyInject(SeasonsStore)
  protected seasons: SeasonsStore;

  @lazyInject(SeasonsController)
  protected seasonsController: SeasonsController;

  constructor() {
    makeAutoObservable(this);
  }

  fetchSeasonByYear = async () => {
    let response: TypeApiResponse<'getSeasonById'>;

    try {
      let payload: TypeApiRequest<'getSeasonById'> = {
        year: Number(this.seasons.selectedSeason),
        allowStub: false,
      };
      if (this.organizations.selectedOrganizationId !== 'my') {
        payload = { ...payload, organizationId: this.organizations.selectedOrganizationId };
      }
      response = await this.axios.api.getSeasonById(payload, { omit: ['year', 'allowStub'] });
    } catch (e) {
      console.warn(`Can't fetch season by year: ${this.seasons.selectedSeason}`);
      return;
    }

    console.log('fetchSeasonByYear');
    this.currentSeason = {
      ...response,
      startDate: moment(response.startDate, 'YYYY.MM.DD'),
      endDate: moment(response.endDate, 'YYYY.MM.DD'),
    };
  };

  currentSeason: EditSeasonModel = {
    year: 2020,
    endDate: null,
    startDate: null,
    label: '',
    harvestDate: '',
    isStub: false,
    sowingDate: '',
  };

  get seasonLabel() {
    if (!this.currentSeason) {
      return '';
    }
    return `${Number(this.currentSeason.year) - 1}-${this.currentSeason.year}`;
  }

  clearStore = () => {
    this.currentSeason = {
      year: 2020,
      endDate: null,
      startDate: null,
      label: '',
      harvestDate: '',
      isStub: false,
      sowingDate: '',
    };
  };

  setStartDate = (v: Moment) => {
    this.currentSeason.startDate = v;
  };

  setEndDate = (v: Moment) => {
    this.currentSeason.endDate = v;
  };

  get isSaveAvailable() {
    return moment(this.currentSeason.startDate, 'DD.MM.YYYY').isBefore(
      moment(this.currentSeason.endDate, 'DD.MM.YYYY')
    );
  }

  get dateBorders() {
    return {
      start: {
        min: moment(`01/01/${Number(this.seasons.selectedSeason) - 1}`, 'DD/MM/YYYY'),
        max: moment(`31/12/${Number(this.seasons.selectedSeason)}`, 'DD/MM/YYYY'),
      },
      end: {
        min: moment(`01/01/${Number(this.seasons.selectedSeason) - 1}`, 'DD/MM/YYYY'),
        max: moment(`31/12/${Number(this.seasons.selectedSeason)}`, 'DD/MM/YYYY'),
      },
    };
  }

  fetchSeasons = () => {
    this.seasonsController.fetchMySeasons();
  };

  get isSowingAndHarvestBordersOut() {
    const startDate = moment(this.currentSeason.startDate, 'DD.MM.YYYY');
    const endDate = moment(this.currentSeason.endDate, 'DD.MM.YYYY');
    const sowingDate = moment(this.currentSeason.sowingDate, 'YYYY-MM-DD');
    const harvestDate = moment(this.currentSeason.harvestDate, 'YYYY-MM-DD');

    return startDate.isAfter(sowingDate) || endDate.isBefore(harvestDate);
  }

  saveSeasonChanges = async () => {
    try {
      let payload: TypeApiRequest<'saveSeason'> = {
        year: this.currentSeason.year,
        startDate: this.currentSeason.startDate.format('YYYY-MM-DD'),
        endDate: this.currentSeason.endDate.format('YYYY-MM-DD'),
      };

      if (this.organizations.selectedOrganizationId !== 'my') {
        payload = { ...payload, organizationId: this.organizations.selectedOrganizationId };
      }

      await this.axios.api.saveSeason(payload, { omit: ['organizationId', 'year'] });
    } catch (e) {
      console.error(`Can't save changes for season = ${this.currentSeason.year}`);
    }
  };
}
