import Skeleton from 'react-loading-skeleton';

import Styled from './LoadingSkeleton.styles';

const LoadingSkeleton = () => {
  return (
    <Styled.MapSkeleton>
      {/* @ts-ignore */}
      <Skeleton height={'100%'} />
    </Styled.MapSkeleton>
  );
};

export default LoadingSkeleton;
