import { TypeApiRoute } from '../../../models/type.api.request';
import { ITask, ITaskCreate } from '../../../models/as-fields/task/task.model';

type TCreateTaskListReq = Omit<ITaskCreate, 'cultureId' | 'cultureZoneId' | 'fieldId'>;

type TCreateTaskListRes = ITask[];

export const createTaskList: TypeApiRoute & {
  request: TCreateTaskListReq;
  response: TCreateTaskListRes;
} = {
  url: () => `/api/as-fields/tasks`,
  method: 'POST',
  headers: {},
  request: {} as TCreateTaskListReq,
  response: {} as TCreateTaskListRes,
};
