enum EImportedFieldErrorType {
  InvalidGeometry = 'INVALID_GEOMETRY',
  Intersection = 'INTERSECTION',
  SmallArea = 'SMALL_AREA',
  LargeArea = 'LARGE_AREA',
  ParseError = 'PARSE_ERROR',
}

interface IGetImportedField {
  id: string;
  name: string;
  area: number;
  geometry: any;
  bbox: any;
  error: boolean;
  errorType?: EImportedFieldErrorType;
  errorDescription?: 'string';
}

export type { IGetImportedField };

export { EImportedFieldErrorType };
