import { ModalFooter } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import Styled from './FieldsImportWarningModal.styles';
import { TFieldsImportWarningModal } from './FieldsImportWarningModal.types';

const FieldsImportWarningModal: FC<TFieldsImportWarningModal> = ({
  title,
  successButtonConfig,
  denyButtonConfig,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.IconWrapper>
        <Styled.Icon />
      </Styled.IconWrapper>

      <Styled.Title>{title}</Styled.Title>

      <ModalFooter successButton={successButtonConfig} denyButton={denyButtonConfig} />
    </Styled.Wrapper>
  );
};

FieldsImportWarningModal.displayName = 'FieldsImportWarningModal';

export default memo(FieldsImportWarningModal);
