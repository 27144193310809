import React, {
  Children,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {InfoBlockWrapper, ImgInfo, TextBlock, TipTail} from './style';

interface Props {
  imgUrl?: string;
}

export const InfoBlock: FC<Props> = ({ children, imgUrl }) => {
  const [showTextBlock, setShowTextBlock] = useState<boolean>(false);

  return (
    <InfoBlockWrapper>
      <ImgInfo onMouseOver={() => setShowTextBlock(true)} onMouseOut={() => setShowTextBlock(false)} />
      <TextBlock isVisible={showTextBlock}>{children}<TipTail /></TextBlock>
    </InfoBlockWrapper>
  );
};
