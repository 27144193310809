import { FC, memo } from 'react';

import { TInputFieldErrorItemProps } from './InputFieldErrorItem.types';
import Styled from './TInputFieldErrorItemProps.styles';

const InputFieldErrorItem: FC<TInputFieldErrorItemProps> = ({ text }) => {
  return <Styled.Wrapper>{text}</Styled.Wrapper>;
};

InputFieldErrorItem.displayName = 'InputFieldErrorItem';

export default memo(InputFieldErrorItem);
