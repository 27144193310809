import styled, { keyframes } from 'styled-components';

import { Colors } from '../../../constans/colors';

type TWrapperProps = {
  $needToHideContent: boolean;
};

const Wrapper = styled.div<TWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $needToHideContent }) => ($needToHideContent ? '#fff' : 'transparent')};
  z-index: 1000;
`;

const DOT_SIZE_POINT = 32;
const DOT_SIZE = `${DOT_SIZE_POINT}px`;
const DOTS_WRAPPER_SIZE = `${DOT_SIZE_POINT * 4}px`;
const DOT_ANIMATION_GAP = `${DOT_SIZE_POINT * 3}px`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const translate3d1 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(${DOT_ANIMATION_GAP}, 0, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
`;

const translate3d2 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(-${DOT_ANIMATION_GAP}, 0, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
`;

const translate3d3 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, ${DOT_ANIMATION_GAP}, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
`;

const translate3d4 = keyframes`
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  50% {
    transform: translate3d(0, -${DOT_ANIMATION_GAP}, 0) scale(.5);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
`;

const SpinnerLoader = styled.div`
  height: ${DOTS_WRAPPER_SIZE};
  width: ${DOTS_WRAPPER_SIZE};
  animation: ${rotate} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  display: block;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: auto;
    margin: auto;
    width: ${DOT_SIZE};
    height: ${DOT_SIZE};
    background: ${Colors.green};
    border-radius: 50%;
    animation: ${translate3d1} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    box-sizing: border-box;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: auto;
    bottom: 0;
    right: 0;
    margin: auto;
    width: ${DOT_SIZE};
    height: ${DOT_SIZE};
    background: ${Colors.green};
    border-radius: 50%;
    animation: ${translate3d2} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    box-sizing: border-box;
  }

  & span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: ${DOTS_WRAPPER_SIZE};
    width: ${DOTS_WRAPPER_SIZE};
  }

  & span::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    right: 0;
    margin: auto;
    width: ${DOT_SIZE};
    height: ${DOT_SIZE};
    background: ${Colors.green};
    border-radius: 50%;
    animation: ${translate3d3} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    box-sizing: border-box;
  }

  & span::after {
    content: '';
    display: block;
    position: absolute;
    top: auto;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: ${DOT_SIZE};
    height: ${DOT_SIZE};
    background: ${Colors.green};
    border-radius: 50%;
    animation: ${translate3d4} 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    box-sizing: border-box;
  }
`;

const StyledSpinnerLoader = {
  Wrapper,
  SpinnerLoader,
};

export default StyledSpinnerLoader;
