import { TypeApiRoute } from '../../../models/type.api.request';

type TDeleteChecklistInstanceReq = {
  id: string;
};

type TDeleteChecklistInstanceRes = {};

const deleteChecklistInstance: TypeApiRoute & {
  request: TDeleteChecklistInstanceReq;
  response: TDeleteChecklistInstanceRes;
} = {
  url: ({ id }) => `/api/as-fields/checklist/instance/${id}`,
  method: 'DELETE',
  headers: {},
  request: {} as TDeleteChecklistInstanceReq,
  response: {} as TDeleteChecklistInstanceRes,
};

export type { TDeleteChecklistInstanceReq, TDeleteChecklistInstanceRes };

export { deleteChecklistInstance };
