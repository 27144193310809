import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useCallback } from 'react';

import { useOperationsTaskActions } from '../../hooks/useOperationsTaskActions';

import fileSvg from './fileSvg.svg';
import {
  CreateNewTaskButtonWrapper,
  CreateTasksSVG,
  CreateTasksText,
  CreateTasksTitle,
  NoTasksPlaceHolderWrapper,
} from './style';

const NoTasksPlaceHolder = () => {
  const operationsTaskActions = useOperationsTaskActions();

  const handlePresetTaskClick = useCallback(() => {
    operationsTaskActions.presetTask();
  }, [operationsTaskActions.presetTask]);

  return (
    <NoTasksPlaceHolderWrapper>
      <CreateTasksSVG src={fileSvg} />
      <CreateTasksTitle>Создайте новую задачу</CreateTasksTitle>
      <CreateTasksText>Вся история будет сохранена и доступна для просмотра</CreateTasksText>
      <CreateNewTaskButtonWrapper>
        <Button color={'primary'} type={'button'} onClick={handlePresetTaskClick}>
          Создать задачу
        </Button>
      </CreateNewTaskButtonWrapper>
    </NoTasksPlaceHolderWrapper>
  );
};

export default observer(NoTasksPlaceHolder);
