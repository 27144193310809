import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import {
  ModalFooter,
  EModalType,
  useModal,
  TModalConfig,
  useNotificator,
  ENotificationType,
  ENotificationHeight,
  ENotificatorTheme,
  NewDropdown as Dropdown,
} from '@farmlink/farmik-ui';

import { useStore } from '../../../../../shared/utils/IoC';
import { EModalSize } from '../../../../../modals/components/Modal/styles';
import { TaskController } from '../../../tasks/mobx/controllers';

import Styled from './CancellationModal.styles';

const ANOTHER_OPTION_VALUE = 'another';
const ERROR_TEXT = 'Необходимо указать причину';
const CANCELATION_MODAL_KEY = 'cancellationModal';
const OPTIONS = [
  {
    label: 'Погодные условия',
    value: 'Погодные условия',
  },
  {
    label: 'Отмена агрооперации клиентом',
    value: 'Отмена агрооперации клиентом',
  },
  {
    label: 'Не успел',
    value: 'Не успел',
  },
  {
    label: 'Другое',
    value: ANOTHER_OPTION_VALUE,
  },
];

export const CancellationModal = observer(() => {
  const [currentOption, setCurrentOption] = useState(null);
  const [comment, setComment] = useState('');
  const [isError, setIsError] = useState(false);
  const { closeModal, getModalPayload } = useModal();
  const { id, status } = getModalPayload();
  const { sendTaskStatus } = useStore(TaskController);
  const { setNotification } = useNotificator();

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setIsError(false);
    setComment(event.target.value.toString());
  };

  const changeReason = (value: string) => {
    setIsError(false);
    setCurrentOption(value);
  };

  const validateData = useCallback((): boolean => {
    setIsError((currentOption === ANOTHER_OPTION_VALUE && comment.length === 0) || !currentOption);
    return (currentOption === ANOTHER_OPTION_VALUE && comment.length === 0) || !currentOption;
  }, [comment, currentOption]);

  const successButtonConfig = useMemo(
    () => ({
      title: 'Сохранить',
      handler: () => {
        if (!validateData()) {
          sendTaskStatus({
            taskId: id,
            status,
            cancellationReason:
              currentOption === ANOTHER_OPTION_VALUE ? `другое\n${comment}` : currentOption,
          }).then(() => {
            setNotification({
              message: 'Задача успешно отменена',
              style: {
                type: ENotificationType.Success,
                height: ENotificationHeight.BIG,
                placement: 'top-center',
                autoClose: 10000,
                theme: ENotificatorTheme.Dark,
              },
            });

            closeModal();
          });
        }
      },
    }),
    [comment, currentOption]
  );

  const denyButtonConfig = {
    title: 'Не сохранять',
    handler: () => closeModal(),
  };

  const dropdownConfig = {
    body: {
      optionList: OPTIONS,
    },
    field: {
      onChange: changeReason,
      placeholder: 'Не выбрано',
    },
    other: {
      dataTestId: 'cancellation-dropdown',
    },
    visual: {
      label: 'Выберите причину отмены',
    },
    validation: {
      error: {
        errorList: isError && currentOption !== ANOTHER_OPTION_VALUE && [ERROR_TEXT],
      },
    },
  };

  return (
    <Styled.Wrapper>
      <Styled.Title>Причина отмены</Styled.Title>
      <Styled.Delimiter />
      <Dropdown config={dropdownConfig}/>
      {isError && currentOption !== ANOTHER_OPTION_VALUE ? (
        <Styled.Error>{ERROR_TEXT}</Styled.Error>
      ) : null}
      {currentOption === ANOTHER_OPTION_VALUE ? (
        <>
          <Styled.CommentWrapper $isError={isError}>
            <Styled.CommentLabel>Дополнительная информация</Styled.CommentLabel>
            <textarea
              value={comment}
              onChange={handleChange}
              rows={4}
              maxLength={500}
              placeholder={'Отсутствует'}
              data-test-id={'cancellation-comment-textarea'}
            />
          </Styled.CommentWrapper>
          {isError ? <Styled.Error>{ERROR_TEXT}</Styled.Error> : null}
        </>
      ) : null}

      <Styled.ButtonsWrapper>
        <ModalFooter
          dataTestId="cancellation-comment"
          successButton={successButtonConfig}
          denyButton={denyButtonConfig}
        />
      </Styled.ButtonsWrapper>
    </Styled.Wrapper>
  );
});

export const cancellationModalConfig: TModalConfig = {
  name: CANCELATION_MODAL_KEY,
  title: '',
  type: EModalType.Custom,
  id: CANCELATION_MODAL_KEY,
  children: CancellationModal,
  dataTestId: CANCELATION_MODAL_KEY,
  styledProps: {
    $modalPaddings: '40px 60px',
    $size: EModalSize.Medium,
  },
  className: CANCELATION_MODAL_KEY,
};

export const cancellationModalSuccessConfig: TModalConfig = {
  name: 'cancellationModalSuccess',
  title: 'Задача была отменена',
  type: EModalType.Success,
  id: 'cancellationModalSuccess',
  dataTestId: 'cancellationModalSuccess',
  successButton: {
    title: 'Продолжить',
  },
  styledProps: {
    $modalPaddings: '40px 60px',
    $size: EModalSize.Medium,
  },
};
