import { useCallback, useMemo, useState } from 'react';
import { Input, ModalFooter, NewDropdown, Typography, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { TDropdownConfig } from '../../../../../../../shared/components/inputs/Dropdown/Dropdown.types';
import { validateCoordinates } from '../../../../../../../shared/utils/helpers/validateCoordinates';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../mobx/stores';
import { MapAdditionsService } from '../../../../../../../shared/features/map/mobx/services';
import { ECheckListInstanceType } from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { ISelectOption } from '../../../../../../../../types/selectOption';

import Styled from './EditTaskPointModal.styles';

interface IEditTaskPointModalPayload {
  isNewPoint: boolean;
  coordinates: number[];
  positionNumber: number;
  type?: ECheckListInstanceType;
  instanceTypeListToCreate?: ECheckListInstanceType[];
}

const getOptionList = (instanceTypeListToCrate: ECheckListInstanceType[] = []): ISelectOption[] => {
  return instanceTypeListToCrate.reduce<ISelectOption[]>((optionList, type) => {
    switch (type) {
      case ECheckListInstanceType.PlannedPoint:
        optionList.push({ label: 'Точка', value: ECheckListInstanceType.PlannedPoint });
        break;
      case ECheckListInstanceType.Machinery:
        optionList.push({ label: 'Техника', value: ECheckListInstanceType.Machinery });
        break;
      case ECheckListInstanceType.Field:
        optionList.push({ label: 'Поле', value: ECheckListInstanceType.Field });
        break;
      default:
    }

    return optionList;
  }, []);
};

const EditTaskPointModal = () => {
  const { getModalPayload, getModalRuntimeHandlers, closeModal } = useModal();
  const payload = (getModalPayload() || {}) as IEditTaskPointModalPayload;
  const { onSuccess, onCancel } = getModalRuntimeHandlers();
  const { selectedTask, selectedField } = useStore(TaskStore);
  const { isPointInZone, cordsToPoint } = useStore(MapAdditionsService);

  const { instanceTypeListToCreate, coordinates, type, positionNumber } = payload;

  const [newCoordinates, setNewCoordinates] = useState('');
  const [newCoordinatesError, setNewCoordinatesError] = useState(null);
  const [isDisplayError, setIsDisplayError] = useState(false);
  const [pointType, setPointType] = useState<ECheckListInstanceType>(type);

  const optionList = useMemo(() => {
    return getOptionList(instanceTypeListToCreate);
  }, [instanceTypeListToCreate]);

  const currentCords = coordinates.map(item => item.toFixed(7)).join(', ');
  const defaultValue = optionList.find(option => option.value === pointType);
  const isNewPoint = payload?.isNewPoint;
  const isDisplayCurrentCorsInput = true;
  const isDisplayNewCordsInput = true;

  const zone = selectedTask?.cultureZone || selectedField;

  const isPointInsidePolygon = useCallback((cords: string) => {
    if (!cords?.length) {
      return true;
    }

    if (isPointInZone(zone, cordsToPoint(cords?.split(', ').map(item => Number(item))))) {
      return true;
    } else {
      throw new Error('Точка находится вне поля');
    }
  }, []);

  const newCoordinatesHandler = useCallback((e: string) => {
    setNewCoordinates(e);

    try {
      if (e !== '' || e !== null) {
        validateCoordinates(e);
        isPointInsidePolygon(e);
      }

      setNewCoordinatesError(null);
    } catch (error) {
      setNewCoordinatesError(error?.message);
    }
  }, []);

  const dropdownConfig: TDropdownConfig = {
    field: {
      onChange: (value: ECheckListInstanceType) => {
        if (value === ECheckListInstanceType.Machinery || value === ECheckListInstanceType.Field) {
          setNewCoordinates('');
          setNewCoordinatesError(null);
        }

        setPointType(value);
      },
      placeholder: 'Выберите тип осмотра',
      defaultValue,
      id: 'culture-select',
      type: {},
    },
    body: {
      optionList,
    },
    visual: {
      label: 'Тип осмотра',
    },
    validation: {
      error: {
        errorList: [],
        options: {
          isDoNotShowErrorText: true,
        },
      },
    },
    other: {
      dataTestId: 'culture-select',
    },
  };

  const onSave = useCallback(() => {
    if (newCoordinatesError) {
      setIsDisplayError(true);
    } else {
      onSuccess?.({
        type: pointType,
        coordinates: newCoordinates?.length
          ? newCoordinates?.split(', ').map(item => Number(item))
          : coordinates,
      });
    }
  }, [newCoordinates, pointType, newCoordinatesError]);

  return (
    <Styled.Wrapper>
      <Typography variant="h5">{`Координаты точки ${positionNumber}`}</Typography>
      <Styled.Hr />

      {isNewPoint && <NewDropdown config={dropdownConfig} />}

      {isDisplayCurrentCorsInput && (
        <Input label="Текущие координаты" value={currentCords} blocked />
      )}

      {isDisplayNewCordsInput && (
        <Input
          label="Новые координаты"
          value={newCoordinates}
          onChange={newCoordinatesHandler}
          error={isDisplayError ? newCoordinatesError : null}
        />
      )}

      <ModalFooter
        successButton={{
          title: 'Сохранить',
          color: 'primary',
          handler: onSave,
          disabled: !pointType,
        }}
        denyButton={{
          title: 'Отменить',
          handler: () => {
            if (isNewPoint) {
              onCancel?.();
            }
            closeModal();
          },
        }}
      />
    </Styled.Wrapper>
  );
};

export default observer(EditTaskPointModal);
