import { FC, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useContextualHelpActions, useModal } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../shared/utils/IoC';
import { TaskStore } from '../../mobx/stores';
import { LEAVE_BEFORE_SAVE_MODAL_ID } from '../../modals/leaveBeforeSaveModal';
import { TaskControlButtons } from '../../components/TaskFullscreen/components/TaskCommonData/components';
import Fullscreen from '../../containers/fullscreen';
import TaskCommonData from '../../components/TaskFullscreen/components/TaskCommonData/TaskCommonData';
import { PreviewTasksTable } from '../../components/TaskFullscreen/components/PreviewTasksTable';
import { CreatedTasksTable } from '../../components/TaskFullscreen/components/CreatedTasksTable';
import { Container } from '../../../../../shared/features/UI/Container';
import { useTasksBackButtonData } from '../../hooks/useTasksBackButtonData';
import { usePageContextualHelp } from '../../../../../shared/hooks';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../shared/constants/contextualPath';

import Styled from './TaskCreateGroup.styles';

const TaskCreateGroup: FC = () => {
  const taskStore = useStore(TaskStore);

  const backButtonData = useTasksBackButtonData();
  const modalActions = useModal();

  usePageContextualHelp(EContextualParentPath.TasksTaskCreateGroup);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.TasksTaskCreateGroup,
    ContextualPaths.TasksTaskCreateGroupHeaderTitle
  );

  useEffect(() => {
    taskStore.setMode('create-multiple');
  }, []);

  const handleBackButtonClick = useCallback(() => {
    if (taskStore.tasksCreationSteps === 'preview') {
      taskStore.setTasksCreationSteps(null);

      return;
    }

    if (taskStore.isFormChanged) {
      modalActions.openModalByModalId(LEAVE_BEFORE_SAVE_MODAL_ID, null, backButtonData.handler);
    } else {
      backButtonData.handler();
    }
  }, [taskStore.isFormChanged, taskStore.tasksCreationSteps, backButtonData.handler]);

  const Content = useMemo(() => {
    if (taskStore.tasksCreationSteps === 'preview') {
      return <PreviewTasksTable />;
    }

    if (taskStore.tasksCreationSteps === 'view-created') {
      return <CreatedTasksTable />;
    }

    return (
      <Container header={{ title: 'Общие данные' }}>
        <TaskCommonData />
      </Container>
    );
  }, [taskStore.tasksCreationSteps]);

  return (
    <Fullscreen
      backButtonText={
        <Styled.TitleWrapper>
          <Styled.Title>Создание задач. Группа</Styled.Title>

          {ContextualHelpIcon}
        </Styled.TitleWrapper>
      }
      backButtonHandler={handleBackButtonClick}
      arrowText={
        taskStore.tasksCreationSteps === 'preview'
          ? 'К постановке задачи'
          : backButtonData.arrowText
      }
    >
      {Content}

      {!taskStore.createdTaskList.length ? <TaskControlButtons /> : null}
    </Fullscreen>
  );
};

TaskCreateGroup.displayName = 'TaskCreateGroup';

export default observer(TaskCreateGroup);
