import _ from 'lodash';
import { useMemo } from 'react';

import { SELECT_ALL_OPTION } from '../../constants';

import { TUseDropdownGetAvailableOptionList } from './useDropdownGetAvailableOptionList.types';

const useDropdownGetAvailableOptionList: TUseDropdownGetAvailableOptionList = ({
  body,
  type,
  state: {
    settings: { isExactCompareOfSearchValue, isFirstOpen, isDropped },
    values: { searchValue, selectValue, selectValueList },
    temporaryData: { preventSortingValues },
  },
  isKeepSelectedOptions,
}) => {
  return useMemo(() => {
    const isSelectedOptionsFirst = type?.multiselect?.isSelectedFirst;
    const isSelectAllButtonOptionNeeded = type?.multiselect?.selectAll;

    const uniqueOptionList = _.uniqBy(body.optionList, 'value');

    const listOfOptionsWithoutSelected =
      type?.multiselect && !isKeepSelectedOptions
        ? uniqueOptionList.filter(
            option => !selectValueList.some(value => option.value === value.value)
          )
        : uniqueOptionList.filter(value => value.value !== selectValue?.value);

    if (isExactCompareOfSearchValue) {
      const exactFilteredOptionList = listOfOptionsWithoutSelected?.filter(
        option => option.label === searchValue
      );

      return exactFilteredOptionList;
    }

    if (searchValue) {
      const selectedValues = selectValueList.flatMap(value => value.value);
      const filteredOptionList = uniqueOptionList?.filter(option => {
        if (selectedValues.includes(option.value) && !isKeepSelectedOptions) {
          return false;
        }

        const trimmedSearchValue = searchValue?.trim()?.toLocaleLowerCase();

        const searchValueWords = trimmedSearchValue.split(' ');

        if (searchValueWords.length) {
          const foundPos = option.label.toLocaleLowerCase().indexOf(trimmedSearchValue, 0);

          return foundPos === 0;
        }

        const optionWords = option.label.split(' ');

        const isFound = optionWords.some(word => {
          const foundPos = word.toLocaleLowerCase().indexOf(trimmedSearchValue, 0);

          return foundPos === 0;
        });

        return isFound;
      });
      let sortedOptionList = _.sortBy(filteredOptionList, ['label']);

      if (isSelectedOptionsFirst && !isFirstOpen) {
        const valueList = selectValueList.flatMap(value => value.value);

        sortedOptionList = _.partition(sortedOptionList, option => {
          return valueList.includes(option.value);
        }).flatMap(collection => collection);
      }

      if (isSelectAllButtonOptionNeeded) {
        sortedOptionList.unshift(SELECT_ALL_OPTION);
      }

      return sortedOptionList;
    } else {
      let listOfOption = listOfOptionsWithoutSelected;

      if (isSelectedOptionsFirst) {
        const valueList = selectValueList.flatMap(value => value.value);

        listOfOption = _.partition(listOfOption, option => {
          if (preventSortingValues?.includes(option)) {
            return false;
          }

          return valueList.includes(option.value);
        }).flatMap(collection => collection);
      }

      if (isSelectAllButtonOptionNeeded) {
        listOfOption.unshift(SELECT_ALL_OPTION);
      }

      return listOfOption;
    }
  }, [
    body,
    isExactCompareOfSearchValue,
    searchValue,
    selectValueList,
    type,
    isFirstOpen,
    isDropped,
  ]);
};

export default useDropdownGetAvailableOptionList;
