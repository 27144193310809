import { observer } from 'mobx-react';
import React, { FC, memo, MouseEvent, useCallback } from 'react';

import { TableFiltersBuilderTag as Tag } from '../../../components';
import { TableFiltersBuilderController as BuilderController } from '../../../mobx/controllers';
import { TableFiltersBuilderStore as Store } from '../../../mobx/stores';
import { ITableFiltersBuilderTag as ITag } from '../../../models/data';
import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { useStore } from '../../../../../utils/IoC';

import Styled from './TableFiltersBuilderTagContainer.styles';

interface IProps {
  tag: ITag;
  isShowHiddenTagsButton: boolean;
  showHiddenTagsButtonText: string;
  onShowHiddenTagsButtonClick: (event: MouseEvent<HTMLDivElement>) => void;
}

const TableFiltersBuilderTagContainer: FC<IProps> = ({
  tag,
  isShowHiddenTagsButton,
  showHiddenTagsButtonText,
  onShowHiddenTagsButtonClick,
}) => {
  const store = useStore(Store);
  const builderController = useStore(BuilderController);

  const isResizeAnimationActivated = store.getIsResizeAnimationsActivated(tag.builderId);

  const handleRemoveTag = useCallback((id: string) => {
    builderController.removeTag(tag.builderId, tag.filter.id, id, tag.filter.type);
  }, []);

  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper {...getDataTestId('tag-wrapper')}>
      <Tag
        id={tag.id}
        label={tag.label}
        isDisableDeleting={tag?.isDisableRemoving}
        onDeleteClick={handleRemoveTag}
        dataTestId={getDataTestId(`tag-${tag.id}`)['data-test-id']}
      />

      {isShowHiddenTagsButton && !isResizeAnimationActivated ? (
        <Styled.ShowMoreTagsButton
          onClick={onShowHiddenTagsButtonClick}
          {...getDataTestId('show-tags-button')}
        >{`+${showHiddenTagsButtonText}`}</Styled.ShowMoreTagsButton>
      ) : null}
    </Styled.Wrapper>
  );
};

TableFiltersBuilderTagContainer.displayName = 'TableFiltersBuilderTagContainer';

export default memo(observer(TableFiltersBuilderTagContainer));
