import React, { FC, memo, useCallback } from 'react';
import { observer } from 'mobx-react';

import { ITableFiltersBuilderPreset as IPreset } from '../../../models/data';
import { TableFiltersBuilderPreset as Preset } from '../../../components';
import { TableFiltersBuilderController as BuilderController } from '../../../mobx/controllers';
import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { useStore } from '../../../../../utils/IoC';

interface IProps {
  preset: IPreset;
  isSelected?: boolean;
}

const TableFiltersBuilderPresetContainer: FC<IProps> = ({ preset, isSelected }) => {
  const builderController = useStore(BuilderController);

  const handlePresetClick = useCallback(
    (presetId: string) => {
      builderController.togglePreset(preset.builderId, presetId, {
        isClearValueList: true,
      });
    },
    [preset.builderId]
  );

  const getDataTestId = useDataTestId();

  return (
    <Preset
      id={preset.id}
      name={preset.name}
      isSelected={isSelected}
      onClick={handlePresetClick}
      dataTestId={getDataTestId(`preset-with-key-${preset.id}`)['data-test-id']}
    />
  );
};

TableFiltersBuilderPresetContainer.displayName = 'TableFiltersBuilderPresetContainer';

export default memo(observer(TableFiltersBuilderPresetContainer));
