import { TypeApplication } from '../../../modules/dashboard/modules/organization/types/applications';
import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/type.response.pageable';

type TypeRequest = {
  organizationId: string;
};

type TypeResponse = {
  content: Array<TypeApplication>;
} & TypeResponsePageable;

export const getOrganizationApplications: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/da-profile/organizations/applications/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
