import { FC, memo } from 'react';

import Styled from './TableBuilderColumn.styles';

interface IProps {
  name: string;
  dataTestId?: string;
}

const TableBuilderColumn: FC<IProps> = ({ name, dataTestId }) => {
  return <Styled.Wrapper data-test-id={dataTestId}>{name}</Styled.Wrapper>;
};

export default memo(TableBuilderColumn);
