export type IndexModel = {
  fromDate: string;
  indexName: string;
  publicId: string;
  status: string;
  toDate: string;
  visImage: string;
};

export enum EIndexType {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
}
