import {
  EChecklistInstanceEventType,
  IChecklistInstanceEvent,
} from '../../../../models/checklist/instance/events/checklist.instance.event.model';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = {
  id: string;
  typeEvent?: EChecklistInstanceEventType;
};

type TypeResponse = IChecklistInstanceEvent[];

export const getChecklistInstanceEventList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: ({ id }) => `/api/as-fields/checklist/instance/${id}/events`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
