import { TypeApiRoute } from '../../../models/type.api.request';
import { IDictionaryEntity } from '../../../models/da-dictionary/DictionaryEntity';

type TRequest = {
  remoteName: string;
  id: string;
};

type TResponse = IDictionaryEntity;

export const getDictionaryEntity: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ remoteName, id }) => `/api/da-dictionary/dictionary/${remoteName}/${id}`,
  method: 'GET',
  request: {} as TRequest,
  response: {} as TResponse,
  headers: {},
};
