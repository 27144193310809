import styled, { keyframes } from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import { BorderRadius, FontSizes } from '../../../../../shared/constans/sizes';

export const Wrapper = styled.div`
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  img {
    margin-bottom: 40px;
  }
`;

export const MainText = styled.div`
  color: ${Colors.black};
  margin-bottom: 12px;
  ${FontSizes.largeBold}
`;

export const SecondaryText = styled.div`
  color: ${Colors.darkGray};
  ${FontSizes.medium}
`;
