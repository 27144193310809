import { useSidebar } from '@farmlink/farmik-ui';
import { FC, useEffect } from 'react';

import { ModalWrapper, Overlay, Modal, ModalActionsFooter } from './style';

type ModalType = {
  isVerticalCentered?: boolean;
  onClose?: () => void;
  paddings?: 'default' | 'seasons' | 'culture-zone' | 'alerts';
  size?: 'small' | 'medium' | 'large';
  dataTestId?: string;
};

export const ModalComponent: FC<ModalType> = ({
  children,
  isVerticalCentered,
  onClose,
  paddings = 'default',
  size = 'small',
  dataTestId,
}) => {
  const { setInBackground } = useSidebar();

  useEffect(() => {
    setInBackground(true);
    return () => {
      setInBackground(false);
    };
  }, []);

  return (
    <>
      <Overlay onClick={onClose} />
      <ModalWrapper>
        <Modal
          data-test-id={`${dataTestId}-modal` || 'modal'}
          size={size}
          paddings={paddings}
          isVerticalCentered={isVerticalCentered}
        >
          {children}
        </Modal>
      </ModalWrapper>
    </>
  );
};

export { ModalActionsFooter };
