import { FC, memo } from 'react';
import {
  Colors,
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  IconButton,
  Tooltip,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { useNavigate } from 'react-router';

import { ETaskStatus } from '../../../../../../../../api/models/as-fields/task/task.model';
import { ReactComponent as BinSvg } from '../../../../../../../shared/static/bin_red.svg';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { TaskEditController } from '../../../../../operations/controllers/task.edit.controller';
import { TASK_DELETION_MODAL } from '../../../../../operations/modals/DeletionModal/modal.config';
import { useTasksRouteActions } from '../../../../hooks';

import Styled from './CancelButton.styled';

interface ICancelButtonProps {
  status: ETaskStatus;
  taskId: string;
  mode?: 'view' | 'edit' | 'create';
}

const CancelButton: FC<ICancelButtonProps> = ({ status, mode, taskId }) => {
  const { openModalByModalId } = useModal();
  const { deleteTask } = useStore(TaskEditController);
  const { setNotification } = useNotificator();
  const { goToTasks } = useTasksRouteActions();

  const isNew = status === ETaskStatus.New;

  return (
    <Tooltip
      id="cancel-task-tooltip"
      position="start"
      place="bottom"
      getContent={() => `${isNew ? 'Удалить' : 'Отменить'}  задачу`}
    >
      <IconButton
        icon="close"
        customIcon={
          isNew ? (
            <Styled.BinColorWrapper>
              <BinSvg />
            </Styled.BinColorWrapper>
          ) : null
        }
        $padding={isNew ? '8px' : '10px'}
        $borderRadius="12px"
        size={32}
        dataTestId={isNew ? 'delete-task-button' : 'cancel-task-button'}
        onClick={() => {
          if (isNew) {
            openModalByModalId(TASK_DELETION_MODAL, {}, () => {
              deleteTask(taskId).then(() => {
                setNotification({
                  message: 'Задача успешно удалена',
                  style: {
                    type: ENotificationType.Success,
                    height: ENotificationHeight.BIG,
                    placement: 'top-center',
                    autoClose: 10000,
                    theme: ENotificatorTheme.Dark,
                  },
                });

                goToTasks();
              });
            });
          } else {
            openModalByModalId('cancellationModal', {
              id: taskId,
              status: ETaskStatus.Canceled,
            });
          }
        }}
        colorScheme={{
          default: {
            background: Colors.lightPink,
            icon: Colors.pink,
          },
          hover: {
            background: Colors.lightPink,
            icon: Colors.hoverPink,
          },
        }}
      />
    </Tooltip>
  );
};

export default memo(CancelButton);
