import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

export enum ESidebarModals {
  UnsavedResult = 'unsavedResult',
}

export const unsavedResultModal: TModalConfig = {
  title: 'Вы уверены, что хотите покинуть экран? Все несохраненные данные будут потеряны',
  id: ESidebarModals.UnsavedResult,
  name: ESidebarModals.UnsavedResult,
  type: EModalType.Warning,
  denyButton: {
    title: 'Нет, отменить',
  },
  successButton: {
    title: 'Да, продолжить',
    color: 'primary',
  },
  styledProps: {
    $size: EModalSize.Medium,
  },
};
