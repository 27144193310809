import styled from 'styled-components';

const Wrapper = styled.div`
  pointer-events: all;
`;

const Styled = {
  Wrapper,
};

export default Styled;
