import { IUserDtoShort } from '../../../user.model';

export enum EChecklistInstanceEventType {
  UpdateChecklist = 'UPDATE_CHECKLIST',
  UpdateInstance = 'UPDATE_INSTANCE',
}

export enum EChecklistInstanceEventSource {
  Web = 'Web',
  Mobile = 'Mobile',
}

export interface IChecklistInstanceEvent {
  createdBy: IUserDtoShort;
  typeEvent: EChecklistInstanceEventType;
  source: EChecklistInstanceEventSource;
  /**
   * Время в формате ISO 8601
   */
  creationDate: string;
}
