import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/type.response.pageable';
import { EIndexType, IndexModel } from '../../models/indices.model';
import { CultureFillEnum } from '../../../modules/dashboard/constants/culture.fill.enum';

type TypeRequest = {
  fieldId?: string;
  indexName?: CultureFillEnum;
  year?: number;
  /**
   * Формат: `YYYY-MM-DD`
   */
  fromDate?: string;
  /**
   * Формат: `YYYY-MM-DD`
   */
  toDate?: string;
  status?: EIndexType[];
};

type TypeResponse = {
  content: Array<IndexModel>;
} & TypeResponsePageable;

export const getIndicesList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/agro/indices/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
