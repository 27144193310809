import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ButtonLink } from '@farmlink/farmik-ui';

import { useStore } from '../../../shared/utils/IoC';
import { getUniqueCulturesNameList } from '../../modules/fields/utils';
import { FieldsStore } from '../../modules/fields/stores/fields.store';
import { Field } from '../../../../api/models/field.model';
import { FieldsRoute } from '../../modules/fields/fields.route';
import { toFixedWithCeil } from '../../../shared/utils/toFixedWithCeil';
import { UiStore } from '../../stores/ui.store';
import { PopupPages } from '../../constants/popup.pages';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../AccessRulesWrapper/ControlAccessRulesWrapper';
import { FieldsController } from '../../modules/fields/controllers/fields.controller';
import { OrganizationsStore } from '../../stores/organizations.store';

import { getCultures } from './utils';
import {
  FieldInfoWrapper,
  FieldCulturePic,
  Label,
  Content,
  FieldSquare,
  FieldEdit,
  FieldCultures,
  FieldSeazonsView,
  FieldCulturesWrapper,
} from './style';

export const FieldInfo: FC = observer(() => {
  const fieldsStore = useStore(FieldsStore);
  const { selectedFieldId, idToFields } = fieldsStore;
  const navigate = useNavigate();
  const ui = useStore(UiStore);
  const fieldsController = useStore(FieldsController);
  const organizationsStore = useStore(OrganizationsStore);
  const [cultures, setCultures] = useState<string>('');
  const [culturesPic, setCulturesPic] = useState<string>('');

  const [field, setField] = useState<Field>();

  const fieldName = useRef(null);
  const [showFieldNameTip, setShowFieldNameTip] = useState<boolean>();

  const isEllipsisActive = (e: HTMLDivElement): void => {
    setShowFieldNameTip(() => {
      return e.offsetWidth < e.scrollWidth;
    });
  };

  const fieldEditRoute = useMemo(
    () =>
      generatePath(FieldsRoute.Edit, {
        orgId: organizationsStore.selectedOrganizationId,
        fieldId: selectedFieldId,
      }),
    [organizationsStore.selectedOrganizationId, selectedFieldId]
  );

  useEffect(() => {
    const selectedField = idToFields.get(selectedFieldId);
    if (selectedField) setField(selectedField);
    const { culturesPic: culturesPicTmp } = getCultures(selectedField);
    const culturesList = getUniqueCulturesNameList(selectedField?.cultureZones);

    setCultures(culturesList.length > 0 ? culturesList.join(', ') : 'Нет культуры');
    setCulturesPic(culturesPicTmp);
  }, [selectedFieldId]);

  // useEffect(() => {
  //   console.log('fieldName.current', fieldName.current);

  //   console.log('isEllipsisActive', isEllipsisActive(fieldName.current));

  // }, [fieldName.current, field]);

  return (
    <FieldInfoWrapper data-test-id="widget-field-info">
      <Label
        fieldname={(field && field.name) || ''}
        showtip={showFieldNameTip}
        onMouseOver={() => {
          isEllipsisActive(fieldName.current);
        }}
        onMouseOut={() => {
          setShowFieldNameTip(false);
        }}
      >
        <p data-test-id="widget-field-info-name" ref={fieldName}>
          {field && field.name}
        </p>
      </Label>
      <Content>
        <FieldSquare data-test-id="widget-field-info-square">
          {field && `${toFixedWithCeil(field.area) || 0} га`}
        </FieldSquare>
        <ControlAccessRulesWrapper mode={AccessRuleVisibility.Hide} actionName="field.editField">
          <ButtonLink
            color={'accent'}
            onClick={() => {
              ui.setFullWeatherMode(false);
              fieldsController.setMainPageState();
              navigate(fieldEditRoute);
            }}
            dataTestId={'edit-field-area'}
          >
            Редактировать поле
          </ButtonLink>
        </ControlAccessRulesWrapper>
        {/* <FieldCultures>{field && getCulturesList(field)}</FieldCultures> */}
        <FieldCulturesWrapper>
          <FieldCultures>{cultures}</FieldCultures>
        </FieldCulturesWrapper>
        <ButtonLink
          color={'accent'}
          onClick={() => ui.setPageState(PopupPages.Seasons)}
          dataTestId={'edit-field-seazons'}
        >
          История посевов
        </ButtonLink>
      </Content>
      {culturesPic && <FieldCulturePic src={culturesPic} />}
    </FieldInfoWrapper>
  );
});
