import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { OrganizationsService } from '../../../../../../../../shared/mobx/services/da-profile';
import { TypeApiRequest } from '../../../../../../../../shared/utils/axios2';
import { TypeUser } from '../../../../../../../../../api/models/user.model';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { createUserSelectOptionList } from '../../../../../../../../shared/utils/helpers/selectOptions';

@provide.transient()
class TasksFiltersOptionsService {
  @lazyInject(OrganizationsService)
  protected organizationsService: OrganizationsService;
  fetchAssigneeList = async (
    payload: TypeApiRequest<'getOrganizationUsers'>
  ): Promise<TypeUser[]> => {
    const userList = await this.organizationsService.getUserList(payload);

    if (userList) {
      return userList;
    }

    return [];
  };

  createAssigneeOptionList = async (organizationId: string): Promise<ISelectOption[]> => {
    if (organizationId === 'my') {
      return [];
    }

    const userList = await this.fetchAssigneeList({
      organizationID: organizationId,
      status: 'ACTIVE',
    });

    if (userList) {
      return createUserSelectOptionList(userList);
    }

    return [];
  };
}

export default TasksFiltersOptionsService;
