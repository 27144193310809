import { ISelectOption } from '../../../../types/selectOption';
import { IGetDictionary } from '../../dictionary/dictionary.model';
import {
  IGetChecklistInstance,
  IPutNestedChecklistInstance,
} from '../instance/checklist.instance.model';
import { TChecklistAttributeFormulaFunction } from '../../as-fields/checklists/attributes/formulas/ChecklistFormulas';

export interface IGetChecklistAttribute {
  order: number;
  isRequired: boolean;
  isActive: boolean;
  isTitle: boolean;
  parentId?: string;
  min?: number;
  max?: number;
  normal?: number;
  stageId?: string;
  id: string;
  checkListId: string;
  attribute: {
    id: string;
    type: EChecklistAttributeType;
    name: string;
    calculationType: 'AVERAGE' | null;
    checklistLink: string | null;
    checklistLinkPublicId: string | null;
    dictionaryLink: string | null;
    isMultiselect: boolean;
    toolTip?: string;
    placeholder?: string;
    precision?: number;
  };
  hasChildren: boolean;
  position: {
    newLine: boolean;
    parentId: string;
    separate: boolean;
    width: EChecklistAttributeWidth;
  };
  toolTip?: string;
  dependencyAttribute?: IGetChecklistAttribute;
  dependencyAttributeId?: string;
  displayParent?: boolean;
  dependencySource?: string;
  dependencySourceId?: string;
  dependencyType?:
    | 'CHECKLIST_ATTRIBUTE'
    | 'CULTURE_TASK'
    | 'PHENOPHASE_TASK'
    | 'OPERATION_TYPE_TASK';
  fileRequired?: boolean;
  calculate?: TChecklistAttributeFormulaFunction;
  visibility?: TChecklistAttributeFormulaFunction;
  dependency?: {
    autoComplete?: boolean;
    dependencies?: IChecklistAttrDependency[];
  };
}

export interface IChecklistAttrDependency {
  returnType: 'VOID';
  type: 'DEPENDENCY';
  dependencyType: EChecklistAttrDependencyDependencyType;
  attrId?: string;
}

export enum EChecklistAttrDependencyDependencyType {
  Attribute = 'ATTRIBUTE',
  CultureTask = 'CULTURE_TASK',
  OperationTypeTask = 'OPERATION_TYPE_TASK',
  PhenophaseTask = 'PHENOPHASE_TASK',
}

export enum EChecklistAttributeWidth {
  Half = 'HALF',
  Full = 'FULL',
}

export interface IChecklistAttributeFileValue {
  id: string;
  fileName?: string;
  fileContentType?: string;
  fileSize?: number;
  downloadUrl?: string;
}

export interface IGetChecklistAttributeValue {
  id: string;
  checkListAttributeId: string;
  integerValue?: number;
  integerMinValue: number;
  integerMaxValue?: number;
  integerValues?: IChecklistAverageValue[];
  doubleValue?: number;
  doubleMinValue?: number;
  doubleMaxValue?: number;
  doubleValues?: IChecklistAverageValue[];
  stringValue?: string;
  longStringValue?: string;
  booleanValue?: boolean;
  enumValues?: IGetChecklistAttributeEnumValue[];
  userDictionaryValues?: IGetChecklistAttributeUserDictionary[];
  dictionaryValueList?: IGetDictionary[];
  fileValue?: IChecklistAttributeFileValue[];
  dateValue?: string;
  checkListInstanceValue?: IGetChecklistInstance[];
}

export interface IChecklistAverageValue {
  culled: boolean;
  value: string | number;
}

export interface IExtendedAverageValue extends IChecklistAverageValue {
  id: string;
  rowId: string | null;
}

export interface IPutChecklistAttributeValue {
  checkListAttributeId: string;
  integerValue?: number | string;
  integerValues?: IChecklistAverageValue[];
  doubleValue?: number | string;
  doubleValues?: IChecklistAverageValue[];
  stringValue?: string;
  longStringValue?: string;
  booleanValue?: boolean;
  enumValues?: string[];
  userDictionaryValues?: IGetChecklistAttributeUserDictionary[];
  dictionaryValueList?: string[];
  dictionaryValueListCrumbs?: string[];
  fileValue?: string[];
  dateValue?: string;
  checkListInstanceValue?: IPutNestedChecklistInstance[];
}

export interface IGetChecklistAttributeEnumValue {
  id: string;
  value: string;
}

export interface IGetChecklistAttributeUserDictionary {
  id: string;
  value: string;
  clientId?: null | string;
}

export interface IChecklistAverageAttrRow {
  id: string;
  valueList: IExtendedAverageValue[];
  hasLongValue?: boolean;
}

export interface IChecklistAttributeWithValue {
  stageId: string;
  isActive: boolean;
  checklistAttribute: IGetChecklistAttribute;
  attributeValue: IPutChecklistAttributeValue;
  schema: {
    isShownError: boolean;
    error: string;
  };
  listOfOptionValue?: ISelectOption[];
  drawingNestedInstanceId?: string;
  progenitorId?: string;
  attributeWithFileValue?: IChecklistAttributeWithValue;
  isShowFileAsSeparate?: boolean;
  children?: IChecklistAttributeWithValue[];
  averageAttrData?: {
    min: string | number;
    max: string | number;
    average: string | number;
    rowList: IChecklistAverageAttrRow[];
    culledValueList: IExtendedAverageValue[];
  };
}

export enum EChecklistAttributeType {
  Boolean = 'BOOLEAN',
  Int = 'INT',
  Double = 'DOUBLE',
  String = 'STRING',
  DictionaryLink = 'DICTIONARY_LINK',
  Enum = 'ENUM',
  UserDictionaryLink = 'USER_DICTIONARY_LINK',
  FileLink = 'FILE_LINK',
  ChecklistInstanceLink = 'CHECKLIST_INSTANCE_LINK',
  LongString = 'LONGSTRING',
  Date = 'DATE',
}
