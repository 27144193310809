import { IGetChecklistAttributeEnumValue } from '../../../../models/checklist/attribute/checklist.attribute.model';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = {
  attributeId: string;
  dependencyValueId?: string;
};

type TypeResponse = {} & IGetChecklistAttributeEnumValue[];

export const getChecklistEnumListByAttributeId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: ({ attributeId }) => `/api/as-fields/checklist/attribute/${attributeId}/enumList`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
