import { FC } from 'react';

import { IGetChecklistInstanceByTaskId } from '../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';

import { ViewingPoint } from './components';

interface IProps {
  checklistInstanceList: IGetChecklistInstanceByTaskId[] | undefined;
}

const ViewingPointsRows: FC<IProps> = ({ checklistInstanceList }) => {
  return (
    <>
      {checklistInstanceList?.map((point, index) => (
        <ViewingPoint point={point} index={index} key={point.id} />
      ))}
    </>
  );
};

ViewingPointsRows.displayName = 'ViewingPointsRows';

export default ViewingPointsRows;
