import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { OperationsStore } from '../stores/operations.store';
import { Task, taskLabels, TasksStore, TaskStatuses } from '../stores/tasks.store';

export interface PreparedTask extends Task {
  operationTypeName: string;
  operationTypeId: string;
  fieldName: string;
  statusLabel: string;
  cultureName: string;
}

@provide.singleton()
export class TasksListingController {
  @lazyInject(OperationsStore)
  operationsStore: OperationsStore;

  @lazyInject(TasksStore)
  tasksStore: TasksStore;

  getDateToTasks = (): any => {
    const result = {};

    [...this.tasksStore.tasksMap]
      .map(([key, item]) => item)
      .slice()
      .forEach(task => {
        const preparedTask = {
          ...task,
          statusLabel: this.getStatusLabel(task.status),
          cultureName: task?.culture?.name || 'Культура не указана',
        };

        if (result[task.bindingDate as string]) {
          result[task.bindingDate as string].push({
            ...preparedTask,
          });
        } else {
          result[task.bindingDate as string] = [preparedTask];
        }
      });

    return result;
  };

  getFieldNameFromOperation(operationId: string): string {
    /* console.log('getFieldNameFromOperation.',operationId);
        console.log(this.operationsStore.OperationsList);
        console.log(this.operationsStore.OperationsList.find(operation => operation.id === operationId)); */
    return (
      this.operationsStore.OperationsList.find(operation => operation.id === operationId)?.name ||
      '<нет данных>'
    );
  }

  getOperationTypeId(operationId: string): string {
    /* console.log('**********getOperationTypeId.', this.operationsStore.OperationsList.find(operationItem => operationItem.id === operationId)?.operationTypeId);
        console.log(operationId);
        console.log(this.operationsStore.OperationsList); */
    return (
      this.operationsStore.OperationsList.find(operationItem => operationItem.id === operationId)
        ?.operationTypeId || ''
    );
  }

  getOperationTypeName(operationTypeId: string): string {
    /* console.log('**************getOperationTypeName.', operationTypeId);
        console.log(this.operationsStore.OperationsType);
        console.log(this.operationsStore.OperationsType.find(operationTypeItem => operationTypeId === operationTypeItem.id)?.name); */
    if (operationTypeId) {
      // console.log(this.operationsStore.OperationsType);
      return (
        this.operationsStore.OperationsType.find(
          operationTypeItem => operationTypeId === operationTypeItem.id
        )?.name || '<нет данных>'
      );
    }
    return '<нет данных>';
  }

  getStatusLabel(status: TaskStatuses): string {
    return taskLabels[status] || 'нет данных';
  }
}
