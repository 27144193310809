import { FC, ReactNode, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../utils/IoC';

import { TableBuilderController as Controller } from './mobx/controllers';
import Styled from './TableBuilder.styles';
import { TableBuilderUIContext as UIContext } from './context/UI';
import { useTableBuilderDataTestId as useDataTestId } from './hooks';
import { TableBuilderStore as Store } from './mobx/stores';
import { TTableBuilderStylePreset as TStylePreset } from './types/styles';
import {
  TableBuilderPlugContainer as PlugContainer,
  TableBuilderTable as Table,
} from './containers';

interface IProps {
  /**
   * Уникальный идентификатор таблицы [билдера].
   */
  builderId: string;
  /**
   * Функция-рендер заглушки, когда таблица была только создана
   * и не было выполнено еще ни одного запроса за данными.
   */
  renderDefaultPlug?: () => ReactNode;
  /**
   * Функция-рендер заглушки, когда таблица пустая, т.е. без данных.
   */
  renderNoDataPlug?: () => ReactNode;
  /**
   * Глобальный пресет стилей, где:
   * 'primary' — основной стиль таблицы согласно макетам;
   * 'cleared' — стиль без border и padding.
   */
  stylePreset?: TStylePreset;
  /**
   * Отображает компонент MoveTop
   * 'showMoveTop'
   */
  showMoveTop?: boolean;
}

const TableBuilder: FC<IProps> = ({
  builderId,
  renderDefaultPlug,
  renderNoDataPlug,
  stylePreset,
  showMoveTop,
}) => {
  const getDataTestId = useDataTestId(builderId);

  const store = useStore(Store);
  const controller = useStore(Controller);

  const rowList = store.getRowList(builderId);
  const isShowDefaultPlug = store.getIsShowDefaultPlug(builderId);
  const activePlugId = store.getTableActivePlugId(builderId);

  const ActivePlug = useMemo(() => {
    if (activePlugId) {
      return store.getActivePlugNode(builderId);
    }
    return null;
  }, [activePlugId, builderId]);

  const DefaultPlug = useMemo(() => {
    if (isShowDefaultPlug) {
      return renderDefaultPlug?.() || <Styled.DefaultPlug {...getDataTestId('default-plug')} />;
    }
  }, [renderDefaultPlug, isShowDefaultPlug]);

  const NoDataPlug = useMemo(() => {
    if (!rowList.length && renderNoDataPlug) {
      return renderNoDataPlug();
    }
  }, [renderNoDataPlug, rowList]);

  useEffect(() => {
    return () => {
      controller.clearStore(builderId);
    };
  }, []);

  return (
    <UIContext.Provider value={{ builderId }}>
      {activePlugId ? (
        <PlugContainer>{ActivePlug}</PlugContainer>
      ) : DefaultPlug || NoDataPlug ? (
        <PlugContainer>{DefaultPlug || NoDataPlug}</PlugContainer>
      ) : (
        <Table stylePreset={stylePreset} showMoveTop={showMoveTop} />
      )}
    </UIContext.Provider>
  );
};

TableBuilder.displayName = 'TableBuilder';

export default observer(TableBuilder);
