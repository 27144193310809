import { FC } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../shared/utils/IoC';
import MapStore from '../../stores/map.store';
import { UiStore } from '../../../dashboard/stores/ui.store';
import { PopupPages } from '../../../dashboard/constants/popup.pages';
import { ReactComponent as PlusIcon } from '../../../shared/static/plus.svg';
import { ReactComponent as MinusIcon } from '../../../shared/static/minus.svg';
import { ReactComponent as ArrowIcon } from '../../../shared/static/arrow.svg';

import {
  MapUserControlsWrapper,
  MapUserControlsActionElement,
  MapUserControlsZoomActionsWrapper,
} from './style';

export const MapUserControls: FC = observer(() => {
  const { instance } = useStore(MapStore);
  const ui = useStore(UiStore);
  if (!instance) return null;

  return (
    <MapUserControlsWrapper data-test-id="map-user-controls-wrapper">
      <MapUserControlsZoomActionsWrapper>
        <MapUserControlsActionElement
          onClick={() => instance.zoomIn()}
          data-test-id="map-user-controls-zoom-in-action"
        >
          <PlusIcon />
        </MapUserControlsActionElement>
        <MapUserControlsActionElement
          onClick={() => instance.zoomOut()}
          data-test-id="map-user-controls-zoom-out-action"
        >
          <MinusIcon />
        </MapUserControlsActionElement>
      </MapUserControlsZoomActionsWrapper>
      {ui.popupPageState !== PopupPages.CultureZone && ui.popupPageState !== PopupPages.Seasons && (
        <MapUserControlsActionElement
          onClick={() => instance.locate({ setView: true })}
          data-test-id="map-user-controls-locate-user-action"
        >
          <ArrowIcon />
        </MapUserControlsActionElement>
      )}
    </MapUserControlsWrapper>
  );
});
