import { ITableFiltersBuilderFilterConfig as IFilterConfig } from '../../../models/configs';
import { ITableFiltersBuilderFilter as IFilter } from '../../../models/data';
import { provide } from '../../../../../utils/IoC';

@provide.singleton()
class TableFiltersBuilderFiltersService<F = any> {
  createFilter = (builderId: string, config: IFilterConfig<F>, order: number): IFilter<F> => {
    const filter: IFilter<F> = {
      ...config,
      builderId,
      order,
    };

    return filter;
  };

  createFilterList = (builderId: string, configList: IFilterConfig<F>[]): IFilter<F>[] => {
    const presetList = configList.map<IFilter<F>>((config, index) =>
      this.createFilter(builderId, config, index + 1)
    );

    return presetList;
  };
}

export default TableFiltersBuilderFiltersService;
