import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;

  position: relative;

  border-radius: 16px;
`;

const StyledTableBuilderPlugContainer = {
  Wrapper,
};

export default StyledTableBuilderPlugContainer;
