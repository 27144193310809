import { makeAutoObservable } from 'mobx';
import { isArray } from 'lodash';

import { provide } from '../../../../../../../../shared/utils/IoC';
import { IMapMarkerElement as IMarketElement } from '../../../../../../../../shared/features/map/models';
import { IDrawChecklistInstance } from '../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { TInspectionPointsZoneElement as TZoneElement } from '../../../models';

@provide.singleton()
class InspectionPointsStore {
  private _zoneElement: TZoneElement | null = null;

  private _pointElementsByPointId: Map<string, IMarketElement<IDrawChecklistInstance>> = new Map<
    string,
    IMarketElement<IDrawChecklistInstance>
  >();

  private _isInspectionPointsChanged = false;

  constructor() {
    makeAutoObservable(this);
  }

  get zoneElement() {
    return this._zoneElement;
  }

  get pointElementList() {
    return [...this._pointElementsByPointId.values()];
  }

  getPointElement = (id: string) => {
    return this._pointElementsByPointId.get(id);
  };

  get isInspectionPointsChanged() {
    return this._isInspectionPointsChanged;
  }

  setZoneElement = (element: TZoneElement): void => {
    this._zoneElement = element;
  };

  setPointElement = (element: IMarketElement<IDrawChecklistInstance>): void => {
    this._pointElementsByPointId.set(element.element.id, element);
  };

  setPointElementList = (
    elementList: IMarketElement<IDrawChecklistInstance>[],
    options?: {
      isClearPreviousList?: boolean;
    }
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...this._pointElementsByPointId.entries()];

    const newCollection: Map<string, IMarketElement<IDrawChecklistInstance>> = new Map<
      string,
      IMarketElement<IDrawChecklistInstance>
    >(previousEntryList);

    if (isArray(elementList)) {
      elementList.forEach(el => newCollection.set(el.element.id, el));
    }

    this._pointElementsByPointId = newCollection;
  };

  setIsInspectionPointsChanged = (state: boolean) => {
    this._isInspectionPointsChanged = state;
  };

  deletePointElement = (id: string): void => {
    this._pointElementsByPointId.delete(id);
  };

  clearZoneElement = (): void => {
    this._zoneElement = null;
  };

  clearPointElementsByPointId = (): void => {
    this._pointElementsByPointId.clear();
  };

  clearIsInspectionPointsChanged = () => {
    this._isInspectionPointsChanged = false;
  };

  clearStore = (): void => {
    this.clearZoneElement();

    this.clearPointElementsByPointId();

    this.clearIsInspectionPointsChanged();
  };
}

export default InspectionPointsStore;
