import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import {
  EChecklistAttributeType as EAttrType,
  IGetChecklistAttribute as IAttribute,
  IGetChecklistAttributeUserDictionary,
  IGetChecklistAttributeValue as IAttributeValue,
} from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { TChecklistsUserDictionaryAttrToDraw as TUserDictionaryAttrToDraw } from '../../../../models';
import { ChecklistsStore } from '../../../stores';
import { ChecklistsAttrsFormulasService } from '../../ChecklistsAttrsFormulasService';
import { ISelectOption } from '../../../../../../../../../../types/selectOption';
import { EChecklistMode } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { getChecklistsAttrRequiredText as getAttrRequiredText } from '../../../../helpers';

const LIST_OF_ADDED_USER_DICT_LINK_VALUE_KEY = 'listOfChecklistUserDictLinkValue';

interface IListOfAddedUserDictLinkValue {
  listOfChecklistUserDictLinkValue: {
    [attrId: string]: IGetChecklistAttributeUserDictionary[];
  };
}

@provide.transient()
class ChecklistsUserDictionaryAttrsService {
  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(ChecklistsAttrsFormulasService)
  protected formulasService: ChecklistsAttrsFormulasService;

  createAttrToDraw = (
    groupId: string,
    attr: IAttribute,
    initialValueList: IAttributeValue[],
    options?: {
      isBlocked?: boolean;
      nestedInstanceId?: string;
      dependentFileAttrId?: string;
    }
  ): TUserDictionaryAttrToDraw => {
    // Формируем "рабочую" модель атрибута.
    const attrToDraw: TUserDictionaryAttrToDraw = {
      id: attr.id,
      groupId,
      isVisible: true,
      order: attr.order,
      initialModel: attr,
      value: {
        checkListAttributeId: attr.id,
        userDictionaryValues: [],
      },
      options: {
        selectOptionList: [],
        selectedSelectOptionList: [],
      },
      isBlocked: options?.isBlocked || this.checklistsStore.mode === EChecklistMode.View,
      validationScheme: {
        isShowError: false,
        errorTitle: getAttrRequiredText(EAttrType.UserDictionaryLink),
      },
      isEdited: false,
      nestedInstanceId: options?.nestedInstanceId,
      dependentFileAttrId: options?.dependentFileAttrId,
    };

    const initialValueOfThisAttr = initialValueList.find(
      ({ checkListAttributeId }) => checkListAttributeId === attrToDraw.id
    );

    if (!initialValueOfThisAttr) {
      return attrToDraw;
    }

    // Заполняем значение атрибута.
    attrToDraw.value = {
      ...attrToDraw.value,
      userDictionaryValues: initialValueOfThisAttr.userDictionaryValues,
    };

    // Приводим значения в формат, с которым работает дропдаун.
    const selectOptionList = initialValueOfThisAttr.userDictionaryValues.map<ISelectOption>(el => ({
      label: el.value,
      value: el.id,
    }));

    // Заполняем выбранные опции.
    attrToDraw.options = { ...attrToDraw.options, selectedSelectOptionList: selectOptionList };

    return attrToDraw;
  };

  changeValue = (groupId: string, value: TUserDictionaryAttrToDraw['value']): void => {
    this.checklistsStore.updateAttrToDraw(groupId, value.checkListAttributeId, {
      value,
      isEdited: true,
    });

    this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.UserDictionaryLink>(
      groupId,
      value.checkListAttributeId,
      {
        isShowError: false,
      }
    );

    const attrToDraw = this.checklistsStore.getAttrToDraw<EAttrType.UserDictionaryLink>(
      groupId,
      value.checkListAttributeId
    );

    if (!attrToDraw) return;

    const newValueList = value.userDictionaryValues.reduce<IGetChecklistAttributeUserDictionary[]>(
      (list, dictValue) => {
        const isANewValue = Boolean(dictValue?.clientId);

        if (!isANewValue) return list;
        if (!this.isAddedNewValue(value.checkListAttributeId, dictValue)) return list;

        list.push(dictValue);

        return list;
      },
      []
    );

    const newSelectOptionList = newValueList.map<ISelectOption>(newValue => ({
      label: newValue.value,
      value: newValue.id,
    }));

    this.setOptionList(groupId, value.checkListAttributeId, [
      ...attrToDraw.options.selectOptionList,
      ...newSelectOptionList,
    ]);
  };

  setOptionList = (groupId: string, id: string, selectOptionList: ISelectOption[]): void => {
    this.checklistsStore.updateAttrToDrawOptions<EAttrType.UserDictionaryLink>(groupId, id, {
      selectOptionList,
    });
  };

  setSelectedOptionList = (
    groupId: string,
    id: string,
    selectOptionList: ISelectOption[]
  ): void => {
    this.checklistsStore.updateAttrToDrawOptions<EAttrType.UserDictionaryLink>(groupId, id, {
      selectedSelectOptionList: selectOptionList,
    });
  };

  getStoredData = (): IListOfAddedUserDictLinkValue | null => {
    const storedData: IListOfAddedUserDictLinkValue = JSON.parse(
      sessionStorage.getItem(LIST_OF_ADDED_USER_DICT_LINK_VALUE_KEY)
    );

    return storedData;
  };

  clearStoredData = (): void => {
    sessionStorage.removeItem(LIST_OF_ADDED_USER_DICT_LINK_VALUE_KEY);
  };

  getStoredValueList = (attrId: string): IGetChecklistAttributeUserDictionary[] => {
    const storedData = this.getStoredData();

    return storedData?.listOfChecklistUserDictLinkValue?.[attrId] || [];
  };

  addDataIntoSessionStorage = (data: IListOfAddedUserDictLinkValue): void => {
    const transformedStoredData: string = JSON.stringify(data);

    sessionStorage.setItem(LIST_OF_ADDED_USER_DICT_LINK_VALUE_KEY, transformedStoredData);
  };

  isAddedNewValue = (
    attrId: string,
    newUserDictLinkValue: IGetChecklistAttributeUserDictionary
  ): boolean => {
    const storedData = this.getStoredData();

    if (storedData) {
      const valueList = storedData.listOfChecklistUserDictLinkValue?.[attrId];

      const hasAlready = valueList?.find(
        value =>
          value.id === newUserDictLinkValue.id || value.clientId === newUserDictLinkValue.clientId
      );

      if (hasAlready) {
        return false;
      }

      if (valueList) {
        storedData.listOfChecklistUserDictLinkValue[attrId].push(newUserDictLinkValue);

        this.addDataIntoSessionStorage(storedData);

        return true;
      } else {
        storedData.listOfChecklistUserDictLinkValue[attrId] = [newUserDictLinkValue];

        this.addDataIntoSessionStorage(storedData);

        return true;
      }
    } else {
      const newData: IListOfAddedUserDictLinkValue = {
        listOfChecklistUserDictLinkValue: {
          [attrId]: [newUserDictLinkValue],
        },
      };

      this.addDataIntoSessionStorage(newData);

      return true;
    }
  };
}

export default ChecklistsUserDictionaryAttrsService;
