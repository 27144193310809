import { FC, memo } from 'react';

import Styled from './FieldsImportLoader.styles';
import { TFieldsImportLoaderProps } from './FieldsImportLoader.types';

const FieldsImportLoader: FC<TFieldsImportLoaderProps> = () => {
  return (
    <Styled.Wrapper>
      <Styled.LoaderWrapper>
        <Styled.Loader>
          <Styled.CenterCircle />
        </Styled.Loader>
      </Styled.LoaderWrapper>

      <Styled.Title>Подождите немного: файл обрабатывается</Styled.Title>
    </Styled.Wrapper>
  );
};

FieldsImportLoader.displayName = 'FieldsImportLoader';

export default memo(FieldsImportLoader);
