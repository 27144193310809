import styled from 'styled-components';

import defaultIcon from '../../../assets/icons/filtersDefault.svg';
import hoverIcon from '../../../assets/icons/filtersHover.svg';

const Icon = styled.div<{ $isSelected: boolean }>`
  width: 40px;
  height: 40px;

  cursor: pointer;

  background: url(${({ $isSelected }) => ($isSelected ? hoverIcon : defaultIcon)});

  &:hover {
    background: url(${hoverIcon});
  }
`;

const StyledTasksFiltersHeaderToggleFiltersButton = {
  Icon,
};

export default StyledTasksFiltersHeaderToggleFiltersButton;
