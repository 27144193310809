import { ISelectOption } from '../../../../../../../../../../../../types/selectOption';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { ProfileStore } from '../../../../../../../../../profile/stores/ProfileStore';
import { useTasksParams } from '../../../../../../../../hooks';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../../../../../../../shared/constants/access-rules-action';
import { CheckAccessStore } from '../../../../../../../../../../../authorization/stores/checkAccess.store';
import { TaskStore } from '../../../../../../../../mobx/stores';

const NO_ASSIGNEE_OPTION: ISelectOption = {
  label: 'Не назначено',
  value: 'noAssignee',
};

const useAssigneeSelect = (): {
  defaultValue: ISelectOption;
  optionList: ISelectOption[];
} => {
  const checkAccessStore = useStore(CheckAccessStore);
  const profileStore = useStore(ProfileStore);
  const taskStore = useStore(TaskStore);

  const params = useTasksParams();

  const isMyOrganization = params.orgId === 'my';

  const hasWorkWithTasksPermission = checkAccessStore.getAccessRuleValue(
    SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS
  );

  const hasManageTasksPermission = checkAccessStore.getAccessRuleValue(
    SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS
  );

  const selfSelectionOption: ISelectOption = {
    label: profileStore?.fullName,
    value: profileStore?.user?.id,
  };

  if (isMyOrganization) {
    return {
      defaultValue: selfSelectionOption,
      optionList: [selfSelectionOption],
    };
  }

  if (hasManageTasksPermission) {
    return {
      defaultValue: taskStore.userDefaultValue ?? NO_ASSIGNEE_OPTION,
      optionList: [NO_ASSIGNEE_OPTION, ...taskStore.userOptionList],
    };
  }

  if (hasWorkWithTasksPermission) {
    return {
      defaultValue: taskStore.userDefaultValue ?? NO_ASSIGNEE_OPTION,
      optionList: [NO_ASSIGNEE_OPTION, selfSelectionOption],
    };
  }

  return {
    defaultValue: null,
    optionList: [],
  };
};

export default useAssigneeSelect;
