import React, { FC, useEffect, useRef, useState } from 'react';
import { Accordeon } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import useOnclickOutside from 'react-cool-onclickoutside';

import { useStore } from '../../../shared/utils/IoC';
import MapStore from '../../../map/stores/map.store';
import { FieldsStore } from '../../modules/fields/stores/fields.store';
import { GoogleSearch } from '../../../shared/components/GoogleSearch';
import { CultureFillEnum } from '../../constants/culture.fill.enum';
import { LabelFieldFillEnum } from '../../constants/label.fill.enum';
import { Timeline } from '../Timeline/Timeline';
// import { MapMode } from '../../../map/stores/map.store';
// import { PopupPages } from '../../constants/popup.pages';
// import { UiStore } from '../../stores/ui.store';

import { TopPanelControlsController } from './controllers/topPanelControls.controller';
import { AccordeonWrapper, ControlPanelItem, ControlPanelWrapper } from './style';

export const TopPanelControls: FC = observer(() => {
  const topPanelControlsController = useStore(TopPanelControlsController);
  const fieldsStore = useStore(FieldsStore);
  const mapStore = useStore(MapStore);

  const [openArr, setOpenArr] = useState([false, false]);
  const accordeonWrapperRef = useOnclickOutside(() => {
    setOpenArr([false, false]);
  });
  const accWrapper = useRef(null);

  useEffect(() => {
    if (Boolean(fieldsStore.selectedFieldId)) {
      if (!Boolean(fieldsStore.prevFieldId)) {
        topPanelControlsController.setFieldFill(CultureFillEnum.Ndvi);
      }
    } else if (fieldsStore.fields.length) {
      topPanelControlsController.setFieldFill(CultureFillEnum.Fill);
    }
  }, [fieldsStore.selectedFieldId]);

  useEffect(() => {
    let cultureZonesIds = [];

    if (fieldsStore.fields.length && !Boolean(fieldsStore.selectedFieldId)) {
      cultureZonesIds = topPanelControlsController.setFieldFill(CultureFillEnum.Fill);
    }

    return () => {
      cultureZonesIds?.forEach(id => {
        mapStore.deletePolygon(id);
      });
    };
  }, [fieldsStore.fields.length]);

  return (
    <ControlPanelWrapper>
      {topPanelControlsController.isGoogleSearchPanel() && (
        <ControlPanelItem isMarginRight>
          <GoogleSearch />
        </ControlPanelItem>
      )}
      {topPanelControlsController.isCultureFillPanel() && (
        <ControlPanelItem ref={accordeonWrapperRef}>
          <AccordeonWrapper ref={accWrapper}>
            <Accordeon
              onOpen={() => {
                setOpenArr([true, false]);
                return 1;
              }}
              onHover={() => 1}
              onChange={value => {
                topPanelControlsController.setFieldFill(value as CultureFillEnum);
              }}
              onMouseOut={() => 1}
              // @ts-ignore
              options={topPanelControlsController.getCultureFillOptionsSet()}
              placeholder={'Выберите заливку'}
              isOpen={openArr[0]}
              dataTestId={'fill-switcher'}
              value={topPanelControlsController.getCultureFill()}
            />
          </AccordeonWrapper>
          {topPanelControlsController.labelSelectAreVisible() && (
            <AccordeonWrapper ref={accWrapper}>
              <Accordeon
                onOpen={() => {
                  setOpenArr([false, true]);
                  return 1;
                }}
                onHover={() => 1}
                onChange={(value: LabelFieldFillEnum) => {
                  topPanelControlsController.selectLabelFieldFillValue(value);
                }}
                onMouseOut={() => 1}
                // @ts-ignore
                options={topPanelControlsController.getLabelFieldFillOptionsSet()}
                placeholder={'Выберите подпись'}
                isOpen={openArr[1]}
                dataTestId={'label-field-fill-switcher'}
                value={topPanelControlsController.getLabelFieldFill()}
              />
            </AccordeonWrapper>
          )}
        </ControlPanelItem>
      )}

      {topPanelControlsController.showTimeLine() && (
        <ControlPanelItem fullWidth isMarginRight>
          <Timeline />
        </ControlPanelItem>
      )}
    </ControlPanelWrapper>
  );
});
