import { IGetChecklistInstanceByTaskId } from '../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { EPointType } from '../../../ListOfChecklistInstance.types';

const getInstanceTypeColor = (inst: IGetChecklistInstanceByTaskId) => {
  if (inst.isComplete) {
    return EPointType.Completed;
  }

  // TODO после уточнения добавить остальные кейсы

  return EPointType.Uncompleted;
};

export default getInstanceTypeColor;
