import { FC, memo } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import Styled from './TableBuilderCell.styles';

interface IProps {
  value: string | number;
  dataTestId?: string;
}

const TableBuilderCell: FC<IProps> = ({ value, dataTestId }) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>();

  return (
    <AutoTooltip content={value ?? '—'} disabled={!isShowTooltip} position={'bottom'}>
      <Styled.Wrapper ref={ref} data-test-id={dataTestId}>
        {value ?? '—'}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

TableBuilderCell.displayName = 'TableBuilderCell';

export default memo(TableBuilderCell);
