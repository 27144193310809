export const createPolygonEventName = 'map.createPolygonEventName';

export const createPolygonEvent = (geometry: any, id: number) => {
  const event = new CustomEvent(createPolygonEventName, {
    detail: {
      geometry,
      id,
    },
  });
  window.dispatchEvent(event);
};
