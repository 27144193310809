import { useMemo } from 'react';

import { ECheckListInstanceType } from '../../../../../../api/models/checklist/instance/checklist.instance.model';
import { ETaskTabs } from '../../models';
import { useStore } from '../../../../../shared/utils/IoC';
import { ChecklistInstancesStore } from '../../../operationsAndTasks/stores/checklist.instances.store';
import { useTasksParams } from '../index';
import { TaskStore } from '../../mobx/stores';

const useTaskEditBackButtonData = () => {
  const taskStore = useStore(TaskStore);
  const checklistInstancesStore = useStore(ChecklistInstancesStore);

  const params = useTasksParams();

  const checklistsBackButtonText = useMemo(() => {
    const drawInstance = checklistInstancesStore.drawInstanceList.find(
      inst => inst.id === params.checklistInstanceId
    );

    if (!drawInstance) {
      return 'Внесение данных чек-листа';
    }

    if (drawInstance?.instance?.type === ECheckListInstanceType.Machinery) {
      return `Техника ${drawInstance.positionNumber}. Внесение данных чек-листа`;
    } else if (drawInstance?.instance?.type === ECheckListInstanceType.Field) {
      return `Поле ${drawInstance.positionNumber}. Внесение данных чек-листа`;
    } else if (
      drawInstance?.instance?.type === ECheckListInstanceType.ActualPoint ||
      drawInstance?.instance?.type === ECheckListInstanceType.PlannedPoint
    ) {
      return `Точка ${drawInstance.positionNumber}. Внесение данных чек-листа`;
    }
  }, [params.checklistInstanceId, checklistInstancesStore.drawInstanceList]);

  const backButtonText = useMemo(() => {
    switch (taskStore.taskTab) {
      case ETaskTabs.Map:
        return 'Добавление точек';
      case ETaskTabs.Checklists:
        return checklistsBackButtonText;
      default:
        return 'Редактирование задачи';
    }
  }, [taskStore.taskTab, checklistsBackButtonText]);

  return {
    backButtonText,
  };
};

export default useTaskEditBackButtonData;
