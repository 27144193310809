import { FC, memo } from 'react';

import { IChecklistsAttrToDraw } from '../../../models';
import { EChecklistAttributeType as EAttributeType } from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { ChecklistsIntegerAttrByEnteringMethodContainer as IntegerAttrByEnteringMethodContainer } from '../ChecklistsIntegerAttr';
import { ChecklistsDoubleAttrByEnteringMethodContainer as DoubleAttrByEnteringMethodContainer } from '../ChecklistsDoubleAttr';
import { ChecklistsStringAttr as StringAttr } from '../ChecklistsStringAttr/ChecklistsStringAttr';
import { ChecklistsBooleanAttr as BooleanAttr } from '../ChecklistsBooleanAttr/ChecklistsBooleanAttr';
import { ChecklistsDictionaryAttr as DictionaryAttr } from '../ChecklistsDictionaryAttr/ChecklistsDictionaryAttr';
import { ChecklistsEnumAttr as EnumAttr } from '../ChecklistsEnumAttr/ChecklistsEnumAttr';
import { ChecklistsUserDictionaryAttr as UserDictionaryAttr } from '../ChecklistsUserDictionaryAttr/ChecklistsUserDictionaryAttr';
import { ChecklistsLongStringAttr as LongStringAttr } from '../ChecklistsLongStringAttr/ChecklistsLongStringAttr';
import { ChecklistsChecklistAttr as ChecklistAttr } from '../ChecklistsChecklistAttr';
import { ChecklistsFileAttrByPositionContainer as FileAttrByPositionContainer } from '../ChecklistsFileAttr';
import { ChecklistsDateAttr as DateAttr } from '../ChecklistsDateAttr/ChecklistsDateAttr';

interface IProps {
  attrToDraw: IChecklistsAttrToDraw;
}

const ChecklistsAttrByTypeContainer: FC<IProps> = ({ attrToDraw }) => {
  switch (attrToDraw.initialModel.attribute.type) {
    case EAttributeType.Boolean:
      return <BooleanAttr attrToDraw={attrToDraw} />;

    case EAttributeType.Int:
      return <IntegerAttrByEnteringMethodContainer attrToDraw={attrToDraw} />;

    case EAttributeType.Double:
      return <DoubleAttrByEnteringMethodContainer attrToDraw={attrToDraw} />;

    case EAttributeType.String:
      return <StringAttr attrToDraw={attrToDraw} />;

    case EAttributeType.DictionaryLink:
      return <DictionaryAttr attrToDraw={attrToDraw} />;

    case EAttributeType.Enum:
      return <EnumAttr attrToDraw={attrToDraw} />;

    case EAttributeType.UserDictionaryLink:
      return <UserDictionaryAttr attrToDraw={attrToDraw} />;

    case EAttributeType.FileLink:
      return <FileAttrByPositionContainer attrToDraw={attrToDraw} />;

    case EAttributeType.ChecklistInstanceLink:
      return <ChecklistAttr attrToDraw={attrToDraw} />;

    case EAttributeType.LongString:
      return <LongStringAttr attrToDraw={attrToDraw} />;

    case EAttributeType.Date:
      return <DateAttr attrToDraw={attrToDraw} />;

    default:
      return <></>;
  }
};

ChecklistsAttrByTypeContainer.displayName = 'ChecklistsAttrByTypeContainer';

export default memo(ChecklistsAttrByTypeContainer);
