import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useContextualHelpActions, useModal } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../shared/utils/IoC';
import { TaskStore } from '../../mobx/stores';
import { LEAVE_BEFORE_SAVE_MODAL_ID } from '../../modals/leaveBeforeSaveModal';
import { TaskControlButtons } from '../../components/TaskFullscreen/components/TaskCommonData/components';
import Fullscreen from '../../containers/fullscreen';
import TaskCommonData from '../../components/TaskFullscreen/components/TaskCommonData/TaskCommonData';
import { Container } from '../../../../../shared/features/UI/Container';
import { useTasksBackButtonData } from '../../hooks/useTasksBackButtonData';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../shared/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../shared/hooks';

import Styled from './TaskCreateOne.styles';

const TaskCreateOne: FC = () => {
  const taskStore = useStore(TaskStore);

  const modalActions = useModal();
  const backButtonData = useTasksBackButtonData();

  usePageContextualHelp(EContextualParentPath.TasksTaskCreateOne);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.TasksTaskCreateOne,
    ContextualPaths.TasksTaskCreateOneHeaderTitle
  );

  useEffect(() => {
    taskStore.setMode('create-one');
  }, []);

  const handleBackButtonClick = useCallback(() => {
    if (taskStore.isFormChanged) {
      modalActions.openModalByModalId(LEAVE_BEFORE_SAVE_MODAL_ID, null, backButtonData.handler);
    } else {
      backButtonData.handler();
    }
  }, [taskStore.isFormChanged, backButtonData.handler]);

  return (
    <Fullscreen
      backButtonText={
        <Styled.TitleWrapper>
          <Styled.Title>Создание задачи</Styled.Title>

          {ContextualHelpIcon}
        </Styled.TitleWrapper>
      }
      backButtonHandler={handleBackButtonClick}
      arrowText={backButtonData.arrowText}
    >
      <Container header={{ title: 'Общие данные' }}>
        <TaskCommonData />
      </Container>

      <TaskControlButtons />
    </Fullscreen>
  );
};

TaskCreateOne.displayName = 'TaskCreateOne';

export default observer(TaskCreateOne);
