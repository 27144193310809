import { makeAutoObservable } from 'mobx';

import { provide } from '../../shared/utils/IoC';
import { IModal } from '../components/Modal/Modal';

@provide.singleton()
export class ModalsStore {
  private uniqueKeyToModal: Map<string, IModal> = new Map<string, IModal>();

  constructor() {
    makeAutoObservable(this);
  }

  getModal = (uniqueKey: string): IModal => this.uniqueKeyToModal.get(uniqueKey);

  setModal = (uniqueKey: string, modal: IModal): void => {
    this.uniqueKeyToModal.set(uniqueKey, modal);
  };

  clearModal = (uniqueKey: string): void => {
    this.uniqueKeyToModal.delete(uniqueKey);
  };

  clearUniqueKeyToModal = (): void => {
    this.uniqueKeyToModal.clear();
  };
}
