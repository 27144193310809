import { CultureZone, Field } from '../../../../../../../../../api/models/field.model';
import {
  IMapMarkerElement as IMarkerElement,
  IMapMarkerElementConfig as IMarkerElementConfig,
  IMapPolygonElementConfig as IPolygonElementConfig,
} from '../../../../../../../../shared/features/map/models';
import { InspectionPointsElementStyles as ElementStyles } from '../../constants';
import {
  ECheckListInstanceType,
  IDrawChecklistInstance,
  IGetChecklistInstanceByTaskId,
} from '../../../../../../../../../api/models/checklist/instance/checklist.instance.model';

interface ICreateZoneConfigOptions {
  isAllowedToCreateMarkers?: boolean;
}

function createZoneConfig<E extends CultureZone | Field>(
  zone: E,
  options?: ICreateZoneConfigOptions
): IPolygonElementConfig<E> {
  return {
    element: zone,
    coordinates: zone.geometry.coordinates,
    options: {
      style: options?.isAllowedToCreateMarkers ? ElementStyles.zoneEdit : ElementStyles.zoneView,
    },
  };
}

function createPlanPointOptions(
  positionNumber: number,
  isCompleted?: boolean
): IMarkerElementConfig['options'] {
  return {
    style: {
      html: String(positionNumber),
      className: `inspection-point inspection-point_plan inspection-point_${
        isCompleted ? 'completed' : 'uncompleted'
      }`,
    },
    tooltip: {
      isDisplayCoordinates: true,
    },
  };
}

function createActualPointOptions(
  positionNumber: number,
  isCompleted?: boolean
): IMarkerElementConfig['options'] {
  return {
    style: {
      html: String(positionNumber),
      className: `inspection-point inspection-point_actual inspection-point_${
        isCompleted ? 'completed' : 'uncompleted'
      }`,
    },
    tooltip: {
      isDisplayCoordinates: true,
    },
  };
}

function getCoorsByInstanceType(drawInstance: IDrawChecklistInstance): number[] {
  const actualCoords =
    drawInstance.instance?.actualCoords?.coordinates ||
    drawInstance.instance?.actualCoords?.geometry?.coordinates;

  const planCoords =
    drawInstance.instance?.planCoords?.coordinates ||
    drawInstance.instance?.planCoords?.geometry?.coordinates;

  switch (drawInstance.instance.type) {
    case ECheckListInstanceType.ActualPoint:
      return actualCoords;
    case ECheckListInstanceType.Field:
      return planCoords;
    case ECheckListInstanceType.Machinery:
      return planCoords;
    case ECheckListInstanceType.PlannedPoint:
      return planCoords;
    default:
  }
}

function createPointConfig(drawInstance: IDrawChecklistInstance): IMarkerElementConfig {
  const isActualPoint = drawInstance.instance.type === ECheckListInstanceType.ActualPoint;

  const coordinates = getCoorsByInstanceType(drawInstance);

  const options = isActualPoint
    ? createActualPointOptions(
        drawInstance.positionNumber,
        (drawInstance.instance as IGetChecklistInstanceByTaskId)?.isComplete
      )
    : createPlanPointOptions(
        drawInstance.positionNumber,
        (drawInstance.instance as IGetChecklistInstanceByTaskId)?.isComplete
      );

  return {
    element: drawInstance,
    coordinates: coordinates ? [coordinates[1], coordinates[0]] : null,
    options,
  };
}

function createPointConfigList(drawInstanceList: IDrawChecklistInstance[]): IMarkerElementConfig[] {
  return drawInstanceList.map(createPointConfig);
}

function getPointElementListToChangeStyle(
  pointElementList: IMarkerElement[],
  drawPointInstanceList: IDrawChecklistInstance[]
): IMarkerElement<IDrawChecklistInstance>[] {
  const pointListToChangeStyle = pointElementList.reduce<IMarkerElement<IDrawChecklistInstance>[]>(
    (list, { element, marker }) => {
      const changedPoint = drawPointInstanceList.find(({ id }) => id === element.id);

      if (changedPoint) {
        const hasChangedNumber = element.positionNumber !== changedPoint.positionNumber;

        if (hasChangedNumber) {
          list.push({ marker, element: changedPoint });
        }
      }

      return list;
    },
    []
  );

  return pointListToChangeStyle;
}

const InspectionPointsElementHelpers = {
  createZoneConfig,
  createPointConfig,
  createPointConfigList,
  createPlanPointOptions,
  createActualPointOptions,
  getPointElementListToChangeStyle,
};

export default InspectionPointsElementHelpers;
