export const GetCkeckedImgUrl = (imgUrl: string, dummyImgUrl?: string) => {
  const resultUrl = new Promise<string>((resolve) => {
    const imgForUrlCheck = new Image();
    imgForUrlCheck.onload = () => {
      resolve(imgUrl);
    };
    imgForUrlCheck.onerror = () => {
      console.error('error occured while field polygon image fetching');
      resolve('');
    };

    imgForUrlCheck.src = imgUrl;
  });
  return resultUrl;
};
