import { IGetChecklistInstance } from '../../../models/checklist/instance/checklist.instance.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  id: string;
};

type TypeResponse = {} & IGetChecklistInstance;

export const getChecklistInstanceById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: ({ id }) => `/api/as-fields/checklist/instance/${id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
