export enum ProfileRoutes {
  Dashboard = '/profile/',
  DashboardMobile = '/profile/mobile-menu',
  EditPersonalData = '/profile/edit',
  EditCompetitionsData = '/profile/edit-expertises',
  EditInterestsData = '/profile/edit-interests',
  Organizations = '/profile/organizations/',
  Publications = '/profile/publications',
  Fields = '/profile/fields',
  AllFields = '/profile/fields/allFields',
  OrgFields = '/profile/fields/organizationFields',
  EditWeatherWidgets = '/profile/widgets/weather',
  FullEditWeatherWidgets = '/profile/widgets/weather/full',
  FullEditQuoteWidgets = '/profile/widgets/quotes/full',
  EditQuotesWidgets = '/profile/widgets/quotes',
  ChangeLog = '/profile/changelog',
}
