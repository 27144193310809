import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../shared/utils/IoC';

@provide.singleton()
export class SeasonCultureStore {
  constructor() {
    makeAutoObservable(this);
  }
  selectedCultureId = '';

  setSelectedCultureId = (selectedCultureId: string) => {
    this.selectedCultureId = selectedCultureId;
  };

  clearSeasonCultureStore = () => {
    this.selectedCultureId = '';
  };
}
