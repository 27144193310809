import { FC, useMemo, useState } from 'react';

import { IGetChecklistInstanceByTaskId } from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';

import { ViewingPointsRows } from './components/ViewingPointsRows';
import Styled from './ViewingPoints.styles';

interface IProps {
  checklistInstanceList: IGetChecklistInstanceByTaskId[];
}

const maxVisibleRowsNumber = 4;

const ViewingPoints: FC<IProps> = ({ checklistInstanceList }) => {
  const [isShowAllRows, setIsShowAllRows] = useState(false);

  const buttonLabel = useMemo(() => {
    return `Показать +${checklistInstanceList.length - maxVisibleRowsNumber}`;
  }, [checklistInstanceList.length]);

  return (
    <Styled.Wrapper>
      <Styled.Label>Точки осмотра</Styled.Label>

      <Styled.ViewingPointsWrapper $isShowAllRows={isShowAllRows}>
        <ViewingPointsRows checklistInstanceList={checklistInstanceList} />
      </Styled.ViewingPointsWrapper>

      {checklistInstanceList.length > maxVisibleRowsNumber && !isShowAllRows ? (
        <Styled.ButtonWrapper>
          <Styled.Button
            onClick={() => {
              setIsShowAllRows(true);
            }}
          >
            {buttonLabel}
          </Styled.Button>
        </Styled.ButtonWrapper>
      ) : null}
    </Styled.Wrapper>
  );
};

ViewingPoints.displayName = 'ViewingPoints';

export default ViewingPoints;
