import styled, { css } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

import { TaskStatuses } from '../../../../../../operations/stores/tasks.store';

const getStatusStyle = (status: TaskStatuses) => {
  const statusStylesMatrix = {
    [TaskStatuses.Canceled]: css`
      background-color: ${Colors.lightPink};
      color: ${Colors.red};
    `,
    [TaskStatuses.New]: css`
      background-color: ${Colors.lightBlue};
      color: ${Colors.addSky};
    `,
    [TaskStatuses.Completed]: css`
      background-color: ${Colors.secondaryGray};
      color: ${Colors.generalBlack};
    `,
    [TaskStatuses.InWork]: css`
      background-color: ${Colors.lightGreen};
      color: ${Colors.accentGreen};
    `,
  };

  return statusStylesMatrix[status];
};

const Status = styled.div<{ $status: TaskStatuses }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  padding: 2px 8px;
  border-radius: 8px;

  ${({ $status }) => getStatusStyle($status)}
`;

const Wrapper = styled.div`
  display: flex;
`;

const StyledTasksListStatusCell = {
  Status,
  Wrapper,
};

export default StyledTasksListStatusCell;
