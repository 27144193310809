import { lazyInject, provide } from '../../../../../utils/IoC';
import { PermissionsBuilderPermissionsService as PermissionsService } from '../../services';
import { PermissionsBuilderStore as Store } from '../../stores';

/**
 * Контроллер доступов, где:
 * P - строковые типы самих доступов (экшены).
 * B - идентификатор сущности (билдера).
 */
@provide.transient()
class PermissionsBuilderController<P = string, B = string> {
  @lazyInject(Store)
  protected store: Store<P, B>;

  @lazyInject(PermissionsService)
  protected permissionsService: PermissionsService<P, B>;

  addPermissionList: PermissionsService<P, B>['addPermissionList'] = (
    builderId,
    permissionList,
    options
  ): void => {
    this.permissionsService.addPermissionList(builderId, permissionList, options);
  };

  clearStore = (builderId: B): void => {
    this.store.deletePermissions(builderId);
  };
}

export default PermissionsBuilderController;
