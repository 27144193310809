import React from 'react';
import Helmet from 'react-helmet';

export const withBrowserTitle = (title: string) => Component => props => {
  return (
    <>
      <Helmet>
        <title>ДА.Помощник. {title}</title>
      </Helmet>
      <Component {...props} />
    </>
  );
};
