import styled from 'styled-components';

const Wrapper = styled.div``;

const Icon = styled.div`
  padding: 16px;
  text-align: center;
  margin-bottom: 20px;
`;

const Title = styled.h4`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.5px;
  text-align: center;

  margin-bottom: 36px;
`;

const Styled = {
  Wrapper,
  Icon,
  Title,
};

export default Styled;
