import React from 'react';
import { makeAutoObservable, reaction } from 'mobx';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../shared/utils/axios2';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { OperationCulture } from '../../../../../api/models/operations/operation.culture';
import { SeasonsStore } from '../../../stores/seasons.store';
import { Operation } from '../../../../../api/models/operations/operation.model';
import { OperationField } from '../../../../../api/models/operations/operation.field.model';
import { OperationType } from '../../../../../api/models/operations/operation.type.model';

import { OperationsStore } from './operations.store';
import { SeasonCultureStore } from './season.culture.store';

@provide.singleton()
export class OperationsUIStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsStore)
  protected seasons: SeasonsStore;

  @lazyInject(SeasonCultureStore)
  protected seasonCulture: SeasonCultureStore;

  @lazyInject(OrganizationsStore)
  protected organizations: OrganizationsStore;

  @lazyInject(OperationsStore)
  protected operations: OperationsStore;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.operations.selectedOperationId,
      () => {
        this.selectMarkId(this.operations.selectedOperationId);
      }
    );
    reaction(
      () => this.operations.selectedOperationFieldId,
      () => {
        this.selectMarkId(this.operations.selectedOperationFieldId);
      }
    );
  }

  selectedMarkId = '';

  selectMarkId = (id: string) => {
    this.selectedMarkId = id;
  };

  
}
