import styled from 'styled-components';

import { AccessRuleVisibility } from './ControlAccessRulesWrapper';

export const ControlAccessRulesWrapperEl = styled.div<{ mode: AccessRuleVisibility }>`
    width: 100%;
    height: 100%;
    display: ${({mode}) =>  mode === AccessRuleVisibility.View 
        || mode === AccessRuleVisibility.Disable ? 'flex' : 'none'};
    pointer-events: ${({mode}) => mode === AccessRuleVisibility.View ? 'auto' : 'none'};
    opacity: ${({mode}) => mode === AccessRuleVisibility.View ? '1' : '0.5'}; 
`;
