import { FC, memo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { ITaskPreview } from '../../../../../../../../../../api/models/as-fields/task/task.model';
import { useShowTooltip } from '../../../../../../../../../shared/features/utils/hooks/tooltip';

import Styled from './PreviewTasksTableOperationCell.styles';

interface IProps {
  taskPreview: ITaskPreview;
}

const PreviewTasksTableOperationCell: FC<IProps> = ({ taskPreview }) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>();

  return (
    <Styled.Wrapper>
      <Styled.Order>{taskPreview.order}</Styled.Order>

      <Styled.Icon $url={taskPreview?.operation?.operationTypeInfo?.attrs?.picUrl} />

      <AutoTooltip
        content={taskPreview.operationName}
        disabled={!isShowTooltip}
        position={'bottom'}
      >
        <Styled.Name ref={ref}>{taskPreview.operationName}</Styled.Name>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

PreviewTasksTableOperationCell.displayName = 'PreviewTasksTableOperationCell';

export default memo(PreviewTasksTableOperationCell);
