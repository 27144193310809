import styled from 'styled-components';

import { Colors } from '../../../shared/constans/colors';
import { BorderRadius } from '../../../shared/constans/sizes';

export const LegendWrapper = styled.div`
  padding: 20px 20px 10px 20px;
  border-radius: ${BorderRadius.default};
  background: ${Colors.white};
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 1001;
  display: flex;
  flex-direction: row;
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${Colors.darkGray};
`;

export const SetWrapper = styled.div`
  margin-right: 12px;

  &:last-child {
    margin-right: 0;

    ${Label} {
      text-align: center;
      flex-grow: 1;
    }
  }
`;

export const WeatherColorsWrapper = styled.div`
  display: flex;
  border-radius: ${BorderRadius.default};
  overflow: hidden;
  margin-bottom: 4px;
  width: 10rem;

`;

export const ColorBlocksWrapper = styled.div`
  display: flex;
  border-radius: ${BorderRadius.default};
  overflow: hidden;
  margin-bottom: 4px;
`;

export const ColorBlock = styled.div`
  width: 16px;
  height: 8px;
  background: ${(props) => props.color};
  flex-grow: 1;
`;

export const LabelsBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;
