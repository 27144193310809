import styled from 'styled-components';

const Input = styled.input`
  display: none;
`;

const StyledFieldsImportModalContainer = {
  Input,
};

export default StyledFieldsImportModalContainer;
