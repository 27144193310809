import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../../../mobx/stores';
import { TaskController } from '../../../../../../mobx/controllers';
import { ETaskAction } from '../../../../../../../../../../api/models/as-fields/task/task.model';
import { useTaskActionControl } from '../../../../../../hooks';
import { Input } from '../../../../../../../operationsAndTasks/modules/fullscreen/checklist/components/shared/Input';

const MAX_COMMENT_LENGTH = 500;

const Comment: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);

  const controls = useTaskActionControl();

  const isCreate = taskStore.isCreateOne || taskStore.isCreateMultiple;
  const hasPermissionToEdit = controls.hasPermission(ETaskAction.TaskEditCommonInfoComment);
  const canEdit = isCreate || (taskStore.isEditMode && hasPermissionToEdit);

  const isBlocked = !canEdit || taskStore.isViewMode;

  const handleChange = useCallback((value: string): void => {
    taskController.changeComment(value);
  }, []);

  return (
    <Input
      label="Комментарий к задаче"
      placeholder="Отсутствует"
      value={
        taskStore.taskCreate?.comment ??
        taskStore.taskUpdate?.comment ??
        taskStore.selectedTask?.comment ??
        ''
      }
      onChange={handleChange}
      isBlocked={isBlocked}
      isWithoutErrorText={true}
      maxLength={MAX_COMMENT_LENGTH}
      as="textarea"
      isResizable={true}
      height="100px"
      dataTestId="comment-textarea"
    />
  );
};

Comment.displayName = 'Comment';

export default observer(Comment);
