import { EFieldsUploadErrorType } from '../../../../modules/dashboard/modules/fields/utils/constants/import';
import { TypeApiRoute } from '../../../models/type.api.request';

type TImportFieldsFromKmlReq = {
  seasonYear: number;
  file: FormData;
  organizationId?: string;
};

type TImportFieldsFromKmlRes = {
  token: 'string';
  errorType?: EFieldsUploadErrorType;
  errorDescription?: 'string';
};

const importFieldsFromKml: TypeApiRoute & {
  request: TImportFieldsFromKmlReq;
  response: TImportFieldsFromKmlRes;
} = {
  url: () => '/api/as-fields/import/kml',
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  request: {} as TImportFieldsFromKmlReq,
  response: {} as TImportFieldsFromKmlRes,
};

export type { TImportFieldsFromKmlReq, TImportFieldsFromKmlRes };

export { importFieldsFromKml };
