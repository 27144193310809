import styled from 'styled-components';

import { Colors } from '../../../../../../../../shared/constans/colors';

const Wrapper = styled.button`
  width: 426px;
  height: 40px;

  background-color: white;

  border: none;
  color: ${Colors.green};
  box-shadow: 0 0 0 1px ${Colors.green};
  border-radius: 12px;
  padding: 12px 16px;
  cursor: pointer;
`;

const StyledChecklistsButton = {
  Wrapper,
};

export default StyledChecklistsButton;
