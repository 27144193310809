import { makeAutoObservable, reaction } from 'mobx';
import L, {
  DivIcon,
  LatLngBoundsExpression,
  Layer,
  marker,
  Polygon,
  Map as LMap,
  FitBoundsOptions,
  PM,
  LatLng,
} from 'leaflet';
import area from '@turf/area';
import { intersect } from '@turf/turf';
import _, { throttle } from 'lodash';

import mapMarkerSvg from '../static/mapMarker.svg';
import { provide } from '../../shared/utils/IoC';
import { createPolygonEvent } from '../events/create.polygon.event';
import { editPolygon } from '../events/edit.polygon.event';
import { RenderPolygonOption } from '../consts/enum.render.option';
import { LabelFieldFillEnum } from '../../dashboard/constants/label.fill.enum';
import { createCultureZoneEvent } from '../events/create.culture.zone.event';
import { clickMapEvent } from '../events/click.map.event';
import { CultureZone } from '../../../api/models/field.model';
import { FOCUSED_MARKER_CLASS } from '../../shared/constants/map/map.constants';

const polygonOptions: L.PM.DrawModeOptions = {
  allowSelfIntersection: false,
  hintlineStyle: {
    fillColor: '#fff9e8',
    color: '#FFD765',
    dashArray: [5, 5],
  },
  snappable: true,
  snapDistance: 3,
  pathOptions: {
    fillColor: '#fff9e8',
    color: '#FFD765',
  },
  markerStyle: {
    // @ts-ignore
    color: '#FFD765',
  },
  templineStyle: {
    fillColor: '#fff9e8',
    color: '#FFD765',
  },
};

const cultureWizardPolygonOptions: L.PM.DrawModeOptions = {
  allowSelfIntersection: false,
  hintlineStyle: {
    fillColor: '#fff',
    fillOpacity: 0,
    color: '#F29993',
    dashArray: [5, 5],
  },
  snappable: true,
  snapDistance: 3,
  pathOptions: {
    fillColor: '#fff9e8',
    color: '#F29993',
    fillOpacity: 0,
  },
  markerStyle: {
    // @ts-ignore
    color: '#F29993',
  },
  templineStyle: {
    fillColor: '#fff',
    fillOpacity: 0,
    color: '#F29993',
    dashArray: [5, 5],
  },
};

export type GeoPoint = {
  lat: number;
  lng: number;
};

export type PolygonData = {
  id: string;
  points: Array<GeoPoint>;
  fill?: string;
};

export enum MapMode {
  Listing = 'listing',
  Creating = 'creating',
  Editing = 'editing',
  CZEditing = 'CZEditing',
  Import = 'import',
}

enum ETooltipSize {
  Normal = '16px',
  Small = '14px',
}

enum PointerStatus {
  None = 'none',
  Auto = 'auto',
}

// Уровень зума карты для скрытия тултипов и уменьшения размера шрифта
const zoomGap = 11;

@provide.singleton()
class MapStore {
  cultureZoneOutOfFieldError;
  prevCultureZoneOutOfFieldError = false;

  // needed to track unfinished contours
  pointsOfUnfinishedContour = 0;

  idToLayout: Map<number, any> = new Map();
  tempMakersSet: Set<HTMLElement> = new Set();

  // todo mapMode присутствует и в fieldStore и mapStore
  mapMode: MapMode = MapMode.Listing;

  private _idToEditableLayerGeometry: Map<number, any> = new Map<number, any>();

  private _idToUnchangedLayer: Map<number, any> = new Map<number, any>();

  private _idToImportedFieldLayer: Map<number, any> = new Map<number, any>();

  private _collectionOfPrevIncorrectLayerId: Set<number> = new Set<number>();

  /**
   * Требуется для проверки на пересечения редактируемого поля,
   * чтобы не перезапускать глобальное сравнение всех полей
   */
  private _collectionOfTempIncorrectLayerId: Set<number> = new Set<number>();

  private _tooltipTextSize: ETooltipSize = ETooltipSize.Normal;

  incorrectLayersId: Set<number> = new Set<number>();
  currentLayer: Layer;

  _drawEndTrigger = 0;

  /**
   * Позиция вертекса в фокусе
   *
   * @private
   * @type {LatLng}
   * @memberof MapStore
   */
  private _currentPointPosition: LatLng = null;

  /**
   * Индекс вертекса в редактируемом полигоне
   *
   * @private
   * @type {[number, number]}
   * @memberof MapStore
   */
  private _currentPointLayerIndex: [number, number] = null;

  private _currentPointId: number = null;

  private _currentFocusMarkerElement: HTMLDivElement = null;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => Array.from(this.incorrectLayersId),
      () => {
        Array.from(this.idToPolygon.values()).forEach(l => {
          // @ts-ignore
          this.changeColorToNormal(RenderPolygonOption.View, l._leaflet_id);
        });
        Array.from(this.idToEditableLayer.values()).forEach(l => {
          // @ts-ignore
          this.changeColorToNormal(RenderPolygonOption.Creating, l._leaflet_id);
        });
        Array.from(this.incorrectLayersId).forEach(id => {
          this.showErrorInPolygon(id);
        });
        console.log('error reaction');
      }
    );

    // / 123

    reaction(
      () => this.pointsOfUnfinishedContour,
      pointsOfUnfinishedContour => {
        if (pointsOfUnfinishedContour === 0) {
          this.unlockMarkers();
        } else {
          this.lockMarkers();
        }
      }
    );
  }

  get drawEndTrigger() {
    return this._drawEndTrigger;
  }

  get isImportMapMode() {
    return this.mapMode === MapMode.Import;
  }

  get editableLayerList() {
    return Array.from(this.idToEditableLayer.values());
  }

  get layerList() {
    return Array.from(this.idToPolygon.values());
  }

  get listOfPrevIncorrectLayerId() {
    return Array.from(this._collectionOfPrevIncorrectLayerId);
  }

  toggleDrawEndTrigger = () => {
    this._drawEndTrigger = Math.random();
  };

  setCollectionOfPrevIncorrectLayerId = (collection: Set<number>): void => {
    this._collectionOfPrevIncorrectLayerId = collection;
  };

  clearCollectionOfPrevIncorrectLayerId = (): void => {
    this._collectionOfPrevIncorrectLayerId.clear();
  };

  get listOfTempIncorrectLayerId() {
    return Array.from(this._collectionOfTempIncorrectLayerId);
  }

  setCollectionOfTempIncorrectLayerId = (collection: Set<number>): void => {
    this._collectionOfTempIncorrectLayerId = collection;
  };

  clearCollectionOfTempIncorrectLayerId = (): void => {
    this._collectionOfTempIncorrectLayerId.clear();
  };

  getLayer = (id: number) => {
    return this.idToEditableLayer.get(id) || this.idToPolygon.get(id) || this.idToLayout.get(id);
  };

  getEditableLayer = (id: number) => {
    return this.idToEditableLayer.get(id);
  };

  getIncorrectLayerId = (id: number): boolean => {
    return this.incorrectLayersId.has(id);
  };

  getEditableLayerGeometry = (id: number): any => {
    return this._idToEditableLayerGeometry.get(id);
  };

  unchangedLayer = (id: number): any => {
    return this._idToUnchangedLayer.get(id);
  };

  getImportedFieldLayer = (id: number): any => {
    return this._idToImportedFieldLayer.get(id);
  };

  get currentPointPosition() {
    return this._currentPointPosition;
  }

  get currentPointLayerIndex() {
    return this._currentPointLayerIndex;
  }

  get currentPointId() {
    return this._currentPointId;
  }

  get currentFocusMarkerElement() {
    return this._currentFocusMarkerElement;
  }

  setIncorrectLayersId = (collection: Set<number>): void => {
    this.incorrectLayersId = collection;
  };

  setEditableLayerGeometry = (id: number, geometry: any): void => {
    this._idToEditableLayerGeometry.set(id, geometry);
  };

  setIdToImportedFieldLayer = (idToLayer: Map<number, any>): void => {
    this._idToImportedFieldLayer = idToLayer;
  };

  setImportedFieldLayer = (id: number, layer: any): void => {
    this._idToImportedFieldLayer.set(id, layer);
  };

  deleteImportedFieldLayer = (id: number): void => {
    this._idToImportedFieldLayer.delete(id);
  };

  setIdToUnchangedLayer = (idToLayer: Map<number, any>): void => {
    this._idToUnchangedLayer = idToLayer;
  };

  clearIdToImportedFieldLayer = (): void => {
    this._idToImportedFieldLayer.clear();
  };

  clearIdToUnchangedLayer = (): void => {
    this._idToUnchangedLayer.clear();
  };

  clearEditableLayerGeometry = (): void => {
    this._idToEditableLayerGeometry.clear();
  };

  deleteLayout = (id: number): void => {
    this.idToLayout.delete(id);
  };

  incrementPointsOfUnfinishedContour = (): void => {
    this.pointsOfUnfinishedContour++;
  };

  clearPointsOfUnfinishedContour = (): void => {
    this.pointsOfUnfinishedContour = 0;
  };

  setIsShapeFinished = (value: boolean): void => {
    this.isShapeFinished = value;
  };

  clearIncorrectLayerId = (): void => {
    this.incorrectLayersId.clear();
  };

  changeColorToNormal = (colorOption: RenderPolygonOption, layerId: number) => {
    if (this.mapMode === MapMode.Import) {
      const layerOfUnchangedField = this.unchangedLayer(layerId);
      const layerOfImportedField = this.getImportedFieldLayer(layerId);

      if (layerOfUnchangedField) {
        layerOfUnchangedField.setStyle({
          fillColor: '#F2F1F4',
          fillOpacity: 0.25,
          color: '#F2F1F4',
          colorOpacity: 0.7,
        });
      }

      if (layerOfImportedField) {
        layerOfImportedField.setStyle({
          fillColor: '#fff9e8',
          color: '#FFD765',
        });
      }

      return;
    }

    if (colorOption === RenderPolygonOption.View) {
      const layer = this.idToPolygon.get(layerId);
      layer.setStyle({ color: '#FD7E09' });
      return;
    }

    if (colorOption === RenderPolygonOption.Listing) {
      const layer = this.idToPolygon.get(layerId);
      if (!layer) {
        return;
      }
      layer.setStyle({ color: '#FFD765' });
      return;
    }

    const layer = this.idToEditableLayer.get(layerId);
    // @ts-ignore
    layer.setStyle({ color: '#FFD765' });
  };

  highlightPolygon = (layerId: number, isPadding?: boolean) => {
    let layer;
    try {
      layer = this.getLayer(layerId);
    } catch (e) {
      console.log(e);
    }

    if (!layer) {
      return;
    }

    if (this.instance) {
      layer.setStyle({
        fillColor: '#ffffff',
        fillOpacity: 0.15,
        strokeWidth: 1,
        color: '#ffffff',
      });

      layer.bringToFront();
    }

    this.centerMapToPoint(layerId, isPadding);
  };

  centerMapToPoint = (layerId: number, isPadding?: boolean) => {
    let layer;
    try {
      layer = this.getLayer(layerId);
    } catch (e) {
      console.log(e);
    }

    if (!layer) {
      return;
    }

    const layerBounds = layer.getBounds();
    const options: FitBoundsOptions = {
      padding: isPadding && [150, 150],
    };

    if (this.instance) this.instance.fitBounds(layerBounds, options);

    // const layerBounds = layer.getBounds();
    // console.log('East - West', layerBounds.getSouthWest(), layerBounds.getSouthEast());
    //
    // const horizontalLength = layerBounds.getSouthWest().distanceTo(layerBounds.getSouthEast());
    // // @ts-ignore
    // const latLon = new L.latLng(layerBounds.center);
    // const bounds = latLon.toBounds(horizontalLength); // 500 = metres
    // this.instance.panTo(latLon).fitBounds(bounds);
  };

  setTooltipContentForLayer = (layerId: number, content: string, isEditable?: boolean) => {
    const layer = isEditable ? this.getEditableLayer(layerId) : this.getLayer(layerId);
    if (!layer) return;
    layer.setTooltipContent(content);
  };

  setViewMode = () => {
    if (!this.instance) {
      return;
    }
    this.mapMode = MapMode.Listing;
    this.instance.pm.disableDraw();
  };

  setInstance = (v: LMap) => {
    // @ts-ignore
    window.leafletMap = v;
    this.instance = v;

    if (this.instance) {
      // @ts-ignore
      L.gridLayer.googleMutant({ type: 'hybrid' }).addTo(v);
      this.instance.attributionControl.setPrefix('');
      this.instance.on('zoomend', this.handleZoom as L.LeafletEventHandlerFn);
      this.instance.on('pm:drawstart', this.handleDrawStart);
      this.instance.on('pm:drawend', this.handleDrawEnd);
      this.instance.on('pm:create', this.handleNewPolygon);
      this.instance.on('click', this.handleMapClick);
      this.instance.pm.setLang('ru');
    }
  };

  handleMapClick = event => {
    event.originalEvent.preventDefault();
    clickMapEvent(event.latlng);

    if (this.mapMode === MapMode.Creating) {
      this.incrementPointsOfUnfinishedContour();
    }
  };

  isGraphicVisible = true;

  handleZoom = () => {
    const zoom = this.instance.getZoom();
    const allPolygons: L.Polygon[] = [...this.polygons, ...this.editableLayers];

    if (zoom >= zoomGap) {
      if (zoom === zoomGap) {
        if (this._tooltipTextSize !== ETooltipSize.Small) {
          allPolygons.forEach(l => {
            const tooltip = l.getTooltip();
            if (tooltip) {
              // @ts-ignore
              (tooltip?._container as HTMLElement).style.fontSize = ETooltipSize.Small;
            }
          });

          this._tooltipTextSize = ETooltipSize.Small;
        }
      } else if (this._tooltipTextSize !== ETooltipSize.Normal) {
        allPolygons.forEach(l => {
          const tooltip = l.getTooltip();

          if (tooltip) {
            // @ts-ignore
            (tooltip?._container as HTMLElement).style.fontSize = ETooltipSize.Normal;
          }
        });

        this._tooltipTextSize = ETooltipSize.Normal;
      }
    }

    if (zoom <= zoomGap - 1 && this.isGraphicVisible) {
      this.isGraphicVisible = false;
      allPolygons.forEach(l => {
        l.closeTooltip();
      });
    }

    if (zoom >= zoomGap && !this.isGraphicVisible) {
      this.isGraphicVisible = true;

      // Задержка требуется для завершения анимации приближения
      setTimeout(() => {
        this.polygons.forEach(l => {
          l.openTooltip();
        });
        this.editableLayers.forEach(l => {
          l.openTooltip();
          l.pm.enable({ preventMarkerRemoval: true });
        });
      }, 200);
    }
  };

  setMode = (v: MapMode) => {
    this.mapMode = v;
  };

  instance: LMap | null = null;

  setCoordinates = (lat: number, lng: number) => {
    this.coordinates = {
      lat,
      lng,
    };
  };

  coordinates: GeoPoint = {
    lat: 0,
    lng: 0,
  };

  idToPolygon: Map<number, Polygon> = new Map();

  setLayout = (boundPoints: LatLngBoundsExpression, url: string) => {
    // @ts-ignore
    const layer = L.imageOverlay(url, boundPoints).addTo(this.instance).bringToFront();
    // @ts-ignore
    this.idToLayout.set(layer._leaflet_id, layer);

    // @ts-ignore
    return layer._leaflet_id;
  };

  getLayout = (id: number) => {
    if (!this.idToLayout.has(id)) {
      throw new Error(`there is no layout with ${id}`);
    }

    return this.idToLayout.get(id);
  };

  get layouts() {
    return Array.from(this.idToLayout.values());
  }

  clearLayouts = () => {
    console.log('this.layouts ', this.layouts);
    this.layouts.forEach(l => {
      l.remove();
    });

    this.idToLayout.clear();
  };

  setLabelToTheField = (
    labelFieldFill: LabelFieldFillEnum,
    options: AddPolygonOptionsType
  ): string => {
    switch (labelFieldFill) {
      case LabelFieldFillEnum.Area:
        return options.area;
      case LabelFieldFillEnum.Culture:
        return options.culture;
      case LabelFieldFillEnum.None:
        return options.none;
      default:
        return options.name;
    }
  };

  // Add polygon by coords
  setPolygon = (
    geometry: Array<Array<number>> | Array<Array<Array<number>>>,
    options?: AddPolygonOptionsType,
    labelFieldFill?: LabelFieldFillEnum
  ) => {
    const points = geometry[0][0][0]
      ? geometry.map(feature => feature.map(dots => [dots[1], dots[0]]))
      : [geometry.map(dots => [dots[1], dots[0]])];
    // @ts-ignore
    let tempPoly;

    const isImportMode: boolean = this.mapMode === MapMode.Import;

    if (
      !isImportMode &&
      options.renderOption === RenderPolygonOption.CultureZone &&
      (!options.fill || options.fill === 'NONE')
    ) {
      // @ts-ignore
      tempPoly = new L.Polygon(points, {
        fill: `url(https://thumbs.dreamstime.com/b/imitation-transparent-background-seamless-vector-illustration-69028332.jpg)` as any,
        fillOpacity: 0.5,
        color: '#FD7E09',
      });
      tempPoly.addTo(this.instance);
      this.idToLayout.set(tempPoly._leaflet_id, tempPoly);
      return tempPoly._leaflet_id;
    } else {
      // @ts-ignore
      tempPoly = new L.Polygon(points);
    }

    if (
      !isImportMode &&
      options.renderOption === RenderPolygonOption.CultureZone &&
      options.fill === 'EMPTY'
    ) {
      // @ts-ignore
      tempPoly = new L.Polygon(points, {
        color: '#ffffff',
        fillColor: '#ffffff',
        fillOpacity: 0,
      });
      tempPoly.addTo(this.instance);
      this.idToLayout.set(tempPoly._leaflet_id, tempPoly);
      return tempPoly._leaflet_id;
    }

    if (isImportMode && options.renderOption === RenderPolygonOption.Import) {
      tempPoly.setStyle({
        fillColor: '#F2F1F4',
        fillOpacity: 0.25,
        color: '#F2F1F4',
        colorOpacity: 0.7,
      });

      if (options.area || options.culture || options.none || options.name) {
        tempPoly
          .bindTooltip(this.setLabelToTheField(labelFieldFill, options), {
            permanent: true,
            direction: 'center',
            className: 'field-label',
          })
          .openTooltip();
      }
    } else if (isImportMode && options.renderOption === RenderPolygonOption.Error) {
      tempPoly.setStyle({
        fillColor: '#fff9e8',
        color: 'red',
      });

      if (options.area || options.culture || options.none || options.name) {
        tempPoly.bindTooltip(this.setLabelToTheField(labelFieldFill, options), {
          permanent: true,
          direction: 'center',
          className: 'field-label',
        });
      }
    } else if (options.renderOption === RenderPolygonOption.View) {
      tempPoly.setStyle({
        fillColor: '#fff9e8',
        color: '#FFD765',
      });
      if (options.area || options.culture || options.none || options.name) {
        tempPoly
          .bindTooltip(this.setLabelToTheField(labelFieldFill, options), {
            permanent: true,
            direction: 'center',
            className: 'field-label',
          })
          .openTooltip();
      }
    } else if (options.renderOption === RenderPolygonOption.CultureZone) {
      // Polygon with fill
      if (options.fill) {
        tempPoly.setStyle({
          fillColor: options.fill,
          fillOpacity: options.fill === 'NONE' ? 0 : 1,
          color: '#fd7e09',
        });
      }
    } else if (options.renderOption === RenderPolygonOption.CultureZoneBorder) {
      tempPoly.setStyle({
        fillOpacity: 0,
        strokeWidth: 1,
        color: '#FD7E09',
        interactive: false,
      });
    } else if (options.renderOption === RenderPolygonOption.CultureZoneHole) {
      tempPoly.setStyle({
        fillColor: 'blue',
        strokeOpacity: '0',
      });
    } else {
      // Side polygon
      tempPoly.setStyle({
        fillColor: '#fd7e09',
        fillOpacity: 0.1,
        strokeWidth: 1,
        color: '#FD7E09',
      });
    }
    if (tempPoly && this.instance && tempPoly.addTo) {
      tempPoly.addTo(this.instance);
    }

    // @ts-ignore
    if (options.editable) {
      // @ts-ignore
      this.idToEditableLayer.set(tempPoly._leaflet_id, tempPoly);
    } else {
      // @ts-ignore
      this.idToPolygon.set(tempPoly._leaflet_id, tempPoly);
    }
    // @ts-ignore
    return tempPoly._leaflet_id;
  };

  setMarker = coords => {
    const geomanIcon = new DivIcon({
      html: `<img src='${mapMarkerSvg}'/>`,
      className: '',
    });

    const newMarker = marker([parseFloat(coords.latitude), parseFloat(coords.longitude)], {
      pmIgnore: true,
    })
      .setIcon(geomanIcon)
      .addTo(this.instance);
  };

  removePolygon = (key: number) => {
    this.idToPolygon.delete(key);
  };

  get polygons() {
    return Array.from(this.idToPolygon.values());
  }

  isShapeFinished = false;

  cancelDrawing = () => {
    // @ts-ignore
    this.instance.pm.disableDraw();
  };

  changeCreatableFieldName = (id: number, name: string) => {
    const layer = this.idToEditableLayer.get(id);
    layer.unbindTooltip();
    layer
      .bindTooltip(name, {
        permanent: true,
        direction: 'center',
        className: 'field-label',
      })
      .openTooltip();
  };

  changeMode = () => {
    if (!this.instance) {
      return;
    }
    this.isShapeFinished = false;
    this.setMode(MapMode.Creating);
    this.instance.pm.enableDraw('Polygon', polygonOptions);

    this.instance.on('pm:drawstart', this.handleDrawStart);
    this.instance.on('pm:drawend', this.handleDrawEnd);
    this.instance.on('pm:create', this.handleNewPolygon);
    this.instance.on('click', this.handleMapClick);
  };

  startDrawCultureWizard = () => {
    this.instance.pm.enableDraw('Polygon', cultureWizardPolygonOptions);
    this.instance.off('pm:create', this.handleNewPolygon);
    this.instance.on('pm:create', this.handleCultureWizardNewPolygon);
  };

  handleCultureWizardNewPolygon = event => {
    const { layer } = event;
    const geoJson = layer.toGeoJSON();
    layer.remove();
    createCultureZoneEvent(geoJson.geometry.coordinates[0]);
  };

  handleDrawStart = event => {
    if (!this.isShapeFinished) {
      return;
    }
    this.isShapeFinished = false;
    this.currentLayer = event.target;
  };

  handleDrawEnd = () => {
    if (this.isShapeFinished || this.isDragStarted) {
      return;
    }
    this.isShapeFinished = true;
    if (this.mapMode === MapMode.Listing) {
      return;
    }
    console.log('DRAW END');
    this.toggleDrawEndTrigger();
    setTimeout(() => this.instance.pm.enableDraw('Polygon', polygonOptions), 50);
  };

  idToEditableLayer: Map<number, any> = new Map();

  get editableLayers() {
    return Array.from(this.idToEditableLayer.values());
  }

  showErrorInPolygon = (layerId: number) => {
    const layer: Layer = this.idToEditableLayer.has(layerId)
      ? this.idToEditableLayer.get(layerId)
      : this.idToPolygon.get(layerId);

    if (!layer) {
      return;
    }

    // @ts-ignore
    layer.setStyle({ color: 'red' });
  };

  isDragStarted = false;

  lockMarkers = (): void => {
    this.tempMakersSet.forEach(item => {
      item.style.pointerEvents = PointerStatus.None;
    });
  };

  unlockMarkers = (): void => {
    this.tempMakersSet.forEach(item => {
      item.style.pointerEvents = PointerStatus.Auto;
    });
  };

  addMarkersToSet = markersCollection => {
    markersCollection.forEach(item => this.tempMakersSet.add(item.pm._layer._icon));
  };

  handleNewPolygon = event => {
    const { layer } = event;
    const geoJson = layer.toGeoJSON();

    setTimeout(() => this.addMarkersToSet(event.marker.pm.markerCache), 50);

    layer.on('pm:markerdragstart', this.handleLayerMarkerDragStart);
    layer.on('pm:markerdragend', this.handleLayerMarkerDragEnd);
    layer.on('pm:markerdrag', throttle(this.handleLayerMarkerDrag, 300));
    layer.on('pm:vertexclick', throttle(this.selectVertex, 300));
    layer.on('pm:vertexadded', e => {
      this.selectVertex(e, true);
    });
    // layer.on('pm:edit', this.handleEditPolygon);
    const layerId = layer._leaflet_id;
    this.idToEditableLayer.set(layerId, layer);
    layer.pm.enable({ preventMarkerRemoval: true });

    layer
      .bindTooltip('', {
        permanent: true,
        direction: 'center',
        className: 'field-label',
      })
      .openTooltip();
    layer.setStyle({ fillColor: '#fff9e8', color: '#FFD765' });
    this.checkCrossingOfFields();
    console.log('coords 0', geoJson.geometry.coordinates[0]);
    const coords = geoJson.geometry.coordinates[0].map(position => [
      position[0].toFixed(19),
      position[1].toFixed(19),
    ]);
    console.log('coords 1', coords);
    createPolygonEvent(coords, layerId);
  };

  reRenderLable = (layer: any) => {
    const label = layer.getTooltip()?.getContent();
    layer.unbindTooltip();
    layer
      .bindTooltip(label, {
        permanent: true,
        direction: 'center',
        className: 'field-label',
      })
      .openTooltip();
  };

  handleLayerMarkerDragEnd = event => {
    this.isDragStarted = false;
    this.reRenderLable(event.target);
    this.handleEditPolygon(event);
    setTimeout(() => {
      this.instance.pm.enableDraw('Polygon', polygonOptions);
      this.clearPointsOfUnfinishedContour();
      this.checkCrossingOfFields();
    }, 100);
  };

  handleLayerMarkerDrag: PM.MarkerDragEventHandler = event => {
    this._currentPointId = event.markerEvent.target._leaflet_id;

    const icon = event.markerEvent.target._icon;
    this.vertexFocus(icon);

    this.currentLayer = event.layer;
    this._currentPointPosition = event?.markerEvent?.latlng;

    const pointIndexInLayerCords = (event.indexPath as unknown) as [number, number];
    this._currentPointLayerIndex = pointIndexInLayerCords;
  };

  selectVertex = (
    event: {
      layer: L.Layer;
      indexPath: number;
      markerEvent: any;
      shape: string;
    },
    isCreation: boolean
  ) => {
    // @ts-ignore
    const _marker = event?.markerEvent?.target || event?.marker;

    if (!_marker) return;

    this._currentPointId = _marker._leaflet_id;

    const icon = _marker._icon;
    this.vertexFocus(icon);

    this.currentLayer = event.layer;
    this._currentPointPosition = _marker?.latlng || _marker?._latlng;

    const pointIndexInLayerCords = (event.indexPath as unknown) as [number, number];
    this._currentPointLayerIndex = pointIndexInLayerCords;
  };

  vertexFocus = icon => {
    this._currentFocusMarkerElement?.classList?.remove(FOCUSED_MARKER_CLASS);

    this._currentFocusMarkerElement = icon;
    this._currentFocusMarkerElement?.classList?.add(FOCUSED_MARKER_CLASS);
  };

  clearVertexCoordinates = () => {
    this._currentPointLayerIndex = null;
    this._currentPointPosition = null;
  };

  handleLayerMarkerDragStart = () => {
    console.log('handleLayerMarkerDragStart');
    this.isDragStarted = true;
    this.instance.pm.disableDraw();
  };

  checkCrossingOfFields = () => {
    console.log('CHECK_OF_CROSSING_FIELDS');

    this.clearCollectionOfPrevIncorrectLayerId();
    this.clearIncorrectLayerId();
    this.clearPointsOfUnfinishedContour();

    const allLayersInSession = [...this.editableLayerList, ...this.layerList];

    const newCollectionOfIncorrectLayerId: Set<number> = new Set<number>();

    allLayersInSession.forEach(layerOne => {
      allLayersInSession.forEach(layerTwo => {
        const featureOne = layerOne.toGeoJSON();
        const areaInHectares = area(featureOne) / 10000;

        if (areaInHectares > 1000) {
          newCollectionOfIncorrectLayerId.add(layerOne._leaflet_id);
        }

        const featureTwo = layerTwo.toGeoJSON();
        const isExactlyThisLayer = layerOne._leaflet_id === layerTwo._leaflet_id;

        if (isExactlyThisLayer) {
          return;
        }

        const haveEqualGeometry = _.isEqual(featureOne?.geometry, featureTwo?.geometry);

        if (haveEqualGeometry) {
          newCollectionOfIncorrectLayerId.add(layerOne._leaflet_id);
          newCollectionOfIncorrectLayerId.add(layerTwo._leaflet_id);
        }

        if (intersect(featureOne, featureTwo)) {
          newCollectionOfIncorrectLayerId.add(layerOne._leaflet_id);
          newCollectionOfIncorrectLayerId.add(layerTwo._leaflet_id);
        }
      });
    });

    const hasIncorrectLayerId = Boolean(newCollectionOfIncorrectLayerId.values);

    if (hasIncorrectLayerId) {
      this.setIncorrectLayersId(newCollectionOfIncorrectLayerId);
      this.setCollectionOfTempIncorrectLayerId(newCollectionOfIncorrectLayerId);
    }
  };

  handleEditPolygon = event => {
    const { target } = event;
    this.addMarkersToSet(target.pm.markerCache);
    this.reRenderLable(event.target);
    const leafletId = target._leaflet_id;
    const geoJson = target.toGeoJSON();
    this.isShapeFinished = false;
    this.checkCrossingOfFields();
    editPolygon(geoJson.geometry, leafletId);
    setTimeout(() => this.checkCrossingOfFields(), 100);
  };

  deletePolygon = (id: number) => {
    const layer = this.idToEditableLayer.get(id) || this.idToPolygon.get(id);
    if (!layer) {
      return;
    }
    layer.remove();
    this.idToPolygon.delete(id);
    this.idToEditableLayer.delete(id);
  };

  removeEditablePolygon = () => {
    this.currentLayer.remove();
    this.currentLayer = null;
  };

  clearPolygons = () => {
    console.log('clearPolygons shit', this.polygons);
    this.polygons.forEach(i => {
      i.unbindTooltip();
      i.remove();
    });
    this.idToPolygon.clear();
  };

  clearEditable = () => {
    this.editableLayers.forEach(i => {
      i.unbindTooltip();
      i.remove();
    });
    this.idToEditableLayer.clear();
  };

  clearFocusMarker = () => {
    this._currentFocusMarkerElement?.classList?.remove(FOCUSED_MARKER_CLASS);

    this.clearVertexCoordinates();

    this._currentFocusMarkerElement = null;
  };

  clearAll = () => {
    this.clearEditable();
    this.clearPolygons();
    this.clearLayouts();
    this.unlockMarkers();
    this.clearEditableLayerGeometry();
    this.clearIdToUnchangedLayer();
    this.clearIdToImportedFieldLayer();
    this.clearCollectionOfPrevIncorrectLayerId();
    this.clearCollectionOfTempIncorrectLayerId();
    this.clearFocusMarker();
    console.log('CLEAR ALL');
  };

  unbindCreatingEvents = () => {
    this.instance.off('pm:drawstart', this.handleDrawStart);
    this.instance.off('pm:drawend', this.handleDrawEnd);
    // disables cultural zone drawing
    this.instance.off('pm:create', this.handleCultureWizardNewPolygon);
  };

  changeLayerToEditable = (id: number) => {
    const layer = this.idToEditableLayer.get(id);
    // @ts-ignore
    layer.on('pm:edit', this.handleEditPolygon);
    layer.on('pm:markerdragend', () => setTimeout(() => this.checkCrossingOfFields(), 100));
    layer.on('pm:markerdrag', throttle(this.handleLayerMarkerDrag, 300));
    layer.on('pm:vertexclick', throttle(this.selectVertex, 300));
    layer.on('pm:vertexadded', e => {
      this.selectVertex(e, true);
    });
    // @ts-ignore
    layer.pm.enable({ preventMarkerRemoval: true });
    this.centerMapToPoint(id);
  };

  drawCultureZones = (cultureZone: CultureZone): number => {
    return this.setPolygon(cultureZone.geometry.coordinates, {
      renderOption: RenderPolygonOption.CultureZone,
      fill: cultureZone?.culture?.attrs?.assistanceColorLegend,
    });
  };
}

export type AddPolygonOptionsType = {
  name?: string;
  area?: string;
  culture?: string;
  none?: string;
  renderOption: RenderPolygonOption;
  editable?: boolean;
  fill?: string;
};

export default MapStore;
