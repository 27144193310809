import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useModal, useSidebar } from '@farmlink/farmik-ui';

import { useTaskPageGlobalControl } from '../../hooks';
import { SpinnerLoader } from '../../../../../shared/components/loaders';
import { editTaskMapPointModal } from '../../modals/editTaskMapPointModal/editTaskMapPointModal';
import { cancellationModalConfig } from '../../../operations/modals/CancellationModal';
import deletionModalConfig from '../../../operations/modals/DeletionModal/modal.config';
import { laveBeforeSaveModalConfig } from '../../modals/leaveBeforeSaveModal';
import { noChecklistFoundModalConfig } from '../../modals/noChecklistFoundModal';

const TaskMiddleware: FC = () => {
  const { isLoading } = useTaskPageGlobalControl('view');
  const { registerModalList } = useModal();
  const { setSidebarVisibility } = useSidebar();

  useEffect(() => {
    setSidebarVisibility(false);

    registerModalList(
      [
        editTaskMapPointModal,
        cancellationModalConfig,
        deletionModalConfig,
        laveBeforeSaveModalConfig,
        noChecklistFoundModalConfig,
      ],
      'taskModals'
    );

    return () => {
      setSidebarVisibility(false);
    };
  }, []);

  if (isLoading) {
    return (
      <div style={{ zIndex: 10000 }}>
        <SpinnerLoader needToHideContent={true} />
      </div>
    );
  }

  return <Outlet />;
};

export default TaskMiddleware;
