import styled, { css, keyframes } from 'styled-components';

import { Colors } from '../../../shared/constans/colors';
import {
  Label as ToolLabel,
  Wrapper as ToolWrapper,
} from '../../../shared/components/ToolTip/style';
import { ESidebarWidth } from '../../stores/ui.store';

type SidebarProps = {
  isShort: boolean;
};

export const fadeOut = css`
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
`;

export const fadeIn = css`
  0% {
    opacity: 0;
  }

  1% {
    display: inline-block;
    opacity: 0;
  }

  100% {
    display: inline-block;
    opacity: 0;
  }
`;

export const Wrapper = styled.div<SidebarProps>`
  background: ${Colors.lightGray};
  height: 100%;
  display: flex;
  flex-direction: column;
  width: ${({ isShort }) => (isShort ? ESidebarWidth.SHORT : ESidebarWidth.LARGE)};
  transition: all 0.3s ease-in-out;
  flex: 1 0 auto;
  * {
    transition: all 0.3s ease-in-out;
  }
  ${({ isShort }) =>
    isShort &&
    `
    ${TitleLogo} {
      opacity: 0;
      animation: ${fadeOut} 0.7s linear;
    }
    ${Content} {
      padding-left: 15px;
      padding-right: 15px;
    }
    ${Footer} {
      padding-left: 15px;
    }
    ${Label} {
      opacity: 0;
      animation: ${fadeOut} 0.7s linear;
    }
  `}
`;

export const Header = styled.div`
  height: 60px;
  border-bottom: 1px solid ${Colors.grayBorder};
  padding-left: 16px;
  display: flex;
  //align-items: center;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
`;

export const MenuWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const accordeonWrapperShowAnimation = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`;

export const AccordeonsWrapper = styled.div<{ isDisabled?: boolean }>`
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  & > * {
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  }
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  width: 198px;
  margin-top: 20px;
  padding-left: 12px;
  padding-right: 12px;
  animation: ${accordeonWrapperShowAnimation} 500ms linear;

  & > div:first-child {
    margin-bottom: 12px;
  }
`;

export const MenuShortItem = styled.div<{ isActive: boolean; isDisabled?: boolean }>`
  //width: 28px;
  height: 32px;
  margin-top: 20px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  border-radius: 6px;

  &:hover {
    background: ${({ isDisabled }) =>
      isDisabled ? 'transparent' : Colors.backgroundSecondaryGray};
  }
  svg {
    width: 20px;
    height: 20px;
  }
  * {
    ${({ isActive, isDisabled }) =>
      isDisabled ? `fill: ${Colors.darkGray}` : isActive && `fill: ${Colors.green}`}
  }
`;

export const OrganizationSmallBlock = styled.div<{ isDisabled?: boolean }>`
  width: 32px;
  height: 32px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  // background: ${Colors.white};
  // border: 1px solid ${Colors.grayBorder};
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
  }
  &:hover {
    border-color: ${Colors.darkGray};
  }

  svg {
    width: 14px;
  }
`;

export const MenuItem = styled.div<{ isActive: boolean; isDisabled?: boolean }>`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  padding-top: 7.76px;
  padding-bottom: 7.76px;
  padding-left: 16px;

  ${MenuShortItem} {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: ${({ isDisabled }) =>
      isDisabled ? 'transparent' : Colors.backgroundSecondaryGray};
    border-radius: 6px;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 0 12px;
    color: ${({ isActive, isDisabled }) =>
      isDisabled ? Colors.darkGray : isActive ? Colors.green : Colors.black};
  }

  ${MenuShortItem} {
    margin-bottom: 0;
  }
`;

export const TipTail = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  left: -5px;
  top: 15px;
  border-right: 5px solid ${Colors.white};
`;
export const ShortLogo = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 5px;
`;

export const TitleLogo = styled.img`
  width: 110px;
  margin-left: 8px;
`;

export const Footer = styled.div`
  height: 106px;
  width: 100%;
  padding-left: 16px;
  display: flex;
  padding-top: 2px;
  border-top: 1px solid ${Colors.grayBorder};
  //margin-top: 100%;
  align-items: center;
`;

export const NameTipWrapper = styled.div`
  position: absolute;
  left: 193px;
  z-index: 1007;
`;

export const TipContent = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background: ${Colors.white};
  filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.1));
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    color: ${Colors.black};
  }
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${Colors.white};
  border: 0.5px solid ${Colors.grayBorder};
  flex: 0 0 32px;
  cursor: pointer;
`;

export const Label = styled.p<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin: 0;
  margin-left: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.black};
  animation: ${fadeIn} 0.7s linear;
  white-space: nowrap;
  cursor: pointer;
  width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  right: -150px;
`;

export const StyledSidebarContextMenu = styled.div`
  position: relative;
  // top: -50px;
  // width: 0;
  // height: 0;
  z-index: 10000;
`;

export const LogoutToolLabel = styled(ToolLabel)`
  color: #f29993;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const MenuOverlay = styled.div`
  position: absolute;
  // width: 100%;
  // height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
`;

export const ContextMenuWrapper = styled.div<{ isOpen?: boolean }>`
  z-index: 10000;
  width: 200px;
  min-width: 200px;
  border-radius: 16px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 6px 8px rgba(0, 0, 0, 0.05),
    0px 2px 24px rgba(0, 0, 0, 0.1);
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: unset;
  & * {
    transition: unset;
  }
  margin-top: -51px;
  margin-left: 16px;
`;

export const Arrow = styled.div`
  position: absolute;
  //background: ${Colors.white};
  z-index: 998;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 10px solid ${Colors.white};
  margin-left: 6px;
  margin-left: -10px;
  margin-top: -29px;
`;

export const SupportLinksBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 23px;
`;

export const SupportLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  & > svg {
    width: 20px;
    height: 20px;
  }
  &:nth-child(1) > svg {
    margin-right: 12px;
  }
  &:nth-child(2) > svg {
    margin-right: 10px;
  }
`;

export const SupportLinkLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #151f32;
`;

export const MenuIcon = styled.div<{ $iconSvg: string }>`
  width: 20px;
  height: 20px;
  background-image: url(${({ $iconSvg }) => $iconSvg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
