import moment from 'moment';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { TaskStore } from '../../stores';
import { Operation } from '../../../../../../../api/models/operations/operation.model';
import { ISelectOption } from '../../../../../../../types/selectOption';

interface IDateRangeOptions {
  minDate?: Date;
  maxDate?: Date;
}

@provide.transient()
class TaskDefaultValuesService {
  @lazyInject(TaskStore)
  protected taskStore: TaskStore;

  get fieldDefaultValueList(): ISelectOption[] {
    if (this.taskStore.mode === 'create-multiple') {
      const optionList = this.taskStore.taskCreate?.taskItems?.map?.(item =>
        this.taskStore.getFieldOption(item.cultureZoneId)
      );

      if (optionList) {
        return optionList;
      }
    }

    return [];
  }

  get planStartDate(): string {
    switch (this.taskStore.mode) {
      case 'view':
        return this.getPlanStartDateForView();
      case 'create-one':
      case 'create-multiple':
        return this.getPlanStartDateForCreate();
      case 'edit':
        return this.getPlanStartDateForEdit();
      default:
    }
  }

  get planEndDate(): string {
    switch (this.taskStore.mode) {
      case 'view':
        return this.getPlanEndDateForView();
      case 'create-one':
      case 'create-multiple':
        return this.getPlanEndDateForCreate();
      case 'edit':
        return this.getPlanEndDateForEdit();
      default:
    }
  }

  get dateRangeOptions(): IDateRangeOptions {
    switch (this.taskStore.mode) {
      case 'view':
        return this.getPlugDateRangeOptions();
      case 'create-one':
      case 'create-multiple':
        return this.getDateRangeOptionsBySelectedOperation();
      case 'edit':
        return this.getDateRangeOptionsByTask();
      default:
    }
  }

  getPresetPlanStartDate = (operation: Operation): string => {
    const currentDate = moment().format('YYYY-MM-DD');

    if (!operation) {
      return currentDate;
    }

    const minDate = moment(operation.startDate).hours(0).minutes(0).seconds(0).milliseconds(0);
    const maxDate = moment(operation.endDate).hours(23).minutes(59).seconds(59);

    if (moment().isBetween(minDate, maxDate)) {
      return currentDate;
    } else if (moment().isBefore(minDate) || moment().isSame(minDate)) {
      return minDate.format('YYYY-MM-DD');
    } else if (moment().isAfter(maxDate) || moment().isSame(maxDate)) {
      return maxDate.format('YYYY-MM-DD');
    }
  };

  protected getPlanStartDateForView = (): string => {
    if (this.taskStore.selectedTask) {
      return moment(this.taskStore.selectedTask.planStartDate).format('DD.MM.YYYY');
    }
  };

  protected getPlanStartDateForCreate(): string {
    if (this.taskStore.taskCreate?.planStartDate) {
      return moment(this.taskStore.taskCreate.planStartDate).format('DD.MM.YYYY');
    }
  }

  protected getPlanStartDateForEdit(): string {
    if (this.taskStore.taskUpdate?.planStartDate) {
      return moment(this.taskStore.taskUpdate.planStartDate).format('DD.MM.YYYY');
    } else if (this.taskStore.selectedTask) {
      return moment(this.taskStore.selectedTask.planStartDate).format('DD.MM.YYYY');
    }
  }

  protected getPlanEndDateForView = (): string => {
    if (!this.taskStore.selectedTask) {
      return moment().format('DD.MM.YYYY');
    }

    return moment(this.taskStore.selectedTask.planEndDate).format('DD.MM.YYYY');
  };

  protected getPlanEndDateForCreate(): string {
    if (this.taskStore.taskCreate?.planEndDate) {
      return moment(this.taskStore.taskCreate.planEndDate).format('DD.MM.YYYY');
    }
  }

  protected getPlanEndDateForEdit(): string {
    if (this.taskStore.taskUpdate?.planEndDate) {
      return moment(this.taskStore.taskUpdate.planEndDate).format('DD.MM.YYYY');
    } else if (this.taskStore.selectedTask) {
      return moment(this.taskStore.selectedTask.planEndDate).format('DD.MM.YYYY');
    }
  }

  protected getPlugDateRangeOptions = (): IDateRangeOptions => ({
    minDate: new Date(),
    maxDate: new Date(),
  });

  protected getDateRangeOptionsBySelectedOperation = (): IDateRangeOptions => {
    const plug: IDateRangeOptions = {
      minDate: new Date(),
      maxDate: new Date(),
    };

    if (!this.taskStore.taskCreate) {
      return plug;
    }

    const { operationId } = this.taskStore.taskCreate;
    const operation = this.taskStore.getOperationOption(operationId)?.initialModel;

    if (!operation) {
      return plug;
    }

    const minDate = moment(operation.startDate).toDate();
    const maxDate = moment(operation.endDate).toDate();

    return {
      minDate,
      maxDate,
    };
  };

  protected getDateRangeOptionsByTask = (): IDateRangeOptions => {
    if (!this.taskStore.selectedTask || !this.taskStore.taskUpdate) {
      return this.getPlugDateRangeOptions();
    }

    const { operationInfo } = this.taskStore.selectedTask;

    const minDate = moment(operationInfo.startDate).toDate();
    const maxDate = moment(operationInfo.endDate).toDate();

    return {
      minDate,
      maxDate,
    };
  };
}

export default TaskDefaultValuesService;
