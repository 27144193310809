import styled from 'styled-components';

import { Colors } from '../../../shared/constans/colors';
import { BorderRadius, FontSizes } from '../../../shared/constans/sizes';

export const CulturesListWrapper = styled.div`
  display: flex;
  background: ${Colors.white};
  border-radius: ${BorderRadius.default};
  height: 48px;
  padding: 0 32px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  z-index: 1001;
  box-shadow: 0px 2px 24px rgb(0 0 0 / 10%);
  max-width: calc(100% - 32px);
  overflow: hidden;
`;

export const CultureWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
  }
`;

export const CultureColor = styled.div`
  width: ${BorderRadius.large};
  height: ${BorderRadius.large};
  border-radius: ${BorderRadius.large};
  background: ${props => props.color};
  margin-right: 8px;
`;

export const CultureLabel = styled.div`
  ${FontSizes.default};
  color: ${Colors.darkGray};
  white-space: nowrap;
`;
