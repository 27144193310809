import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../utils/IoC';
import { IndexModel } from '../../../../../../../../../../api/models/indices.model';
import { CultureFillEnum } from '../../../../../../../../../dashboard/constants/culture.fill.enum';

@provide.singleton()
class IndicesStore {
  private _fieldIdToIndicesList: Map<string, IndexModel[]> = new Map();
  private _fieldIdToIndicesYearList: Map<string, number[]> = new Map();
  private _currentFillMode: CultureFillEnum = CultureFillEnum.None;
  private _currentYear: number | null = null;
  private _selectedIndex: IndexModel | null = null;
  private _selectedIndexIndex: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get currentFillMode() {
    return this._currentFillMode;
  }

  get currentYear() {
    return this._currentYear;
  }

  get selectedIndex() {
    return this._selectedIndex;
  }

  get selectedIndexIndex() {
    return this._selectedIndexIndex;
  }

  getIndicesListByFieldId = (fieldId: string) => {
    return this._fieldIdToIndicesList.get(fieldId) || [];
  };

  getIndicesYearListByFieldId = (fieldId: string) => {
    return this._fieldIdToIndicesYearList.get(fieldId) || [];
  };

  setCurrentFillMode = (mode: CultureFillEnum) => {
    this._currentFillMode = mode;
  };

  setCurrentYear = (year: number) => {
    this._currentYear = year;
  };

  setIndices = (fieldId: string, indicesList: IndexModel[]) => {
    this._fieldIdToIndicesList.set(fieldId, indicesList);
  };

  setYearIndices = (fieldId: string, yearList: number[]) => {
    this._fieldIdToIndicesYearList.set(fieldId, yearList);
  };

  setSelectedIndex = (index: IndexModel, arrayIndex?: number) => {
    this._selectedIndex = index;
    this._selectedIndexIndex = arrayIndex;
  };

  clearFillMode = () => {
    this._currentFillMode = CultureFillEnum.None;
  };

  clearYear = () => {
    this._currentYear = null;
  };

  clearIndicesList = () => {
    this._fieldIdToIndicesList = new Map();
  };

  clearSelectedIndex = () => {
    this._selectedIndex = null;
    this._selectedIndexIndex = null;
  };

  clearStore = () => {
    this.clearIndicesList();
    this.clearFillMode();
    this.clearYear();
    this.clearSelectedIndex();
  };
}

export default IndicesStore;
