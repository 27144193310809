import React, { FC } from 'react';
import { Button } from '@farmlink/farmik-ui';

import { ReactComponent as SpinnerSvg } from './assets/Spiner.svg';
import { SpinnerWrapper, ButtonWrapper } from './style';

type ButtonLoaderProps = {
  isLoading: boolean;
  onClick: () => void;
  disabled: boolean;
};

export const ButtonLoader: FC<ButtonLoaderProps> = ({ children, isLoading, onClick, disabled }) => {
  return (
    <ButtonWrapper isLoading={isLoading}>
      <Button type={'button'} color={'primary'} onClick={!isLoading && onClick} disabled={disabled}>
        {isLoading ? (
          <SpinnerWrapper>
            <SpinnerSvg />
          </SpinnerWrapper>
        ) : (
          children
        )}
      </Button>
    </ButtonWrapper>
  );
};
