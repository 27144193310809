import { FC, memo } from 'react';

import { DropdownMultiselectValue } from './components';
import Styled from './DropdownMultiselect.styles';
import { TDropdownMultiselectProps } from './DropdownMultiselect.types';

const DropdownMultiselect: FC<TDropdownMultiselectProps> = ({
  selectValueList,
  onCloseClick,
  multiselectType,
  aggregationHeader,
  isIncludeSelectAllOption,
  isBlocked,
}) => {
  if (multiselectType === 'counter') {
    return (
      <Styled.Wrapper>
        <Styled.Title>{`${aggregationHeader} ${
          selectValueList.length - Number(isIncludeSelectAllOption)
        }`}</Styled.Title>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper>
      {selectValueList.map(selectValue => (
        <DropdownMultiselectValue
          key={selectValue.value}
          selectValue={selectValue}
          onCloseClick={onCloseClick}
          isBlocked={isBlocked}
        />
      ))}
    </Styled.Wrapper>
  );
};

DropdownMultiselect.displayName = 'DropdownMultiselect';

export default memo(DropdownMultiselect);
