import { isNumber } from 'lodash';

import ClearDay from '../static/condition-icons/clear-day.svg';
import ClearNight from '../static/condition-icons/clear-night.svg';
import FogDay from '../static/condition-icons/fog-day.svg';
import FogNight from '../static/condition-icons/fog-night.svg';
import HeavyRainDay from '../static/condition-icons/heavy-rain-day.svg';
import HeavyRainNight from '../static/condition-icons/heavy-rain-night.svg';
import LessCloudyDay from '../static/condition-icons/less-cloudy-day.svg';
import LessCloudyNight from '../static/condition-icons/less-cloudy-night.svg';
import MainlyCloudyDay from '../static/condition-icons/mainly-cloudy-day.svg';
import MainlyCloudyNight from '../static/condition-icons/mainly-cloudy-night.svg';
import RainDay from '../static/condition-icons/rain-day.svg';
import RainNight from '../static/condition-icons/rain-night.svg';
import RainAndSnowDay from '../static/condition-icons/rain-and-snow-day.svg';
import RainAndSnowNight from '../static/condition-icons/rain-and-snow-night.svg';
import SnowDay from '../static/condition-icons/snow-day.svg';
import SnowNight from '../static/condition-icons/snow-night.svg';
import StormDay from '../static/condition-icons/storm-day.svg';
import StormNight from '../static/condition-icons/storm-night.svg';

export function WeatherConditionToIcon(condition: string | [string, string], currentHour?: number) {
  const variants = {
    clear: [ClearDay, ClearNight],
    'partly-cloud': [LessCloudyDay, LessCloudyNight],
    cloudy: [LessCloudyDay, LessCloudyNight],
    overcast: [MainlyCloudyDay, MainlyCloudyNight],
    drizzle: [FogDay, FogNight],
    'light-rain': [RainDay, RainNight],
    rain: [RainDay, RainNight],
    'moderate-rain': [RainDay, RainNight],
    'heavy-rain': [HeavyRainDay, HeavyRainNight],
    'continuous-heavy-rain': [HeavyRainDay, HeavyRainNight],
    showers: [HeavyRainDay, HeavyRainNight],
    hail: [HeavyRainDay, HeavyRainNight],
    thunderstorm: [StormDay, StormNight],
    'thunderstorm-with-rain': [StormDay, StormNight],
    'thunderstorm-with-hail': [StormDay, StormNight],
    'wet-snow': [RainAndSnowDay, RainAndSnowNight],
    'light-snow': [SnowDay, SnowNight],
    snow: [SnowDay, SnowNight],
    'snow-showers': [SnowDay, SnowNight],
  };

  // @ts-ignore
  const icons = variants[condition];

  if (!icons) {
    return ClearDay;
  }

  let hours = 0;

  if(isNumber(currentHour)) hours = currentHour;  
  else hours = new Date(Date.now()).getHours();

  if (hours >= 18 || hours <= 5) {
    return icons[1];
  } else {
    return icons[0];
  }
}
