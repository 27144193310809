import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  email?: string;
  // operType: 'PHONE_VERIFICATION' | 'authorization';
  deviceType?: null;
  countryCode: string;
  phoneNumber: string;
  externalKey?: string;
};

type TypeResponse = {
  operId: string;
};

export const userSendSmsCode: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: `/api/da-profile/users/phoneConfirm/sendCode`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
