import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Switcher } from '@farmlink/farmik-ui';

import { TChecklistsBooleanAttrToDraw as TBooleanAttrToDraw } from '../../../../models';
import { ChecklistsAttr as Attribute } from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { ChecklistsStore } from '../../../../mobx/stores';
import { EChecklistMode } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import { useChecklistsAttrErrorList } from '../../../../hooks';

import Styled from './ChecklistsBooleanAttr.styles';

interface IProps {
  attrToDraw: TBooleanAttrToDraw;
}

const ChecklistsBooleanAttr: FC<IProps> = ({
  attrToDraw: { id, groupId, initialModel, value, validationScheme, dependentFileAttrId },
}) => {
  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);

  const dependentFileAttr = checklistsStore.getAttrToDraw<EAttrType.FileLink>(
    groupId,
    dependentFileAttrId
  );

  const visibilityResult = initialModel.visibility
    ? checklistsController.calculateAttrVisibility(groupId, id)
    : { value: true };

  useEffect(() => {
    checklistsController.toggleAttrVisibility(groupId, id, visibilityResult.value as boolean);

    if (!dependentFileAttr) return;

    checklistsController.toggleAttrVisibility(
      groupId,
      dependentFileAttrId,
      visibilityResult.value as boolean
    );
  }, [JSON.stringify(visibilityResult)]);

  const errorList = useChecklistsAttrErrorList(
    validationScheme,
    dependentFileAttr?.validationScheme
  );

  const handleChange = useCallback((newValue: boolean): void => {
    checklistsController.changeAttrValue(EAttrType.Boolean, groupId, {
      ...value,
      booleanValue: newValue,
    });
  }, []);

  return (
    <>
      {visibilityResult.value ? (
        <Attribute width={initialModel.position.width} isNewLine={initialModel.position.newLine}>
          <Styled.Wrapper $isActive={value.booleanValue}>
            <Styled.Label>{initialModel.attribute?.name}</Styled.Label>

            <Switcher
              value={value.booleanValue}
              onChange={handleChange}
              isBlocked={checklistsStore.mode === EChecklistMode.View}
            />
          </Styled.Wrapper>

          <Styled.Container>
            {dependentFileAttrId ? (
              <FileAttrContainer groupId={groupId} attrId={dependentFileAttrId} />
            ) : null}

            <InputFieldError error={{ errorList }} />
          </Styled.Container>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsBooleanAttr.displayName = 'ChecklistsBooleanAttr';

export default observer(ChecklistsBooleanAttr);
