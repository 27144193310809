import {
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  NotificatorProps,
} from '@farmlink/farmik-ui';

import { ETaskStatus } from '../../../../../../../../../api/models/as-fields/task/task.model';

const getAvailableStatusChange = (
  currentStatus: ETaskStatus
): {
  status: ETaskStatus;
  buttonText: string;
  notification?: NotificatorProps;
  onChangeHandler?: (status?: ETaskStatus) => void;
} => {
  switch (currentStatus) {
    case ETaskStatus.New:
      return { status: ETaskStatus.InWork, buttonText: 'Взять в работу' };
    case ETaskStatus.InWork:
      return { status: ETaskStatus.Completed, buttonText: 'Завершить' };
    case ETaskStatus.Completed:
      return {
        status: ETaskStatus.InWork,
        buttonText: 'Вернуть в работу',

        notification: {
          message: 'Успешно возвращена в работу',
          style: {
            type: ENotificationType.Success,
            height: ENotificationHeight.BIG,
            placement: 'top-center',
            autoClose: 10000,
            theme: ENotificatorTheme.Dark,
          },
        },
      };
    case ETaskStatus.Canceled:
      return {
        status: ETaskStatus.InWork,
        buttonText: 'Вернуть в работу',

        notification: {
          message: 'Успешно возвращена в работу',
          style: {
            type: ENotificationType.Success,
            height: ENotificationHeight.BIG,
            placement: 'top-center',
            autoClose: 10000,
            theme: ENotificatorTheme.Dark,
          },
        },
      };

    default:
      return null;
  }
};

export default getAvailableStatusChange;
