import { observer } from 'mobx-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { AutoTooltip, Chip, Scrollbar, useShowTooltip, useSidebar } from '@farmlink/farmik-ui';
import moment from 'moment/moment';

import { TableElementDrawerBuilder } from '../../../../../shared/components/TableElementDrawerBuilder';
import {
  CommentBlock,
  CommentLabel,
  Content,
  DetailsButton,
  Footer,
  Header,
  Info,
  InfoBlock,
  Label,
  OperationInfoWrapper,
  OperationTypeName,
  StyledWrapper,
  SvgImg,
  TaskStatusBoldText,
  TaskStatusNormalText,
  TooltipContentRow,
  TooltipContentWrapper,
  TooltipDate,
  TooltipText,
  TooltipWrapper,
  TopBlock,
} from '../../../operations/components/TaskView/style';
import { ETaskStatus, ITask } from '../../../../../../api/models/as-fields/task/task.model';
import { useStore } from '../../../../../shared/utils/IoC';
import {
  ECheckListInstanceType,
  IGetChecklistInstanceByTaskId as IInstance,
} from '../../../../../../api/models/checklist/instance/checklist.instance.model';
import { TaskStatusBlock } from '../../../operations/components/TaskView/components/TaskStatusBlock';
import cultureSvg from '../../../operations/components/TaskView/assets/cultureSvg.svg';
import fieldSvg from '../../../operations/components/TaskView/assets/fieldSvg.svg';
import userSvg from '../../../operations/components/TaskView/assets/userSvg.svg';
import { TextExpand } from '../../../operations/components/TaskView/components/TextExpand';
import { ViewingPoints } from '../../../operations/components/TaskView/components/ViewingPoints';
import { getTaskStatusData } from '../../../operations/components/TaskView/utils/getTaskStatusData';
import { createZoneName, toDouble } from '../../mobx/stores/TaskStore/Task.store';
import { ChecklistsService } from '../../../../../shared/mobx/services/as-fields/ChecklistsService';
import { useTasksParams, useTasksRouteActions } from '../../hooks';
import { ProfileStore } from '../../../profile/stores/ProfileStore';
import { TaskStatusPill } from '../../components/TaskStatusPill';
import { TaskController } from '../../mobx/controllers';
import { ITaskEvent } from '../../../../../../api/models/task/events/task.event.mode';

import Styled from './TasksDrawer.styles';
import { ReactComponent as CloseSvg } from './assets/close.svg';

interface IProps {
  task: ITask;
  onClose: () => void;
}

const TasksDrawer: FC<IProps> = ({ task, onClose }) => {
  const checklistsService = useStore(ChecklistsService);

  const profileStore = useStore(ProfileStore);
  const taskController = useStore(TaskController);
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>(
    task.operationInfo.operationTypeInfo.name
  );

  const [instanceList, setInstanceList] = useState<IInstance[]>([]);
  const [eventList, setEventList] = useState<ITaskEvent[]>([]);

  const sidebarActions = useSidebar();
  const tasksRouteActions = useTasksRouteActions();
  const params = useTasksParams();

  useEffect(() => {
    (async () => {
      sidebarActions.setInBackground(true);

      const events = await taskController.fetchTaskEventList(task.id);

      setEventList(events);

      const response = await checklistsService.getChecklistInstanceList({
        taskId: task.id,
        size: 2000,
        types: [
          ECheckListInstanceType.ActualPoint,
          ECheckListInstanceType.PlannedPoint,
          ECheckListInstanceType.Machinery,
          ECheckListInstanceType.Field,
        ],
      });

      if (response?.content) {
        setInstanceList(response.content);
      }
    })();

    return () => {
      sidebarActions.setInBackground(false);
    };
  }, []);

  const taskStatusData = useMemo(() => {
    return getTaskStatusData(task);
  }, [task]);

  const fieldName = useMemo(() => {
    const { field, cultureZone } = task;

    const fieldNameText = createZoneName(field?.name, field?.area);

    if (cultureZone) {
      const zoneName = createZoneName(cultureZone?.name, cultureZone.area);

      return cultureZone?.name
        ? `${fieldNameText}. ${zoneName}. ${toDouble(cultureZone.area)} га`
        : `${fieldNameText}. ${toDouble(cultureZone.area)} га`;
    }

    return fieldNameText;
  }, [task]);

  const assigneeName = useMemo(() => {
    const isMyOrganization = params.orgId === 'my';

    if (isMyOrganization) {
      return profileStore.fullName;
    }

    if (task.assignee?.fullName) {
      return task.assignee?.fullName;
    }

    return 'Не назначено';
  }, [task.assignee?.fullName, profileStore.fullName, params.orgId]);

  const handleDetailsClick = () => {
    const {
      status,
      checklistsAvailable,
      checklistsMachineryAvailable,
      checklistsFieldAvailable,
    } = task;

    if (status === ETaskStatus.InWork) {
      tasksRouteActions.goToMap(task.id);
    } else if (status === ETaskStatus.Completed) {
      if (!checklistsAvailable && !checklistsMachineryAvailable && !checklistsFieldAvailable) {
        tasksRouteActions.goToTask(task.id);
      }

      if (instanceList?.length) {
        tasksRouteActions.goToChecklist(instanceList[0].id, task.id);
      } else {
        tasksRouteActions.goToMap(task.id);
      }
    } else {
      tasksRouteActions.goToTask(task.id);
    }

    onClose();
  };

  return (
    <TableElementDrawerBuilder onClose={onClose}>
      <Styled.TasksDrawer>
        <Styled.FixedTitle>
          <Header>
            <OperationInfoWrapper>
              <AutoTooltip
                disabled={!isShowTooltip}
                content={task.operationInfo.operationTypeInfo.name}
                position="bottom-right"
                $translate={{ x: '-203px' }}
              >
                <OperationTypeName ref={ref} data-test-id={'operation-type-name'}>
                  {task.operationInfo.operationTypeInfo.name}
                </OperationTypeName>
              </AutoTooltip>

              <TaskStatusPill
                status={task?.status}
                eventList={eventList}
                isRightTooltip={isShowTooltip}
              />
            </OperationInfoWrapper>

            <StyledWrapper>
              <CloseSvg data-test-id={'close-task-view-form'} onClick={onClose} />
            </StyledWrapper>
          </Header>
        </Styled.FixedTitle>

        <Scrollbar>
          <TopBlock>
            {Boolean(taskStatusData) && (
              <TaskStatusBlock taskForView={task}>
                <TaskStatusBoldText>{taskStatusData.boldText}</TaskStatusBoldText>

                <TaskStatusNormalText>{taskStatusData.normalText}</TaskStatusNormalText>

                <TooltipWrapper>
                  <Chip
                    className="task-status-data-chip"
                    color="default"
                    size="medium"
                    icon="info"
                    dataTestId={`task-status`}
                    iconTooltip={{
                      content: (
                        <TooltipContentWrapper>
                          <TooltipContentRow>
                            <TooltipText>Плановое начало</TooltipText>
                            <TooltipDate>
                              {moment(task.planStartDate).format('DD.MM.YYYY')}
                            </TooltipDate>
                          </TooltipContentRow>

                          <TooltipContentRow>
                            <TooltipText>Плановое завершение</TooltipText>
                            <TooltipDate>
                              {moment(task.planEndDate).format('DD.MM.YYYY')}
                            </TooltipDate>
                          </TooltipContentRow>
                        </TooltipContentWrapper>
                      ),
                      position: 'bottom-left',
                      $translate: {
                        x: '20px',
                      },
                    }}
                  />
                </TooltipWrapper>
              </TaskStatusBlock>
            )}

            <Content>
              <InfoBlock>
                <Label>Культура</Label>
                <SvgImg src={cultureSvg} />
                <Info data-test-id={'culture-name'}>
                  {task?.culture?.name || 'Культура не указана'}
                </Info>
              </InfoBlock>

              <InfoBlock>
                <Label>Поле</Label>
                <SvgImg src={fieldSvg} />
                <Info data-test-id={'field-name'}>{fieldName}</Info>
              </InfoBlock>

              <InfoBlock>
                <Label>Исполнитель</Label>
                <SvgImg src={userSvg} />
                <Info data-test-id={'task-assignee'}>{assigneeName}</Info>
              </InfoBlock>

              <InfoBlock>
                <Label>Автор</Label>
                <SvgImg src={userSvg} />
                <Info data-test-id={'task-assignee'}>{task.createdBy?.fullName || `Я`}</Info>
              </InfoBlock>

              <CommentBlock>
                <CommentLabel>Комментарий к задаче</CommentLabel>
                <TextExpand data-test-id={'task-comment'} text={task.comment || 'Отсутствует'} />
              </CommentBlock>

              {Boolean(instanceList.length) && (
                <ViewingPoints checklistInstanceList={instanceList} />
              )}
            </Content>
          </TopBlock>
        </Scrollbar>

        <Styled.FixedFooter>
          <Footer>
            <DetailsButton onClick={handleDetailsClick}>Подробнее</DetailsButton>
          </Footer>
        </Styled.FixedFooter>
      </Styled.TasksDrawer>
    </TableElementDrawerBuilder>
  );
};

TasksDrawer.displayName = 'TasksDrawer';

export default observer(TasksDrawer);
