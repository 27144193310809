import { observer } from 'mobx-react';
import { FC, MouseEvent, memo, useCallback } from 'react';

import { useTableBuilderUIContext as useUIContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { TableBuilderGrid as Grid } from '../../shared';
import { useStore } from '../../../../../utils/IoC';
import { TableBuilderStore as Store } from '../../../mobx/stores';
import { useSortBy } from '../../../../utils/hooks/sort';
import { ITableBuilderRow as IRow } from '../../../models/data';
import { TableBuilderCell as Cell } from '../TableBuilderCell';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';

import Styled from './TableBuilderRow.styles';

interface IProps {
  row: IRow;
}

const TableBuilderRow: FC<IProps> = ({ row }) => {
  const store = useStore(Store);

  const UIContext = useUIContext();

  const cellList = useSortBy(store.getCellList(UIContext.builderId));
  const rowConfig = store.getRowConfig(UIContext.builderId);

  const handleRowClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      rowConfig?.onRowClick?.(row.element, event);
    },
    [rowConfig?.onRowClick, row.element]
  );

  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper
      onClick={handleRowClick}
      $hasClickEvent={Boolean(rowConfig?.onRowClick)}
      {...getDataTestId('row-wrapper')}
    >
      <Grid>
        {cellList.map(cell => (
          <Cell key={cell.id as string} cell={cell} row={row} />
        ))}
      </Grid>
    </Styled.Wrapper>
  );
};

TableBuilderRow.displayName = 'TableBuilderRow';

export default memo(observer(TableBuilderRow));
