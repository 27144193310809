import { FC, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';

import { TableBuilderHeader as Header } from '../header';
import { ContentLoader } from '../../../UI/loaders/ContentLoader';
import { TableBuilderContent as Content } from '../content';
import {
  useTableBuilderDataTestId as useDataTestId,
  useTableBuilderInfiniteScroll as useInfiniteScroll,
} from '../../hooks';
import { TTableBuilderStylePreset as TStylePreset } from '../../types/styles';
import { useStore } from '../../../../utils/IoC';
import { TableBuilderStore as Store } from '../../mobx/stores';
import { useTableBuilderUIContext as useUIContext } from '../../context/UI/TableBuilderUIContext/hooks';

import Styled from './TableBuilderTable.styles';

interface IProps {
  /**
   * Глобальный пресет стилей, где:
   * 'primary' — основной стиль таблицы согласно макетам;
   * 'cleared' — стиль без border и padding.
   */
  stylePreset?: TStylePreset;
  /**
   * Отображает компонент MoveTop
   * 'showMoveTop'
   */
  showMoveTop?: boolean;
}

const TableBuilderTable: FC<IProps> = ({ stylePreset, showMoveTop }) => {
  const store = useStore(Store);
  const UIContext = useUIContext();

  const getDataTestId = useDataTestId(UIContext.builderId);

  const { tableRef, isShowLoaderByScroll } = useInfiniteScroll(UIContext.builderId);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const isFetchingElements = store.getIsFetchingElements(UIContext.builderId);

  /**
   * Данная логика необходима для того, чтобы явно зафиксировать высоту оверлея загрузки при пагинации.
   * В противном случае, из-за overflow-hidden, корректную высоту контента через ref получить невозможно.
   */
  const loaderOverlayHeight = useMemo<`${number}px`>(() => {
    const headerOffsetHeight = headerRef.current?.offsetHeight ?? 0;
    const contentOffsetHeight = contentRef.current?.offsetHeight ?? 0;

    // Производим расчет скрытого контента.
    const contentHeightWithHiddenOverflow = contentOffsetHeight + headerOffsetHeight;

    /**
     * Если у нас нет скрытого контента (не отображается скролл),
     * то просто возвращаем исходную высоту контента,
     * иначе возвращаем рассчитанную высоту (высота хэдера + видимый контент).
     */
    if (contentOffsetHeight < contentHeightWithHiddenOverflow) {
      return `${contentOffsetHeight}px`;
    }

    return `${contentHeightWithHiddenOverflow}px`;
  }, [headerRef.current?.offsetHeight, contentRef.current?.offsetHeight]);

  const loaderDataTestId = getDataTestId('content-loader')['data-test-id'];
  const moveTopDataTestId = getDataTestId('move-top')['data-test-id'];

  return (
    <Styled.Wrapper $preset={stylePreset} {...getDataTestId()}>
      <Styled.HeaderWrapper ref={headerRef} {...getDataTestId('header-wrapper')}>
        <Header />
      </Styled.HeaderWrapper>

      <Styled.ContentWrapper ref={contentRef} {...getDataTestId('content-wrapper')}>
        {isShowLoaderByScroll || isFetchingElements ? (
          <ContentLoader
            parentRef={contentRef}
            overlayStyles={{
              height: loaderOverlayHeight,
              borderRadius: '0 0 16px 16px',
              zIndex: '3',
            }}
            dataTestId={loaderDataTestId}
          />
        ) : null}

        <Content ref={tableRef} />
      </Styled.ContentWrapper>
      <Styled.MoveTop
        parentRef={tableRef}
        showMoveTop={showMoveTop}
        dataTestId={moveTopDataTestId}
      />
    </Styled.Wrapper>
  );
};

TableBuilderTable.displayName = 'TableBuilderTable';

export default observer(TableBuilderTable);
