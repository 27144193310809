import styled from 'styled-components';

const MobileIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 4.75303C3.5 3.42754 4.57452 2.35303 5.9 2.35303H9.60909C10.9346 2.35303 12.0091 3.42754 12.0091 4.75303V12.1883C12.0091 13.5138 10.9346 14.5883 9.60909 14.5883H5.9C4.57452 14.5883 3.5 13.5138 3.5 12.1883V4.75303Z"
      stroke="#151F32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 3.98438H12.0091V8.87849H3.5V3.98438Z"
      stroke="#151F32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.75472 10.5098C7.63723 10.5098 7.54199 10.6011 7.54199 10.7137C7.54199 10.8263 7.63723 10.9176 7.75472 10.9176C7.87221 10.9176 7.96745 10.8263 7.96745 10.7137C7.96745 10.6011 7.87221 10.5098 7.75472 10.5098"
      stroke="#151F32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.75472 12.5493C7.63723 12.5493 7.54199 12.6406 7.54199 12.7532C7.54199 12.8659 7.63723 12.9572 7.75472 12.9572C7.87221 12.9572 7.96745 12.8659 7.96745 12.7532C7.96745 12.6406 7.87221 12.5493 7.75472 12.5493"
      stroke="#151F32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.88265 10.5098C9.76516 10.5098 9.66992 10.6011 9.66992 10.7137C9.66992 10.8263 9.76516 10.9176 9.88265 10.9176C10.0001 10.9176 10.0954 10.8263 10.0954 10.7137C10.0954 10.6011 10.0001 10.5098 9.88265 10.5098"
      stroke="#151F32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.88265 12.5493C9.76516 12.5493 9.66992 12.6406 9.66992 12.7532C9.66992 12.8659 9.76516 12.9572 9.88265 12.9572C10.0001 12.9572 10.0954 12.8659 10.0954 12.7532C10.0954 12.6406 10.0001 12.5493 9.88265 12.5493"
      stroke="#151F32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.62777 10.5098C5.51028 10.5098 5.41504 10.6011 5.41504 10.7137C5.41504 10.8263 5.51028 10.9176 5.62777 10.9176C5.74525 10.9176 5.84049 10.8263 5.84049 10.7137C5.84049 10.6011 5.74525 10.5098 5.62777 10.5098"
      stroke="#151F32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.62777 12.5493C5.51028 12.5493 5.41504 12.6406 5.41504 12.7532C5.41504 12.8659 5.51028 12.9572 5.62777 12.9572C5.74525 12.9572 5.84049 12.8659 5.84049 12.7532C5.84049 12.6406 5.74525 12.5493 5.62777 12.5493"
      stroke="#151F32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const DesktopIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6667 2C13.0203 2 13.3594 2.14048 13.6095 2.39052C13.8595 2.64057 14 2.97971 14 3.33333V10.6667C14 11.0203 13.8595 11.3594 13.6095 11.6095C13.3594 11.8595 13.0203 12 12.6667 12H10V12.6667H10.6667C10.8435 12.6667 11.013 12.7369 11.1381 12.8619C11.2631 12.987 11.3333 13.1565 11.3333 13.3333C11.3333 13.5101 11.2631 13.6797 11.1381 13.8047C11.013 13.9298 10.8435 14 10.6667 14H5.33333C5.15652 14 4.98695 13.9298 4.86193 13.8047C4.7369 13.6797 4.66667 13.5101 4.66667 13.3333C4.66667 13.1565 4.7369 12.987 4.86193 12.8619C4.98695 12.7369 5.15652 12.6667 5.33333 12.6667H6V12H3.33333C2.97971 12 2.64057 11.8595 2.39052 11.6095C2.14048 11.3594 2 11.0203 2 10.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H12.6667ZM8.66667 12H7.33333V12.6667H8.66667V12ZM12.6667 3.33333H3.33333V10.6667H12.6667V3.33333Z"
        fill="#151F32"
      />
    </svg>
  );
};

const List = styled.ul`
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
`;

const Item = styled.li`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

const Icon = styled.i`
  line-height: 20px;
  max-height: 16px;
`;

const Date = styled.span`
  padding-left: 4px;
  flex-grow: 1;
`;

const LastElementWrapper = styled.div``;

const Styled = {
  List,
  Item,
  Date,
  Icon,
  MobileIcon,
  DesktopIcon,
  LastElementWrapper,
};

export default Styled;
