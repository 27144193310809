import { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { AxiosError } from 'axios';

import { useStore } from '../../../../../shared/utils/IoC';
import { TaskStore } from '../../mobx/stores';
import TaskController from '../../mobx/controllers/TaskController/Task.controller';
import { useTasksParams } from '../index';
import { ChecklistInstancesController } from '../../../operationsAndTasks/controllers/checklist.instances.controller';
import { ChecklistInstancesStore } from '../../../operationsAndTasks/stores/checklist.instances.store';
import { useInspectionPointsActionsHook } from '../../components/InspectionPoints/hooks';
import { ApplicationRoutes } from '../../../../../routes';

interface IHookConfig {
  isIgnoreOnMountFetch?: {
    taskData?: boolean;
    instanceList?: boolean;
  };
  isIgnoreOnUnmountClear?: boolean;
}

const useTaskPageGlobalControl = (mode: TaskStore['mode'], options?: IHookConfig) => {
  const taskStore = useStore(TaskStore);
  const checklistsStore = useStore(ChecklistInstancesStore);

  const taskController = useStore(TaskController);
  const checklistsController = useStore(ChecklistInstancesController);

  const params = useTasksParams();
  const inspectionPointsActions = useInspectionPointsActionsHook();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const fetchInstanceList = useCallback(async () => {
    const instanceList = await checklistsController.fetchInstanceList(
      params.taskId,
      inspectionPointsActions.displayPointList,
      true
    );

    return instanceList;
  }, [params.taskId]);

  const fetchTaskData = useCallback(async () => {
    try {
      const taskData = await taskController.fetchTaskData(params.taskId, {
        displayZone: inspectionPointsActions.displayZone,
        isEditMode: mode === 'edit',
      });

      return taskData;
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 403) {
        return navigate(ApplicationRoutes.PageForbidden);
      }

      navigate(ApplicationRoutes.PageNotFound);
    }
  }, [params.taskId, mode]);

  const clearStores = useCallback(
    (clearOptions?: { isIgnoreTaskStore?: boolean; isIgnoreInstanceStore?: boolean }) => {
      if (!clearOptions?.isIgnoreTaskStore) {
        taskStore.clearTaskStore();
      }

      if (!clearOptions?.isIgnoreInstanceStore) {
        checklistsStore.clearChecklistInstancesStore();
      }
    },
    []
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      taskStore.setMode(mode);

      if (!options?.isIgnoreOnMountFetch?.taskData) {
        await fetchTaskData();
      }

      if (!options?.isIgnoreOnMountFetch?.instanceList) {
        await fetchInstanceList();
      }
    })().finally(() => setIsLoading(false));

    return () => {
      if (options?.isIgnoreOnUnmountClear) {
        return;
      }

      clearStores();
    };
  }, []);

  return {
    fetchInstanceList,
    isLoading,
    fetchTaskData,
    clearStores,
  };
};

export default useTaskPageGlobalControl;
