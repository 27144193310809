import { EChecklistAttributeType as EAttrType } from '../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

enum EPlaceholder {
  Int = 'Укажите значение',
  Double = 'Укажите значение',
  String = 'Введите текст',
  LongString = 'Введите текст',
  Enum = 'Выберите значение',
  UserDictionaryLink = 'Выберите или введите значение',
  DictionaryLink = 'Выберите значение',
  Date = 'Выберите значение',
}

const getChecklistsAttrPlaceholder = (attrType: EAttrType): EPlaceholder => {
  switch (attrType) {
    case EAttrType.Int:
      return EPlaceholder.Int;

    case EAttrType.Double:
      return EPlaceholder.Double;

    case EAttrType.String:
      return EPlaceholder.String;

    case EAttrType.DictionaryLink:
      return EPlaceholder.DictionaryLink;

    case EAttrType.Enum:
      return EPlaceholder.Enum;

    case EAttrType.UserDictionaryLink:
      return EPlaceholder.UserDictionaryLink;

    case EAttrType.LongString:
      return EPlaceholder.LongString;

    case EAttrType.Date:
      return EPlaceholder.Date;

    default:
  }
};

export default getChecklistsAttrPlaceholder;
