import { observer } from 'mobx-react';
import { FC, useMemo, useEffect, useCallback } from 'react';
import { ButtonLink } from '@farmlink/farmik-ui';

import { Map } from '../../../../../shared/features/map/components';
import { useStore } from '../../../../../shared/utils/IoC';
import { ListOfChecklistInstance } from '../../../operationsAndTasks/components/instance';
import { useTasksRouteActions } from '../../hooks';
import InstanceListActualityService from '../../../operationsAndTasks/services/instanceList/instanceListActuality.service';
import { IMapCreateMarkerHandler, IMapMarker } from '../../../../../shared/features/map/models';
import { ECheckListInstanceType } from '../../../../../../api/models/checklist/instance/checklist.instance.model';

import Styled from './InspectionPoints.styles';
import { InspectionPointsStore } from './mobx/stores';
import { InspectionPointsController } from './mobx/controllers';
import './assets/styles/points/points.css';
import { useInspectionPointsActionsHook } from './hooks';

interface IProps {
  mode: 'checklists' | 'tasks';
  onAddPointClick?: () => void;
  instanceTypeListToCreate?: ECheckListInstanceType[];
  isAllowedToShowAddButton?: boolean;
  isAllowedToCreateMarkers?: boolean;
  isDisplayTitleActionButton?: boolean;
  isHideMap?: boolean;
}

const InspectionPoints: FC<IProps> = ({
  mode,
  instanceTypeListToCreate,
  isDisplayTitleActionButton,
  isAllowedToCreateMarkers,
  isHideMap,
}) => {
  const { pointElementList, clearStore, setIsInspectionPointsChanged } = useStore(
    InspectionPointsStore
  );
  const { getMarkerOptions, createMarker } = useStore(InspectionPointsController);
  const { clearService } = useStore(InstanceListActualityService);

  const { goToMapCreatePoint } = useTasksRouteActions();
  const { createMarkerHandler } = useInspectionPointsActionsHook();

  const markerHandler: IMapCreateMarkerHandler = useCallback(
    (marker: IMapMarker) => {
      setIsInspectionPointsChanged(true);
      return createMarkerHandler(marker, instanceTypeListToCreate);
    },
    [instanceTypeListToCreate]
  );

  const sortedPointList = useMemo(
    () => pointElementList.sort((a, b) => a.element.positionNumber - b.element.positionNumber),
    [pointElementList]
  );

  useEffect(() => {
    return () => {
      clearService();
      clearStore();
    };
  }, []);

  if (mode === 'checklists') {
    return (
      <Styled.MapWrapper $isHidden={isHideMap}>
        <Map
          globalConfig={{
            mapKey: 'taskInspectionPointsMap',
            createMarkersOptions: {
              getMarkerOptions,
              createMarker,
              isAllowedToCreateMarkers,
            },
          }}
          style={{ borderRadius: '16px' }}
        />
      </Styled.MapWrapper>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>Точки осмотра</Styled.Title>

        {isDisplayTitleActionButton && (
          <ButtonLink
            onClick={() => {
              goToMapCreatePoint();
            }}
            color="accent"
          >
            + Добавить или изменить точки
          </ButtonLink>
        )}
      </Styled.Header>

      <Styled.MapWrapper $marginTop={'24px'}>
        <Map
          globalConfig={{
            mapKey: 'taskInspectionPointsMap',
            createMarkersOptions: {
              getMarkerOptions,
              isAllowedToCreateMarkers,
              createMarker: markerHandler,
            },
            fieldIndicesOptions: {},
          }}
          style={{ borderRadius: '16px' }}
        />
      </Styled.MapWrapper>

      {sortedPointList?.length ? (
        <Styled.InstanceListWrapper>
          <ListOfChecklistInstance
            mode={'fullscreen'}
            variant="fullscreen"
            instList={sortedPointList.map(({ element }) => element)}
            type={'point'}
            title={null}
            isChangesCanBeUndone
          />
        </Styled.InstanceListWrapper>
      ) : null}
    </Styled.Wrapper>
  );
};

InspectionPoints.displayName = 'InspectionPoints';

export default observer(InspectionPoints);
