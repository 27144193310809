import { FC, useEffect, useState, useCallback, useMemo } from 'react';
import {
  Button,
  CalendarComponent,
  Dropdown,
  EDateFormat,
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';

import { ModalComponent } from '../../../../../../components/Modal/Modal';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationsStore } from '../../stores/operations.store';
import { CreateOperationFormController } from '../../controllers/create.operation.form.controller';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { SeasonCultureStore } from '../../stores/season.culture.store';
import { TDropdownConfig } from '../../../../../shared/components/inputs/Dropdown/Dropdown.types';

import {
  Title,
  Line,
  SeasonStubSelectorWrapper,
  CalendarWrapper,
  CalendarRow,
  BottomLine,
  ButtonsWrapper,
  CalendarSplitter,
} from './style';

type CreateSeasonModalProps = {
  closeModal: () => void;
  operationId: string;
};

export const EditOperationModal: FC<CreateSeasonModalProps> = observer(
  ({ closeModal, operationId }) => {
    const seassonStore = useStore(SeasonsStore);
    const operationsStore = useStore(OperationsStore);
    const createOperationFormController = useStore(CreateOperationFormController);
    const seasonCultureStore = useStore(SeasonCultureStore);

    const operationToEdit = createOperationFormController.getOperationById(operationId);

    const [operationTypeId, setOperationTypeId] = useState(operationToEdit.operationTypeInfo.id);
    const [isFormSending, setIsFormSending] = useState(false);

    const [startDate, setStartDate] = useState<Moment>(moment(operationToEdit.startDate));
    const [endDate, setEndDate] = useState<Moment>(moment(operationToEdit.endDate));

    const [operationTitle, setOperationTitle] = useState(null);

    const notificatorActions = useNotificator();

    useEffect(() => {
      const culture = operationsStore.OperationCulturesInFields.filter(
        item => item?.culture.id === seasonCultureStore.selectedCultureId
      )[0]?.culture.name;

      setOperationTitle(culture ? culture : 'поля без культуры');
      operationsStore.fetchOperationTypeDictionaryByCultureId(seasonCultureStore.selectedCultureId);
    }, []);

    const isSaveButtonAvailable =
      endDate && startDate.isSameOrBefore(endDate) && Boolean(operationTypeId) && !isFormSending;

    const handleEditOperation = async () => {
      setIsFormSending(true);

      try {
        await createOperationFormController.handleEditOperation(
          {
            startDate: startDate?.format('YYYY-MM-DD'),
            endDate: endDate?.format('YYYY-MM-DD'),
            name: operationToEdit.name,
            operationTypeId: operationToEdit.id,
          },
          operationId
        );

        notificatorActions.setNotification({
          message: 'Операция успешно изменена',
          style: {
            type: ENotificationType.Success,
            height: ENotificationHeight.BIG,
            placement: 'top-center',
            autoClose: 10000,
            hideProgressBar: false,
            theme: ENotificatorTheme.Dark,
          },
        });

        setIsFormSending(false);

        closeModal();
      } catch (e) {
        notificatorActions.setNotification({
          message: `Ошибка изменения операции`,
          style: {
            type: ENotificationType.Warning,
            height: ENotificationHeight.BIG,
            placement: 'top-center',
            autoClose: 10000,
            hideProgressBar: false,
            theme: ENotificatorTheme.Dark,
          },
        });

        setIsFormSending(false);
      }
    };

    const handleStartDateChange = useCallback(
      (date: Date) => {
        const momentStartDate = moment(date);
        setStartDate(momentStartDate);
        if (momentStartDate.isAfter(endDate)) setEndDate(momentStartDate);
      },
      [endDate]
    );

    const handleEndDateChange = useCallback(
      (date: Date) => {
        const momentEndDate = moment(date);
        setEndDate(momentEndDate);
        if (momentEndDate.isBefore(startDate)) setStartDate(momentEndDate);
      },
      [startDate]
    );

    const startDateValue = useMemo(() => {
      const result = (startDate as Moment)?.format('DD.MM.YYYY') || '';
      return result;
    }, [startDate]);

    const endDateValue = useMemo(() => {
      const result = (endDate as Moment)?.format('DD.MM.YYYY') || '';
      return result;
    }, [endDate]);

    const startDateCalendar = useMemo(
      () => (
        <CalendarComponent
          label={'Начало выполнения'}
          value={startDateValue}
          defaultValue={startDateValue}
          onChange={handleStartDateChange}
          placeholder={'Укажите дату'}
          datePickerOptions={{
            dateRange: {
              minDate: moment(seassonStore.selectedSeassonData.startDate).toDate(),
              maxDate: moment(seassonStore.selectedSeassonData.endDate).toDate(),
            },
          }}
          dataTestId={'operation-start-date'}
          dateFormat={EDateFormat.DayAndMonthOnly}
          readOnly={false}
          isDisabled={false}
          isBlocked={false}
          error={''}
          isCloseOnChange={true}
        />
      ),
      [
        startDate,
        endDate,
        startDateValue,
        seassonStore.selectedSeassonData.startDate,
        seassonStore.selectedSeassonData.endDate,
      ]
    );

    const endDateCalendar = useMemo(
      () => (
        <CalendarComponent
          label={'Окончание выполнения'}
          value={endDateValue}
          defaultValue={endDateValue}
          onChange={handleEndDateChange}
          placeholder={'Укажите дату'}
          datePickerOptions={{
            dateRange: {
              minDate: moment(seassonStore.selectedSeassonData.startDate).toDate(),
              maxDate: moment(seassonStore.selectedSeassonData.endDate).toDate(),
            },
          }}
          dataTestId={'operation-end-date'}
          dateFormat={EDateFormat.DayAndMonthOnly}
          readOnly={false}
          isDisabled={false}
          isBlocked={false}
          error={''}
          isCloseOnChange={true}
        />
      ),
      [
        startDate,
        endDate,
        endDateValue,
        seassonStore.selectedSeassonData.startDate,
        seassonStore.selectedSeassonData.endDate,
      ]
    );

    const dropdownConfig: TDropdownConfig = {
      body: { optionList: operationsStore.getOperationsTypeSelectAdapter },
      field: {
        onChange: setOperationTypeId,
        placeholder: 'Не выбрано',
        defaultValue: {
          value: operationToEdit.id,
          label: operationToEdit.name,
        },
      },
      visual: {
        label: 'Тип операции',
        isBlocked: true,
      },
      other: {
        dataTestId: 'operation-stub-select',
      },
    };

    return (
      <ModalComponent size={'medium'} paddings={'seasons'} data-test-id={'operation-add-modal-new'}>
        <Title data-test-id={'operation-add-modal-new-title'}>Операция для {operationTitle}</Title>
        <Line />
        <SeasonStubSelectorWrapper>
          <Dropdown config={dropdownConfig} />
        </SeasonStubSelectorWrapper>
        <CalendarRow>
          <CalendarWrapper>{startDateCalendar}</CalendarWrapper>
          <CalendarSplitter />
          <CalendarWrapper>{endDateCalendar}</CalendarWrapper>
        </CalendarRow>
        <BottomLine />
        <ButtonsWrapper>
          <Button
            color={'default'}
            type={'button'}
            onClick={() => closeModal()}
            dataTestId={'operation-cancel-btn'}
          >
            Отменить
          </Button>
          <Button
            color={'primary'}
            type={'button'}
            disabled={!isSaveButtonAvailable}
            onClick={handleEditOperation}
            dataTestId={'operation-save-btn'}
          >
            Сохранить
          </Button>
        </ButtonsWrapper>
      </ModalComponent>
    );
  }
);
