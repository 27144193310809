import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const config: TModalConfig = {
  id: 'warningBeforeLeavingThePage',
  name: 'warningBeforeLeavingThePage',
  title: 'Если покинуть экран, все несохранённые данные будут потеряны',
  type: EModalType.Warning_Before_Living,
  styles: {
    title: {
      $size: 'large',
      $alignTitle: 'center',
    },
  },
  dataTestId: 'warning-before-leaving-the-page',
  denyButton: {
    title: 'Остаться',
    dataTestId: 'warning-before-leaving-the-page-deny-button',
  },
  successButton: {
    title: 'Покинуть экран',
    dataTestId: 'warning-before-leaving-the-page-success-button',
  },
};

const createWarningBeforeLeavingThePage = (
  successHandler: () => void,
  denyHandler?: () => void
): { key: string; name: string; configList: TModalConfig[] } => {
  return {
    key: config.name,
    name: config.name,
    configList: [
      {
        ...config,
        denyButton: { ...config.denyButton, handler: denyHandler && denyHandler },
        successButton: { ...config.successButton, handler: successHandler, color: 'secondary' },
      },
    ],
  };
};

export default createWarningBeforeLeavingThePage;
