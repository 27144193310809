import { TypeApiRoute } from '../../models/type.api.request';
import { Field } from '../../models/field.model';

type TypeRequest = {
  id: string;
  seasonYear: string;
};

type TypeResponse = any;

export const saveFieldById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request =>
    `/api/as-fields/fields/${request.id}${
      request.seasonYear ? `?seasonYear=${request.seasonYear}` : ''
    }`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
