import { useCallback } from 'react';

import { useStore } from '../../../../utils/IoC';
import { PermissionsBuilderStore as Store } from '../../mobx/stores';
import { PermissionsBuilderController as Controller } from '../../mobx/controllers';

/**
 * Хук доступов, где:
 * P - строковые типы самих доступов (экшены).
 * B - идентификатор сущности (билдера).
 */
const usePermissionsBuilderPermissions = <P = string, B = string>(builderId?: B) => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const getPermission = useCallback(
    (permission: P, customBuilderId?: B) => {
      return store.hasPermission(customBuilderId || builderId, permission);
    },
    [builderId]
  );

  const getPermissionList = useCallback(
    (customBuilderId?: B) => {
      return store.getPermissionList(customBuilderId || builderId);
    },
    [builderId]
  );

  const addPermissionList = useCallback(
    (permissionList: P[], customBuilderId?: B, config?: { isClearPreviousList?: boolean }) => {
      controller.addPermissionList(customBuilderId || builderId, permissionList, config);
    },
    [builderId]
  );

  const clearStore = useCallback(
    (customBuilderId?: B) => {
      controller.clearStore(customBuilderId || builderId);
    },
    [builderId]
  );

  return {
    getPermission,
    getPermissionList,
    addPermissionList,
    clearStore,
  };
};

export default usePermissionsBuilderPermissions;
