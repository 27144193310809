import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Colors, Icon } from '@farmlink/farmik-ui';

import { useStore } from '../../../shared/utils/IoC';
import MapStore from '../../stores/map.store';
import { clickMapEventName } from '../../events/click.map.event';
import { CultureZoneStore } from '../../../dashboard/components/PopupSlider/components/CultureZone/stores/culture.zone.store';
import Styled from '../PointCoordinatesControls/PointCoordinatesControls.styles';

import { usePointCoordinatesUpdate } from './hooks';

const PointCoordinatesControls = () => {
  const map = useStore(MapStore);
  const zone = useStore(CultureZoneStore);

  const {
    clearFocusMarker,
    expandChangeCord,
    isDisplayCoordinatesBlock,
    isExpanded,
    newCoords,
    shrinkChangeCord,
    coordinates,
    updateCord,
    onChangeCoordinates,
    error,
    isExpandDisabled,
  } = usePointCoordinatesUpdate({ map, zone });

  useEffect(() => {
    const onMapClick = e => {
      clearFocusMarker();
    };

    window.addEventListener(clickMapEventName, onMapClick);

    return () => {
      window.removeEventListener(clickMapEventName, onMapClick);
    };
  }, []);

  if (!isDisplayCoordinatesBlock || isExpandDisabled) return null;

  return (
    <Styled.BottomBackground>
      <Styled.Container onSubmit={updateCord}>
        <Styled.CoordinateDisplay value={coordinates} blocked />
        {isExpanded ? (
          <>
            <Styled.CoordinateDisplay
              value={newCoords}
              onChange={onChangeCoordinates}
              $isError={Boolean(error?.length)}
            />

            <Styled.IconButton
              onClick={shrinkChangeCord}
              size={40}
              $padding={12}
              type="button"
              customIcon={
                <Styled.IconWrapper $hoverColor={Colors.lightPink}>
                  <Icon icon="close" size={16} fill={Colors.accentPink} />
                </Styled.IconWrapper>
              }
            />
            <Styled.IconButton
              size={40}
              $padding={12}
              type="submit"
              customIcon={
                <Styled.IconWrapper $hoverColor={Colors.lightGreen}>
                  <Styled.CheckIcon />
                </Styled.IconWrapper>
              }
            />
          </>
        ) : (
          <Styled.IconButton
            onClick={expandChangeCord}
            size={40}
            $padding={12}
            type="button"
            $isDisabled={isExpandDisabled}
            customIcon={
              <Styled.IconWrapper>
                <Icon icon="coordinates" size={16} />
              </Styled.IconWrapper>
            }
          />
        )}
      </Styled.Container>
    </Styled.BottomBackground>
  );
};

export default observer(PointCoordinatesControls);
