import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrapper = styled.div`
  width: 20px;
  height: 20px;
  animation: ${rotate} 2s linear infinite;
  position: absolute;
  left: 50%;
  margin-left: -10px;
`;

export const ButtonWrapper = styled.div<{ isLoading: boolean }>`
  position: relative;
  & button {
    justify-content: center;

    cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
  }
`;
