import styled from 'styled-components';

import { Colors } from '../../../../../../../constans/colors';

import closeDefaultSvg from './assets/images/close-default-12x12.svg';
import closeHoverSvg from './assets/images/close-hover-12x12.svg';

const Label = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  color: ${Colors.generalBlack};
  ${({ theme }) => theme.Typography.schemas.body}
`;

const Close = styled.div`
  width: 12px;
  height: 12px;
  background: url(${closeDefaultSvg});
  cursor: pointer;

  :hover {
    background: url(${closeHoverSvg});
  }
`;

const Wrapper = styled.div<{ $isBlocked?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 2px 8px;
  border-radius: 8px;
  background-color: ${({ $isBlocked }) => ($isBlocked ? Colors.gray : Colors.generalWhite)};

  & > :not(:last-child) {
    margin-right: 4px;
  }
`;

const StyledDropdownMultiselectValue = { Close, Label, Wrapper };

export default StyledDropdownMultiselectValue;
