import { makeAutoObservable } from 'mobx';
import { cloneDeep, isArray } from 'lodash';

import {
  ITableFiltersBuilderPreset as IPreset,
  ITableFiltersBuilderFilter as IFilter,
  ITableFiltersBuilderValue as IValue,
} from '../../../models/data';
import {
  ITableFiltersBuilderHeaderConfig as IHeaderConfig,
  ITableFiltersBuilderRowConfig as IRowConfig,
} from '../../../models/configs';
import { provide } from '../../../../../utils/IoC';
import { ISelectOption } from '../../../../../../../types/selectOption';

interface ISetElementListOptions {
  isClearPreviousList?: boolean;
}

@provide.singleton()
class TableFiltersBuilderStore<F = any> {
  /**
   * Делает перерасчет верстки фильтров на основное переданного флага и ключа.
   * Пример: родительский компонент фильтров изменил размеры и нужно сделать перерасчет
   * количества тегов для отображения. Если этого не сделать, то поедет верстка,
   * так как отображение тегов рассчитывается при помощи JS.
   */
  private _isNeedToResizeFiltersByBuilderId: Map<string, boolean> = new Map();

  /**
   * Когда активируется какая-то анимация, что связана с изменением размеров фильтров,
   * то скрываем все кнопки, для которых требуется финальный перерасчет размера контейнера.
   */
  private _isResizeAnimationsActivatedByBuilderId: Map<string, boolean> = new Map();

  private _headersByBuilderId: Map<string, IHeaderConfig> = new Map();

  private _rowConfigsByBuilderId: Map<string, IRowConfig> = new Map();

  private _selectedPresetsByBuilderId: Map<string, IPreset<F>> = new Map();

  private _isShowFiltersByBuilderId: Map<string, boolean> = new Map();

  private _appliedValuesByBuilderId: Map<string, Map<keyof F, IValue<F>>> = new Map();

  private _selectedValuesByBuilderId: Map<string, Map<keyof F, IValue<F>>> = new Map();

  private _defaultValuesByBuilderId: Map<string, Map<keyof F, IValue<F>>> = new Map();

  private _presetsByBuilderId: Map<string, Map<string, IPreset<F>>> = new Map();

  private _filtersByBuilderId: Map<string, Map<keyof F, IFilter<F>>> = new Map();

  private _optionListsByBuilderId: Map<string, Map<keyof F, ISelectOption[]>> = new Map();

  constructor() {
    makeAutoObservable(this);

    // @ts-ignore
    window.builder = this;
  }

  getIsNeedToResizeFilters = (builderId: string): boolean => {
    return this._isNeedToResizeFiltersByBuilderId.get(builderId);
  };

  getIsResizeAnimationsActivated = (builderId: string): boolean => {
    return this._isResizeAnimationsActivatedByBuilderId.get(builderId);
  };

  getHeaderConfig = (builderId: string): IHeaderConfig => {
    return this._headersByBuilderId.get(builderId);
  };

  getRowConfig = (builderId: string): IRowConfig => {
    return this._rowConfigsByBuilderId.get(builderId);
  };

  getSelectedPreset = (builderId: string): IPreset<F> => {
    return this._selectedPresetsByBuilderId.get(builderId);
  };

  getIsShowFilters = (builderId: string): boolean => {
    return this._isShowFiltersByBuilderId.get(builderId);
  };

  getAppliedValueList = (
    builderId: string,
    options?: {
      isVisibleOnly?: boolean;
    }
  ): IValue<F>[] => {
    const valueList = [...(this._appliedValuesByBuilderId.get(builderId)?.values?.() || [])];

    if (options?.isVisibleOnly) {
      return valueList.filter(({ isHidden }) => !isHidden);
    }

    return [...(this._appliedValuesByBuilderId.get(builderId)?.values?.() || [])];
  };

  getAppliedValues = (builderId: string): Record<keyof F, IValue<F>> => {
    const valueEntryList = [...(this._appliedValuesByBuilderId.get(builderId)?.entries?.() || [])];

    return Object.fromEntries(valueEntryList) as Record<keyof F, IValue<F>>;
  };

  getAppliedValue = (builderId: string, filterId: keyof F): IValue<F> => {
    return this._appliedValuesByBuilderId.get(builderId)?.get?.(filterId);
  };

  getSelectedValueList = (builderId: string): IValue<F>[] => {
    return [...(this._selectedValuesByBuilderId.get(builderId)?.values?.() || [])];
  };

  getSelectedValue = (builderId: string, filterId: keyof F): IValue<F> => {
    return this._selectedValuesByBuilderId.get(builderId)?.get?.(filterId);
  };

  getDefaultValueList = (builderId: string): IValue<F>[] => {
    return [...(this._defaultValuesByBuilderId.get(builderId)?.values?.() || [])];
  };

  getDefaultValue = (builderId: string, filterId: keyof F): IValue<F> => {
    return this._defaultValuesByBuilderId.get(builderId)?.get?.(filterId);
  };

  getPresetList = (builderId: string): IPreset<F>[] => {
    return [...(this._presetsByBuilderId.get(builderId)?.values?.() || [])];
  };

  getPreset = (builderId: string, presetId: string): IPreset<F> => {
    return this._presetsByBuilderId.get(builderId)?.get?.(presetId);
  };

  getFilterList = (
    builderId: string,
    options?: {
      isOnlyVisible?: boolean;
    }
  ): IFilter<F>[] => {
    const filterList = [...(this._filtersByBuilderId.get(builderId)?.values?.() || [])];

    if (options?.isOnlyVisible) {
      return filterList.filter(({ isHidden }) => !isHidden);
    }

    return filterList;
  };

  getFilter = (builderId: string, filterId: keyof F): IFilter<F> => {
    return this._filtersByBuilderId.get(builderId)?.get?.(filterId);
  };

  getOptionList = (builderId: string, filterId: keyof F): ISelectOption[] => {
    return this._optionListsByBuilderId.get(builderId)?.get?.(filterId) || [];
  };

  setIsNeedToResizeFilters = (builderId: string, value: boolean): void => {
    this._isNeedToResizeFiltersByBuilderId.set(builderId, value);
  };

  setIsResizeAnimationsActivated = (builderId: string, value: boolean): void => {
    this._isResizeAnimationsActivatedByBuilderId.set(builderId, value);
  };

  setHeaderConfig = (builderId: string, config: IHeaderConfig): void => {
    this._headersByBuilderId.set(builderId, config);
  };

  updateHeaderConfig = (builderId: string, data: Partial<IHeaderConfig>): void => {
    const headerConfig = this.getHeaderConfig(builderId);

    if (!headerConfig) return;

    this._headersByBuilderId.set(builderId, { ...headerConfig, ...data });
  };

  setRowConfig = (builderId: string, config: IRowConfig): void => {
    this._rowConfigsByBuilderId.set(builderId, config);
  };

  setSelectedPreset = (builderId: string, presetId: string): void => {
    const preset = this._presetsByBuilderId.get(builderId)?.get?.(presetId);

    if (preset) {
      this.deleteSelectedValueList(builderId, [], { isClearAll: true });

      this._selectedPresetsByBuilderId.set(preset.builderId, preset);

      this.setAppliedValueList(builderId, cloneDeep(preset.relatedFilterValueList), {
        isClearPreviousList: true,
      });
    }
  };

  setIsShowFilters = (builderId: string, value: boolean): void => {
    this._isShowFiltersByBuilderId.set(builderId, value);
  };

  setAppliedValueList = (
    builderId: string,
    valueList: IValue<F>[],
    options?: ISetElementListOptions
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(this._appliedValuesByBuilderId.get(builderId)?.entries?.() || [])];

    const newCollection = new Map<keyof F, IValue<F>>(previousEntryList);

    if (isArray(valueList)) {
      valueList.forEach(value => {
        newCollection.set(value.filterId, value);
      });
    }

    this._appliedValuesByBuilderId.set(builderId, newCollection);
  };

  setSelectedValueList = (
    builderId: string,
    valueList: IValue<F>[],
    options?: ISetElementListOptions
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(this._selectedValuesByBuilderId.get(builderId)?.entries?.() || [])];

    const newCollection = new Map<keyof F, IValue<F>>(previousEntryList);

    if (isArray(valueList)) {
      valueList.forEach(value => {
        newCollection.set(value.filterId, value);
      });
    }

    this._selectedValuesByBuilderId.set(builderId, newCollection);
  };

  setSelectedValue = (builderId: string, value: IValue<F>): void => {
    const hasCollection = this._selectedValuesByBuilderId.has(builderId);

    if (hasCollection) {
      this._selectedValuesByBuilderId.get(builderId).set(value.filterId, value);
    } else {
      const newCollection = new Map([[value.filterId, value]]);

      this._selectedValuesByBuilderId.set(builderId, newCollection);
    }
  };

  setDefaultValueList = (
    builderId: string,
    valueList: IValue<F>[],
    options?: ISetElementListOptions
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(this._defaultValuesByBuilderId.get(builderId)?.entries?.() || [])];

    const newCollection = new Map<keyof F, IValue<F>>(previousEntryList);

    if (isArray(valueList)) {
      valueList.forEach(value => {
        newCollection.set(value.filterId, value);
      });
    }

    this._defaultValuesByBuilderId.set(builderId, newCollection);
  };

  setPresetList = (
    builderId: string,
    presetList: IPreset<F>[],
    options?: ISetElementListOptions
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(this._presetsByBuilderId.get(builderId)?.entries?.() || [])];

    const newCollection = new Map<string, IPreset<F>>(previousEntryList);

    if (isArray(presetList)) {
      presetList.forEach(preset => {
        newCollection.set(preset.id, preset);
      });
    }

    this._presetsByBuilderId.set(builderId, newCollection);
  };

  setFilterList = (
    builderId: string,
    filterList: IFilter<F>[],
    options?: ISetElementListOptions
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(this._filtersByBuilderId.get(builderId)?.entries?.() || [])];

    const newCollection = new Map<keyof F, IFilter<F>>(previousEntryList);

    if (isArray(filterList)) {
      filterList.forEach(filter => {
        newCollection.set(filter.id, filter);
      });
    }

    this._filtersByBuilderId.set(builderId, newCollection);
  };

  setFilterOptionList = (
    builderId: string,
    filterId: keyof F,
    optionList: ISelectOption[]
  ): void => {
    const hasCollection = this._optionListsByBuilderId.has(builderId);

    if (hasCollection) {
      this._optionListsByBuilderId.get(builderId).set(filterId, optionList);
    } else {
      const newCollection = new Map([[filterId, optionList]]);

      this._optionListsByBuilderId.set(builderId, newCollection);
    }
  };

  deleteIsNeedToResizeFilters = (builderId: string): void => {
    this._isNeedToResizeFiltersByBuilderId.delete(builderId);
  };

  deleteIsResizeAnimationsActivated = (builderId: string): void => {
    this._isResizeAnimationsActivatedByBuilderId.delete(builderId);
  };

  deleteHeaderConfig = (builderId: string): void => {
    this._headersByBuilderId.delete(builderId);
  };

  deleteRowConfig = (builderId: string): void => {
    this._rowConfigsByBuilderId.delete(builderId);
  };

  deleteSelectedPreset = (
    builderId: string,
    options?: {
      isClearValueList?: boolean;
    }
  ): void => {
    this._selectedPresetsByBuilderId.delete(builderId);

    if (options?.isClearValueList) {
      this.deleteAppliedValueList(builderId, [], { isClearAll: true });
      this.deleteSelectedValueList(builderId, [], { isClearAll: true });
    }
  };

  deleteAppliedValueList = (
    builderId: string,
    filterIdList: (keyof F)[],
    options?: {
      isClearAll?: boolean;
    }
  ): void => {
    if (options?.isClearAll) {
      this._appliedValuesByBuilderId.delete(builderId);
    } else {
      filterIdList.forEach(id => this._appliedValuesByBuilderId.get(builderId)?.delete?.(id));
    }
  };

  deleteAppliedValue = (builderId: string, filterId: keyof F): void => {
    this._appliedValuesByBuilderId.get(builderId)?.delete?.(filterId);
  };

  deleteAppliedValueOption = (builderId: string, filterId: keyof F, optionValue: string): void => {
    const optionList = this._appliedValuesByBuilderId.get(builderId)?.get?.(filterId)
      ?.selectOptionList;

    if (optionList) {
      const withoutTarget = optionList.filter(option => option.value !== optionValue);

      this._appliedValuesByBuilderId.get(builderId).get(filterId).selectOptionList = withoutTarget;

      if (!withoutTarget.length) {
        this.deleteAppliedValue(builderId, filterId);
      }
    }
  };

  deleteAppliedValueOptionList = (
    builderId: string,
    filterId: keyof F,
    optionValueList: string[]
  ): void => {
    const optionList = this._appliedValuesByBuilderId.get(builderId)?.get?.(filterId)
      ?.selectOptionList;

    if (optionList) {
      const withoutTarget = optionList.filter(
        option => !optionValueList.some(optionValue => option.value === optionValue)
      );

      this._appliedValuesByBuilderId.get(builderId).get(filterId).selectOptionList = withoutTarget;

      if (!withoutTarget.length) {
        this.deleteAppliedValue(builderId, filterId);
      }
    }
  };

  deleteSelectedValueList = (
    builderId: string,
    filterIdList: (keyof F)[],
    options?: {
      isClearAll?: boolean;
    }
  ): void => {
    if (options?.isClearAll) {
      this._selectedValuesByBuilderId.delete(builderId);
    } else {
      filterIdList.forEach(id => this._selectedValuesByBuilderId.get(builderId)?.delete?.(id));
    }
  };

  deleteDefaultValueList = (
    builderId: string,
    filterIdList: (keyof F)[],
    options?: {
      isClearAll?: boolean;
    }
  ): void => {
    if (options?.isClearAll) {
      this._defaultValuesByBuilderId.delete(builderId);
    } else {
      filterIdList.forEach(id => this._defaultValuesByBuilderId.get(builderId)?.delete?.(id));
    }
  };

  deleteSelectedValueOption = (builderId: string, filterId: keyof F, optionValue: string): void => {
    const optionList = this._selectedValuesByBuilderId.get(builderId)?.get?.(filterId)
      ?.selectOptionList;

    if (optionList) {
      const withoutTarget = optionList.filter(option => option.value !== optionValue);

      this._selectedValuesByBuilderId.get(builderId).get(filterId).selectOptionList = withoutTarget;
    }
  };

  deletePresets = (builderId: string): void => {
    this._presetsByBuilderId.delete(builderId);
  };

  deleteFilters = (builderId: string): void => {
    this._filtersByBuilderId.delete(builderId);
  };

  deleteSelectOptionList = (builderId: string): void => {
    this._optionListsByBuilderId.delete(builderId);
  };
}

export default TableFiltersBuilderStore;
