import { action, makeAutoObservable, runInAction } from 'mobx';

import { UserInfoType } from '../../../../../api/models/user.model';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { PersonalDataType } from '../types/personalData';
import { Axios, TypeApiResponse } from '../../../../shared/utils/axios2';

import { ExpertiseType } from './EditExpertiseStore';

export const defaultErrorsFields = {
  firstName: '',
  secondName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',
};

@provide.singleton()
export class ProfileStore {
  @lazyInject(Axios)
  protected axios2: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  user: UserInfoType | null = null;
  fullName = '';
  personalDataItems: PersonalDataType[] = [];
  competentionsList: string[] = [];
  collapsedWelcomeWidget = false;
  expertises: Array<ExpertiseType> = [];

  isLoadingBySeasonFieldUrl = false;

  private _isLoaded = false;

  // app settings

  login = '';

  pendingContracts: TypeApiResponse<'getPendingContracts'> = {
    emails: [],
    phoneNumbers: [],
  };

  interests: Array<any>;

  errorsFields = defaultErrorsFields;

  avatarHash = '';

  avatarFile: File;

  get isLoaded() {
    return this._isLoaded;
  }

  setIsLoaded = (state: boolean) => {
    this._isLoaded = state;
  };
}
