import { FC, useEffect, useMemo, useState } from 'react';
import { Button, CalendarComponent, Checkbox, Dropdown, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import moment from 'moment';

import { ModalComponent } from '../../../../../../components/Modal/Modal';
import { useStore } from '../../../../../shared/utils/IoC';
import { CreateSeasonStore } from '../../stores/create.season.store';
import ErrorImage from '../../../../../shared/assets/images/cancel.png';
import { EApiErrorTitle } from '../../../../../shared/utils/axios2';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsController } from '../../../../controllers/seasons/seasons.controller';
import { CreateSeasonController } from '../../controllers/create.season.controller';
import { SidebarController } from '../../../../components/Sidebar/sidebar.controller';
import { TableFiltersBuilderController } from '../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { TDropdownConfig } from '../../../../../shared/components/inputs/Dropdown/Dropdown.types';

import MarkSvg from './../../static/label.svg';
import {
  Title,
  Line,
  SeasonStubSelectorWrapper,
  CalendarWrapper,
  CalendarRow,
  BottomLine,
  CopySeasonWrapper,
  ButtonsWrapper,
  CopySeasonSelectWrapper,
  MarkOkImg,
  LabelTitle,
  ButtonActionWrapper,
  ErrorImg,
} from './style';

type CreateSeasonModalProps = {
  closeModal: () => void;
};

export const CreateSeasonModal: FC<CreateSeasonModalProps> = observer(() => {
  const store = useStore(CreateSeasonStore);
  const seasonsController = useStore(SeasonsController);
  const organizations = useStore(OrganizationsStore);
  const createSeasonController = useStore(CreateSeasonController);
  const { setActiveDropdown } = useStore(SidebarController);
  const { closeModal } = useModal();

  const [isSecondStep, setIsSecondStep] = useState(false);

  const [isSeasonCreateError, setIsSeasonCreateError] = useState(false);

  const {
    stubYearsOptions,
    copyYearsOptions,
    selectStubYear,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateBorders,
    selectedStubYear,
    isButtonAvailable,
    setCopyYear,
    clear,
    clearCopyYear,
    isActiveCopyCheckbox,
  } = store;

  const { saveSeason } = createSeasonController;
  const tableFiltersBuilderController = useStore(TableFiltersBuilderController);

  const [isCopyAvailable, setIsCopyAvailable] = useState<boolean>(false);

  const startDateValue = useMemo(() => {
    return startDate ? startDate.format('DD.MM.YYYY') : '';
  }, [startDate]);

  const endDateValue = useMemo(() => {
    return endDate ? endDate.format('DD.MM.YYYY') : '';
  }, [endDate]);

  useEffect(() => {
    store.fetchStubSeasons();

    store.fetchSeasonsForCopy({
      organizationId:
        (organizations.selectedOrganizationId !== 'my' && organizations.selectedOrganizationId) ||
        undefined,
      // startYear: 2010,
      // endYear: 2040,
    });

    return () => {
      clear();
    };
  }, []);

  const handleSave = async () => {
    try {
      await saveSeason();
      /**
       * При смене сезона очищаем сохраненные данные фильтров в разделе "задачи" [H15-3546].
       */
      tableFiltersBuilderController.clearAppliedValuesInSessionStorage('tasks');
    } catch (e) {
      if (e.message === EApiErrorTitle.REF_EXISTS) {
        setIsSeasonCreateError(true);
      }

      return;
    }
    setIsSecondStep(true);
  };

  const handleFinish = async () => {
    await seasonsController.fetchMySeasons();
    await seasonsController.setSelectedSeason(selectedStubYear);
    closeModal();
    setActiveDropdown(null);
  };

  const handleIsCopyAvailable = (): void => {
    clearCopyYear();
    setIsCopyAvailable(!isCopyAvailable);
  };

  const selectDropdownConfig: TDropdownConfig = {
    field: {
      onChange: value => selectStubYear(value),
      placeholder: 'Сезон',
      isDisabled: false,
    },
    visual: {
      label: 'Выберите сезон для добавления',
    },
    body: {
      optionList: stubYearsOptions,
    },
    other: {
      dataTestId: 'season-stub-select',
    },
  };

  const copyDropdownConfig: TDropdownConfig = {
    field: {
      onChange: value => setCopyYear(value),
      placeholder: 'Сезон',
      isDisabled: false,
    },
    visual: {
      label: 'Выберите сезон для добавления',
    },
    body: {
      optionList: copyYearsOptions.filter(({ fieldsCount }) => fieldsCount > 0),
    },
    other: {
      dataTestId: 'season-copy-stub-select',
    },
  };

  if (isSecondStep) {
    const yearsLabel = `${Number(selectedStubYear) - 1} - ${selectedStubYear}`;
    return (
      <ModalComponent
        paddings={'default'}
        isVerticalCentered={true}
        data-test-id={'season-add-modal-success'}
      >
        <MarkOkImg src={MarkSvg} />
        <LabelTitle data-test-id={'season-add-modal-label'}>
          Новый сезон «{yearsLabel} гг.»
          <br />
          успешно добавлен
        </LabelTitle>
        <ButtonActionWrapper>
          <Button
            color={'primary'}
            type={'button'}
            onClick={handleFinish}
            data-test-id={'season-add-modal-success-continue'}
          >
            Продолжить
          </Button>
        </ButtonActionWrapper>
      </ModalComponent>
    );
  }

  if (isSeasonCreateError) {
    const yearsLabel = `${Number(selectedStubYear) - 1} - ${selectedStubYear}`;
    return (
      <ModalComponent
        paddings={'default'}
        isVerticalCentered={true}
        data-test-id={'season-add-modal-error'}
      >
        <ErrorImg src={ErrorImage} data-test-id={'season-add-modal-already-created-img'} />
        <LabelTitle data-test-id={'season-add-modal-already-created'}>
          Сезон «{yearsLabel} гг.» уже создан
          <br />в организации «{organizations.currentOrganization.name}» !
        </LabelTitle>
        <ButtonActionWrapper>
          <Button
            color={'primary'}
            type={'button'}
            onClick={() => closeModal()}
            data-test-id={'season-add-modal-error-continue'}
          >
            Продолжить
          </Button>
        </ButtonActionWrapper>
      </ModalComponent>
    );
  }

  return (
    <ModalComponent paddings={'seasons'} data-test-id={'season-add-modal-new'}>
      <Title data-test-id={'season-add-modal-new-title'}>Добавление нового сезона</Title>
      <Line />
      <SeasonStubSelectorWrapper>
        <Dropdown config={selectDropdownConfig} />
      </SeasonStubSelectorWrapper>
      <CalendarRow>
        <CalendarWrapper>
          <CalendarComponent
            label={'Начало сезона'}
            value={startDateValue}
            defaultValue={startDateValue}
            onChange={(value: Date) => setStartDate(moment(value))}
            placeholder={'Укажите дату'}
            datePickerOptions={{
              dateRange: {
                minDate: dateBorders.start.min?.toDate(),
                maxDate: (endDate
                  ? moment(endDate).subtract(1, 'd')
                  : dateBorders.start.max
                )?.toDate(),
              },
            }}
            dataTestId={'season-start-date'}
            isBlocked={!selectedStubYear}
            isDisabled={!selectedStubYear}
            readOnly={!selectedStubYear}
            error={''}
            isCloseOnChange={true}
          />
        </CalendarWrapper>
        <CalendarWrapper>
          <CalendarComponent
            label={'Конец сезона'}
            value={endDateValue}
            defaultValue={endDateValue}
            onChange={(value: Date) => setEndDate(moment(value))}
            placeholder={'Укажите дату'}
            datePickerOptions={{
              dateRange: {
                minDate: (startDate && startDate.year() < dateBorders.end.max.year()
                  ? moment(dateBorders.end.min).add(1, 'y')
                  : moment(startDate).add(1, 'd')
                )?.toDate(),

                maxDate: dateBorders.end.max?.toDate(),
              },
            }}
            dataTestId={'season-end-date'}
            isBlocked={!selectedStubYear}
            isDisabled={!selectedStubYear}
            readOnly={!selectedStubYear}
            error={''}
            isCloseOnChange={true}
          />
        </CalendarWrapper>
      </CalendarRow>
      <BottomLine />
      <CopySeasonWrapper>
        <Checkbox
          label={'Копировать поля из другого сезона'}
          onChange={handleIsCopyAvailable}
          value={isCopyAvailable}
          disable={!Boolean(copyYearsOptions.length)}
          dataTestId={'copy-fields-other-season'}
        />
        {isCopyAvailable && (
          <CopySeasonSelectWrapper>
            <Dropdown config={copyDropdownConfig} />
          </CopySeasonSelectWrapper>
        )}
      </CopySeasonWrapper>
      <ButtonsWrapper>
        <Button
          color={'default'}
          type={'button'}
          onClick={() => closeModal()}
          dataTestId={'season-cancel-btn'}
        >
          Отменить
        </Button>
        <Button
          color={'primary'}
          type={'button'}
          disabled={isCopyAvailable ? !isActiveCopyCheckbox : !isButtonAvailable}
          onClick={handleSave}
          dataTestId={'season-save-btn'}
        >
          Сохранить
        </Button>
      </ButtonsWrapper>
    </ModalComponent>
  );
});
