import { Input } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import { ReactComponent as MapSvg } from '../../static/creation-map-mock.svg';
import { toFixedWithCeilBackEnd } from '../../../../../shared/utils/toFixedWithCeil';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../../components/AccessRulesWrapper/ControlAccessRulesWrapper';

import {
  Line,
  Wrapper,
  ContentWrapper,
  MapIcon,
  RightColumn,
  AreaLabel,
  BottomSectionWrapper,
  Delete,
  MapImage,
} from './style';

type CreationFieldItemProps = {
  onNameChange: (v: string) => void;
  name: string;
  onDelete: () => void;
  area: number;
  image?: string;
};

export const CreationFieldItem: FC<CreationFieldItemProps> = memo(
  ({ onNameChange, name, onDelete, area, image }) => {
    return (
      <Wrapper data-test-id={'fields-creation-props-wrapper'}>
        <ContentWrapper>
          {image ? (
            <MapImage src={image} data-test-id={'fields-creation-map-image'} />
          ) : (
            <MapIcon data-test-id={'fields-creation-map-icon'}>
              <MapSvg data-test-id={'fields-creation-map-svg'} />
            </MapIcon>
          )}

          <RightColumn data-test-id={'fields-creation-right-column'}>
            <Input onChange={onNameChange} value={name} data-test-id={'fields-creation-name'} />
            <BottomSectionWrapper data-test-id={'fields-creation-bottom-section-wrapper'}>
              <AreaLabel isError={area > 1000} data-test-id={'fields-creation-props-area'}>
                {area > 1000
                  ? 'Площадь более 1000 га'
                  : `Площадь: ${toFixedWithCeilBackEnd(area)} га`}
              </AreaLabel>
              <Delete>
                <ControlAccessRulesWrapper
                  mode={AccessRuleVisibility.Hide}
                  actionName="field.deleteField"
                >
                  <div onClick={onDelete} data-test-id={'fields-creation-props-delete'}>
                    Удалить
                  </div>
                </ControlAccessRulesWrapper>
              </Delete>
            </BottomSectionWrapper>
          </RightColumn>
        </ContentWrapper>

        <Line />
      </Wrapper>
    );
  }
);
