import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { CreateSeasonModal } from './CreateSeasonModal';

const CREATE_SEASON_MODAL_ID = 'create-season-modal-id';

const createSeasonModalConfig: TModalConfig = {
  id: CREATE_SEASON_MODAL_ID,
  name: CREATE_SEASON_MODAL_ID,
  title: null,
  type: EModalType.Custom,
  children: CreateSeasonModal,
  dataTestId: 'season-add-modal-error',
};

export { createSeasonModalConfig, CREATE_SEASON_MODAL_ID };
