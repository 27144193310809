import moment from 'moment';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { TaskStore } from '../../stores';
import { TaskDefaultValuesService } from '../TaskDefaultValuesService';
import { ITaskCreate } from '../../../../../../../api/models/as-fields/task/task.model';

@provide.transient()
class TaskChangesService {
  @lazyInject(TaskDefaultValuesService)
  protected defaultValuesService: TaskDefaultValuesService;

  @lazyInject(TaskStore)
  protected taskStore: TaskStore;

  changePlanStartDate = (planStartDate: string): void => {
    switch (this.taskStore.mode) {
      case 'create-one':
      case 'create-multiple':
        this.changePlanStartDateForCreate(planStartDate);
        break;
      case 'edit':
        this.changePlanStartDateForEdit(planStartDate);
        break;
      default:
    }
  };

  changePlanEndDate = (planEndDate: string): void => {
    switch (this.taskStore.mode) {
      case 'create-one':
      case 'create-multiple':
        this.changePlanEndDateForCreate(planEndDate);
        break;
      case 'edit':
        this.changePlanEndDateForEdit(planEndDate);
        break;
      default:
    }
  };

  changeTaskCreateData: TaskStore['setTaskCreateData'] = data => {
    this.taskStore.setTaskCreateData(data);
    this.taskStore.setIsFormChanged(true);
  };

  protected getPlanStartDateToChange = (planEndDate: string): string => {
    const momentStartDate = moment(this.defaultValuesService.planStartDate, 'DD.MM.YYYY');
    const momentEndDate = moment(planEndDate);

    if (momentEndDate.isBefore(momentStartDate)) {
      return planEndDate;
    }
  };

  protected getPlanEndDateToChange = (planStartDate: string): string => {
    const momentStartDate = moment(planStartDate);
    const momentEndDate = moment(this.defaultValuesService.planEndDate, 'DD.MM.YYYY');

    if (momentStartDate.isAfter(momentEndDate)) {
      return planStartDate;
    }
  };

  protected changePlanStartDateForCreate = (planStartDate: string): void => {
    const planEndDate = this.getPlanEndDateToChange(planStartDate);

    const data: Partial<ITaskCreate> = {
      planStartDate,
    };

    if (planEndDate) {
      data.planEndDate = planEndDate;
    }

    this.changeTaskCreateData(data);
  };

  protected changePlanStartDateForEdit = (planStartDate: string): void => {
    const planEndDate = this.getPlanEndDateToChange(planStartDate);

    const data: Partial<ITaskCreate> = {
      planStartDate,
    };

    if (planEndDate) {
      data.planEndDate = planEndDate;
    }

    this.taskStore.setTaskUpdateData(data);
  };

  protected changePlanEndDateForCreate = (planEndDate: string): void => {
    const planStartDate = this.getPlanStartDateToChange(planEndDate);

    const data: Partial<ITaskCreate> = {
      planEndDate,
    };

    if (planStartDate) {
      data.planStartDate = planStartDate;
    }

    this.changeTaskCreateData(data);
  };

  protected changePlanEndDateForEdit = (planEndDate: string): void => {
    const planStartDate = this.getPlanStartDateToChange(planEndDate);

    const data: Partial<ITaskCreate> = {
      planEndDate,
    };

    if (planStartDate) {
      data.planStartDate = planStartDate;
    }

    this.taskStore.setTaskUpdateData(data);
  };
}

export default TaskChangesService;
