import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { Colors, Icon, IconButton, Typography } from '@farmlink/farmik-ui';
import { useDebouncedCallback } from 'use-debounce';

import { useStore } from '../../../../../../../../utils/IoC';
import { IndicesStore } from '../../mobx';
import { IndexModel } from '../../../../../../../../../../api/models/indices.model';
import { formatDateToNameString } from '../../../../../../../../utils/formatDateToNameString';

import Styled from './Timeline.styles';
import { YearSelector } from './components';

interface IProps {
  arrayIndex: number;
  yearList?: number[];
  indicesList?: IndexModel[];
  selectIndex?: (index: IndexModel, arrayIndex: number) => void;
  refetchData?: () => any;
}

const arrowColorScheme = {
  default: { background: 'transparent', icon: Colors.generalDark },
  hover: { background: 'transparent', icon: Colors.green },
  disabled: { background: 'transparent', icon: Colors.darkGrey },
};

const ITEM_WIDTH = 108;

/**
 * Так как список `indicesList` реверснут - логика линейного смещения тоже обратная
 */

const Timeline: FC<IProps> = ({ yearList, indicesList, selectIndex, refetchData, arrayIndex }) => {
  const store = useStore(IndicesStore);

  const ref = useRef<HTMLLIElement>(null);

  /**
   * publicId являющиеся крайними значениями в списке
   */
  const extremesPublicId = useMemo<{ min: string; max: string }>(() => {
    if (!indicesList) {
      return { max: null, min: null };
    }

    return { min: indicesList[0]?.publicId, max: indicesList[indicesList.length - 1]?.publicId };
  }, [indicesList]);

  const isSelected = useCallback((publicId: string) => store.selectedIndex?.publicId === publicId, [
    store.selectedIndex,
  ]);

  const isNoData = !Boolean(indicesList?.length);

  const setIndex = (index: number) => {
    store.setSelectedIndex(indicesList[index], index);
    selectIndex?.(indicesList[index], index);
  };

  const onYearChange = (year: number) => {
    store.setCurrentYear(year);
    store.clearSelectedIndex();
  };

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [ref.current, store?.selectedIndexIndex]);

  const debouncedRefetch = useDebouncedCallback(() => refetchData(), 1000);

  return (
    <Styled.Wrapper>
      {yearList?.length ? (
        <YearSelector
          options={yearList}
          onYearChange={onYearChange}
          defaultValue={store?.currentYear}
        />
      ) : null}

      {!isNoData ? (
        <Styled.List>
          {indicesList?.map((item, i) => {
            return (
              <Styled.Item
                key={item.publicId}
                onClick={() => setIndex(i)}
                data-test-id={`map-timeline-item-${i}`}
                data-test-date={item.fromDate}
                $isSelected={isSelected(item.publicId)}
                $width={ITEM_WIDTH}
                {...(isSelected(item.publicId) && { ref })}
              >
                <Typography align="center" variant="body">
                  {formatDateToNameString(item.fromDate, true)}
                </Typography>
              </Styled.Item>
            );
          })}
        </Styled.List>
      ) : (
        <Styled.NoData>
          <Styled.NoDataGroup>
            <Icon icon="warning_blob" dataTestId="map-timeline-no-data" size="medium" />
            <Typography variant="body" className="map-timeline-no-data-text">
              Мы уже обрабатываем снимки, но нам ещё нужно время
            </Typography>
          </Styled.NoDataGroup>
          <Styled.ReloadButton onClick={() => debouncedRefetch()}>Обновить</Styled.ReloadButton>
        </Styled.NoData>
      )}

      {!isNoData ? (
        <Styled.Controls>
          <IconButton
            icon="arrow_left"
            colorScheme={arrowColorScheme}
            size={20}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setIndex(arrayIndex + 1);
            }}
            $isDisabled={store.selectedIndex?.publicId === extremesPublicId.max}
          />
          <IconButton
            icon="arrow_right"
            colorScheme={arrowColorScheme}
            size={20}
            onClick={() => setIndex(arrayIndex - 1)}
            $isDisabled={store.selectedIndex?.publicId === extremesPublicId.min}
          />
        </Styled.Controls>
      ) : null}
    </Styled.Wrapper>
  );
};

export default observer(Timeline);
