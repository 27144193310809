import { Colors } from '@farmlink/farmik-ui';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ selected: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 134px;
  height: 56px;
  background-color: #ffffff;
  padding: 12px 10px;
  border: 1px solid ${({ selected }) => (selected ? `#3FB65F` : `#E9E6F0`)};
  border-radius: 12px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  transition: background-color 0.3s ease-in-out;

  :hover {
    background-color: ${Colors.secondaryGray};

    ${({ selected }) =>
      selected &&
      css`
        background-color: ${Colors.generalWhite};
        border-color: ${Colors.hoverGreen};
      `}
  }
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 8px;
  overflow: hidden;
  max-width: 72px;
`;

export const CardTitle = styled.div`
  max-width: 82px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #151f32;
  max-width: inherit;
`;

export const InstanceCoordType = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9aa0ad;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: inherit;
`;

export const CardsListBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 10px;
  margin-top: 20px;
  height: 60px;
`;

export const PointImg = styled.img`
  height: 32px;
  width: 32px;
`;
