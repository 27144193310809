import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide } from '../../shared/utils/IoC';
import { Axios } from '../../shared/utils/axios2';
import { AccessRule } from '../../../api/api';
import { ACCESS_ACTIONS_MODULES } from '../../shared/constants/access-rules-action';

@provide.singleton()
export class CheckAccessStore {
  @lazyInject(Axios)
  protected axios: Axios;

  accessRules: Array<AccessRule> = [];
  newAccessRules: Array<AccessRule> = [];
  accessRulesReady = false;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;

  clearAccessRules = () => {
    this.accessRulesReady = false;
    this.accessRules = [];
    this.newAccessRules = [];
  };

  getAllAccessRulesByModule = async (organizationId: string) => {
    this.accessRulesReady = false;
    /* eslint-disable no-await-in-loop */
    for (const moduleCode of ACCESS_ACTIONS_MODULES) {
      await this.axios.api.checkAccessByModule({ organizationId, moduleCode }).then(response => {
        runInAction(() => {
          this.newAccessRules = this.newAccessRules.concat(response);
          if (ACCESS_ACTIONS_MODULES.indexOf(moduleCode) == ACCESS_ACTIONS_MODULES.length - 1) {
            this.accessRules = this.newAccessRules;
            this.loading = false;
          }
        });
      });
    }
    this.accessRulesReady = true;
    /* eslint-enable no-await-in-loop */
  };

  getAccessRulesByCodePrefix = (organizationId: string, codePrefix?: string) => {
    this.loading = true;
    this.axios.api.checkAccessByCodePrefix({ organizationId, codePrefix }).then(response => {
      runInAction(() => {
        this.accessRules = this.accessRules.concat(response);
        this.loading = false;
      });
    });
  };

  getAccessRuleValue = (code: string) => {
    return this.accessRules.find(rule => rule.code === code)?.available || false;
  };
}
