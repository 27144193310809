import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  organizationId?: string;
  year: number;
  startDate: string;
  endDate: string;
  copyFieldsFromYear?: number;
};

type TypeResponse = string;

export const createSeason: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/seasons/create`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
