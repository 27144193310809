import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { ProfileController } from '../../profile/controllers/profile.controller';
import { CreateSeasonStore } from '../stores/create.season.store';
import { EAppSettingsType } from '../../../../..';

@provide.singleton()
export class CreateSeasonController {
  @lazyInject(CreateSeasonStore)
  protected createSeasonStore: CreateSeasonStore;

  @lazyInject(OrganizationsStore)
  protected organizationStore: OrganizationsStore;

  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(ProfileController)
  protected profileController: ProfileController;

  saveSeason = async () => {
    let response: TypeApiResponse<'createSeason'>;

    let payload: TypeApiRequest<'createSeason'> = {
      year: Number(this.createSeasonStore.selectedStubYear),
      endDate: this.createSeasonStore.endDate.format('YYYY-MM-DD'),
      startDate: this.createSeasonStore.startDate.format('YYYY-MM-DD'),
    };

    if (this.organizationStore.selectedOrganizationId !== 'my') {
      payload = { ...payload, organizationId: this.organizationStore.selectedOrganizationId };
    }

    if (this.createSeasonStore.copyYear) {
      payload.copyFieldsFromYear = Number(this.createSeasonStore.copyYear);
    }

    try {
      response = await this.axios.api.createSeason(payload);

      this.profileController.setAppSettings(EAppSettingsType.Season)(payload.year.toString());
    } catch (e) {
      console.log(e);
      throw new Error(e.response.data.errors[0]?.title);
    }

    return response;
  };
}
