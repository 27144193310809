import { TypeApiRoute } from '../../models/type.api.request';
import { ITask } from '../../models/as-fields/task/task.model';

type GetTaskReq = {
  id: string;
};

type TypeResponse = ITask;

export const getTask: TypeApiRoute & {
  request: GetTaskReq;
  response: TypeResponse;
} = {
  url: ({ id }) => `/api/as-fields/task/${id}`,
  method: 'GET',
  headers: {},
  request: {} as GetTaskReq,
  response: {} as TypeResponse,
};

export type { GetTaskReq };
