import { makeAutoObservable } from 'mobx';
import { isArray } from 'lodash';

import {
  IChecklistAttributeFileValue,
  IGetChecklistAttribute,
  IGetChecklistAttributeEnumValue,
  IGetChecklistAttributeUserDictionary,
  IPutChecklistAttributeValue,
} from '../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { IGetChecklist } from '../../../../../api/models/checklist/checklist.model';
import {
  IDrawChecklistInstance,
  IDrawingNestedInstance,
  IGetChecklistInstance,
} from '../../../../../api/models/checklist/instance/checklist.instance.model';
import { IGetIntensity } from '../../../../../api/models/intensity/intensity.model';
import { ISelectOption } from '../../../../../types/selectOption';
import { provide } from '../../../../shared/utils/IoC';
import {
  IChecklistDrawingStage,
  IGetChecklistStage,
} from '../../../../../api/models/checklist/stage/checklist.stage.model';
import { IGetDictionary } from '../../../../../api/models/dictionary/dictionary.model';
import { Task } from '../../operations/stores/tasks.store';
import { sortBy } from '../../../../shared/features/utils/helpers/sort';
import { IChecklistInstanceEvent } from '../../../../../api/models/checklist/instance/events/checklist.instance.event.model';

export enum EChecklistMode {
  View = 'VIEW',
  Edit = 'EDIT',
}

@provide.singleton()
export class ChecklistInstancesStore {
  /** TODO: описать комментариями принадлежность ниже значений
   * (или вынести в контроллер подключив соответствующий стор) */
  hasPositionToInstanceChanged = false;
  taskId: string;

  checklistAttributeId: string;

  private _isFormChanged = false;

  // Ниже располагаются выбранные значения чек-листа

  private _selectedInstance: IGetChecklistInstance | null = null;

  private _selectedInstId: string | null = null;

  private _selectedIntensity: IGetIntensity | null = null;

  private _selectedInstanceEventList: IChecklistInstanceEvent[] = [];

  /**
   * Показывает какая запись в этот момент редактируется или заполняется
   * Требуется для отслеживания незаполненных атрибутов/записей при сохранении
   */
  private _editableDrawingNestedInstance: IDrawingNestedInstance | null = null;

  /**
   * Показывает состояние редактируемой записи до всех изменений с ней
   */
  private _previousStateOfEditableDrawingNestedInstance: IDrawingNestedInstance | null = null;

  /**
   * Отслеживает результат выбора в модальном окне фенофаз. Необходим для того, чтобы
   * понимать, когда выбирать фенофазу, а когда нет.
   */
  private _intensityModalResult: boolean | null = null;

  // Требуется для контроля за несохраненными данными пользователя
  private _idOfUnsavedAttr: string | null = null;

  // Ниже располагаются коллекции данных

  private _idToChecklist = new Map<string, IGetChecklist>();

  private _idToExtendedInstance = new Map<string, IGetChecklistInstance>();

  private _checklistIdToStageList = new Map<string, IGetChecklistStage[]>();

  private _checklistIdToAttributeList = new Map<string, IGetChecklistAttribute[]>();

  private _idToDrawPointInst: Map<string, IDrawChecklistInstance> = new Map<
    string,
    IDrawChecklistInstance
  >();

  private _idToDrawMachineryInst: Map<string, IDrawChecklistInstance> = new Map<
    string,
    IDrawChecklistInstance
  >();

  private _idToIntensity = new Map<string, IGetIntensity>();

  // [ИСПОЛЬЗУЕТСЯ ДЛЯ ОТРИСОВКИ] Хранит stages, что непосредственно отображаются на странице
  private _idToDrawingStage = new Map<string, IChecklistDrawingStage>();

  private _idToDrawingNestedInstance: Map<string, IDrawingNestedInstance> = new Map<
    string,
    IDrawingNestedInstance
  >();

  // Коллекция из записей, которые в данный момент редактируются
  private _attrIdToEditNestedInst: Map<string, IDrawingNestedInstance> = new Map<
    string,
    IDrawingNestedInstance
  >();

  private _attrIdToTemplateNestedInstance = new Map<string, IDrawingNestedInstance>();

  private _attributeIdToEnumList = new Map<string, IGetChecklistAttributeEnumValue[]>();

  private _attrIdToFileList = new Map<string, IChecklistAttributeFileValue[]>();

  private _attributeIdToUserDictionaryList = new Map<
    string,
    IGetChecklistAttributeUserDictionary[]
  >();

  private _attrIdToDictionaryList = new Map<string, IGetDictionary[]>();

  private _stageIdToMapFromDepAttrValues: Map<
    string,
    Map<string, IPutChecklistAttributeValue>
  > = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  // Геттеры

  get isFormChanged() {
    return this._isFormChanged;
  }

  get selectedInstance() {
    return this._selectedInstance;
  }

  get selectedIntensity() {
    return this._selectedIntensity;
  }

  get editableDrawingNestedInstance() {
    return this._editableDrawingNestedInstance;
  }

  get previousStateOfEditableDrawingNestedInstance() {
    return this._previousStateOfEditableDrawingNestedInstance;
  }

  get intensityModalResult() {
    return this._intensityModalResult;
  }

  get checklistList(): IGetChecklist[] {
    return Array.from(this._idToChecklist.values());
  }

  getChecklist = (id: string): IGetChecklist => {
    return this._idToChecklist.get(id);
  };

  getExtendedInstance = (id: string): IGetChecklistInstance => {
    return this._idToExtendedInstance.get(id);
  };

  get intensityList(): IGetIntensity[] {
    return Array.from(this._idToIntensity.values());
  }

  getStageList = (checklistId: string): IGetChecklistStage[] => {
    return this._checklistIdToStageList.get(checklistId);
  };

  getAttributeList = (checklistId: string): IGetChecklistAttribute[] => {
    return this._checklistIdToAttributeList.get(checklistId);
  };

  get drawingStageList(): IChecklistDrawingStage[] {
    return Array.from(this._idToDrawingStage.values());
  }

  getDrawingStage = (id: string): IChecklistDrawingStage => this._idToDrawingStage.get(id);

  get drawingNestedInstanceList(): IDrawingNestedInstance[] {
    return Array.from(this._idToDrawingNestedInstance.values());
  }

  getDrawingNestedInstance = (instanceId: string): IDrawingNestedInstance => {
    return this._idToDrawingNestedInstance.get(instanceId);
  };

  get listOfEditNestedInst(): IDrawingNestedInstance[] {
    return Array.from(this._attrIdToEditNestedInst.values());
  }

  getEditNestedInst = (attrId: string): IDrawingNestedInstance => {
    return this._attrIdToEditNestedInst.get(attrId);
  };

  getTempNestedInst = (attrId: string): IDrawingNestedInstance => {
    return this._attrIdToTemplateNestedInstance.get(attrId);
  };

  get templateNestedInstanceList(): IDrawingNestedInstance[] {
    return Array.from(this._attrIdToTemplateNestedInstance.values());
  }

  get idOfUnsavedAttr() {
    return this._idOfUnsavedAttr;
  }

  get enumList(): IGetChecklistAttributeEnumValue[] {
    const _enumList: IGetChecklistAttributeEnumValue[] = [];

    Array.from(this._attributeIdToEnumList.values()).forEach(valueList =>
      _enumList.push(...valueList)
    );

    return _enumList;
  }

  get userDictionaryList(): IGetChecklistAttributeUserDictionary[] {
    const _userDictionaryList: IGetChecklistAttributeUserDictionary[] = [];

    Array.from(this._attributeIdToUserDictionaryList.values()).forEach(valueList =>
      _userDictionaryList.push(...valueList)
    );

    return _userDictionaryList;
  }

  get dictionaryList(): IGetDictionary[] {
    const _dictionaryList: IGetDictionary[] = [];

    Array.from(this._attrIdToDictionaryList.values()).forEach(valueList =>
      _dictionaryList.push(...valueList)
    );

    return _dictionaryList;
  }

  getAttributeEnumList = (attributeId: string): IGetChecklistAttributeEnumValue[] => {
    return this._attributeIdToEnumList.get(attributeId);
  };

  getUserDictionaryList = (attributeId: string): ISelectOption[] => {
    return (
      this._attributeIdToUserDictionaryList
        .get(attributeId)
        ?.map<ISelectOption>(({ id, value }) => ({ label: value, value: id })) || []
    );
  };

  getDictionaryOptionListByAttrId = (attributeId: string): ISelectOption[] => {
    return this._attrIdToDictionaryList
      .get(attributeId)
      ?.map<ISelectOption>(({ id, name }) => ({ label: name, value: id }));
  };

  get selectedInstId() {
    return this._selectedInstId;
  }

  get allDepAttrValList(): IPutChecklistAttributeValue[] {
    const collectionList = [...this._stageIdToMapFromDepAttrValues.values()];

    return collectionList.flatMap(collection => [...collection.values()]);
  }

  getDepAttrValue = (stageId: string, attrId: string): IPutChecklistAttributeValue => {
    return this._stageIdToMapFromDepAttrValues.get(stageId)?.get?.(attrId);
  };

  get drawInstanceList(): IDrawChecklistInstance[] {
    return [...this.listOfDrawPointInst, ...this.listOfDrawMachineryInst];
  }

  get listOfDrawPointInst(): IDrawChecklistInstance[] {
    return Array.from(this._idToDrawPointInst.values());
  }

  get listOfDrawMachineryInst(): IDrawChecklistInstance[] {
    return Array.from(this._idToDrawMachineryInst.values());
  }

  getDrawInst = (id: string): IDrawChecklistInstance => {
    const drawPointInst = this._idToDrawPointInst.get(id);

    if (drawPointInst) {
      return drawPointInst;
    }

    const drawMachineryInst = this._idToDrawMachineryInst.get(id);

    if (drawMachineryInst) {
      return drawMachineryInst;
    }
  };

  getTheBiggestDrawInstPosNum = (): number => {
    const drawInstList = this.listOfDrawPointInst;

    const theBiggestPositionNumber = drawInstList.map<number>(
      ({ positionNumber }) => positionNumber
    );

    if (theBiggestPositionNumber.length) {
      return Math.max(...theBiggestPositionNumber);
    }

    return 0;
  };

  get drawInstWithTheLowestPosNum(): IDrawChecklistInstance {
    const drawInstList = [...this.listOfDrawPointInst, ...this.listOfDrawMachineryInst];

    const [firstInstance] = sortBy(drawInstList, 'positionNumber');

    return firstInstance;
  }

  get selectedInstanceEventList() {
    return this._selectedInstanceEventList;
  }

  // Сеттеры

  setIsFormChanged = (value: boolean): void => {
    this._isFormChanged = value;
  };

  setSelectedIntensity = (intensity: IGetIntensity): void => {
    this._selectedIntensity = intensity;
  };

  setSelectedInstance = (instance: IGetChecklistInstance): void => {
    this._selectedInstance = instance;
  };

  setEditableDrawingNestedInstance = (drawingNestedInstance: IDrawingNestedInstance): void => {
    this._editableDrawingNestedInstance = drawingNestedInstance;
  };

  setPreviousStateOfEditableDrawingNestedInstance = (
    drawingNestedInstance: IDrawingNestedInstance
  ): void => {
    this._previousStateOfEditableDrawingNestedInstance = drawingNestedInstance;
  };

  setIntensityModalResult = (result: boolean): void => {
    this._intensityModalResult = result;
  };

  setExtendedInstance = (instance: IGetChecklistInstance): void => {
    this._idToExtendedInstance.set(instance.id, instance);
  };

  setIdToIntensity = (id: string, intensity: IGetIntensity): void => {
    this._idToIntensity.set(id, intensity);
  };

  setStageList = (checklistId: string, stageList: IGetChecklistStage[]): void => {
    this._checklistIdToStageList.set(checklistId, stageList);
  };

  setAttributeList = (checklistId: string, attributeList: IGetChecklistAttribute[]): void => {
    this._checklistIdToAttributeList.set(checklistId, attributeList);
  };

  setIdToDrawingStage = (idToDrawingStage: Map<string, IChecklistDrawingStage>): void => {
    this._idToDrawingStage = idToDrawingStage;
  };

  setDrawingStage = (id: string, drawingStage: IChecklistDrawingStage): void => {
    this._idToDrawingStage.set(id, drawingStage);
  };

  setDrawingNestedInstance = (drawingNestedInstance: IDrawingNestedInstance): void => {
    this._idToDrawingNestedInstance.set(drawingNestedInstance.id, drawingNestedInstance);
  };

  setDrawingNestedInstanceList = (
    instanceList: IDrawingNestedInstance[],
    options?: {
      isCleanSetting?: boolean;
    }
  ): void => {
    const previousEntryList = options?.isCleanSetting
      ? []
      : [...this._idToDrawingNestedInstance.entries()];

    const newCollection: Map<string, IDrawingNestedInstance> = new Map<
      string,
      IDrawingNestedInstance
    >(previousEntryList);

    if (isArray(instanceList)) {
      instanceList.forEach(instance => {
        newCollection.set(instance.id, instance);
      });
    }

    this._idToDrawingNestedInstance = newCollection;
  };

  setEditNestedInst = (inst: IDrawingNestedInstance): void => {
    this._attrIdToEditNestedInst.set(inst.rootChecklistAttribute.id, inst);
  };

  setTemplateNestedInstance = (templateNestedInstance: IDrawingNestedInstance): void => {
    this._attrIdToTemplateNestedInstance.set(
      templateNestedInstance.rootChecklistAttribute.id,
      templateNestedInstance
    );
  };

  deleteTemplateNestedInstance = (attributeId: string): void => {
    this._attrIdToTemplateNestedInstance.delete(attributeId);
  };

  setIdOfUnsavedAttr = (attrId: string): void => {
    this._idOfUnsavedAttr = attrId;
  };

  setEnumList = (attributeId: string, enumList: IGetChecklistAttributeEnumValue[]): void => {
    this._attributeIdToEnumList.set(attributeId, enumList);
  };

  setHasPositionToInstanceChanged = (value: boolean) => {
    this.hasPositionToInstanceChanged = value;
  };

  setFileListByAttrId = (attrId: string, value: IChecklistAttributeFileValue[]): void => {
    this._attrIdToFileList.set(attrId, value);
  };

  setAttributeIdToUserDictionaryList = (
    attributeId: string,
    userDictionaryList: IGetChecklistAttributeUserDictionary[]
  ): void => {
    this._attributeIdToUserDictionaryList.set(attributeId, userDictionaryList);
  };

  setNewUserDictionaryValue = (
    attributeId: string,
    userDictionaryValue: IGetChecklistAttributeUserDictionary
  ): void => {
    const userDictionaryList = this._attributeIdToUserDictionaryList.get(attributeId);

    if (userDictionaryList) {
      userDictionaryList.push(userDictionaryValue);

      this._attributeIdToUserDictionaryList.set(attributeId, userDictionaryList);
    }
  };

  deleteDrawingNestedInstance = (instanceId: string): void => {
    this._idToDrawingNestedInstance.delete(instanceId);
  };

  setDictionaryList = (attributeId: string, dictionaryList: IGetDictionary[]): void => {
    this._attrIdToDictionaryList.set(attributeId, dictionaryList);
  };

  setDepAttrValue = (stageId: string, value: IPutChecklistAttributeValue): void => {
    const collection = this._stageIdToMapFromDepAttrValues.get(stageId);
    const previousEntryList = [...(collection?.entries?.() || [])];

    const newCollection = new Map<string, IPutChecklistAttributeValue>(previousEntryList);
    newCollection.set(value.checkListAttributeId, value);

    this._stageIdToMapFromDepAttrValues.set(stageId, newCollection);
  };

  setIdToDrawInst = (listOfDrawPointInst: IDrawChecklistInstance[] = []): void => {
    const newCol: Map<string, IDrawChecklistInstance> = new Map<string, IDrawChecklistInstance>();

    listOfDrawPointInst.forEach(drawInst => newCol.set(drawInst.id, drawInst));

    this._idToDrawPointInst = newCol;
  };

  setDrawInst = (drawInst: IDrawChecklistInstance): void => {
    this._idToDrawPointInst.set(drawInst.id, drawInst);
  };

  setSelectedInstanceEventList = (eventList: IChecklistInstanceEvent[]) => {
    this._selectedInstanceEventList = eventList;
  };

  delDrawInst = (id: string): void => {
    this._idToDrawPointInst.delete(id);
  };

  // Ниже методы для приведения значений в сторе к дефолтному состоянию

  clearIsFormChanged = (): void => {
    this._isFormChanged = false;
  };

  clearSelectedInstance = (): void => {
    this._selectedInstance = null;
  };

  clearSelectedIntensity = (): void => {
    this._selectedIntensity = null;
  };

  clearEditableDrawingNestedInstance = (): void => {
    this._editableDrawingNestedInstance = null;
  };

  clearPreviousStateOfEditableDrawingNestedInstance = (): void => {
    this._previousStateOfEditableDrawingNestedInstance = null;
  };

  clearIntensityModalResult = (): void => {
    this._intensityModalResult = null;
  };

  clearIdToChecklist = (): void => {
    this._idToChecklist.clear();
  };

  clearIdToExtendedInstance = (): void => {
    this._idToExtendedInstance.clear();
  };

  clearIdToIntensity = (): void => {
    this._idToIntensity.clear();
  };

  clearChecklistIdToAttributeList = (): void => {
    this._checklistIdToAttributeList.clear();
  };

  clearIdOfUnsavedAttr = (): void => {
    this._idOfUnsavedAttr = null;
  };

  clearAttributeIdToEnumList = (): void => {
    this._attributeIdToEnumList.clear();
  };

  clearChecklistIdToStageList = (): void => {
    this._checklistIdToStageList.clear();
  };

  clearIdToDrawingStage = (): void => {
    this._idToDrawingStage.clear();
  };

  clearIdToDrawingNestedInstance = (): void => {
    this._idToDrawingNestedInstance.clear();
  };

  clearAttrIdToEditNestedInst = (): void => {
    this._attrIdToEditNestedInst.clear();
  };

  delEditNestedInst = (attrId: string): void => {
    this._attrIdToEditNestedInst.delete(attrId);
  };

  clearAttrIdToTemplateNestedInstance = (): void => {
    this._attrIdToTemplateNestedInstance.clear();
  };

  clearAttrIdToFileList = () => {
    this._attrIdToFileList.clear();
  };

  clearAttributeIdToUserDictionaryList = (): void => {
    this._attributeIdToUserDictionaryList.clear();
  };

  clearAttrIdToDictionaryList = (): void => {
    this._attrIdToDictionaryList.clear();
  };

  clearSelectedInstId = (): void => {
    this._selectedInstId = null;
  };

  clearStageIdToMapFromDepAttrValues = (): void => {
    this._stageIdToMapFromDepAttrValues.clear();
  };

  clearIdToDrawPointInst = (): void => {
    this._idToDrawPointInst.clear();
  };

  clearIdToDrawMachineryInst = (): void => {
    this._idToDrawMachineryInst.clear();
  };

  clearSelectedInstanceEventList = () => {
    this._selectedInstanceEventList = [];
  };

  clearChecklistInstancesStore = (): void => {
    this.clearIsFormChanged();
    this.clearIdOfUnsavedAttr();

    this.clearSelectedInstance();
    this.clearSelectedIntensity();

    this.clearSelectedInstId();

    this.clearEditableDrawingNestedInstance();
    this.clearPreviousStateOfEditableDrawingNestedInstance();

    this.clearIntensityModalResult();

    this.clearIdToChecklist();
    this.clearIdToExtendedInstance();
    this.clearChecklistIdToStageList();
    this.clearChecklistIdToAttributeList();
    this.clearIdToIntensity();
    this.clearAttrIdToEditNestedInst();
    this.clearIdToDrawPointInst();
    this.clearIdToDrawMachineryInst();

    this.clearAttributeIdToEnumList();
    this.clearAttributeIdToUserDictionaryList();
    this.clearAttrIdToDictionaryList();
    this.clearAttrIdToFileList();
    this.clearStageIdToMapFromDepAttrValues();

    this.clearIdToDrawingStage();
    this.clearIdToDrawingNestedInstance();
    this.clearAttrIdToTemplateNestedInstance();

    this.clearSelectedInstanceEventList();
  };

  clearStrangeProps = (): void => {
    this.checklistAttributeId = '';
    this.clearAttrIdToFileList();
  };
}
