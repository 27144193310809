import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios } from '../../../../utils/axios2';
import { TGetSeasonCulture } from '../../../../../../api/endpoints/seasons/get.season.culture';
import { CultureList } from '../../../../../../api/models/operations/operation.culture.list.model';

@provide.singleton()
class SeasonsService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  getCultureList = async (payload: TGetSeasonCulture): Promise<CultureList> => {
    const { getSeasonCulture } = this.axiosService.api;

    try {
      const seasonCulture = await getSeasonCulture(payload);

      return seasonCulture;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default SeasonsService;
