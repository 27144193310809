import { EModalType, IModal } from '../../../../modals/components/Modal/Modal';
import { IModalConfig } from '../../../../modals/config';

export const tasksModalUniqueKey = 'tasks';

export enum ETasksModalName {
  TaskCompletedSuccessfully = 'TASK_CREATE_SUCCESSFULLY',
  WarningBeforeChangeInstance = 'WARNING_BEFORE_CHANGE_INSTANCE',
  TaskCreationError = 'TASK_CREATION_ERROR',
}

const taskCompletedSuccessfully: IModal = {
  name: ETasksModalName.TaskCompletedSuccessfully,
  title: 'Задача успешно создана',
  type: EModalType.Success,
  successButton: {
    title: 'Продолжить',
  },
};

const TaskCreationError: IModal = {
  name: ETasksModalName.TaskCreationError,
  title: 'Кажется, что-то пошло не так. Попробуйте ещё раз',
  type: EModalType.Warning,
  successButton: {
    title: 'Продолжить',
  },
};

export const taskModalsConfig: IModalConfig = {
  [tasksModalUniqueKey]: [taskCompletedSuccessfully, TaskCreationError],
};
