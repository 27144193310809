const LeaveBeforeSaveModalIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_1335_15290" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 0C10.7452 0 0 10.7452 0 24C0 28.3973 1.18261 32.5184 3.24713 36.0626L0.288134 44.9396C-0.181249 46.3478 0.992811 47.7585 2.46279 47.5527L14.1915 45.9107C17.186 47.2533 20.5058 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 0C10.7452 0 0 10.7452 0 24C0 28.3973 1.18261 32.5184 3.24713 36.0626L0.288134 44.9396C-0.181249 46.3478 0.992811 47.7585 2.46279 47.5527L14.1915 45.9107C17.186 47.2533 20.5058 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0Z"
        fill="#00386B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 0C10.7452 0 0 10.7452 0 24C0 28.3973 1.18261 32.5184 3.24713 36.0626L0.288134 44.9396C-0.181249 46.3478 0.992811 47.7585 2.46279 47.5527L14.1915 45.9107C17.186 47.2533 20.5058 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0Z"
        fill="#E2F0FF"
      />
      <path
        d="M3.24713 36.0626L7.04186 37.3275C7.4054 36.2369 7.28212 35.0426 6.70349 34.0493L3.24713 36.0626ZM0.288134 44.9396L4.08287 46.2045L0.288134 44.9396ZM2.46279 47.5527L1.9082 43.5914L1.9082 43.5914L2.46279 47.5527ZM14.1915 45.9107L15.828 42.2608C15.1414 41.953 14.382 41.845 13.637 41.9494L14.1915 45.9107ZM4 24C4 12.9543 12.9543 4 24 4V-4C8.53603 -4 -4 8.53603 -4 24H4ZM6.70349 34.0493C4.98538 31.0998 4 27.671 4 24H-4C-4 29.1236 -2.62017 33.9371 -0.209232 38.076L6.70349 34.0493ZM4.08287 46.2045L7.04186 37.3275L-0.547606 34.7977L-3.5066 43.6747L4.08287 46.2045ZM1.9082 43.5914C3.3782 43.3856 4.55224 44.7964 4.08287 46.2045L-3.5066 43.6747C-4.91474 47.8991 -1.39258 52.1315 3.01739 51.5141L1.9082 43.5914ZM13.637 41.9494L1.9082 43.5914L3.01738 51.5141L14.7461 49.8721L13.637 41.9494ZM24 44C21.0808 44 18.3179 43.3771 15.828 42.2608L12.5551 49.5607C16.0542 51.1295 19.9308 52 24 52V44ZM44 24C44 35.0457 35.0457 44 24 44V52C39.464 52 52 39.464 52 24H44ZM24 4C35.0457 4 44 12.9543 44 24H52C52 8.53603 39.464 -4 24 -4V4Z"
        fill="#BEE3FA"
        mask="url(#path-1-inside-1_1335_15290)"
      />
      <path
        d="M3.24713 36.0626L7.04186 37.3275C7.4054 36.2369 7.28212 35.0426 6.70349 34.0493L3.24713 36.0626ZM0.288134 44.9396L4.08287 46.2045L0.288134 44.9396ZM2.46279 47.5527L1.9082 43.5914L1.9082 43.5914L2.46279 47.5527ZM14.1915 45.9107L15.828 42.2608C15.1414 41.953 14.382 41.845 13.637 41.9494L14.1915 45.9107ZM4 24C4 12.9543 12.9543 4 24 4V-4C8.53603 -4 -4 8.53603 -4 24H4ZM6.70349 34.0493C4.98538 31.0998 4 27.671 4 24H-4C-4 29.1236 -2.62017 33.9371 -0.209232 38.076L6.70349 34.0493ZM4.08287 46.2045L7.04186 37.3275L-0.547606 34.7977L-3.5066 43.6747L4.08287 46.2045ZM1.9082 43.5914C3.3782 43.3856 4.55224 44.7964 4.08287 46.2045L-3.5066 43.6747C-4.91474 47.8991 -1.39258 52.1315 3.01739 51.5141L1.9082 43.5914ZM13.637 41.9494L1.9082 43.5914L3.01738 51.5141L14.7461 49.8721L13.637 41.9494ZM24 44C21.0808 44 18.3179 43.3771 15.828 42.2608L12.5551 49.5607C16.0542 51.1295 19.9308 52 24 52V44ZM44 24C44 35.0457 35.0457 44 24 44V52C39.464 52 52 39.464 52 24H44ZM24 4C35.0457 4 44 12.9543 44 24H52C52 8.53603 39.464 -4 24 -4V4Z"
        fill="url(#paint0_linear_1335_15290)"
        mask="url(#path-1-inside-1_1335_15290)"
      />
      <path
        d="M17.6003 35.2006V31.7594H21.805V22.5025H17.7864V19.0957H26.5678V31.7594H30.4003V35.2006H17.6003Z"
        fill="url(#paint1_linear_1335_15290)"
      />
      <circle cx="23.2002" cy="14.4002" r="3.2" fill="#1885F3" />
      <defs>
        <linearGradient
          id="paint0_linear_1335_15290"
          x1="24"
          y1="-1.31518e-07"
          x2="22.7838"
          y2="46.7684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#76BCFF" />
          <stop offset="0.399302" stopColor="#1E8EFE" />
          <stop offset="1" stopColor="#066BD0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1335_15290"
          x1="24.4003"
          y1="6.08569"
          x2="22.2581"
          y2="30.3341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#76BCFF" />
          <stop offset="0.399302" stopColor="#1E8EFE" />
          <stop offset="1" stopColor="#066BD0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LeaveBeforeSaveModalIcon;
