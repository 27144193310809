import { FC } from 'react';
import { observer } from 'mobx-react';

import { TasksFilters } from '../TasksFilters';
import { TasksList } from '../TasksList';
import { EContextualParentPath } from '../../../../../shared/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../shared/hooks';

import Styled from './Tasks.styles';

const Tasks: FC = () => {
  usePageContextualHelp(EContextualParentPath.Tasks);

  return (
    <Styled.Wrapper>
      <Styled.FiltersWrapper>
        <TasksFilters />
      </Styled.FiltersWrapper>

      <Styled.ListWrapper>
        <TasksList />
      </Styled.ListWrapper>
    </Styled.Wrapper>
  );
};

Tasks.displayName = 'Tasks';

export default observer(Tasks);
