import { FC, memo } from 'react';

import { ITablePlugBuilderAutoRenderConfig as IAutoRenderConfig } from '../../models/configs';
import { useDataTestId } from '../../../../../utils/hooks/locators';

import Styled from './TablePlugBuilderAutoRender.styles';

const DEFAULT_DATA_TEST_ID = 'table-plug-body';

type TProps = { dataTestId?: string } & IAutoRenderConfig;

const TablePlugBuilderAutoRender: FC<TProps> = ({ dataTestId, header, content, footer }) => {
  const isCustomIcon = typeof header.icon !== 'string';

  const getDataTestId = useDataTestId(dataTestId ? `${dataTestId}-content` : DEFAULT_DATA_TEST_ID);

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      {header ? (
        <Styled.Header {...getDataTestId('header')}>
          {isCustomIcon ? (
            header.icon
          ) : (
            <Styled.Icon $name={header.icon} {...getDataTestId('icon')} />
          )}
        </Styled.Header>
      ) : null}

      {content ? (
        <Styled.Content {...getDataTestId('content')}>
          <Styled.Title {...getDataTestId('title')}>{content.title}</Styled.Title>

          <Styled.Description {...getDataTestId('description')}>
            {content.description}
          </Styled.Description>
        </Styled.Content>
      ) : null}

      {footer ? footer.renderButton() : null}
    </Styled.Wrapper>
  );
};

TablePlugBuilderAutoRender.displayName = 'TablePlugBuilderAutoRender';

export default memo(TablePlugBuilderAutoRender);
