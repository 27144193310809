import { EChecklistAttributeType as EAttrType } from '../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

enum ERequiredText {
  Normal = 'Это поле не может быть пустым',
  FileLink = 'Прикрепите хотя бы одну фотографию',
}

const getChecklistsAttrRequiredText = (attrType: EAttrType): ERequiredText => {
  switch (attrType) {
    case EAttrType.Int:
      return ERequiredText.Normal;

    case EAttrType.Double:
      return ERequiredText.Normal;

    case EAttrType.String:
      return ERequiredText.Normal;

    case EAttrType.DictionaryLink:
      return ERequiredText.Normal;

    case EAttrType.Enum:
      return ERequiredText.Normal;

    case EAttrType.UserDictionaryLink:
      return ERequiredText.Normal;

    case EAttrType.FileLink:
      return ERequiredText.FileLink;

    case EAttrType.LongString:
      return ERequiredText.Normal;

    case EAttrType.Date:
      return ERequiredText.Normal;

    default:
  }
};

export default getChecklistsAttrRequiredText;
