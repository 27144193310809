import { useCallback } from 'react';
import moment from 'moment';

import { useStore } from '../../../../../shared/utils/IoC';
import { TaskDefaultValuesService } from '../../../tasks/mobx/services';
import { TaskStore } from '../../../tasks/mobx/stores';
import { SeasonCultureStore } from '../../stores/season.culture.store';
import { OperationsStore } from '../../stores/operations.store';
import { useTasksRouteActions } from '../../../tasks/hooks';
import { NO_CULTURE_SELECT_OPTION } from '../../../../../shared/utils/constants/selectOptions';
import { ITaskCreate } from '../../../../../../api/models/as-fields/task/task.model';
import { checkIfDateIsInTheRange } from '../../../../../shared/utils/helpers/checkIfDateIsInTheRange';

const useOperationsTaskActions = () => {
  const taskDefaultValuesService = useStore(TaskDefaultValuesService);

  const taskStore = useStore(TaskStore);
  const seasonCultureStore = useStore(SeasonCultureStore);
  const operationsStore = useStore(OperationsStore);

  const actions = useTasksRouteActions();

  const handlePresetTask = useCallback(
    (bindingDate?: string) => {
      const cultureId =
        seasonCultureStore.selectedCultureId === 'emptyCulture'
          ? NO_CULTURE_SELECT_OPTION.value
          : seasonCultureStore.selectedCultureId;

      const planStartDate =
        bindingDate ??
        taskDefaultValuesService.getPresetPlanStartDate(operationsStore.selectedOperation);

      const data: Partial<ITaskCreate> = {
        cultureId,
        planStartDate,
        planEndDate: planStartDate,
      };

      /**
       * Странный кейс. У нас может быть операция, диапазон дат которой не пересекается
       * с предустановленной датой в списке задач.
       */
      if (operationsStore.selectedOperation) {
        if (bindingDate) {
          const { startDate, endDate } = operationsStore.selectedOperation;

          const isInTheRange = checkIfDateIsInTheRange(bindingDate, startDate, endDate);

          if (isInTheRange) {
            data.operationId = operationsStore.selectedOperation.id;
          }
        } else {
          data.operationId = operationsStore.selectedOperation.id;
        }
      }

      if (bindingDate) {
        data.bindingDate = bindingDate;
      }

      taskStore.setTaskCreateData(data);

      actions.goToTaskCreateOne();
    },
    [
      actions.goToTaskCreateOne,
      operationsStore.selectedOperation,
      seasonCultureStore.selectedCultureId,
    ]
  );

  return {
    presetTask: handlePresetTask,
  };
};

export default useOperationsTaskActions;
