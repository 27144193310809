import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import {
  EChecklistAttributeType as EAttrType,
  IGetChecklistAttribute as IAttribute,
  IGetChecklistAttributeValue as IAttributeValue,
} from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import {
  IChecklistsFormulaResult as IFormulaResult,
  TChecklistsIntegerAttrToDraw as TIntegerAttrToDraw,
} from '../../../../models';
import { ChecklistsStore } from '../../../stores';
import { ChecklistsAttrsFormulasService } from '../../ChecklistsAttrsFormulasService';
import { EChecklistMode } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { getChecklistsAttrRequiredText as getAttrRequiredText } from '../../../../helpers';
import AverageAttributeHelpers from '../../../../containers/attributes/ChecklistsAverageAttribute/helpers/AverageAttribute.helpers';

const { isAverageChecklistAttr, createAverageAttrData } = AverageAttributeHelpers;

@provide.transient()
class ChecklistsIntegerAttrsService {
  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(ChecklistsAttrsFormulasService)
  protected formulasService: ChecklistsAttrsFormulasService;

  createAttrToDraw = (
    groupId: string,
    attr: IAttribute,
    initialValueList: IAttributeValue[],
    options?: {
      isBlocked?: boolean;
      nestedInstanceId?: string;
      dependentFileAttrId?: string;
    }
  ): TIntegerAttrToDraw => {
    const attrToDraw: TIntegerAttrToDraw = {
      id: attr.id,
      groupId,
      isVisible: true,
      order: attr.order,
      initialModel: attr,
      value: {
        checkListAttributeId: attr.id,
        integerValue: '',
      },
      options: null,
      isBlocked: options?.isBlocked || this.checklistsStore.mode === EChecklistMode.View,
      validationScheme: {
        isShowError: false,
        errorTitle: getAttrRequiredText(EAttrType.Int),
      },
      isEdited: false,
      nestedInstanceId: options?.nestedInstanceId,
      dependentFileAttrId: options?.dependentFileAttrId,
    };

    const initialValueOfThisAttr = initialValueList.find(
      ({ checkListAttributeId }) => checkListAttributeId === attrToDraw.id
    );

    if (initialValueOfThisAttr) {
      attrToDraw.value = {
        ...attrToDraw.value,
        integerValue: initialValueOfThisAttr.integerValue,
      };
    }

    if (isAverageChecklistAttr(attr.attribute)) {
      attrToDraw.options = {
        ...attrToDraw.options,
        averageData: createAverageAttrData(attr, initialValueOfThisAttr),
      };

      attrToDraw.value.integerValues = initialValueOfThisAttr?.integerValues;
    }

    return attrToDraw;
  };

  changeValue = (groupId: string, value: TIntegerAttrToDraw['value']): void => {
    this.checklistsStore.updateAttrToDraw(groupId, value.checkListAttributeId, {
      value,
      isEdited: true,
    });

    this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.Int>(
      groupId,
      value.checkListAttributeId,
      {
        isShowError: false,
      }
    );
  };

  calculateValue = (groupId: string, attrId: string): IFormulaResult => {
    const result = this.formulasService.calculateValue(groupId, attrId);

    return result;
  };
}

export default ChecklistsIntegerAttrsService;
