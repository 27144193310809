import { IGetIntensity } from '../intensity/intensity.model';

export enum EChecklistType {
  Point = 'POINT',
  Machinery = 'MACHINERY',
  Field = 'FIELD',
  Nested = 'NESTED',
}

export interface IGetChecklist {
  id: string;
  name: string;
  operationType: {
    id: string;
    name: string;
    noCulture: boolean;
    iconLink: string;
  };
  cultureId: string;
  cultureIntensity: IGetIntensity;
  organizationId: string;
  checkListSettings?: IIGetChecklistSettings[];
}

export interface IIGetChecklistSettings {
  id: string;
  checklistId: string;
  culture: {
    id: string;
    name: string;
  };
  operatiionType: {
    id: string;
    name: string;
  };
  phenophase: null;
}
