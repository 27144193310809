import moment, { Moment } from 'moment';
import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../shared/utils/IoC';
import { TaskStatuses } from '../../operations/stores/tasks.store';

import { taskLabels } from './tasks.store';

export const defaultStatuses = [
  { value: TaskStatuses.InWork, label: taskLabels[TaskStatuses.InWork] },
  { value: TaskStatuses.New, label: taskLabels[TaskStatuses.New] },
];

@provide.singleton()
export class TasksFilterStore {
  constructor() {
    makeAutoObservable(this);
  }

  culture: string[] = [];
  asignee: string[] = [];
  operationType: string[] = [];

  status: string[] = defaultStatuses.flatMap(status => status.value);
  startDate: Moment;
  endDate: Moment;
  priority = [];
  field = '';
  season = '';
  organizationId = '';

  initDates = (startDate, endDate) => {
    this.startDate = moment(startDate);
    this.endDate = moment(endDate);
  };

  setCulture = (newValue: string[]) => {
    this.culture = newValue;
  };
  addCultureToList = (newValue: string) => {
    this.culture = [...this.culture, newValue];
  };

  setAsignee = (newValue: string[]) => {
    this.asignee = newValue;
  };
  addAsigneeToList = (newValue: string) => {
    this.asignee = [...this.asignee, newValue];
  };

  setStatus = (newValue: string[]) => {
    this.status = newValue;
  };

  setStartDate = newValue => {
    this.startDate = newValue;
  };

  setEndDate = newValue => {
    this.endDate = newValue;
  };

  setOperationType = (newValue: string[]) => {
    this.operationType = newValue;
  };
  addOperationToOperationTypeList = (newValue: string) => {
    this.operationType = [...this.operationType, newValue];
  };

  setPriority = newValue => {
    this.priority = newValue;
  };

  setField = newValue => {
    this.field = newValue;
  };

  setSeason = newValue => {
    this.season = newValue;
  };

  setOrganizationId = newValue => {
    this.organizationId = newValue;
  };
}
