import styled from 'styled-components';

import { Colors } from '../../../shared/constans/colors';
import { PopupPages } from '../../constants/popup.pages';

type PropType = {
  visibility: boolean;
  type?: PopupPages;
};

const renderPopup = (v: PopupPages | undefined) => {
  const variants = {
    [PopupPages.Main]: `
    grid-template-columns: 62% minmax(200px, 38%);
    @media (max-width: 1365px) {
      grid-template-columns: 62% minmax(200px, 38%);
    }
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 200px;
    }
    `,
    [PopupPages.CultureZone]: `grid-template-columns: 1fr`,
  };

  return variants[v] || variants[PopupPages.Main];
};

export const PSlider = styled.div<PropType>`
  background: ${Colors.backgroundSecondaryGray};

  position: relative;
  transition: all 0.3s ease-in-out;
  display: ${({ type }) => (type === PopupPages.Seasons ? 'block' : 'grid')};

  grid-template-columns: 2fr minmax(min-content, 1fr) minmax(125px, 1fr);
  ${({ type }) => renderPopup(type)};
  grid-gap: 16px;
  padding: ${({ type }) => (type === PopupPages.None ? '0' : '16px')};
  height: ${({ visibility }) => (visibility ? 'auto' : '0; padding: 0;')};
  box-sizing: border-box;
`;
export const FakeSlider = styled.div<PropType>`
  background: ${Colors.backgroundSecondaryGray};
  display: none;
  position: relative;
  width: 100%;
  ${({ visibility }) => visibility && 'display: flex;'};
  ${({ type }) => type === PopupPages.Main && 'height: 300px;'};
`;
