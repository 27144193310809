import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Sidebar } from '../Sidebar/Sidebar';
import { useStore } from '../../../shared/utils/IoC';
import { UiStore } from '../../stores/ui.store';

import { Blur, SidebarWrapper, Wrapper } from './style';

export const AdaptiveSidebar: FC = observer(() => {
  const ui = useStore(UiStore);
  const { isAdaptiveSidebarVisible, setIsSidebarShort } = ui;

  if (!isAdaptiveSidebarVisible) {
    return null;
  }

  return (
    <Wrapper>
      <Blur onClick={() => setIsSidebarShort(true)} />
      <SidebarWrapper>
        <Sidebar isAdaptive={true} />
      </SidebarWrapper>
    </Wrapper>
  );
});
