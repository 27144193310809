import { FC } from 'react';

const FileSvg: FC = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1667 18.3333C17.1667 16.5844 18.5844 15.1667 20.3333 15.1667H47.8024L62.8335 31.1376V61.6668C62.8335 63.4157 61.4157 64.8335 59.6668 64.8335H20.3333C18.5844 64.8335 17.1667 63.4157 17.1667 61.6668V18.3333Z"
        fill="url(#paint0_linear_1774_12531)"
        stroke="url(#paint1_linear_1774_12531)"
        strokeWidth="0.333333"
      />
      <path
        d="M47.5515 28.6024L47.9488 15.1787L63.0003 31.0716L51.1503 32.0237C49.1712 32.1827 47.4927 30.587 47.5515 28.6024Z"
        fill="#D1DFF2"
      />
      <rect
        x="23.7646"
        y="36.4287"
        width="32.8089"
        height="2.50001"
        rx="1.25"
        fill="url(#paint2_linear_1774_12531)"
      />
      <rect
        x="23.7646"
        y="42.8574"
        width="32.8089"
        height="2.50001"
        rx="1.25"
        fill="url(#paint3_linear_1774_12531)"
      />
      <rect
        x="23.7646"
        y="49.2861"
        width="32.8089"
        height="2.50001"
        rx="1.25"
        fill="url(#paint4_linear_1774_12531)"
      />
      <rect
        x="23.7646"
        y="55.7148"
        width="15.8971"
        height="2.50001"
        rx="1.25"
        fill="url(#paint5_linear_1774_12531)"
      />
      <path
        d="M48.0553 27.7082V15.5043L62.6122 30.8749H51.222C49.4731 30.8749 48.0553 29.4572 48.0553 27.7082Z"
        fill="url(#paint6_linear_1774_12531)"
        stroke="url(#paint7_linear_1774_12531)"
        strokeWidth="0.333333"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1774_12531"
          x1="24.9618"
          y1="15.0002"
          x2="7.33572"
          y2="44.1223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3F9FB" />
          <stop offset="1" stopColor="#E4EDEF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1774_12531"
          x1="40.0001"
          y1="15"
          x2="40.0001"
          y2="65.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCE0FF" />
          <stop offset="0.418024" stopColor="#D2DFF1" />
          <stop offset="1" stopColor="#A7BDDE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1774_12531"
          x1="43.4701"
          y1="31.0808"
          x2="23.1003"
          y2="50.4248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20285F" />
          <stop offset="1" stopColor="#030934" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1774_12531"
          x1="43.4701"
          y1="37.5095"
          x2="23.1003"
          y2="56.8535"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20285F" />
          <stop offset="1" stopColor="#030934" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1774_12531"
          x1="43.4701"
          y1="43.9382"
          x2="23.1003"
          y2="63.2822"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20285F" />
          <stop offset="1" stopColor="#030934" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1774_12531"
          x1="33.3127"
          y1="50.3669"
          x2="17.8217"
          y2="57.4949"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20285F" />
          <stop offset="1" stopColor="#030934" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1774_12531"
          x1="51.4996"
          y1="18.75"
          x2="55.846"
          y2="30.8978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F3A82" />
          <stop offset="1" stopColor="#20285E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1774_12531"
          x1="55.4441"
          y1="15.0859"
          x2="55.4441"
          y2="31.0416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#525FBD" />
          <stop offset="1" stopColor="#242C67" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FileSvg;
