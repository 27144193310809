import { makeAutoObservable, toJS } from 'mobx';

import { lazyInject, provide } from '../../shared/utils/IoC';
import { Axios, TypeApiResponse } from '../../shared/utils/axios2';
import { getFieldSeasonsById } from '../../../api/endpoints/fields/get.field.seasons.by.id';
import { FieldSeasonsModel } from '../../../api/models/field.seasons.model';
import { FieldsStore } from '../modules/fields/stores/fields.store';
import MapStore from '../../map/stores/map.store';
import { RenderPolygonOption } from '../../map/consts/enum.render.option';

@provide.singleton()
export class FieldSeasonsStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(MapStore)
  map: MapStore;

  @lazyInject(FieldsStore)
  fieldsStore: FieldsStore;

  get fields() {
    return Array.from(this.yearToField.values());
  }

  yearToField: Map<number, FieldSeasonsModel> = new Map<number, FieldSeasonsModel>();
  yearsToFieldIdToCopy: Map<number, FieldSeasonsModel> = new Map<number, FieldSeasonsModel>();

  isLoading = true;

  get yearsToCopy() {
    return Array.from(this.yearsToFieldIdToCopy.keys());
  }

  getYearToField = (year: number) => {
    return this.yearsToFieldIdToCopy.get(year);
  };

  renderCultureZoneByYear = (year: string) => {
    const obj = this.yearToField.get(Number(year));

    if (!obj) return;

    if (!Boolean(obj.fieldVersion.cultureZones?.length)) {
      const { geometry } = obj.fieldVersion;
      const polyId = this.map.setPolygon(geometry.coordinates, {
        renderOption: RenderPolygonOption.CultureZone,
        fill: 'EMPTY',
      });
      this.map.centerMapToPoint(polyId);
      return;
    }

    const { geometry, culture } = obj?.fieldVersion?.cultureZones?.[0];

    if (!geometry || !culture) return;

    // show field boundary
    this.map.setPolygon(obj.fieldVersion.geometry.coordinates, {
      renderOption: RenderPolygonOption.View,
    });

    // show cultures
    obj.fieldVersion.cultureZones.forEach(zone => {
      const polyId = this.map.drawCultureZones(zone);
      this.map.centerMapToPoint(polyId);
    });
  };

  fetchSeasons = async (includeAllSeasons = true) => {
    if (includeAllSeasons) {
      this.clear();
    }
    let response: TypeApiResponse<'getFieldSeasonsById'>;
    this.isLoading = true;
    try {
      response = await this.axios.api.getFieldSeasonsById(
        { fieldId: this.fieldsStore.selectedFieldId, includeAllSeasons },
        { omit: ['fieldId'] }
      );
    } catch (e) {
      this.isLoading = false;
      throw new Error(`can't fetch fields with`);
    }
    this.isLoading = false;

    if (includeAllSeasons) {
      response.content.forEach(obj => {
        this.yearToField.set(obj.season.year, obj);
      });
    } else {
      this.yearsToFieldIdToCopy.clear();
      response.content.forEach(obj => {
        this.yearsToFieldIdToCopy.set(obj.season.year, obj);
      });
    }
  };

  selectedSeasonYearToCopy: number = null;

  selectSeasonYearToCopy = (seasonYear: number) => {
    this.selectedSeasonYearToCopy = seasonYear;
  };

  copyFieldFromSeason = async (seasonYear: number) => {
    if (this.selectedSeasonYearToCopy) {
      try {
        await this.axios.api.copyFieldById(
          {
            fieldId: this.yearsToFieldIdToCopy.get(this.selectedSeasonYearToCopy).fieldVersion.id,
            fromSeasonYear: this.selectedSeasonYearToCopy,
            toSeasonYear: seasonYear,
          },
          {
            omit: ['fieldId', 'fromSeasonYear', 'toSeasonYear'],
          }
        );
      } catch (e) {
        throw new Error(e.response.data.error);
      }
    }
  };

  clear = () => {
    this.yearToField.clear();
    this.isLoading = true;
  };
}
