import { FC, memo } from 'react';

import { IExtendedAverageValue } from '../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

import Styled from './ChecklistsAverageAttrCullNumbersModalItem.styles';

type TValue = IExtendedAverageValue;

interface IProps {
  item: TValue;
  onToggleItem: (item: TValue, isSelected: boolean) => void;
  isSelected: boolean;
}

const ChecklistsAverageAttrCullNumbersModalItem: FC<IProps> = ({
  item,
  onToggleItem,
  isSelected,
}) => {
  return (
    <Styled.Wrapper $isSelected={isSelected} onClick={() => onToggleItem(item, isSelected)}>
      {item.value}
    </Styled.Wrapper>
  );
};

ChecklistsAverageAttrCullNumbersModalItem.displayName = 'ChecklistsAverageAttrCullNumbersModalItem';

export default memo(ChecklistsAverageAttrCullNumbersModalItem);
