import { makeAutoObservable, autorun, reaction } from 'mobx';

import { FieldsStore } from '../modules/fields/stores/fields.store';
import { lazyInject, provide } from '../../shared/utils/IoC';
import { Axios, TypeApiResponse } from '../../shared/utils/axios2';

import { SeasonsStore } from './seasons.store';


@provide.singleton()
export class WeatherStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsStore)
  protected seasons: SeasonsStore;

  @lazyInject(FieldsStore)
  protected fields: FieldsStore;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.fields.selectedFieldId,
      () => {
        if (this.seasons.selectedSeason) {
          this.fetchWeatherById();
          this.fetchWeatherForecastsById();
        }
      }
    );
  }

  weatherById: any;

  fetchWeatherById = async () => {
    let response: TypeApiResponse<'getWeatherById'>;
    if (!this.fields.selectedFieldId) return;
    try {
      // this.fields.isLoading = true;
      response = await this.axios.api.getWeatherById({
        id: this.fields.selectedFieldId,
        seasonYear: Number(this.seasons.selectedSeason),
      });
      this.weatherById = response;
      // this.fields.isLoading = false;
    } catch (e) {
      // this.fields.isLoading = false;
      return;
    }
    return response;
  };

  weatherForecastsById: any;

  fetchWeatherForecastsById = async () => {
    let response: TypeApiResponse<'getWeatherForecastsById'>;
    if (!this.fields.selectedFieldId) return;
    try {
      // this.fields.isLoading = true;
      response = await this.axios.api.getWeatherForecastsById({
        id: this.fields.selectedFieldId,
        seasonYear: Number(this.seasons.selectedSeason),
      });
      this.weatherForecastsById = response;
      // this.fields.isLoading = false;
    } catch (e) {
      // this.fields.isLoading = false;
      return;
    }
    return response;
  };
}
