import { CSSProperties, FC, memo, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../utils/IoC';
import { MapController } from '../../mobx/controllers';
import { IMapGlobalConfig as IGlobalConfig } from '../../models';
import { Overlay } from '../Overlay';

import Styled from './Map.styles';

interface IProps {
  globalConfig: IGlobalConfig;
  style?: CSSProperties;
}

const Map: FC<IProps> = ({ globalConfig, style }) => {
  const { initInstance, clearMapStore } = useStore(MapController);

  useEffect(() => {
    initInstance(globalConfig);

    return () => {
      clearMapStore();
    };
  }, []);

  return (
    <Styled.Composition>
      <Styled.Wrapper id={globalConfig.mapKey} data-test-id={globalConfig.mapKey} $style={style} />
      <Overlay globalConfig={globalConfig} />
    </Styled.Composition>
  );
};

Map.displayName = 'Map';

export default memo(observer(Map));
