import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  gender?: string;
  lastName?: string;
  firstName?: string;
  secondName?: string;
  dateOfBirth?: string;
  specializationId?: number;
  avatar?: string;
  email: string;
};

type TypeResponse = {};

export const updateProfileWithEmail: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/da-profile/users/myProfile/withEmail?email=${request.email}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
