import moment from 'moment';

import { IndexModel } from '../../../../../../api/models/indices.model';
import findClosestDateInPast from '../../components/Overlay/helpers/findNearestDateInCollection';

const useIndicesDateSelect = () => {
  const currentDate = new Date();

  const getClosestDateIndex = (indicesList: IndexModel[], targetDate: Date) => {
    const isAfter = moment(targetDate).isAfter(currentDate);

    const dateList = indicesList.flatMap(index => new Date(index.fromDate));

    const existedDateIndex = dateList.findIndex(
      date =>
        moment(date).format('YYYY-DD-MM') ===
        moment(isAfter ? currentDate : targetDate).format('YYYY-DD-MM')
    );

    if (existedDateIndex >= 0) {
      return { value: indicesList[existedDateIndex], index: existedDateIndex };
    }

    const closestDate = findClosestDateInPast(dateList, isAfter ? currentDate : targetDate);

    return {
      value: indicesList[closestDate?.closestDateIndex ?? 0],
      index: closestDate?.closestDateIndex || 0,
      isNoClosestDateInPast: closestDate.closestDate === null,
    };
  };

  return { getClosestDateIndex };
};

export default useIndicesDateSelect;
