import { TypeApiRoute } from '../../models/type.api.request';
import { UserInfoType } from '../../models/user.model';
import { TypeResponsePageable } from '../../models/type.response.pageable';
import { Operation } from '../../models/operations/operation.model';

type TGetOperationListReq = {
  organizationId?: string;
  cultureId?: string;
  seasonYear: number;
  noCulture?: boolean | undefined;
  size?: number;
  page?: number;
};

type TypeResponse = { content: Array<Operation> } & TypeResponsePageable;

export const getOperations: TypeApiRoute & {
  request: TGetOperationListReq;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/operation/list`,
  method: 'GET',
  headers: {},
  request: {} as TGetOperationListReq,
  response: {} as TypeResponse,
};

export type { TGetOperationListReq };
