const formatEnteredDoubleValue = (
  value: string,
  options?: {
    allowNegativeNumber?: boolean;
  }
): string | number => {
  const trimmedValue = value.trim();
  const numberValue = Number(trimmedValue);

  const isMinus = trimmedValue === '-';
  const isEmpty = trimmedValue === '';

  const isMoreThanOneLeadingZero: boolean = trimmedValue?.[0] === '0' && trimmedValue?.[1] === '0';

  const isMinusAndIsMoreThanOneLeadingZero: boolean =
    trimmedValue?.[0] === '-' && trimmedValue?.[1] === '0' && trimmedValue?.[2] !== '.';

  if (isEmpty) {
    return trimmedValue;
  }

  if (isMinus && options?.allowNegativeNumber) {
    return trimmedValue;
  }

  if (!isNaN(numberValue)) {
    return isMoreThanOneLeadingZero ? 0 : isMinusAndIsMoreThanOneLeadingZero ? '-0' : trimmedValue;
  }
};

export default formatEnteredDoubleValue;
