import { useCallback, useMemo, useState } from 'react';

import { TableFiltersBuilderController } from '../../../../mobx/controllers';
import { ITableFiltersBuilderFilter as IFilter } from '../../../../models/data';
import { useStore } from '../../../../../../utils/IoC';
import { ISelectOption } from '../../../../../../../../types/selectOption';

type TPaginationConfig = {
  currentPage: number;
  totalPages: number;
  onScroll: () => Promise<any>;
  onPageChange: () => void;
};

const useTableFilterBuilderDropdownPagination = (filter: IFilter, optionList: ISelectOption[]) => {
  const controller = useStore(TableFiltersBuilderController);

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(2);

  const handleScroll = useCallback(async () => {
    if (!filter?.selectOptions?.pagination) {
      return;
    }

    const data = await filter?.selectOptions?.pagination?.requestQuery?.(page + 1);

    const updatedOptionList = [...optionList, ...data.optionList];

    setPage(page + 1);
    setTotalPages(data.totalPages);

    if (filter?.selectOptions?.pagination?.updateDataHandler) {
      filter.selectOptions.pagination.updateDataHandler?.(updatedOptionList);
    }

    controller.addSelectOptionList(filter.builderId, filter.id, updatedOptionList);

    return Promise.resolve(true);
  }, [page, optionList, filter]);

  const paginationConfig = useMemo<TPaginationConfig>(
    () => ({
      currentPage: page,
      totalPages,
      onScroll: handleScroll,
      onPageChange: () => null,
    }),
    [page, handleScroll, totalPages]
  );

  return { handleScroll, paginationConfig };
};

export default useTableFilterBuilderDropdownPagination;
