import React, { FC, MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useDataTestId } from '@farmlink/farmik-ui';

import ArrowIcon from './assets/icons/arrow/arrow-top.svg';
import Styled from './MoveTop.styles';

type TMoveTop = {
  parentRef: MutableRefObject<HTMLDivElement>;
  showMoveTop?: boolean;
  dataTestId?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const MoveTop: FC<TMoveTop> = ({ parentRef, showMoveTop, dataTestId, ...props }) => {
  const [isShowMoveTop, setIsShowMoveTop] = useState(false);

  const getDataTestId = useDataTestId(dataTestId);

  const handleClick = () => {
    parentRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = useCallback(() => {
    const element = parentRef.current;

    if (element?.scrollTop > 0) {
      setIsShowMoveTop(true);
    } else {
      setIsShowMoveTop(false);
    }
  }, []);

  useEffect(() => {
    const element = parentRef.current;

    if (!showMoveTop) {
      return;
    }

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [showMoveTop]);

  return isShowMoveTop ? (
    <Styled.Button onClick={handleClick} {...props} {...getDataTestId()}>
      <Styled.ArrowIcon src={ArrowIcon} />
    </Styled.Button>
  ) : null;
};

MoveTop.displayName = 'MoveTop';

export default MoveTop;
