import { FC, memo } from 'react';
import moment from 'moment';

import { TasksListDaysLeftValue as Value } from '../../values';

interface IProps {
  id: string;
  canceledDate: string;
}

const TasksListDaysLeftWithStatusCanceled: FC<IProps> = ({ id, canceledDate }) => {
  return (
    <Value
      id={id}
      icon={'calendar'}
      title={moment(canceledDate).format('DD.MM.YYYY')}
      tooltipTitle={'Дата отмены'}
    />
  );
};

TasksListDaysLeftWithStatusCanceled.displayName = 'TasksListDaysLeftWithStatusCanceled';

export default memo(TasksListDaysLeftWithStatusCanceled);
