import styled, { keyframes } from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import { BorderRadius, FontSizes } from '../../../../../shared/constans/sizes';
import { NoScrollBar } from '../../../../../shared/constans/noScrollbar';

export const Wrapper = styled.div`
  height: 100%;
  background: ${Colors.white};
  border-radius: ${BorderRadius.small};
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  width: 240px;
  @media (min-width: 1024px) {
    width: 240px;
  }
  @media (min-width: 1366px) {
    width: 300px;
  }
  @media (min-width: 1920px) {
    width: 400px;
  }
  margin-right: 24px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  cursor: pointer;
  ${FontSizes.largeBold}
`;

export const AddOperation = styled.div`
  color: ${Colors.green};
  ${FontSizes.medium};
`;

export const Body = styled.div`
  display: flex;
  flex-grow: 1;
  // ${NoScrollBar({ direction: 'y' })};
`;

export const Footer = styled.div`
  padding: 16px 16px 20px;

  .add-operations-button {
    font-weight: 600;
    justify-content: center;
  }
`;

export const OperationsListWrapper = styled.div`
  width: 100%;
  margin-top: 4px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const spinner = keyframes`
  to {transform: rotate(360deg);} 
`;

export const Spinner = styled.div`
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  border-top: 2px solid #07d;
  border-right: 2px solid transparent;
  animation: ${spinner} 0.6s linear infinite;
  // margin: 24px 0;
  align-self: center;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
