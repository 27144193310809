import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';

import { useStore } from '../../shared/utils/IoC';
import { SessionStore } from '../stores/session.store';
import { ProfileController } from '../../dashboard/modules/profile/controllers/profile.controller';

type ExternalRedirectProps = {
  redirectTo: string;
  isLogout?: boolean;
};

export const ExternalRedirect: FC<ExternalRedirectProps> = ({ redirectTo, isLogout }) => {
  useEffect(() => {
    let redirectUrl = redirectTo;
    if (isLogout) {
      redirectUrl = redirectUrl.replace('?', 'logout?');
    }
    window.location.href = redirectUrl;
  }, []);

  return <div>загрузка</div>;
};

type AuthorizationWrapperProps = {
  excludedRoutes: Array<string>;
  redirectTo: string;
  isZoneForAuthorized: boolean;
  children: any;
};

export const AuthorizationWrapper = observer(
  ({ children, isZoneForAuthorized, redirectTo, excludedRoutes }: AuthorizationWrapperProps) => {
    const { isLogout, isLoading, isLoggedIn, checkIsLoggedIn } = useStore(SessionStore);

    const { setIsLoadingBySeasonFieldsUrl } = useStore(ProfileController);

    const { pathname, search } = useLocation();

    const { season, fieldId } = useParams<{ season: string; fieldId: string }>();

    useEffect(() => {
      if (!isLoggedIn) {
        const searchParam = new URLSearchParams(search.slice(1));
        setIsLoadingBySeasonFieldsUrl(Boolean(season) && Boolean(fieldId));
        checkIsLoggedIn(searchParam.get('authKey'));
      }
    }, [search, isLoggedIn]);

    const isRouteExcluded = () => {
      const matches = excludedRoutes.filter(item => pathname.includes(item));
      return matches.length > 0;
    };

    if (isLoading) {
      return <div>загрузка</div>;
    }

    if (isZoneForAuthorized) {
      if (isLoggedIn || isRouteExcluded()) {
        return children;
      }

      return <ExternalRedirect redirectTo={redirectTo} isLogout={isLogout} />;
    }

    if (!isLoggedIn || isRouteExcluded()) {
      return children;
    }

    return <ExternalRedirect redirectTo={redirectTo} isLogout={isLogout} />;
  }
);
