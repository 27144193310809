import { FC, memo } from 'react';
import {
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  useNotificator,
} from '@farmlink/farmik-ui';

import Styled from './InstanceCoordinates.styles';

const InstanceCoordinates: FC<{ order: number; coordinates: number[] }> = ({
  order,
  coordinates,
}) => {
  const { setNotification, clearAll } = useNotificator();

  const parsedCoordinates = coordinates?.map(item => item.toFixed(7))?.join(', ');

  const handleCopy = async () => {
    try {
      // @ts-ignore
      await navigator?.permissions?.query({ name: 'clipboard-write' });
      await navigator.clipboard.writeText(parsedCoordinates);
      clearAll();

      setNotification({
        message: `Координаты точки ${order} скопированы в буфер обмена`,
        style: {
          type: ENotificationType.Success,
          height: ENotificationHeight.BIG,
          placement: 'top-center',
          theme: ENotificatorTheme.Dark,
        },
      });
    } catch (err) {
      console.error('Copy coordinates error: ', err);
    }
  };

  return (
    <Styled.CordButton
      type="button"
      data-test-id="copy-instance-coordinates-button"
      onClick={handleCopy}
    >
      <Styled.CopyIcon />
      <Styled.Coordinates data-test-id="copy-instance-coordinates-value">
        {parsedCoordinates}
      </Styled.Coordinates>
    </Styled.CordButton>
  );
};

export default memo(InstanceCoordinates);
