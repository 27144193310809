import { IDrawChecklistInstance } from '../../../../../../../api/models/checklist/instance/checklist.instance.model';

type TListOfChecklistInstanceProps = {
  mode: 'listing' | 'fullscreen';
  type: 'point' | 'technique';
  variant: 'drawer' | 'fullscreen';
  title: string;
  instList: IDrawChecklistInstance[];
  isHideHeader?: boolean;
  description?: string;
  addButton?: {
    title: string;
    onClick: () => void;
  };
  isChangesCanBeUndone?: boolean;
};

enum EPointType {
  Uncompleted,
  Completed,
  Warning,
  Error,
}

export { EPointType };

export type { TListOfChecklistInstanceProps };
