import styled from 'styled-components';

import { Colors } from '../../../../../../../../shared/constans/colors';

const Button = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 12px;
  background-color: ${Colors.secondaryGray};
  cursor: pointer;
  color: ${Colors.generalBlack};
  transition: color 0.3s ease;

  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    color: ${Colors.skyHover};
  }
`;

const StyledTasksFiltersUploadButton = {
  Button,
};

export default StyledTasksFiltersUploadButton;
