import { FC, memo } from 'react';

import { TasksListDaysLeftValue as Value } from '../../values';

interface IProps {
  id: string;
  daysLeft: number;
}

const TasksListDaysLeftWithStatusInWork: FC<IProps> = ({ id, daysLeft }) => {
  if (daysLeft > 0) {
    return (
      <Value
        id={id}
        icon={'finish'}
        title={`${Math.abs(daysLeft)} д.`}
        tooltipTitle={'Дни до завершения'}
      />
    );
  }

  if (daysLeft === 0) {
    return (
      <Value
        id={id}
        icon={'check'}
        title={'Сегодня'}
        tooltipTitle={'Завершение по плану сегодня'}
      />
    );
  }

  if (daysLeft < 0) {
    return (
      <Value
        id={id}
        icon={'clock'}
        title={`${Math.abs(daysLeft)} д.`}
        tooltipTitle={'Просроченные дни'}
      />
    );
  }

  return <></>;
};

TasksListDaysLeftWithStatusInWork.displayName = 'TasksListDaysLeftWithStatusInWork';

export default memo(TasksListDaysLeftWithStatusInWork);
