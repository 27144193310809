import { Checkbox } from '@farmlink/farmik-ui';
import { FC, memo, MouseEvent, useCallback, useMemo } from 'react';

import Styled from './DropdownBodyOption.styles';
import {
  TDropdownBodyOptionLabelParts,
  TDropdownBodyOptionProps,
} from './DropdownBodyOption.types';

const DropdownBodyOption: FC<TDropdownBodyOptionProps> = ({
  option,
  searchValue,
  selectedValues,
  isSearch,
  onOptionClick,
  dataTestId,
  onCloseClick,
  isDisplayCheckbox,
  isOptionSelected,
}) => {
  // const isOptionSelected = useMemo(() => {
  //   if (isSelectedSelectAllOption) {
  //     return true;
  //   }
  //
  //   return selectedValues.includes(option.value);
  // }, [selectedValues, option, isSelectedSelectAllOption]);

  const handleOptionClick = useCallback(
    (event: MouseEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      if (isOptionSelected) {
        return onCloseClick(option.value);
      }

      onOptionClick(option);
    },
    [isOptionSelected, onCloseClick]
  );

  const labelParts = useMemo<TDropdownBodyOptionLabelParts>(() => {
    if (!isSearch) {
      return {
        firstLabelPart: '',
        lastLabelPart: '',
      };
    }

    const regExpSearch = new RegExp(searchValue, 'i');

    const foundPos = option.label.search(regExpSearch);

    if (foundPos !== 0) {
      return {
        firstLabelPart: '',
        lastLabelPart: option.label,
      };
    }

    const firstLabelPart = option.label.match(new RegExp(`${searchValue}`, 'i'));
    const lastLabelPart = option.label.slice(foundPos + (searchValue.length - 1) + 1);

    return {
      firstLabelPart: firstLabelPart?.[0] || '',
      lastLabelPart,
    };
  }, [option, searchValue, isSearch]);

  if (!isSearch) {
    return (
      <Styled.Wrapper
        onClick={handleOptionClick}
        data-value={`dropdown-body-option-value-${dataTestId}`}
        data-is-selected={isOptionSelected}
      >
        {isDisplayCheckbox && (
          <Checkbox
            onChange={e => 1}
            value={isOptionSelected}
            dataTestId={`dropdown-body-option-checkbox-${dataTestId}`}
          />
        )}

        <Styled.Option data-test-id={`dropdown-body-option-title-${dataTestId}`}>
          {option.label}
        </Styled.Option>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper
      onClick={handleOptionClick}
      data-value={`dropdown-body-option-value-${dataTestId}`}
      data-is-selected={isOptionSelected}
    >
      {isDisplayCheckbox && (
        <Checkbox
          onChange={e => 1}
          value={isOptionSelected}
          dataTestId={`dropdown-body-option-checkbox-${dataTestId}`}
        />
      )}

      <Styled.Option data-test-id={`dropdown-body-option-title-${dataTestId}`}>
        {labelParts.firstLabelPart.length > 0 && (
          <Styled.MatchedValue>{labelParts.firstLabelPart}</Styled.MatchedValue>
        )}
        {labelParts.lastLabelPart}
      </Styled.Option>
    </Styled.Wrapper>
  );
};

DropdownBodyOption.displayName = 'DropdownBodyOption';

export default memo(DropdownBodyOption);
