import { observer } from 'mobx-react';
import { FC, memo } from 'react';

import { useStore } from '../../../../../../shared/utils/IoC';
import { ChecklistInstancesController } from '../../../controllers/checklist.instances.controller';
import { ChecklistInstancesStore } from '../../../stores/checklist.instances.store';
import { ListOfChecklistInstance } from '../ListOfChecklistInstance';
import { useTasksRouteActions } from '../../../../tasks/hooks';

interface IProps {
  taskId: string;
  isFromFullscreen?: boolean;
}

const TechniqueListContainer: FC<IProps> = observer(({ taskId, isFromFullscreen }) => {
  const { listOfDrawMachineryInst } = useStore(ChecklistInstancesStore);
  const { createMachineryInst } = useStore(ChecklistInstancesController);

  const { goToChecklistEdit } = useTasksRouteActions();

  const handleAddButtonClick = async (): Promise<void> => {
    const createdInst = await createMachineryInst(taskId);

    if (createdInst) {
      goToChecklistEdit(createdInst.id, taskId);
    }
  };

  return (
    <ListOfChecklistInstance
      mode={isFromFullscreen ? 'fullscreen' : 'listing'}
      type="technique"
      title={isFromFullscreen ? 'У вас ещё нет добавленной техники' : 'Техника'}
      description={
        isFromFullscreen
          ? 'Добавьте рабочую технику, чтобы проверить корректность её настроек в любой момент'
          : 'В полноэкранном шаблоне создания задачи вы можете добавить используемую технику'
      }
      instList={listOfDrawMachineryInst}
      addButton={{
        title: 'Добавить технику',
        onClick: handleAddButtonClick,
      }}
      variant="fullscreen"
    />
  );
});

TechniqueListContainer.displayName = 'TechniqueListContainer';

export default memo(TechniqueListContainer);
