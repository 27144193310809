import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../shared/constans/colors';

const HeaderTabWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  list-style-type: none;
  gap: 12px;
  margin-bottom: 24px !important;
`;

const HeaderTab = styled.li<{ $isActive: boolean; $isDisabled?: boolean }>`
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding: 0 0 8px 0;
  position: relative;

  &:hover {
    &:after {
      background: ${({ $isActive }) => ($isActive ? Colors.skyHover : Colors.borderGray)};
    }

    & > a {
      color: ${({ $isActive }) => ($isActive ? Colors.skyHover : Colors.hoverDarkGray)};
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    background: ${({ $isActive }) => ($isActive ? Colors.sky : 'transparent')};
    height: 2px;
    border-radius: 2px;
    transition: 0.3s;
  }

  & > a {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.15px;
    transition: 0.3s;

    color: ${({ $isActive }) => ($isActive ? Colors.sky : Colors.darkGray)};
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: default;

      &:hover {
        color: ${Colors.borderGray};
      }

      & > a {
        cursor: default;
        color: ${Colors.borderGray} !important;
      }

      &:after {
        content: none;
      }
    `}
`;

const Styled = {
  HeaderTab,
  HeaderTabWrapper,
};

export default Styled;
