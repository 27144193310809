import * as L from 'leaflet';
import * as turf from '@turf/turf';

import { provide } from '../../../../../utils/IoC';
import { CultureZone, Field } from '../../../../../../../api/models/field.model';

@provide.transient()
class MapAdditionsService {
  addVerticalCorners = (instance: L.Map) => {
    // @ts-ignore
    const corners = instance._controlCorners;
    const prefix = 'leaflet-';
    // @ts-ignore
    const container = instance._controlContainer;

    function createCorner(vSide, hSide) {
      const className = `${prefix + vSide} ${prefix}${hSide}`;

      corners[vSide + hSide] = L.DomUtil.create('div', className, container);
    }

    createCorner('verticalcenter', 'left');
    createCorner('verticalcenter', 'right');
  };

  cordsToPoint = (cords: number[]) => {
    return turf.point(cords);
  };

  isPointInZone = (
    zone: Field | CultureZone,
    point: turf.helpers.Feature<turf.helpers.Point>
  ): boolean => {
    const turfPolygon = turf.polygon(zone.geometry.coordinates);

    return turf.booleanPointInPolygon(point, turfPolygon);
  };
}

export default MapAdditionsService;
