import { FC, memo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { useDataTestId } from '../../../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './TasksListDaysLeftValue.styles';

interface IProps {
  id: string;
  icon: 'start' | 'finish' | 'clock' | 'check' | 'calendar';
  title: string;
  tooltipTitle: string;
}

const TasksListDaysLeftValue: FC<IProps> = ({ id, icon, title, tooltipTitle }) => {
  const getDataTestId = useDataTestId(`tasks-table-days-left-cell-${icon}`);

  return (
    <AutoTooltip
      dataTestId={`tasks-table-days-left-cell-${id}`}
      content={tooltipTitle}
      disabled={tooltipTitle.length === 0}
    >
      <Styled.Wrapper {...getDataTestId('wrapper')}>
        <Styled.Icon $icon={icon} {...getDataTestId('icon')} />

        <Styled.Title $isAccentPink={icon === 'clock'} {...getDataTestId('title')}>
          {title}
        </Styled.Title>
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

TasksListDaysLeftValue.displayName = 'TasksListDaysLeftCellNewPlan';

export default memo(TasksListDaysLeftValue);
