export const WeatherEngToRus = (key: string): string => {
  const list = {
    clear: 'ясно',
    'partly-cloudy': 'малооблачно',
    cloudy: 'облачно',
    overcast: 'пасмурно',
    drizzle: 'морось',
    'light-rain': 'небольшой дождь',
    rain: 'дождь',
    'moderate-rain': 'умеренно сильный дождь',
    'heavy-rain': 'сильный дождь',
    'continuous-heavy-rain': 'длительный сильный дождь',
    showers: 'ливень',
    'wet-snow': 'дождь со снегом',
    'light-snow': 'небольшой снег',
    snow: 'снег',
    'snow-showers': 'снегопад',
    hail: 'град',
    thunderstorm: 'гроза',
    'thunderstorm-with-rain': 'дождь с грозой',
    'thunderstorm-with-hail': 'гроза с градом',
  };

  // @ts-ignore
  return list[key];
};
