import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  data: any;
  url: any;
};

type TypeResponse = {};

export const uploadFileFinish: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => request.url,
  method: 'PUT',
  headers: { 'DA-HOST': 'da-profile' },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
