import styled from 'styled-components';

import { BorderRadius, FontSizes } from '../../../shared/constans/sizes';
import { Colors } from '../../../shared/constans/colors';

import Dummy_map_bg from './assets/dummy.svg';

type PropType = {
  visibility?: boolean;
  imgUrl?: any;
};

export const FWSlider = styled.div<PropType>`
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${Colors.gray};
  position: absolute;
  z-index: 1010;
  width: ${({ visibility }) => (visibility ? '100%' : '0')};
  transition: all 0.3s ease;
  padding: ${({ visibility }) => (visibility ? '24px' : '0')};
  @media (max-width: 1365px) {
    padding: ${({ visibility }) => (visibility ? '16px' : '0')};
  }
  @media (max-width: 1024px) {
    padding: ${({ visibility }) => (visibility ? '12px' : '0')};
  }
  overflow-y: scroll;
  /* ie 10+ */
  & {
    -ms-overflow-style: none;
  }

  /* фф (свойство больше не работает, других способов тоже нет)*/
  & {
    overflow: -moz-scrollbars-none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const MainInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  margin-bottom: 24px;
  flex-shrink: 0;
  @media (max-width: 1366px) {
    margin-bottom: 16px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 12px;
  }
`;
export const SnapshotOfMap = styled.img<PropType>`
  @media (max-width: 1365px) {
    display: none;
  }
  height: 100%;
  border-radius: ${BorderRadius.default};
`;

export const NoPicDummyWrapper = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  aspect-ratio: 1 / 1;
  padding: 24px;
  background: #fff;
  border-radius: ${BorderRadius.default};
  @media (min-width: 1366px) {
    display: flex;
  }
`;

export const PicDummy = styled.img.attrs({
  src: Dummy_map_bg
})`
  margin-bottom: 22px;
`;

export const DummyText = styled.p`
  ${FontSizes.medium}
  color: ${Colors.black};
  width: 100%;
  text-align: center;
`;