import { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';

import {
  TChecklistsIntegerAttrToDraw as TIntegerAttrToDraw,
  TChecklistsDoubleAttrToDraw as TDoubleAttrToDraw,
} from '../../../models';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { ChecklistsStore } from '../../../mobx/stores';
import { useChecklistsAttrErrorList } from '../../../hooks';
import { InputFieldError } from '../../../../../../../../shared/components/InputFieldError';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { Input } from '../../../../../../operationsAndTasks/modules/fullscreen/checklist/components/shared/Input';
import { ChecklistsController } from '../../../mobx/controllers';
import { ChecklistsAttr as Attribute } from '../../../components/elements';
import { createChecklistsAttributeId as createAttrId } from '../../../helpers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

import {
  AverageAttributeCulledItemsBody,
  AverageAttributeItemsBody as ItemsBody,
} from './components';
import Styled from './ChecklistsAverageAttribute.styles';
import cullNumbersModalConfig from './modals/ChecklistsAverageAttriCullNumbersModal/ChecklistsAverageAttrCullNumbersModal';
import AverageAttributeHelpers from './helpers/AverageAttribute.helpers';

interface IProps {
  attrToDraw: TIntegerAttrToDraw | TDoubleAttrToDraw;
}

const { getValueListToCull } = AverageAttributeHelpers;

const ChecklistsAverageAttribute: FC<IProps> = ({ attrToDraw }) => {
  const {
    id,
    groupId,
    initialModel,
    options,
    isBlocked,
    validationScheme,
    dependentFileAttrId,
  } = attrToDraw;

  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);

  const { openModalByModalId, registerModalList } = useModal();

  const dependentFileAttr = checklistsStore.getAttrToDraw<EAttrType.FileLink>(
    groupId,
    dependentFileAttrId
  );

  const visibilityResult = initialModel.visibility
    ? checklistsController.calculateAttrVisibility(groupId, id)
    : { value: true };

  useEffect(() => {
    checklistsController.toggleAttrVisibility(groupId, id, visibilityResult.value as boolean);

    if (!dependentFileAttr) return;

    checklistsController.toggleAttrVisibility(
      groupId,
      dependentFileAttrId,
      visibilityResult.value as boolean
    );
  }, [JSON.stringify(visibilityResult)]);

  useEffect(() => {
    registerModalList([cullNumbersModalConfig], cullNumbersModalConfig.id);
  }, []);

  const errorList = useChecklistsAttrErrorList(
    validationScheme,
    dependentFileAttr?.validationScheme
  );

  const handleCullNumbersClick = () => {
    openModalByModalId(cullNumbersModalConfig.id, {
      attrToDraw,
    });
  };

  const valueListToCull = useMemo(() => {
    return getValueListToCull(options?.averageData?.rowList);
  }, [options?.averageData?.rowList]);

  if (!visibilityResult.value) {
    return <></>;
  }

  return (
    <Attribute
      width={initialModel.position.width}
      isNewLine={initialModel.position.newLine}
      id={createAttrId(groupId, id)}
    >
      <Styled.Wrapper $isError={Boolean(errorList.length)}>
        <Styled.Header>
          <Styled.TitleWrapper>
            <Styled.TitleAndErrorContainer>
              {initialModel.attribute?.name ? (
                <Styled.Title $isRequired={initialModel.isRequired}>
                  {initialModel.attribute?.name}
                </Styled.Title>
              ) : null}

              <InputFieldError error={{ errorList }} />
            </Styled.TitleAndErrorContainer>

            {!isBlocked ? (
              <Styled.ActionWrapper>
                <Styled.Action
                  type={'button'}
                  onClick={handleCullNumbersClick}
                  $isDisabled={!valueListToCull.length}
                >
                  Выбраковать значения
                </Styled.Action>
              </Styled.ActionWrapper>
            ) : null}
          </Styled.TitleWrapper>

          {initialModel.attribute.placeholder ? (
            <Styled.Description>{initialModel.attribute.placeholder}</Styled.Description>
          ) : null}
        </Styled.Header>

        <Styled.ItemsBodyWrapper>
          <ItemsBody attrToDraw={attrToDraw} />

          {dependentFileAttrId ? (
            <FileAttrContainer groupId={groupId} attrId={dependentFileAttrId} />
          ) : null}
        </Styled.ItemsBodyWrapper>

        <Styled.CalculatedDataWrapper>
          <Input label={'Среднее'} isBlocked value={attrToDraw.options.averageData.average} />
          <Input label={'Минимум'} isBlocked value={attrToDraw.options.averageData.min} />
          <Input label={'Максимум'} isBlocked value={attrToDraw.options.averageData.max} />
        </Styled.CalculatedDataWrapper>

        {attrToDraw.options.averageData.culledValueList.length ? (
          <AverageAttributeCulledItemsBody
            attrToDraw={attrToDraw}
            culledValueList={attrToDraw.options.averageData.culledValueList}
            isBlocked={isBlocked}
          />
        ) : null}
      </Styled.Wrapper>
    </Attribute>
  );
};

ChecklistsAverageAttribute.displayName = 'ChecklistsAverageAttribute';

export default observer(ChecklistsAverageAttribute);
