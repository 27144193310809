import React, { FC } from 'react';

import { FieldWeather } from '../../../FieldWeather';
import { FieldSoilComposition } from '../../../FieldSoilComposition';
import { FieldInfo } from '../../../FieldInfo';

export const Main: FC = () => {
  return (
    <>
      <FieldWeather back_btn={false} />
      {/* <FieldSoilComposition /> */}
      <FieldInfo />
    </>
  );
};
