import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';

export const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  position: relative;
`;

export const Line = styled.div`
  width: 248px;
  height: 0.5px;
  background: ${Colors.grayBorder};
  position: absolute;
  bottom: 0;
  left: 16px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
`;

export const MapImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: ${Colors.gray};
  border: 1px solid ${Colors.grayBorder};
`;

export const MapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: ${Colors.gray};
  border: 1px solid ${Colors.grayBorder};
  & svg {
    fill: ${Colors.darkGray};
    width: 16px;
    height: 16px;
  }
`;

export const RightColumn = styled.div`
  width: 192px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const BottomSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;
export const AreaLabel = styled.p<{ isError?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;

  color: ${({ isError }) => isError && Colors.red};
`;

export const Delete = styled.p`
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.red};
  margin: 0;
`;
