import { IBreadcrumbsEntity } from '../../../../../../api/models/breadcrumbs/breadcrumbs.model';
import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../utils/axios2';

@provide.singleton()
class ChecklistsService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  getChecklistInstanceList = async (
    payload: TypeApiRequest<'getChecklistInstanceList'>
  ): Promise<TypeApiResponse<'getChecklistInstanceList'>> => {
    try {
      const queryTypes = payload?.types?.join?.('&types=');

      const response = await this.axiosService.api.getChecklistInstanceList(payload, {
        omit: ['taskId', 'types'],
        query: {
          types: queryTypes ? queryTypes : null,
        },
      });

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getStageList = async (
    payload: TypeApiRequest<'getChecklistStageList'>
  ): Promise<TypeApiResponse<'getChecklistStageList'>> => {
    try {
      const response = await this.axiosService.api.getChecklistStageList(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getAttributeList = async (
    payload: TypeApiRequest<'getChecklistAttributeList'>
  ): Promise<TypeApiResponse<'getChecklistAttributeList'>> => {
    try {
      const response = await this.axiosService.api.getChecklistAttributeList(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getAttributeListByPublicId = async (
    payload: TypeApiRequest<'getChecklistAttributeByCheckListPublicId'>
  ): Promise<TypeApiResponse<'getChecklistAttributeByCheckListPublicId'>> => {
    try {
      const response = await this.axiosService.api.getChecklistAttributeByCheckListPublicId(
        payload,
        { omit: ['publicId'] }
      );

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  saveInstanceAttributeValues = async (
    payload: TypeApiRequest<'postChecklistInstanceAttributeValues'>
  ): Promise<TypeApiResponse<'postChecklistInstanceAttributeValues'>> => {
    try {
      const response = await this.axiosService.api.postChecklistInstanceAttributeValues(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getUserDictionaryList = async (
    payload: TypeApiRequest<'getChecklistUserDictionaryListByAttributeId'>
  ): Promise<TypeApiResponse<'getChecklistUserDictionaryListByAttributeId'>> => {
    try {
      const response = await this.axiosService.api.getChecklistUserDictionaryListByAttributeId(
        payload,
        { omit: ['attributeId'] }
      );

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getEnumList = async (
    payload: TypeApiRequest<'getChecklistEnumListByAttributeId'>
  ): Promise<TypeApiResponse<'getChecklistEnumListByAttributeId'>> => {
    try {
      const response = await this.axiosService.api.getChecklistEnumListByAttributeId(payload, {
        omit: ['attributeId'],
      });

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  fetchDictionaryItemBreadcrumbs = async (
    remoteName: string,
    id: string
  ): Promise<IBreadcrumbsEntity> => {
    try {
      const breadcrumbs = await this.axiosService.api.getDictionaryItemBreadcrumbs(
        { remoteName, id },
        {
          omit: ['remoteName', 'id'],
        }
      );

      return breadcrumbs;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}

export default ChecklistsService;
