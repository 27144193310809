import { FC, useState } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../shared/utils/IoC';
import MapStore, { MapMode } from '../../stores/map.store';

import { ReactComponent as BinSvg } from './../../static/bin.svg';
import { Control, TipTail, Tooltip, Wrapper } from './style';

export const CreateControls: FC = observer(() => {
  const map = useStore(MapStore);

  const [isShowTooltip, setIsShowTooltip] = useState(false);

  const { mapMode, cancelDrawing, clearPointsOfUnfinishedContour, pointsOfUnfinishedContour } = map;

  if (mapMode !== MapMode.Creating) {
    return null;
  }

  return (
    <Wrapper data-test-id="map-user-controls-trashcan">
      <Control
        disabled={pointsOfUnfinishedContour === 0}
        onMouseOver={() => setIsShowTooltip(true)}
        onMouseOut={() => setIsShowTooltip(false)}
        onClick={() => {
          cancelDrawing();
          clearPointsOfUnfinishedContour();
        }}
      >
        <BinSvg />
      </Control>
      {isShowTooltip && (
        <Tooltip>
          <p>Удалить незаконченный контур</p>
          <TipTail />
        </Tooltip>
      )}
    </Wrapper>
  );
});
