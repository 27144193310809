import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  operationTypeId: string;
  startDate: string;
  endDate: string;
  seasonYear: number;
  name: string;
  cultureId: string;
  organizationId: string;
};

type TypeResponse = {
  operationTypeId: string;
  startDate: string;
  endDate: string;
  seasonYear: number;
  name: string;
  cultureId: string;
  id: string;
};

export const createOperation: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request =>
    `/api/as-fields/operation?organizationId=${request.organizationId !== 'my' ? request.organizationId : ''}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
