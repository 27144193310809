import { TypeApiRoute } from '../../models/type.api.request';
import { CreateFieldModel } from '../../models/create.field.model';
import { CultureZone } from '../../models/update.culture.zone';

type TypeRequest = Array<CultureZone>;

type InputData = {
  zones: TypeRequest;
  seasonYear: number;
  organizationId: string;
};

type TypeResponse = Array<string>;

export const createCultureZones: TypeApiRoute & {
  request: InputData | ((request: any) => TypeRequest);
  response: TypeResponse;
} = {
  url: req =>
    `/api/as-fields/fields/cultureZones/create?seasonYear=${req.seasonYear}${
      req.organizationId === 'my' ? '' : `&organizationId=${req.organizationId}`
    }`,
  method: 'POST',
  headers: {},
  request: req => req.zones,
  response: {} as TypeResponse,
};
