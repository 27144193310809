import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../shared/utils/IoC';
import { ChecklistsStore } from '../../../mobx/stores';
import { useSortBy } from '../../../../../../../../shared/features/utils/hooks/sort';
import { ChecklistsController } from '../../../mobx/controllers';
import { useTasksParams } from '../../../../../hooks';
import { IGetChecklistStage as IStage } from '../../../../../../../../../api/models/checklist/stage/checklist.stage.model';
import { ChecklistStageSkeleton, ChecklistsStage as Stage } from '../../../components/elements';
import { ChecklistInstancesStore } from '../../../../../../operationsAndTasks/stores/checklist.instances.store';

interface IProps {
  stage: IStage;
}

const ChecklistsStageContainer: FC<IProps> = ({ stage }) => {
  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);
  const { selectedInstance } = useStore(ChecklistInstancesStore);

  const params = useTasksParams();

  const [isLoading, setIsLoading] = useState(false);

  const attrToDrawList = useSortBy(checklistsStore.getAttrToDrawListByGroupId(stage.id));

  useEffect(() => {
    if (!selectedInstance?.id) {
      return;
    }

    setIsLoading(true);

    checklistsController
      .createAttrToDrawListByStageId(params.taskId, stage.id)
      .finally(() => setIsLoading(false));
  }, [selectedInstance]);

  return (
    attrToDrawList?.length > 0 &&
    (isLoading ? (
      <ChecklistStageSkeleton />
    ) : (
      <Stage stage={stage} attrToDrawList={attrToDrawList} />
    ))
  );
};

ChecklistsStageContainer.displayName = 'ChecklistsStageContainer';

export default observer(ChecklistsStageContainer);
