import { useMemo } from 'react';

import { EChecklistAttributeType as EAttrType } from '../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { getChecklistsAttrPlaceholder as getAttrPlaceholder } from '../../helpers';

const useChecklistAttrPlaceholder = (attrType: EAttrType, placeholder?: string): string => {
  return useMemo(() => placeholder || getAttrPlaceholder(attrType), [attrType, placeholder]);
};

export default useChecklistAttrPlaceholder;
