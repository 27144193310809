import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

export const TASK_DELETION_MODAL = 'TASK_DELETION_MODAL';

const deletionModalConfig: TModalConfig = {
  name: TASK_DELETION_MODAL,
  id: TASK_DELETION_MODAL,
  dataTestId: 'deletionModal',
  title: 'Вы уверены, что хотите удалить задачу?',
  type: EModalType.Warning,
  denyButton: {
    title: 'Не удалять',
  },
  successButton: {
    title: 'Удалить',
    color: 'secondary',
  },
};

export default deletionModalConfig;
