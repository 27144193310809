import { provide } from '../../../../shared/utils/IoC';
import { PopupPages } from '../../../constants/popup.pages';
import { MapMode } from '../../../../map/stores/map.store';

import { FieldsController } from './fields.controller';

@provide.singleton()
export class FieldsListingController extends FieldsController {
  centerMapWithoutSelect = () => {
    const fieldId = Array.from(this.fieldsStore.idToFields.keys()).shift();
    if (fieldId) {
      const field = this.fieldsStore.idToFields.get(fieldId);
      this.mapStore.centerMapToPoint(field.polyId);
    }
  };

  setMapModeToListining = () => {
    this.mapStore.clearAll();
    this.setMapModeValue(MapMode.Listing);

    this.setNonePageState();
  };

  updateListingAfterChangeSeasson = async () => {
    if (
      this.fieldsStore.mapMode === MapMode.Listing &&
      this.uiStore.popupPageState !== PopupPages.Seasons &&
      this.uiStore.popupPageState !== PopupPages.CultureZone
    ) {
      if (
        this.fieldsStore.prevSeason !== this.seasonsStore.selectedSeason ||
        (this.organizationStore.selectedOrganizationId !== this.fieldsStore.prevOrganization &&
          this.fieldsStore.prevSeason === this.seasonsStore.selectedSeason)
      ) {
        this.setNonePageState();
        await this.clearListing();

        if (this.seasonsStore.selectedSeason) {
          if (!this.fieldsImportStore.listOfImportedField.length) {
            await this.fetchFieldsList();
          }
        }

        this.setNonePageState();
        this.centerMapWithoutSelect();

        this.setNoneFieldFill(true);
        if (this.uiStore.showFullWeather) this.uiStore.setFullWeatherMode(false);
        if (this.uiStore.showPopupSlider) this.uiStore.setShowPopupSlider(false);
      }
    }
  };

  goToFieldCreating = () => {
    this.selectFieldId('');
    this.uiStore.setPageState(PopupPages.None, false, true);
    this.uiStore.setFullWeatherMode(false);
    this.mapStore.unbindCreatingEvents();
  };
}
