import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios, TypeApiRequest } from '../../../../utils/axios2';
import { TypeUser } from '../../../../../../api/models/user.model';

@provide.singleton()
class OrganizationsService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  getUserList = async (payload: TypeApiRequest<'getOrganizationUsers'>): Promise<TypeUser[]> => {
    try {
      const { content } = await this.axiosService.api.getOrganizationUsers(payload);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default OrganizationsService;
