import styled from 'styled-components';

import { Colors } from '../../../shared/constans/colors';

export const Wrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  flex-direction: row;
  z-index: 1005;
`;

export const Tooltip = styled.div`
  display: flex;
  height: 40px;
  right: 0;
  top: 56px;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background: ${Colors.white};
  filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.1));
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    margin: 0;
    color: ${Colors.black};
  }
`;

export const TipTail = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  top: -7px;
  right: 22px;
  transform: rotate(90deg);
  border-right: 5px solid ${Colors.white};
`;

export const Control = styled.div<{ disabled: boolean }>`
  position: relative;
  width: 48px;
  height: 48px;
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};

  & > svg {
    fill: ${({ disabled }) => (disabled ? Colors.darkGray : Colors.black)};
    width: 20px;
  }
`;
