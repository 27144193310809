import { useState, useRef, useEffect } from 'react';

/**
 * @param depForUpdate - Зависимость, при которой мы будем обновлять флаг по скрытию/показу тултипа.
 */
const useShowTooltip = <T extends HTMLElement>(depForUpdate?: any) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);
  const ref = useRef<T | null>(null);

  useEffect(() => {
    if (ref.current) {
      if (ref.current.scrollWidth > ref.current.clientWidth) {
        setIsShowTooltip(true);
      } else {
        setIsShowTooltip(false);
      }
    }
  }, [depForUpdate]);

  return { ref, isShowTooltip };
};

export default useShowTooltip;
