import { observer } from 'mobx-react';
import React from 'react';

import MapStore, { MapMode } from '../../../map/stores/map.store';
import { AvailableCultures } from '../AvailableCultures';
import { useStore } from '../../../shared/utils/IoC';
import { FieldsStore } from '../../modules/fields/stores/fields.store';
import { CultureFillEnum } from '../../constants/culture.fill.enum';
import { UiStore } from '../../stores/ui.store';
import { PopupPages } from '../../constants/popup.pages';
import { StaticLegend } from '../StaticLegend';

export const LegendControls = observer(() => {
  const fieldsStore = useStore(FieldsStore);
  const ui = useStore(UiStore);

  const { mapMode } = fieldsStore;
  return (
    <>
      {mapMode === MapMode.Listing &&
        (fieldsStore.showCulturesFillPanel === CultureFillEnum.Ndvi ||
          fieldsStore.showCulturesFillPanel === CultureFillEnum.Msavi) &&
        (ui.popupPageState === PopupPages.None || ui.popupPageState === PopupPages.Main) &&
        fieldsStore.fields.length && <StaticLegend />}
      {mapMode === MapMode.Listing &&
        fieldsStore.showCulturesFillPanel === CultureFillEnum.Fill &&
        (ui.popupPageState === PopupPages.None || ui.popupPageState === PopupPages.Main) && (
          <AvailableCultures />
        )}
    </>
  );
});
