import { FC, MouseEvent, useMemo } from 'react';

import { SELECT_ALL_OPTION } from '../../constants';

import Styled from './DropdownBody.styles';
import { TDropdownBodyProps } from './DropdownBody.types';
import { DropdownBodyOption } from './components/DropdownBodyOption';

const DropdownBody: FC<TDropdownBodyProps> = ({
  type,
  handlers: { onOptionClick, onCloseClick },
  optionList,
  values,
  dataTestId,
  isSelectedSelectAllOption,
}) => {
  const handleCreateNewValueClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    onOptionClick({ label: values.searchValue?.trim(), value: values.searchValue?.trim() });
  };

  // Определяет, когда показывать кнопку по добавлению нового опшена
  const isCreateNewValueButton =
    type?.search?.options?.isAllowNewValueToBeAdded && !optionList.length && values.searchValue;

  // Определяет, когда показывать сообщение о том, что ничего не было найдено
  const isNothingFoundLabel = type?.search && !optionList.length;

  const isDisplayCheckbox = type?.multiselect?.options?.itemStyle === 'checkbox';

  const selectedValueList = useMemo(() => {
    if (values.selectValue) {
      return [values.searchValue];
    }

    if (values.selectValueList) {
      return values.selectValueList.flatMap(value => value.value);
    }
  }, [values.selectValue, values.selectValueList]);

  return (
    <Styled.Wrapper data-test-id={`dropdown-body-wrapper-${dataTestId}`}>
      <Styled.OptionList data-test-id={`dropdown-body-option-list-${dataTestId}`}>
        {optionList.map(option => {
          const isSelectAllOption = option.value === SELECT_ALL_OPTION.value;
          const isNormalOptionSelected = selectedValueList.includes(option.value);

          const isOptionSelected = isSelectAllOption
            ? isSelectedSelectAllOption
            : isNormalOptionSelected;

          return (
            <DropdownBodyOption
              key={option.value}
              option={option}
              searchValue={values.searchValue}
              selectedValues={selectedValueList}
              isSearch={Boolean(type?.search)}
              onOptionClick={onOptionClick}
              dataTestId={dataTestId}
              onCloseClick={onCloseClick}
              isDisplayCheckbox={isDisplayCheckbox}
              isOptionSelected={isOptionSelected}
            />
          );
        })}

        {isNothingFoundLabel ? (
          <Styled.NothingFoundLabel
            data-test-id={`dropdown-body-nothing-found-label-${dataTestId}`}
          >
            К сожалению, мы ничего не смогли найти
          </Styled.NothingFoundLabel>
        ) : null}

        {isCreateNewValueButton ? (
          <Styled.CreateNewValueWrapper
            onClick={handleCreateNewValueClick}
            data-test-id={`dropdown-body-create-new-value-wrapper-${dataTestId}`}
          >
            <Styled.CreateNewValueButton
              data-test-id={`dropdown-body-create-new-value-button-${dataTestId}`}
            >
              Создать
            </Styled.CreateNewValueButton>
            <Styled.CreateNewValueLabel
              data-test-id={`dropdown-body-create-new-value-label-${dataTestId}`}
            >
              {values.searchValue}
            </Styled.CreateNewValueLabel>
          </Styled.CreateNewValueWrapper>
        ) : null}
      </Styled.OptionList>
    </Styled.Wrapper>
  );
};

export default DropdownBody;
