import styled, { keyframes } from 'styled-components';

const CenterCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;

  background-color: #ffffff;
`;

const loader = keyframes`
  to {transform: rotate(-360deg);} 
`;

const Loader = styled.div`
  height: 40px;
  width: 40px;
  background: conic-gradient(from 180deg at 50% 50%, #3fb65f 0deg, rgba(98, 160, 115, 0) 360deg);
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  animation: ${loader} 1s linear infinite;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const StyledFieldsImportLoader = {
  CenterCircle,
  Title,
  Loader,
  LoaderWrapper,
  Wrapper,
};

export default StyledFieldsImportLoader;
