import { Colors } from '@farmlink/farmik-ui';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: calc(100% - 256px);
  background-color: ${Colors.generalWhite};
  height: 40px;
  border-radius: 12px;
  pointer-events: all;
  display: flex;
`;

const List = styled.ul`
  display: flex;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  flex-direction: row-reverse;
  flex-grow: 1;

  overflow-x: scroll;

  position: relative;

  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Item = styled.li<{ $width: number; $isSelected?: boolean }>`
  width: ${({ $width }) => $width}px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  transition-property: color, background;

  :hover {
    background-color: ${Colors.hoverGray};
  }

  :after {
    position: absolute;
    width: 1px;
    height: 20px;
    top: auto;
    right: 0;
    content: '';
    background-color: ${Colors.borderGray};
    border-radius: 1px;
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${Colors.green};

      :hover {
        background-color: ${Colors.hoverGreen};
      }

      :after {
        content: none;
      }

      & > span {
        color: ${Colors.generalWhite};
        font-width: 600;
      }
    `};
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  width: 64px;
  flex-shrink: 0;
  padding: 10px 8px;
  gap: 8px;

  position: relative;

  :after {
    position: absolute;
    width: 1px;
    height: 20px;
    top: auto;
    left: 0;
    content: '';
    background-color: ${Colors.borderGray};
    border-radius: 1px;
  }
`;

const NoData = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  gap: 8px;

  & .map-timeline-no-data-text {
    line-height: 16px;
  }
`;

const NoDataGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-left: 8px;
  align-items: center;
`;

const ReloadButton = styled.button`
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: ${Colors.darkGrey};
  font-weight: 600;

  :hover {
    color: ${Colors.generalDarkHover};
  }
`;

const Styled = {
  Wrapper,
  List,
  Item,
  Controls,
  NoData,
  ReloadButton,
  NoDataGroup,
};

export default Styled;
