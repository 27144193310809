import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  fieldId: string;
  fromSeasonYear: number;
  toSeasonYear: number;
};

type TypeResponse = {};

export const copyFieldById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request =>
    `/api/as-fields/fields/${request.fieldId}/copy?fromSeasonYear=${request.fromSeasonYear}&toSeasonYear=${request.toSeasonYear}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
