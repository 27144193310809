import styled from 'styled-components';

const Wrapper = styled.div`
  padding-bottom: 16px;

  border-bottom: 1px solid #e9e6f0;

  background-color: white;
`;

const StyledTableBuilderHeader = {
  Wrapper,
};

export default StyledTableBuilderHeader;
