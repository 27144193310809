import { FC, MouseEvent } from 'react';

import closeDefaultSvg from '../../assets/images/close-default-16x16.svg';
import closeHoverSvg from '../../assets/images/close-hover-16x16.svg';
import arrowDownDefaultSvg from '../../assets/images/arrow-down-default-16x16.svg';
import arrowDownHoverSvg from '../../assets/images/arrow-down-hover-16x16.svg';
import arrowTopHoverSvg from '../../assets/images/arrow-top-hover-16x16.svg';
import minusDefaultSvg from '../../assets/images/minus-default-16x16.svg';

import Styled from './DropdownIcons.styles';
import { TDropdownIconsProps } from './DropdownIcons.types';

const DropdownIcons: FC<TDropdownIconsProps> = ({
  field,
  state,
  visual,
  isMultiSelect,
  dataTestId,
  handlers: { onArrowClick, onClearClick },
}) => {
  const hasValue = Boolean(
    state.values.searchValue || state.values.selectValue || state.values.selectValueList.length
  );

  const isValueAfterCleaning = Boolean(state.values.selectValue)
    ? state.values.selectValue?.value === field?.icons?.clear?.options?.valueAfterCleaning?.value
    : false;

  const handleClearClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    onClearClick();
  };

  const handleArrowClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    onArrowClick();
  };

  if (visual?.isBlocked) {
    return (
      <Styled.Wrapper
        $isMultiSelect={isMultiSelect}
        $isBlocked={visual?.isBlocked}
        data-test-id={`${dataTestId}_blockedIcon`}
      >
        <Styled.Minus $defaultSvg={minusDefaultSvg} />
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper $isMultiSelect={isMultiSelect} $isBlocked={visual?.isBlocked}>
      {field?.icons?.clear && hasValue && !isValueAfterCleaning ? (
        <Styled.ClearIcon
          onClick={handleClearClick}
          $defaultSvg={closeDefaultSvg}
          $hoverSvg={closeHoverSvg}
          $isDropped={state.settings.isDropped}
          data-test-id={`${dataTestId}_click-action-clear`}
        />
      ) : null}

      <Styled.ArrowIcon
        onClick={handleArrowClick}
        $arrowTopHoverSvg={arrowTopHoverSvg}
        $arrowDownDefaultSvg={arrowDownDefaultSvg}
        $arrowDownHoverSvg={arrowDownHoverSvg}
        $isDropped={state.settings.isDropped}
        data-test-id={`${dataTestId}_click-action`}
      />
    </Styled.Wrapper>
  );
};

export default DropdownIcons;
