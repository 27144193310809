import { memo, FC } from 'react';
import moment from 'moment';

import {
  ETaskEventType,
  ITaskEvent,
} from '../../../../../../api/models/task/events/task.event.mode';

import Styled from './TaskHistoryTooltip.styles';

interface IProps {
  eventList?: ITaskEvent[];
}

const TASK_EVENT_TYPE_SCHEME: Record<ETaskEventType, string> = {
  NEW: 'Новая',
  CANCELED: 'Отменена',
  COMPLETED: 'Завершена',
  IN_WORK: 'В работе',
};

const TaskHistoryTooltip: FC<IProps> = ({ eventList = [] }) => {
  return (
    <Styled.List>
      {eventList.map(event => (
        <Styled.Item>
          <Styled.Content>
            {TASK_EVENT_TYPE_SCHEME[event.typeEvent] ?? 'Другое'}: {event?.createdBy?.fullName}
          </Styled.Content>
          {event.creationDate && (
            <Styled.Date>{moment(event.creationDate).format('DD.MM.YYYY')}</Styled.Date>
          )}
        </Styled.Item>
      ))}
    </Styled.List>
  );
};

export default memo(TaskHistoryTooltip);
