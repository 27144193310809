import styled from 'styled-components';

import { Colors } from '../../../../../../../../../../../../shared/constans/colors';

import clearSvg from './assets/images/clear-icon-12x12.svg';

const { borderGray, red } = Colors;

const Clear = styled.div`
  flex: 0 0 12px;

  width: 12px;
  height: 12px;

  cursor: pointer;

  background: url(${clearSvg});
`;

const Value = styled.div`
  flex: 1 1 50%;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
`;

const getWrapWidth = ($isBlocked?: boolean, $isValueTooLong?: boolean): string => {
  if ($isBlocked && $isValueTooLong) {
    return '155px';
  }

  if ($isBlocked) {
    return '55px';
  }

  if ($isValueTooLong) {
    return '175px';
  }

  return '75px';
};

const Wrapper = styled.div<{ $isBlocked?: boolean; $isValueTooLong?: boolean }>`
  width: ${({ $isBlocked, $isValueTooLong }) => getWrapWidth($isBlocked, $isValueTooLong)};

  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ $isBlocked }) => ($isBlocked ? 'center' : 'space-between')};
  align-items: center;

  gap: 4px;

  padding: 6px 12px;

  border: 1px solid ${borderGray};
  border-radius: 12px;

  color: ${red};
`;

const StyledAverageAttributeCulledItem = {
  Clear,
  Value,
  Wrapper,
};

export default StyledAverageAttributeCulledItem;
