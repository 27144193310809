import { runInAction } from 'mobx';

import { CheckAccessStore } from '../../../authorization/stores/checkAccess.store';
import { Axios, TypeApiResponse } from '../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../shared/utils/IoC';
import { FieldsController } from '../../modules/fields/controllers/fields.controller';
import { FieldsImportStore } from '../../modules/fields/stores/import';
import { ProfileStore } from '../../modules/profile/stores/ProfileStore';
import { OrganizationsStore } from '../../stores/organizations.store';
import { SeasonsController } from '../seasons/seasons.controller';

@provide.singleton()
export class OrganizationsController {
  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(OrganizationsStore)
  protected organizationsStore: OrganizationsStore;

  @lazyInject(SeasonsController)
  seasonsController: SeasonsController;

  @lazyInject(FieldsController)
  fieldsController: FieldsController;

  @lazyInject(CheckAccessStore)
  checkAccessStore: CheckAccessStore;

  @lazyInject(Axios)
  axios: Axios;

  @lazyInject(FieldsImportStore)
  protected fieldsImportStore: FieldsImportStore;

  fetchOrganizations = async () => {
    let response: TypeApiResponse<'getOrganizationsMine'> = null;
    try {
      this.organizationsStore.loading = true;
      response = await this.axios.api.getOrganizationsMine({ nameOrInn: '', roles: [] });
      this.organizationsStore.loading = false;
    } catch (e) {
      this.organizationsStore.loading = false;
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }

    runInAction(() => {
      const orgMap = new Map();

      response.content.forEach(i => {
        orgMap.set(i.organizationId, i);
      });

      this.organizationsStore.setOrganizationMap(orgMap);
    });

    return response.content;
  };

  selectOrganization = async (id: string, isJustSelect?: boolean): Promise<void> => {
    this.organizationsStore.setSelectedOrganizationId(id);

    if (!isJustSelect) {
      if (id !== 'my') {
        this.checkAccessStore.clearAccessRules();
        await this.checkAccessStore.getAllAccessRulesByModule(id);
      }
      this.fieldsController.clearListing();
      await this.seasonsController.fetchMySeasons();

      if (!this.fieldsImportStore.listOfImportedField.length) {
        await this.fieldsController.fetchFieldsList();
      }
    }
  };
}
