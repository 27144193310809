import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { PopupPages } from '../../../constants/popup.pages';
import { RenderPolygonOption } from '../../../../map/consts/enum.render.option';
import { FieldSeasonsStore } from '../../../stores/field.seasons.store';

import { FieldsController } from './fields.controller';

@provide.singleton()
export class FieldsItemController extends FieldsController {
  @lazyInject(FieldSeasonsStore, 'FieldSeasonsStore')
  fieldsSeasonsStore: FieldSeasonsStore;

  goToFieldEditing = () => {
    this.uiStore.setPageState(PopupPages.None, false, true);
    this.uiStore.setFullWeatherMode(false);
    this.setMainPageState();
  };

  resetEditMode = async (exitWhenFieldDeleted = false) => {
    // Если пытаемся удалить поле с экрана истории посевов или редактирования культурной зоны, то не рендерим не заселекченные поля
    const isResetingFromSeasonsOrCultureZone =
      this.uiStore.popupPageState === PopupPages.Seasons ||
      this.uiStore.popupPageState === PopupPages.CultureZone;

    // эта блядская переменная нужна чтобы скидывался заселекченное поле после выхода из редактирования
    if (exitWhenFieldDeleted) {
      this.setNonePageState();
      this.selectFieldId('');
    }

    if (!isResetingFromSeasonsOrCultureZone || exitWhenFieldDeleted) {
      [
        ...Array.from(this.mapStore.idToPolygon.values()),
        ...Array.from(this.mapStore.idToEditableLayer.values()),
      ].forEach(l => {
        l.unbindTooltip();
        l.remove();
      });

      this.mapStore.idToPolygon.clear();
      this.mapStore.idToEditableLayer.clear();
      await this.fetchFieldsList(RenderPolygonOption.View, '', true);
    }
    //
    // if (this.seasonsStore.selectedSeason) {
    //   this.fieldsSeasonsStore.renderCultureZoneByYear(this.seasonsStore.selectedSeason);
    // }

    const polyId = this.fieldsStore.getSelectedField()?.polyId;

    if (polyId) {
      this.closeFieldTooltip(polyId);

      // making the field outline white
      this.mapStore.highlightPolygon(polyId);
    }
  };
}
