import { FC } from 'react';

import { IMapGlobalConfig } from '../../models';

import Styled from './Overlay.styles';
import { Indices } from './components/';
import { Zoom } from './components/Controls';

interface IOverlayProps {
  globalConfig: IMapGlobalConfig;
}

const Overlay: FC<IOverlayProps> = ({ globalConfig }) => {
  const isDisplayIndices = globalConfig?.fieldIndicesOptions;

  return isDisplayIndices ? (
    <Styled.Overlay>
      <Styled.Topline>
        <Indices />
      </Styled.Topline>
      <Styled.RightAside>
        <Zoom />
      </Styled.RightAside>
    </Styled.Overlay>
  ) : null;
};

export default Overlay;
