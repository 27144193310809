import { IGetChecklistAttribute } from '../../../models/checklist/attribute/checklist.attribute.model';
import { TypeApiRoute } from '../../../models/type.api.request';

export interface IGetChecklistAttributeListPayload {
  checkListId: string;
  attributeName?: string;
  activeOnly?: boolean;
  phenophaseId?: string;
  taskId?: string;
  size?: number;
  stageId?: string;
}

type TypeRequest = {} & IGetChecklistAttributeListPayload;

type TypeResponse = { content: IGetChecklistAttribute[] };

export const getChecklistAttributeList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/as-fields/checklist/attribute/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
