import styled from 'styled-components';

import { BorderRadius, FontSizes } from '../../../../../shared/constans/sizes';
import { Colors } from '../../../../../shared/constans/colors';

export const SeasonsComponentWrapper = styled.div<{ isSidebarShort: boolean }>`
  width: 100%;
  //max-width: 1240px;
  max-width: ${({ isSidebarShort }) =>
    isSidebarShort ? 'calc(100vw - 376px)' : 'calc(100vw - 512px)'};
  display: flex;
  flex-direction: column;
  height: 312px;

  @media (max-width: 1365px) {
    max-width: calc(100vw - 376px);
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  ${FontSizes.title};
`;

export const SeasonsWrapper = styled.div`
  white-space: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  width: 100%;
  overflow-y: hidden;

  /* ie 10+ */
  & {
    -ms-overflow-style: none;
  }

  /* фф (свойство больше не работает, других способов тоже нет)*/
  & {
    overflow: -moz-scrollbars-none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 248px;
`;

export const SeasonItem = styled.div<{ active?: boolean }>`
  display: inline-block;
  background: #ffffff;
  border-radius: 16px;
  border: ${({ active }) => (active ? `1px solid ${Colors.green}` : '1px solid transparent')};
  width: 296px;
  height: 248px;
  margin-right: 16px;
  vertical-align: top;
`;

export const SeasonItemHeader = styled.div`
  padding: 22px 24px 8px;
  border-bottom: 1px solid ${Colors.lightnessGray};
  position: relative;
`;

export const SeasonItemHeaderTitle = styled.div`
  ${FontSizes.large}
`;

export const SeasonItemHeaderSubTitle = styled.div`
  ${FontSizes.default};
  color: ${Colors.darkGray};
`;

export const SeasonCultureItem = styled.div`
  margin: 16px 0;
  display: flex;
  //height: 296px;
`;

export const SeasonItemBody = styled.div`
  padding: 16px 24px;
  height: 181px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 0;
  }

  /* ie 10+ */
  & {
    -ms-overflow-style: none;
  }

  /* фф (свойство больше не работает, других способов тоже нет)*/
  & {
    overflow: -moz-scrollbars-none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ${SeasonCultureItem}:first-child {
    margin-top: 0;
  }

  ${SeasonCultureItem}:last-child {
    margin-bottom: 0;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

export const SeasonCultureItemColorWrapper = styled.div`
  width: 24px;
  margin-right: 12px;
`;

export const SeasonCultureItemDescriptionWrapper = styled.div`
  width: 208px;
`;

export const SeasonCultureItemColor = styled.div<{ color: string }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ color }) => color};
`;

export const SeasonCultureItemTitle = styled.div`
  ${FontSizes.medium};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SeasonCultureItemSubTitle = styled.div`
  ${FontSizes.medium};
  color: ${Colors.darkGray};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ArrowsWrapper = styled.div`
  display: flex;
`;

export const ArrowButton = styled.div`
  background: #ffffff;
  border-radius: ${BorderRadius.default};
  width: 48px;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  cursor: pointer;
  user-select: none;
`;

export const AddCulture = styled.div`
  position: absolute;
  right: 24px;
  top: 22px;
  cursor: pointer;
`;

export const EmptyCultureWrapper = styled.div`
  text-align: center;
  margin-top: 48px;
`;

export const EmptyCultureTitle = styled.div<{ gap?: number }>`
  ${FontSizes.medium};
  color: ${Colors.darkGray};
  margin-bottom: ${({ gap }) => (gap ? `${gap}px` : '14px')};
`;

export const EmptyCultureLink = styled.div`
  ${FontSizes.medium};
  color: ${Colors.green};
  cursor: pointer;
  margin: 0 auto;
`;

export const BackButton = styled.div`
  padding: 14px 24px;
  background: ${Colors.white};
  display: flex;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
`;

export const BackButtonIcon = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  svg {
    fill: ${Colors.darkGray};
  }
`;

export const BackButtonText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const SeasonsModalTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-bottom: 24px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${Colors.grayBorder};
`;

export const SeasonsModalBody = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
