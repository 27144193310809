import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { EModalSize } from '../../../../../modals/components/Modal/styles';

export const REPORT_UPLOAD_MODAL_SUCCESS_ID = 'reportUploadModalSuccess';

export const uploadReportModalSuccess = (dateFrom: string, dateTo: string): TModalConfig => {
  return {
    id: REPORT_UPLOAD_MODAL_SUCCESS_ID,
    name: REPORT_UPLOAD_MODAL_SUCCESS_ID,
    title: `Список задач за ${dateFrom} – ${dateTo} успешно выгружен`,
    type: EModalType.Success,
    styledProps: {
      $size: EModalSize.Medium,
    },
    successButton: {
      title: 'Продолжить',
      color: 'primary',
    },
  };
};
