import { FC, memo, useEffect } from 'react';
import { Colors, IconButton } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import TaskCommonData from '../../components/TaskCommonData/TaskCommonData';
import { Widget } from '../../../Widgets';
import {
  useTaskActionControl,
  useTaskPageGlobalControl,
  useTasksParams,
  useTasksRouteActions,
} from '../../../../hooks';
import { ETaskAction } from '../../../../../../../../api/models/as-fields/task/task.model';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { TaskController } from '../../../../mobx/controllers';
import { TaskStore } from '../../../../mobx/stores';

export interface ITaskSummaryContainer {
  mode?: TaskStore['mode'];
}

const SummaryContainer: FC<ITaskSummaryContainer> = ({ mode }) => {
  const { taskId } = useTasksParams();
  const { goToTaskEdit } = useTasksRouteActions();
  const { clearTaskStore } = useStore(TaskController);

  const { fetchTaskData } = useTaskPageGlobalControl(mode, {
    isIgnoreOnMountFetch: { taskData: true },
    isIgnoreOnUnmountClear: true,
  });

  const controls = useTaskActionControl();

  const hasPermissionToEdit = controls.hasPermission(ETaskAction.TaskEditCommonInfo);
  const canEdit = mode === 'view' && hasPermissionToEdit;

  useEffect(() => {
    return () => {
      if (mode === 'edit') {
        clearTaskStore();
        fetchTaskData();
      }
    };
  }, []);

  return (
    <Widget
      sideContent={
        canEdit ? (
          <>
            <IconButton
              icon="edit"
              $padding="8px"
              $borderRadius="12px"
              size={32}
              type="button"
              dataTestId="task-edit-button"
              onClick={() => goToTaskEdit(taskId)}
              colorScheme={{
                default: {
                  background: Colors.secondaryGray,
                  icon: Colors.black,
                },
                hover: {
                  background: Colors.lightGreen,
                  icon: Colors.green,
                },
              }}
            />
          </>
        ) : null
      }
      displayTitle
      title={'Общие данные'}
      dataTestId="new-task-general-data-title"
    >
      <TaskCommonData />
    </Widget>
  );
};

export default memo(observer(SummaryContainer));
