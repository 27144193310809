import { TypeApiRoute } from '../../models/type.api.request';
import { ITaskEvent } from '../../models/task/events/task.event.mode';

type GetTaskEventListReq = {
  id: string;
};

type TypeResponse = ITaskEvent[];

export const getTaskEventList: TypeApiRoute & {
  request: GetTaskEventListReq;
  response: TypeResponse;
} = {
  url: ({ id }) => `/api/as-fields/task/${id}/events`,
  method: 'GET',
  headers: {},
  request: {} as GetTaskEventListReq,
  response: {} as TypeResponse,
};

export type { GetTaskEventListReq };
