import styled, { css } from 'styled-components';

import { Colors } from '../../../shared/constans/colors';

export const ControlPanelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1001;
  width: auto;
  max-width: 100%;
`;

export const ControlPanelItem = styled.div<{ fullWidth?: boolean; isMarginRight?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  padding-right: ${({ fullWidth }) => (fullWidth ? '16px' : '0')};
  overflow: ${({ fullWidth }) => (fullWidth ? 'hidden' : 'visible')};
  ${({ isMarginRight }) =>
    isMarginRight &&
    css`
      margin-right: 16px;
    `}
  display: flex;
`;

export const AccordeonWrapper = styled.div`
  width: 194px;
  margin-right: 16px;
  & > div {
    border: none;
    div[data-test-id='fill-switcher-header'],
    div[data-test-id='label-field-fill-switcher-header'] {
      height: 40px;
      font-size: 14px;
      line-height: 20px;
      img {
        margin-top: 0;
      }
      p[data-test-id='fill-switcher-header-label'],
      p[data-test-id='label-field-fill-switcher-header-label'] {
        font-size: 14px;
        line-height: 20px;
        &:before {
          content: 'Заливка: ';
          color: ${Colors.darkGray};
          font-size: 14px;
          line-height: 20px;
          width: 0px;
          display: inline-block;
          visibility: hidden;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    div[data-test-id='fill-switcher-body-inner'] {
      max-height: 176px !important;
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
    div[data-test-id='label-field-fill-switcher-body-inner'] {
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &.withBefore {
    p[data-test-id='fill-switcher-header-label'] {
      &:before {
        width: 52px !important;
        visibility: visible !important;
      }
    }
  }
`;
