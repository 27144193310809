import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../shared/constans/colors';

import techniqueSvg from './assets/images/technique-51x50.svg';
import pointsSvg from './assets/images/points-50x42.svg';

const Title = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

type TStyledListOfChecklistInstanceHeader = {
  $hasData: boolean;
};

const addBottomBorder = ($hasData: boolean) => {
  if ($hasData) {
    return css`
      border-bottom: 1px solid ${Colors.borderGray};
      padding-bottom: 12px;
    `;
  }
};

const Header = styled.div<TStyledListOfChecklistInstanceHeader>`
  ${({ $hasData }) => addBottomBorder($hasData)}
`;

const MachineryIcon = styled.div`
  width: 50px;
  height: 50px;
  background: url(${techniqueSvg});
`;

const PointsIcon = styled.div`
  width: 50px;
  height: 42px;
  background: url(${pointsSvg});
`;

const Description = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.secondaryDarkGray};
  flex: 1 1 50%;
`;

const NoDataWrapper = styled.div<{ $isFromFullscreen?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${({ $isFromFullscreen }) =>
    $isFromFullscreen &&
    css`
      margin-top: 4px;
    `}
`;

const Body = styled.div<{ $length: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-height: calc(100% - 30px);
  overflow-y: auto;
  min-height: ${({ $length }) => ($length >= 3 ? 183 : $length * 61)}px;

  & > :last-child {
    border-bottom: none;
  }
`;

const AddButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  color: ${Colors.green};
`;

const Footer = styled.div<{ $hasData?: boolean }>`
  ${({ $hasData }) =>
    $hasData
      ? css`
          margin-top: 10px;
        `
      : css`
          margin-top: 8px;
        `};
`;

const Wrapper = styled.div`
  width: 100%;
`;

const StyledListOfChecklistInstance = {
  Title,
  Header,
  MachineryIcon,
  PointsIcon,
  Description,
  NoDataWrapper,
  Body,
  AddButton,
  Footer,
  Wrapper,
};

export default StyledListOfChecklistInstance;
