import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../shared/utils/IoC';
import { CheckAccessStore } from '../../../authorization/stores/checkAccess.store';
import { AccessRule } from '../../../../api/api';
import { OrganizationsStore } from '../../stores/organizations.store';

import { ControlAccessRulesWrapperEl } from './style';

export enum AccessRuleVisibility {
  View = 'view',
  Disable = 'disable',
  Hide = 'hide',
}

export interface AccessRulesWrapperProps {
  actionName: string;
  mode: AccessRuleVisibility;
  children: any;
}

export const ControlAccessRulesWrapper: FC<AccessRulesWrapperProps> = observer(
  (props: AccessRulesWrapperProps) => {
    const checkAccessStore = useStore(CheckAccessStore);

    const organizationStore = useStore(OrganizationsStore);

    const { accessRules } = checkAccessStore;

    const visibility = useMemo(() => {
      if (organizationStore.selectedOrganizationId === 'my') {
        return AccessRuleVisibility.View;
      } else {
        return accessRules.some(
          (ruleItem: AccessRule) => ruleItem.code === props.actionName && ruleItem.available
        )
          ? AccessRuleVisibility.View
          : props.mode;
      }
    }, [accessRules, organizationStore.selectedOrganizationId]);

    return visibility === AccessRuleVisibility.Hide ? null : (
      <ControlAccessRulesWrapperEl className="control-access-rules-wrapper-el" mode={visibility}>
        {props.children}
      </ControlAccessRulesWrapperEl>
    );
  }
);
