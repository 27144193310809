import { EChecklistAttributeType } from '../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { EChecklistAttrRequiredText } from '../../constants';

const getRequiredText = (attrType: EChecklistAttributeType): string => {
  if (
    attrType !== EChecklistAttributeType.FileLink &&
    attrType !== EChecklistAttributeType.Boolean
  ) {
    return EChecklistAttrRequiredText.Normal;
  } else {
    return EChecklistAttrRequiredText.FileLink;
  }
};

const checklistAttrErrorHelpers = {
  getRequiredText,
};

export default checklistAttrErrorHelpers;
