import styled, { css } from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';

const Status = styled.div<{ $isSelected?: boolean }>`
  width: 4px;
  height: 86.87px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0px 5px 5px 0px;
  background-color: ${({ $isSelected }) => ($isSelected ? Colors.green : Colors.red)};
`;

const MapImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: ${Colors.gray};
  border: 1px solid ${Colors.grayBorder};
`;

const MapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: ${Colors.gray};

  & svg {
    fill: ${Colors.darkGray};
    width: 16px;
    height: 16px;
  }
`;

const AreaLabel = styled.p<{ $isError?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;

  color: ${({ $isError }) => $isError && Colors.red};
`;

const Delete = styled.p<{ $isSelectedWithoutError: boolean }>`
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${({ $isSelectedWithoutError }) => ($isSelectedWithoutError ? Colors.green : Colors.red)};
`;

const RightColumn = styled.div`
  margin-left: 8px;
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
`;

const BottomSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

const ContentWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div<{ $isSelected?: boolean }>`
  padding: 16px;
  position: relative;
  cursor: pointer;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${Colors.gray};
    `}
`;

const StyledEditableFieldItem = {
  Status,
  MapImage,
  MapIcon,
  AreaLabel,
  Delete,
  RightColumn,
  BottomSectionWrapper,
  ContentWrapper,
  Wrapper,
};

export default StyledEditableFieldItem;
