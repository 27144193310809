import { provide, lazyInject } from '../../../../shared/utils/IoC';
import { FieldsStore } from '../../../modules/fields/stores/fields.store';
import { UiStore } from '../../../stores/ui.store';
import { PopupPages } from '../../../constants/popup.pages';
import { CultureFillEnum } from '../../../constants/culture.fill.enum';
import { FieldsController } from '../../../modules/fields/controllers/fields.controller';
import { IndicesStore } from '../../../stores/indices.store';
import { IndicesController } from '../../Timeline/controllers/indices.controller';

@provide.singleton()
export class PopupSliderController {
  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(UiStore)
  uiStore: UiStore;

  @lazyInject(FieldsController)
  protected fieldsController: FieldsController;

  @lazyInject(IndicesController)
  protected indicesController: IndicesController;

  blurField = () => {
    if (this.uiStore.popupPageState === PopupPages.Seasons) {
      return;
    }

    this.indicesController.refreshPrevIndicesState();
    this.fieldsController.selectFieldId('');

    if (this.fieldsStore.showCulturesFillPanel === CultureFillEnum.Fill) {
      this.fieldsController.displayCultureZonesPolygons();
    }

    this.uiStore.setPageState(PopupPages.None, false, true);
  };
}
