import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TableBuilderController } from '../../../../../../../../shared/features/TableBuilder/mobx/controllers';
import { ITask } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { TasksListConfigsService as ConfigsService } from '../../services';
import { TasksService } from '../../../../../../../../shared/mobx/services/as-fields';
import { TypeApiRequest } from '../../../../../../../../shared/utils/axios2';
import { SeasonsStore } from '../../../../../../../stores/seasons.store';
import { ITasksFilters } from '../../../../TasksFilters/models';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';
import { TableFiltersBuilderController } from '../../../../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { TableBuilderStore } from '../../../../../../../../shared/features/TableBuilder/mobx/stores';

@provide.transient()
class TasksListController {
  @lazyInject(OrganizationsStore)
  protected organizationsStore: OrganizationsStore;

  @lazyInject(TableBuilderStore)
  protected tableBuilderStore: TableBuilderStore<ITask, 'tasks'>;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(TasksService)
  protected tasksService: TasksService;

  @lazyInject(ConfigsService)
  protected configsService: ConfigsService;

  @lazyInject(TableFiltersBuilderController)
  protected tableFiltersBuilderController: TableFiltersBuilderController<ITasksFilters>;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController<ITask, 'tasks'>;

  initiateTable = (): void => {
    const config = this.configsService.createConfig();

    this.tableBuilderController.initiateTable(config);
    this.tableBuilderController.addPaginationScrollEvent('tasks', () =>
      this.fetchTaskList(this.tableFiltersBuilderController.getAppliedFilters('tasks'), true)
    );
  };

  /**
   * Метод получения списка задач согласно фильтрам.
   * @param payload - выбранные фильтры.
   * @param isSavePagination - флаг, который говорит о том, сохраняем мы пагинацию или нет.
   * Когда активируется запрос после обновленных фильтров, то обнуляем пагинацию.
   */
  fetchTaskList = async (payload: ITasksFilters, isSavePagination?: boolean): Promise<void> => {
    if (!this.seasonsStore.selectedSeason) {
      return;
    }

    if (!isSavePagination) {
      this.tableBuilderController.addCurrentPage('tasks', 0);
      this.tableBuilderController.addTotalPages('tasks', 0);
    }

    const request: TypeApiRequest<'getTaskList'> = {
      ...payload,
      cultureId: payload?.cultureId?.map?.(id => (id === 'noCulture' ? null : id)),
      assigneeId: payload?.assigneeId?.map?.(id => (id === 'noAssignee' ? null : id)),
      seasonYear: Number(this.seasonsStore.selectedSeason),
      organizationId:
        this.organizationsStore.selectedOrganizationId !== 'my'
          ? this.organizationsStore.selectedOrganizationId
          : undefined,
    };

    const currentPage = this.tableBuilderStore.getCurrentPage('tasks');

    await this.tasksService.getTaskListByFilters(request, {
      query: {
        page: isSavePagination ? currentPage : 0,
        size: 20,
      },
      actions: {
        showLoader: () => {
          this.tableBuilderController.showLoader('tasks');
        },
        hideLoader: () => {
          this.tableBuilderController.hideLoader('tasks');
          this.tableBuilderController.hideDefaultPlug('tasks');
        },
        success: response => {
          this.tableBuilderController.addTotalPages('tasks', response.totalPages);

          this.tableBuilderController.addElementList('tasks', response.content, 'id', {
            isClearPreviousList: !isSavePagination,
          });
        },
      },
    });
  };
}

export default TasksListController;
