import styled from 'styled-components';

import { FontSizes } from '../../../../../constans/sizes';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  //& > :not(:last-child) {
  //  margin-right: 8px;
  //}
  //
  //& > div {
  //  margin-bottom: 10px;
  //}
`;

const Title = styled.p`
  margin: 0;
  padding: 0;

  ${FontSizes.medium};
`;

const StyledDropdownMultiselect = {
  Wrapper,
  Title,
};

export default StyledDropdownMultiselect;
