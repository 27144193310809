import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { TablePlugBuilder } from '../../../../../../../../shared/features/UI/plugs/TablePlugBuilder';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { TableFiltersBuilderController } from '../../../../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { useDataTestId } from '../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './TasksListNoDataPlug.styles';

const TasksListNoDataPlug: FC = () => {
  const tableFiltersBuilderController = useStore(TableFiltersBuilderController);

  const handleButtonClick = useCallback(() => {
    tableFiltersBuilderController.removeAllValues('tasks');
  }, []);

  const getDataTestId = useDataTestId('tasks-table');

  const noDataPlugDataTestId = getDataTestId('no-data-plug')['data-test-id'];

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'search' },
        content: {
          title: 'Задачи по заданным параметрам не найдены',
          description: 'Измените настройки фильтров',
        },
        footer: {
          renderButton: () => (
            <Styled.Button
              color={'primary'}
              onClick={handleButtonClick}
              alignment={'center'}
              dataTestId={getDataTestId('clear-filters-plug-button')['data-test-id']}
            >
              Очистить фильтр
            </Styled.Button>
          ),
        },
      }}
      dataTestId={noDataPlugDataTestId}
    />
  );
};

TasksListNoDataPlug.displayName = 'TasksListNoDataPlug';

export default observer(TasksListNoDataPlug);
