import styled from 'styled-components';

const TasksDrawer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 16px 24px 24px;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;

  background-color: #ffffff;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const FixedTitle = styled.div`
  display: block;
  position: sticky;
`;

const FixedFooter = styled.div`
  display: block;
  position: sticky;
`;

const StyledTasksDrawer = { TasksDrawer, FixedTitle, FixedFooter };

export default StyledTasksDrawer;
