const TOKEN_KEY = 'importedFieldsToken';

interface ITokenCollectionOfImportedFields {
  [orgId: string]: {
    [season: string]: string;
  };
}

const getTokenCollection = (): ITokenCollectionOfImportedFields | null => {
  const savedTokenCollection: ITokenCollectionOfImportedFields = JSON.parse(
    sessionStorage.getItem(TOKEN_KEY)
  );

  return savedTokenCollection;
};

const getToken = (orgId: string, season: string): string | null => {
  const savedTokenCollection = getTokenCollection();

  return savedTokenCollection?.[orgId]?.[season];
};

const setToken = (orgId: string, season: string, token: string): void => {
  if (!orgId || !season) {
    return;
  }

  const savedTokenCollection = getTokenCollection() || {};
  const savedToken = savedTokenCollection?.[orgId]?.[season];

  if (savedToken) {
    const isExactlyTisToken = savedToken === token;

    if (isExactlyTisToken) {
      return;
    }
  }

  const tokenCollection: ITokenCollectionOfImportedFields = { ...savedTokenCollection };
  tokenCollection[orgId] = {
    ...(tokenCollection?.[orgId] || {}),
    [season]: token,
  };
  const transformedTokenCollection = JSON.stringify(tokenCollection);

  sessionStorage.setItem(TOKEN_KEY, transformedTokenCollection);
};

const deleteToken = (): void => {
  sessionStorage.removeItem(TOKEN_KEY);
};

const importedFieldsSessionStorageHelpers = {
  getToken,
  setToken,
  deleteToken,
};

export type { ITokenCollectionOfImportedFields };

export default importedFieldsSessionStorageHelpers;
