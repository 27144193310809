import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { OperationCulture } from '../../../../../../api/models/operations/operation.culture';
import { ConfigurableSlider } from '../ConfigurableSlider';
import { CultureItem } from '../CultureItem';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationsStore } from '../../stores/operations.store';
import { SeasonCultureStore } from '../../stores/season.culture.store';
import { TasksStore } from '../../stores/tasks.store';
import { TasksController } from '../../controllers/tasks.controller';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { FieldsStore } from '../../../fields/stores/fields.store';

import NoSeasonsSvg from './assets/noSeasonsSvg.svg';
import {
  NoSeasonsPlaceHolder,
  NoSeasonsPlaceHolderTextBlock,
  NoSeasonsText1,
  NoSeasonsText2,
} from './style';

type CultureListType = {
  cultures: OperationCulture[];
  fieldsWithoutCulturesCount: number;
};

export const CultureList: FC<CultureListType> = observer(
  ({ cultures, fieldsWithoutCulturesCount }) => {
    const organizationsStore = useStore(OrganizationsStore);
    const seasonCultureStore = useStore(SeasonCultureStore);
    const seasonsStore = useStore(SeasonsStore);
    const store = useStore(OperationsStore);
    const tasksStore = useStore(TasksStore);
    const tasksController = useStore(TasksController);
    const fieldsStore = useStore(FieldsStore);

    const createFormattedFieldName = (
      fieldCountNumber: number,
      fieldName = ['поле', 'поля', 'полей']
    ): string => {
      const fieldCountNumberFirst = fieldCountNumber % 100;
      const fieldCountNumberSecond = fieldCountNumber % 10;
      if (fieldCountNumberFirst > 10 && fieldCountNumberFirst < 20) {
        return fieldName[2];
      }
      if (fieldCountNumberSecond > 1 && fieldCountNumberSecond < 5) {
        return fieldName[1];
      }
      if (fieldCountNumberSecond === 1) {
        return fieldName[0];
      }
      return fieldName[2];
    };

    console.log('seasonCultureStore.selectedCultureId', seasonCultureStore.selectedCultureId);

    const handleSelectCulture = async cultureId => {
      store.selectOperationId('');
      store.selectOperationFieldId('');
      seasonCultureStore.setSelectedCultureId(cultureId);
      store.OperationsList = [];
      store.OperationsType = [];
      tasksController.clear();
      // Не выяснена причина использования. При переходе на новую ручку убрал.
      // await store.getOperationsType(cultureId);
      store.resetPageNumberSettings();
      await store.getOperations();
      tasksController.resetPageSettings();
      await tasksController.fetchTasks({
        organizationId: organizationsStore.selectedOrganizationId,
        seassonCultureId: cultureId,
        operationId: store.selectedOperationId,
        fieldId: store.selectedOperationFieldId,
        noCulture: cultureId === 'emptyCulture' ? true : undefined,
      });
    };

    return seasonsStore.selectedSeason && fieldsStore.fields.length ? (
      <ConfigurableSlider dataTestId="operation-cultures">
        {fieldsWithoutCulturesCount > 0 && (
          <CultureItem
            id={'emptyCulture'}
            onClick={handleSelectCulture.bind(this, 'emptyCulture')}
            name={'Культура не указана'}
            fieldCount={`${fieldsWithoutCulturesCount} ${createFormattedFieldName(
              fieldsWithoutCulturesCount
            )}`}
            img={''}
            selected={seasonCultureStore.selectedCultureId === 'emptyCulture'}
            key={'emptyCulture'}
            data-test-id={'operation-emptyculture-item'}
          />
        )}
        {cultures.map(item => {
          return (
            <CultureItem
              onClick={handleSelectCulture.bind(this, item.culture.id)}
              name={item.culture.name}
              fieldCount={`${item.fieldCount} ${createFormattedFieldName(item.fieldCount)}`}
              totalArea={`${item.totalArea.toFixed(1)} га`}
              img={item.culture.attrs?.smallAssistancePic}
              selected={item.culture.id === seasonCultureStore.selectedCultureId}
              key={item.culture.id + item.variety}
              id={item.culture.id}
            />
          );
        })}
      </ConfigurableSlider>
    ) : store.isLoading ? null : (
      <NoSeasonsPlaceHolder data-test-id={'no-seasons-or-fields-placeholder'}>
        <img src={NoSeasonsSvg} />
        <NoSeasonsPlaceHolderTextBlock>
          <NoSeasonsText1>Здесь будут отображаться все культуры выбранного сезона</NoSeasonsText1>
          <NoSeasonsText2>Вы сможете создать перечень операций для каждой культуры</NoSeasonsText2>
        </NoSeasonsPlaceHolderTextBlock>
      </NoSeasonsPlaceHolder>
    );
  }
);
