import { FC } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import MobilePlaceholderSVG from '../static/mobilePlaceholder.svg';

import { useStore } from './shared/utils/IoC';
import { UiStore } from './dashboard/stores/ui.store';
import { Colors } from './shared/constans/colors';

export const MobilePlaceHolder: FC = observer(() => {
  const ui = useStore(UiStore);
  const showMobilePlaceHolder = ui.pageWidth <= 428 || ui.pageHeight <= 428;

  if (!showMobilePlaceHolder) return null;

  return (
    <MainBlock id={'main-block-mobile'}>
      <MobilePlaceholderIcon src={MobilePlaceholderSVG} />
      <Header1>
        В настоящий момент сервис доступен для работы только на десктопных и планшeтных устройствах
      </Header1>
      <Header2>По любым интересующим вопросам Вы можете написать нам на электронную почту</Header2>
      <MailToLink href="mailto: info@digitalagro.ru">info@digitalagro.ru</MailToLink>
      <Description>
        Мобильное приложение для iOS и Android находятся в стадии разработки и скоро будут доступны
        для скачивания
      </Description>
    </MainBlock>
  );
});

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 20px;
  text-align: center;
  position: fixed;
  z-index: 2000;
  background: ${Colors.white};
  top: 0;
  left: 0;
  right: 0;
  @media (min-width: 375px) {
    padding: 0 32px;
  }
  z-index: 10000;
`;

const MobilePlaceholderIcon = styled.img`
  min-width: 100px;
`;

const Header1 = styled.h1`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
`;

const Header2 = styled.h2`
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 0;
`;
const MailToLink = styled.a`
  width: 100%;
  height: 56px;
  border-radius: 20px;
  background-color: #3fb65f;
  color: #fff;
  font-size: 14px;
  line-height: 56px;
  font-weight: 600;
  margin-top: 0;
`;

const Description = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  color: #9aa0ad;
  margin-top: 0;
`;
