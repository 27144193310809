import moment, { Moment } from 'moment';
import { isBoolean } from 'lodash';

import { Axios, TypeApiRequest } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../stores/seasons.store';
import {
  Task,
  TasksStore,
  TaskStatuses,
  TaskPrioretees,
  FullScreenMode,
} from '../stores/tasks.store';
import { ProfileStore } from '../../profile/stores/ProfileStore';
import { CheckAccessStore } from '../../../../authorization/stores/checkAccess.store';
import { AccessRule } from '../../../../../api/endpoints/user/checkAccessByCodePrefix';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { SELECT_ALL_OPTION } from '../../../../shared/components/inputs/Dropdown/constants';

import { PreparedTask } from './tasks.listing.controller';

const taskListPageSize = 20;

export interface TaskFormData {
  a: string;
}

@provide.singleton()
export class TasksController {
  @lazyInject(TasksStore)
  protected tasksStore: TasksStore;

  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(OrganizationsStore)
  protected organizationsStore: OrganizationsStore;

  @lazyInject(CheckAccessStore)
  protected checkAccessStore: CheckAccessStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  // @lazyInject(OperationsController)
  // protected operationsController: OperationsController;

  @lazyInject(Axios)
  protected axios: Axios;

  clear() {
    this.tasksStore.selectedTaskId = '';
    this.tasksStore.tasksMap.clear();
    this.tasksStore.pageNumber = 0;
    this.tasksStore.totalPages = 0;
    this.tasksStore.tasksLoaded = false;
    this.tasksStore.tasksLoadedByFilter = false;
  }

  isTaskAvailableForEdit = (task: Partial<Task>) => {
    const result =
      this.organizationsStore.selectedOrganizationId === 'my' ||
      this.checkAccessStore.accessRules.find(
        (accessRule: AccessRule) => accessRule.code === 'scouting.manageTasks'
      ) ||
      (this.checkAccessStore.accessRules.find(
        (accessRule: AccessRule) => accessRule.code === 'scouting.workWithContractors'
      ) &&
        (this.profileStore.user?.id === task.createdBy.fullName ||
          this.profileStore.user?.id === (task.assigneeId || task.assignee?.id)));
    return result;
  };

  async getTaskById(id: string) {
    // console.log('***********getTaskById');
    const response = await this.axios.api.getTask({ id });
    // console.log('*********getTaskById.response!!!!!');

    // console.log(response);
    this.tasksStore.selectedTask = {
      ...(response as any),
      planStartDate: moment((response as any).planStartDate),
      planEndDate: moment((response as any).planEndDate),
      executedDate: moment((response as any).executedDate),
      canceledDate: moment((response as any).canceledDate),
    };
  }

  resetPageSettings = () => {
    this.tasksStore.pageNumber = 0;
    this.tasksStore.totalPages = 0;
  };

  async fetchTasks(
    {
      organizationId,
      seassonCultureId,
      operationId,
      fieldId,
      noCulture,
      assigneeId,
      operationTypeId,
      planDateFrom,
      planDateTo,
      statusIn,
      priorityIn,
    }: {
      seassonCultureId: string;
      organizationId?: string;
      operationId?: string;
      fieldId?: string;
      noCulture?: boolean;
      assigneeId?: string;
      operationTypeId?: string;
      planDateFrom?: Moment;
      planDateTo?: Moment;
      statusIn?: TaskStatuses[];
      priorityIn?: Array<TaskPrioretees>;
    },
    concatList?: boolean
  ) {
    try {
      this.tasksStore.loading = true;

      const requestObj: TypeApiRequest<'getTasks'> = {
        organizationId: organizationId === 'my' ? undefined : organizationId,
        cultureId: seassonCultureId
          ? seassonCultureId !== 'emptyCulture'
            ? seassonCultureId
            : undefined
          : undefined,
        operationId: operationId ? operationId : undefined,
        operationTypeId,
        planDateFrom: planDateFrom ? planDateFrom.format('YYYY-MM-DD') : undefined,
        planDateTo: planDateTo ? planDateTo.format('YYYY-MM-DD') : undefined,
        // @ts-ignore
        statusIn: statusIn ? statusIn.join() : undefined,
        fieldId: fieldId ? fieldId : undefined,
        seasonYear: this.seasonsStore.selectedSeason ? this.seasonsStore.selectedSeason : undefined,
        noCulture,
        // @ts-ignore
        assigneeId,
        page: this.tasksStore.pageNumber ? this.tasksStore.pageNumber : 0,
        size: taskListPageSize,
        // @ts-ignore
        priorityIn: priorityIn ? priorityIn.join() : undefined,
      };

      const response = await this.axios.api.getTasks(requestObj);

      if (!concatList) {
        this.tasksStore.tasksMap.clear();
      }

      this.tasksStore.totalPages = response.totalPages;
      this.tasksStore.pageNumber = concatList ? this.tasksStore.pageNumber + 1 : 1;

      response.content.forEach(task => {
        this.tasksStore.tasksMap.set(task.id, {
          ...task,
        });
      });
      this.tasksStore.loading = false;
      this.tasksStore.tasksLoaded = true;
    } catch (error) {
      this.tasksStore.loading = false;
    }
  }

  async fetchTasksByFilters(
    {
      organizationId,
      cultureId,
      operationTypeId,
      fieldId,
      assigneeId,
      planDateFrom,
      planDateTo,
      status,
      expired,
    }: Partial<TypeApiRequest<'getTaskList'>>,
    concatList?: boolean,
    currentPage?: number
  ) {
    try {
      this.tasksStore.loading = true;

      const formattedCultureIdList = cultureId
        ? cultureId.map(id => {
            if (id === 'noCulture') {
              return null;
            }

            return id;
          })
        : undefined;

      const formattedAssigneeIdList = assigneeId
        ? assigneeId.map(id => {
            if (id === 'noAssignee') {
              return null;
            }

            return id;
          })
        : undefined;

      const requestObj: TypeApiRequest<'getTaskList'> = {
        organizationId: organizationId === 'my' ? undefined : organizationId,
        cultureId: formattedCultureIdList,
        operationTypeId: operationTypeId ? operationTypeId : undefined,
        planDateFrom: planDateFrom ? planDateFrom : undefined,
        planDateTo: planDateTo ? planDateTo : undefined,
        status: status ? status : undefined,
        fieldId: fieldId ? fieldId : undefined,
        seasonYear: this.seasonsStore.selectedSeason
          ? Number(this.seasonsStore.selectedSeason)
          : undefined,
        assigneeId: formattedAssigneeIdList,
        expired: isBoolean(expired) ? expired : undefined,
      };

      const response = await this.axios.api.getTaskList(requestObj, {
        query: {
          sort: 'bindingDate,desc',
          page: this.tasksStore.pageNumber ? this.tasksStore.pageNumber : 0,
          size: taskListPageSize,
        },
      });

      if (!concatList) {
        this.tasksStore.tasksMap.clear();
      }

      this.tasksStore.totalPages = response.totalPages;
      this.tasksStore.pageNumber = concatList
        ? this.tasksStore.pageNumber + 1
        : currentPage || currentPage === 0
        ? currentPage
        : 1;

      response.content.forEach(task => {
        this.tasksStore.tasksMap.set(task.id, {
          ...task,
        } as any);
      });

      this.tasksStore.loading = false;
      this.tasksStore.tasksLoaded = true;
    } catch (error) {
      this.tasksStore.loading = false;
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  selectTask(taskId: string) {
    this.tasksStore.selectedTaskId = taskId;
  }

  fetchStatusesToChange(taskId: string): Array<TaskStatuses> {
    return [];
  }

  async setStatus(taskId: string, status: TaskStatuses, cancellationReason?: string) {
    try {
      await this.axios.api.setStatus({ taskId, status, cancellationReason });
      const task = this.tasksStore.tasksMap.get(taskId);

      /**
       * Данный костыль создан с той целью, чтобы, после рефакторинга, где используются
       * новые методы, не крашились задачи.
       */
      if (!task) {
        return;
      }

      const newMap = new Map();

      this.tasksStore.tasksMap = this.tasksStore.tasksMap.set(taskId, {
        ...task,
        status,
        cancellationReason,
      });

      [...this.tasksStore.tasksMap].forEach(([key, value]) => {
        newMap.set(key, value);
      });

      this.tasksStore.tasksMap = newMap;
    } finally {
      console.log('status had been updated');
    }
  }

  /* async createTask(data: Task) {
        console.log('createTask');
        await this.axios.api.createTask(data);
    }*/

  async updateTask(data: Partial<Task>) {
    console.log('updateTask');
    console.log(data);

    await this.axios.api.updateTaskTheOldRequest({
      ...data,
      planStartDate:
        data.planStartDate instanceof moment
          ? (data.planStartDate as Moment).format('YYYY-MM-DD')
          : data.planStartDate,
      planEndDate:
        data.planEndDate instanceof moment
          ? (data.planEndDate as Moment).format('YYYY-MM-DD')
          : data.planEndDate,
    });
  }

  showFullScreenCreationPage = (): void => {
    this.tasksStore.setFullScreenMode(FullScreenMode.TaskFullScreen);
  };

  hideFullScreenCreationPage = (): void => {
    this.tasksStore.setFullScreenMode(null);
  };

  showTaskEditForm = (): void => {
    this.tasksStore.setIsTaskEditForm(true);
  };

  hideTaskEditForm = (): void => {
    this.tasksStore.setIsTaskEditForm(false);
  };

  enableViewMode = (): void => {
    this.tasksStore.setIsViewMode(true);
  };

  disableViewMode = (): void => {
    this.tasksStore.setIsViewMode(false);
  };

  setTemporaryTask = (task: Partial<Task> | PreparedTask): void => {
    this.tasksStore.setTemporaryTask(task);
  };

  clearTemporaryTask = (): void => {
    this.tasksStore.clearTemporaryTask();
  };
}
