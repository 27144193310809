import { FC } from 'react';
import { observer } from 'mobx-react';

import { ReactComponent as PlusIcon } from '../../../../../../../../shared/static/plus.svg';
import { ReactComponent as MinusIcon } from '../../../../../../../../shared/static/minus.svg';
import { useStore } from '../../../../../../../utils/IoC';
import { MapController } from '../../../../../mobx/controllers';

import Styled from './Zoom.styles';

const Zoom: FC = () => {
  const controls = useStore(MapController);

  return (
    <Styled.Wrapper>
      <Styled.Button onClick={controls.zoomIn}>
        <PlusIcon />
      </Styled.Button>
      <Styled.Button onClick={controls.zoomOut}>
        <MinusIcon />
      </Styled.Button>
    </Styled.Wrapper>
  );
};

export default observer(Zoom);
