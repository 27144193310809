import { FC } from 'react';
import { observer } from 'mobx-react';

import AverageAttributeHelpers from '../../helpers/AverageAttribute.helpers';
import {
  TChecklistsDoubleAttrToDraw as TDoubleAttrToDraw,
  TChecklistsIntegerAttrToDraw as TIntegerAttrToDraw,
} from '../../../../../models';
import {
  EChecklistAttributeType as EAttrType,
  IExtendedAverageValue,
} from '../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { useStore } from '../../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../../mobx/controllers';

import Styled from './AverageAttributeCulledItemsBody.styles';
import { AverageAttributeCulledItem } from './components';

interface IProps {
  attrToDraw: TIntegerAttrToDraw | TDoubleAttrToDraw;
  culledValueList: IExtendedAverageValue[];
  isBlocked?: boolean;
}

const { deleteCulledValue } = AverageAttributeHelpers;

const AverageAttributeCulledItemsBody: FC<IProps> = ({
  attrToDraw,
  isBlocked,
  culledValueList,
}) => {
  const checklistsController = useStore(ChecklistsController);

  const handleDeleteItem = (item: IExtendedAverageValue) => {
    if (attrToDraw.initialModel.attribute.type === EAttrType.Int) {
      const { value, averageData } = deleteCulledValue<EAttrType.Int>(
        attrToDraw as TIntegerAttrToDraw,
        item
      );

      checklistsController.changeAttrValue(EAttrType.Int, attrToDraw.groupId, value);
      checklistsController.changeAttrOptions<EAttrType.Int>(attrToDraw.groupId, attrToDraw.id, {
        averageData,
      });
    } else {
      const { value, averageData } = deleteCulledValue<EAttrType.Double>(
        attrToDraw as TDoubleAttrToDraw,
        item
      );

      checklistsController.changeAttrValue(EAttrType.Double, attrToDraw.groupId, value);
      checklistsController.changeAttrOptions<EAttrType.Double>(attrToDraw.groupId, attrToDraw.id, {
        averageData,
      });
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.TitleWrapper>
        <Styled.Title>Выбракованные значения:</Styled.Title>
      </Styled.TitleWrapper>

      <Styled.ItemsWrapper>
        {culledValueList.map(value => (
          <AverageAttributeCulledItem
            key={value.id}
            item={value}
            onDeleteItem={handleDeleteItem}
            isBlocked={isBlocked}
          />
        ))}
      </Styled.ItemsWrapper>
    </Styled.Wrapper>
  );
};

AverageAttributeCulledItemsBody.displayName = 'AverageAttributeCulledItemsBody';

export default observer(AverageAttributeCulledItemsBody);
