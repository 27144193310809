import { makeAutoObservable } from 'mobx';

import { provide } from '../../shared/utils/IoC';

export interface ILeaveUnitDialogParams {
  isShow: boolean;
  actionHandler: any;
}

@provide.singleton()
export class LeaveUnitModalStore {
  textLabel = '';

  actionHandler: () => void = null;

  isShow = false;

  constructor() {
    makeAutoObservable(this);
  }

  setDialogSettings(params: ILeaveUnitDialogParams) {
    for (const key in params) {
      this[key] = params[key];
    }
  }

  setIsShow = (value: boolean): void => {
    this.isShow = value;
  };
}
