import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
`;

export const RightColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SideBarWrapper = styled.div`
  display: flex;
`;

export const MapWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const TooltipWrapper = styled.div`
  position: absolute;
`;

export const Btn = styled.button`
  width: 200px;
  height: 80px;
  background-color: #3a63f4;
  color: white;
  font-size: 26px;
  position: absolute;
  top: 0;
  //left: 100px;
  z-index: 1001;
  cursor: pointer;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-height: 0;
`;

export const MapsControlsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
`;
