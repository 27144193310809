import styled, { css } from 'styled-components';

import { EChecklistAttributeWidth } from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

const Wrapper = styled.div<{ $width: EChecklistAttributeWidth }>`
  ${({ $width }) => {
    switch ($width) {
      case EChecklistAttributeWidth.Half:
        return css`
          flex: 1 1 40%;
          max-width: 426px;
        `;

      case EChecklistAttributeWidth.Full:
        return css`
          flex: 1 1 70%;
        `;

      default:
    }
  }};
`;

const StyledChecklistsAttr = {
  Wrapper,
};

export default StyledChecklistsAttr;
