import _, { isUndefined } from 'lodash';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import {
  EChecklistAttributeType as EAttrType,
  IChecklistAverageValue,
  IGetChecklistAttributeUserDictionary,
  IPutChecklistAttributeValue as IAttrValueToSave,
} from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { ChecklistsStore } from '../../stores';
import {
  TChecklistsBooleanAttrToDraw as TBooleanAttrToDraw,
  TChecklistsDoubleAttrToDraw as TDoubleAttrToDraw,
  TChecklistsIntegerAttrToDraw as TIntegerAttrToDraw,
  TChecklistsStringAttrToDraw as TStringAttrToDraw,
  TChecklistsDictionaryAttrToDraw as TDictionaryAttrToDraw,
  TChecklistsEnumAttrToDraw as TEnumAttrToDraw,
  TChecklistsUserDictionaryAttrToDraw as TUserDictionaryAttrToDraw,
  TChecklistsFileAttrToDraw as TFileAttrToDraw,
  TChecklistsChecklistAttrToDraw as TChecklistAttrToDraw,
  TChecklistsLongStingAttrToDraw as TLongStingAttrToDraw,
  TChecklistsDateAttrToDraw as TDateAttrToDraw,
  IChecklistsAttrToDraw as IAttrToDraw,
} from '../../../models';
import { IPutNestedChecklistInstance as INestedInstanceToSave } from '../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { createChecklistsAttributeId as createAttrId } from '../../../helpers';
import AverageAttributeHelpers from '../../../containers/attributes/ChecklistsAverageAttribute/helpers/AverageAttribute.helpers';

const { isAverageChecklistAttr } = AverageAttributeHelpers;

@provide.transient()
class ChecklistsValidationsService {
  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  validateValues = (attrToDrawList: IAttrToDraw[]): IAttrValueToSave[] => {
    const validatedValueList = attrToDrawList.reduce<IAttrValueToSave[]>(
      (valueList, attrToDraw) => {
        switch (attrToDraw.initialModel.attribute.type) {
          case EAttrType.Boolean: {
            const validatedValue = this.validateBooleanValue(attrToDraw);
            const hasValue = !this.checkIfAttrHasEmptyValue(EAttrType.Boolean, validatedValue);
            if (hasValue) valueList.push(validatedValue);
            break;
          }

          case EAttrType.Int: {
            const validatedValue = this.validateIntegerValue(attrToDraw);
            const hasValue = !this.checkIfAttrHasEmptyValue(EAttrType.Int, validatedValue);
            if (hasValue) valueList.push(validatedValue);
            break;
          }

          case EAttrType.Double: {
            const validatedValue = this.validateDoubleValue(attrToDraw);
            const hasValue = !this.checkIfAttrHasEmptyValue(EAttrType.Double, validatedValue);
            if (hasValue) valueList.push(validatedValue);
            break;
          }

          case EAttrType.String: {
            const validatedValue = this.validateStringValue(attrToDraw);
            const hasValue = !this.checkIfAttrHasEmptyValue(EAttrType.String, validatedValue);
            if (hasValue) valueList.push(validatedValue);
            break;
          }

          case EAttrType.DictionaryLink: {
            const validatedValue = this.validateDictionaryValue(attrToDraw);
            const hasValue = !this.checkIfAttrHasEmptyValue(
              EAttrType.DictionaryLink,
              validatedValue
            );
            if (hasValue) valueList.push(validatedValue);
            break;
          }

          case EAttrType.Enum: {
            const validatedValue = this.validateEnumValue(attrToDraw);
            const hasValue = !this.checkIfAttrHasEmptyValue(EAttrType.Enum, validatedValue);
            if (hasValue) valueList.push(validatedValue);
            break;
          }

          case EAttrType.UserDictionaryLink: {
            const validatedValue = this.validateUserDictionaryValue(attrToDraw);
            const hasValue = !this.checkIfAttrHasEmptyValue(
              EAttrType.UserDictionaryLink,
              validatedValue
            );
            if (hasValue) valueList.push(validatedValue);
            break;
          }

          case EAttrType.FileLink: {
            const validatedValue = this.validateFileValue(attrToDraw);
            const hasValue = !this.checkIfAttrHasEmptyValue(EAttrType.FileLink, validatedValue);
            if (hasValue) valueList.push(validatedValue);
            break;
          }

          case EAttrType.ChecklistInstanceLink: {
            const validatedValue = this.validateChecklistValue(attrToDraw);
            const hasValue = !this.checkIfAttrHasEmptyValue(
              EAttrType.ChecklistInstanceLink,
              validatedValue
            );
            if (hasValue) valueList.push(validatedValue);
            break;
          }

          case EAttrType.LongString: {
            const validatedValue = this.validateLongStringValue(attrToDraw);
            const hasValue = !this.checkIfAttrHasEmptyValue(EAttrType.LongString, validatedValue);
            if (hasValue) valueList.push(validatedValue);
            break;
          }

          case EAttrType.Date: {
            const validatedValue = this.validateDateValue(attrToDraw);
            const hasValue = !this.checkIfAttrHasEmptyValue(EAttrType.Date, validatedValue);
            if (hasValue) valueList.push(validatedValue);
            break;
          }

          default:
        }

        return valueList;
      },
      []
    );

    return validatedValueList;
  };

  protected validateBooleanValue = ({ value }: TBooleanAttrToDraw): TBooleanAttrToDraw['value'] => {
    return value;
  };

  protected validateIntegerValue = ({
    id,
    groupId,
    initialModel,
    value,
    validationScheme,
  }: TIntegerAttrToDraw): TIntegerAttrToDraw['value'] => {
    const formattedValue = this.formatNumberValue(value.integerValue);
    const isAverageAttr = isAverageChecklistAttr(initialModel.attribute);

    const formattedAttrId = createAttrId(groupId, id);
    const hasEmptyValue = isAverageAttr
      ? !value.integerValues?.length
      : value.integerValue === undefined || value.integerValue === '';

    if (initialModel.isRequired && hasEmptyValue) {
      this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.Int>(groupId, id, {
        isShowError: true,
      });

      if (!this.checklistsStore.attrIdWithError) {
        this.checklistsStore.setAttrIdWithError(formattedAttrId);
        this.checklistsStore.setIsAttrIdWithErrorTargeted(true);
      }
    }

    if (validationScheme.isShowError) {
      this.checklistsStore.setAttrIdWithError(formattedAttrId);
      this.checklistsStore.setIsAttrIdWithErrorTargeted(true);
    }

    if (isAverageAttr) {
      const formattedValueList =
        value.integerValues?.reduce?.<IChecklistAverageValue[]>((acc, integerValue) => {
          const formattedAvgValue = this.formatNumberValue(integerValue.value);

          if (!_.isUndefined(formattedAvgValue)) {
            acc.push({
              ...integerValue,
              value: formattedAvgValue,
            });
          }

          return acc;
        }, []) ?? [];

      return {
        checkListAttributeId: id,
        integerValues: formattedValueList,
      };
    }

    if (!_.isUndefined(formattedValue)) {
      return {
        checkListAttributeId: id,
        integerValue: formattedValue,
      };
    }
  };

  protected validateDoubleValue = ({
    id,
    groupId,
    initialModel,
    value,
    validationScheme,
  }: TDoubleAttrToDraw): TDoubleAttrToDraw['value'] => {
    const formattedValue = this.formatNumberValue(value.doubleValue);
    const isAverageAttr = isAverageChecklistAttr(initialModel.attribute);

    const formattedAttrId = createAttrId(groupId, id);
    const hasEmptyValue = isAverageAttr
      ? !value.doubleValues?.length
      : value.doubleValue === undefined || value.doubleValue === '';

    if (initialModel.isRequired && hasEmptyValue) {
      this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.Double>(groupId, id, {
        isShowError: true,
      });

      if (!this.checklistsStore.attrIdWithError) {
        this.checklistsStore.setAttrIdWithError(formattedAttrId);
        this.checklistsStore.setIsAttrIdWithErrorTargeted(true);
      }
    }

    if (validationScheme.isShowError) {
      this.checklistsStore.setAttrIdWithError(formattedAttrId);
      this.checklistsStore.setIsAttrIdWithErrorTargeted(true);
    }

    if (isAverageAttr) {
      const formattedValueList =
        value.doubleValues?.reduce?.<IChecklistAverageValue[]>((acc, doubleValue) => {
          const formattedAvgValue = this.formatNumberValue(doubleValue.value);

          if (!_.isUndefined(formattedAvgValue)) {
            acc.push({
              ...doubleValue,
              value: formattedAvgValue,
            });
          }

          return acc;
        }, []) ?? [];

      return {
        checkListAttributeId: id,
        doubleValues: formattedValueList,
      };
    }

    if (!_.isUndefined(formattedValue)) {
      return {
        checkListAttributeId: id,
        doubleValue: formattedValue,
      };
    }
  };

  protected validateStringValue = ({
    id,
    groupId,
    initialModel,
    value,
  }: TStringAttrToDraw): TStringAttrToDraw['value'] => {
    const formattedAttrId = createAttrId(groupId, id);
    const hasEmptyValue = value.stringValue === undefined || value.stringValue === '';

    if (initialModel.isRequired && hasEmptyValue) {
      this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.String>(groupId, id, {
        isShowError: true,
      });

      if (!this.checklistsStore.attrIdWithError) {
        this.checklistsStore.setAttrIdWithError(formattedAttrId);
        this.checklistsStore.setIsAttrIdWithErrorTargeted(true);
      }
    }

    return value;
  };

  protected validateDictionaryValue = ({
    id,
    groupId,
    initialModel,
    value,
  }: TDictionaryAttrToDraw): TDictionaryAttrToDraw['value'] => {
    const formattedAttrId = createAttrId(groupId, id);
    const hasEmptyValue = !value.dictionaryValueList.length;

    if (initialModel.isRequired && hasEmptyValue) {
      this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.DictionaryLink>(groupId, id, {
        isShowError: true,
      });

      if (!this.checklistsStore.attrIdWithError) {
        this.checklistsStore.setAttrIdWithError(formattedAttrId);
        this.checklistsStore.setIsAttrIdWithErrorTargeted(true);
      }
    }

    return value;
  };

  protected validateEnumValue = ({
    id,
    groupId,
    initialModel,
    value,
  }: TEnumAttrToDraw): TEnumAttrToDraw['value'] => {
    const formattedAttrId = createAttrId(groupId, id);
    const hasEmptyValue = !value.enumValues.length;

    if (initialModel.isRequired && hasEmptyValue) {
      this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.Enum>(groupId, id, {
        isShowError: true,
      });

      if (!this.checklistsStore.attrIdWithError) {
        this.checklistsStore.setAttrIdWithError(formattedAttrId);
        this.checklistsStore.setIsAttrIdWithErrorTargeted(true);
      }
    }

    return value;
  };

  protected validateUserDictionaryValue = ({
    id,
    groupId,
    initialModel,
    value,
  }: TUserDictionaryAttrToDraw): TUserDictionaryAttrToDraw['value'] => {
    const formattedAttrId = createAttrId(groupId, id);
    const hasEmptyValue = !value.userDictionaryValues.length;

    if (initialModel.isRequired && hasEmptyValue) {
      this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.UserDictionaryLink>(
        groupId,
        id,
        {
          isShowError: true,
        }
      );

      if (!this.checklistsStore.attrIdWithError) {
        this.checklistsStore.setAttrIdWithError(formattedAttrId);
        this.checklistsStore.setIsAttrIdWithErrorTargeted(true);
      }
    }

    const formattedValueList = value.userDictionaryValues.reduce<
      IGetChecklistAttributeUserDictionary[]
    >((list, dictValue) => {
      const isANewValue = dictValue?.clientId;

      if (isANewValue) list.push({ id: null, value: dictValue.value });
      else list.push({ id: dictValue.id, value: null });

      return list;
    }, []);

    return { ...value, userDictionaryValues: formattedValueList };
  };

  protected validateFileValue = ({
    id,
    groupId,
    initialModel,
    value,
  }: TFileAttrToDraw): TFileAttrToDraw['value'] => {
    const formattedAttrId = createAttrId(groupId, id);
    const hasEmptyValue = !value.fileValue.length;

    if (initialModel.isRequired && hasEmptyValue) {
      this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.FileLink>(groupId, id, {
        isShowError: true,
      });

      if (!this.checklistsStore.attrIdWithError) {
        this.checklistsStore.setAttrIdWithError(formattedAttrId);
        this.checklistsStore.setIsAttrIdWithErrorTargeted(true);
      }
    }

    return value;
  };

  protected validateChecklistValue = ({
    value,
  }: TChecklistAttrToDraw): TChecklistAttrToDraw['value'] => {
    const instanceList = this.checklistsStore.getNestedInstanceToDrawListByAttrId(
      value.checkListAttributeId
    );

    const formattedInstanceList = instanceList.reduce<INestedInstanceToSave[]>((acc, instance) => {
      const attrToDrawList = this.checklistsStore.getAttrToDrawListByGroupId(instance.id);
      const visibleAttrToDrawList = attrToDrawList.filter(attrToDraw => attrToDraw.isVisible);

      const validatedValueList = this.validateValues(visibleAttrToDrawList);

      if (validatedValueList.length) {
        acc.push({
          checkListId: this.checklistsStore.selectedChecklist.id,
          values: this.validateValues(visibleAttrToDrawList),
        });
      }

      return acc;
    }, []);

    return {
      ...value,
      checkListInstanceValue: formattedInstanceList,
    };
  };

  protected validateLongStringValue = ({
    id,
    groupId,
    initialModel,
    value,
  }: TLongStingAttrToDraw): TLongStingAttrToDraw['value'] => {
    const formattedAttrId = createAttrId(groupId, id);
    const hasEmptyValue = value.longStringValue === undefined || value.longStringValue === '';

    if (initialModel.isRequired && hasEmptyValue) {
      this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.LongString>(groupId, id, {
        isShowError: true,
      });

      if (!this.checklistsStore.attrIdWithError) {
        this.checklistsStore.setAttrIdWithError(formattedAttrId);
        this.checklistsStore.setIsAttrIdWithErrorTargeted(true);
      }
    }

    return value;
  };

  protected validateDateValue = ({
    id,
    groupId,
    initialModel,
    value,
  }: TDateAttrToDraw): TDateAttrToDraw['value'] => {
    const formattedAttrId = createAttrId(groupId, id);
    const hasEmptyValue = value.dateValue === undefined || value.dateValue === '';

    if (initialModel.isRequired && hasEmptyValue) {
      this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.Date>(groupId, id, {
        isShowError: true,
      });

      if (!this.checklistsStore.attrIdWithError) {
        this.checklistsStore.setAttrIdWithError(formattedAttrId);
        this.checklistsStore.setIsAttrIdWithErrorTargeted(true);
      }
    }

    return value;
  };

  protected formatNumberValue = (value: string | number): number | string => {
    if (value === undefined || value === '') return null;

    const formattedValue = Number(value);

    if ((formattedValue || formattedValue === 0) && !isNaN(formattedValue)) {
      return Number(value);
    }
  };

  protected checkIfAttrHasEmptyValue = <T extends EAttrType>(
    type: T,
    value: IAttrToDraw<T>['value']
  ): boolean => {
    if (!value) return true;

    switch (type) {
      case EAttrType.Boolean:
        return false;

      case EAttrType.Int:
        return this.checkIfIntValueIsEmpty(value);

      case EAttrType.Double:
        return this.checkIfDoubleValueIsEmpty(value);

      case EAttrType.String: {
        const { stringValue } = value as IAttrToDraw<EAttrType.String>['value'];
        return !stringValue;
      }

      case EAttrType.DictionaryLink: {
        const { dictionaryValueList } = value as IAttrToDraw<EAttrType.DictionaryLink>['value'];
        return !dictionaryValueList.length;
      }

      case EAttrType.Enum: {
        const { enumValues } = value as IAttrToDraw<EAttrType.Enum>['value'];
        return !enumValues.length;
      }

      case EAttrType.UserDictionaryLink: {
        const {
          userDictionaryValues,
        } = value as IAttrToDraw<EAttrType.UserDictionaryLink>['value'];
        return !userDictionaryValues.length;
      }

      case EAttrType.FileLink: {
        const { fileValue } = value as IAttrToDraw<EAttrType.FileLink>['value'];
        return !fileValue.length;
      }

      case EAttrType.ChecklistInstanceLink: {
        const {
          checkListInstanceValue,
        } = value as IAttrToDraw<EAttrType.ChecklistInstanceLink>['value'];
        return !checkListInstanceValue.length;
      }

      case EAttrType.LongString: {
        const { longStringValue } = value as IAttrToDraw<EAttrType.LongString>['value'];
        return !longStringValue;
      }

      case EAttrType.Date: {
        const { dateValue } = value as IAttrToDraw<EAttrType.Date>['value'];
        return !dateValue;
      }

      default:
        return false;
    }
  };

  checkIfIntValueIsEmpty = (value: TIntegerAttrToDraw['value']): boolean => {
    if (!isUndefined(value?.integerValues)) {
      return !value.integerValues.length;
    }

    return (
      value.integerValue === '' || value.integerValue === undefined || value.integerValue === null
    );
  };

  checkIfDoubleValueIsEmpty = (value: TDoubleAttrToDraw['value']): boolean => {
    if (!isUndefined(value?.doubleValues)) {
      return !value.doubleValues.length;
    }

    return (
      value.doubleValue === '' || value.doubleValue === undefined || value.doubleValue === null
    );
  };
}

export default ChecklistsValidationsService;
