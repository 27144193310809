import { useEffect } from 'react';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { useStore } from '../../utils/IoC';
import { ReferencesService } from '../../mobx/services/as-fields';
import { EContextualParentPath } from '../../constants/contextualPath';

const usePageContextualHelp = (parentPath: EContextualParentPath) => {
  const referencesService = useStore(ReferencesService);
  const helpActions = useContextualHelpActions();

  useEffect(() => {
    helpActions.initiateContextualHelpList(parentPath, async () => {
      const response = await referencesService.getReferenceList({
        active: true,
        size: 2000,
        parentPath,
      });

      if (response.content) return response.content;
      else return [];
    });
  }, []);
};

export default usePageContextualHelp;
