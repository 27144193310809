import { TypeApiRoute } from '../../models/type.api.request';
import { CultureList } from '../../models/operations/operation.culture.list.model';

type TGetSeasonCulture = {
  year: number;
  organizationId?: string;
};

type TypeResponse = CultureList;

export const getSeasonCulture: TypeApiRoute & {
  request: TGetSeasonCulture;
  response: TypeResponse;
} = {
  url: req => `/api/as-fields/seasons/${req.year}/cultureList`,
  method: 'GET',
  headers: {},
  request: {} as TGetSeasonCulture,
  response: {} as TypeResponse,
};

export type { TGetSeasonCulture };
