import styled from 'styled-components';

import deleteActionSvg from './assets/images/delete-action-40x40.svg';
import addActionSvg from './assets/images/add-action-40x40.svg';

const DeleteAction = styled.div`
  width: 40px;
  height: 40px;

  cursor: pointer;

  background: url(${deleteActionSvg});
`;

const AddAction = styled(DeleteAction)`
  background: url(${addActionSvg});
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  align-items: center;

  & > :not(:last-child) {
    margin-right: 12px;
  }
`;

const StyledAverageAttributeItemsBodyRow = {
  AddAction,
  DeleteAction,
  Wrapper,
};

export default StyledAverageAttributeItemsBodyRow;
