import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import {
  ITableBuilderCellConfig,
  ITableBuilderColumnConfig,
  ITableBuilderConfig,
} from '../../../../../../../../shared/features/TableBuilder/models/configs';
import { ITask } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { createZoneName, toDouble } from '../../../../../mobx/stores/TaskStore/Task.store';
import {
  TasksListOperationCell as OperationCell,
  TasksListDaysLeftCell as DaysLeft,
  TasksListStatusCell as StatusCell,
} from '../../../components/cells';
import { TaskViewStore } from '../../../../../../operations/stores/task.view.store';
import { ProfileStore } from '../../../../../../profile/stores/ProfileStore';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';

type TBuilderConfig = ITableBuilderConfig<ITask, 'tasks'>;
type TColumnConfig = ITableBuilderColumnConfig<ITask>;
type TCellConfig = ITableBuilderCellConfig<ITask>;

@provide.transient()
class TasksListConfigsService {
  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(OrganizationsStore)
  protected organizationStore: OrganizationsStore;

  @lazyInject(TaskViewStore)
  protected taskViewStore: TaskViewStore;

  createConfig = (): TBuilderConfig => {
    const columnList = this.createColumnList();
    const cellList = this.createCellList();

    return {
      id: 'tasks',
      columnConfigList: columnList,
      cellConfigList: cellList,
    };
  };

  createColumnList = (): TColumnConfig[] => {
    const operation = this.createOperationColumn();
    const field = this.createFieldColumn();
    const culture = this.createCultureColumn();
    const assignee = this.createAssigneeColumn();
    const daysLeft = this.createDaysLeftColumn();
    const status = this.createStatusColumn();

    return [operation, field, culture, assignee, daysLeft, status];
  };

  createOperationColumn = (): TColumnConfig => {
    return {
      id: 'operationInfo',
      name: 'Операция',
      width: {
        default: '1fr',
      },
    };
  };

  createFieldColumn = (): TColumnConfig => {
    return {
      id: 'field',
      name: 'Поле',
      width: {
        default: '1fr',
      },
    };
  };

  createCultureColumn = (): TColumnConfig => {
    return {
      id: 'culture',
      name: 'Культура',
      width: {
        default: '1fr',
      },
    };
  };

  createAssigneeColumn = (): TColumnConfig => {
    return {
      id: 'assignee',
      name: 'Исполнитель',
      width: {
        default: '1fr',
      },
    };
  };

  createDaysLeftColumn = (): TColumnConfig => {
    return {
      id: 'daysLeft',
      name: 'Срок',
      width: {
        default: '1fr',
        s1920: '200px',
        s1366: '120px',
        s1024: '120px',
      },
    };
  };

  createStatusColumn = (): TColumnConfig => {
    return {
      id: 'status',
      name: 'Статус',
      width: {
        default: '95px',
      },
    };
  };

  createCellList = (): TCellConfig[] => {
    const operation = this.createOperationCell();
    const field = this.createFieldCell();
    const culture = this.createCultureCell();
    const assignee = this.createAssigneeCell();
    const daysLeft = this.createDaysLeftCell();
    const status = this.createStatusCell();

    return [operation, field, culture, assignee, daysLeft, status];
  };

  createOperationCell = (): TCellConfig => {
    return {
      id: 'operationInfo',
      customRenderConfig: {
        renderCell: row => <OperationCell row={row} />,
      },
    };
  };

  createFieldCell = (): TCellConfig => {
    return {
      id: 'field',
      autoRenderConfig: {
        renderValue: row => {
          const selectedField = row.field;
          const selectedZone = row?.cultureZone;

          const fieldName = createZoneName(selectedField?.name, selectedField.area);

          if (selectedZone) {
            const zoneName = createZoneName(selectedZone?.name, selectedZone.area);

            return selectedZone?.name
              ? `${fieldName}. ${zoneName}. ${toDouble(selectedZone.area)} га`
              : `${fieldName}. ${toDouble(selectedZone.area)} га`;
          }

          return fieldName;
        },
      },
    };
  };

  createCultureCell = (): TCellConfig => {
    return {
      id: 'culture',
      autoRenderConfig: {
        renderValue: row => row?.culture?.name ?? 'Культура не указана',
      },
    };
  };

  createAssigneeCell = (): TCellConfig => {
    return {
      id: 'assignee',
      autoRenderConfig: {
        renderValue: row => {
          const isMyOrganization = this.organizationStore.selectedOrganizationId === 'my';

          if (isMyOrganization) {
            return this.profileStore.fullName;
          } else {
            return row?.assignee?.fullName || 'Не назначено';
          }
        },
      },
    };
  };

  createDaysLeftCell = (): TCellConfig => {
    return {
      id: 'daysLeft',
      customRenderConfig: {
        renderCell: row => <DaysLeft row={row} />,
      },
    };
  };

  createStatusCell = (): TCellConfig => {
    return {
      id: 'status',
      customRenderConfig: {
        renderCell: row => <StatusCell row={row} />,
      },
    };
  };
}

export default TasksListConfigsService;
