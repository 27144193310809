import { IGetIntensity } from '../../models/intensity/intensity.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  size?: number;
  page?: number;
  context?: string;
};

type TypeResponse = {
  content: IGetIntensity[];
};

export const getIntensityList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => '/api/as-fields/intensity/list',
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
