import { useCallback } from 'react';

import { SELECT_ALL_OPTION } from './../../constants';
import { TDropdownOnOptionClick, TUseDropdownBodyHandlers } from './useDropdownBodyHandlers.types';

const useDropdownBodyHandlers: TUseDropdownBodyHandlers = ({ state, setState, props }) => {
  const handleOptionClick = useCallback<TDropdownOnOptionClick>(
    (option, isNewData) => {
      const isShowWarning = props.config?.validation?.modal?.isShowWarning;

      const isMultiselect = props.config.field?.type?.multiselect;

      const isTitleReplacement = props.config.field?.onChangeTitleReplaceHandler;

      const isPreventBodyClose = props.config.visual?.isPreventCloseAfterSelect;

      const isSelectAllOptionSelected = option.value === SELECT_ALL_OPTION.value;

      if (!isTitleReplacement) {
        props.config.field.onChange(option?.value || '', {
          selectedValueList: isMultiselect
            ? [...state.values.selectValueList, option]
            : state.values.selectValueList,
          isNewData: isNewData || option?.label === option?.value,
          searchValue: state.values.searchValue,
          option,
          optionList: props.config.body.optionList,
        });
      }

      if (isShowWarning) {
        setState({
          temporaryData: {
            onChangeData: {
              selectedValueList: isMultiselect
                ? [...state.values.selectValueList, option]
                : state.values.selectValueList,
              isNewData: isNewData || option?.label === option?.value,
              searchValue: state.values.searchValue,
              option,
              optionList: props.config.body.optionList,
            },
          },
        });

        return;
      }

      if (props.config.field?.type?.multiselect) {
        if (props.config.field?.onChangeTitleReplaceHandler) {
          const titleHandler = props.config.field?.onChangeTitleReplaceHandler(option);

          if (typeof titleHandler === 'object') {
            titleHandler.then(value => {
              setState({
                values: {
                  ...state.values,
                  searchValue: null,
                  selectValueList: [...state.values.selectValueList, { ...option, label: value }],
                },
                settings: {
                  ...state.settings,
                  isDropped: isPreventBodyClose,
                  isSearchFieldFocused: false,
                  isFirstOpen: false,
                },
                temporaryData: {
                  ...state.temporaryData,
                  preventSortingValues: [
                    ...state.values.selectValueList,
                    { ...option, label: value },
                  ],
                },
              });

              props.config.field.onChange(option?.value || '', {
                selectedValueList: isMultiselect
                  ? [...state.values.selectValueList, option]
                  : state.values.selectValueList,
                isNewData: isNewData || option?.label === option?.value,
                searchValue: state.values.searchValue,
                option,
                optionList: props.config.body.optionList,
                valuesWithCrumbs: [
                  ...state.values.selectValueList.flatMap(valueItem => valueItem.label),
                  value,
                ],
              });
            });
          }

          return;
        }

        setState({
          values: {
            ...state.values,
            searchValue: null,
            selectValueList: isSelectAllOptionSelected
              ? [...props.config.body.optionList, option]
              : [...state.values.selectValueList, option],
          },
          settings: {
            ...state.settings,
            isDropped: isPreventBodyClose,
            isSearchFieldFocused: false,
            isFirstOpen: false,
          },
          temporaryData: {
            ...state.temporaryData,
            preventSortingValues: [...(state.temporaryData?.preventSortingValues ?? []), option],
          },
        });

        return;
      }

      if (props.config.field?.onChangeTitleReplaceHandler) {
        const titleHandler = props.config.field?.onChangeTitleReplaceHandler(option);

        if (typeof titleHandler === 'object') {
          titleHandler.then(value => {
            setState({
              values: {
                ...state.values,
                searchValue: null,
                selectValue: { ...option, label: value },
              },
              settings: {
                ...state.settings,
                isDropped: isPreventBodyClose,
                isSearchFieldFocused: false,
                isFirstOpen: false,
              },
            });

            props.config.field.onChange(option?.value || '', {
              selectedValueList: isMultiselect
                ? [...state.values.selectValueList, option]
                : state.values.selectValueList,
              isNewData: isNewData || option?.label === option?.value,
              searchValue: state.values.searchValue,
              option,
              optionList: props.config.body.optionList,
              valuesWithCrumbs: [value],
            });
          });
        }

        return;
      }

      setState({
        values: { ...state.values, searchValue: null, selectValue: option },
        settings: {
          ...state.settings,
          isDropped: isPreventBodyClose,
          isSearchFieldFocused: false,
          isFirstOpen: false,
        },
      });
    },
    [state, setState, props]
  );

  const handlers: ReturnType<TUseDropdownBodyHandlers> = {
    onOptionClick: handleOptionClick,
  };

  return handlers;
};

export default useDropdownBodyHandlers;
