import useTasksParams from '../useTasksParams/useTasksParams';
import { usePermissionsBuilderPermissions } from '../../../../../shared/features/PermissionsBuilder/hooks';
import { ETaskAction } from '../../../../../../api/models/as-fields/task/task.model';

const useTaskActionControl = () => {
  const params = useTasksParams();
  const permissions = usePermissionsBuilderPermissions<ETaskAction>(params?.taskId);

  return {
    hasPermission: permissions.getPermission,
    getPermissionList: permissions.getPermissionList,
    clearPermissionsStore: permissions.clearStore,
  };
};

export default useTaskActionControl;
