import Skeleton from 'react-loading-skeleton';

import Styled from './SkeletonInstanceCard.styles';

const SkeletonInstanceCard = () => {
  return (
    <Styled.Wrapper>
      {/** @ts-ignore */}
      <Skeleton height="100%" />
    </Styled.Wrapper>
  );
};

export default SkeletonInstanceCard;
