import { FC, memo, useCallback, useMemo } from 'react';
import { DropdownButton, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useTaskActionControl, useTasksParams } from '../../hooks';
import { ETaskAction, ETaskStatus } from '../../../../../../api/models/as-fields/task/task.model';
import { getAvailableStatusChange } from '../InspectionPoints/utils/helpers';
import { TaskController } from '../../mobx/controllers';
import { useStore } from '../../../../../shared/utils/IoC';

import Styled from './TaskHeaderSideContent.styled';
import { CancelButton } from './components';

interface ITaskHeaderSideContentProps {
  status: ETaskStatus;
  availableStatusList: ETaskStatus[];
  mode?: 'view' | 'edit' | 'create';
}

const TaskHeaderSideContent: FC<ITaskHeaderSideContentProps> = ({
  status,
  mode,
  availableStatusList,
}) => {
  const { taskId } = useTasksParams();

  const { sendTaskStatus } = useStore(TaskController);

  const controls = useTaskActionControl();
  const isAllowedToChangeStatus = controls.hasPermission(ETaskAction.TaskSetStatus);
  const isAllowedToDeleteTask = controls.hasPermission(ETaskAction.TaskDelete);

  const { setNotification } = useNotificator();

  const isCreationMode = mode === 'create';
  const isEditMode = mode === 'edit';
  const isViewMode = mode === 'view';

  const isAllowToCancel = useMemo(
    () =>
      status === ETaskStatus.New ||
      status === ETaskStatus.InWork ||
      status === ETaskStatus.Impossible,
    [status]
  );

  const availableStatusChange = useMemo(() => {
    if (!isAllowedToChangeStatus) {
      return null;
    }

    const statusButtonConfig = getAvailableStatusChange(status);

    return availableStatusList?.includes(statusButtonConfig.status) ? statusButtonConfig : null;
  }, [status, availableStatusList, isAllowedToChangeStatus]);

  const handleChangeTaskStatus = useCallback(() => {
    if (availableStatusChange) {
      sendTaskStatus({
        status: availableStatusChange.status,
        taskId,
        cancellationReason: null,
      }).then(() => {
        if (availableStatusChange?.notification) {
          setNotification(availableStatusChange.notification);
        }

        availableStatusChange?.onChangeHandler?.(availableStatusChange.status);
      });
    }
  }, [taskId, availableStatusChange]);

  // Помимо основных условий скрываем кнопки с режиме редактирования
  const isDisplayDeleteButton = isViewMode && isAllowedToDeleteTask;
  const isDisplayCancelButton =
    (isViewMode && isAllowToCancel && isAllowedToChangeStatus) || isDisplayDeleteButton;

  const isDisplayStatusChangeButton =
    isViewMode && availableStatusChange && isAllowedToChangeStatus;

  return (
    <>
      {isDisplayCancelButton && <CancelButton status={status} taskId={taskId} mode={mode} />}
      {isDisplayStatusChangeButton && (
        <Styled.StatusButtonWrapper>
          <DropdownButton
            type="button"
            dataTestId="change-task-status-button"
            onClick={handleChangeTaskStatus}
            title={availableStatusChange?.buttonText}
            style={{ size: 'sm' }}
          />
        </Styled.StatusButtonWrapper>
      )}
    </>
  );
};

export default observer(TaskHeaderSideContent);
