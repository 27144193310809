import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import { BorderRadius, FontSizes } from '../../../../../shared/constans/sizes';

import defaultImg from './assets/default.svg';

export const CultureItemBody = styled.div<{ active: boolean }>`
  width: 176px;
  min-height: 58px;
  padding: 10px 12px;
  background: ${Colors.white};
  border: 1px solid ${({ active }) => (active ? `${Colors.green}` : `${Colors.grayBorder}`)};
  box-sizing: border-box;
  border-radius: ${BorderRadius.small};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &#emptyCulture {
    width: 197px;
    & > div {
      overflow: visible;
    }
  }
`;

export const CultureItemName = styled.div`
  ${FontSizes.mediumBold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
`;

export const CultureItemField = styled.div`
  color: #7e8b9d;
  ${FontSizes.default};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
  display: flex;
`;

export const CultureItemFieldCount = styled.p`
  margin: 0 4px 0 0;
  display: inline-block;
`;

export const CultureItemFieldArea = styled.p`
  display: inline-block;
  padding-left: 8px;
  margin: 0;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 6px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #7e8b9d;
    display: block;
  }
`;

export const CultureItemImg = styled.img.attrs(props => ({
  src: props.src || defaultImg,
  className: props.src ? '' : 'defaultImg',
}))`
  height: 100%;
  width: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: rotateY(180deg);
  &.defaultImg {
    height: 50%;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
