import { TypeApiRoute } from '../../../models/type.api.request';
import { ITask, TTaskUpdate } from '../../../models/as-fields/task/task.model';

type TUpdateTaskReq = {
  id: string;
} & Partial<TTaskUpdate>;

type TUpdateTaskRes = ITask;

const updateTask: TypeApiRoute & {
  request: TUpdateTaskReq;
  response: TUpdateTaskRes;
} = {
  url: ({ id }) => `/api/as-fields/task/${id}`,
  method: 'PUT',
  headers: {},
  request: {} as TUpdateTaskReq,
  response: {} as TUpdateTaskRes,
};

export type { TUpdateTaskReq };

export { updateTask };
