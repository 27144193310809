import { useCallback } from 'react';

import { useStore } from '../../../../utils/IoC';
import { TableBuilderStore as Store } from '../../mobx/stores';
import { TableBuilderController as Controller } from '../../mobx/controllers';
import { useDynamicPagination } from '../../../DynamicPagination/hooks';

const useTableBuilderInfiniteScroll = (builderId: string) => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const currentPage = store.getCurrentPage(builderId);
  const totalPages = store.getTotalPages(builderId);

  const handlePageChange = useCallback(() => controller.increaseCurrentPage(builderId), []);
  const handleScroll = store.getPaginationConfig(builderId)?.onScroll;

  const plugScrollEvent = useCallback(async () => {
    await null;
  }, []);

  const { scrollRef, isShowLoader } = useDynamicPagination<HTMLDivElement>({
    currentPage,
    totalPages,
    onScroll: handleScroll ?? plugScrollEvent,
    onPageChange: handlePageChange,
  });

  return { tableRef: scrollRef, isShowLoaderByScroll: isShowLoader };
};

export default useTableBuilderInfiniteScroll;
