import { TypeApplication2 } from '../../../modules/dashboard/modules/organization/types/applications';
import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/type.response.pageable';

type TypeRequest = {
  status?: 'SENT';
  nameOrInn: string;
};

type TypeResponse = {
  content: Array<TypeApplication2>;
} & TypeResponsePageable;

export const getOrganizationApplicationsMine: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/organizations/MyApplicationlist`,
  method: 'GET',
  headers: {
    'DA-HOST': 'da-profile',
  },
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
