import { lazyInject, provide } from '../../../../../../../../utils/IoC';
import IndicesService from '../services/Indices.serivices';
import { CultureFillEnum } from '../../../../../../../../../dashboard/constants/culture.fill.enum';
import { EIndexType } from '../../../../../../../../../../api/models/indices.model';
import IndicesStore from '../stores/Indices.stores';

@provide.transient()
class IndicesController {
  @lazyInject(IndicesService)
  indicesService: IndicesService;

  @lazyInject(IndicesStore)
  indicesStore: IndicesStore;

  fetchIndices = async (
    fieldId: string,
    year?: number,
    indexName?: CultureFillEnum,
    status?: EIndexType[],
    fromDate?: string,
    toDate?: string
  ) => {
    const indicesList = (
      await this.indicesService.getIndices({ fieldId, year, indexName, status, fromDate, toDate })
    ).content;

    this.indicesStore.setIndices(fieldId, indicesList);

    return indicesList;
  };

  fetchIndicesYearList = async (
    fieldId: string,
    indexName?: CultureFillEnum,
    seasonYear?: number,
    status?: EIndexType[]
  ) => {
    const indicesYearList = await this.indicesService.getIndicesYears({
      fieldId,
      indexName,
      seasonYear,
      status,
    });

    this.indicesStore.setYearIndices(fieldId, indicesYearList);

    return indicesYearList;
  };
}

export default IndicesController;
