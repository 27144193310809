import { FC, memo, PropsWithChildren } from 'react';

import Styled from './ChecklistsContent.styles';

interface IProps {
  isOpen?: boolean;
}

const ChecklistsContent: FC<PropsWithChildren<IProps>> = ({ children, isOpen = true }) => {
  return <Styled.Wrapper $isOpen={isOpen}>{children}</Styled.Wrapper>;
};

ChecklistsContent.displayName = 'ChecklistsContent';

export default memo(ChecklistsContent);
