import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../utils/axios2';

@provide.singleton()
class DictionaryService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  getDictionaryEntityList = async (
    payload: TypeApiRequest<'getDictionaryEntityList'>,
    query?: Record<string, string | number>
  ): Promise<TypeApiResponse<'getDictionaryEntityList'>> => {
    try {
      const response = await this.axiosService.api.getDictionaryEntityList(
        payload,
        query && { query }
      );

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getDictionaryEntity = async (
    payload: TypeApiRequest<'getDictionaryEntity'>
  ): Promise<TypeApiResponse<'getDictionaryEntity'>> => {
    try {
      const fetchedEntity = await this.axiosService.api.getDictionaryEntity(payload, {
        omit: ['remoteName', 'id'],
      });

      return fetchedEntity;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default DictionaryService;
