import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  max-width: 200px;
  height: 40px;
  background-color: ${Colors.generalWhite};
  border-radius: 12px;
  flex-shrink: 0;

  pointer-events: all;
`;

const Styled = { Wrapper };

export default Styled;
