import { TypeApiRoute } from '../../../models/type.api.request';
import { IDictionaryEntity } from '../../../models/da-dictionary/DictionaryEntity';

type TRequest = {
  idIn?: string[];
  parentId?: string;
  nameFilter?: string;
  codeFilter?: string;
  descriptionFilter?: string;
  pathEquals?: string;
  pathPrefix?: string;
  originalOnly?: boolean;
  latestVersion?: boolean;
  level?: number;
  attrs?: {
    [key: string]: any;
  };
  status?: string;
  fetchAttributes?: boolean;
  remoteName?: string;
  dependencyName?: string;
  dependencyRecordId?: string;
};

type TResponse = { content: IDictionaryEntity[] };

export const getDictionaryEntityList: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ remoteName }) => `/api/da-dictionary/dictionary/${remoteName}/findAll`,
  method: 'POST',
  request: {} as TRequest,
  response: {} as TResponse,
  headers: {},
};
