import { useCallback } from 'react';
import { generatePath, matchPath } from 'react-router-dom';

import { ETaskTabs } from '../../models';
import useTasksParams from '../useTasksParams/useTasksParams';

import { TasksRoute } from './../../tasks.route';

const useTaskTabs = () => {
  const { orgId, taskId, checklistInstanceId } = useTasksParams();

  const getTaskTabIdByPathname = useCallback(
    (pathname: string) => {
      switch (true) {
        case Boolean(matchPath(generatePath(TasksRoute.Task, { orgId, taskId }), pathname)):
        case Boolean(matchPath(generatePath(TasksRoute.TaskEdit, { orgId, taskId }), pathname)):
          return ETaskTabs.Summary;

        case Boolean(matchPath(generatePath(TasksRoute.TaskMap, { orgId, taskId }), pathname)):
        case Boolean(
          matchPath(generatePath(TasksRoute.TaskMapCreatePoint, { orgId, taskId }), pathname)
        ):
          return ETaskTabs.Map;

        case Boolean(
          checklistInstanceId &&
            matchPath(
              generatePath(TasksRoute.ChecklistEdit, { orgId, taskId, checklistInstanceId }),
              pathname
            )
        ):
          return ETaskTabs.Checklists;

        case Boolean(
          checklistInstanceId &&
            matchPath(generatePath(TasksRoute.ChecklistList, { orgId, taskId }), pathname)
        ):
        case Boolean(
          checklistInstanceId &&
            matchPath(
              generatePath(TasksRoute.Checklist, { orgId, taskId, checklistInstanceId }),
              pathname
            )
        ):
          return ETaskTabs.Checklists;

        case Boolean(matchPath(generatePath(TasksRoute.TaskReport, { orgId, taskId }), pathname)):
          return ETaskTabs.Report;

        case Boolean(matchPath(generatePath(TasksRoute.TaskHistory, { orgId, taskId }), pathname)):
          return ETaskTabs.History;

        default:
          return false;
      }
    },
    [orgId, taskId, checklistInstanceId]
  );

  return { getTaskTabIdByPathname };
};

export default useTaskTabs;
