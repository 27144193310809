import { FC } from 'react';

import * as Styled from './styles';

type IContainerProps = {
  title?: string;
} & Styled.IStyledContainerProps;

const Container: FC<IContainerProps> = ({ title, children, ...styled }) => (
  <Styled.Wrapper {...styled}>
    <Styled.HeaderWrapper>
      <Styled.Header>{title && <Styled.Title>{title}</Styled.Title>}</Styled.Header>
    </Styled.HeaderWrapper>

    <Styled.Body {...styled}>{children}</Styled.Body>
  </Styled.Wrapper>
);

export default Container;
