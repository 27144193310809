import styled from 'styled-components';

import { FontSizes } from '../../../../../shared/constans/sizes';
import { Colors } from '../../../../../shared/constans/colors';

export const OperationItemWrapper = styled.div<{ isActive?: boolean }>`
  position: relative;
  padding: 8px 16px;
  border-bottom: 1px solid ${Colors.grayBorder};
  /* margin-left: -16px;
  margin-right: -16px; */
  cursor: pointer;
  background: ${({ isActive }) => (isActive ? Colors.lightGray : 'transparent')};

  &:hover {
    background: rgba(0, 0, 0, 0.01);
  }

  &:before {
    content: '';
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    width: 4px;
    height: 100%;
    background: ${Colors.green};
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0px 5px 5px 0px;
  }
`;

export const OperationName = styled.div`
  ${FontSizes.medium};
  width: calc(100% - 28px);
  margin-bottom: 1px;
`;

export const OperationDate = styled.div`
  color: ${Colors.darkGray};
  ${FontSizes.default}
`;

export const OperationMenu = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;
