import styled from 'styled-components';

import { Colors } from '../../../../../../../constans/colors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MatchedValue = styled.span`
  color: ${Colors.generalBlack};
`;

const Option = styled.div`
  width: 100%;
  cursor: pointer;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.secondaryDarkGray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* background-color: red; */

  &:hover {
    color: ${Colors.green};

    & ${MatchedValue} {
      color: ${Colors.green};
    }
  }
`;

const StyledDropdownBodyOption = {
  MatchedValue,
  Option,
  Wrapper,
};

export default StyledDropdownBodyOption;
