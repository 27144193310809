import styled from 'styled-components';

const Label = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Wrapper = styled.div<{ $isActive: boolean; $hasError?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 8px 12px;
  border-radius: 12px;

  background-color: ${({ $hasError, $isActive }) => {
    if ($hasError) {
      return '#FEF5F5';
    } else if ($isActive) {
      return '#ECF8EF';
    } else {
      return '#FAF9FD';
    }
  }};
`;

const StyledChecklistsBooleanAttr = {
  Label,
  Container,
  Wrapper,
};

export default StyledChecklistsBooleanAttr;
