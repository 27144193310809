import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
`;

export const Blur = styled.div`
  width: 100%;
  height: 100%;
  filter: blur(4px);
  background: black;
  opacity: 0.5;
  transform: scale(1.01);
`;

export const SidebarWrapper = styled.div`
  position: absolute;
  z-index: 901;
  left: 0;
  width: 196px;
  top: 0;
  height: 100%;
`;
