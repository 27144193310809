import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { RenderPolygonOption } from '../../../../map/consts/enum.render.option';
import { MapMode } from '../../../../map/stores/map.store';
import { FieldsModalService } from '../services/fields.modals.service';

import { FieldsController } from './fields.controller';

@provide.singleton()
export class FieldsAddController extends FieldsController {
  @lazyInject(FieldsModalService)
  protected fieldsModalService: FieldsModalService;

  returnToFieldsListing = async () => {
    await this.fetchFieldsList(RenderPolygonOption.View, '', true);

    this.setMapModeValue(MapMode.Listing);
    this.mapStore.setViewMode();
  };

  startCreatingMode = async () => {
    this.mapStore.clearAll();
    this.setMapModeValue(MapMode.Creating);
    await this.fetchFieldsList(RenderPolygonOption.Creating, '', true);
    this.mapStore.changeMode();
  };

  openWarnBeforeLeavingThePageModal = (onSuccess?: () => void) => {
    this.fieldsModalService.warnBeforeLeavingThePage(onSuccess);
  };

  openIntersectionWarning = () => {
    this.fieldsModalService.intersectionWarning();
  };

  openOversizedAreaWarning = () => {
    this.fieldsModalService.oversizedAreaWarning();
  };

  openUnfinishedContourWarning = (onSuccess?: () => void) => {
    this.fieldsModalService.unfinishedContourWarning(onSuccess);
  };
}
