import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import {
  ChecklistsBooleanAttrsService,
  ChecklistsDateAttrsService,
  ChecklistsDictionaryAttrsService,
  ChecklistsDoubleAttrsService,
  ChecklistsEnumAttrsService,
  ChecklistsFileAttrsService as FileAttrsService,
  ChecklistsIntegerAttrsService,
  ChecklistsLongStringAttrsService,
  ChecklistsStringAttrsService,
  ChecklistsUserDictionaryAttrsService,
} from '../attributes';
import { IChecklistsAttrToDraw as IAttrToDraw } from '../../../models';

@provide.transient()
class ChecklistsAttrValuesService {
  @lazyInject(ChecklistsBooleanAttrsService)
  protected booleanAttrsService: ChecklistsBooleanAttrsService;

  @lazyInject(ChecklistsIntegerAttrsService)
  protected integerAttrsService: ChecklistsIntegerAttrsService;

  @lazyInject(ChecklistsDoubleAttrsService)
  protected doubleAttrsService: ChecklistsDoubleAttrsService;

  @lazyInject(ChecklistsStringAttrsService)
  protected stringAttrsService: ChecklistsStringAttrsService;

  @lazyInject(ChecklistsDictionaryAttrsService)
  protected dictionaryAttrsService: ChecklistsDictionaryAttrsService;

  @lazyInject(ChecklistsEnumAttrsService)
  protected enumAttrsService: ChecklistsEnumAttrsService;

  @lazyInject(ChecklistsUserDictionaryAttrsService)
  protected userDictionaryAttrsService: ChecklistsUserDictionaryAttrsService;

  @lazyInject(FileAttrsService)
  protected fileAttrsService: FileAttrsService;

  @lazyInject(ChecklistsLongStringAttrsService)
  protected longStringAttrsService: ChecklistsLongStringAttrsService;

  @lazyInject(ChecklistsDateAttrsService)
  protected dateAttrsService: ChecklistsDateAttrsService;

  changeAttrValue = <T extends EAttrType>(
    type: T,
    groupId: string,
    value: IAttrToDraw<T>['value']
  ): void => {
    switch (type) {
      case EAttrType.Int:
        this.integerAttrsService.changeValue(groupId, value);
        break;

      case EAttrType.Double:
        this.doubleAttrsService.changeValue(groupId, value);
        break;

      case EAttrType.String:
        this.stringAttrsService.changeValue(groupId, value);
        break;

      case EAttrType.Boolean:
        this.booleanAttrsService.changeValue(groupId, value);
        break;

      case EAttrType.DictionaryLink:
        this.dictionaryAttrsService.changeValue(groupId, value);
        break;

      case EAttrType.Enum:
        this.enumAttrsService.changeValue(groupId, value);
        break;

      case EAttrType.UserDictionaryLink:
        this.userDictionaryAttrsService.changeValue(groupId, value);
        break;

      case EAttrType.FileLink:
        this.fileAttrsService.changeValue(groupId, value);
        break;

      case EAttrType.LongString:
        this.longStringAttrsService.changeValue(groupId, value);
        break;

      case EAttrType.Date:
        this.dateAttrsService.changeValue(groupId, value);
        break;

      default:
    }
  };
}

export default ChecklistsAttrValuesService;
