import { ECheckListInstanceType } from '../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';

const getInstanceName = (type: ECheckListInstanceType, positionNumber: number) => {
  if (type === ECheckListInstanceType.PlannedPoint || type === ECheckListInstanceType.ActualPoint) {
    return `Точка ${positionNumber}`;
  }

  if (type === ECheckListInstanceType.Machinery) {
    return `Техника ${positionNumber}`;
  }

  if (type === ECheckListInstanceType.Field) {
    return `Поле ${positionNumber}`;
  }

  return `Точка ${positionNumber}`;
};

export default getInstanceName;
