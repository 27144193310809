import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../shared/utils/IoC';

@provide.singleton()
class InstanceListActualityService {
  private _instanceIdToDeleteHandler = new Map<string, () => Promise<boolean>>();

  constructor() {
    makeAutoObservable(this);
  }

  get readyToRemoveInstanceIdList() {
    return Array.from(this._instanceIdToDeleteHandler.keys());
  }

  addInstanceToRemoveList = (id: string, deleteHandler: () => Promise<boolean>) => {
    this._instanceIdToDeleteHandler.set(id, deleteHandler);
  };

  deleteInstanceFromRemoveList = (id: string) => {
    this._instanceIdToDeleteHandler.delete(id);
  };

  isInstanceReadyToRemove = (id: string) => {
    return this._instanceIdToDeleteHandler.has(id);
  };

  deleteReadyToRemoveItems = () => {
    const handlerList = Array.from(this._instanceIdToDeleteHandler.values());
    this.clearService();
    return handlerList.map(handler => handler?.());
  };

  clearService = () => {
    this._instanceIdToDeleteHandler = new Map();
  };
}

export default InstanceListActualityService;
