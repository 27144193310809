import { Accordeon, AutoTooltip, useSidebar } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import useOnclickOutside from 'react-cool-onclickoutside';

import { useStore } from '../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import OrgSvg from '../../../../../../static/organization-mock.svg';
import { SeasonsController } from '../../../../controllers/seasons/seasons.controller';
import { TasksController } from '../../../../modules/operations/controllers/tasks.controller';
import { ProfileController } from '../../../../modules/profile/controllers/profile.controller';
import { OrganizationsController } from '../../../../controllers/organizations/organizations.controller';
import { OperationsStore } from '../../../../modules/operations/stores/operations.store';
import { EAppSettingsType } from '../../../../../..';
import { SidebarController } from '../../sidebar.controller';
import { DashboardRoutes } from '../../../../dashboard.routes';
import { TableFiltersBuilderController } from '../../../../../shared/features/TableFiltersBuilder/mobx/controllers';

import Styled from './style';

interface IOrganizationsAccordeonProps {
  expanded?: boolean;
  slug?: string;
  disabled?: boolean;
  customDataTestId?: string;
}

export const OrganizationsAccordeon: FC<IOrganizationsAccordeonProps> = observer(
  ({ disabled: isDisabled, expanded, customDataTestId }) => {
    const organization = useStore(OrganizationsStore);
    const seasonsController = useStore(SeasonsController);
    const operationsStore = useStore(OperationsStore);
    const tasksController = useStore(TasksController);
    const profileController = useStore(ProfileController);
    const organizationsController = useStore(OrganizationsController);
    const { activeDropdown, setActiveDropdown } = useStore(SidebarController);

    const tableFiltersBuilderController = useStore(TableFiltersBuilderController);

    const { expandSidebar } = useSidebar();
    const navigate = useNavigate();

    const { season, fieldId } = useParams<{ season: string; fieldId: string }>();

    const accordeonWrapperRef = useCallback(
      useOnclickOutside(() => {
        if (activeDropdown === 'orgs') {
          setActiveDropdown(null);
        }
      }),
      [activeDropdown]
    );

    const replaceToURLWithOrganizationOnly = (organizationId: string) => {
      const previousOrgId = organization.currentOrganization?.organizationId || 'my';
      if (Boolean(season) || Boolean(fieldId)) {
        const newRoute = generatePath(DashboardRoutes.Fields, { orgId: organizationId });
        navigate(newRoute);
      } else {
        navigate(location.pathname.replace(previousOrgId, organizationId));
      }
    };

    const handleSelectOrganization = async (organizationId: string) => {
      await seasonsController.setSelectedSeason('');
      operationsStore.clearOperationsStore();
      tasksController.clear();
      replaceToURLWithOrganizationOnly(organizationId);

      profileController.setAppSettings(EAppSettingsType.Organization)(organizationId);
      organizationsController.selectOrganization(organizationId);
      setActiveDropdown(null);
      /**
       * При смене сезона очищаем сохраненные данные фильтров в разделе "задачи" [H15-3545].
       */
      tableFiltersBuilderController.clearAppliedValuesInSessionStorage('tasks');
    };

    const orgTooltip =
      organization.selectedOrganizationId === 'my'
        ? 'Мои поля'
        : `Организация ${organization?.currentOrganization?.name}`;

    return expanded ? (
      <Styled.OpacityWrapper $hidden={!expanded} $disabled={isDisabled} ref={accordeonWrapperRef}>
        <Accordeon
          onHover={() => 1}
          onChange={handleSelectOrganization}
          onMouseOut={() => 1}
          value={organization.selectedOrganizationId}
          options={[{ label: 'Мои поля', value: 'my' }, ...organization.organizationList]}
          placeholder="Мои поля"
          isOpen={!isDisabled && activeDropdown === 'orgs'}
          onOpen={() => setActiveDropdown('orgs')}
          dataTestId={customDataTestId}
          disabled={isDisabled}
        />
      </Styled.OpacityWrapper>
    ) : (
      <AutoTooltip content={orgTooltip} position="right">
        <Styled.OrganizationSmallBlock
          id="fieldsShortIcon"
          data-test-id={`${customDataTestId}-short-icon`}
          onClick={() => {
            expandSidebar();
            // Задержка для отрабатывания анимации
            setTimeout(() => setActiveDropdown('orgs'), 300);
          }}
          $isDisabled={isDisabled}
        >
          <img src={organization?.currentOrganization?.logo?.downloadUrl || OrgSvg} />
        </Styled.OrganizationSmallBlock>
      </AutoTooltip>
    );
  }
);
