import React from 'react';

import Img from './assets/noOperations.svg';
import { Wrapper, MainText, SecondaryText } from './style';

export const NoOperations = () => {
  return (
    <Wrapper data-test-id={'operation-nooperation'}>
      <img src={Img} alt="" data-test-id={'operation-nooperation-img'} />
      <MainText data-test-id={'operation-nooperation-maintext'}>Добавьте запланированные операции по культуре</MainText>
      <SecondaryText data-test-id={'operation-nooperation-secondarytext'}>Настройте тип и период проведения операций</SecondaryText>
    </Wrapper>
  );
};
