import { forwardRef } from 'react';
import { observer } from 'mobx-react';

import { useTableBuilderUIContext as useUIContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { useStore } from '../../../../../utils/IoC';
import { TableBuilderStore as Store } from '../../../mobx/stores';
import { TableBuilderRow as Row } from '../TableBuilderRow';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';

import Styled from './TableBuilderContent.styles';

const TableBuilderContent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    const store = useStore(Store);

    const UIContext = useUIContext();

    const rowList = store.getRowList(UIContext.builderId);

    const getDataTestId = useDataTestId();

    return (
      <Styled.Scrollbar ref={ref} {...props} {...getDataTestId('content')}>
        {rowList.map(row => (
          <Row key={row.id} row={row} />
        ))}
      </Styled.Scrollbar>
    );
  }
);

TableBuilderContent.displayName = 'TableBuilderContent';

export default observer(TableBuilderContent);
