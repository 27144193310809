import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../../../../shared/constans/colors';

const { generalBlack } = Colors;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  margin-bottom: 12px;

  color: ${generalBlack};
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: ${generalBlack};
`;

const ItemsBody = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 16px 12px;

  margin-top: 30px;

  max-height: 60vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: row;
`;

const FooterWrapper = styled.div<{ $isOverflow: boolean }>`
  width: 100%;
  padding-top: 30px;

  ${({ $isOverflow }) =>
    $isOverflow &&
    css`
      background: #ffffff;
      padding-top: 16px;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    `}
`;

const StyledChecklistsAverageAttrCullNumbersModal = {
  Title,
  Description,
  ItemsBody,
  Wrapper,
  FooterWrapper,
};

export default StyledChecklistsAverageAttrCullNumbersModal;
