import { ReactNode } from 'react';
import {
  polygon as turfPolygon,
  intersect,
  Polygon,
  Feature,
  explode,
  booleanPointInPolygon,
  area,
  difference,
} from '@turf/turf';
import { reaction, runInAction, toJS } from 'mobx';
import _ from 'lodash';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { FieldsStore, PrevValuesType } from '../stores/fields.store';
import MapStore, { AddPolygonOptionsType, MapMode } from '../../../../map/stores/map.store';
import { UiStore } from '../../../stores/ui.store';
import { SeasonsStore } from '../../../stores/seasons.store';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { PopupPages } from '../../../constants/popup.pages';
import { CultureFillEnum } from '../../../constants/culture.fill.enum';
import { RenderPolygonOption } from '../../../../map/consts/enum.render.option';
import { Field } from '../../../../../api/models/field.model';
import { Axios, TypeApiResponse } from '../../../../shared/utils/axios2';
import { toFixedWithCeilBackEnd } from '../../../../shared/utils/toFixedWithCeil';
import { ProfileStore } from '../../profile/stores/ProfileStore';
import { timerDelay } from '../../../../shared/utils/timerDelay';
import { FieldsErrors } from '../constants/fields.errors';
import { editPolygonEventName } from '../../../../map/events/edit.polygon.event';
import { createPolygonEventName } from '../../../../map/events/create.polygon.event';
import { makeAreaTooltip, makeTooltipVariants } from '../utils';
import { FieldSeasonsStore } from '../../../stores/field.seasons.store';
import { FieldsImportStore } from '../stores/import';
import { MapController } from '../../../../map/controllers/map.controller';
import { EImportedFieldErrorType } from '../../../../../api/models/fields/getImportedField.model';

@provide.singleton()
export class FieldsController {
  @lazyInject(FieldsStore, 'FieldsStore')
  protected fieldsStore: FieldsStore;

  @lazyInject(MapStore, 'MapStore')
  protected mapStore: MapStore;

  @lazyInject(UiStore, 'UiStore')
  uiStore: UiStore;

  @lazyInject(ProfileStore)
  profileStore: ProfileStore;

  @lazyInject(SeasonsStore, 'SeasonsStore')
  seasonsStore: SeasonsStore;

  @lazyInject(OrganizationsStore, 'OrganizationsStore')
  organizationStore: OrganizationsStore;

  @lazyInject(FieldSeasonsStore, 'FieldSeasonsStore')
  fieldSeasonsStore: FieldSeasonsStore;

  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(FieldsImportStore)
  protected fieldsImportStore: FieldsImportStore;

  @lazyInject(MapController)
  protected mapController: MapController;

  constructor() {
    this.uiStore.renderPolygonsCallback = this.renderAvailableFields;
    this.uiStore.fetchPolygonsCallback = this.fetchFieldsList;

    reaction(
      () => this.fieldsStore.mapMode,
      () => {
        console.log('this is !!!!', this.fieldsStore.mapMode);
        if (this.fieldsStore.mapMode === MapMode.Creating) {
          this.unbindEditEvents();
          window.addEventListener(editPolygonEventName, this.handleEditPolygon);
          window.addEventListener(createPolygonEventName, this.handlePolygonCreation);
        } else if (
          this.fieldsStore.mapMode === MapMode.Editing ||
          this.fieldsStore.mapMode === MapMode.CZEditing
        ) {
          window.addEventListener(editPolygonEventName, this.handleEditPolygonInEditMode);
        }
      }
    );
  }

  fetchFieldsList = async (
    renderOption: RenderPolygonOption = RenderPolygonOption.View,
    excludeFieldId?: string,
    ignorePreventing?: boolean,
    isClearBeforeSet?: boolean
  ) => {
    // let attempts = 0;
    // while (!this.seasonsStore.selectedSeason) {
    //   // eslint-disable-next-line no-await-in-loop
    //   await new Promise(resolve => {
    //     setTimeout(() => resolve(0), 1000);
    //   });
    //   attempts++;
    //   if (attempts > 5) {
    //     return;
    //   }
    // }

    // Preventing additional requests to server
    if (
      (!ignorePreventing &&
        this.fieldsStore.prevSeason === this.seasonsStore.selectedSeason &&
        this.fieldsStore.prevOrganization === this.organizationStore.selectedOrganizationId &&
        this.fieldsStore.prevMapMode === this.fieldsStore.mapMode &&
        this.fieldsStore.mapMode !== MapMode.Listing &&
        this.fieldsStore.prevPopupState === this.uiStore.popupPageState) ||
      (!ignorePreventing &&
        (this.fieldsStore.isLoading || Number(this.seasonsStore.selectedSeason) === 0))
    ) {
      this.fieldsStore.fieldsLoaded = true;
      return;
    }

    this.mapStore.clearPolygons();
    this.fieldsStore.clearIdToFields();
    this.fieldsStore.setLoading(true);

    const organizationId =
      this.organizationStore.selectedOrganizationId === 'my'
        ? ''
        : this.organizationStore.selectedOrganizationId;

    let response: TypeApiResponse<'getFields'> | null = null;
    try {
      response = await this.axios.api.getFields(
        {
          organizationId,
          withGeometry: true,
          withCultureZones: true,
          seasonYear: Number(this.seasonsStore.selectedSeason),
          sort: 'name',
        },
        {
          omit: ['organizationId'],
        }
      );
    } catch (e) {
      this.fieldsStore.setLoading(false);
      this.fieldsStore.fieldsLoaded = true;
      return;
    }
    this.fieldsStore.setLoading(false);

    this.setPreviousValues({
      prevPopupState: this.uiStore.popupPageState,
      prevMapMode: this.fieldsStore.prevMapMode,
      prevSeason: this.seasonsStore.selectedSeason,
      prevOrganization: this.organizationStore.selectedOrganizationId,
    });

    // Очистка стора используется для фикса двойных контуров при редактировании полей
    if (isClearBeforeSet) this.mapStore.clearAll();

    this.setFieldsToStoreAndMap(response.content, renderOption, excludeFieldId);
    this.fieldsStore.fieldsLoaded = true;
  };

  enableImportedFieldEditing = (id: string) => {
    const { editableField, getField, setField, setEditableField } = this.fieldsStore;

    const { setFocusTargetId, setIsFieldFocused } = this.fieldsImportStore;

    const {
      getEditableLayer,
      getLayer,
      getIncorrectLayerId,
      setPolygon,
      deletePolygon,
      setImportedFieldLayer,
      deleteImportedFieldLayer,
    } = this.mapStore;

    const {
      enableLayerEditing,
      disableLayerEditing,
      checkForIntersectionsEditableLayer,
    } = this.mapController;

    setFocusTargetId(id);
    setIsFieldFocused(true);

    const isExactlyThisField: boolean = editableField?.id === id;

    if (isExactlyThisField) {
      return;
    }

    const field: Field = getField(id);

    if (editableField) {
      disableLayerEditing(editableField.polyId);
      getLayer(editableField.polyId)?.on('click', this.enableEditingByClickOnImportedField);
    }

    const previousSelectedEditableLayer = getEditableLayer(field?.polyId);

    if (previousSelectedEditableLayer) {
      setEditableField(field);
      enableLayerEditing(field.polyId);

      /**
       * Пока не уверен, насколько необходима тут проверка на пересечения.
       * Нужно испробовать методом тыка.
       */
      checkForIntersectionsEditableLayer(null, previousSelectedEditableLayer);

      return;
    }

    const hasIncorrectPolygons: boolean = getIncorrectLayerId(field.polyId);

    const layerId = setPolygon(field.geometry.coordinates, {
      name: field.name,
      renderOption: hasIncorrectPolygons ? RenderPolygonOption.Error : RenderPolygonOption.View,
      editable: true,
    });

    deletePolygon(field.polyId);
    deleteImportedFieldLayer(field.polyId);

    const editableLayer = getEditableLayer(layerId);
    editableLayer.pm.enable({ allowSelfIntersection: false, preventMarkerRemoval: true });
    setImportedFieldLayer(layerId, editableLayer);

    const points = field.geometry.coordinates;
    const polygon = turfPolygon(points);
    const areaInHectare = area(polygon) / 10000;

    setEditableField({ ...field, polyId: layerId, areaInHectare });
    setField({ ...field, polyId: layerId });

    enableLayerEditing(layerId);
    checkForIntersectionsEditableLayer(null, editableLayer);
  };

  enableEditingByClickOnImportedField = event => {
    const { fields } = this.fieldsStore;

    const field = fields.find(storedField => storedField?.polyId === event.target._leaflet_id);

    if (field) {
      this.enableImportedFieldEditing(field.id);
    }
  };

  displayImportedFields = () => {
    const { listOfImportedField } = this.fieldsImportStore;
    const {
      getLayer,
      setMode,
      centerMapToPoint,
      checkCrossingOfFields,
      setIdToUnchangedLayer,
      setIdToImportedFieldLayer,
      clearPolygons,
    } = this.mapStore;
    const { fields, setFieldsMapMode, setIdToField } = this.fieldsStore;
    const { setPopupPageState } = this.uiStore;

    clearPolygons();

    setFieldsMapMode(MapMode.Import);
    setMode(MapMode.Import);
    setPopupPageState(PopupPages.None);

    const idToField: Map<string, Field> = new Map<string, Field>();
    const idToLayerOfPreviouslyAddedField: Map<number, any> = new Map<number, any>();
    const idToLayerOfImportedField: Map<number, any> = new Map<number, any>();
    const listOfImportedFieldToScroll: Field[] = [];

    const transformedListOfImportedField = listOfImportedField?.map<any>(field => ({
      ...field,
      isImported: true,
    }));

    const fieldList = [...fields, ...transformedListOfImportedField];

    fieldList.forEach(field => {
      const isImported: boolean = field?.isImported;
      const hasInvalidGeometry = field?.errorType === EImportedFieldErrorType.InvalidGeometry;

      if (hasInvalidGeometry) {
        const invalidField = { ...field };
        invalidField.name = `Поле ${makeAreaTooltip(invalidField?.area ?? 0)}`;

        idToField.set(invalidField.id, invalidField);

        return;
      }

      const polyId = this.createFieldPolygon(field);

      const fieldWithPolyId: Field = { ...field, polyId };

      if (!field?.name) {
        fieldWithPolyId.name = `Поле ${makeAreaTooltip(fieldWithPolyId?.area ?? 0)}`;
      }

      idToField.set(fieldWithPolyId.id, fieldWithPolyId);

      if (isImported) {
        idToLayerOfImportedField.set(polyId, getLayer(polyId));
        listOfImportedFieldToScroll.push(fieldWithPolyId);
        getLayer(polyId)?.on('click', this.enableEditingByClickOnImportedField);
      } else {
        idToLayerOfPreviouslyAddedField.set(polyId, getLayer(polyId));
      }
    });

    if (listOfImportedFieldToScroll.length) {
      const sortedList = _.sortBy(listOfImportedFieldToScroll, 'errorType');
      const polyId = sortedList[0]?.polyId;

      if (polyId) {
        centerMapToPoint(polyId);
      }
    }

    setIdToField(idToField);
    setIdToUnchangedLayer(idToLayerOfPreviouslyAddedField);
    setIdToImportedFieldLayer(idToLayerOfImportedField);

    checkCrossingOfFields();
  };

  createFieldPolygon = (field: Field, renderOption?: RenderPolygonOption): number => {
    const { isImportMapMode } = this.mapStore;
    const { showLabelFieldFill } = this.fieldsStore;

    const isImported = field?.isImported;

    const polygonOptions: AddPolygonOptionsType = {
      ...makeTooltipVariants(field),
      renderOption:
        isImportMapMode && !isImported
          ? RenderPolygonOption.Import
          : renderOption || RenderPolygonOption.View,
    };

    const polyId = this.mapStore.setPolygon(
      field.geometry.coordinates,
      polygonOptions,
      showLabelFieldFill
    );

    return polyId;
  };

  setFieldsToStoreAndMap = (
    fields: Field[],
    renderOption?: RenderPolygonOption,
    excludeFieldId?: string
  ) => {
    fields.forEach((field, index) => {
      if (field.id === excludeFieldId) {
        return;
      }

      const polyId = this.createFieldPolygon(field, renderOption);
      this.setFieldById(field.id, { ...field, polyId });

      if (
        index === 0 &&
        renderOption !== RenderPolygonOption.Creating &&
        !this.fieldsStore.firstLoadingFields
      ) {
        this.mapStore.centerMapToPoint(polyId);
        this.setFirstLoadingFields(true);
      }

      if (renderOption !== RenderPolygonOption.Creating) {
        const polygon = this.mapStore.idToPolygon.get(polyId);
        if (polygon) {
          this.mapStore.idToPolygon.get(polyId).on('click', this.selectFieldOnPolygonEvent);
        }
      }
    });
  };

  resetSelectedField = async (): Promise<void> => {
    this.selectFieldId('');
    this.uiStore.setPageState(PopupPages.None, false, true);
    await this.fetchFieldsList();
    this.mapStore.centerMapToPoint(this.fieldsStore.fields?.[0]?.polyId);
  };

  setMainPageState = () => {
    if (
      this.fieldsStore.mapMode === MapMode.Listing &&
      (this.uiStore.popupPageState === PopupPages.Main ||
        this.uiStore.popupPageState === PopupPages.None)
    ) {
      if (this.mapStore.instance) {
        setTimeout(() => this.mapStore.instance?.invalidateSize?.(), 300);
      }

      this.uiStore.setPopupPageState(PopupPages.Main);
    }
  };

  getSelectedFieldByLeafletId = (leafletId: number): Field =>
    Array.from(this.fieldsStore.idToFields.values()).find(({ polyId }) => polyId === leafletId) ||
    this.fieldsStore.fieldCultureZonesId.get(leafletId);

  selectFieldOnPolygonEvent = event => {
    if (
      this.fieldsStore.mapMode !== MapMode.Listing ||
      this.uiStore.popupPageState === PopupPages.Seasons
    ) {
      return;
    }

    const field = this.getSelectedFieldByLeafletId(event.target._leaflet_id);

    // don't handle field click if it's already selected
    if (this.fieldsStore.selectedFieldId === field.id) return;

    this.selectField(field.id);

    this.setMainPageState();

    // not center if there is selecting from polygon
    this.mapStore.centerMapToPoint(field.polyId);

    const selectedField = this.fieldsStore.getSelectedField();

    if (selectedField) {
      this.mapStore.changeColorToNormal(RenderPolygonOption.Listing, selectedField.polyId);
    }

    if (this.fieldsStore.showCulturesFillPanel === CultureFillEnum.Fill) {
      this.displayCultureZonesPolygons();
    }

    this.mapStore.highlightPolygon(field.polyId);
  };

  clearListing = () => {
    // this.fieldsStore.clearListing();
    this.fieldsStore.fields.forEach(f => {
      const layer = this.mapStore.idToPolygon.get(f.polyId);
      if (!layer) {
        return;
      }
      if (layer.getTooltip()) {
        layer.unbindTooltip();
        layer.remove();
      }

      this.mapStore.idToPolygon.delete(f.polyId);
    });

    this.clearSelectedFieldId();
  };

  setMapModeValue = (value: MapMode) => {
    this.fieldsStore.mapMode = value;
  };

  setMapMode = async (v: MapMode, excludeId?: string) => {
    this.mapStore.clearAll();
    this.fieldsStore.mapMode = v;

    if (v === MapMode.Creating) {
      this.fetchFieldsList(RenderPolygonOption.Creating);
    }

    if (v === MapMode.Editing) {
      await this.fetchFieldsList(RenderPolygonOption.Edit, excludeId);
      await this.fetchFieldById(excludeId);
      this.uiStore.setPageState(PopupPages.None, true);

      return;
    }

    if (v === MapMode.Listing) {
      this.uiStore.setPageState(PopupPages.None);
    }
  };

  startViewMode = () => {
    this.mapStore.setViewMode();
  };

  clear = () => {
    this.fieldsStore.isLoading = true;
    this.fieldsStore.clearIdToFields();
  };

  displayCultureZonesPolygons = (): number[] => {
    if (
      this.fieldsStore.mapMode === MapMode.Editing ||
      this.fieldsStore.mapMode === MapMode.Creating ||
      this.uiStore.popupPageState === PopupPages.CultureZone
    ) {
      return;
    }

    this.fieldsStore.fieldCultureZonesId.clear();

    const cultureZonesIds = [];
    this.fieldsStore.fields
      .filter(f => f.cultureZones?.length > 0)
      .forEach(field => {
        field.cultureZones.forEach(zone => {
          const zonePolyId = this.mapStore.drawCultureZones(zone);
          cultureZonesIds.push(zonePolyId);
          this.fieldsStore.cultureZones.add(zonePolyId);
          // adding a click handler to each culture
          this.fieldsStore.fieldCultureZonesId.set(zonePolyId, field);
          const polygon = this.mapStore.idToPolygon.get(zonePolyId);

          if (polygon) {
            polygon?.on('click', this.selectFieldOnPolygonEvent);
          }
        });
      });

    return cultureZonesIds;
  };

  editField = () => {
    this.uiStore.setPageState(PopupPages.None);
    this.mapStore.clearAll();
  };

  selectFieldId = id => {
    this.fieldsStore.prevFieldId = this.fieldsStore.selectedFieldId;
    this.fieldsStore.selectedFieldId = id;
  };

  closeFieldTooltip = (polyId: number): void => {
    if (
      this.fieldsStore.showCulturesFillPanel === CultureFillEnum.Ndvi ||
      this.fieldsStore.showCulturesFillPanel === CultureFillEnum.Msavi
    ) {
      this.mapStore.getLayer(polyId)?.closeTooltip();
    }
  };

  // todo рефактор слишком много запутанной логики
  selectField = async (id: string, changePageState = true) => {
    if (this.fieldsStore.selectedFieldId === id) {
      const field = this.fieldsStore.idToFields.get(id);
      if (!field) {
        return;
      }

      this.closeFieldTooltip(field.polyId);
      timerDelay(300).then(() => this.mapStore.centerMapToPoint(field.polyId, true));
      return;
    }

    this.fieldsStore.prevFieldId = this.fieldsStore.selectedFieldId;
    this.fieldsStore.selectedFieldId = id;

    await timerDelay(0);
    this.uiStore.setShowPopupSlider(true);
    if (
      changePageState &&
      this.fieldsStore.mapMode === MapMode.Listing &&
      (this.uiStore.popupPageState === PopupPages.Main ||
        this.uiStore.popupPageState === PopupPages.None)
    ) {
      this.uiStore.setPageState(PopupPages.Main);
    }
    if (this.fieldsStore.showCulturesFillPanel === CultureFillEnum.Fill) {
      this.displayCultureZonesPolygons();
    }
    const field = this.fieldsStore.idToFields.get(id);
    if (!field) {
      return;
    }

    this.closeFieldTooltip(field.polyId);
    // Задержка для пересчёта карты после появления блока погоды
    timerDelay(300).then(() => this.mapStore.highlightPolygon(field.polyId, true));
  };

  setShowCulturesFillPanel = (fill: CultureFillEnum): number[] => {
    this.fieldsStore.showCulturesFillPanel = fill;
    if (fill === CultureFillEnum.Fill) {
      const cultureZonesIds = this.displayCultureZonesPolygons();
      const field = this.fieldsStore.idToFields.get(this.fieldsStore.selectedFieldId);

      if (field) {
        this.mapStore.highlightPolygon(field.polyId);
      }

      return cultureZonesIds;
    }

    this.fieldsStore.cultureZones.forEach(id => {
      this.mapStore.deletePolygon(id);
    });
    this.fieldsStore.cultureZones.clear();
    this.fieldsStore.fields.forEach(field => {
      const layer = this.mapStore.getLayer(field.polyId);
      if (!layer) {
        return;
      }
      // @ts-ignore
      layer.setStyle({
        fillColor: '#fff9e8',
        fillOpacity: 0.2,
      });
    });
  };

  setFieldRef = (id: string, ref: ReactNode) => {
    const field = this.fieldsStore.idToFields.get(id);
    if (field) field.ref = ref;
  };

  setFieldById = (id: string, field: Field) => {
    runInAction(() => this.fieldsStore.idToFields.set(id, field));
  };

  clearSelectedFieldId = () => {
    this.fieldsStore.selectedFieldId = '';
  };

  setNoneFieldFill = (value: boolean) => {
    this.fieldsStore.noneFieldFill = value;
  };

  resetFieldFill = (selectFirstField = true) => {
    if (selectFirstField) {
      this.selectField(Array.from(this.fieldsStore.idToFields.keys())[0]);
    } else {
      this.centerMapWithoutSelect();
      // this.ui.setPageState(PopupPages.None, true);
    }
    this.setNoneFieldFill(true);
    if (this.uiStore.showFullWeather) this.uiStore.setFullWeatherMode(false);
    if (this.uiStore.showPopupSlider) this.uiStore.setShowPopupSlider(false);
  };

  /**
   * Select first poly in fields list without select
   * TODO: possible need select center point from geolocation or moscow by default
   */
  centerMapWithoutSelect = () => {
    const fieldId = Array.from(this.fieldsStore.idToFields.keys()).shift();
    if (fieldId) {
      const field = this.fieldsStore.idToFields.get(fieldId);
      this.mapStore.centerMapToPoint(field.polyId);
    }
  };

  clearCreatableFields = () => {
    let layers = Array.from(this.mapStore.idToEditableLayer.values());
    layers.forEach(l => {
      l.unbindTooltip();
      l.remove();
    });
    layers = Array.from(this.mapStore.idToPolygon.values());
    layers.forEach(l => {
      l.unbindTooltip();
      l.remove();
    });
    this.mapStore.idToEditableLayer.clear();
    this.fieldsStore.idToCreatableField.clear();
    layers = Array.from(this.mapStore.idToPolygon.values());
    layers.forEach(l => {
      l.unbindTooltip();
      l.remove();
    });
    this.mapStore.idToPolygon.clear();
    this.mapStore.currentLayer = null;
    this.mapStore.isShapeFinished = false;
    this.mapStore.unbindCreatingEvents();
  };

  setFirstLoadingFields = (value: boolean) => {
    this.fieldsStore.firstLoadingFields = value;
  };

  renderAvailableFields = (
    renderOption?: RenderPolygonOption,
    excludeFieldId?: string,
    renderWithFill?: CultureFillEnum
  ) => {
    this.fieldsStore.fields.forEach(field => {
      if (field.id === excludeFieldId) {
        return;
      }
      const polyId = this.mapStore.setPolygon(
        field.geometry.coordinates,
        {
          ...makeTooltipVariants(field),
          renderOption: renderOption || RenderPolygonOption.View,
        },
        this.fieldsStore.showLabelFieldFill
      );
      this.fieldsStore.idToFields.set(field.id, { ...field, polyId });
      // if (index === 0) {
      //   // @ts-ignore
      //   const latlng = L.latLng(Number(field.center.latitude), Number(field.center.longitude));
      //   this.map.instance.setView(latlng);
      //   let layer;
      //   try {
      //     layer = this.map.getLayer(polyId);
      //     this.map.instance.fitBounds(layer.getBounds(), {
      //       padding: [220, 220],
      //     });
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }

      if (renderOption !== RenderPolygonOption.Creating) {
        const polygon = this.mapStore.idToPolygon.get(polyId);

        if (polygon) {
          this.mapStore.idToPolygon.get(polyId).on('click', this.selectFieldOnPolygonEvent);
        }
      }
    });

    if (renderWithFill) {
      this.fieldsStore.setNoneFieldFill(false);
      this.setShowCulturesFillPanel(renderWithFill);
    }
  };

  setPreviousValues = (prevValue: PrevValuesType) => {
    this.fieldsStore.prevPopupState = prevValue.prevPopupState;
    this.fieldsStore.prevMapMode = prevValue.prevMapMode;
    this.fieldsStore.prevSeason = prevValue.prevSeason;
    this.fieldsStore.prevOrganization = prevValue.prevOrganization;
  };

  startFieldCreating = () => {
    this.mapStore.changeMode();
  };

  handleEditPolygon = (data: any) => {
    const points = data.detail.geometry.coordinates;
    const polyId = data.detail.id;
    console.log('handle points ', data);
    const polygon = turfPolygon(points);
    // conver square meters to square gectars
    const fieldArea = area(polygon) / 10000;
    const field = this.fieldsStore.idToCreatableField.get(polyId);
    console.log(field);
    // const name = `Поле ${toFixedWithCeilBackEnd(fieldArea)} га`;
    console.log(this.fieldsStore.idToCreatableField.get(polyId));
    console.log(this.mapStore.idToEditableLayer.get(polyId));
    const name = this.fieldsStore.idToCreatableField.get(polyId)?.name;

    const layer = this.mapStore.getEditableLayer(polyId);
    if (name) layer.getTooltip().setContent(name);
    if (field) {
      field.areaInHectare = fieldArea;
    }

    // field.name = name;

    this.mapStore.clearPointsOfUnfinishedContour();
  };

  handlePolygonCreation = (data: any) => {
    const points = [data.detail.geometry];
    const polyId = data.detail.id;
    console.log('points', points);
    const polygon = turfPolygon(points);
    const fieldArea = area(polygon) / 10000;
    console.log(fieldArea);
    const name = `Поле ${toFixedWithCeilBackEnd(fieldArea)} га`;
    console.log('name', name);
    const layer = this.mapStore.getEditableLayer(polyId);
    layer.getTooltip().setContent(name);
    this.fieldsStore.idToCreatableField.set(polyId, {
      id: polyId,
      name,
      areaInHectare: parseFloat(fieldArea.toFixed(7)),
      longLatGeometry: points,
      polyId,
    });

    this.mapStore.clearPointsOfUnfinishedContour();
  };

  changeName = (id: number, v: string) => {
    const field = this.fieldsStore.idToCreatableField.get(id);
    field.name = v;
    this.mapStore.changeCreatableFieldName(id, v);
  };

  deleteField = (id: number) => {
    const field = this.fieldsStore.idToCreatableField.get(id);
    this.mapStore.deletePolygon(field.polyId);
    this.fieldsStore.idToCreatableField.delete(id);
    this.mapStore.checkCrossingOfFields();
  };

  creatableFieldsWithGeoJson = () => {
    const fields = Array.from(this.fieldsStore.idToCreatableField.values());
    return fields.map(field => {
      const layer = this.mapStore.getEditableLayer(field.polyId);
      // @ts-ignore
      const geoJson = layer.toGeoJSON();
      return {
        name: field.name,
        geoJson: {
          ...geoJson,
          geometry: {
            ...geoJson.geometry,
            coordinates: [
              geoJson.geometry.coordinates[0].map(position => [
                position[0].toFixed(19),
                position[1].toFixed(19),
              ]),
            ],
          },
        },
      };
    });
  };

  saveCreatableFields = async () => {
    if (this.fieldsStore.isAreaToBig) {
      throw new Error(FieldsErrors.AreaTobBig);
    }

    if (this.mapStore.incorrectLayersId.size > 0) {
      throw new Error(FieldsErrors.Intersection);
    }

    if (this.mapStore.pointsOfUnfinishedContour) {
      throw new Error(FieldsErrors.UnfinishedContour);
    }

    let response: TypeApiResponse<'createFields'> | null = null;

    try {
      this.fieldsStore.isLoading = true;
      const params = {
        organizationId: this.organizationStore.selectedOrganizationId,
        seasonYear: Number(this.seasonsStore.selectedSeason),
        fields: this.creatableFieldsWithGeoJson(),
      };
      response = await this.axios.api.createFields(params);
    } catch (e) {
      this.fieldsStore.isLoading = false;
      console.log(e);

      throw new Error(e.response.data.error);
    }
    this.fieldsStore.isLoading = false;
    this.clearCreatableFields();
  };

  fetchFieldById = async (id: string) => {
    let response: TypeApiResponse<'getFieldById'>;

    let attempts = 0;

    while (!this.seasonsStore.selectedSeason) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise(resolve => {
        setTimeout(() => resolve(0), 1000);
      });
      attempts++;
      if (attempts > 5) {
        throw new Error('season not passed');
      }
    }

    try {
      response = await this.axios.api.getFieldById(
        { fieldId: id, seasonYear: Number(this.seasonsStore.selectedSeason) },
        { omit: ['fieldId'] }
      );
    } catch (e) {
      return;
    }
    this.fieldsStore.editableField = response;

    setTimeout(() => {
      const prevPolyId = this.fieldsStore.getField(id)?.polyId;
      if (prevPolyId) this.mapStore.deletePolygon(prevPolyId);
    }, 1000);

    const layerId = this.mapStore.setPolygon(response.geometry.coordinates, {
      name: response.name,
      renderOption: RenderPolygonOption.View,
      editable: true,
    });
    const layer = this.mapStore.getEditableLayer(layerId);
    // @ts-ignore
    layer.pm.enable({ allowSelfIntersection: false, preventMarkerRemoval: true });
    this.fieldsStore.editableField.polyId = layerId;
    this.fieldsStore.initialEditableField = response;
    this.fieldsStore.initialEditableField.polyId = layerId;
    const points = response.geometry.coordinates;

    const polygon = turfPolygon(points);
    const fieldArea = area(polygon) / 10000;

    this.fieldsStore.editableField.areaInHectare = fieldArea;
    this.mapStore.changeLayerToEditable(layerId);
  };

  setFullWeatherMode = (isFull: boolean) => {
    this.fieldsStore.showFullWeather = isFull;
  };

  fetchWeatherById = async () => {
    let response: TypeApiResponse<'getWeatherById'>;
    if (!this.fieldsStore.selectedFieldId) return;
    try {
      this.fieldsStore.isLoading = true;
      response = await this.axios.api.getWeatherById({
        id: this.fieldsStore.selectedFieldId,
        seasonYear: Number(this.seasonsStore.selectedSeason),
      });
      this.fieldsStore.weatherById = response;
      this.fieldsStore.isLoading = false;
    } catch (e) {
      this.fieldsStore.isLoading = false;
      return;
    }
    return response;
  };

  fetchWeatherForecastsById = async () => {
    let response: TypeApiResponse<'getWeatherForecastsById'>;
    if (!this.fieldsStore.selectedFieldId) return;
    try {
      this.fieldsStore.isLoading = true;
      response = await this.axios.api.getWeatherForecastsById({
        id: this.fieldsStore.selectedFieldId,
        seasonYear: Number(this.seasonsStore.selectedSeason),
      });
      this.fieldsStore.weatherForecastsById = response;
      this.fieldsStore.isLoading = false;
    } catch (e) {
      this.fieldsStore.isLoading = false;
      return;
    }
    return response;
  };

  // saveEditableField = async (id: string) => {
  //   if (this.fieldsStore.editableField.areaInHectare > 1000) {
  //     throw new Error(FieldsErrors.AreaTobBig);
  //   }
  //   console.log(1);
  //   if (this.mapStore.incorrectLayersId.size > 0) {
  //     throw new Error(FieldsErrors.Intersection);
  //   }
  //   console.log(2);
  //   try {
  //     this.fieldsStore.isLoading = true;
  //     const editableFieldWithGeoJSON = this.fieldsStore.editableFieldWithGeoJson;
  //     console.log(3);
  //     // @ts-ignore
  //     await this.axios.api.saveFieldById({
  //       id,
  //       cultureZoneId: id,
  //       ...editableFieldWithGeoJSON,
  //       // @ts-ignore
  //       seasonYear: Number(this.seasonsStore.selectedSeason),
  //     });
  //     console.log(4);
  //     const field = this.fieldsStore.editableField;
  //     console.log(5);
  //     if (field && field.cultureZones && field.cultureZones.length && this.seasonsStore.selectedSeason) {
  //       console.log(6);
  //       await this.axios.api.updateCultureZones({
  //         zones: [
  //           {
  //             ...editableFieldWithGeoJSON,
  //             sowingDate: String(field.cultureZones[0].sowingDate),
  //             harvestDate: String(field.cultureZones[0].harvestDate),
  //             cultureId: field.cultureZones[0].culture.id,
  //           },
  //         ],
  //         seasonYear: Number(this.seasonsStore.selectedSeason),
  //         fieldId: this.fieldsStore.editableField.id,
  //       });
  //     }
  //   } catch (e) {
  //     this.fieldsStore.isLoading = false;
  //     throw new Error(e.response.data.error);
  //   }
  //   this.fieldsStore.isLoading = false;
  // };

  isNewPolygonCultureZonesValid = (polygon: Feature<Polygon>) => {
    const ALLOWED_OUTSIDE_AREA = 1; // sq.m
    const ALLOWED_CZ_OVERFLOW_AREA = 10;

    if (toJS(this.fieldsStore.editableField.cultureZones).length > 0) {
      let isCultureZoneOutsidePolygon: boolean;
      let isPolygonPointInsideCultureZone: boolean;
      let isPolygonIntersectsCultureZones: boolean;

      // @ts-ignore
      // if (window.displayCZ) {
      //   this.fieldsStore.editableField.cultureZones.forEach(zone => {
      //     const zonePolyId = this.mapStore.drawCultureZones(zone);
      //     this.fieldsStore.cultureZones.add(zonePolyId);
      //     this.fieldsStore.fieldCultureZonesId.set(zonePolyId, this.fieldsStore.editableField);
      //   });
      // }

      const pointsOfNewPolygon = explode(polygon);
      toJS(this.fieldsStore.editableField.cultureZones).forEach(cultureZone => {
        const cultureZonePolygon = turfPolygon(cultureZone.geometry.coordinates);
        const cultureZoneOutside = difference(cultureZonePolygon, polygon);
        const outsideArea = cultureZoneOutside === null ? 0 : area(cultureZoneOutside);

        /**
         *  Полигон культурной зоны не выступает больше погрешности
         */
        if (outsideArea > ALLOWED_OUTSIDE_AREA) {
          // console.log('🗺 ❌ fields outside', { cultureZonePolygon, polygon, cultureZoneOutside });
          isCultureZoneOutsidePolygon = outsideArea > ALLOWED_OUTSIDE_AREA;
          return;
        }

        /**
         * Точки полигона внутри культурных зон
         */
        isPolygonPointInsideCultureZone = pointsOfNewPolygon.features.some(point => {
          const isPointInside = booleanPointInPolygon(point, cultureZonePolygon, {
            ignoreBoundary: true,
          });

          // if (isPointInside) {
          //   console.log('🗺 ❌ point inside polygon', [point, cultureZonePolygon]);
          // }

          return isPointInside;
        });

        const intersection = intersect(polygon, cultureZonePolygon);

        if (
          intersection !== null &&
          Math.abs(area(intersection) - area(cultureZonePolygon)) > ALLOWED_CZ_OVERFLOW_AREA
        ) {
          // console.log('=====', {
          //   intersection,
          //   cultureZonePolygon,
          //   areas: {
          //     intersect: area(intersection),
          //     cz: area(cultureZonePolygon),
          //     diff: Math.abs(area(intersection) - area(cultureZonePolygon)),
          //   },
          // });

          isPolygonIntersectsCultureZones = true;
        }
      });

      if (
        isPolygonIntersectsCultureZones ||
        isPolygonPointInsideCultureZone ||
        isCultureZoneOutsidePolygon
      ) {
        // eslint-disable-next-line no-console
        console.warn('🗺 ❌ При изменении контура поля найдено невалидное состояние:', {
          isPolygonIntersectsCultureZones,
          isPolygonPointInsideCultureZone,
          isCultureZoneOutsidePolygon,
        });
      }

      return (
        !isPolygonIntersectsCultureZones &&
        !isPolygonPointInsideCultureZone &&
        !isCultureZoneOutsidePolygon
      );
    } else {
      return true;
    }
  };

  // isPolygonIntersectsCultureZones = fieldPolygonAfterChanges => {
  //   return this.fieldsStore.editableField.cultureZones.some(cultureZone => {
  //     const cultZonePoly = turfPolygon(cultureZone.geometry.coordinates);
  //     const intersection = intersect(fieldPolygonAfterChanges, cultZonePoly);

  //     if (intersection === null) {
  //       return true;
  //     }

  //     return area(intersection) < area(cultZonePoly);
  //   });
  // };

  handleEditPolygonInEditMode = data => {
    const newPolygon = turfPolygon([data.detail.geometry.coordinates[0]]);
    const isPolygonValid = this.isNewPolygonCultureZonesValid(newPolygon);

    if (!isPolygonValid) {
      return this.mapController.setCultureZoneOutOfFieldError(true);
    }

    this.mapController.setCultureZoneOutOfFieldError(false);
    const points = data.detail.geometry.coordinates;

    const polygon = turfPolygon(points);
    // convert square meters to square gectars
    const fieldArea = area(polygon) / 10000;
    this.fieldsStore.editableField.areaInHectare = fieldArea;
    this.fieldsStore.editableField.geometry = data.detail.geometry;
  };

  changeEditableFieldName = (v: string) => {
    this.fieldsStore.editableField.name = v;
    const layer = this.mapStore.getEditableLayer(this.fieldsStore.editableField.polyId);

    layer.setTooltipContent(this.fieldsStore.editableField.name);
  };

  deleteFieldById = async (id: string) => {
    try {
      await this.axios.api.deleteFieldById({ fieldId: id });

      const field = this.fieldsStore.editableField || this.fieldsStore.idToFields.get(id);

      if (field) this.mapStore.deletePolygon(field.polyId);

      this.fieldsStore.idToFields.delete(id);
      this.mapStore.clearLayouts();
    } catch (e) {
      throw e;
    }
  };

  deleteFieldFromCurrentSeason = async (id: string, seasonYear: number) => {
    try {
      await this.axios.api.deleteFieldFromCurrentSeason({ fieldId: id, seasonYear });

      const field = this.fieldsStore.editableField || this.fieldsStore.idToFields.get(id);

      if (field) this.mapStore.deletePolygon(field.polyId);

      this.fieldsStore.idToFields.delete(id);
      this.mapStore.clearLayouts();
    } catch (e) {
      throw e;
    }
  };

  unbindEditEvents = () => {
    window.removeEventListener(editPolygonEventName, this.handleEditPolygonInEditMode);
  };

  resetEditMode = () => {
    [
      ...Array.from(this.mapStore.idToPolygon.values()),
      ...Array.from(this.mapStore.idToEditableLayer.values()),
    ].forEach(l => {
      l.unbindTooltip();
      l.remove();
    });
    this.mapStore.idToPolygon.clear();
    this.mapStore.idToEditableLayer.clear();
  };

  refreshFieldsList = (onRefresh?: () => void): void => {
    this.fetchFieldsList().then(() => {
      onRefresh?.();
    });
  };

  setNonePageState = () => {
    if (this.mapStore.instance) {
      setTimeout(() => this.mapStore.instance.invalidateSize());
    }

    this.uiStore.setPopupPageState(PopupPages.None);
  };

  blurField = () => {
    this.selectFieldId('');

    this.uiStore.setPageState(PopupPages.None);
  };

  hasField = (id: string): boolean => Boolean(this.fieldsStore.idToFields.get(id));
}
