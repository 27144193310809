import styled from 'styled-components';

import { ITablePlugBuilderAutoRenderConfig as IAutoRenderConfig } from '../../models/configs';
import searchIcon from '../../assets/icons/search.svg';

type TIconName = IAutoRenderConfig['header']['icon'];

const getIconByName = (name: TIconName) => {
  switch (name) {
    case 'search':
      return searchIcon;
    default:
      return '';
  }
};

const Icon = styled.div<{ $name: TIconName }>`
  width: 50px;
  height: 50px;

  background: url(${({ $name }) => getIconByName($name)}); ;
`;

const Header = styled.div``;

const Description = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #7e8b9d;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #151f32;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 40px;
`;

const StyledTablePlugBuilderAutoRender = {
  Icon,
  Header,
  Description,
  Title,
  Content,
  Wrapper,
};

export default StyledTablePlugBuilderAutoRender;
