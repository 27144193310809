import React, { FC, useState, useRef, useCallback, useEffect } from 'react';
import { Icon } from '@farmlink/farmik-ui';

import { SliderWrapper, SliderContainer, ArrowButton, ArrowButtonBgGradient } from './style';

export const ConfigurableSlider: FC<{ dataTestId?: string }> = ({
  children,
  dataTestId = 'configurable',
}) => {
  const scroll = useRef<HTMLDivElement>();

  const [blockedLeftArr, setBlockedLeftArr] = useState<boolean>(true);
  const [blockedRightArr, setBlockedRightArr] = useState<boolean>(false);
  const [noScroll, setNoScroll] = useState<boolean>(false);
  const checkSizeTriggerCount = useRef<number>(0);

  const scrollHandle = e => {
    if (
      e.target.scrollLeft > 0 &&
      e.target.scrollLeft + e.target.clientWidth < e.target.scrollWidth
    ) {
      setBlockedLeftArr(false);
      setBlockedRightArr(false);
    }
    if (e.target.scrollLeft === 0) {
      setBlockedLeftArr(true);
      setBlockedRightArr(false);
    }
    if (
      e.target.scrollLeft > 0 &&
      Math.ceil(e.target.scrollLeft) + Math.ceil(e.target.clientWidth) >= e.target.scrollWidth
    ) {
      setBlockedLeftArr(false);
      setBlockedRightArr(true);
    }
  };

  const clickHandle = useCallback((direction: 'left' | 'right') => {
    if (direction === 'left') {
      scroll.current.scrollLeft -= 250;
    } else {
      scroll.current.scrollLeft += 250;
    }
  }, []);

  const checkSize = () => {
    if (checkSizeTriggerCount.current) return;
    else checkSizeTriggerCount.current += 1;
    if (scroll.current && scroll.current.scrollWidth > scroll.current.clientWidth) {
      setBlockedLeftArr(true);
      setBlockedRightArr(false);
    } else {
      setBlockedLeftArr(true);
      setBlockedRightArr(true);
    }
  };

  useEffect(() => {
    if (checkSizeTriggerCount.current) checkSizeTriggerCount.current = 0;
    scroll.current.scrollLeft = 0;
    checkSize();
  }, [scroll.current?.children.length]);

  useEffect(() => {
    window.addEventListener('resize', checkSize);
    return () => {
      window.removeEventListener('resize', checkSize);
    };
  }, []);

  return (
    <SliderWrapper data-test-id={`${dataTestId}-slider-wrapper`}>
      {!blockedLeftArr && (
        <ArrowButtonBgGradient $position="left" data-test-id={`${dataTestId}-slider-left-arr`}>
          <ArrowButton
            onClick={() => clickHandle('left')}
            data-test-id={`${dataTestId}-slider-left-arr-btn`}
          >
            <Icon
              icon={'arrow_left'}
              size={'small'}
              dataTestId={`${dataTestId}-slider-left-arr-btn-icon`}
            />
          </ArrowButton>
        </ArrowButtonBgGradient>
      )}
      <SliderContainer
        ref={scroll}
        onScroll={scrollHandle}
        data-test-id={`${dataTestId}-slider-container`}
      >
        {children}
      </SliderContainer>
      {!blockedRightArr && (
        <ArrowButtonBgGradient $position="right" data-test-id={`${dataTestId}-slider-right-arr`}>
          <ArrowButton
            onClick={() => clickHandle('right')}
            data-test-id={`${dataTestId}-slider-right-arr-btn`}
          >
            <Icon
              icon={'arrow_right'}
              size={'small'}
              data-test-id={`${dataTestId}-slider-right-arr-btn-icon`}
            />
          </ArrowButton>
        </ArrowButtonBgGradient>
      )}
    </SliderWrapper>
  );
};
