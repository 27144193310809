const formatEnteredIntValue = (
  value: string,
  options?: {
    allowNegativeNumber?: boolean;
  }
): string | number => {
  const trimmedValue = value.trim();
  const numberValue = Number(trimmedValue);

  const isMinus = trimmedValue === '-';
  const isEmpty = trimmedValue === '';

  if (isEmpty) {
    return trimmedValue;
  }

  if (isMinus && options?.allowNegativeNumber) {
    return trimmedValue;
  }

  if (!isNaN(numberValue)) {
    return numberValue;
  }
};

export default formatEnteredIntValue;
