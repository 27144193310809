import styled from 'styled-components';

const StatusButtonWrapper = styled.div`
  width: 160px;
`;

const Styled = {
  StatusButtonWrapper,
};

export default Styled;
