import { makeAutoObservable, runInAction, reaction } from 'mobx';

import { lazyInject, provide } from '../../../../../../shared/utils/IoC';
import { TypeUser, TypeUserRole, TypeUserStatus } from '../../../../../../../api/models/user.model';
import { Axios } from '../../../../../../shared/utils/axios2';
import { OrganizationsStore } from '../../../../../stores/organizations.store';

export type TableUserType = {
  status: TypeUserStatus;
  userName: string;
  role: TypeUserRole;
  organizationJoinDate: string;
  employeeId: string;
  id?: any;
};

export type FilterType = {
  status?: TypeUserStatus | undefined;
  userName?: string | undefined;
  role?: TypeUserRole | undefined;
  organizationJoinDate?: string | undefined;
};

const defaultFilters = {
  status: undefined,
  userName: undefined,
  role: undefined,
  organizationJoinDate: undefined,
};

@provide.singleton()
export class OrganizationUsersStore {
  @lazyInject(Axios)
  protected axios2: Axios;

  @lazyInject(OrganizationsStore)
  protected organizations: OrganizationsStore;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.organizations.selectedOrganizationId,
      () => {
        if (this.organizations.selectedOrganizationId !== 'my') {
          this.getOrganizationUsersList(this.organizations.selectedOrganizationId);
        }
      }
    );
  }

  usersList: TypeUser[] = [];
  tableUsersList: TableUserType[] = [];
  emptyListMessage = '';
  chosenUserId = '';
  filters: FilterType = defaultFilters;

  getOrganizationUsersList = (organizationId: string, usersStatus?: TypeUserStatus) => {
    this.emptyListMessage = '';

    this.axios2.api
      .getOrganizationUsers({
        organizationID: organizationId === 'my' ? '' : organizationId,
        role: this.filters.role || undefined,
        status: this.filters.status || usersStatus || undefined,
        fullname: this.filters.userName || undefined,
      })
      .then(response => {
        runInAction(() => {
          this.usersList = response.content;
          this.normalizeUsersListForTable(response.content);
        });
      })
      .catch(error => {
        console.error(error);

        this.emptyListMessage = 'Не удалось загрузить список сотрудников';
      });
  };

  normalizeUsersListForTable = (usersList: TypeUser[]) => {
    this.tableUsersList = usersList.map(user => {
      const organizationJoinDate = user.organizationJoinDate.slice(0, 10);
      const [year, month, day] = organizationJoinDate.split('-');

      return {
        status: user.status,
        userName: user.userName,
        role: user.role,
        organizationJoinDate: `${day}.${month}.${year}`,
        employeeId: user.id,
      };
    });
  };

  setFilters = (filters: FilterType, organizationId: string) => {
    this.filters = filters;

    this.getOrganizationUsersList(organizationId);
  };

  clearFilters = () => {
    this.filters = defaultFilters;
  };

  openEditUserForm = (employeeId: string) => {
    this.chosenUserId = employeeId;
    console.log('user choosed');
  };

  closeEditUserForm = () => {
    this.chosenUserId = '';
  };

  getUserById = ({ organizationId }: { organizationId: string }) =>
    this.axios2.api.getOrganizationUser(
      { userId: this.chosenUserId, organizationId },
      { omit: ['userId', 'organizationId'] }
    );

  updateUserRole = (payload: { role: TypeUserRole; employeeId?: string }, organizationId: string) =>
    this.axios2.api.updateUserRole({
      role: payload.role,
      employeeId: payload.employeeId,
      organizationId,
    });

  deleteUser = (organizationId: string) =>
    this.axios2.api.deleteOrganizationUser(
      {
        userId: this.chosenUserId,
        organizationId,
      },
      { omit: ['userId'] }
    );
}
