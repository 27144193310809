import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';
import StyledEditableFieldItem from '../../components/EditableFieldItem/EditableFieldItem.styles';

const ErrorNotificationWrapper = styled.div`
  padding: 0 16px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 68px;
  margin-bottom: 106px;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0;
  }

  /* ie 10+ */
  & {
    -ms-overflow-style: none;
  }

  /* (свойство больше не работает, других способов тоже нет) */
  & {
    overflow: -moz-scrollbars-none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  & ${StyledEditableFieldItem.Wrapper}:not(:last-child) {
    border-bottom: 1px solid ${Colors.borderGray};
  }
`;

const StyledFieldsImportContainer = {
  ErrorNotificationWrapper,
  ListWrapper,
};

export default StyledFieldsImportContainer;
