import { TypeApiRoute } from '../../../models/type.api.request';

type TDeleteImportedFieldByIdReq = {
  id: string;
};

type TDeleteImportedFieldByIdRes = {};

const deleteImportedFieldById: TypeApiRoute & {
  request: TDeleteImportedFieldByIdReq;
  response: TDeleteImportedFieldByIdRes;
} = {
  url: ({ id }) => `/api/as-fields/import/field/${id}`,
  method: 'DELETE',
  headers: {},
  request: {} as TDeleteImportedFieldByIdReq,
  response: {} as TDeleteImportedFieldByIdRes,
};

export type { TDeleteImportedFieldByIdReq, TDeleteImportedFieldByIdRes };

export { deleteImportedFieldById };
