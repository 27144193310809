import { TaskStatuses } from '../../../modules/dashboard/modules/operations/stores/tasks.store';
import { ETaskStatus } from '../../models/as-fields/task/task.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TSetStatusReq = {
  taskId: string;
  status: TaskStatuses | ETaskStatus;
  cancellationReason: string;
};

type TypeResponse = {};

export const setStatus: TypeApiRoute & {
  request: TSetStatusReq;
  response: TypeResponse;
} = {
  url: () => `/api/as-fields/task/setStatus`,
  method: 'POST',
  headers: {},
  request: {} as TSetStatusReq,
  response: {} as TypeResponse,
};

export type { TSetStatusReq };
