import { Colors, IconButton as FIconButton, Input } from '@farmlink/farmik-ui';
import styled, { css } from 'styled-components';

const BottomBackground = styled.div`
  background-color: ${`${Colors.black}66`}; // hex + 40% opacity
  position: absolute;
  z-index: 1005;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 16px;
`;

const Container = styled.form`
  display: flex;
  flex-direction: row;
  gap: 12px;
  opacity: 1;
`;

const IconButton = styled(FIconButton)`
  background-color: ${Colors.generalWhite};
`;

const IconWrapper = styled.div<{ $hoverColor?: Colors | string }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  transition: background-color 0.2s ease-in-out;

  :hover {
    background-color: ${({ $hoverColor }) => ($hoverColor ? $hoverColor : Colors.secondaryBGGrey)};
  }
`;

const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
    <path
      d="M14.0521 1.02409C13.7836 0.836348 13.4098 0.861638 13.1696 1.09996L13.1696 1.1L4.91129 9.30338L1.32018 5.76316C1.32015 5.76313 1.32011 5.7631 1.32008 5.76307C1.05142 5.49654 0.615551 5.49657 0.346926 5.76316C0.0778979 6.03014 0.0777668 6.46292 0.347017 6.72944L0.347167 6.72959L4.42675 10.7509C4.69306 11.0145 5.13408 11.0145 5.40039 10.7509L5.40052 10.7508L14.1426 2.06634C14.1427 2.06627 14.1428 2.0662 14.1429 2.06612L14.1429 2.06612L14.0724 1.99517C14.2634 1.80665 14.296 1.52033 14.1702 1.29841C14.2071 1.26293 14.2183 1.21408 14.2195 1.18986M14.0521 1.02409L14.1902 1.15175C14.2005 1.16419 14.2103 1.1769 14.2195 1.18986M14.0521 1.02409C14.0597 1.02364 14.0648 1.02418 14.0664 1.02525L14.0722 1.02995L14.0746 1.03212L14.0766 1.034L14.0779 1.03533L14.143 1.09965L14.208 1.16409L14.2079 1.16411M14.2195 1.18986C14.2199 1.18225 14.2193 1.17708 14.2182 1.1755L14.2134 1.16976L14.2112 1.16738L14.2093 1.16544L14.2079 1.16411M14.2195 1.18986L14.2079 1.16405C14.2081 1.16429 14.2082 1.16437 14.2079 1.16411"
      fill="#3FB65F"
      stroke="#3FB65F"
      strokeWidth="0.2"
    />
  </svg>
);

const CoordinateDisplay = styled(Input)<{ $isError?: boolean }>`
  ${({ $isError }) =>
    $isError &&
    css`
      color: ${Colors.pink};
      border-color: ${Colors.pink} !important;
    `}

  width: 220px;
`;

const Styled = {
  Container,
  CoordinateDisplay,
  BottomBackground,
  IconButton,
  IconWrapper,
  CheckIcon,
};

export default Styled;
