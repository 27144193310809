import { IDrawChecklistInstance } from '../../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { MapController } from '../../../../../../../../../../shared/features/map/mobx/controllers';
import { useStore } from '../../../../../../../../../../shared/utils/IoC';
import { InspectionPointsStore } from '../../../../../../../../tasks/components/InspectionPoints/mobx/stores';
import { ChecklistInstancesStore } from '../../../../../../../stores/checklist.instances.store';
import { TInstanceExtendedByClientId } from '../../ChecklistInstance';

const DELETABLE_CLASSNAME = 'inspection-point_deletable';

const useChecklistInstanceActions = () => {
  const { getPointElement, setIsInspectionPointsChanged } = useStore(InspectionPointsStore);
  const { changeMarkerStyle } = useStore(MapController);
  const { setDrawInst } = useStore(ChecklistInstancesStore);

  const markAsDeletable = (id: string, state: boolean) => {
    const point = getPointElement(id);

    if (point?.marker) {
      const { className } = point.marker.leafletMarker.options.icon.options;

      if (state) {
        changeMarkerStyle(point.marker.id, {
          className: `${className} ${DELETABLE_CLASSNAME}`,
        });
      } else {
        changeMarkerStyle(point.marker.id, {
          className: className.replace(` ${DELETABLE_CLASSNAME}`, ''),
        });
      }
    }
  };

  const getUpdatedDrawInst = (
    instance: IDrawChecklistInstance,
    updatedInstance: TInstanceExtendedByClientId
  ): IDrawChecklistInstance => {
    return {
      ...instance,
      isMachinery: updatedInstance.type === 'MACHINERY',
      isField: updatedInstance.type === 'FIELD',
      instance: {
        ...instance.instance,
        ...(updatedInstance.type ? { type: updatedInstance.type } : {}),
        ...(updatedInstance?.coordinates
          ? {
              planCoords: {
                ...instance.instance.planCoords,
                coordinates: updatedInstance.coordinates,
                geometry: {
                  ...instance.instance.planCoords.geometry,
                  coordinates: updatedInstance.coordinates,
                },
              },
            }
          : {}),
      },
    };
  };

  const updateDrawInst = (
    instance: IDrawChecklistInstance,
    updatedInstance: TInstanceExtendedByClientId
  ) => {
    const updatedDrawInst = getUpdatedDrawInst(instance, updatedInstance);

    setIsInspectionPointsChanged(true);

    setDrawInst(updatedDrawInst);
  };

  return { markAsDeletable, getUpdatedDrawInst, updateDrawInst };
};

export default useChecklistInstanceActions;
