import styled from 'styled-components';

const NoteWrapper = styled.div`
  & p > span {
    white-space: pre-line;
  }
`;

const Styled = {
  NoteWrapper,
};

export default Styled;
