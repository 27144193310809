import styled, { css } from 'styled-components';
import { Colors as FColors, IconButton } from '@farmlink/farmik-ui';

import { Colors } from '../../../../../../../../shared/constans/colors';
import { ICustomColor } from '../../../../../../../../shared/constans/customColorsPrests';
import { EPointType } from '../../ListOfChecklistInstance.types';

import deleteSvg from './assets/images/delete-16x16.svg';
import completedSvg from './assets/images/completed.svg';
import uncompletedSvg from './assets/images/uncompleted.svg';
import warningSvg from './assets/images/warning.svg';
import errorSvg from './assets/images/error.svg';

const PositionMark = styled.div<{ $colors: ICustomColor }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 12px;
  color: ${({ $colors }) => $colors.color};
  background-color: ${({ $colors }) => $colors.backgroundColor};
  margin-right: 12px;
  text-align: center;
  align-items: center;
`;

const getTypeStyle = (type: EPointType) => {
  switch (type) {
    case EPointType.Completed:
      return css`
        background-color: ${FColors.lightGreen};
        background-image: url(${completedSvg});
      `;

    case EPointType.Uncompleted:
      return css`
        background-color: ${FColors.lightBlue};
        background-image: url(${uncompletedSvg});
      `;

    case EPointType.Warning:
      return css`
        background-color: ${FColors.lightOrange};
        background-image: url(${warningSvg});
      `;

    case EPointType.Error:
      return css`
        background-color: ${FColors.lightPink};
        background-image: url(${errorSvg});
      `;

    default:
      return css`
        background-color: ${FColors.lightBlue};
        background-image: url(${uncompletedSvg});
      `;
  }
};

const PositionType = styled.div<{ type: EPointType }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 36px;
  height: 36px;
  text-align: center;
  align-items: center;
  border-radius: 12px;
  margin-right: 16px;
  background-position: center;
  background-repeat: no-repeat;

  ${({ type }) => getTypeStyle(type)}
`;

const PositionText = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
`;

const Label = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NameWrapper = styled.div<{ $isRemoving?: boolean }>`
  flex: 1 1 20%;
  display: flex;
  align-items: center;
  overflow: hidden;

  ${({ $isRemoving }) =>
    $isRemoving &&
    css`
      opacity: 0.4;
    `}
`;

const TypeWrapper = styled.div<{ $isRemoving?: boolean }>`
  flex: 1 1 36%;
  display: flex;
  align-items: center;
  overflow: hidden;

  ${({ $isRemoving }) =>
    $isRemoving &&
    css`
      opacity: 0.4;
    `}
`;

const CoordinatesWrapper = styled.div<{ $isRemoving?: boolean; $isRightAlign?: boolean }>`
  flex: 1 1 36%;
  display: flex;
  align-items: center;
  overflow: hidden;

  ${({ $isRightAlign }) =>
    $isRightAlign &&
    css`
      flex-basis: calc(100% / 5.4);
    `}

  ${({ $isRemoving }) =>
    $isRemoving &&
    css`
      opacity: 0.4;
    `}
`;

const ChecklistActionLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.green};
  cursor: pointer;
`;
const ChecklistStatus = styled.div<{ $isFilled: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ $isFilled }) => ($isFilled ? '#3FB65F' : Colors.black)};
  background: ${({ $isFilled }) => ($isFilled ? '#ECF8EF' : Colors.agateGray)};
  border-radius: 8px;
  padding: 2px 8px;
`;

const OperationWrapper = styled.div`
  flex: 1 1 auto;
  width: 32px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`;

const ActionButton = styled(IconButton)<{
  $isDisabled?: boolean;
  $hoverColor?: { color?: Colors | string; fill?: Colors | string };
}>`
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: ${({ $isDisabled }) => ($isDisabled ? '0.5' : '1')};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
  background-color: transparent;
  transition: 0.3s;
  color: ${Colors.black};

  & svg > path {
    transition: 0.3s;
  }

  :hover {
    & svg > path {
      ${({ $hoverColor }) =>
        $hoverColor?.color ? `color: ${$hoverColor.color}` : `color: ${Colors.green}`};
      ${({ $hoverColor }) =>
        $hoverColor?.fill ? `fill: ${$hoverColor.fill}` : `fill: ${Colors.green}`};
    }
  }
`;

const DeleteButton = styled.div<{ $isDisabled?: boolean }>`
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: ${({ $isDisabled }) => ($isDisabled ? '0.5' : '1')};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
  background: url(${deleteSvg});
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  border-bottom: 0.5px solid #e9e6f0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 11.5px 0;
  gap: 12px;
`;

const StyledChecklistInstance = {
  PositionMark,
  PositionText,
  PositionType,
  Label,
  ChecklistActionLabel,
  ChecklistStatus,
  NameWrapper,
  TypeWrapper,
  CoordinatesWrapper,
  OperationWrapper,
  DeleteButton,
  ActionButtonWrapper,
  ActionButton,
  Wrapper,
};

export default StyledChecklistInstance;
