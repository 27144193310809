import { FC, memo } from 'react';

import { ITask } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { taskLabels } from '../../../../../stores/tasks.store';
import { useDataTestId } from '../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './TasksListStatusCell.styles';

interface IProps {
  row: ITask;
}

const TasksListStatusCell: FC<IProps> = ({ row }) => {
  const getDataTestId = useDataTestId('tasks-table-status-cell');

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <Styled.Status $status={row.status as any} {...getDataTestId('status')}>
        {taskLabels[row.status as any]}
      </Styled.Status>
    </Styled.Wrapper>
  );
};

TasksListStatusCell.displayName = 'TasksListStatusCell';

export default memo(TasksListStatusCell);
