import { FC } from 'react';

import Styled from './TasksFiltersHeaderToggleFiltersButton.styles';

interface IProps {
  isSelected: boolean;
  onClick: () => void;
}

const TasksFiltersHeaderToggleFiltersButton: FC<IProps> = ({ isSelected, onClick }) => {
  return (
    <Styled.Icon
      onClick={onClick}
      $isSelected={isSelected}
      data-test-id={'tasks-table-filters-header-toggle-filters-button'}
    />
  );
};

TasksFiltersHeaderToggleFiltersButton.displayName = 'TasksFiltersHeaderToggleFiltersButton';

export default TasksFiltersHeaderToggleFiltersButton;
