import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Button, useNotificator, useSidebar } from '@farmlink/farmik-ui';
import { useNavigate, generatePath } from 'react-router-dom';

import { Footer, Header, Label, StyledLabelWrapper, Wrapper } from '../../style';
import { ListWrapper } from '../listing/style';
import AddPointSvg from '../../static/map-add-entity-mock.svg';
import { useStore } from '../../../../../shared/utils/IoC';
import { FieldsStore } from '../../stores/fields.store';
import MapStore, { MapMode } from '../../../../../map/stores/map.store';
import { CreationFieldItem } from '../../components/CreationgFieldItem/CreationFieldItem';
import { FieldsRoute } from '../../fields.route';
import { FieldsErrors } from '../../constants/fields.errors';
import { ButtonLoader } from '../../../../../shared/components/ButtonLoader/ButtonLoader';
import { FieldsAddController } from '../../controllers/fields.add.controller';
import { FieldsController } from '../../controllers/fields.controller';
import { Colors } from '../../../../../shared/constans/colors';
import { ModalContainer } from '../../../../../modals/containers';
import { addFieldModalUniqueKey } from '../../modals/modalsConfig';
import { EModalSize } from '../../../../../modals/components/Modal/styles';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { useWarningBeforeLeavingThePage } from '../../../../../shared/hooks/useWarningBeforeLeavingThePage';

import {
  AddFirstPointInformation,
  ButtonWrapper,
  InformationDescription,
  InformationImage,
  InformationTitle,
} from './style';

export const FieldsAdd = observer(() => {
  const fieldsAddController = useStore(FieldsAddController);

  const fieldStore = useStore(FieldsStore);
  const fieldsController = useStore(FieldsController);
  const organizationsStore = useStore(OrganizationsStore);
  const { creationFields, isLoading } = fieldStore;
  const { setSidebarDisabled } = useSidebar();

  const notificator = useNotificator();

  const fieldsListingRoute = useMemo(
    () =>
      generatePath(FieldsRoute.Listing, {
        orgId: organizationsStore.selectedOrganizationId,
      }),
    [organizationsStore.selectedOrganizationId]
  );

  const {
    deleteField,
    changeName,
    saveCreatableFields,
    clearCreatableFields,
    creatableFieldsWithGeoJson,
  } = fieldsController;

  const mapStore = useStore(MapStore);
  const navigate = useNavigate();

  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage();

  useEffect(() => {
    setSidebarDisabled(true);

    return () => {
      fieldsAddController.returnToFieldsListing();
      clearCreatableFields();
      mapStore.clearPointsOfUnfinishedContour();
      setSidebarDisabled(false);
    };
  }, []);

  useEffect(() => {
    if (!mapStore.instance) {
      return;
    }

    /**
     * Установлена задержка перед вызовом, т.к. при перезагрузке роута на странице добавления полей возникает
     * рейсинг состояний полей на карте.
     * TODO: Определить последовательность смены состояний, наладить поток состояний внутри системы
     */
    setTimeout(() => {
      if (
        mapStore.mapMode === MapMode.Creating ||
        mapStore.mapMode === MapMode.Editing ||
        mapStore.mapMode === MapMode.CZEditing
      ) {
        return;
      }

      fieldsAddController.startCreatingMode();
    }, 1000);
  }, [mapStore.instance]);

  const handleSave = async () => {
    try {
      await saveCreatableFields();
      navigate(fieldsListingRoute);
    } catch (e) {
      if (e.message === FieldsErrors.AreaTobBig) {
        fieldsAddController.openOversizedAreaWarning();
      }

      if (e.message === FieldsErrors.UnfinishedContour) {
        fieldsAddController.openUnfinishedContourWarning();
      }

      if (e.message === FieldsErrors.Intersection || e.message === FieldsErrors.CrossingGeometry) {
        fieldsAddController.openIntersectionWarning();
      }
    }
  };

  const handleClose = () => {
    if (creatableFieldsWithGeoJson().length > 0) {
      showWarningBeforeLeavingThePage(() => {
        notificator.clearAll();
        navigate(fieldsListingRoute);
      });
    } else {
      notificator.clearAll();
      navigate(fieldsListingRoute);
    }
  };

  return (
    <Wrapper data-test-id={'fields-add-section'}>
      <Header>
        <StyledLabelWrapper>
          <Label data-test-id={'fields-add-section-label'}>Добавление полей</Label>
        </StyledLabelWrapper>
      </Header>
      <ListWrapper>
        {creationFields.map(i => (
          <CreationFieldItem
            key={i.id}
            area={i.areaInHectare}
            name={i.name}
            onNameChange={v => changeName(i.id, v)}
            onDelete={() => deleteField(i.id)}
            data-test-id={'fields-add-props'}
          />
        ))}
        {creationFields.length < 3 ? (
          <AddFirstPointInformation>
            {!creationFields.length && (
              <>
                <InformationImage src={AddPointSvg} />
                <InformationTitle data-test-id={'fields-add-polygon-points'}>
                  Проставьте точки контура поля на карте
                </InformationTitle>
                <InformationDescription data-test-id={'fields-add-info'}>
                  После замыкания контура можно будет добавить еще поля
                </InformationDescription>
              </>
            )}
            {Boolean(creationFields.length) && (
              <InformationDescription data-test-id={'fields-add-info'} $color={Colors.darkGray}>
                Продолжайте рисовать новые поля в нужном количестве
              </InformationDescription>
            )}
          </AddFirstPointInformation>
        ) : null}
      </ListWrapper>
      <Footer>
        <ButtonWrapper>
          <Button
            type={'button'}
            color={'default'}
            onClick={handleClose}
            dataTestId={'fields-add-cancel'}
          >
            Отменить
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <ButtonLoader
            isLoading={isLoading}
            onClick={handleSave}
            disabled={creationFields.length === 0}
            data-test-id={'fields-add-save'}
          >
            Сохранить
          </ButtonLoader>
        </ButtonWrapper>
      </Footer>
      <ModalContainer uniqueKey={addFieldModalUniqueKey} $size={EModalSize.Medium} />
    </Wrapper>
  );
});
