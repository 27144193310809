import { FC } from 'react';

import Styled from './ChecklistsFormulaIcon.styles';

const ChecklistsFormulaIcon: FC = () => {
  return <Styled.Wrapper />;
};

ChecklistsFormulaIcon.displayName = 'ChecklistsFormulaIcon';

export default ChecklistsFormulaIcon;
