import styled from 'styled-components';

const TaskTitleWrapper = styled.span`
  & > span {
    margin-right: 12px;
  }
  margin-right: 12px;
`;

const TaskTitlePaddingWrapper = styled.div`
  padding-left: 22px;
`;

const Styled = { TaskTitleWrapper, TaskTitlePaddingWrapper };

export default Styled;
