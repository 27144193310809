import styled from 'styled-components';

import { Colors } from '../../../shared/constans/colors';

export const Wrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  min-width: 186px;
  flex-direction: row;
  z-index: 999;
`;

export const ContinueButton = styled.div<{ disabled?: boolean }>`
  padding: 14px 20px;
  text-align: center;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background: ${Colors.green};

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: ${Colors.white};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const CancelButton = styled.div`
  width: 48px;
  height: 48px;
  background: ${Colors.white};
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 12px;
  svg {
    width: 10px;
    height: 10px;
    fill: ${Colors.red};
  }
`;
