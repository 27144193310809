import {
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  NotificatorProps,
} from '@farmlink/farmik-ui';

export enum ENotificationScheme {
  Dark = 'dark',
  Light = 'light',
}

interface IOptions {
  type?: ENotificationType;
  autoClose?: number | false;
  hideProgressBar?: boolean;
}

const getNotificationScheme = (
  schemeType: ENotificationScheme,
  message: string,
  options?: IOptions
): NotificatorProps => {
  switch (schemeType) {
    case ENotificationScheme.Dark:
    default:
      return {
        message,
        style: {
          type: options?.type ?? ENotificationType.Success,
          height: ENotificationHeight.BIG,
          placement: 'top-center',
          autoClose: options?.autoClose ?? 10000,
          hideProgressBar: Boolean(options?.hideProgressBar),
          theme: ENotificatorTheme.Dark,
        },
      };

    case ENotificationScheme.Light:
      return {
        message,
        style: {
          type: options?.type ?? ENotificationType.Info,
          height: ENotificationHeight.BIG,
          hideProgressBar: options?.hideProgressBar ?? true,
          autoClose: options?.autoClose ?? 10000000,
          placement: 'top-right',
          theme: ENotificatorTheme.Light,
        },
      };
  }
};

export default getNotificationScheme;
