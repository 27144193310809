import L, { DivIcon, marker } from 'leaflet';
import { point as getTurfPoint, booleanPointInPolygon as checkIfPointInPolygon } from '@turf/turf';
import { cloneDeep } from 'lodash';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { MapStore } from '../../stores';
import {
  IMapPolygonElement as IPolygonElement,
  IMapPolygonElementConfig as IPolygonElementConfig,
  IMapGlobalConfig as IGlobalConfig,
  IMapPolygon as IPolygon,
  IMapPolygonTooltip as IPolygonTooltip,
  IMapMarker as IMarker,
  IMapMarkerElementConfig as IMarkerElementConfig,
  IMapCreateMarkersOptions as ICreateMarkersOptions,
  IMapSetListOptions as ISetListOptions,
  IMapMarkerElement as IMarkerElement,
  IMapMarkerElementConfig,
} from '../../../models';
import { MAP_DEFAULT_SELECTED_POLYGON_STYLE as DEFAULT_SELECTED_POLYGON_STYLE } from '../../../utils/constants';
import { MapAdditionsService } from '../../services';

@provide.singleton()
class MapController {
  @lazyInject(MapStore)
  protected mapStore: MapStore;

  @lazyInject(MapAdditionsService)
  protected mapAdditions: MapAdditionsService;

  onSelectPolygon = (event: L.LeafletMouseEvent): void => {
    const polyId: number = event.target._leaflet_id;

    this.selectPolygon(polyId);
  };

  onCancelSelectedPolygon = (event: L.LeafletMouseEvent): void => {
    const { selectedPolygon, clearSelectedPolygon } = this.mapStore;

    if (!selectedPolygon) {
      return;
    }

    const point = getTurfPoint([event.latlng.lng, event.latlng.lat]);
    const feature = selectedPolygon.leafletPolygon.toGeoJSON();

    const isPointInPolygon = checkIfPointInPolygon(point, feature);

    if (!isPointInPolygon) {
      this.restoreStyleToPrevSelectedPolygon();
      clearSelectedPolygon();
    }
  };

  onCreateMarker = (event: L.LeafletMouseEvent): void => {
    const { createMarkersOptions, setMarker, globalConfig } = this.mapStore;

    if (globalConfig?.createMarkersOptions?.isAllowedToCreateMarkers === false) {
      return;
    }

    const handleCreateMarker = (): void => {
      const markerOptions = createMarkersOptions?.getMarkerOptions?.();

      const newMarker = this.createMarker([event.latlng.lat, event.latlng.lng], markerOptions);

      if (newMarker) {
        createMarkersOptions?.createMarker?.(newMarker);
        setMarker(newMarker);
      }
    };

    if (createMarkersOptions?.bounds) {
      const point = getTurfPoint([event.latlng.lng, event.latlng.lat]);
      const feature = createMarkersOptions.bounds.leafletPolygon.toGeoJSON();

      const isPointInBounds = checkIfPointInPolygon(point, feature);

      if (isPointInBounds) {
        handleCreateMarker();
      }
    } else {
      handleCreateMarker();
    }
  };

  initInstance = (globalConfig: IGlobalConfig): L.Map => {
    const { setGlobalConfig, setInstance, setCreateMarkersOptions } = this.mapStore;

    const { mapKey, createMarkersOptions } = globalConfig;

    const instance = L.map(mapKey, {
      center: [55.751244, 37.618423],
      zoom: 19,
      zoomControl: false,
      minZoom: 3,
      maxZoom: 20,
    });

    if (!instance) {
      return;
    }

    instance.on('click', this.onCancelSelectedPolygon);

    if (createMarkersOptions?.isAllowedToCreateMarkers) {
      instance.on('click', this.onCreateMarker);
    } else {
      instance.off('click', this.onCreateMarker);
    }

    if (globalConfig?.createMarkersOptions) {
      setCreateMarkersOptions(globalConfig.createMarkersOptions);
    }

    setGlobalConfig(globalConfig);
    setInstance(instance);

    return instance;
  };

  restoreStyleToPrevSelectedPolygon = (): void => {
    const { selectedPolygon } = this.mapStore;

    if (!selectedPolygon) {
      return;
    }

    const { leafletPolygon, prevStyle } = selectedPolygon;

    leafletPolygon.setStyle(prevStyle);
  };

  selectPolygon = (polyId: number): void => {
    const { selectedPolygon, getPolygon, setSelectedPolygon } = this.mapStore;

    const polygon = getPolygon(polyId);

    if (!polygon) {
      return;
    }

    if (polyId !== selectedPolygon?.id) {
      /**
       * При клике на другой полигон, когда есть уже выбранный, то приводим к "дефолтному
       * состоянию" предыдущий. В противном случае он также останется "выбранным".
       */
      this.restoreStyleToPrevSelectedPolygon();

      setSelectedPolygon({
        ...polygon,
        prevStyle: cloneDeep(polygon.leafletPolygon.options),
      });

      polygon.leafletPolygon.setStyle(DEFAULT_SELECTED_POLYGON_STYLE);
    }

    this.centerMapOnPoint(polyId);
  };

  createPolygon = (
    coordinates: IPolygonElementConfig['coordinates'],
    options?: IPolygonElementConfig['options']
  ): IPolygon => {
    const { instance } = this.mapStore;

    if (!instance) {
      return;
    }

    if (!coordinates) {
      return;
    }

    const points = coordinates[0][0][0]
      ? coordinates.map(feature => feature.map(dots => [dots[1], dots[0]]))
      : [coordinates.map(dots => [dots[1], dots[0]])];

    const polygon = new L.Polygon(points);

    if (options?.isAllowToSelectPolygon) {
      polygon.on('click', this.onSelectPolygon);
    }

    if (options?.style) {
      polygon.setStyle(options.style);
    }

    if (options?.tooltip) {
      polygon.bindTooltip(options.tooltip.content, options?.tooltip?.options).openTooltip();
    }

    polygon.addTo(instance);

    return {
      // @ts-ignore
      id: polygon._leaflet_id,
      leafletPolygon: polygon,
    };
  };

  createMarker = (
    coordinates: IMarkerElementConfig['coordinates'],
    options?: IMarkerElementConfig['options']
  ): IMarker => {
    const { instance } = this.mapStore;

    if (!instance) {
      return null;
    }

    const icon = new DivIcon(options?.style ?? {});

    try {
      const createdMarker = marker(coordinates, { pmIgnore: true }).setIcon(icon).addTo(instance);

      if (createdMarker) {
        const extendedMarker = this.extendsMarkerOptions(createdMarker, options);

        return {
          // @ts-ignore
          id: extendedMarker._leaflet_id,
          leafletMarker: extendedMarker,
        };
      }
    } catch (error) {
      console.error('Ошибка создания точки', error);
    }
  };

  extendsMarkerOptions = (_marker: L.Marker<any>, options?: IMarkerElementConfig['options']) => {
    if (options?.tooltip) {
      if (options?.tooltip?.isDisplayCoordinates) {
        // @ts-ignore
        const { lat, lng } = _marker._latlng;

        _marker.bindTooltip(`${lng.toFixed(7)} ${lat.toFixed(7)}`, {
          direction: 'bottom',
          className: 'inspection-point_tooltip',
          offset: [50, 16],
          opacity: 1,
        });
      }

      return _marker;
    }
  };

  changePolygonStyle = (polyId: number, style: L.PathOptions): void => {
    const { selectedPolygon, getPolygon, setSelectedPolygon } = this.mapStore;

    const polygon = getPolygon(polyId);

    if (!polygon) {
      return;
    }

    if (polyId === selectedPolygon?.id) {
      setSelectedPolygon({ ...selectedPolygon, prevStyle: style });

      return;
    }

    polygon.leafletPolygon.setStyle(style);
  };

  changeMarkerStyle = (id: number, style: IMapMarkerElementConfig['options']['style']): void => {
    const { getMarker } = this.mapStore;

    const markerToChange = getMarker(id);

    if (!markerToChange) {
      return;
    }

    const newIcon = new DivIcon({ ...markerToChange.leafletMarker.getIcon().options, ...style });

    markerToChange.leafletMarker.setIcon(newIcon);
  };

  changePolygonTooltip = (polyId: number, tooltip: IPolygonTooltip): void => {
    const { getPolygon } = this.mapStore;

    const polygon = getPolygon(polyId);

    if (!polygon) {
      return;
    }

    polygon.leafletPolygon.unbindTooltip();
    polygon.leafletPolygon.bindTooltip(tooltip.content, tooltip.options);
  };

  centerMapOnPoint = (polyId: number, options?: L.FitBoundsOptions): void => {
    const { instance, getPolygon } = this.mapStore;

    const polygon = getPolygon(polyId);

    if (!polygon) {
      return;
    }

    const polygonBounds = polygon.leafletPolygon.getBounds();

    instance.fitBounds(polygonBounds, options);
  };

  removePolygonsFromInstance = (): void => {
    const { polygonList } = this.mapStore;

    polygonList.forEach(({ leafletPolygon }) => {
      leafletPolygon.unbindTooltip();
      leafletPolygon.remove();
    });
  };

  removeMarkersFromInstance = (): void => {
    const { markerList } = this.mapStore;

    markerList.forEach(({ leafletMarker }) => {
      leafletMarker.remove();
    });
  };

  removeInstance = (): void => {
    const { instance } = this.mapStore;

    instance.off();
    instance.remove();
  };

  clearMapStore = (): void => {
    const { clearStore } = this.mapStore;

    this.removePolygonsFromInstance();
    this.removeMarkersFromInstance();
    this.removeInstance();

    clearStore();
  };

  displayPolygon = <E>({
    element,
    coordinates,
    options,
  }: IPolygonElementConfig<E>): IPolygonElement<E> => {
    const { setPolygon } = this.mapStore;

    const polygon = this.createPolygon(coordinates, options);

    if (polygon) {
      setPolygon(polygon);

      return {
        element,
        polygon,
      };
    }
  };

  displayPolygonList = <E>(
    configList: IPolygonElementConfig<E>[],
    setOptions?: ISetListOptions
  ): IPolygonElement<E>[] => {
    const { setPolygonList } = this.mapStore;

    if (setOptions?.isClearPreviousList) {
      this.removeMarkersFromInstance();
    }

    const elementList: IPolygonElement[] = [];
    const polygonList: IPolygon[] = [];

    configList.forEach(({ element, coordinates, options }) => {
      const polygon = this.createPolygon(coordinates, options);

      if (polygon) {
        polygonList.push(polygon);

        elementList.push({ element, polygon });
      }
    });

    setPolygonList(polygonList, setOptions);

    return elementList;
  };

  displayMarkerList = <E>(
    configList: IMarkerElementConfig<E>[],
    setOptions?: ISetListOptions
  ): IMarkerElement<E>[] => {
    const { setMarkerList } = this.mapStore;

    if (setOptions?.isClearPreviousList) {
      this.removeMarkersFromInstance();
    }

    const elementList: IMarkerElement[] = [];
    const markerList: IMarker[] = [];

    configList.forEach(({ element, coordinates, options }) => {
      if (coordinates) {
        try {
          const createdMarker = this.createMarker(coordinates, options);

          if (createdMarker) {
            markerList.push(createdMarker);

            elementList.push({ element, marker: createdMarker });
          }
        } catch (error) {
          console.error('Ошибка создания точки на карте', error);
        }
      } else {
        elementList.push({ element, marker: null });
      }
    });

    setMarkerList(markerList, setOptions);

    return elementList;
  };

  addCreateMarkersOptions = (options: Partial<ICreateMarkersOptions>): void => {
    const { instance, setCreateMarkersOptions } = this.mapStore;

    if (options?.isAllowedToCreateMarkers) {
      instance?.on('click', this.onCreateMarker);
    } else {
      instance?.off('click', this.onCreateMarker);
    }

    setCreateMarkersOptions(options);
  };

  removePolygon = (id: number): void => {
    const { getPolygon, deletePolygon } = this.mapStore;

    const polygonToDelete = getPolygon(id);

    if (polygonToDelete) {
      polygonToDelete.leafletPolygon.remove();

      deletePolygon(id);
    }
  };

  zoomIn = () => {
    const { instance } = this.mapStore;

    instance?.zoomIn();
  };

  zoomOut = () => {
    const { instance } = this.mapStore;

    instance?.zoomOut();
  };

  removePolygonList = (
    idList: number[],
    options?: {
      isRemoveAllPolygons?: boolean;
    }
  ): void => {
    const { polygonList } = this.mapStore;

    if (options?.isRemoveAllPolygons) {
      polygonList.forEach(({ id }) => this.removePolygon(id));

      return;
    }

    idList.forEach(id => this.removePolygon(id));
  };

  removeMarker = (id: number): void => {
    const { getMarker, deleteMarker } = this.mapStore;

    const markerToDelete = getMarker(id);

    if (markerToDelete) {
      markerToDelete.leafletMarker.remove();

      deleteMarker(id);
    }
  };

  removeMarkerList = (
    idList: number[],
    options?: {
      isRemoveAllMarkers?: boolean;
    }
  ): void => {
    const { markerList } = this.mapStore;

    if (options?.isRemoveAllMarkers) {
      markerList.forEach(({ id }) => this.removeMarker(id));

      return;
    }

    idList.forEach(id => this.removeMarker(id));
  };
}

export default MapController;
