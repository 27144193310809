import { useCallback } from 'react';
import { useModal } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { InspectionPointsStore } from '../../mobx/stores';
import { useMapActions } from '../../../../../../../shared/features/map/hooks';
import { IMapMarker, IMapMarkerElement } from '../../../../../../../shared/features/map/models';
import { CultureZone, Field } from '../../../../../../../../api/models/field.model';
import { InspectionPointsElementHelpers as ElementHelpers } from '../../utils/helpers';
import {
  ECheckListInstanceType,
  IDrawChecklistInstance,
} from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { InspectionPointsController } from '../../mobx/controllers';
import { TInspectionPointsZoneElement as TZoneElement } from '../../models';
import { EDIT_TASK_MAP_POINT_MODAL_ID } from '../../../../modals/editTaskMapPointModal/editTaskMapPointModal';
import { useChecklistInstanceActions } from '../../../../../operationsAndTasks/components/instance/ListOfChecklistInstance/components/ChecklistInstance/hooks';
import { ChecklistInstancesStore } from '../../../../../operationsAndTasks/stores/checklist.instances.store';
import { NO_CHECKLIST_FOUND_MODAL_ID } from '../../../../modals/noChecklistFoundModal';

const {
  createZoneConfig,
  createPointConfigList,
  getPointElementListToChangeStyle,
} = ElementHelpers;

// Отображает КЗ на карте.
type TDisplayZone = (
  zone: CultureZone | Field,
  options?: {
    isAllowedToAddPoints?: boolean;
  }
) => TZoneElement;

// Отображает точки на карте.
type TDisplayPointList = (
  pointList: IDrawChecklistInstance[],
  options?: {
    isCleanDisplay?: boolean;
    isReorder?: boolean;
  }
) => void;

type TCreateMarkerHandler = (
  marker: IMapMarker,
  instanceTypeListToCreate?: ECheckListInstanceType[]
) => IMapMarkerElement | false;

const useInspectionPointsActionsHook = (): {
  displayZone: TDisplayZone;
  displayPointList: TDisplayPointList;
  cleanMap;
  createMarkerHandler;
} => {
  const { pointElementList, setZoneElement, setPointElementList } = useStore(InspectionPointsStore);

  const { changePointElementList, removePointElementList, createMarker } = useStore(
    InspectionPointsController
  );

  const { delDrawInst, setDrawInst } = useStore(ChecklistInstancesStore);

  const {
    centerMapOnElement,
    displayPolygonList,
    displayMarkerList,
    removePolygonList,
    removeMarkerList,
    changeMarkerStyle,
    addCreateMarkersOptions,
  } = useMapActions();

  const { updateDrawInst } = useChecklistInstanceActions();

  const { openModalByModalId, closeModal } = useModal();

  const displayZone = useCallback<TDisplayZone>((zone, options) => {
    if (!zone) {
      removePolygonList([], { isRemoveAllPolygons: true });
      removeMarkerList([], { isRemoveAllMarkers: true });

      addCreateMarkersOptions({ bounds: null, isAllowedToCreateMarkers: false });

      return;
    }

    // Процесс отрисовки КЗ на карте.

    removeMarkerList([], { isRemoveAllMarkers: true });

    const [element] = displayPolygonList(
      [createZoneConfig(zone, { isAllowedToCreateMarkers: options?.isAllowedToAddPoints })],
      {
        isClearPreviousList: true,
      }
    );

    if (element) {
      addCreateMarkersOptions({
        bounds: element.polygon,
        isAllowedToCreateMarkers: options?.isAllowedToAddPoints,
      });

      centerMapOnElement(element.polygon.id, {
        paddingBottomRight: [64, 64],
        paddingTopLeft: [64, 8],
      });

      setZoneElement(element);
    }

    return element;
  }, []);

  const displayPointList = useCallback<TDisplayPointList>(
    (_pointList, options) => {
      const pointList = _pointList;

      // Если это первая отрисовка элементов, то не делаем никаких проверок, а просто рисуем точки.
      if (options?.isCleanDisplay || !pointElementList.length) {
        const markerElementList = displayMarkerList(createPointConfigList(pointList), {
          isClearPreviousList: true,
        });

        setPointElementList(markerElementList);
      } else {
        // После изменения исходного массива с точками, проверяем, какие элементы были удалены или изменены.

        // Проверяем на удаление.
        const pointListToDelete = pointElementList.filter(
          ({ element }) => !pointList.some(point => point.id === element.id)
        );

        if (pointListToDelete.length) {
          removeMarkerList(pointListToDelete.map(({ marker }) => marker.id));
          removePointElementList(pointListToDelete.map(({ element }) => element.id));
        }

        // Проверяем на изменение.
        const pointListToChangeStyle = getPointElementListToChangeStyle(
          pointElementList,
          pointList
        );

        if (pointListToChangeStyle) {
          pointListToChangeStyle.forEach(({ marker, element }) => {
            changeMarkerStyle(marker.id, {
              html: String(element.positionNumber),
            });
          });

          changePointElementList(pointListToChangeStyle);
        }
      }
    },
    [pointElementList]
  );

  const createMarkerHandler: TCreateMarkerHandler = useCallback(
    (marker: IMapMarker, instanceTypeListToCreate) => {
      const instance = createMarker(marker) as IMapMarkerElement<IDrawChecklistInstance>;

      if (!instanceTypeListToCreate?.length) {
        openModalByModalId(NO_CHECKLIST_FOUND_MODAL_ID, {}, () => {
          delDrawInst(instance.element.id);
          removePointElementList([instance.element.id]);
        });

        return false;
      }

      if (instanceTypeListToCreate?.length > 1) {
        const { positionNumber, instance: innerInstance } = instance.element;

        openModalByModalId(
          EDIT_TASK_MAP_POINT_MODAL_ID,
          {
            instanceTypeListToCreate,
            isNewPoint: true,
            coordinates: innerInstance.planCoords.geometry.coordinates,
            positionNumber,
          },
          data => {
            updateDrawInst(instance.element, data);

            closeModal();
          },
          () => {
            delDrawInst(instance.element.id);
            removePointElementList([instance.element.id]);
          }
        );
      } else if (typeof instance === 'object' && instanceTypeListToCreate?.length) {
        const [availableType] = instanceTypeListToCreate;

        if (availableType === ECheckListInstanceType.Machinery) {
          instance.element.isMachinery = true;
        } else if (availableType === ECheckListInstanceType.Field) {
          instance.element.isField = true;
        }

        instance.element.instance.type = availableType;

        setDrawInst(instance.element);

        return instance;
      } else {
        delDrawInst(instance.element.id);
        removePointElementList([instance.element.id]);
        return false;
      }
    },
    []
  );

  const cleanMap = () => {
    removePolygonList([], { isRemoveAllPolygons: true });
    removeMarkerList([], { isRemoveAllMarkers: true });
  };

  return {
    displayZone,
    displayPointList,
    cleanMap,
    createMarkerHandler,
  };
};

export default useInspectionPointsActionsHook;
