import { FC, PropsWithChildren } from 'react';

import { ITask } from '../../../../../../../../api/models/as-fields/task/task.model';

import Styled from './TaskStatusBlock.styles';

interface IProps {
  taskForView: ITask;
}

const TaskStatusBlock: FC<PropsWithChildren<IProps>> = ({ taskForView, children }) => {
  return (
    <Styled.Wrapper $daysLeft={taskForView.daysLeft} $taskStatus={taskForView.status}>
      {children}
    </Styled.Wrapper>
  );
};

TaskStatusBlock.displayName = 'TaskStatusBlock';

export default TaskStatusBlock;
