import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../utils/axios2';

@provide.singleton()
class ReferencesService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  getReferenceList = async (
    payload: TypeApiRequest<'getReferenceList'>
  ): Promise<TypeApiResponse<'getReferenceList'>> => {
    try {
      const response = await this.axiosService.api.getReferenceList(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}

export default ReferencesService;
