import styled, { css } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

import { BorderRadius } from '../../../../../shared/constans/sizes';

export const SliderWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  position: relative;
`;

export const SliderContainer = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 12px;
  margin-bottom: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

export const ArrowButton = styled.div`
  background: ${Colors.secondaryGray};
  border-radius: ${BorderRadius.small};
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg > path {
    fill: ${Colors.secondaryDarkGray};
    transition: fill 0.3s ease-in-out;
  }

  :hover {
    & svg > path {
      fill: ${Colors.black};
    }
  }
`;

export const ArrowButtonBgGradient = styled.div<{ $position: 'left' | 'right' }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  position: relative;
  transition: opacity 0.3s ease-in-out;

  ::after {
    content: '';
    height: 60px;
    width: 16px;
    z-index: 1;

    position: absolute;
  }

  ${({ $position }) =>
    $position === 'left'
      ? css`
          justify-content: flex-start;

          ::after {
            right: -16px;
            background: linear-gradient(90deg, #ffffff 63.54%, rgba(255, 255, 255, 0.64) 100%);
          }
        `
      : css`
          justify-content: flex-end;

          ::after {
            left: -16px;
            background: linear-gradient(270deg, #ffffff 63.54%, rgba(255, 255, 255, 0) 100%);
          }
        `};
`;
