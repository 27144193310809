import { ModalFooter } from '@farmlink/farmik-ui';
import { TModalButton } from '@farmlink/farmik-ui/dist/feature/Modal/components/Modal/Modal';
import { FC, memo, useMemo } from 'react';

import Styled from './FieldsImportSuccessModal.styles';
import { TFieldsImportSuccessModalProps } from './FieldsImportSuccessModal.types';

const FieldsImportSuccessModal: FC<TFieldsImportSuccessModalProps> = ({
  addedFieldsCount,
  continueClick,
}) => {
  const successButton = useMemo<TModalButton>(() => {
    return {
      title: 'Продолжить',
      handler: continueClick,
    };
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.IconWrapper>
        <Styled.Icon />
      </Styled.IconWrapper>

      <Styled.Title>
        {`Успешно загруженных полей: ${addedFieldsCount}.
          Теперь нужно проверить их границы, названия и убедиться, что никакие из полей не пересекаются.`}
      </Styled.Title>

      <ModalFooter successButton={successButton} />
    </Styled.Wrapper>
  );
};

FieldsImportSuccessModal.displayName = 'FieldsImportSuccessModal';

export default memo(FieldsImportSuccessModal);
