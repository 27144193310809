import { FC } from 'react';

import { Action, ActionsWrapper, Description, Tail, Wrapper } from './style';

type CreateZoneToolTipProps = {
  acceptAction: any;
  denyAction: any;
};

export const CreateZoneToolTip: FC<CreateZoneToolTipProps> = ({ acceptAction, denyAction }) => {
  return (
    <Wrapper>
      <Description>Добавить культуру на этот участок?</Description>
      <ActionsWrapper>
        <Action colorScheme={'deny'} onClick={denyAction}>
          <p>Нет</p>
        </Action>
        <Action colorScheme={'accent'} onClick={acceptAction}>
          <p>Да</p>
        </Action>
      </ActionsWrapper>
      <Tail />
    </Wrapper>
  );
};
