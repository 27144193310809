import { ModalFooter } from '@farmlink/farmik-ui';
import { TModalButton } from '@farmlink/farmik-ui/dist/feature/Modal/components/Modal/Modal';
import { FC, memo, useMemo } from 'react';

import Styled from './FieldsImportFileImportModal.styles';
import { TFieldsImportFileImportModalProps } from './FieldsImportFileImportModal.types';

const FieldsImportFileImportModal: FC<TFieldsImportFileImportModalProps> = ({
  onFileUploaderClick,
  closeModal,
}) => {
  const successButton = useMemo<TModalButton>(() => {
    return {
      title: 'Выбрать файл',
      handler: onFileUploaderClick,
    };
  }, []);

  const denyButton = useMemo<TModalButton>(() => {
    return {
      title: 'Отменить',
      handler: () => closeModal(),
    };
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.Title>Загрузка контуров поля</Styled.Title>

      <Styled.Description>
        Вы можете загрузить до 500 полей в форматах kml, geoJSON и shape. Максимальный размер файла:
        50 МБ
      </Styled.Description>

      <ModalFooter successButton={successButton} denyButton={denyButton} />
    </Styled.Wrapper>
  );
};

FieldsImportFileImportModal.displayName = 'FieldsImportFileImportModal';

export default memo(FieldsImportFileImportModal);
