import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const List = styled.ul`
  padding-inline-start: 0;
  list-style: none;
  margin: 0;
  max-width: 340px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Item = styled.li`
  display: flex;
  gap: 16px;
  width: 100%;

  & > p,
  & > span {
    line-height: 20px;
  }
`;

const Content = styled.p`
  color: ${Colors.generalWhite};
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
  min-width: 0;
  padding: 0;
  margin: 0;
`;

const Date = styled.span`
  color: ${Colors.generalWhite};
`;

const Styled = {
  List,
  Item,
  Date,
  Content,
};

export default Styled;
