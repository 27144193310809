import { IGetChecklistAttributeUserDictionary } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

const LIST_OF_ADDED_USER_DICT_LINK_VALUE_KEY = 'listOfChecklistUserDictLinkValue';

interface IListOfAddedUserDictLinkValue {
  listOfChecklistUserDictLinkValue: {
    [attrId: string]: IGetChecklistAttributeUserDictionary[];
  };
}

export { LIST_OF_ADDED_USER_DICT_LINK_VALUE_KEY };

export type { IListOfAddedUserDictLinkValue };
