import { useStore } from '../../../../../shared/utils/IoC';
import { CheckAccessStore } from '../../../../../authorization/stores/checkAccess.store';
import { TaskStore } from '../../mobx/stores';
import { useTasksParams } from '../index';
import { ProfileStore } from '../../../profile/stores/ProfileStore';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../shared/constants/access-rules-action';

interface Actions {
  isAllowTaskEditing: boolean;
}

const useTaskAccessRules = (): Actions => {
  const { user } = useStore(ProfileStore);
  const { getAccessRuleValue } = useStore(CheckAccessStore);
  const { selectedTask } = useStore(TaskStore);

  const { orgId } = useTasksParams();

  const isAllowTaskEditing = (): boolean => {
    const isMyOrganization = orgId === 'my';
    const isAllowToManageTasks = getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS);
    const isAllowWorkWithTasks = getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS);
    const isTheSameUser = selectedTask?.assignee?.id === user?.id;

    return isMyOrganization || isAllowToManageTasks || (isAllowWorkWithTasks && isTheSameUser);
  };

  return {
    isAllowTaskEditing: isAllowTaskEditing(),
  };
};

export default useTaskAccessRules;
