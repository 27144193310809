import {
  ECheckListInstanceType,
  IGetChecklistInstanceByTaskId,
} from '../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';

const getInstanceType = (inst: IGetChecklistInstanceByTaskId) => {
  if (inst.type === ECheckListInstanceType.PlannedPoint) {
    return 'План';
  }

  if (inst.type === ECheckListInstanceType.ActualPoint) {
    return 'Факт';
  }

  if (inst.type === ECheckListInstanceType.Machinery) {
    return inst?.machineryName;
  }
};

export default getInstanceType;
