import { Widget } from '../../../Widgets';

const HistoryContainer = () => {
  return (
    <Widget displayTitle title={'История'} dataTestId="new-task-general-data-title">
      HISTORY
    </Widget>
  );
};

export default HistoryContainer;
