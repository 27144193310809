export enum EBiImportFormat {
  Default = 'Default',
  Excel = 'Excel',
  InformixUnload = 'InformixUnload',
  InformixUnloadCsv = 'InformixUnloadCsv',
  MongoDBCsv = 'MongoDBCsv',
  MongoDBTsv = 'MongoDBTsv',
  MySQL = 'MySQL',
  Oracle = 'Oracle',
  PostgreSQLCsv = 'PostgreSQLCsv',
  PostgreSQLText = 'PostgreSQLText',
  RFC4180 = 'RFC4180',
  TDF = 'TDF',
}

export enum EBiImportDelimiterType {
  Comma = 'COMMA',
  Tab = 'TAB',
  Semicolon = 'SEMICOLON',
}
