import styled from 'styled-components';

const DefaultPlug = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 16px;

  background-color: white;
`;

const StyledTableBuilder = {
  DefaultPlug,
};

export default StyledTableBuilder;
