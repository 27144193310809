import { observer } from 'mobx-react';
import { FC, memo, useMemo } from 'react';

import { IDrawChecklistInstance } from '../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { ICustomColor } from '../../../../../../shared/constans/customColorsPrests';

import { ChecklistInstance } from './components';
import Styled from './ListOfChecklistInstance.styles';
import { IChecklistInstanceProps } from './components/ChecklistInstance/ChecklistInstance';
import { TListOfChecklistInstanceProps } from './ListOfChecklistInstance.types';

export interface IInstWithColor extends IDrawChecklistInstance {
  colors: ICustomColor;
}

const ListOfChecklistInstance: FC<TListOfChecklistInstanceProps> = ({
  title,
  description,
  instList,
  addButton,
  mode,
  isChangesCanBeUndone,
  variant,
  isHideHeader,
}) => {
  const hasData = Boolean(instList.length);

  const NoData: JSX.Element | null = useMemo(() => {
    return (
      <Styled.NoDataWrapper $isFromFullscreen={mode === 'fullscreen'}>
        <Styled.Description>{description}</Styled.Description>
      </Styled.NoDataWrapper>
    );
  }, [instList, description]);

  const instancePropsByVariant = useMemo<Partial<IChecklistInstanceProps>>(() => {
    if (variant === 'drawer') {
      return {
        isActionsHide: true,
        isCoordinatesHide: true,
        isDisplayTime: true,
        isShort: true,
      };
    }

    return {};
  }, [variant]);

  return (
    <Styled.Wrapper>
      {!isHideHeader ? (
        <Styled.Header $hasData={hasData && variant !== 'fullscreen'}>
          {title ? <Styled.Title>{title}</Styled.Title> : null}
        </Styled.Header>
      ) : null}

      <Styled.Body $length={instList.length}>
        {hasData
          ? instList.map(instance => (
              <ChecklistInstance
                key={instance.id}
                extendedInstanceByColor={instance}
                isChangesCanBeUndone={isChangesCanBeUndone}
                {...instancePropsByVariant}
              />
            ))
          : NoData}
      </Styled.Body>

      {addButton ? (
        <Styled.Footer $hasData={!hasData}>
          <Styled.AddButton type={'button'} onClick={addButton.onClick}>
            {addButton.title}
          </Styled.AddButton>
        </Styled.Footer>
      ) : null}
    </Styled.Wrapper>
  );
};

ListOfChecklistInstance.displayName = 'ListOfChecklistInstance';

export default memo(observer(ListOfChecklistInstance));
