const ReturnIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5294 12.8525C13.0268 11.7615 14 9.99435 14 7.99988C14 4.68615 11.3137 1.99987 8 1.99987H7.64706M8 13.9999C4.68629 13.9999 2 11.3136 2 7.99988C2 6.00537 2.97318 4.23817 4.47059 3.1472M7.29412 15.3411L8.70588 13.9293L7.29412 12.5175M8.70588 3.48222L7.29412 2.07046L8.70588 0.658691"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReturnIcon;
