import { ModalFooter } from '@farmlink/farmik-ui';
import { TModalButton } from '@farmlink/farmik-ui/dist/feature/Modal/components/Modal/Modal';
import { FC, memo, useMemo } from 'react';

import Styled from './FieldsImportSomethingWentWrongModal.styles';
import { TFieldsImportSomethingWentWrongModalProps } from './FieldsImportSomethingWentWrongModal.types';

const FieldsImportSomethingWentWrongModal: FC<TFieldsImportSomethingWentWrongModalProps> = ({
  closeModal,
}) => {
  const successButton = useMemo<TModalButton>(() => {
    return {
      title: 'Понятно',
      color: 'primary',
      handler: closeModal,
    };
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.IconWrapper>
        <Styled.Icon />
      </Styled.IconWrapper>

      <Styled.Title>
        Произошла ошибка системы.
        <br /> Пожалуйста, попробуйте ещё раз
      </Styled.Title>

      <ModalFooter successButton={successButton} />
    </Styled.Wrapper>
  );
};

FieldsImportSomethingWentWrongModal.displayName = 'FieldsImportSomethingWentWrongModal';

export default memo(FieldsImportSomethingWentWrongModal);
