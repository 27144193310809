import styled from 'styled-components';

import plugIcon from './assets/icons/plug.svg';

const Icon = styled.div<{ $url?: string }>`
  width: 36px;
  height: 36px;

  flex: 0 0 36px;

  background: url(${({ $url }) => $url || plugIcon});
`;

const Name = styled.span`
  flex: 1 1 auto;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: #151f32;
`;

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  overflow: hidden;

  gap: 16px;
`;

const StyledTasksListOperationCell = {
  Icon,
  Name,
  Wrapper,
};

export default StyledTasksListOperationCell;
