import { IGetChecklist } from '../../models/checklist/checklist.model';
import { TypeApiRoute } from '../../models/type.api.request';

export type IGetChecklistListPayload = {
  name?: string;
  operationTypeId?: string;
  cultureId?: string;
  cultureIntensityId?: string;
  organizationId: string;
};

type TypeRequest = {} & IGetChecklistListPayload;

type TypeResponse = { content: IGetChecklist[] };

export const getChecklistList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/as-fields/checklist/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
