import { FC } from 'react';

import { ConfigurableSlider } from '../../../../../../../operations/components/ConfigurableSlider';

import Styled from './CardList.styles';

const CardList: FC = ({ children }) => {
  return (
    <Styled.Wrapper>
      <ConfigurableSlider>{children}</ConfigurableSlider>
    </Styled.Wrapper>
  );
};

export default CardList;
