import styled from 'styled-components';

import formulaIcon from './assets/icons/formula.png';

const Wrapper = styled.div`
  height: 16px;
  width: 16px;

  background: url(${formulaIcon}) center / 16px 16px;
`;

const StyledChecklistsFormulaIcon = {
  Wrapper,
};

export default StyledChecklistsFormulaIcon;
