import styled from 'styled-components';

import { Colors } from '../../constans/colors';

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${Colors.darkGray};
  cursor: pointer;

  :hover {
    cursor: pointer;
  }
`;

const ArrowIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const StyledMoveTop = {
  Button,
  ArrowIcon,
};

export default StyledMoveTop;
