import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { EditSeasonModal } from './EditSeasonModal';

const EDIT_SEASON_MODAL_ID = 'edit-season-modal-id';

const editSeasonModalConfig: TModalConfig = {
  id: EDIT_SEASON_MODAL_ID,
  name: EDIT_SEASON_MODAL_ID,
  title: null,
  type: EModalType.Custom,
  children: EditSeasonModal,
  dataTestId: 'season-editing-modal',
};

export { editSeasonModalConfig, EDIT_SEASON_MODAL_ID };
