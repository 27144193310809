export const editPolygonEventName = 'map.editPolygonEventName';

export const editPolygon = (geometry: any, id: number) => {
  const event = new CustomEvent(editPolygonEventName, {
    detail: {
      geometry,
      id,
    },
  });
  window.dispatchEvent(event);
};
