import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  userId: string;
  organizationId: string;
};

type TypeResponse = {};

export const deleteOrganizationUser: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: (request: TypeRequest) => `/api/da-profile/organizations/user/delete/${request.userId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
