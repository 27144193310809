import { ITableFiltersBuilderPresetConfig as IPresetConfig } from '../../../models/configs';
import { ITableFiltersBuilderPreset as IPreset } from '../../../models/data';
import { provide } from '../../../../../utils/IoC';

@provide.singleton()
class TableFiltersBuilderPresetsService<F = any> {
  createPreset = (builderId: string, config: IPresetConfig<F>, order: number): IPreset<F> => {
    return {
      ...config,
      builderId,
      order,
    };
  };

  createPresetList = (builderId: string, configList: IPresetConfig<F>[]): IPreset<F>[] => {
    const presetList = configList.map<IPreset<F>>((config, index) =>
      this.createPreset(builderId, config, index + 1)
    );

    return presetList;
  };
}

export default TableFiltersBuilderPresetsService;
