import { EFieldsUploadErrorType } from '../../../../modules/dashboard/modules/fields/utils/constants/import';
import { TypeApiRoute } from '../../../models/type.api.request';

type TImportFieldsFromGeojsonReq = {
  seasonYear: number;
  file: FormData;
  organizationId?: string;
};

type TImportFieldsFromGeojsonRes = {
  token: 'string';
  errorType?: EFieldsUploadErrorType;
  errorDescription?: 'string';
};

const importFieldsFromGeojson: TypeApiRoute & {
  request: TImportFieldsFromGeojsonReq;
  response: TImportFieldsFromGeojsonRes;
} = {
  url: () => '/api/as-fields/import/geojson',
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  request: {} as TImportFieldsFromGeojsonReq,
  response: {} as TImportFieldsFromGeojsonRes,
};

export type { TImportFieldsFromGeojsonReq, TImportFieldsFromGeojsonRes };

export { importFieldsFromGeojson };
