import styled from 'styled-components';

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 12px;
`;

const Description = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const StyledFieldsImportFileImportModal = {
  Title,
  Description,
  Wrapper,
};

export default StyledFieldsImportFileImportModal;
