import { useParams } from 'react-router-dom';

import { TasksParams } from '../../models';

const useTasksParams = (): TasksParams => {
  const params = useParams<keyof TasksParams>();

  return params;
};

export default useTasksParams;
