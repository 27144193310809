import { v4 as uuid } from 'uuid';

import {
  IDrawChecklistInstance,
  IGetChecklistInstanceByTaskId,
  IPutChecklistInstance,
} from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { checklistInstanceHelpers } from '../checklistInstanceHelpers';

type TInstance = IGetChecklistInstanceByTaskId | IPutChecklistInstance;

interface IInstListCol<T extends TInstance | IDrawChecklistInstance> {
  pointInstList: T[];
  machineryInstList: T[];
  fieldInstList: T[];
}

const createInstListCol = (instList: TInstance[]): IInstListCol<TInstance> => {
  const { isMachinery, isPlan, isActual, isField } = checklistInstanceHelpers;

  const initInstListCol: IInstListCol<TInstance> = {
    pointInstList: [],
    machineryInstList: [],
    fieldInstList: [],
  };

  const instListCol = instList.reduce<IInstListCol<TInstance>>((col, inst) => {
    if (isMachinery(inst.type)) {
      col.machineryInstList.push(inst);
    } else if (isPlan(inst.type) || isActual(inst.type)) {
      col.pointInstList.push(inst);
    } else if (isField(inst.type)) {
      col.fieldInstList.push(inst);
    }

    return col;
  }, initInstListCol);

  return instListCol;
};

const createSortedInstList = (instList: TInstance[]): TInstance[] => {
  const sortedInstList = [...instList].sort((instOne, instTwo) => {
    const timeOne = new Date(instOne.createOrUpdateDate).getTime();
    const timeTwo = new Date(instTwo.createOrUpdateDate).getTime();

    return timeOne - timeTwo;
  });

  return sortedInstList;
};

const createSortedDrawInstList = (
  drawInstList: IDrawChecklistInstance[]
): IDrawChecklistInstance[] => {
  const sortedDrawInstList = [...drawInstList].sort((drawInstOne, drawInstTwo) => {
    const timeOne = new Date(drawInstOne.instance.createOrUpdateDate).getTime();
    const timeTwo = new Date(drawInstTwo.instance.createOrUpdateDate).getTime();

    return timeOne - timeTwo;
  });

  return sortedDrawInstList;
};

const createChangedDrawInstList = (
  drawInstList: IDrawChecklistInstance[]
): IDrawChecklistInstance[] => {
  return createSortedDrawInstList(drawInstList).map<IDrawChecklistInstance>((drawInst, i) => ({
    ...drawInst,
    positionNumber: i + 1,
  }));
};

const createDrawInst = (
  combineInstance: TInstance,
  positionNumber: number,
  isClientOnly?: boolean
): IDrawChecklistInstance => {
  const isMachinery = checklistInstanceHelpers.isMachinery(combineInstance.type);
  const instance = combineInstance as IGetChecklistInstanceByTaskId;

  const drawInst: IDrawChecklistInstance = {
    id: instance?.id || uuid(),
    positionNumber,
    instance,
    isMachinery,
    isUnsaved: Boolean(isClientOnly),
  };

  return drawInst;
};

const createDrawInstListCol = (
  instList: IGetChecklistInstanceByTaskId[] = [],
  isMergedList?: boolean
): IInstListCol<IDrawChecklistInstance> => {
  const instListCol = createInstListCol(instList);

  if (isMergedList) {
    const drawInstListCol: IInstListCol<IDrawChecklistInstance> = {
      pointInstList: createSortedInstList([
        ...instListCol.pointInstList,
        ...instListCol.machineryInstList,
        ...instListCol.fieldInstList,
      ]).map((inst, i) => createDrawInst(inst, i + 1)),
      machineryInstList: [],
      fieldInstList: [],
    };

    return drawInstListCol;
  }

  const drawInstListCol: IInstListCol<IDrawChecklistInstance> = {
    pointInstList: createSortedInstList(instListCol.pointInstList).map((inst, i) =>
      createDrawInst(inst, i + 1)
    ),
    machineryInstList: createSortedInstList(instListCol.machineryInstList).map((inst, i) =>
      createDrawInst(inst, i + 1)
    ),
    fieldInstList: createSortedInstList(instListCol.fieldInstList).map((inst, i) =>
      createDrawInst(inst, i + 1)
    ),
  };

  return drawInstListCol;
};

const drawChecklistInstanceHelpers = {
  createDrawInst,
  createDrawInstListCol,
  createChangedDrawInstList,
};

export type { TInstance };

export default drawChecklistInstanceHelpers;
