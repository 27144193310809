import { FC, useCallback, useEffect, useMemo } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { DefaultTheme, Note } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { ThemeProvider } from 'styled-components';

import Fullscreen, { IFullscreenProps } from '../../containers/fullscreen';
import { useTaskActionControl, useTaskTabs, useTasksParams } from '../../hooks';
import { ETaskTabs, ITaskTab } from '../../models';
import { TasksRoute } from '../../tasks.route';
import { useStore } from '../../../../../shared/utils/IoC';
import { TaskStore } from '../../mobx/stores';
import { ETaskAction, ETaskStatus } from '../../../../../../api/models/as-fields/task/task.model';
import { getTaskStatusData } from '../../../operations/components/TaskView/utils/getTaskStatusData';
import { ChecklistInstancesStore } from '../../../operationsAndTasks/stores/checklist.instances.store';

import { TaskControlButtons } from './components/TaskCommonData/components';
import { TaskTabsHeader } from './components/TaskTabsHeader';
import { ChecklistsContainer, HistoryContainer, MapContainer, ReportContainer } from './containers';
import { SummaryContainer } from './containers/SummaryContainer';
import Styled from './TaskFullscreen.styles';

const MODE_LIST_WITH_HIDDEN_TABS: TaskStore['mode'][] = ['create-one', 'create-multiple', 'edit'];

interface IProps {
  fullscreenProps: IFullscreenProps;
  mode?: TaskStore['mode'];
}

const TaskFullscreen: FC<IProps> = ({ fullscreenProps, mode }) => {
  const taskStore = useStore(TaskStore);
  const checklistInstancesStore = useStore(ChecklistInstancesStore);

  const taskTabs = useTaskTabs();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useTasksParams();

  const controls = useTaskActionControl();
  const isCommonInfoAllowed = controls.hasPermission(ETaskAction.TaskViewCommonInfo);

  const getTabKeyByParams = useCallback(() => {
    const tabId = taskTabs.getTaskTabIdByPathname(location.pathname);

    if (tabId) {
      taskStore.setTaskTab(tabId);
    } else {
      navigate(generatePath(TasksRoute.Task, { orgId: params.orgId, taskId: params.taskId }));
    }

    return tabId;
  }, [params, location?.pathname]);

  useEffect(() => {
    getTabKeyByParams();
  }, [location]);

  const isChecklistContainerHided = useMemo(() => {
    return (
      Boolean(
        !taskStore.selectedTask?.availableActions?.includes(ETaskAction.TaskViewCheckLists)
      ) ||
      Boolean(
        !Boolean(taskStore.selectedTask?.checklistsAvailable) &&
          !Boolean(taskStore.selectedTask?.checklistsMachineryAvailable) &&
          !Boolean(taskStore.selectedTask?.checklistsFieldAvailable)
      )
    );
  }, [taskStore.selectedTask, checklistInstancesStore.drawInstWithTheLowestPosNum]);

  const { tabList, tabMap } = useMemo<{
    tabList: ITaskTab[];
    tabMap: Map<ETaskTabs, ITaskTab>;
  }>(() => {
    const _tabMap = new Map();

    const _tabList = [
      {
        content: <SummaryContainer mode={mode} />,
        id: ETaskTabs.Summary,
        tabHeaderContent: 'Постановка задачи',
        route: generatePath(TasksRoute.Task, { orgId: params.orgId, taskId: params.taskId }),
        onSelectTab: (id, tab) => navigate(tab.route),
        isHided: !isCommonInfoAllowed,
      },
      {
        content: <MapContainer mode={mode} />,
        id: ETaskTabs.Map,
        tabHeaderContent: 'На карте',
        route: generatePath(TasksRoute.TaskMap, { orgId: params.orgId, taskId: params.taskId }),
        onSelectTab: (id, tab) => navigate(tab.route),
      },
      {
        content: <ChecklistsContainer mode={mode} />,
        id: ETaskTabs.Checklists,
        tabHeaderContent: 'Чек-листы',
        route: generatePath(TasksRoute.Checklist, {
          orgId: params.orgId,
          taskId: params.taskId,
          checklistInstanceId: checklistInstancesStore.drawInstWithTheLowestPosNum?.id || '',
        }),
        onSelectTab: (id, tab) => navigate(tab.route),
        isHided: isChecklistContainerHided,
      },
      {
        content: <ReportContainer />,
        id: ETaskTabs.Report,
        isHided: true,
        tabHeaderContent: 'Отчёт',
        route: generatePath(TasksRoute.TaskReport, { orgId: params.orgId, taskId: params.taskId }),
        onSelectTab: (id, tab) => navigate(tab.route),
      },
      {
        content: <HistoryContainer />,
        id: ETaskTabs.History,
        isHided: true,
        tabHeaderContent: 'История',
        route: generatePath(TasksRoute.TaskHistory, { orgId: params.orgId, taskId: params.taskId }),
        onSelectTab: (id, tab) => navigate(tab.route),
      },
    ];

    _tabList.forEach(tab => _tabMap.set(tab.id, tab));

    return { tabList: _tabList, tabMap: _tabMap };
  }, [
    isChecklistContainerHided,
    mode,
    checklistInstancesStore.drawInstWithTheLowestPosNum,
    isCommonInfoAllowed,
  ]);

  const currentTab = useMemo(() => {
    if (!taskStore.taskTab) {
      getTabKeyByParams();
    }

    return tabMap.get(taskStore.taskTab)?.content || null;
  }, [tabMap, taskStore.taskTab]);

  const isShowTabsHeader = useMemo(() => {
    return !MODE_LIST_WITH_HIDDEN_TABS.some(hiddenMode => mode === hiddenMode);
  }, [mode]);

  const cancelReasonNotification = useMemo(() => {
    if (taskStore?.selectedTask) {
      return getTaskStatusData(taskStore?.selectedTask);
    }
  }, [taskStore?.selectedTask]);

  return (
    <Fullscreen {...fullscreenProps}>
      {taskStore.selectedTask?.cancellationReason &&
        taskStore.selectedTask.status === ETaskStatus.Canceled && (
          <ThemeProvider theme={DefaultTheme}>
            <Styled.NoteWrapper>
              <Note
                bodyText={cancelReasonNotification?.normalText}
                title={cancelReasonNotification?.boldText}
                noteType={'redInfo'}
                dataTestId={'task-cancel-reason'}
                iconName={'red_info'}
                fullWidth
              />
            </Styled.NoteWrapper>
          </ThemeProvider>
        )}

      {isShowTabsHeader ? (
        <TaskTabsHeader tabList={tabList} currentTab={taskStore.taskTab} />
      ) : null}

      {currentTab}

      <TaskControlButtons />
    </Fullscreen>
  );
};

TaskFullscreen.displayName = 'TaskFullscreen';

export default observer(TaskFullscreen);
