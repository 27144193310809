import { FC, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

import { ReactComponent as MenuSvg } from '../../../../../../static/menu.svg';
import { Operation } from '../../../../../../api/models/operations/operation.model';
import { OperationsStore } from '../../stores/operations.store';
import { OperationsUIStore } from '../../stores/operations.ui.store';
import { FieldsStore } from '../../../fields/stores/fields.store';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationField } from '../../../../../../api/models/operations/operation.field.model';
import { toFixedWithCeilBackEnd } from '../../../../../shared/utils/toFixedWithCeil';
import { TasksController } from '../../controllers/tasks.controller';
import { TasksStore } from '../../stores/tasks.store';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonCultureStore } from '../../stores/season.culture.store';

import { Field, FieldImg, FieldName, FieldTasks, FieldArea, Spacer, FieldInfo } from './style';

type Props = {
  field: OperationField;
  isActive: boolean;
};

export const OperationFieldsListItem: FC<Props> = observer(({ field, isActive }) => {
  const organizationsStore = useStore(OrganizationsStore);
  const seasonCultureStore = useStore(SeasonCultureStore);
  const store = useStore(OperationsStore);
  const uiStore = useStore(OperationsUIStore);
  const fieldsStore = useStore(FieldsStore);
  const tasksStore = useStore(TasksStore);
  const tasksController = useStore(TasksController);

  const currentField = fieldsStore.idToFields.get(field.fieldId);
  console.log(currentField);

  const [showField, setShowField] = useState<boolean>(false);

  const handleFieldClick = async (fieldId: string) => {
    uiStore.selectMarkId(fieldId);
    store.selectOperationFieldId(fieldId);
    tasksController.clear();
    tasksController.resetPageSettings();
    await tasksController.fetchTasks({
        organizationId: organizationsStore.selectedOrganizationId,
        seassonCultureId: seasonCultureStore.selectedCultureId,
        operationId: store.selectedOperationId,
        fieldId: store.selectedOperationFieldId,
        noCulture: seasonCultureStore.selectedCultureId === 'emptyCulture' ? true : undefined,
      });
  }

  return (
    <Field
      onClick={handleFieldClick.bind(this, field.fieldId)}
      data-test-id={'operation-field-item'}
      isActive={isActive}
    >
      <FieldImg src={currentField?.icon?.downloadUrl} data-test-id={'operation-field-item-img'}></FieldImg>
      <FieldInfo>
        <FieldName data-test-id={'operation-field-item-name'}>{field.fieldName}</FieldName>
        <FieldTasks data-test-id={'operation-field-item-tasks'}>{field.tasks} задач</FieldTasks>
      </FieldInfo>
      <FieldArea data-test-id={'operation-field-item-area'}>
        {`${currentField?.area ? toFixedWithCeilBackEnd(currentField?.area) : '0'} га`}
      </FieldArea>
    </Field>
  );
});
