import { Colors, Tooltip, useModal, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, memo, useCallback, useMemo } from 'react';
import moment from 'moment';

import {
  IDrawChecklistInstance,
  IGetChecklistInstanceByTaskId,
} from '../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { ProfileStore } from '../../../../../../profile/stores/ProfileStore';
import { ChecklistInstancesController } from '../../../../../controllers/checklist.instances.controller';
import { TaskStore } from '../../../../../../tasks/mobx/stores';
import { useTasksParams } from '../../../../../../tasks/hooks';
import { CheckAccessStore } from '../../../../../../../../authorization/stores/checkAccess.store';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../../../../shared/constants/access-rules-action';
import { EPointType } from '../../ListOfChecklistInstance.types';
import InstanceListActualityService from '../../../../../services/instanceList/instanceListActuality.service';
import { EDIT_TASK_MAP_POINT_MODAL_ID } from '../../../../../../tasks/modals/editTaskMapPointModal/editTaskMapPointModal';
import {
  ENotificationScheme,
  getNotificationScheme,
} from '../../../../../../../../shared/utils/helpers/getNotificationScheme';

import Styled from './ChecklistInstance.styles';
import { InstanceCoordinates, ReturnIcon } from './components';
import getInstanceName from './helpers/getInstanceName';
import getInstanceType from './helpers/getInstanceType';
import { useChecklistInstanceActions } from './hooks';
import getInstanceTypeColor from './helpers/getInstanceTypeColor';

export interface IChecklistInstanceProps {
  extendedInstanceByColor: IDrawChecklistInstance;
  isChangesCanBeUndone?: boolean;
  isActionsHide?: boolean;
  isCoordinatesHide?: boolean;
  isDisplayTime?: boolean;
  isShort?: boolean;

  isDisplayOldChecklistButtons?: boolean;
}

export type TInstanceExtendedByClientId = IGetChecklistInstanceByTaskId & {
  clientOnlyId?: string;
  coordinates?: number[];
};

const ChecklistInstance: FC<IChecklistInstanceProps> = observer(
  ({ extendedInstanceByColor, isChangesCanBeUndone, isActionsHide, isDisplayTime }) => {
    const { selectedTask, isEditMode } = useStore(TaskStore);
    const { user } = useStore(ProfileStore);
    const { getAccessRuleValue } = useStore(CheckAccessStore);
    const { selectedOrganizationId } = useStore(OrganizationsStore);
    const {
      addInstanceToRemoveList,
      deleteInstanceFromRemoveList,
      isInstanceReadyToRemove,
      readyToRemoveInstanceIdList,
    } = useStore(InstanceListActualityService);

    const { deleteInstance } = useStore(ChecklistInstancesController);

    const { taskId } = useTasksParams();

    const { markAsDeletable, updateDrawInst } = useChecklistInstanceActions();

    const { openModalByModalId, closeModal } = useModal();
    const { setNotification } = useNotificator();

    const { positionNumber, instance: combineInstance } = extendedInstanceByColor;

    const workWithTasks = getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS);
    const manageTasks = getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS);

    const isOrgMy = selectedOrganizationId === 'my';
    const isTasksCreating = taskId === 'create';

    const task = selectedTask as any;
    const inst = combineInstance as TInstanceExtendedByClientId;

    const isDisplayDeleteButton = inst?.isComplete === false || extendedInstanceByColor.isUnsaved;
    const isDisplayEditButton = inst?.isComplete === false || extendedInstanceByColor.isUnsaved;
    const isReadyToRemove = useMemo(() => isInstanceReadyToRemove(extendedInstanceByColor.id), [
      readyToRemoveInstanceIdList,
    ]);

    const checkAccessRulesForEditChecklist = () => {
      if (!task) {
        return false;
      }

      if (isTasksCreating) return false; // проверяем не оздание ли это новой задачи

      if (isOrgMy || manageTasks) return true;

      const isUserAssignedToTask = task?.assignee?.id === user?.id;

      if (workWithTasks && isUserAssignedToTask) return true;

      if (workWithTasks && !isUserAssignedToTask) return false;

      return false;
    };

    const {
      instanceDate,
      instanceName,
      instanceType,
      instancePointTypeColor,
      instanceCoordinates,
    } = useMemo<{
      instanceName: string;
      instanceType: string;
      instanceDate: string;
      instancePointTypeColor: EPointType;
      instanceCoordinates?: number[];
    }>(() => {
      const { instance, positionNumber: _positionNumber } = extendedInstanceByColor;

      return {
        instanceName: getInstanceName(instance.type, _positionNumber),
        instanceType: getInstanceType(inst),
        instanceDate: inst?.createOrUpdateDate
          ? moment(inst.createOrUpdateDate).format('DD.MM.YY HH:mm')
          : null,
        instancePointTypeColor: getInstanceTypeColor(inst),
        instanceCoordinates:
          instance?.actualCoords?.geometry?.coordinates ||
          instance?.actualCoords?.coordinates ||
          instance?.planCoords?.geometry?.coordinates ||
          instance?.planCoords?.coordinates,
      };
    }, [extendedInstanceByColor]);

    const onDeleteHandler = useCallback(() => {
      addInstanceToRemoveList(extendedInstanceByColor.id, () =>
        deleteInstance(extendedInstanceByColor.id, {
          isChangesCanBeUndone,
        })
      );

      markAsDeletable(extendedInstanceByColor.id, true);
    }, [extendedInstanceByColor, isChangesCanBeUndone]);

    const onReturnHandler = useCallback(() => {
      deleteInstanceFromRemoveList(extendedInstanceByColor.id);
      markAsDeletable(extendedInstanceByColor.id, false);
    }, [extendedInstanceByColor]);

    const onEditHandler = useCallback(() => {
      openModalByModalId(
        EDIT_TASK_MAP_POINT_MODAL_ID,
        {
          ...inst,
          positionNumber,
          coordinates:
            inst?.actualCoords?.geometry?.coordinates ||
            inst?.actualCoords?.coordinates ||
            inst?.planCoords?.geometry?.coordinates ||
            inst?.planCoords?.coordinates,
        },
        (data: TInstanceExtendedByClientId) => {
          updateDrawInst(extendedInstanceByColor, data);

          setNotification(
            getNotificationScheme(ENotificationScheme.Dark, 'Точки успешно изменены')
          );

          closeModal();
        }
      );
    }, [inst, positionNumber, extendedInstanceByColor]);

    return (
      <Styled.Wrapper>
        <Styled.NameWrapper $isRemoving={isReadyToRemove}>
          <Styled.PositionType type={instancePointTypeColor} />
          <Styled.Label data-test-id="instance-point-name">{instanceName}</Styled.Label>
        </Styled.NameWrapper>

        <Styled.TypeWrapper $isRemoving={isReadyToRemove}>
          <Styled.Label data-test-id="instance-point-type">{instanceType}</Styled.Label>
        </Styled.TypeWrapper>

        <Styled.CoordinatesWrapper $isRemoving={isReadyToRemove} $isRightAlign={!isEditMode}>
          {Boolean(instanceCoordinates) && (
            <InstanceCoordinates order={positionNumber} coordinates={instanceCoordinates} />
          )}
        </Styled.CoordinatesWrapper>

        {!isActionsHide && isEditMode && (
          <Styled.OperationWrapper>
            {isReadyToRemove ? (
              <Styled.ActionButtonWrapper>
                <Tooltip
                  id={`return-button-tooltip-${positionNumber}`}
                  getContent={() => 'Восстановить точку'}
                  position="start"
                  place="bottom"
                  offset={{ left: 8 }}
                >
                  <Styled.ActionButton
                    customIcon={<ReturnIcon />}
                    dataTestId="return-instance-button"
                    onClick={onReturnHandler}
                    $hoverColor={{
                      fill: 'none',
                      color: Colors.green,
                    }}
                  />
                </Tooltip>
              </Styled.ActionButtonWrapper>
            ) : (
              <>
                {isDisplayEditButton && (
                  <Tooltip
                    id={`edit-button-tooltip-${positionNumber}`}
                    getContent={() => 'Редактировать координаты'}
                    position="start"
                    place="bottom"
                    offset={{ left: 8 }}
                  >
                    <Styled.ActionButtonWrapper>
                      <Styled.ActionButton
                        icon="edit"
                        dataTestId="edit-instance-button"
                        onClick={onEditHandler}
                      />
                    </Styled.ActionButtonWrapper>
                  </Tooltip>
                )}
                {isDisplayDeleteButton && (
                  <Tooltip
                    id={`delete-button-tooltip-${positionNumber}`}
                    getContent={() => 'Удалить точку'}
                    position="start"
                    place="bottom"
                    offset={{ left: 8 }}
                  >
                    <Styled.ActionButtonWrapper>
                      <Styled.ActionButton
                        icon="bin"
                        dataTestId="delete-instance-button"
                        onClick={onDeleteHandler}
                        $hoverColor={{
                          fill: Colors.pink,
                        }}
                        $isDisabled={
                          checkAccessRulesForEditChecklist()
                            ? false
                            : inst?.createdBy && user?.id !== inst?.createdBy
                        }
                      />
                    </Styled.ActionButtonWrapper>
                  </Tooltip>
                )}
              </>
            )}
          </Styled.OperationWrapper>
        )}

        {isDisplayTime && (
          <Styled.OperationWrapper>
            <Styled.Label data-test-id="instance-point-date">{instanceDate}</Styled.Label>
          </Styled.OperationWrapper>
        )}
      </Styled.Wrapper>
    );
  }
);

ChecklistInstance.displayName = 'ChecklistInstance';

export default memo(ChecklistInstance);
