import {
  EChecklistAttributeType,
  IChecklistAttributeWithValue,
} from '../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

const isBoolAttr = (attrWithValue: IChecklistAttributeWithValue): boolean => {
  return attrWithValue.checklistAttribute.attribute.type === EChecklistAttributeType.Boolean;
};

const isUserDictLinkAttr = (attrWithValue: IChecklistAttributeWithValue): boolean => {
  return (
    attrWithValue.checklistAttribute.attribute.type === EChecklistAttributeType.UserDictionaryLink
  );
};

const isFileLinkAttr = (attrWithValue: IChecklistAttributeWithValue): boolean => {
  return attrWithValue.checklistAttribute.attribute.type === EChecklistAttributeType.FileLink;
};

const checklistAttrTypeHelpers = {
  isBoolAttr,
  isUserDictLinkAttr,
  isFileLinkAttr,
};

export default checklistAttrTypeHelpers;
