import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import {
  IGetChecklistAttribute as IAttribute,
  IGetChecklistAttributeValue as IAttributeValue,
} from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { TChecklistsBooleanAttrToDraw as TBooleanAttrToDraw } from '../../../../models';
import { ChecklistsStore } from '../../../stores';
import { ChecklistsAttrsFormulasService } from '../../ChecklistsAttrsFormulasService';
import { EChecklistMode } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';

@provide.transient()
class ChecklistsBooleanAttrsService {
  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(ChecklistsAttrsFormulasService)
  protected formulasService: ChecklistsAttrsFormulasService;

  createAttrToDraw = (
    groupId: string,
    attr: IAttribute,
    initialValueList: IAttributeValue[],
    options?: {
      isBlocked?: boolean;
      nestedInstanceId?: string;
      dependentFileAttrId?: string;
    }
  ): TBooleanAttrToDraw => {
    const attrToDraw: TBooleanAttrToDraw = {
      id: attr.id,
      groupId,
      isVisible: true,
      order: attr.order,
      initialModel: attr,
      value: {
        checkListAttributeId: attr.id,
        booleanValue: false,
      },
      options: null,
      isBlocked: options?.isBlocked || this.checklistsStore.mode === EChecklistMode.View,
      validationScheme: {
        isShowError: false,
        errorTitle: '',
      },
      isEdited: false,
      nestedInstanceId: options?.nestedInstanceId,
      dependentFileAttrId: options?.dependentFileAttrId,
    };

    const initialValueOfThisAttr = initialValueList.find(
      ({ checkListAttributeId }) => checkListAttributeId === attrToDraw.id
    );

    if (initialValueOfThisAttr) {
      attrToDraw.value = {
        ...attrToDraw.value,
        booleanValue: Boolean(initialValueOfThisAttr.booleanValue),
      };
    }

    return attrToDraw;
  };

  changeValue = (groupId: string, value: TBooleanAttrToDraw['value']): void => {
    this.checklistsStore.updateAttrToDraw(groupId, value.checkListAttributeId, {
      value,
      isEdited: true,
    });
  };
}

export default ChecklistsBooleanAttrsService;
