import { FC, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { CalendarComponent } from '@farmlink/farmik-ui';
import moment from 'moment/moment';

import { TChecklistsDateAttrToDraw as TDateAttrToDraw } from '../../../../models';
import {
  ChecklistsAttr as Attribute,
  ChecklistsCSSContainer as CSSContainer,
} from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { SeasonsStore } from '../../../../../../../../stores/seasons.store';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { useChecklistsAttrErrorList } from '../../../../hooks';
import { ChecklistsStore } from '../../../../mobx/stores';

interface IProps {
  attrToDraw: TDateAttrToDraw;
}

const ChecklistsDateAttr: FC<IProps> = ({
  attrToDraw: {
    id,
    groupId,
    initialModel,
    value,
    isBlocked,
    validationScheme,
    dependentFileAttrId,
  },
}) => {
  const seasonsStore = useStore(SeasonsStore);
  const checklistsStore = useStore(ChecklistsStore);

  const checklistsController = useStore(ChecklistsController);

  const dependentFileAttr = checklistsStore.getAttrToDraw<EAttrType.FileLink>(
    groupId,
    dependentFileAttrId
  );

  const isDependentFileEmpty = !dependentFileAttr?.value?.fileValue?.length;

  const visibilityResult = initialModel.visibility
    ? checklistsController.calculateAttrVisibility(groupId, id)
    : { value: true };

  useEffect(() => {
    checklistsController.toggleAttrVisibility(groupId, id, visibilityResult.value as boolean);

    if (!dependentFileAttr) return;

    checklistsController.toggleAttrVisibility(
      groupId,
      dependentFileAttrId,
      visibilityResult.value as boolean
    );
  }, [JSON.stringify(visibilityResult)]);

  const errorList = useChecklistsAttrErrorList(
    validationScheme,
    dependentFileAttr?.validationScheme
  );

  const errorString = useMemo(() => errorList.join('\n'), [errorList]);

  const handleChange = useCallback((newValue: string): void => {
    checklistsController.changeAttrValue(EAttrType.Date, groupId, {
      ...value,
      dateValue: newValue,
    });
  }, []);

  return (
    <>
      {visibilityResult.value ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
        >
          <CalendarComponent
            label={initialModel.attribute?.name}
            value={
              value.dateValue ? moment(value.dateValue, 'YYYY-MM-DD')?.format('DD.MM.YYYY') : ''
            }
            defaultValue={
              value.dateValue ? moment(value.dateValue, 'YYYY-MM-DD')?.format('DD.MM.YYYY') : ''
            }
            onChange={(newDate: Date) => handleChange(moment(newDate).format('YYYY-MM-DD'))}
            placeholder={initialModel.attribute?.placeholder}
            datePickerOptions={{
              dateRange: {
                minDate: moment(seasonsStore.selectedSeassonData.startDate, 'YYYY-MM-DD').toDate(),
                maxDate: moment(seasonsStore.selectedSeassonData.endDate, 'YYYY-MM-DD')
                  .hours(23)
                  .minutes(59)
                  .seconds(59)
                  .milliseconds(999)
                  .toDate(),
              },
            }}
            tooltip={initialModel.toolTip}
            isRequired={initialModel.isRequired}
            isBlocked={isBlocked}
            isCloseOnChange={true}
            error={errorString}
          />

          <CSSContainer display={'flex'} justifyContent={'space-between'}>
            <CSSContainer {...(isDependentFileEmpty ? {} : { flex: '1 1 auto' })}>
              {dependentFileAttrId ? (
                <FileAttrContainer groupId={groupId} attrId={dependentFileAttrId} />
              ) : null}
            </CSSContainer>
          </CSSContainer>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsDateAttr.displayName = 'ChecklistsDateAttr';

export default observer(ChecklistsDateAttr);
