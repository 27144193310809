import {
  TChangeImportedFieldReq,
  TGetImportedFieldListReq,
  TImportFieldsFromKmlRes,
  TImportFieldsFromShapeReq,
  TImportFieldsFromShapeRes,
} from '../../../../../../api/api';
import { Field } from '../../../../../../api/models/field.model';
import { RenderPolygonOption } from '../../../../../map/consts/enum.render.option';
import MapStore, { MapMode } from '../../../../../map/stores/map.store';
import { Axios } from '../../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { EFieldsImportModalName } from '../../modals/import/configs/fieldsImportModalsConfig';
import { FieldsImportService } from '../../services/import';
import { FieldsStore } from '../../stores/fields.store';
import { FieldsImportStore } from '../../stores/import';
import { importedFieldsSessionStorageHelpers } from '../../utils/helpers/import';
import { FieldsController } from '../fields.controller';

@provide.singleton()
class FieldsImportController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(OrganizationsStore)
  protected organizationStore: OrganizationsStore;

  @lazyInject(MapStore)
  protected mapStore: MapStore;

  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(FieldsImportStore)
  protected fieldsImportStore: FieldsImportStore;

  @lazyInject(FieldsImportService)
  protected fieldsImportService: FieldsImportService;

  @lazyInject(FieldsController)
  protected fieldsController: FieldsController;

  importFields = async (apiName: string, formData: FormData): Promise<TImportFieldsFromKmlRes> => {
    const { selectedOrganizationId } = this.organizationStore;
    const { selectedSeason } = this.seasonsStore;
    const { setIsDataBeingProcessed } = this.fieldsImportStore;
    const { importFields } = this.fieldsImportService;
    const { setToken } = importedFieldsSessionStorageHelpers;

    setIsDataBeingProcessed(true);

    const res = await importFields(apiName, {
      organizationId: selectedOrganizationId === 'my' ? '' : selectedOrganizationId,
      seasonYear: Number(selectedSeason),
      file: formData,
    });

    if (!res) {
      setIsDataBeingProcessed(false);

      return res;
    }

    if (!res?.errorType) {
      setToken(selectedOrganizationId, selectedSeason, res?.token);
    }

    return res;
  };

  importFieldsFromShape = async (formData: FormData): Promise<TImportFieldsFromShapeRes> => {
    const { selectedOrganizationId } = this.organizationStore;
    const { selectedSeason } = this.seasonsStore;
    const { setIsDataBeingProcessed } = this.fieldsImportStore;
    const { importFieldsFromShape } = this.fieldsImportService;
    const { setToken } = importedFieldsSessionStorageHelpers;

    setIsDataBeingProcessed(true);

    const payload: TImportFieldsFromShapeReq = {
      organizationId: selectedOrganizationId === 'my' ? '' : selectedOrganizationId,
      seasonYear: Number(selectedSeason),
      files: formData,
    };

    const importShapeRes = await importFieldsFromShape(payload);

    if (!importShapeRes) {
      setIsDataBeingProcessed(false);

      return importShapeRes;
    }

    if (!importShapeRes?.errorType) {
      setToken(selectedOrganizationId, selectedSeason, importShapeRes?.token);
    }

    setToken(selectedOrganizationId, selectedSeason, importShapeRes?.token);

    return importShapeRes;
  };

  fetchImportedList = async (token: string): Promise<boolean> => {
    const { setIsDataBeingProcessed, setIdToImportedField } = this.fieldsImportStore;
    const { getImportedFieldList } = this.fieldsImportService;

    const importListPayload: TGetImportedFieldListReq = {
      token,
      size: 500,
    };

    const importListRes = await getImportedFieldList(importListPayload);

    if (!importListRes) {
      setIsDataBeingProcessed(false);

      return false;
    }

    const { content } = importListRes;

    setIdToImportedField(content);
    setIsDataBeingProcessed(false);

    return true;
  };

  deleteImportedField = async (field: Field): Promise<void> => {
    const { setSaveErrorModal } = this.fieldsImportStore;
    const { deletePolygon } = this.mapStore;
    const { editableField, deleteField, clearEditableField } = this.fieldsStore;

    const res = await this.fieldsImportService.deleteImportedFieldById(field.id);

    if (res === EFieldsImportModalName._Success) {
      deleteField(field.id);

      if (!field?.polyId) {
        return;
      }

      deletePolygon(field?.polyId);

      if (field.id === editableField?.id) {
        clearEditableField();
      }
    } else {
      setSaveErrorModal(res);
    }
  };

  saveImportedFieldList = async (): Promise<EFieldsImportModalName> => {
    const { selectedOrganizationId } = this.organizationStore;
    const { selectedSeason } = this.seasonsStore;
    const { saveImportedFieldList } = this.fieldsImportService;
    const { setSaveErrorModal } = this.fieldsImportStore;
    const { getToken, deleteToken } = importedFieldsSessionStorageHelpers;

    const token = getToken(selectedOrganizationId, selectedSeason);

    if (!token) {
      return;
    }

    // Показываем лоадер
    setSaveErrorModal(EFieldsImportModalName.Loader);

    const res = await saveImportedFieldList(token);

    if (res === EFieldsImportModalName._Success) {
      deleteToken();

      return res;
    } else {
      setSaveErrorModal(res);

      return res;
    }
  };

  changeFieldName = (field: Field, value: string): void => {
    const { setTooltipContentForLayer } = this.mapStore;
    const { editableField, getField, setField, setEditableField } = this.fieldsStore;

    const foundField = getField(field.id);
    const changedField: Field = { ...foundField, name: value };
    const changedEditableField = { ...editableField, name: value };

    setField(changedField);
    setEditableField(changedEditableField);
    setTooltipContentForLayer(field?.polyId, value);
  };

  displayImportedFields = () => {
    const { selectedOrganizationId } = this.organizationStore;
    const { listOfImportedField } = this.fieldsImportStore;
    const { displayImportedFields } = this.fieldsController;
    const { getToken } = importedFieldsSessionStorageHelpers;

    if (!listOfImportedField.length) {
      // Задержка в связи с медленным получением сезона
      setTimeout(async () => {
        const token = await getToken(selectedOrganizationId, this.seasonsStore.selectedSeason);

        if (token) {
          this.fetchImportedList(token).then(() => {
            displayImportedFields();
          });
        }
      }, 500);
    } else {
      displayImportedFields();
    }
  };

  changeImportedField = async (fieldProps: TChangeImportedFieldReq): Promise<boolean> => {
    const { setSaveErrorModal, setIsWaitingForEditRes } = this.fieldsImportStore;
    const { changeImportedField } = this.fieldsImportService;

    const res = await changeImportedField(fieldProps?.id, fieldProps);

    setIsWaitingForEditRes(false);

    if (res === EFieldsImportModalName._Success) {
      return true;
    } else {
      setSaveErrorModal(res);
    }
  };

  clearStoreAfterLeavePage = async (): Promise<void> => {
    const { clearStore } = this.fieldsImportStore;
    const { setViewMode, clearAll } = this.mapStore;
    const { fetchFieldsList, setMapModeValue } = this.fieldsController;
    const { clearEditableField } = this.fieldsStore;

    clearEditableField();
    clearStore();
    clearAll();

    await fetchFieldsList(RenderPolygonOption.View, '', true);
    setMapModeValue(MapMode.Listing);
    setViewMode();
  };
}

export default FieldsImportController;
