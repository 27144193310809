import styled, { css } from 'styled-components';

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  color: #151f32;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Description = styled.div`
  width: 100%;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #7e8b9d;
`;

const Content = styled.div``;

const Header = styled.div<{ $isHideHeaderBorder?: boolean }>`
  display: flex;
  flex-direction: column;

  gap: 8px;

  ${({ $isHideHeaderBorder }) =>
    !$isHideHeaderBorder &&
    css`
      border-bottom: 1px solid #e9e6f0;
      padding-bottom: 10px;
    `}
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  padding: 24px;

  gap: 16px;

  background-color: white;
`;

const StyledContainer = {
  Title,
  TitleWrapper,
  Description,
  Content,
  Header,
  Wrapper,
};

export default StyledContainer;
