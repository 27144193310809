import { useModal } from '@farmlink/farmik-ui';
import { useCallback } from 'react';

import { createWarningBeforeLeavingThePage } from '../../configs/modals/global';

type ShowWarningBeforeLeavingThePage = (successHandler: () => void) => void;

interface Actions {
  showWarningBeforeLeavingThePage: ShowWarningBeforeLeavingThePage;
}

const useWarningBeforeLeavingThePage = (): Actions => {
  const { registerModalList, openModalByModalId } = useModal();

  const showWarningBeforeLeavingThePage = useCallback<ShowWarningBeforeLeavingThePage>(
    successHandler => {
      const modalConfig = createWarningBeforeLeavingThePage(successHandler);

      registerModalList(modalConfig.configList, modalConfig.key);

      openModalByModalId(modalConfig.name);
    },
    []
  );

  return {
    showWarningBeforeLeavingThePage,
  };
};

export default useWarningBeforeLeavingThePage;
