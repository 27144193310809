import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { EditTaskPointModal } from './components';

export const EDIT_TASK_MAP_POINT_MODAL_ID = 'editTaskMapPointModal';

export const editTaskMapPointModal: TModalConfig = {
  id: EDIT_TASK_MAP_POINT_MODAL_ID,
  name: EDIT_TASK_MAP_POINT_MODAL_ID,
  children: EditTaskPointModal,
  type: EModalType.Custom,
  dataTestId: EDIT_TASK_MAP_POINT_MODAL_ID,
  title: null,
  styledProps: {
    $size: EModalSize.Medium,
    $modalPaddings: '40px 60px',
  },
  styles: {
    title: {
      $withoutDescription: true,
    },
  },
  className: EDIT_TASK_MAP_POINT_MODAL_ID,
};
