import { FC, memo, useMemo } from 'react';

import { AverageAttributeItem } from '../index';
import AverageAttributeHelpers from '../../../../helpers/AverageAttribute.helpers';
import {
  IChecklistAverageAttrRow,
  IExtendedAverageValue,
} from '../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

import Styled from './AverageAttributeItemsBodyRow.styles';

interface IProps {
  inputNumberType: 'integer' | 'double';
  actionType: 'delete' | 'add';
  row: IChecklistAverageAttrRow;
  onChangeItemValue: (item: IExtendedAverageValue) => void;
  onAddRowClick: () => void;
  onDeleteRowClick: (rowId: string) => void;
  isBlocked?: boolean;
}

const { getFilledValueList } = AverageAttributeHelpers;

const AverageAttributeItemsBodyRow: FC<IProps> = ({
  inputNumberType,
  actionType,
  row,
  onChangeItemValue,
  onAddRowClick,
  onDeleteRowClick,
  isBlocked,
}) => {
  const valueList = useMemo(() => {
    if (isBlocked) {
      return getFilledValueList(row.valueList);
    }

    return row.valueList;
  }, [row.valueList, isBlocked]);

  const ActionAdd =
    !isBlocked && actionType === 'add' ? <Styled.AddAction onClick={onAddRowClick} /> : null;

  const ActionDelete =
    !isBlocked && actionType === 'delete' ? (
      <Styled.DeleteAction onClick={() => onDeleteRowClick(row.id)} />
    ) : null;

  return (
    <Styled.Wrapper>
      {valueList.map(value => (
        <AverageAttributeItem
          key={value.id}
          inputNumberType={inputNumberType}
          item={value}
          onChangeItemValue={onChangeItemValue}
          isBlocked={isBlocked}
          hasLongValue={row?.hasLongValue}
        />
      ))}

      {ActionAdd}
      {ActionDelete}
    </Styled.Wrapper>
  );
};

AverageAttributeItemsBodyRow.displayName = 'AverageAttributeItemsBodyRow';

export default memo(AverageAttributeItemsBodyRow);
