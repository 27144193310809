import { FC } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@farmlink/farmik-ui';

import { PanelWrapper } from './style';

export const AdminPanel: FC = observer(() => {
  const handleClick = () => {
    localStorage.removeItem('loginAs');
    // window.close();
  };

  if (!localStorage.getItem('loginAs')) {
    return null;
  }

  return (
    <PanelWrapper>
      <p>Админка</p>
      <div>
        <Button color={'primary'} type={'button'} onClick={handleClick}>
          Выйти
        </Button>
      </div>
    </PanelWrapper>
  );
});
