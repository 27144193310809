import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { TableFiltersBuilderStore as Store } from '../../../mobx/stores';
import { TableFiltersBuilderController as Controller } from '../../../mobx/controllers';
import { useTableFiltersBuilderUIContext as useUIContext } from '../../../context/UI/TableFiltersBuilderUIContext/TableFiltersBuilderUI.context';
import { useStore } from '../../../../../utils/IoC';

import Styled from './TableFiltersBuilderHeader.styles';
import { TableFiltersBuilderFilterIcon as FilterIcon } from './components/icons';

const TableFiltersBuilderHeader: FC = () => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const UIContext = useUIContext();

  const headerConfig = store.getHeaderConfig(UIContext.builderId);
  const isShowFilters = store.getIsShowFilters(UIContext.builderId);
  const hasAppliedValues = Boolean(store.getAppliedValueList(UIContext.builderId).length);

  const toggleFilters = useCallback(() => {
    controller.toggleFiltersVisibility(UIContext.builderId);
  }, []);

  const getDataTestId = useDataTestId();
  const filterIconDataTestId = getDataTestId('toggle-filters-button')['data-test-id'];

  return (
    <Styled.Header {...getDataTestId('header')}>
      <Styled.TitleWrapper {...getDataTestId('header-title-wrapper')}>
        <Styled.Title {...getDataTestId('header-title')}>{headerConfig?.title}</Styled.Title>

        {headerConfig?.ContextualHelpIcon ? headerConfig.ContextualHelpIcon : null}
      </Styled.TitleWrapper>

      <Styled.Controls {...getDataTestId('header-controls')}>
        <Styled.Icons {...getDataTestId('header-icons')}>
          {headerConfig?.iconsConfig?.iconList?.map?.(icon => (
            <Styled.IconWrapper key={icon.id}>{icon.element}</Styled.IconWrapper>
          ))}

          {headerConfig?.iconsConfig?.isShowToggleFilters ? (
            <Styled.IconWrapper onClick={toggleFilters} {...getDataTestId('header-icon-wrapper')}>
              <FilterIcon
                isOpened={isShowFilters}
                isActive={hasAppliedValues}
                dataTestId={filterIconDataTestId}
              />
            </Styled.IconWrapper>
          ) : null}
        </Styled.Icons>

        {headerConfig?.actionButtonConfig?.autoRenderConfig ? (
          <Styled.ActionButton
            color={'primary'}
            type={'button'}
            onClick={headerConfig?.actionButtonConfig?.autoRenderConfig?.onClick}
          >
            {headerConfig?.actionButtonConfig?.autoRenderConfig?.title}
          </Styled.ActionButton>
        ) : (
          headerConfig?.actionButtonConfig?.customRenderConfig?.renderButton?.()
        )}
      </Styled.Controls>
    </Styled.Header>
  );
};

TableFiltersBuilderHeader.displayName = 'TableFiltersBuilderHeader';

export default observer(TableFiltersBuilderHeader);
