import { Colors, IconButton, Typography } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding: 10px 8px;

  position: relative;

  :after {
    position: absolute;
    width: 1px;
    height: 20px;
    top: auto;
    right: 0;
    content: '';
    background-color: ${Colors.borderGray};
    border-radius: 1px;
  }
`;

const Value = styled(Typography)`
  width: 40px;
  flex-shrink: 0;
`;

const ArrowButton = IconButton;

const Styled = {
  Wrapper,
  Value,
  ArrowButton,
};

export default Styled;
