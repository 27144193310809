import { FC } from 'react';
import { observer } from 'mobx-react';

import AverageAttributeHelpers from '../../helpers/AverageAttribute.helpers';
import {
  TChecklistsDoubleAttrToDraw as TDoubleAttrToDraw,
  TChecklistsDoubleAttrToDraw,
  TChecklistsIntegerAttrToDraw as TIntegerAttrToDraw,
  TChecklistsIntegerAttrToDraw,
} from '../../../../../models';
import {
  EChecklistAttributeType as EAttrType,
  IExtendedAverageValue,
} from '../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { useStore } from '../../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../../mobx/controllers';

import { AverageAttributeItemsBodyRow } from './components';
import Styled from './AverageAttributeItemsBody.styles';

interface IProps {
  attrToDraw: TChecklistsIntegerAttrToDraw | TChecklistsDoubleAttrToDraw;
}

const { changeValues, addRow, deleteRow } = AverageAttributeHelpers;

const AverageAttributeItemsBody: FC<IProps> = ({ attrToDraw }) => {
  const checklistsController = useStore(ChecklistsController);

  const handleDeleteRow = (rowId: string): void => {
    if (attrToDraw.initialModel.attribute.type === EAttrType.Int) {
      const { value, averageData } = deleteRow<EAttrType.Int>(
        attrToDraw as TIntegerAttrToDraw,
        rowId
      );

      checklistsController.changeAttrValue(EAttrType.Int, attrToDraw.groupId, value);
      checklistsController.changeAttrOptions<EAttrType.Int>(attrToDraw.groupId, attrToDraw.id, {
        averageData,
      });
    } else {
      const { value, averageData } = deleteRow<EAttrType.Double>(
        attrToDraw as TDoubleAttrToDraw,
        rowId
      );

      checklistsController.changeAttrValue(EAttrType.Double, attrToDraw.groupId, value);
      checklistsController.changeAttrOptions<EAttrType.Double>(attrToDraw.groupId, attrToDraw.id, {
        averageData,
      });
    }
  };

  const handleAddRowClick = (): void => {
    if (attrToDraw.initialModel.attribute.type === EAttrType.Int) {
      const { value, averageData } = addRow<EAttrType.Int>(attrToDraw as TIntegerAttrToDraw);

      checklistsController.changeAttrValue(EAttrType.Int, attrToDraw.groupId, value);
      checklistsController.changeAttrOptions<EAttrType.Int>(attrToDraw.groupId, attrToDraw.id, {
        averageData,
      });
    } else {
      const { value, averageData } = addRow<EAttrType.Double>(attrToDraw as TDoubleAttrToDraw);

      checklistsController.changeAttrValue(EAttrType.Double, attrToDraw.groupId, value);
      checklistsController.changeAttrOptions<EAttrType.Double>(attrToDraw.groupId, attrToDraw.id, {
        averageData,
      });
    }
  };

  const handleChangeItemValue = (item: IExtendedAverageValue) => {
    if (attrToDraw.initialModel.attribute.type === EAttrType.Int) {
      const { value, averageData } = changeValues<EAttrType.Int>(attrToDraw as TIntegerAttrToDraw, [
        item,
      ]);

      checklistsController.changeAttrValue(EAttrType.Int, attrToDraw.groupId, value);
      checklistsController.changeAttrOptions<EAttrType.Int>(attrToDraw.groupId, attrToDraw.id, {
        averageData,
      });
    } else {
      const { value, averageData } = changeValues<EAttrType.Double>(
        attrToDraw as TDoubleAttrToDraw,
        [item]
      );

      checklistsController.changeAttrValue(EAttrType.Double, attrToDraw.groupId, value);
      checklistsController.changeAttrOptions<EAttrType.Double>(attrToDraw.groupId, attrToDraw.id, {
        averageData,
      });
    }
  };

  return (
    <Styled.Wrapper>
      {attrToDraw.options.averageData.rowList.map((row, index) => (
        <AverageAttributeItemsBodyRow
          key={row.id}
          inputNumberType={attrToDraw.initialModel.attribute.type === 'INT' ? 'integer' : 'double'}
          actionType={
            index === attrToDraw.options.averageData.rowList.length - 1 ? 'add' : 'delete'
          }
          row={row}
          onChangeItemValue={handleChangeItemValue}
          onAddRowClick={handleAddRowClick}
          onDeleteRowClick={handleDeleteRow}
          isBlocked={attrToDraw.isBlocked}
        />
      ))}
    </Styled.Wrapper>
  );
};

AverageAttributeItemsBody.displayName = 'AverageAttributeItemsBody';

export default observer(AverageAttributeItemsBody);
