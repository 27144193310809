import { FC } from 'react';
import { ButtonProps } from '@farmlink/farmik-ui';

import { ButtonInner, Wrapper } from './style';

type CustomButtonProps = {
  children: any;
  onClick: () => void;
  disabled?: boolean;
  dataTestId?: string;
  variant: ButtonProps['styleType'];
  color: ButtonProps['color'];
};

export const CustomButton: FC<CustomButtonProps> = ({
  children,
  variant,
  color,
  onClick,
  disabled,
  dataTestId,
}) => {
  return (
    <Wrapper>
      <ButtonInner
        styleType={variant}
        color={color}
        onClick={onClick}
        type={'button'}
        disabled={disabled}
        data-test-id={dataTestId}
      >
        {children}
      </ButtonInner>
    </Wrapper>
  );
};
