import { chunk } from 'lodash';

import {
  ITableFiltersBuilderTechSizesByScreen as ITechSizesByScreen,
  ITableFiltersBuilderFilter as IFilter,
  ITableFiltersBuilderPreset as IPreset,
} from '../../../models/data';
import { TableFiltersBuilderStore as Store } from '../../stores';
import { sortBy } from '../../../utils/helpers/sort';
import {
  ETableFiltersBuilderRowSizeByScreen as ERowSizeByScreen,
  TABLE_FILTERS_BUILDER_CONTROLS_KEY as CONTROLS_KEY,
  TABLE_FILTERS_BUILDER_DEFAULT_FILTER_SIZE as DEFAULT_FILTER_SIZE,
  TABLE_FILTERS_BUILDER_SUPPORTED_SCREEN_LIST as SUPPORTED_SCREEN_LIST,
} from '../../../constants';
import { TTableFiltersBuilderSupportedScreen as TSupportedScreen } from '../../../types/screens';
import { lazyInject, provide } from '../../../../../utils/IoC';

@provide.singleton()
class TableFiltersBuilderGridService<F = any> {
  @lazyInject(Store)
  protected store: Store<F>;

  /**
   * Создает объект, со списком ключей каждого экрана, для составления сетки элементов.
   * @param builderId — уникальный идентификатор фильтров;
   * @param key — ключ области, где будет располагаться сетка.
   */
  getTechSizesByScreen = (builderId: string, key: 'filters' | 'presets'): ITechSizesByScreen => {
    const groupedAreas = this.getGroupedAreasByKey(builderId, key);

    const initial: ITechSizesByScreen = {
      s1920: [],
      s1366: [],
      s1024: [],
    };

    const techSizesByScreen = SUPPORTED_SCREEN_LIST.reduce((acc, screen) => {
      acc[screen] = this.getAreasList(groupedAreas[screen], screen);

      return acc;
    }, initial);

    return techSizesByScreen;
  };

  /**
   * Создает предварительный объект, со списком ключей каждого экрана, согласно переданному ключу области.
   * @param builderId — уникальный идентификатор фильтров;
   * @param key — ключ области, где будет располагаться сетка.
   */
  protected getGroupedAreasByKey = (
    builderId: string,
    key: 'filters' | 'presets'
  ): ITechSizesByScreen => {
    switch (key) {
      case 'filters':
        return this.getTempFiltersAreasByScreen(builderId);
      case 'presets':
        return this.getTempPresetsAreasByScreen(builderId);
      default:
    }
  };

  /**
   * Создает двумерный массив, из списка ключей элементов, под необходимый экран.
   * @param tempAreaList — предварительный список ключей элементов для конкретного экрана;
   * @param screen — разрешение экрана.
   */
  protected getAreasList = (tempAreaList: string[], screen: TSupportedScreen): string[] => {
    const rowSize = ERowSizeByScreen[screen.toUpperCase()] as number;

    const groupedAreaList = chunk(tempAreaList, rowSize);

    const lastAreaList = groupedAreaList[groupedAreaList.length - 1];

    if (lastAreaList.length === rowSize) {
      const systemAreaList = this.addControlsKeyToAreaList([], rowSize);

      return this.formatAreasList([...groupedAreaList, systemAreaList]);
    } else {
      const areaListWithControls = this.addControlsKeyToAreaList(lastAreaList, rowSize);

      return this.formatAreasList([...groupedAreaList.slice(0, -1), areaListWithControls]);
    }
  };

  /**
   * Создает предварительный объект, со списком ключей каждого экрана, для пресетов.
   * @param builderId — уникальный идентификатор фильтров;
   */
  protected getTempPresetsAreasByScreen = (builderId: string): ITechSizesByScreen => {
    const presetList = this.store.getPresetList(builderId);
    const orderedList = sortBy(presetList);

    const initial: ITechSizesByScreen = {
      s1920: [],
      s1366: [],
      s1024: [],
    };

    const tempPresetsAreas = SUPPORTED_SCREEN_LIST.reduce((acc, screen) => {
      acc[screen] = this.getTempPresetsAreaList(orderedList);

      return acc;
    }, initial);

    return tempPresetsAreas;
  };

  /**
   * Получает полный список ключей пресетов для сетки.
   * @param presetList — список пресетов на основе конфигов.
   */
  protected getTempPresetsAreaList = (presetList: IPreset<F>[]): string[] => {
    const areaList = presetList.map(preset => preset.id);

    return areaList;
  };

  /**
   * Создает предварительный объект, со списком ключей каждого экрана, для фильтров.
   * @param builderId — уникальный идентификатор фильтров;
   */
  protected getTempFiltersAreasByScreen = (builderId: string): ITechSizesByScreen => {
    const filterList = this.store.getFilterList(builderId, {
      isOnlyVisible: true,
    });
    const orderedList = sortBy(filterList);

    const initial: ITechSizesByScreen = {
      s1920: [],
      s1366: [],
      s1024: [],
    };

    const tempPresetsAreas = SUPPORTED_SCREEN_LIST.reduce((acc, screen) => {
      acc[screen] = this.getTempFiltersAreaList(orderedList, screen);

      return acc;
    }, initial);

    return tempPresetsAreas;
  };

  /**
   * Получает полный список ключей фильтров для сетки,
   * отформатированный на основе конфига каждого из фильтров.
   * @param filterList — список фильтров на основе конфигов.
   * @param screen — разрешение экрана.
   */
  protected getTempFiltersAreaList = (
    filterList: IFilter<F>[],
    screen: TSupportedScreen
  ): string[] => {
    const areaList = filterList.flatMap<string>(filter => {
      const size = filter?.sizesByScreen?.[screen] || DEFAULT_FILTER_SIZE;

      return Array(size).fill(filter.id as string);
    });

    return areaList;
  };

  /**
   * Добавляет ключ кнопок управления в необходимый список ключей фильтров для сетки.
   * @param areaList — список ключей фильтров для сетки.
   * @param rowSize — размер ряда на основе ширины экрана.
   */
  protected addControlsKeyToAreaList = (
    areaList: string[],
    rowSize: ERowSizeByScreen
  ): string[] => {
    const amountToFill = rowSize - 1 - areaList.length;
    const filledAreaList = this.fillAreaListByEmptyValues(areaList, amountToFill);

    const formattedAreaList = [...filledAreaList, CONTROLS_KEY];

    return formattedAreaList;
  };

  /**
   * Заполняет список заглушками для сетки.
   * @param areaList — список ключей фильтров для сетки.
   * @param amount — количество заглушек (точек) для добавления.
   */
  protected fillAreaListByEmptyValues = (areaList: string[], amount: number): string[] => {
    const newAreaList: string[] = [];

    for (let i = 0; i < amount; i++) {
      newAreaList.push('.');
    }

    return [...areaList, ...newAreaList];
  };

  /**
   * Приводит технический массив к списку, который может отрисовать CSS Grid.
   * @param areasList — двумерный массив из списка ключей фильтров для составления сетки.
   */
  protected formatAreasList = (areasList: string[][]): string[] => {
    return areasList.map(areaList => areaList.join(' '));
  };
}

export default TableFiltersBuilderGridService;
