import { TypeApiRoute } from '../../models/type.api.request';
import { EIndexType } from '../../models/indices.model';
import { CultureFillEnum } from '../../../modules/dashboard/constants/culture.fill.enum';

type TypeRequest = {
  fieldId: string;
  indexName?: CultureFillEnum;
  seasonYear?: number;
  status?: EIndexType[];
};

type TypeResponse = number[];

export const getIndicesYearList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/agro/indices/fetchYears`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
