import { FC, memo } from 'react';

import { InputFieldErrorItem } from './components';
import Styled from './InputFieldError.styles';
import { TInputFieldErrorProps } from './InputFieldError.types';

const InputFieldError: FC<TInputFieldErrorProps> = ({ error: { errorList, options } }) => {
  return (
    <Styled.Wrapper $maxWidth={options?.maxWidth}>
      {!options?.isDoNotShowErrorText
        ? errorList.map(text => <InputFieldErrorItem key={text} text={text} />)
        : null}
    </Styled.Wrapper>
  );
};

export default memo(InputFieldError);
