import { TypeApiRoute } from '../../models/type.api.request';
import { TypeUserRole } from '../../models/user.model';

type TypeRequest = {
  role: TypeUserRole;
  employeeId?: string;
  organizationId: string;
};

type TypeResponse = {};

export const updateUserRole: TypeApiRoute & { request: TypeRequest; response: TypeResponse } = {
  url: (request: TypeRequest) => `/api/da-profile/organizations/user/update/${request.employeeId}`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
