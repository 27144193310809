import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Button, useModal } from '@farmlink/farmik-ui';
import _ from 'lodash';

import { useStore } from '../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { FieldsController } from '../../controllers/fields.controller';
import { FieldsRoute } from '../../fields.route';
import { FieldsStore } from '../../stores/fields.store';
import { Footer, Header, Label, StyledLabelWrapper, Wrapper } from '../../style';
import { ButtonWrapper } from '../add/style';
import { FieldsImportController } from '../../controllers/import';
import { FieldsImportStore } from '../../stores/import';
import { EditableFieldItem, FieldErrorNotification } from '../../components';
import { Field } from '../../../../../../api/models/field.model';
import MapStore from '../../../../../map/stores/map.store';
import { TChangeImportedFieldReq } from '../../../../../../api/api';
import { EFieldsImportModalName } from '../../modals/import/configs/fieldsImportModalsConfig';
import { ButtonLoader } from '../../../../../shared/components/ButtonLoader/ButtonLoader';
import { SessionStore } from '../../../../../authorization/stores/session.store';

import Styled from './FieldsImportContainer.styles';

const FieldsImportContainer: FC = observer(() => {
  const sessionStore = useStore(SessionStore);
  const { logout } = sessionStore;

  const organizationsStore = useStore(OrganizationsStore);
  const { selectedOrganizationId } = organizationsStore;

  const fieldsStore = useStore(FieldsStore);
  const { editableField, fields } = fieldsStore;

  const fieldsImportStore = useStore(FieldsImportStore);
  const {
    focusTargetId,
    isFieldFocused,
    isWaitingForEditRes,
    saveErrorModal,
    setIsFieldFocused,
    setIsWaitingForEditRes,
    clearSaveErrorModal,
  } = fieldsImportStore;

  const mapStore = useStore(MapStore);
  const { getEditableLayerGeometry } = mapStore;

  const fieldsImportController = useStore(FieldsImportController);
  const {
    changeFieldName,
    deleteImportedField,
    displayImportedFields,
    clearStoreAfterLeavePage,
    saveImportedFieldList,
    changeImportedField,
  } = fieldsImportController;

  const fieldsController = useStore(FieldsController);
  const { enableImportedFieldEditing } = fieldsController;

  const navigate = useNavigate();

  const { closeModal, openModalByModalId } = useModal();

  const importedFieldNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!focusTargetId) {
      return;
    }

    if (importedFieldNameRef?.current?.id === `field-card-id-${focusTargetId}`) {
      importedFieldNameRef.current.focus();

      setIsFieldFocused(false);
    }
  }, [isFieldFocused]);

  const throttledRequestToChangeField = useRef(
    _.throttle((changedData: TChangeImportedFieldReq) => changeImportedField(changedData), 3000)
  );

  const fieldsListingRoute = useMemo<string>(() => {
    return generatePath(FieldsRoute.Listing, {
      orgId: selectedOrganizationId,
    });
  }, [selectedOrganizationId]);

  useEffect(() => {
    if (editableField) {
      const { id, name } = editableField;

      const changedData: TChangeImportedFieldReq = { id, name };

      throttledRequestToChangeField.current(changedData);
    }
  }, [editableField]);

  useEffect(() => {
    if (editableField && getEditableLayerGeometry(editableField?.polyId)) {
      const { id } = editableField;

      setIsWaitingForEditRes(true);

      const geometry = getEditableLayerGeometry(editableField?.polyId);

      const changedData: TChangeImportedFieldReq = {
        id,
        geoJson: {
          type: 'Feature',
          geometry,
        },
      };

      throttledRequestToChangeField.current(changedData);
    }
  }, [getEditableLayerGeometry(editableField?.polyId)]);

  useEffect(() => {
    displayImportedFields();
    closeModal();

    return () => {
      clearStoreAfterLeavePage();
    };
  }, []);

  useEffect(() => {
    if (saveErrorModal === EFieldsImportModalName.FileImport) {
      openModalByModalId(EFieldsImportModalName.FileImport, { isSomethingWentWrong: true });
      clearSaveErrorModal();

      navigate(fieldsListingRoute);
    } else if (saveErrorModal === EFieldsImportModalName.NotAuthorized) {
      logout();
    } else if (saveErrorModal) {
      openModalByModalId(saveErrorModal);
      clearSaveErrorModal();
    }
  }, [saveErrorModal]);

  const handleSave = async (): Promise<void> => {
    const res = await saveImportedFieldList();

    if (res === EFieldsImportModalName._Success) {
      closeModal();
      navigate(fieldsListingRoute);
    }
  };

  const handleClose = async (): Promise<void> => {
    await clearStoreAfterLeavePage();
    navigate(fieldsListingRoute);
  };

  const handleDeleteImportedField = useCallback(async (field: Field): Promise<void> => {
    await deleteImportedField(field);
  }, []);

  const handleFieldNameChange = useCallback((field: Field, value: string): void => {
    changeFieldName(field, value);
  }, []);

  const totalCountOfIncorrectFields = useMemo<number>(() => {
    return fields.reduce<Field[]>((incorrectFieldList, field) => {
      if (field?.error) {
        incorrectFieldList.push(field);
      }

      return incorrectFieldList;
    }, [])?.length;
  }, [fields]);

  const displayedImportedFiledList = useMemo<Field[]>(() => {
    const filteredFields = fields.filter(field => field?.isImported);

    return _.sortBy(filteredFields, 'errorType');
  }, [fields]);

  return (
    <Wrapper data-test-id={'fields-import-section'}>
      <Header>
        <StyledLabelWrapper>
          <Label data-test-id={'fields-import-section-label'}>Добавление полей</Label>
        </StyledLabelWrapper>
      </Header>

      <Styled.ListWrapper>
        {totalCountOfIncorrectFields ? (
          <Styled.ErrorNotificationWrapper>
            <FieldErrorNotification totalErrorCount={totalCountOfIncorrectFields} />
          </Styled.ErrorNotificationWrapper>
        ) : null}

        {displayedImportedFiledList.map(field => (
          <EditableFieldItem
            key={field?.id}
            field={field}
            focusTargetId={focusTargetId}
            isSelected={field?.id === editableField?.id}
            onDelete={handleDeleteImportedField}
            onNameChange={handleFieldNameChange}
            onCardClick={enableImportedFieldEditing}
            ref={importedFieldNameRef}
          />
        ))}
      </Styled.ListWrapper>

      <Footer>
        <ButtonWrapper>
          <Button
            type={'button'}
            color={'default'}
            onClick={handleClose}
            dataTestId={'fields-import-cancel'}
          >
            Отменить
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <ButtonLoader disabled={false} isLoading={isWaitingForEditRes} onClick={handleSave}>
            Сохранить
          </ButtonLoader>
        </ButtonWrapper>
      </Footer>
    </Wrapper>
  );
});

export default FieldsImportContainer;
