import { Dropdown } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { TDropdownConfig } from '../../../../../../../../shared/components/inputs/Dropdown/Dropdown.types';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { Container } from '../../../../../components/Container';
import { ChecklistInstancesController } from '../../../../../controllers/checklist.instances.controller';
import {
  ChecklistInstancesStore,
  EChecklistMode,
} from '../../../../../stores/checklist.instances.store';
import { DEFAULT_INTENSITY_OPTION } from '../../../../../utils/checklist.instances';
import { useTasksParams } from '../../../../../../tasks/hooks';
import {
  ECheckListInstanceType,
  IGetChecklistInstance,
} from '../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { TaskStore } from '../../../../../../tasks/mobx/stores';
import { EChecklistType } from '../../../../../../../../../api/models/checklist/checklist.model';
import { TypeApiRequest } from '../../../../../../../../shared/utils/axios2';
import { ChecklistsStore } from '../../../../../../tasks/modules/Checklists/mobx/stores';

export const getTypeForChecklist = (inst: IGetChecklistInstance): EChecklistType => {
  switch (inst.type) {
    case ECheckListInstanceType.PlannedPoint:
      return EChecklistType.Point;
    case ECheckListInstanceType.ActualPoint:
      return EChecklistType.Point;
    case ECheckListInstanceType.Field:
      return EChecklistType.Field;
    case ECheckListInstanceType.Machinery:
      return EChecklistType.Machinery;
    default:
  }
};

const Intensity: FC = () => {
  const taskStore = useStore(TaskStore);
  const checklistsStore = useStore(ChecklistsStore);

  const { selectedIntensity, intensityModalResult, selectedInstance } = useStore(
    ChecklistInstancesStore
  );

  const {
    intensityOptionList,
    intensityDefaultValue,
    fetchIntensityByTaskId,
    warnBeforeChangingIntensity,
    selectIntensity,
  } = useStore(ChecklistInstancesController);

  const params = useTasksParams();

  const correctOrgId = params.orgId === 'my' ? '' : params.orgId;

  useEffect(() => {
    (async () => {
      if (!taskStore.selectedTask?.intensityRequired) return;

      await fetchIntensityByTaskId({
        taskId: params.taskId,
        checkListType: getTypeForChecklist(selectedInstance),
      });
    })();
  }, []);

  const handleIntensityChange = useCallback<TDropdownConfig['field']['onChange']>(
    (intensityId: string): void => {
      const payload: TypeApiRequest<'getChecklistByKeys'> = {
        organizationId: correctOrgId,
        operationTypeId: taskStore.selectedTask?.operationInfo?.operationTypeInfo?.id,
        intensityId,
        cultureId: taskStore?.selectedTask?.operationInfo?.cultureId,
        type: getTypeForChecklist(selectedInstance),
      };

      if (selectedIntensity) {
        warnBeforeChangingIntensity(payload);
      } else {
        selectIntensity(payload);
      }
    },
    [taskStore.selectedTask, selectedIntensity, selectedInstance]
  );

  const dropdownConfig: TDropdownConfig = {
    field: {
      onChange: handleIntensityChange,
      defaultValue: intensityDefaultValue,
      icons: {
        clear: {
          options: {
            valueAfterCleaning: DEFAULT_INTENSITY_OPTION,
          },
        },
      },
    },
    body: {
      optionList: intensityOptionList,
    },
    visual: {
      label: 'Выбранная фенофаза',
      isBlocked: checklistsStore.mode === EChecklistMode.View,
    },
    validation: {
      modal: {
        isShowWarning: Boolean(selectedIntensity),
        modalResult: intensityModalResult,
      },
    },
  };

  return (
    <>
      {taskStore?.selectedTask?.intensityRequired && (
        <Container title="Фенофаза">
          <StyledWrapper>
            <Dropdown config={dropdownConfig} />
          </StyledWrapper>
        </Container>
      )}
    </>
  );
};

const StyledWrapper = styled.div`
  flex: 1 1 70%;
  margin: 0 8px;
`;

export default observer(Intensity);
