import {
  Task,
  TaskStatuses,
} from '../../../modules/dashboard/modules/operations/stores/tasks.store';
import { TypeApiRoute } from '../../models/type.api.request';

// todo ОБязательно заполнить типы на запросы
type TypeRequest = {};

type TypeResponse = {};

export const createTask_oldMethod: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/task/`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
