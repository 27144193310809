import { makeAutoObservable } from 'mobx';
import { isArray } from 'lodash';

import { provide } from '../../../../../../shared/utils/IoC';
import { OperationCulture } from '../../../../../../../api/models/operations/operation.culture';
import { OperationField } from '../../../../../../../api/models/operations/operation.field.model';
import { ISelectOption } from '../../../../../../../types/selectOption';
import { Operation } from '../../../../../../../api/models/operations/operation.model';
import { TypeUser } from '../../../../../../../api/models/user.model';
import {
  ITask,
  ITaskCreate,
  ITaskPreview,
  TTaskUpdate,
} from '../../../../../../../api/models/as-fields/task/task.model';
import { ETaskTabs, ICultureZoneExtendedByField } from '../../../models';
import { Field } from '../../../../../../../api/models/field.model';
import { ITaskEvent } from '../../../../../../../api/models/task/events/task.event.mode';

type TMode = 'view' | 'create-one' | 'create-multiple' | 'edit';
type TFromPage = 'operations' | 'tasks';
type TTasksCreationStep = 'preview' | 'view-created';

export const toDouble = (value: string | number): string => {
  if (isNaN(Number(value))) {
    return;
  }

  return Number(value).toFixed(2);
};

/**
 * TODO: Временное расположение здесь. Нужно вынести функцию в библиотеку и использовать везде для названия.
 */
export const createZoneName = (name: string, area: number): string => {
  if (name) {
    return `${name}`;
  } else {
    return `Поле ${toDouble(area)} га`;
  }
};

@provide.singleton()
class TaskStore {
  private _mode: TMode = 'view';

  private _fromPage: TFromPage | null = null;

  private _taskTab: ETaskTabs = null;

  private _selectedTask: ITask | null = null;

  private _selectedField: Field | null = null;

  private _taskCreate: ITaskCreate | null = null;

  private _taskUpdate: Partial<TTaskUpdate> = {};

  // Коллекции полученных моделей с бэка
  private _idToOperationCulture: Map<string, OperationCulture> = new Map<
    string,
    OperationCulture
  >();

  private _idToOperationField: Map<string, OperationField> = new Map<string, OperationField>();

  private _idToOperation: Map<string, Operation> = new Map<string, Operation>();

  private _idToUser: Map<string, TypeUser> = new Map<string, TypeUser>();

  // Опции для дропдаунов
  private _valueToCultureOption: Map<string, ISelectOption> = new Map<string, ISelectOption>();

  private _valueToOperationOption: Map<string, ISelectOption<Operation>> = new Map<
    string,
    ISelectOption<Operation>
  >();

  private _fieldOptionsByValue: Map<string, ISelectOption> = new Map<string, ISelectOption>();

  private _valueToUserOption: Map<string, ISelectOption> = new Map<string, ISelectOption>();

  private _extendedCultureZonesById: Map<string, ICultureZoneExtendedByField> = new Map<
    string,
    ICultureZoneExtendedByField
  >();

  private _isFormChanged = false;

  private _previewTasksById: Map<string, ITaskPreview> = new Map();

  private _createdTasksById: Map<string, ITask> = new Map();

  private _tasksCreationStep: TTasksCreationStep | null = null;

  private _taskEventList: ITaskEvent[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get mode() {
    return this._mode;
  }

  get fromPage() {
    return this._fromPage;
  }

  get taskTab() {
    return this._taskTab;
  }

  get selectedTask() {
    return this._selectedTask;
  }

  get selectedField() {
    return this._selectedField;
  }

  get taskCreate() {
    return this._taskCreate;
  }

  get taskUpdate() {
    return this._taskUpdate;
  }

  get operationList(): Operation[] {
    return Array.from(this._idToOperation.values());
  }

  // Получение списка опций
  get cultureOptionList(): ISelectOption[] {
    return Array.from(this._valueToCultureOption.values());
  }

  getCultureOption = (value: string): ISelectOption => {
    return this._valueToCultureOption.get(value);
  };

  get operationOptionList(): ISelectOption<Operation>[] {
    return Array.from(this._valueToOperationOption.values());
  }

  getOperationOption = (value: string): ISelectOption<Operation> => {
    return this._valueToOperationOption.get(value);
  };

  get fieldOptionList(): ISelectOption[] {
    return [...this._fieldOptionsByValue.values()];
  }

  getFieldOption = (value: string): ISelectOption => {
    return this._fieldOptionsByValue.get(value);
  };

  get userOptionList(): ISelectOption[] {
    return Array.from(this._valueToUserOption.values());
  }

  getUserOption = (value: string): ISelectOption => {
    return this._valueToUserOption.get(value);
  };

  // Значения по умолчания для полей
  get cultureDefaultValue(): ISelectOption {
    const cultureId = this.taskCreate?.cultureId || this.selectedTask?.culture?.id;

    return this._valueToCultureOption.get(cultureId);
  }

  get operationDefaultValue(): ISelectOption<Operation>[] {
    const operationId = this.taskCreate?.operationId || this.selectedTask?.operationInfo?.id;
    const foundOption = this._valueToOperationOption.get(operationId);

    if (foundOption) {
      return [foundOption];
    }

    return [];
  }

  get operationFieldDefaultValue(): ISelectOption[] {
    if (!this._selectedTask) {
      return [];
    }

    const selectedField = this._selectedTask.field;
    const selectedZone = this._selectedTask?.cultureZone;

    const fieldName = createZoneName(selectedField?.name, selectedField.area);

    if (selectedZone) {
      const zoneName = createZoneName(selectedZone?.name, selectedZone.area);

      const option: ISelectOption = {
        value: selectedZone.id,
        label: selectedZone?.name
          ? `${fieldName}. ${zoneName}. ${toDouble(selectedZone.area)} га`
          : `${fieldName}. ${toDouble(selectedZone.area)} га`,
      };

      return [option];
    }

    return [
      {
        label: fieldName,
        value: selectedField.id,
      },
    ];
  }

  get userDefaultValue(): ISelectOption {
    const assigneeId =
      this.taskCreate?.assigneeId || this.taskUpdate?.assigneeId || this.selectedTask?.assignee?.id;

    return this._valueToUserOption.get(assigneeId);
  }

  get isTaskChanged(): boolean {
    return Object.keys(this._taskUpdate).length > 0;
  }

  // Режимы
  get isViewMode(): boolean {
    return this._mode === 'view';
  }

  get isEditMode(): boolean {
    return this._mode === 'edit';
  }

  get isCreateOne(): boolean {
    return this._mode === 'create-one';
  }

  get isCreateMultiple(): boolean {
    return this._mode === 'create-multiple';
  }

  get listOfZoneExtendedByField() {
    return [...this._extendedCultureZonesById.values()];
  }

  getZoneExtendedByField = (id: string): ICultureZoneExtendedByField => {
    return this._extendedCultureZonesById.get(id);
  };

  get isFormChanged() {
    return this._isFormChanged;
  }

  get previewTaskList() {
    return [...this._previewTasksById.values()];
  }

  get createdTaskList(): ITask[] {
    return [...this._createdTasksById.values()];
  }

  get tasksCreationSteps(): TTasksCreationStep {
    return this._tasksCreationStep;
  }

  get taskEventList() {
    return this._taskEventList;
  }

  setMode = (mode: TMode): void => {
    this._mode = mode;
  };

  setFromPage = (value: TFromPage): void => {
    this._fromPage = value;
  };

  setTaskTab = (tab: ETaskTabs): void => {
    this._taskTab = tab;
  };

  setSelectedTask = (task: ITask): void => {
    this._selectedTask = task;
  };

  setSelectedField = (field: Field): void => {
    this._selectedField = field;
  };

  setTaskCreateData = (data: Partial<ITaskCreate>): void => {
    this._taskCreate = { ...this._taskCreate, ...data };
  };

  setTaskCreate = (task: ITaskCreate): void => {
    this._taskCreate = task;
  };

  setTaskUpdateData = (data: Partial<TTaskUpdate>): void => {
    this._taskUpdate = { ...this._taskUpdate, ...data };
  };

  setTaskUpdate = (task: TTaskUpdate): void => {
    this._taskUpdate = task;
  };

  setOperationCultureList = (list: OperationCulture[]): void => {
    const newIdToOperationCulture: Map<string, OperationCulture> = new Map<
      string,
      OperationCulture
    >();

    if (isArray(list)) {
      list.forEach(culture => newIdToOperationCulture.set(culture.culture.id, culture));
    }

    this._idToOperationCulture = newIdToOperationCulture;
  };

  setOperationList = (list: Operation[]): void => {
    const newIdToOperation: Map<string, Operation> = new Map<string, Operation>();

    if (isArray(list)) {
      list.forEach(operation => newIdToOperation.set(operation.id, operation));
    }

    this._idToOperation = newIdToOperation;
  };

  setUserList = (userList: TypeUser[]): void => {
    const collection: Map<string, TypeUser> = new Map<string, TypeUser>();

    if (isArray(userList)) {
      userList.forEach(user => collection.set(user.userId, user));
    }

    this._idToUser = collection;
  };

  setCultureOptionList = (optionList: ISelectOption[]): void => {
    const collection: Map<string, ISelectOption> = new Map<string, ISelectOption>();

    if (isArray(optionList)) {
      optionList.forEach(option => collection.set(option.value, option));
    }

    this._valueToCultureOption = collection;
  };

  setOperationOptionList = (optionList: ISelectOption<Operation>[]): void => {
    const collection: Map<string, ISelectOption<Operation>> = new Map<string, ISelectOption>();

    if (isArray(optionList)) {
      optionList.forEach(option => collection.set(option.value, option));
    }

    this._valueToOperationOption = collection;
  };

  setFieldOptionList = (optionList: ISelectOption[]): void => {
    const collection: Map<string, ISelectOption> = new Map<string, ISelectOption>();

    if (isArray(optionList)) {
      optionList.forEach(option => collection.set(option.value, option));
    }

    this._fieldOptionsByValue = collection;
  };

  setUserOptionList = (optionList: ISelectOption[]): void => {
    const collection: Map<string, ISelectOption> = new Map<string, ISelectOption>();

    if (isArray(optionList)) {
      optionList.forEach(option => collection.set(option.value, option));
    }

    this._valueToUserOption = collection;
  };

  setListOfZoneExtendedByField = (
    listOfZoneExtendedByField: ICultureZoneExtendedByField[],
    options?: { isClearPreviousList?: boolean }
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...this._extendedCultureZonesById.entries()];

    const newCollection: Map<string, ICultureZoneExtendedByField> = new Map<
      string,
      ICultureZoneExtendedByField
    >(previousEntryList);

    if (isArray(listOfZoneExtendedByField)) {
      listOfZoneExtendedByField.forEach(zone => {
        newCollection.set(zone.id, zone);
      });
    }

    this._extendedCultureZonesById = newCollection;
  };

  changeSelectedTask = (updatedTask: Partial<ITask>) => {
    this._selectedTask = { ...this._selectedTask, ...updatedTask };
  };

  setIsFormChanged = (value: boolean): void => {
    this._isFormChanged = value;
  };

  setPreviewTaskList = (previewTaskList: ITaskPreview[]): void => {
    previewTaskList.forEach(task => this._previewTasksById.set(task.id, task));
  };

  setCreatedTaskList = (createdTaskList: ITask[]): void => {
    createdTaskList.forEach(task => this._createdTasksById.set(task.id, task));
  };

  setTasksCreationSteps = (mode: TTasksCreationStep): void => {
    this._tasksCreationStep = mode;
  };

  setTaskEventList = (eventList: ITaskEvent[]) => {
    this._taskEventList = eventList;
  };

  clearMode = (): void => {
    this._mode = 'view';
  };

  clearFromPage = (): void => {
    this._fromPage = null;
  };

  clearTaskTab = (): void => {
    this._taskTab = ETaskTabs.Summary;
  };

  clearSelectedTask = (): void => {
    this._selectedTask = null;
  };

  clearSelectedField = (): void => {
    this._selectedField = null;
  };

  clearTaskCreate = (): void => {
    this._taskCreate = null;
  };

  clearTaskUpdate = (): void => {
    this._taskUpdate = {};
  };

  clearIdToOperationCulture = (): void => {
    this._idToOperationCulture.clear();
  };

  clearIdToOperationField = (): void => {
    this._idToOperationField.clear();
  };

  clearIdToOperation = (): void => {
    this._idToOperation.clear();
  };

  clearIdToUser = (): void => {
    this._idToUser.clear();
  };

  clearValueToCultureOption = (): void => {
    this._valueToCultureOption.clear();
  };

  clearValueToOperationOption = (): void => {
    this._valueToOperationOption.clear();
  };

  clearFieldOptionsByValue = (): void => {
    this._fieldOptionsByValue.clear();
  };

  clearValueToUserOption = (): void => {
    this._valueToUserOption.clear();
  };

  clearCultureZoneById = (): void => {
    this._extendedCultureZonesById.clear();
  };

  clearIsFormChanged = (): void => {
    this._isFormChanged = false;
  };

  clearPreviewTasksById = (): void => {
    this._previewTasksById.clear();
  };

  clearCreatedTasksById = (): void => {
    this._createdTasksById.clear();
  };

  clearTasksCreationSteps = (): void => {
    this._tasksCreationStep = null;
  };

  clearTaskEventList = () => {
    this._taskEventList = [];
  };

  clearTaskStore = () => {
    this.clearMode();
    this.clearFromPage();
    this.clearTaskTab();

    this.clearSelectedTask();
    this.clearSelectedField();

    this.clearTaskCreate();
    this.clearTaskUpdate();

    this.clearIdToOperationCulture();
    this.clearIdToOperationField();
    this.clearIdToOperation();
    this.clearIdToUser();

    this.clearValueToCultureOption();
    this.clearValueToOperationOption();
    this.clearFieldOptionsByValue();
    this.clearValueToUserOption();

    this.clearCultureZoneById();

    this.clearIsFormChanged();

    this.clearPreviewTasksById();
    this.clearCreatedTasksById();
    this.clearTasksCreationSteps();

    this.clearTaskEventList();
  };
}

export default TaskStore;
