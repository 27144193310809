import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  gap: 8px;
`;

const Timeline = styled.div`
  width: 100%;
  background: ${Colors.generalWhite};
  border-radius: 12px;
  overflow: hidden;

  & .react-loading-skeleton {
    height: 100%;
    transform: translateY(-3px);
  }
`;

const Calendar = styled.div`
  width: 40px;
  background: ${Colors.generalWhite};
  border-radius: 12px;
  overflow: hidden;

  & .react-loading-skeleton {
    height: 100%;
    transform: translateY(-3px);
  }
`;

const Styled = {
  Wrapper,
  Timeline,
  Calendar,
};

export default Styled;
