import styled from 'styled-components';

const MapSkeleton = styled.div`
  width: 100%;
  height: 415px;
  border-radius: 16px;
`;

const Styled = {
  MapSkeleton,
};

export default Styled;
