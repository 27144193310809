export const createCultureZoneEventName = 'map.create.culture.zone.event';

export const createCultureZoneEvent = (geometry: any) => {
  const event = new CustomEvent(createCultureZoneEventName, {
    detail: {
      geometry,
    },
  });
  window.dispatchEvent(event);
};
