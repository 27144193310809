import _ from 'lodash';

import { Field } from '../../../api/models/field.model';
import { EImportedFieldErrorType } from '../../../api/models/fields/getImportedField.model';
import { PopupPages } from '../../dashboard/constants/popup.pages';
import { FieldsStore } from '../../dashboard/modules/fields/stores/fields.store';
import { FieldsImportStore } from '../../dashboard/modules/fields/stores/import';
import { UiStore } from '../../dashboard/stores/ui.store';
import { lazyInject, provide } from '../../shared/utils/IoC';
import { RenderPolygonOption } from '../consts/enum.render.option';
import { editPolygon } from '../events/edit.polygon.event';
import MapStore, { MapMode } from '../stores/map.store';
import { getIdOfIntersectingLayers, hasTooBigAreaInHectares } from '../utils/helpers';

@provide.singleton()
export class MapController {
  @lazyInject(MapStore)
  protected mapStore: MapStore;

  @lazyInject(UiStore)
  protected uiStore: UiStore;

  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(FieldsImportStore)
  protected fieldsImportStore: FieldsImportStore;

  get isImportMode() {
    return this.mapStore.mapMode === MapMode.Import;
  }

  removeLayout = (id: number) => {
    if (this.uiStore.popupPageState === PopupPages.Seasons) return;

    try {
      const layout = this.mapStore.getLayout(id);

      if (layout) {
        layout.remove();
        this.mapStore.deleteLayout(id);
      }
    } catch (err) {
      throw new Error(`не полуается удалить layout с ${id}`);
    }
  };

  handleEditLayer = (event: any): void => {
    const {
      setIsShapeFinished,
      setEditableLayerGeometry,
      addMarkersToSet,
      reRenderLable,
    } = this.mapStore;

    const { target } = event;

    addMarkersToSet(target.pm.markerCache);
    reRenderLable(event.target);

    const leafletId = target._leaflet_id;
    const geoJson = target.toGeoJSON();

    setIsShapeFinished(false);

    editPolygon(geoJson.geometry, leafletId);

    setEditableLayerGeometry(leafletId, geoJson.geometry);
  };

  removeErrorFromField = (polyId: number): void => {
    const { getFieldByPolyId, setField } = this.fieldsStore;

    const field = getFieldByPolyId(polyId);

    const changedField = _.omit(field, ['error', 'errorType', 'errorDescription']);

    setField(changedField);
  };

  addIntersectionErrorToTheField = (polyId: number): void => {
    const { getFieldByPolyId, setField } = this.fieldsStore;

    const field = getFieldByPolyId(polyId);

    const changedField: Field = {
      ...field,
      error: true,
      errorType: EImportedFieldErrorType.Intersection,
      errorDescription: 'intersection with existing field',
    };

    setField(changedField);
  };

  changeColorOfPatchedLayers = (
    listOfPatchedLayersId: number[],
    theWholeListOfLayers: any[]
  ): void => {
    const { changeColorToNormal } = this.mapStore;

    listOfPatchedLayersId.forEach(id => {
      theWholeListOfLayers.forEach(layer => {
        if (id === layer._leaflet_id) {
          const listOfIntersectingLayerId = getIdOfIntersectingLayers(layer, theWholeListOfLayers);

          if (!listOfIntersectingLayerId.length) {
            changeColorToNormal(RenderPolygonOption.View, id);

            if (this.isImportMode) {
              this.removeErrorFromField(id);
            }
          }
        }
      });
    });
  };

  changeColorOfCorrectLayer = (correctLayerId: number, theWholeListOfLayers: any[]): void => {
    const { changeColorToNormal } = this.mapStore;

    theWholeListOfLayers.forEach(layer => {
      if (correctLayerId === layer._leaflet_id) {
        changeColorToNormal(RenderPolygonOption.View, correctLayerId);

        if (this.isImportMode) {
          this.removeErrorFromField(correctLayerId);
        }
      }
    });
  };

  checkForIntersectionsEditableLayer = (event: any, layer?: any): void => {
    const {
      editableLayerList,
      layerList,
      listOfPrevIncorrectLayerId,
      setCollectionOfPrevIncorrectLayerId,
      showErrorInPolygon,
    } = this.mapStore;

    const selectedEditableLayer = event?.target || layer;

    // Получаем слои полей
    const theWholeListOfLayers = [...editableLayerList, ...layerList];

    // Инициализируем коллекцию для уникальных айдишников слоев
    const collectionOfIntersectingLayerId: Set<number> = new Set<number>();

    // Проверяем размер редактируемого поля
    if (hasTooBigAreaInHectares(selectedEditableLayer)) {
      collectionOfIntersectingLayerId.add(selectedEditableLayer._leaflet_id);
    }

    // Выявляем все пересечения с полем
    const tempListOfIntersectingLayerId = getIdOfIntersectingLayers(
      selectedEditableLayer,
      theWholeListOfLayers
    );

    if (tempListOfIntersectingLayerId.length) {
      tempListOfIntersectingLayerId.forEach(id => collectionOfIntersectingLayerId.add(id));
    }

    const listOfIntersectingLayerId = Array.from(collectionOfIntersectingLayerId);
    const hasIntersections = Boolean(listOfIntersectingLayerId.length);

    /**
     * Получаем айдишники слоев, которые были исправлены
     * (нужно для окрашивания их в дефолтный цвет)
     */
    const listOfPatchedLayersId = _.without(
      listOfPrevIncorrectLayerId,
      ...listOfIntersectingLayerId
    );
    const hasPatchedLayers = Boolean(listOfPatchedLayersId.length);

    /**
     * Если это первое исправление слоя в рамках данной сессии и оно оказалось
     * успешным за одно действие, то приводим его окрас в дефолтное состояние
     */
    if (!hasIntersections && !hasPatchedLayers) {
      this.changeColorOfCorrectLayer(selectedEditableLayer._leaflet_id, theWholeListOfLayers);
    }

    // Приводим исправленные слои к дефолтному цвету
    if (hasPatchedLayers) {
      this.changeColorOfPatchedLayers(listOfPatchedLayersId, theWholeListOfLayers);
    }

    // Окрашиваем пересекающиеся поля в красный цвет
    if (hasIntersections) {
      listOfIntersectingLayerId.forEach(id => {
        showErrorInPolygon(id);

        if (this.isImportMode) {
          this.addIntersectionErrorToTheField(id);
        }
      });
    }

    /**
     * Закидываем в стор коллекцию айдишников пересекающихся полей
     * для следующей проверки
     */
    setCollectionOfPrevIncorrectLayerId(collectionOfIntersectingLayerId);
  };

  enableLayerEditing = (layerId: number): void => {
    const { getEditableLayer, centerMapToPoint } = this.mapStore;

    const layer = getEditableLayer(layerId);

    if (layer) {
      layer.on('pm:edit', this.handleEditLayer);
      layer.on('pm:markerdragend', this.checkForIntersectionsEditableLayer);
      layer.on('click', this.handleClickOnLayerToFocusId);

      layer.pm.enable({ preventMarkerRemoval: true });

      centerMapToPoint(layerId);
    }
  };

  disableLayerEditing = (layerId: number): void => {
    const { getEditableLayer } = this.mapStore;

    const layer = getEditableLayer(layerId);

    if (layer) {
      layer.off('pm:edit', this.handleEditLayer);
      layer.off('pm:markerdragend', this.checkForIntersectionsEditableLayer);

      layer.pm.disable();
    }
  };

  handleClickOnLayerToFocusId = (event): void => {
    const { centerMapToPoint } = this.mapStore;
    const { setFocusTargetId, setIsFieldFocused } = this.fieldsImportStore;
    const { getFieldByPolyId } = this.fieldsStore;

    const layerId = event?.target?._leaflet_id;
    const field = getFieldByPolyId(layerId);

    if (field) {
      setFocusTargetId(field.id);
      setIsFieldFocused(true);
      centerMapToPoint(layerId);
    }
  };

  setCultureZoneOutOfFieldError = (value: boolean) => {
    this.mapStore.prevCultureZoneOutOfFieldError = this.mapStore.cultureZoneOutOfFieldError;
    this.mapStore.cultureZoneOutOfFieldError = value;
  };
}
