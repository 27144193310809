import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/type.response.pageable';
import { OperationType } from '../../models/operations/operation.type.model';

type TypeRequest = {
  name?: string;
  cultureId?: string;
  noCulture?: boolean;
};

type TypeResponse = { content: Array<OperationType> } & TypeResponsePageable;

export const getOperationsType: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/operation/type/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
