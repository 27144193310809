import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { LatLng, PM } from 'leaflet';
import { cloneDeep } from 'lodash';
import { distance } from '@turf/turf';
import { ENotificationType, useNotificator } from '@farmlink/farmik-ui';

import MapStore, { MapMode } from '../../../../stores/map.store';
import { CultureZoneStore } from '../../../../../dashboard/components/PopupSlider/components/CultureZone/stores/culture.zone.store';
import { validateCoordinates } from '../../../../../shared/utils/helpers/validateCoordinates';
import { EMapValidationError } from '../../../../../shared/constants/map/map.constants';

const usePointCoordinatesUpdate = ({ map, zone }: { map: MapStore; zone: CultureZoneStore }) => {
  const {
    instance,
    currentPointPosition,
    currentPointLayerIndex,
    currentLayer,
    mapMode,
    clearFocusMarker,
    checkCrossingOfFields,
  } = map;

  const [isExpanded, setIsExpanded] = useState(false);
  const [newCoords, setNewCoords] = useState('');
  const [error, setError] = useState('');
  const [referenceCords, setReferenceCords] = useState(null);

  const { setNotification } = useNotificator();

  const coordinates = useMemo(
    () =>
      currentPointPosition &&
      `${Number(currentPointPosition.lat).toFixed(7)}, ${Number(currentPointPosition.lng).toFixed(
        7
      )}`,
    [currentPointPosition]
  );

  const isDisplayCoordinatesBlock = useMemo(
    () =>
      instance &&
      (mapMode === MapMode.Editing ||
        mapMode === MapMode.CZEditing ||
        mapMode === MapMode.Creating),
    [mapMode, instance]
  );

  useEffect(() => {
    setIsExpanded(false);
    setNewCoords('');
  }, [isDisplayCoordinatesBlock]);

  const onChangeCoordinates = (value: string) => {
    setError(null);
    setNewCoords(value);
  };

  const isExpandDisabled = !currentPointPosition;

  useEffect(() => {
    setNewCoords(coordinates);
  }, [coordinates, currentLayer]);

  useEffect(() => {
    setError('');
  }, [currentPointLayerIndex, currentPointPosition]);

  const expandChangeCord = useCallback(() => {
    setNewCoords(coordinates);
    setIsExpanded(true);

    if (!referenceCords) {
      setReferenceCords(coordinates);
    }
  }, [coordinates, referenceCords]);

  const shrinkChangeCord = useCallback(() => {
    // setNewCoords(referenceCords);
    // updateCord();
    setIsExpanded(false);

    setNewCoords('');
    setError(null);
    setReferenceCords(null);
  }, [referenceCords]);

  const sendNotification = (message: string) => {
    setNotification({
      message,
      style: { placement: 'top-right', type: ENotificationType.Warning },
    });
  };

  const updateCord = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (!currentLayer) {
        return;
      }

      try {
        validateCoordinates(newCoords);

        // @ts-ignore
        const layerCords: LatLng[][] = currentLayer.getLatLngs();

        const parsedNewCords = newCoords.split(', ').map(item => Number(item));
        const startCords = coordinates.split(', ').map(item => Number(item));

        const distanceBetweenCords = distance(parsedNewCords, startCords, { units: 'kilometers' });

        // Проверка валидности отдалённости
        if (distanceBetweenCords >= 1) {
          throw new Error('Перенос координаты более чем на 1 км');
        }

        const newLatLng = new LatLng(parsedNewCords[0], parsedNewCords[1]);

        const previousCords = cloneDeep(layerCords);

        layerCords[currentPointLayerIndex[0]][currentPointLayerIndex[1]] = newLatLng;

        if (mapMode === MapMode.CZEditing) {
          const leafletId = (currentLayer as any)._leaflet_id;
          const geoJson = cloneDeep(currentLayer as any)
            .setLatLngs(layerCords)
            .toGeoJSON();

          // Проверка валидности КЗ
          const validationError = zone.validateEditCultureZone(geoJson, leafletId, {
            isPreventUndo: true,
          });

          if (validationError) {
            (currentLayer as any).setLatLngs(previousCords);
            setError(validationError);

            switch (validationError) {
              case EMapValidationError.OutOfField:
                return sendNotification('Культурная зона выходит за границы поля');

              default:
                return sendNotification('Имеются пересечения культурных зон');
            }
          }
        }

        // @ts-ignore
        currentLayer.setLatLngs(layerCords); // установка вертекса полигона

        // Проверка самопересечения
        if ((currentLayer as any).pm.hasSelfIntersection()) {
          (currentLayer as any).setLatLngs(previousCords);
          const errorMessage = 'Пересечение собственного контура';

          setError(errorMessage);

          sendNotification(errorMessage);

          return;
        }

        ((currentLayer as any).pm._markers[currentPointLayerIndex[0]][
          currentPointLayerIndex[1]
        ] as L.Marker).setLatLng(newLatLng); // подтягивание маркера редактирования к вертексу

        // @ts-ignore
        currentLayer.redraw();

        setIsExpanded(false);

        if (
          mapMode === MapMode.Creating ||
          mapMode === MapMode.Editing ||
          mapMode === MapMode.CZEditing
        ) {
          checkCrossingOfFields();
        }
      } catch (err) {
        sendNotification(err?.message || 'Непредвиденная ошибка валидации');
        setError('unknown');
      }
    },
    [currentLayer, currentPointLayerIndex, coordinates, newCoords, mapMode, zone]
  );

  return {
    isExpanded,
    isDisplayCoordinatesBlock,
    newCoords,
    onChangeCoordinates,
    coordinates,
    expandChangeCord,
    shrinkChangeCord,
    clearFocusMarker,
    updateCord,
    error,
    isExpandDisabled,
  };
};

export default usePointCoordinatesUpdate;
