import { useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, memo, useCallback, useRef, ChangeEvent, useState, useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import { FieldsImportController } from '../../../../controllers/import';
import { FieldsRoute } from '../../../../fields.route';
import { FieldsImportStore } from '../../../../stores/import';
import { EFieldsUploadErrorType } from '../../../../utils/constants/import';
import { getFileExtension } from '../../../../utils/helpers/import';
import { EFieldsImportModalName } from '../../configs/fieldsImportModalsConfig';

import {
  FieldsImportFileImportModal,
  FieldsImportLoader,
  FieldsImportSomethingWentWrongModal,
  FieldsImportSuccessModal,
  FieldsImportWarningModal,
  FieldsImportWrongFormatModal,
} from './components';
import Styled from './FieldsImportModalContainer.styles';
import { useImportFields } from './hooks';

export enum EFieldsImportContainerRenderModalName {
  FileImport = 'fileImport',
  WrongFormat = 'wrongFormat',
  Loader = 'loader',
  Success = 'success',
  SomethingWentWrong = 'SomethingWentWrong',
  TooBigFileSize = 'TooBigFileSize',
  TooManyFields = 'TooManyFields',
}

export enum EFieldsImportFileExtension {
  Kml = 'kml',
  GeoJSON = 'geojson',
  Zip = 'zip',
  Shape = 'shp',
  Cpg = 'cpg',
  Prj = 'prj',
  Qmd = 'qmd',
  Shx = 'shx',
  Dbf = 'dbf',
}

const SHAPE_FILE_EXTENSIONS: string[] = [
  EFieldsImportFileExtension.Shape,
  EFieldsImportFileExtension.Cpg,
  EFieldsImportFileExtension.Prj,
  EFieldsImportFileExtension.Qmd,
  EFieldsImportFileExtension.Shx,
];

const AVAILABLE_FILE_EXTENSIONS: string[] = [
  EFieldsImportFileExtension.Kml,
  EFieldsImportFileExtension.GeoJSON,
  EFieldsImportFileExtension.Zip,
  EFieldsImportFileExtension.Shape,
  EFieldsImportFileExtension.Cpg,
  EFieldsImportFileExtension.Prj,
  EFieldsImportFileExtension.Qmd,
  EFieldsImportFileExtension.Shx,
  EFieldsImportFileExtension.Dbf,
];

const FieldsImportModalContainer: FC = observer(() => {
  const organizationsStore = useStore(OrganizationsStore);
  const { selectedOrganizationId } = organizationsStore;

  const fieldsImportStore = useStore(FieldsImportStore);
  const { listOfImportedField } = fieldsImportStore;

  const fieldsImportController = useStore(FieldsImportController);
  const { importFieldsFromShape, fetchImportedList } = fieldsImportController;

  const [
    renderModalName,
    setRenderModalName,
  ] = useState<EFieldsImportContainerRenderModalName | null>(null);

  const { importFields } = useImportFields(setRenderModalName);

  const { closeModal, getModalPayload, openModalByModalId } = useModal();
  const modalPayload = getModalPayload();

  useEffect(() => {
    if (modalPayload?.isSomethingWentWrong) {
      setRenderModalName(EFieldsImportContainerRenderModalName.SomethingWentWrong);
    } else {
      setRenderModalName(EFieldsImportContainerRenderModalName.FileImport);
    }
  }, modalPayload?.isSomethingWentWrong);

  const navigate = useNavigate();

  const fileUploaderRef = useRef<HTMLInputElement>(null);

  const handleFileUploaderClick = useCallback((): void => {
    fileUploaderRef?.current?.click();
  }, [fileUploaderRef]);

  const continueClick = useCallback((): void => {
    const routePath = generatePath(FieldsRoute.Import, {
      orgId: selectedOrganizationId,
    });

    openModalByModalId(EFieldsImportModalName.Loader);

    navigate(routePath);
  }, [selectedOrganizationId]);

  const handleImportFieldsFromShape = useCallback(async (formData: FormData): Promise<void> => {
    setRenderModalName(EFieldsImportContainerRenderModalName.Loader);

    const importShapeRes = await importFieldsFromShape(formData);

    if (!importShapeRes) {
      setRenderModalName(EFieldsImportContainerRenderModalName.SomethingWentWrong);

      return;
    }

    const { token, errorType } = importShapeRes;

    if (errorType) {
      if (errorType === EFieldsUploadErrorType.FieldsCount) {
        setRenderModalName(EFieldsImportContainerRenderModalName.TooManyFields);

        return;
      }

      setRenderModalName(EFieldsImportContainerRenderModalName.SomethingWentWrong);

      return;
    }

    const isSuccess = await fetchImportedList(token);

    if (isSuccess) {
      setRenderModalName(EFieldsImportContainerRenderModalName.Success);
    } else {
      setRenderModalName(EFieldsImportContainerRenderModalName.SomethingWentWrong);
    }
  }, []);

  const handleFileUploaderChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
      const fileList = event.target.files;

      if (!fileList.length) {
        return;
      }

      const tooBigFileList: File[] = [];
      const listOfFileWithIncorrectExtension: File[] = [];
      const fileExtensionList: string[] = [];

      Array.from(fileList).forEach(file => {
        const fileExtension = getFileExtension(file);

        const isAllowedThisFileByExtension = AVAILABLE_FILE_EXTENSIONS.includes(fileExtension);

        if (!isAllowedThisFileByExtension) {
          listOfFileWithIncorrectExtension.push(file);
        }

        if (file.size > 1e7) {
          tooBigFileList.push(file);
        }

        fileExtensionList.push(fileExtension);
      });

      if (listOfFileWithIncorrectExtension.length) {
        setRenderModalName(EFieldsImportContainerRenderModalName.WrongFormat);

        return;
      }

      if (tooBigFileList.length) {
        setRenderModalName(EFieldsImportContainerRenderModalName.TooBigFileSize);

        return;
      }

      if (fileExtensionList.includes(EFieldsImportFileExtension.Kml)) {
        await importFields(EFieldsImportFileExtension.Kml, fileList);

        return;
      }

      if (fileExtensionList.includes(EFieldsImportFileExtension.GeoJSON)) {
        await importFields(EFieldsImportFileExtension.GeoJSON, fileList);

        return;
      }

      if (fileExtensionList.includes(EFieldsImportFileExtension.Zip)) {
        await importFields(EFieldsImportFileExtension.Zip, fileList);

        return;
      }

      const shapeFormData = new FormData();

      Array.from(fileList).forEach(file => {
        shapeFormData.append('files', file);
      });

      await handleImportFieldsFromShape(shapeFormData);
    },
    []
  );

  return (
    <>
      {renderModalName === EFieldsImportContainerRenderModalName.FileImport ? (
        <FieldsImportFileImportModal
          onFileUploaderClick={handleFileUploaderClick}
          closeModal={closeModal}
        />
      ) : null}

      {renderModalName === EFieldsImportContainerRenderModalName.WrongFormat ? (
        <FieldsImportWrongFormatModal
          onFileUploaderClick={handleFileUploaderClick}
          closeModal={() => {
            closeModal();
            setRenderModalName(null);
          }}
        />
      ) : null}

      {renderModalName === EFieldsImportContainerRenderModalName.Loader ? (
        <FieldsImportLoader />
      ) : null}

      {renderModalName === EFieldsImportContainerRenderModalName.Success ? (
        <FieldsImportSuccessModal
          addedFieldsCount={listOfImportedField?.length}
          continueClick={continueClick}
        />
      ) : null}

      {renderModalName === EFieldsImportContainerRenderModalName.SomethingWentWrong ? (
        <FieldsImportSomethingWentWrongModal closeModal={closeModal} />
      ) : null}

      {renderModalName === EFieldsImportContainerRenderModalName.TooBigFileSize ? (
        <FieldsImportWarningModal
          title={
            'Размер файла превышает допустимые 50 МБ. Уменьшите файл и загрузите его ещё раз или отрисуйте контуры вручную'
          }
          successButtonConfig={{
            color: 'primary',
            title: 'Понятно ',
            handler: closeModal,
          }}
        />
      ) : null}

      {renderModalName === EFieldsImportContainerRenderModalName.TooManyFields ? (
        <FieldsImportWarningModal
          title={
            'Автоматическая загрузка не сработала, так как файл содержит слишком много полей. Обратитесь в службу поддержки, \n' +
            'и мы поможем внести данные '
          }
          successButtonConfig={{
            color: 'default',
            title: 'Закрыть ',
            handler: closeModal,
          }}
          denyButtonConfig={{
            color: 'primary',
            title: 'Обратиться в поддержку',
            handler: () => {
              setRenderModalName(EFieldsImportContainerRenderModalName.FileImport);
            },
          }}
        />
      ) : null}

      <Styled.Input
        type="file"
        name="file"
        multiple
        ref={fileUploaderRef}
        onChange={handleFileUploaderChange}
        accept=".kml, .geojson, .shp, .zip, .cpg, .prj, .qmd, .shx, .dbf"
      />
    </>
  );
});

FieldsImportModalContainer.displayName = 'FieldsImportModalContainer';

export default memo(FieldsImportModalContainer);
