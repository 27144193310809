import { FC, memo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { ITask } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { useDataTestId } from '../../../../../../../../shared/features/utils/hooks/locators';
import { useShowTooltip } from '../../../../../../../../shared/features/utils/hooks/tooltip';

import Styled from './TasksListOperationCell.styles';

interface IProps {
  row: ITask;
}

const TasksListOperationCell: FC<IProps> = ({ row }) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLSpanElement>();

  const operationName = row?.operationInfo?.name;
  const picUrl = row?.operationInfo?.operationTypeInfo?.attrs?.picUrl;

  const getDataTestId = useDataTestId('tasks-table-operation-cell');

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <Styled.Icon $url={picUrl} {...getDataTestId('icon')} />

      <AutoTooltip content={operationName || '—'} disabled={!isShowTooltip} position={'bottom'}>
        <Styled.Name ref={ref} {...getDataTestId('name')}>
          {operationName || '—'}
        </Styled.Name>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

TasksListOperationCell.displayName = 'TasksListOperationCell';

export default memo(TasksListOperationCell);
