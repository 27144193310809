import 'leaflet.gridlayer.googlemutant';
import { makeAutoObservable } from 'mobx';
import L, { gridLayer } from 'leaflet';
import { isArray } from 'lodash';

import { provide } from '../../../../../utils/IoC';
import {
  IMapCreateMarkersOptions as ICreateMarkersOptions,
  IMapExtendedPolygon as IExtendedPolygon,
  IMapGlobalConfig as IGlobalConfig,
  IMapMarker as IMarker,
  IMapPolygon as IPolygon,
  IMapSetListOptions as ISetListOptions,
} from '../../../models';

@provide.singleton()
class MapStore {
  private _globalConfig: IGlobalConfig | null = null;

  private _instance: L.Map | null = null;

  private _selectedPolygon: IExtendedPolygon | null = null;

  private _createMarkersOptions: ICreateMarkersOptions | null = null;

  private _polygonsById: Map<number, IPolygon> = new Map<number, IPolygon>();

  private _markersById: Map<number, IMarker> = new Map<number, IMarker>();

  constructor() {
    makeAutoObservable(this);
  }

  get globalConfig() {
    return this._globalConfig;
  }

  get instance() {
    return this._instance;
  }

  get selectedPolygon() {
    return this._selectedPolygon;
  }

  get createMarkersOptions() {
    return this._createMarkersOptions;
  }

  get polygonList() {
    return [...this._polygonsById.values()];
  }

  getPolygon = (id: number): IPolygon => {
    return this._polygonsById.get(id);
  };

  get markerList() {
    return [...this._markersById.values()];
  }

  getMarker = (id: number): IMarker => {
    return this._markersById.get(id);
  };

  setGlobalConfig = (globalConfig: IGlobalConfig): void => {
    this._globalConfig = globalConfig;
  };

  setInstance = (instance: L.Map): void => {
    if (!instance) {
      return;
    }

    // @ts-ignore
    window.leafletMap = instance;
    this._instance = instance;

    // @ts-ignore
    gridLayer.googleMutant({ type: 'hybrid' }).addTo(instance);
    instance.attributionControl.setPrefix('');
  };

  setSelectedPolygon = (polygon: IExtendedPolygon): void => {
    this._selectedPolygon = polygon;
  };

  setCreateMarkersOptions = (options: Partial<ICreateMarkersOptions>): void => {
    this._createMarkersOptions = { ...(this._createMarkersOptions || {}), ...options };
  };

  setPolygon = (polygon: IPolygon): void => {
    this._polygonsById.set(polygon.id, polygon);
  };

  setPolygonList = (polygonList: IPolygon[], options?: ISetListOptions): void => {
    const previousEntryList = options?.isClearPreviousList ? [] : [...this._polygonsById.entries()];

    const newCollection: Map<number, IPolygon> = new Map<number, IPolygon>(previousEntryList);

    if (isArray(polygonList)) {
      polygonList.forEach(polygon => {
        newCollection.set(polygon.id, polygon);
      });
    }

    this._polygonsById = newCollection;
  };

  setMarker = (marker: IMarker): void => {
    this._markersById.set(marker.id, marker);
  };

  setMarkerList = (markerList: IMarker[], options?: ISetListOptions): void => {
    const previousEntryList = options?.isClearPreviousList ? [] : [...this._markersById.entries()];

    const newCollection: Map<number, IMarker> = new Map<number, IMarker>(previousEntryList);

    if (isArray(markerList)) {
      markerList.forEach(marker => newCollection.set(marker.id, marker));
    }

    this._markersById = newCollection;
  };

  updateGlobalConfig = (config: IGlobalConfig) => {
    this.setGlobalConfig({ ...this._globalConfig, ...config });
  };

  deletePolygon = (id: number): void => {
    this._polygonsById.delete(id);
  };

  deleteMarker = (id: number): void => {
    this._markersById.delete(id);
  };

  clearGlobalConfig = (): void => {
    this._globalConfig = null;
  };

  clearInstance = (): void => {
    this._instance = null;
  };

  clearSelectedPolygon = (): void => {
    this._selectedPolygon = null;
  };

  clearCreateMarkersOptions = (): void => {
    this._createMarkersOptions = null;
  };

  clearPolygonsById = (): void => {
    this._polygonsById.clear();
  };

  clearMarkersById = (): void => {
    this._markersById.clear();
  };

  clearStore = (): void => {
    this.clearGlobalConfig();
    this.clearInstance();
    this.clearSelectedPolygon();
    this.clearCreateMarkersOptions();

    this.clearPolygonsById();
    this.clearMarkersById();
  };
}

export default MapStore;
