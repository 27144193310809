import { FC } from 'react';

import { ForecastType } from '../Forecast';
import { WeatherEngToRus } from '../../utils/WeatherEngToRus';
import { WindDirectionEngToRus } from '../../utils/WindDirectionEngToRus';
import { WeatherConditionToIcon } from '../../utils/WeatherConditionToIcon';
import { hasValue } from '../../../shared/utils/helpers';

import {
  Wrapper,
  Title,
  Header,
  Condition,
  ConditionIcon,
  DescriptionLine,
  Temperature,
  TemperatureNight,
  TemperatureAndConditionWrapper,
  Time,
} from './style';

export const ForecastWeekPart: FC<ForecastType> = ({
  feelsLike,
  windGust,
  windDirection,
  pressureMm,
  dew,
  humidity,
  temperature,
  temperatureNight,
  condition,
  conditionNight,
  title,
  hours,
  windSpeed,
  precType,
  sunrise,
  sunset,
}) => {
  const precTypeMap = (id: number) => {
    return ['без осадков', 'дождь', 'дождь со снегом', 'снег', 'град'][Number(id)];
  };

  return (
    <Wrapper data-test-id="forecast-week-component">
      <Header data-test-id="forecast-week-component-header">
        <Title data-test-id="forecast-week-component-header-title">{title}</Title>
        <Time data-test-id="forecast-week-component-header-time">{hours}</Time>
      </Header>
      <TemperatureAndConditionWrapper data-test-id="forecast-week-component-temp-cond-wrapper">
        <ConditionIcon
          src={WeatherConditionToIcon(condition)}
          alt=""
          data-test-id="forecast-week-component-cond-icon"
        />
        <Temperature data-test-id="forecast-week-component-temperature">{temperature}°</Temperature>
        <ConditionIcon
          src={WeatherConditionToIcon(conditionNight, 0)}
          alt=""
          data-test-id="forecast-week-component-cond-icon-night"
        />
        <TemperatureNight data-test-id="forecast-week-component-temperature-night">
          {temperatureNight}°
        </TemperatureNight>
        <Condition data-test-id="forecast-week-component-cond">
          {WeatherEngToRus(condition)}
        </Condition>
      </TemperatureAndConditionWrapper>
      <DescriptionLine>
        <p data-test-id="forecast-week-component-humidity">
          Влажность <span>{humidity} %</span>
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p data-test-id="forecast-week-component-precType">
          Осадки <span>{precTypeMap(Number(precType))}</span>
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p data-test-id="forecast-week-component-pressureMm">
          Давление <span>{pressureMm} мм рт.ст.</span>
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p data-test-id="forecast-week-component-dew">
          Точка росы 
          {hasValue(dew) ? <span>{dew}°С</span> : <span>–</span>}
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p data-test-id="forecast-week-component-windSpeed">
          Скорость ветра <span>{windSpeed} м/с</span>
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p data-test-id="forecast-week-component-windDirection">
          Направление ветра <span>{WindDirectionEngToRus(windDirection)}</span>
        </p>
      </DescriptionLine>
      <DescriptionLine>
        <p data-test-id="forecast-week-component-windGust">
          Порывы ветра <span>{windGust} м/с</span>
        </p>
      </DescriptionLine>
    </Wrapper>
  );
};
