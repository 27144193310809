import { displayModalWindow } from '../../../../modals/config';
import { ModalsStore } from '../../../../modals/store/modals.store';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { addFieldModalUniqueKey, EAddFieldModalName } from '../modals/modalsConfig';

@provide.singleton()
export class FieldsModalService {
  @lazyInject(ModalsStore)
  protected modalsStore: ModalsStore;

  warnBeforeLeavingThePage = (onSuccess?: () => void) => {
    const { setModal } = this.modalsStore;

    const successHandler = () => {
      if (onSuccess) onSuccess();
    };

    setModal(
      addFieldModalUniqueKey,
      displayModalWindow(
        addFieldModalUniqueKey,
        EAddFieldModalName.WarningBeforeLeavingThePage,
        successHandler
      )
    );
  };

  intersectionWarning = () => {
    const { setModal } = this.modalsStore;

    setModal(
      addFieldModalUniqueKey,
      displayModalWindow(addFieldModalUniqueKey, EAddFieldModalName.IntersectionWarning)
    );
  };

  oversizedAreaWarning = () => {
    const { setModal } = this.modalsStore;

    setModal(
      addFieldModalUniqueKey,
      displayModalWindow(addFieldModalUniqueKey, EAddFieldModalName.OversizedAreaWarning)
    );
  };

  unfinishedContourWarning = (onSuccess?: () => void) => {
    const { setModal } = this.modalsStore;

    const successHandler = () => {
      if (onSuccess) onSuccess();
    };

    setModal(
      addFieldModalUniqueKey,
      displayModalWindow(
        addFieldModalUniqueKey,
        EAddFieldModalName.UnfinishedContourWarning,
        successHandler
      )
    );
  };
}
