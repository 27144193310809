import { useCallback } from 'react';

import { SELECT_ALL_OPTION } from '../../constants';

import { TUseDropdownIconHandlers } from './useDropdownIconHandlers.types';

const useDropdownIconHandlers: TUseDropdownIconHandlers = ({ state, setState, props }) => {
  const handleClearClick = useCallback(() => {
    const valueAfterCleaning = props.config.field?.icons?.clear?.options?.valueAfterCleaning;

    const isShowWarning = props.config?.validation?.modal?.isShowWarning;

    props.config.field.onChange('');

    if (isShowWarning) {
      setState({
        temporaryData: {
          onChangeData: {
            option: valueAfterCleaning || null,
          },
        },
      });

      return;
    }

    setState({
      values: {
        ...state.values,
        searchValue: null,
        selectValue: valueAfterCleaning || null,
        selectValueList: [],
      },
      settings: { ...state.settings, isSearchFieldFocused: false, isDropped: false },
      temporaryData: {
        ...state.temporaryData,
        preventSortingValues: [],
      },
    });
  }, [
    state,
    props.config.field?.icons?.clear?.options?.valueAfterCleaning,
    props.config?.validation?.modal,
    props.config.field.onChange,
  ]);

  const handleArrowClick = useCallback(() => {
    setState({
      settings: { ...state.settings, isDropped: !state.settings.isDropped, isFirstOpen: true },
      temporaryData: {
        ...state.temporaryData,
        preventSortingValues: [],
      },
    });
  }, [state]);

  const handleCloseClick = useCallback(
    (value: string) => {
      const isSelectAllOptionSelected = value === SELECT_ALL_OPTION.value;

      if (isSelectAllOptionSelected) {
        props.config.field.onChange('', {
          selectedValueList: [],
          optionList: props.config.body.optionList,
          valuesWithCrumbs: [],
        });

        setState({
          values: {
            ...state.values,
            selectValueList: [],
          },
          settings: {
            ...state.settings,
            isFirstOpen: true,
          },
        });

        return;
      }

      const listOfValWithoutDeleted = [...state.values.selectValueList].filter(
        selectValue => selectValue.value !== value
      );

      props.config.field.onChange('', {
        selectedValueList: listOfValWithoutDeleted,
        optionList: props.config.body.optionList,
        valuesWithCrumbs: listOfValWithoutDeleted.flatMap(valueItem => valueItem.label),
      });

      setState({
        values: {
          ...state.values,
          selectValueList: listOfValWithoutDeleted,
        },
      });
    },
    [state, props]
  );

  const handlers: ReturnType<TUseDropdownIconHandlers> = {
    onClearClick: handleClearClick,
    onArrowClick: handleArrowClick,
    onCloseClick: handleCloseClick,
  };

  return handlers;
};

export default useDropdownIconHandlers;
