import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const StyledChecklistsContent = {
  Wrapper,
};

export default StyledChecklistsContent;
