import styled from 'styled-components';
import { CSSProperties } from 'react';

const Wrapper = styled.div<{ $style: CSSProperties }>(({ $style }) => ({
  width: '100%',
  height: '100%',
  ...$style,
}));

const Composition = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledMap = {
  Wrapper,
  Composition,
};

export default StyledMap;
