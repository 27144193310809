import { FC } from 'react';
import Popup from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';
import './style.css';
import { ReactComponent as MenuSvg } from '../../../../static/menu.svg';

export const Dropdown: FC = ({ children }) => {
  return (
    <Popup className="menu-dropDown" trigger={<MenuSvg width={16} />} position="right center">
      {children}
    </Popup>
  );
};
