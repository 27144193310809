import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { useModal } from '@farmlink/farmik-ui';

import { laveBeforeSaveModalConfig } from '../../modals/leaveBeforeSaveModal';
import { useStore } from '../../../../../shared/utils/IoC';
import { TaskController } from '../../mobx/controllers';
import { TaskStore } from '../../mobx/stores';
import { useTasksParams } from '../../hooks';
import { noChecklistFoundModalConfig } from '../../modals/noChecklistFoundModal';
const TaskCreate: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);

  const modalActions = useModal();
  const params = useTasksParams();

  useEffect(() => {
    (async () => {
      modalActions.registerModalList(
        [laveBeforeSaveModalConfig, noChecklistFoundModalConfig],
        'taskModals'
      );

      await taskController.fetchDataToCreateTask(params.orgId);
    })();

    return () => {
      taskStore.clearTaskStore();
    };
  }, []);

  return <Outlet />;
};

TaskCreate.displayName = 'TaskCreate';

export default observer(TaskCreate);
