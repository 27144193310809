import { TypeApiRoute } from '../../models/type.api.request';
import { Operation } from '../../models/operations/operation.model';

type TypeRequest = {
  operationId: string;
  operationTypeId: string;
  startDate: string;
  endDate: string;
  seasonYear: number;
  name: string;
  cultureId: string;
};

export const editOperation: TypeApiRoute & {
  request: TypeRequest;
  response: Operation;
} = {
  url: request => `/api/as-fields/operation/${request.operationId}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as Operation,
};
