import { Button, ButtonProps, useSidebar } from '@farmlink/farmik-ui';
import { FC, memo, useEffect } from 'react';

import SuccessSvg from '../../../dashboard/static/success.svg';
import WarningSvg from '../../../dashboard/static/warning.svg';

import * as Styled from './styles';

export enum EModalType {
  Warning = 'WARNING',
  Success = 'SUCCESS',
  Custom = 'CUSTOM',
}

interface IModalButton {
  title: string;
  color?: ButtonProps['color'];
  disabled?: boolean;
  handler?: () => void;
  dataTestId?: string;
}

export interface IModal {
  name: string;
  title: string;
  type: EModalType;
  successButton?: IModalButton;
  denyButton?: IModalButton;
  dataTestId?: string;
}

type PropsModal = {
  modal: IModal;
  uniqueKey: string;
  closeModalHandler: () => void;
} & Styled.PropsStyledModal;

const Modal: FC<PropsModal> = ({
  modal: { title, type, successButton, denyButton, dataTestId },
  uniqueKey,
  closeModalHandler,
  ...styled
}) => {
  const { setInBackground } = useSidebar();

  useEffect(() => {
    setInBackground(true);

    return () => {
      setInBackground(false);
    };
  }, []);

  const denyButtonHandler = async (): Promise<void> => {
    await denyButton?.handler?.();
    closeModalHandler();
  };

  const successButtonHandler = async (): Promise<void> => {
    await successButton?.handler?.();
    closeModalHandler();
  };

  return (
    <Styled.Overlay>
      <Styled.Modal {...styled} data-test-id={dataTestId ? dataTestId : `${uniqueKey}-modal`}>
        {type === EModalType.Success ? (
          <Styled.MarkImg src={SuccessSvg} />
        ) : (
          <Styled.MarkImg src={WarningSvg} />
        )}
        <Styled.LabelTitle>{title}</Styled.LabelTitle>
        <Styled.ButtonActionWrapper>
          {denyButton && (
            <Button
              color={denyButton?.color || 'default'}
              type={'button'}
              disabled={denyButton?.disabled}
              dataTestId={
                denyButton.dataTestId ? denyButton.dataTestId : `${uniqueKey}-modal-deny-button`
              }
              onClick={denyButtonHandler}
            >
              {denyButton.title}
            </Button>
          )}
          {successButton && (
            <Button
              color={successButton?.color || 'primary'}
              type={'button'}
              disabled={successButton?.disabled}
              dataTestId={
                successButton.dataTestId
                  ? successButton.dataTestId
                  : `${uniqueKey}-modal-accept-button`
              }
              onClick={successButtonHandler}
            >
              {successButton.title}
            </Button>
          )}
        </Styled.ButtonActionWrapper>
      </Styled.Modal>
    </Styled.Overlay>
  );
};

export default memo(Modal);
