import { IGetImportedField } from '../../../models/fields/getImportedField.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TGetImportedFieldListReq = {
  token: string;
  size?: number;
  page?: number;
  viewportCoordinates?: number[];
};

type TGetImportedFieldListRes = {
  content: IGetImportedField[];
};

const getImportedFieldList: TypeApiRoute & {
  request: TGetImportedFieldListReq;
  response: TGetImportedFieldListRes;
} = {
  url: () => '/api/as-fields/import/list',
  method: 'GET',
  headers: {},
  request: {} as TGetImportedFieldListReq,
  response: {} as TGetImportedFieldListRes,
};

export type { TGetImportedFieldListReq, TGetImportedFieldListRes };

export { getImportedFieldList };
