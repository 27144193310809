import { observer } from 'mobx-react';
import { FC, memo } from 'react';

import { useStore } from '../../../../../../shared/utils/IoC';
import { ChecklistInstancesStore } from '../../../stores/checklist.instances.store';
import { ListOfChecklistInstance } from '../ListOfChecklistInstance';
import { useTasksRouteActions } from '../../../../tasks/hooks';

interface IProps {
  taskId: string;
}

const ChecklistPointInstanceListContainer: FC<IProps> = ({ taskId }) => {
  const { listOfDrawPointInst } = useStore(ChecklistInstancesStore);

  return (
    <ListOfChecklistInstance
      mode={'listing'}
      type={'point'}
      title={'Точки осмотра'}
      description={
        'Перейдите в полноэкранный шаблон создания задачи, чтобы построить удобный маршрут по полю'
      }
      instList={listOfDrawPointInst}
      variant="drawer"
    />
  );
};

ChecklistPointInstanceListContainer.displayName = 'ChecklistPointInstanceListContainer';

export default memo(observer(ChecklistPointInstanceListContainer));
