import { makeAutoObservable } from 'mobx';
import { throttle } from 'lodash';
import { Portal } from 'react-portal-hook';

import { lazyInject, provide } from '../../shared/utils/IoC';
import { PopupPages } from '../constants/popup.pages';
import MapStore from '../../map/stores/map.store';
import { RenderPolygonOption } from '../../map/consts/enum.render.option';
import { ProfileStore } from '../modules/profile/stores/ProfileStore';
import { CultureFillEnum } from '../constants/culture.fill.enum';

type FunctionFromType = 'sowing/harvest' | 'none';

export enum ESidebarWidth {
  SHORT = '62px',
  LARGE = '196px',
}

@provide.singleton()
export class UiStore {
  @lazyInject(ProfileStore)
  profile: ProfileStore;

  @lazyInject(MapStore)
  map: MapStore;

  constructor() {
    makeAutoObservable(this);
    setTimeout(() => {
      this.pageWidth = document.documentElement.clientWidth;
      this.pageHeight = document.documentElement.clientHeight;
      const vh = this.pageHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 0);
    window.addEventListener('resize', throttle(this.handleResize, 300));
  }

  renderPolygonsCallback: any = null;
  fetchPolygonsCallback: any = null;
  showFullWeather = false;
  showPopupSlider = false;
  currentPortal: Portal = null;

  pageWidth = 1920;
  pageHeight = 1080;

  handleResize = () => {
    this.pageWidth = document.documentElement.clientWidth;
    this.pageHeight = document.documentElement.clientHeight;
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  get isAdaptive() {
    return this.pageWidth >= 1024 && this.pageWidth <= 1365;
  }

  get isAdaptiveSidebarVisible() {
    return this.pageWidth >= 1024 && this.pageWidth <= 1365 && !this.isSidebarShort;
  }

  setFullWeatherMode = (isFull: boolean) => {
    this.showFullWeather = isFull;
  };

  setShowPopupSlider = (show: boolean) => {
    this.showPopupSlider = show;
  };

  popupPageState: PopupPages = PopupPages.None;

  setFunctionFireFrom = (v: FunctionFromType) => {
    this.functionFireForm = v;
  };

  functionFireForm: FunctionFromType = 'none';

  setPageState = (
    v: PopupPages,
    isIgnoreMapCLear?: boolean,
    isIgnoreFetchPolygons?: boolean
  ): void => {
    // eslint-disable-next-line
    console.log('setPageState shit', v);
    if (this.map.instance) {
      setTimeout(() => {
        if (this.map.instance) {
          this.map.instance.invalidateSize();
        }
      }, 300);
    }
    if (v === PopupPages.CultureZone || v === PopupPages.None) {
      if (!isIgnoreMapCLear) {
        this.map.clearAll();
      }
    }

    if (v === PopupPages.Main) {
      this.map.clearAll();
      this.renderPolygonsCallback(RenderPolygonOption.View);
    }

    if (
      v === PopupPages.None &&
      this.profile.user &&
      !isIgnoreFetchPolygons &&
      this.fetchPolygonsCallback
    ) {
      this.fetchPolygonsCallback(RenderPolygonOption.View);
    }

    if (
      v === PopupPages.None &&
      this.profile.user &&
      this.renderPolygonsCallback &&
      isIgnoreFetchPolygons
    ) {
      this.renderPolygonsCallback(RenderPolygonOption.View, undefined, CultureFillEnum.Fill);
    }

    this.popupPageState = v;
  };

  setPopupPageState = (state: PopupPages) => {
    this.setPageState(state);
    // this.popupPageState = state;
  };

  isSidebarShort = true;

  setIsSidebarShort = (v: boolean) => {
    this.isSidebarShort = v;
    localStorage.setItem('sidebarShort', v ? 'true' : 'false');
  };

  sidebarShortState = () => localStorage.getItem('sidebarShort') === 'true';

  tipContainerRef = null;
  tipLocation = {
    x: 0,
    y: 0,
  };

  setContainerRef = (ref: any) => {
    this.tipContainerRef = ref;
  };

  contextMenuCloseFunc: () => void;

  setContextMenuCloseFunc = (func: () => void) => {
    this.contextMenuCloseFunc = func;
  };

  isTipOpen = false;

  openTip = (x: number, y: number) => {
    this.isTipOpen = true;
    this.tipLocation = {
      x,
      y,
    };
  };

  closeTip = () => {
    this.isTipOpen = false;
  };

  isEditSeasonModalOpen = false;

  setEditSeason = (v: boolean) => {
    this.isEditSeasonModalOpen = v;
  };

  closePortal = () => {
    this.currentPortal.close();
  };

  setPortal = (portal: Portal) => {
    this.currentPortal = portal;
  };

  cleanPortal = () => {
    this.currentPortal = null;
  };
}
