import { TypeApiRoute } from '../../../models/type.api.request';
import { CultureZone } from '../../../models/field.model';

type TGetCultureZoneListReq = {
  organizationId?: string;
  operationId?: string;
  seasonYear?: number;
  cultureId?: string;
  byTasks?: boolean;
  withGeometry?: boolean;
  noCulture?: boolean;
};

type TGetCultureZoneListRes = { content: CultureZone[] };

const getCultureZoneList: TypeApiRoute & {
  request: TGetCultureZoneListReq;
  response: TGetCultureZoneListRes;
} = {
  url: () => `/api/as-fields/fields/cultureZones/list`,
  method: 'GET',
  headers: {},
  request: {} as TGetCultureZoneListReq,
  response: {} as TGetCultureZoneListRes,
};

export type { TGetCultureZoneListReq, TGetCultureZoneListRes };

export { getCultureZoneList };
