import { IGetIntensity } from '../../models/intensity/intensity.model';
import { TypeApiRoute } from '../../models/type.api.request';
import { EChecklistType } from '../../models/checklist/checklist.model';

type TypeRequest = {
  taskId: string;
  checkListType?: EChecklistType;
};

type TypeResponse = IGetIntensity[];

export const getIntensityByTaskId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: ({ taskId }) => `/api/as-fields/intensity/byTask/${taskId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
