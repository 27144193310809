import styled from 'styled-components';

const FiltersWrapper = styled.div`
  flex: 0 0 auto;
`;

const ListWrapper = styled.div`
  flex: 1 1 auto;

  padding: 20px 24px;

  overflow: hidden;
`;

const Wrapper = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledTasks = {
  FiltersWrapper,
  ListWrapper,
  Wrapper,
};

export default StyledTasks;
