import { intersect } from '@turf/turf';
import _ from 'lodash';

const getIdOfIntersectingLayers = (comparableLayer: any, layerList: any[]): number[] => {
  const collectionOfIntersectingLayerId: Set<number> = new Set<number>();

  const comparableLayerId = comparableLayer._leaflet_id;
  const comparableLayerGeoJSON = comparableLayer.toGeoJSON();

  layerList.forEach(layer => {
    const layerId = layer._leaflet_id;
    const layerGeoJSON = layer.toGeoJSON();

    const isExactlyThisLayer = comparableLayerId === layerId;

    if (isExactlyThisLayer) {
      return;
    }

    const haveEqualGeometry = _.isEqual(comparableLayerGeoJSON?.geometry, layerGeoJSON?.geometry);

    if (haveEqualGeometry) {
      collectionOfIntersectingLayerId.add(comparableLayerId);
      collectionOfIntersectingLayerId.add(layerId);
    }

    if (intersect(comparableLayerGeoJSON, layerGeoJSON)) {
      collectionOfIntersectingLayerId.add(comparableLayerId);
      collectionOfIntersectingLayerId.add(layerId);
    }
  });

  return Array.from(collectionOfIntersectingLayerId);
};

export default getIdOfIntersectingLayers;
