import { IChecklistAttributeFormulaFunction as IFunction } from './functions/ChecklistAttributeFormulaFunction';
import { IChecklistAttributeFormulaAttribute as IAttribute } from './attributes/ChecklistAttributeFormulaAttribute';
import { IChecklistAttributeFormulaConstant as IConstant } from './constants/ChecklistAttributeFormulaConstant';
import { IChecklistAttributeFormulaProperty as IProperty } from './properties/ChecklistAttributeFormulaProperty';

export enum EChecklistFormulaType {
  Attribute = 'ATTRIBUTE',
  Const = 'CONST',
  Function = 'FUNCTION',
  Dependency = 'DEPENDENCY',
  Property = 'PROPERTY',
}

type TFormula<FM extends IFunction | IAttribute | IConstant | IProperty> = {
  type: EChecklistFormulaType;
} & FM;

export type TChecklistAttributeFormula<
  T extends EChecklistFormulaType
> = T extends EChecklistFormulaType.Function
  ? TFormula<IFunction>
  : T extends EChecklistFormulaType.Attribute
  ? TFormula<IAttribute>
  : T extends EChecklistFormulaType.Const
  ? TFormula<IConstant>
  : T extends EChecklistFormulaType.Property
  ? TFormula<IProperty>
  : never;

export type TChecklistAttributeFormulaFunction = TChecklistAttributeFormula<EChecklistFormulaType.Function>;
export type TChecklistAttributeFormulaAttribute = TChecklistAttributeFormula<EChecklistFormulaType.Attribute>;
export type TChecklistAttributeFormulaConstant = TChecklistAttributeFormula<EChecklistFormulaType.Const>;
export type TChecklistAttributeFormulaProperty = TChecklistAttributeFormula<EChecklistFormulaType.Property>;
