import moment, { Moment } from 'moment';

const getStartDateDefault = (seasonStartDate, seasonEndDate) => {
  const today = moment(new Date());
  today.set({ hour: 0, minute: 0, second: 0 });
  if (today.isBetween(moment(seasonStartDate), moment(seasonEndDate))) {
    return today;
  } else {
    return moment(seasonStartDate);
  }
};

const getEndDateDefault = (date: Moment, seasonStartDate, seasonEndDate) => {
  const initialDate = moment(date);
  initialDate.add(1, 'week');
  const datePlusWeek = moment(initialDate.format('DD.MM.YYYY'), 'DD.MM.YYYY');

  if (datePlusWeek.isBetween(moment(seasonStartDate), moment(seasonEndDate))) {
    return datePlusWeek;
  } else {
    return moment(seasonEndDate);
  }
};

export { getStartDateDefault, getEndDateDefault };
