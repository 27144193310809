import { TypeApiRoute } from '../../models/type.api.request';
import { Field } from '../../models/field.model';

type TypeRequest = {
  fieldId: string;
};

type TypeResponse = Field;

export const deleteFieldById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/fields/${request.fieldId}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
