import { FC, memo, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../utils/IoC';
import { TableBuilderController as Controller } from '../../../mobx/controllers';
import { useTableBuilderUIContext as useUIContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';

import Styled from './TableBuilderGrid.styles';

const TableBuilderGrid: FC<PropsWithChildren<any>> = ({ children }) => {
  const controller = useStore(Controller);

  const UIContext = useUIContext();

  const columnsWidth = controller.getColumnsWidth(UIContext.builderId);

  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper $columnsWidth={columnsWidth} {...getDataTestId('grid')}>
      {children}
    </Styled.Wrapper>
  );
};

TableBuilderGrid.displayName = 'TableBuilderGrid';

export default memo(observer(TableBuilderGrid));
