import { FC, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

import { ReactComponent as MenuSvg } from '../../../../../../static/menu.svg';
import { Operation } from '../../../../../../api/models/operations/operation.model';
import { OperationsStore } from '../../stores/operations.store';
import { OperationsUIStore } from '../../stores/operations.ui.store';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationField } from '../../../../../../api/models/operations/operation.field.model';
import { OperationFieldsListItem } from '../OperationFieldsListItem';

import { FieldsList } from './style';

type Props = {
  fields: Array<OperationField>;
};

export const OperationFieldsList: FC<Props> = observer(({ fields }) => {
  const store = useStore(OperationsStore);
  const uiStore = useStore(OperationsUIStore);
  const [showField, setShowField] = useState<boolean>(false);

  return (
    <FieldsList data-test-id={'operation-item-fields-list'}>
      {fields.map(field => (
        <OperationFieldsListItem field={field} isActive={field.fieldId === uiStore.selectedMarkId} />
      ))}
    </FieldsList>
  );
});
