import { FC, useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { useLocation, useParams, useNavigate, Outlet } from 'react-router';
import { observer } from 'mobx-react';

import { SeasonsController } from '../../controllers/seasons/seasons.controller';
import { useStore } from '../../../shared/utils/IoC';
import { ProfileStore } from '../profile/stores/ProfileStore';
import { ProfileController } from '../profile/controllers/profile.controller';
import { SeasonsStore } from '../../stores/seasons.store';
import { OrganizationsStore } from '../../stores/organizations.store';
import { EAppSettingsType } from '../../../..';
import {
  MainWrapper,
  MapWrapper,
  MapsControlsWrapper,
} from '../../components/DashboardLayout/style';
import { FullWeatherSlider } from '../../components/FullWeatherSlider';
import { HoleTooltip } from '../../components/HoleTooltip/HoleTooltip';
import { TopPanelControls } from '../../components/TopPanelControls';
import { CreateControls } from '../../../map/components/CreateControls/CreateControls';
import { LegendControls } from '../../components/LegendControls/LegendControls';
import { MapUserControls } from '../../../map/components/MapUserControls/MapUserControls';
import { PopupSlider } from '../../components/PopupSlider';
import { Map } from '../../../map/Map';
import PointCoordinatesControls from '../../../map/components/PointCoordinatesControls/PointCoordinatesControls';

import { FieldsController } from './controllers/fields.controller';
import { FieldsListing } from './containers/listing/fields.listing';
import { FieldsRoute } from './fields.route';

export const FieldsRouter: FC<{ orgId?: string }> = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();

  const { orgId, season, fieldId } = useParams<{
    orgId?: string;
    season?: string;
    fieldId?: string;
  }>();
  const { selectedOrganizationId } = useStore(OrganizationsStore);
  const seasonsController = useStore(SeasonsController);
  const { selectedSeason } = useStore(SeasonsStore);
  const fieldsController = useStore(FieldsController);
  const { isLoadingBySeasonFieldUrl } = useStore(ProfileStore);
  const { setIsLoadingBySeasonFieldsUrl } = useStore(ProfileController);
  const profileController = useStore(ProfileController);

  const isListing =
    Boolean(orgId) &&
    (location.pathname === generatePath(FieldsRoute.Listing, { orgId }) ||
      location.pathname === `${generatePath(FieldsRoute.Listing, { orgId })}/`);

  const isListingWithSeasonAndFieldId =
    Boolean(orgId) &&
    Boolean(season) &&
    Boolean(fieldId) &&
    (location.pathname ===
      generatePath(FieldsRoute.ListingWithSeasonAndFiueldId, { orgId, season, fieldId }) ||
      location.pathname ===
        `${generatePath(FieldsRoute.ListingWithSeasonAndFiueldId, { orgId, season, fieldId })}/`);

  useEffect(() => {
    (async () => {
      if (isLoadingBySeasonFieldUrl && selectedOrganizationId === orgId && season && fieldId) {
        await seasonsController.fetchMySeasons();
        await seasonsController.setSelectedSeason(season);
        profileController.setAppSettings(EAppSettingsType.Season)(season);
        if (fieldsController.hasField(fieldId)) {
          await fieldsController.selectField(fieldId);
        } else {
          navigate(generatePath(FieldsRoute.Listing, { orgId }));
        }

        setIsLoadingBySeasonFieldsUrl(false);
      }
    })();
  }, [selectedOrganizationId]);

  return (
    <>
      <MainWrapper>
        <FieldsListing visible={isListing || isListingWithSeasonAndFieldId} />
        <Outlet />
        <MapsControlsWrapper>
          <FullWeatherSlider />
          <MapWrapper>
            <HoleTooltip />
            <TopPanelControls />
            <Map />
            <CreateControls />
            <LegendControls />
            <MapUserControls />
            <PointCoordinatesControls />
          </MapWrapper>
          <PopupSlider />
        </MapsControlsWrapper>
      </MainWrapper>
    </>
  );
});
