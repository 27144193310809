import { useTableFiltersBuilderUIContext } from '../../context/UI/TableFiltersBuilderUIContext/TableFiltersBuilderUI.context';
import { useDataTestId } from '../../../utils/hooks/locators';

const useTableFiltersBuilderDataTestId = (builderId?: string) => {
  const UIContext = useTableFiltersBuilderUIContext();

  const getDataTestId = useDataTestId(`${builderId ?? UIContext.builderId}-table-filters`);

  return getDataTestId;
};

export default useTableFiltersBuilderDataTestId;
