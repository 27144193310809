import styled from 'styled-components';

const Title = styled.span`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: #151f32;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StyledTaskCreateGroup = {
  Title,
  TitleWrapper,
};

export default StyledTaskCreateGroup;
