import { Axios, TypeApiRequest } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { SeasonsController } from '../../../controllers/seasons/seasons.controller';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { SeasonsStore } from '../../../stores/seasons.store';
import { EditSeasonStore } from '../stores/edit.season.store';
import { lsAppSettingsKey } from '../../../../..';
import { ProfileController } from '../../profile/controllers/profile.controller';

@provide.singleton()
export class EditSeasonController {
  @lazyInject(EditSeasonStore)
  protected editSeasonStore: EditSeasonStore;

  @lazyInject(OrganizationsStore)
  protected organizationStore: OrganizationsStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsController)
  protected seasonsController: SeasonsController;

  @lazyInject(ProfileController)
  protected profileController: ProfileController;

  getAppSettings = (): any => {
    const appSettingsStr = localStorage.getItem(lsAppSettingsKey);
    return appSettingsStr ? JSON.parse(appSettingsStr) : {};
  };

  deleteCurrentSeason = async (): Promise<void> => {
    try {
      let payload: TypeApiRequest<'deleteSeason'> = {
        year: this.editSeasonStore.currentSeason.year,
      };

      if (this.organizationStore.selectedOrganizationId !== 'my') {
        payload = {
          ...payload,
          organizationId: this.organizationStore.selectedOrganizationId,
        };
      }

      await this.axios.api.deleteSeason(payload, { omit: ['organizationId', 'year'] });
      await this.seasonsController.fetchMySeasons();

      const appSettings = this.getAppSettings();
      this.profileController.deleteSeasonFromAppSettings(appSettings);

      if (!Boolean(this.seasonsStore.seasonsOptions.length)) {
        this.seasonsStore.selectedSeason = '';
      }
    } catch (e) {
      throw new Error(`Can't delete season ${this.editSeasonStore.currentSeason.year}`);
    }
  };
}
