import { FC, memo } from 'react';

import Styled from './FieldErrorNotification.styles';
import { TFieldErrorNotificationProps } from './FieldErrorNotification.types';

const FieldErrorNotification: FC<TFieldErrorNotificationProps> = ({ totalErrorCount }) => {
  return (
    <Styled.Wrapper>
      <Styled.Icon />
      <Styled.Title>{`Найдено ошибок: ${totalErrorCount}`}</Styled.Title>
    </Styled.Wrapper>
  );
};

FieldErrorNotification.displayName = 'FieldErrorNotification';

export default memo(FieldErrorNotification);
