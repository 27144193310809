import { FC, memo, useMemo } from 'react';

import { Tooltipster } from '../../../../../../../../../../../shared/components/Tooltipster/Tooltipster';

import Styled from './ChecklistsSeparateFileAttrInput.styles';

interface IProps {
  onClick: () => void;
  label?: string;
  tooltip?: string;
  id?: string;
  isRequired?: boolean;
  isBlocked?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  hasAttachedFiles?: boolean;
}

const ChecklistsSeparateFileAttrInput: FC<IProps> = ({
  onClick,
  label,
  tooltip,
  id,
  isRequired,
  isBlocked,
  isDisabled,
  isError,
  hasAttachedFiles,
}) => {
  const fieldTitle = useMemo(() => {
    if (isBlocked && !hasAttachedFiles) {
      return 'Фотографии не прикреплены';
    }

    return 'Прикрепить фотографии';
  }, [isBlocked, hasAttachedFiles]);

  return (
    <Styled.Wrapper
      $isBlocked={isBlocked}
      $isDisabled={isDisabled}
      $isError={isError}
      data-test-id={'separate-file-link-attribute-wrapper'}
    >
      <Styled.TopBlock>
        {label ? (
          <Styled.LabelWrapper>
            <Styled.Label
              $isRequired={isRequired}
              data-test-id={'separate-file-link-attribute-label'}
            >
              {label}
            </Styled.Label>

            {tooltip && (
              <>
                <Tooltipster
                  id={`tooltip-${id}`}
                  title={tooltip}
                  placement={'top-start'}
                  data-test-id={'separate-file-link-attribute-tooltip'}
                >
                  <Styled.IconInfo />
                </Tooltipster>
              </>
            )}
          </Styled.LabelWrapper>
        ) : null}
      </Styled.TopBlock>

      {!isBlocked || (isBlocked && !hasAttachedFiles) ? (
        <Styled.Field
          onClick={onClick}
          $isDisabled={isBlocked && !hasAttachedFiles}
          data-test-id={'separate-file-link-attribute-field'}
        >
          <Styled.Content>
            <Styled.ClipCin />

            <Styled.ContentText data-test-id={'separate-file-link-attribute-field-title'}>
              {fieldTitle}
            </Styled.ContentText>
          </Styled.Content>
        </Styled.Field>
      ) : null}
    </Styled.Wrapper>
  );
};

ChecklistsSeparateFileAttrInput.displayName = 'ChecklistsSeparateFileAttrInput';

export default memo(ChecklistsSeparateFileAttrInput);
