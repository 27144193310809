import styled from 'styled-components';

import warningSvg from '../../../../../../../../../dashboard/static/warning.svg';

const Icon = styled.div`
  height: 44px;
  width: 50px;

  background: url(${warningSvg});
`;

const IconWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin-bottom: 38px;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  margin-bottom: 36px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const StyledFieldsImportWarningModal = {
  Title,
  Icon,
  IconWrapper,
  Wrapper,
};

export default StyledFieldsImportWarningModal;
