import { observer } from 'mobx-react';

import { DialogModal } from '../../../../components/DialogModal/DialogModal';
import { useStore } from '../../../shared/utils/IoC';
import { LeaveUnitModalStore } from '../../stores/leave.unit.modal.store';

export const LeaveUnitModalDialog = observer(() => {
  const leaveUnitModal = useStore(LeaveUnitModalStore);
  return leaveUnitModal.isShow ? (
    <DialogModal
      status={'warning'}
      title={'Вы уверены, что хотите покинуть экран? Все несохраненные данные будут потеряны'}
      accept={{
        name: 'Да, продолжить',
        handler: leaveUnitModal.actionHandler,
        color: 'primary',
      }}
      cancel={{
        name: 'Нет, отменить',
        handler: () => {
          leaveUnitModal.setIsShow(false);
        },
        color: 'default',
      }}
      size={'medium'}
      paddings={'alerts'}
      dataTestId={'fields-add'}
    />
  ) : null;
});
