import { IGetChecklist } from '../../models/checklist/checklist.model';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  instanceId: string;
  intensityId: string;
};

type TypeResponse = {} & IGetChecklist;

export const getChecklistByInstanceAndIntensity: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: () => `/api/as-fields/checklist/byInstanceAndIntensity`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
