import { TypeApiRoute } from '../../../models/type.api.request';

type TChangeImportedFieldReq = {
  id: string;
  name?: string;
  geoJson?: {
    type: 'Feature';
    geometry: any;
  };
};

type TChangeImportedFieldRes = {};

const changeImportedField: TypeApiRoute & {
  request: TChangeImportedFieldReq;
  response: TChangeImportedFieldRes;
} = {
  url: ({ id }) => `/api/as-fields/import/field/${id}`,
  method: 'PUT',
  headers: {},
  request: {} as TChangeImportedFieldReq,
  response: {} as TChangeImportedFieldRes,
};

export type { TChangeImportedFieldReq, TChangeImportedFieldRes };

export { changeImportedField };
