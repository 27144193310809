import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../../../../../shared/constans/colors';

import { ReactComponent as InfoIcon } from './assets/images/info.svg';
import clipSvg from './assets/images/clip-16x16px.svg';

const IconInfo = styled(InfoIcon)`
  width: 14px;
  height: 14px;
  margin: 0 4px 4px 4px;
  cursor: pointer;
`;

const Label = styled.div<{ $isRequired?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  margin: 0 0 4px;

  color: ${Colors.secondaryDarkGray};

  &:after {
    color: red;
    visibility: ${({ $isRequired }) => ($isRequired ? 'visible' : 'hidden')};
    content: '*';
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TopBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const ClipCin = styled.div`
  width: 16px;
  height: 16px;

  margin-right: 8px;

  background: url(${clipSvg});
`;

const ContentText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: ${Colors.secondaryDarkGray};
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const Field = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  padding: 8px 16px;

  border-radius: 12px;
  border: 1px solid ${Colors.borderGray};

  cursor: pointer;

  &:hover {
    border-color: #bac2d3;
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: auto;
      opacity: 0.5;
    `}
`;

const Wrapper = styled.div<{ $isBlocked?: boolean; $isDisabled?: boolean; $isError?: boolean }>`
  position: relative;
  cursor: pointer;

  ${({ $isError }) =>
    $isError &&
    css`
      & ${Field} {
        border-color: ${Colors.red};
      }
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      & ${Field} {
        pointer-events: none;
        opacity: 0.5;
        cursor: auto;
      }
    `}

  ${({ $isBlocked }) =>
    $isBlocked &&
    css`
      & ${Field} {
        pointer-events: none;
        cursor: auto;
      }
    `}
`;

const StyledChecklistsSeparateFileAttrInput = {
  IconInfo,
  Label,
  LabelWrapper,
  TopBlock,
  ClipCin,
  ContentText,
  Content,
  Field,
  Wrapper,
};

export default StyledChecklistsSeparateFileAttrInput;
