import styled from 'styled-components';
import { NewScrollbar } from '@farmlink/farmik-ui';

const Scrollbar = styled(NewScrollbar)`
  height: 100%;

  & > :not(:last-child) {
    border-bottom: 1px solid #e9e6f0;
  }
`;

const StyledTableBuilderContent = {
  Scrollbar,
};

export default StyledTableBuilderContent;
