import {
  EChecklistFormulaType as EFormulaType,
  TChecklistAttributeFormula as TFormula,
} from '../ChecklistFormulas';

export enum EChecklistAttributeFormulaFunctionReturnType {
  Integer = 'INTEGER',
  Double = 'DOUBLE',
}

export enum EChecklistAttributeFormulaFunctionType {
  /**
   * (a and b).
   */
  AND = 'AND',
  /**
   * (a or b).
   */
  OR = 'OR',
  /**
   * (a xor b).
   */
  XOR = 'XOR',
  /**
   * (a = b).
   */
  EQ = 'EQ',
  /**
   * (a > b).
   */
  GT = 'GT',
  /**
   * (a < b).
   */
  LT = 'LT',
  /**
   * ((a + b +…+n) /n).
   */
  AVG = 'AVG',
  /**
   * (a * b).
   */
  MULT = 'MULT',
  /**
   * (a + b).
   */
  ADD = 'ADD',
  /**
   * (a - b).
   */
  SUBTRACT = 'SUBTRACT',
  /**
   * (a / b).
   */
  DIVISION = 'DIVISION',
  /**
   * (a >= b).
   */
  GOE = 'GOE',
  /**
   * (a <= b).
   */
  LOE = 'LOE',
  /**
   * ((a / b) * 100).
   */
  PERCENT = 'PERCENT',
  /**
   * (a != b).
   */
  NE = 'NE',
  /**
   * (!a).
   */
  NOT = 'NOT',
}

export interface IChecklistAttributeFormulaFunction {
  returnType: EChecklistAttributeFormulaFunctionReturnType;
  functionType: EChecklistAttributeFormulaFunctionType;
  args: TFormula<
    EFormulaType.Function | EFormulaType.Attribute | EFormulaType.Const | EFormulaType.Property
  >[];
}
