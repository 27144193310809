import { ChangeEvent, FC, memo, useMemo } from 'react';
import { isUndefined } from 'lodash';

import { IExtendedAverageValue } from '../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import {
  formatEnteredDoubleValue,
  formatEnteredIntValue,
} from '../../../../../../../../../../../../shared/utils/helpers/formatEnteredValue';

import Styled from './AverageAttributeItem.styles';

interface IProps {
  inputNumberType: 'integer' | 'double';
  item: IExtendedAverageValue;
  onChangeItemValue: (item: IExtendedAverageValue) => void;
  isBlocked?: boolean;
  hasLongValue?: boolean;
}

const AverageAttributeItem: FC<IProps> = ({
  inputNumberType,
  item,
  onChangeItemValue,
  isBlocked,
  hasLongValue,
}) => {
  const isShowClearIcon = useMemo(() => {
    if (isBlocked) {
      return false;
    }

    return item.value !== '';
  }, [item.value, isBlocked]);

  const handleClearClick = (): void => {
    onChangeItemValue({ ...item, value: '' });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const enteredValue = e.target.value;
    const isInteger = inputNumberType === 'integer';

    const formattedValue = isInteger
      ? formatEnteredIntValue(enteredValue)
      : formatEnteredDoubleValue(enteredValue);

    if (!isUndefined(formattedValue)) {
      onChangeItemValue({ ...item, value: formattedValue });
    }
  };

  return (
    <Styled.Wrapper $isBlocked={isBlocked} $hasLongValue={hasLongValue}>
      <Styled.InputWrapper>
        <Styled.Input
          placeholder={inputNumberType === 'double' ? '00,0' : '0'}
          value={item.value}
          maxLength={16}
          onChange={handleChange}
          $isDisabled={item.culled}
          $isBlocked={isBlocked}
        />
      </Styled.InputWrapper>

      {isShowClearIcon ? <Styled.Clear onClick={handleClearClick} /> : null}
      {isBlocked ? <Styled.Minus /> : null}
    </Styled.Wrapper>
  );
};

AverageAttributeItem.displayName = 'AverageAttributeItem';

export default memo(AverageAttributeItem);
