import { TypeApiRoute } from '../../models/type.api.request';
import { SeasonModel } from '../../models/season.model';
import { EditSeasonModel } from '../../models/edit.season.model';

type TypeRequest = {
  organizationId?: string;
  allowStub: boolean;
  year: number;
};

type TypeResponse = EditSeasonModel;

export const getSeasonById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req => `/api/as-fields/seasons/${req.year}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
