import { ECheckListInstanceType } from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';

const isMachinery = (type: ECheckListInstanceType): boolean => {
  return type === ECheckListInstanceType.Machinery;
};

const isPlan = (type: ECheckListInstanceType): boolean => {
  return type === ECheckListInstanceType.PlannedPoint;
};

const isActual = (type: ECheckListInstanceType): boolean => {
  return type === ECheckListInstanceType.ActualPoint;
};

const isField = (type: ECheckListInstanceType): boolean => {
  return type === ECheckListInstanceType.Field;
};

const checklistInstanceHelpers = {
  isMachinery,
  isPlan,
  isActual,
  isField,
};

export default checklistInstanceHelpers;
