const findClosestDateInPast = (dateList: Date[], targetDate: Date) => {
  let closestDate: Date | null = null;
  let closestDateIndex: number | null = null;
  let timeDiff: number = Number.POSITIVE_INFINITY;

  for (let i = 0; i < dateList.length; i++) {
    const diff: number = targetDate.getTime() - dateList[i].getTime();

    if (diff > 0) {
      const currentDiff: number = Math.abs(diff);
      if (currentDiff < timeDiff) {
        closestDate = dateList[i];
        closestDateIndex = i;
        timeDiff = currentDiff;
      }
    }
  }

  return { closestDate, closestDateIndex };
};

export default findClosestDateInPast;
