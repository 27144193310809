import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../../../../shared/constans/colors';
import { FontSizes } from '../../../../../../../../../../shared/constans/sizes';

const TextAsDeleteButton = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.red};
  margin-left: 24px;
  cursor: pointer;
`;

const EditButton = styled.div<{
  $editDefaultSvg: string;
  $editHoverSvg: string;
}>`
  width: 17px;
  height: 16px;
  cursor: pointer;

  background: url(${({ $editDefaultSvg }) => $editDefaultSvg});

  :hover {
    background: url(${({ $editHoverSvg }) => $editHoverSvg});
  }
`;

const DeleteButton = styled.div<{
  $deleteDefaultSvg: string;
  $deleteHoverSvg: string;
}>`
  width: 24px;
  height: 24px;
  cursor: pointer;

  background: url(${({ $deleteDefaultSvg }) => $deleteDefaultSvg});

  :hover {
    background: url(${({ $deleteHoverSvg }) => $deleteHoverSvg});
  }
`;

const CollapseButton = styled.div<{
  $isCollapsed: boolean;
  $arrowDownDefaultSvg: string;
  $arrowDownHoverSvg: string;
  $arrowTopDefaultSvg: string;
  $arrowTopHoverSvg: string;
}>`
  width: 24px;
  height: 24px;
  cursor: pointer;

  ${({
    $isCollapsed,
    $arrowTopDefaultSvg,
    $arrowTopHoverSvg,
    $arrowDownDefaultSvg,
    $arrowDownHoverSvg,
  }) =>
    $isCollapsed
      ? css`
          background: url(${$arrowTopDefaultSvg});

          :hover {
            background: url(${$arrowTopHoverSvg});
          }
        `
      : css`
          background: url(${$arrowDownDefaultSvg});

          :hover {
            background: url(${$arrowDownHoverSvg});
          }
        `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;

  gap: 24px;
`;

const Subtitle = styled.p<{ $subtitleIncludeSvg: string }>`
  ${FontSizes.medium}
  color: ${Colors.darkGray};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  margin-top: 6px;
  max-width: 85%;
  min-width: 40%;

  &::before {
    content: '';
    display: inline-block;
    background-image: url(${({ $subtitleIncludeSvg }) => $subtitleIncludeSvg});
    width: 16px;
    height: 16px;
    margin-right: 2px;
    transform: translateY(4px);
  }
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 0 70%;
  white-space: nowrap;
`;

const Header = styled.div`
  margin: 0 8px;
  flex: 1 0 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  /* Ограничивает длину названия вложенного чек-листа */
  max-width: 864px;
`;

const SaveButton = styled.button`
  width: 426px;
  height: 40px;

  background-color: white;

  border: none;
  color: ${Colors.green};
  box-shadow: 0 0 0 1px ${Colors.green};
  border-radius: 12px;
  padding: 12px 16px;
  cursor: pointer;
`;

const SaveButtonWrapper = styled.div`
  display: flex;
  flex: 1 0 70%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const StyledChecklistsChecklistAttrInstance = {
  TextAsDeleteButton,
  CollapseButton,
  DeleteButton,
  EditButton,
  ButtonsWrapper,
  Subtitle,
  Title,
  Header,
  SaveButton,
  SaveButtonWrapper,
  Wrapper,
};

export default StyledChecklistsChecklistAttrInstance;
