import { LeaveBeforeSaveModalIcon } from '../../assets/LeaveBeforeSaveModalIcon';

import Styled from './LeaveBeforeSaveContent.styled';

const LeaveBeforeSaveContent = () => {
  return (
    <>
      <Styled.Wrapper>
        <Styled.Icon>
          <LeaveBeforeSaveModalIcon />
        </Styled.Icon>
        <Styled.Title>Если покинуть экран, все несохранённые данные будут потеряны</Styled.Title>
      </Styled.Wrapper>
    </>
  );
};

export default LeaveBeforeSaveContent;
