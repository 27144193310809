import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';
import { FC } from 'react';
import moment from 'moment';

import {
  ECheckListInstanceType,
  IGetChecklistInstanceByTaskId,
} from '../../../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';

import Styled from './ViewingPoints.styles';

const getPointName = (point: IGetChecklistInstanceByTaskId, positionNumber: number) => {
  switch (point.type) {
    case ECheckListInstanceType.PlannedPoint:
      return `Точка ${positionNumber}`;
    case ECheckListInstanceType.ActualPoint:
      return `Точка ${positionNumber}`;
    case ECheckListInstanceType.Machinery:
      return `Техника ${positionNumber}`;
    case ECheckListInstanceType.Field:
      return `Поле ${positionNumber}`;
    default:
      return '';
  }
};

const getPointType = (point: IGetChecklistInstanceByTaskId) => {
  switch (point.type) {
    case ECheckListInstanceType.PlannedPoint:
      return 'План';
    case ECheckListInstanceType.ActualPoint:
      return 'Факт';
    case ECheckListInstanceType.Machinery:
      return point.machineryName || '';
    default:
      return '';
  }
};

const ViewingPoint: FC<{
  point: IGetChecklistInstanceByTaskId;
  index: number;
}> = ({ point, index }) => {
  const { isShowTooltip, ref } = useShowTooltip<HTMLSpanElement>();
  const subtitle = getPointType(point);

  return (
    <Styled.ViewingPoint key={point.id}>
      <Styled.ViewingPointDescription
        data-test-id={`instance-point-icon${point?.isComplete ? '-complete' : ''}`}
      >
        {point.isComplete ? <Styled.GreenDot /> : <Styled.InfoDot />}
        <Styled.ViewingPointName key={point.name} data-test-id="instance-point-name">
          {getPointName(point, index + 1)}
        </Styled.ViewingPointName>

        <AutoTooltip content={subtitle} disabled={!isShowTooltip} position="top">
          <Styled.ViewingPointType ref={ref} data-test-id="instance-point-type">
            {subtitle}
          </Styled.ViewingPointType>
        </AutoTooltip>
      </Styled.ViewingPointDescription>

      <Styled.ViewingPointDate data-test-id="instance-point-date">
        {point.isComplete ? moment(point.createOrUpdateDate).format('DD.MM.YY HH:MM') : ''}
      </Styled.ViewingPointDate>
    </Styled.ViewingPoint>
  );
};

export default ViewingPoint;
