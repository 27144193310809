import React, { FC, memo, useCallback } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown, SELECT_ALL_OPTION } from '@farmlink/farmik-ui';
import { TNewDropdownConfig } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';

import { ITableFiltersBuilderFilter as IFilter } from '../../../models/data';
import { TableFiltersBuilderStore as BuilderStore } from '../../../mobx/stores';
import { TableFiltersBuilderController as BuilderController } from '../../../mobx/controllers';
import { useTableFilterBuilderDropdownPagination as usePagination } from '../../../utils/hooks/pagination';
import { useTableFiltersBuilderDataTestId as useDataTestId } from '../../../hooks/useTableFiltersBuilderDataTestId';
import { useStore } from '../../../../../utils/IoC';

interface IProps {
  filter: IFilter;
}

const TableFiltersBuilderFilter: FC<IProps> = ({ filter }) => {
  const builderStore = useStore(BuilderStore);
  const builderController = useStore(BuilderController);

  const appliedValueOptionList = builderStore.getAppliedValue(filter.builderId, filter.id)
    ?.selectOptionList;

  const selectedValueOptionList = builderStore.getSelectedValue(filter.builderId, filter.id)
    ?.selectOptionList;

  const defaultValueList = selectedValueOptionList || appliedValueOptionList || [];

  const optionList = builderStore.getOptionList(filter.builderId, filter.id);

  const paginationActions = usePagination(filter, optionList);

  const handleChange = useCallback<TNewDropdownConfig['field']['onChange']>(
    (newValue, otherData) => {
      const isSelectAllValue = newValue === SELECT_ALL_OPTION.value;

      builderController.selectSelectOptionList(
        filter.builderId,
        filter.id,
        isSelectAllValue ? otherData.optionList : otherData?.selectedValueList || []
      );
    },
    []
  );

  const getDataTestId = useDataTestId();

  const config: TNewDropdownConfig = {
    field: {
      onChange: handleChange,
      defaultValueList,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isHideSearchFieldAutocomplete: true,
          },
        },
        multiselect: {
          options: {
            itemStyle: 'checkbox',
          },
          counter: {
            label: `${filter.name}:`,
          },
          isSelectedFirst: true,
        },
      },
      placeholder: filter.name,
      id: filter.id as string,
    },
    body: {
      optionList,
      isKeepSelectedOptions: true,
    },
    visual: {
      isPreventCloseAfterSelect: true,
      isRounded: true,
    },
    styles: {
      fieldPadding: '6px 12px',
      borderRadius: '8px',
    },
    // @ts-ignore
    other: {
      dataTestId: getDataTestId(`select-filter-with-key-${filter.id as string}`)['data-test-id'],
    },
  };

  return <NewDropdown config={config} paginationConfig={paginationActions.paginationConfig} />;
};

TableFiltersBuilderFilter.displayName = 'TableFiltersBuilderFilter';

export default memo(observer(TableFiltersBuilderFilter));
