import { observer } from 'mobx-react';

import { useStore } from '../utils/IoC';
import { UiStore } from '../../dashboard/stores/ui.store';

import { Wrapper } from './style';

export const TipWrapper = observer(({ children }) => {
  const store = useStore(UiStore);

  const handleContainer = el => {
    store.setContainerRef(el);
  };

  return (
    <Wrapper ref={handleContainer} style={{ top: store.tipLocation.y, left: store.tipLocation.x }}>
      {children}
    </Wrapper>
  );
});
