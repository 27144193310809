import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import {
  EChecklistAttributeType as EAttrType,
  IGetChecklistAttribute as IAttr,
  IGetChecklistAttributeValue as IAttributeValue,
} from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { ChecklistsStore } from '../../stores';
import {
  ChecklistsBooleanAttrsService,
  ChecklistsDateAttrsService,
  ChecklistsDictionaryAttrsService,
  ChecklistsDoubleAttrsService,
  ChecklistsEnumAttrsService,
  ChecklistsIntegerAttrsService,
  ChecklistsLongStringAttrsService,
  ChecklistsStringAttrsService,
  ChecklistsUserDictionaryAttrsService,
  ChecklistsFileAttrsService,
  ChecklistsChecklistAttrsService as ChecklistAttrsService,
} from '../attributes';
import { IChecklistsAttrToDraw as IAttrToDraw } from '../../../models';

@provide.transient()
class ChecklistsAttrsDisplayService {
  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(ChecklistsBooleanAttrsService)
  protected booleanAttrsService: ChecklistsBooleanAttrsService;

  @lazyInject(ChecklistsIntegerAttrsService)
  protected integerAttrsService: ChecklistsIntegerAttrsService;

  @lazyInject(ChecklistsDoubleAttrsService)
  protected doubleAttrsService: ChecklistsDoubleAttrsService;

  @lazyInject(ChecklistsStringAttrsService)
  protected stringAttrsService: ChecklistsStringAttrsService;

  @lazyInject(ChecklistsDictionaryAttrsService)
  protected dictionaryAttrsService: ChecklistsDictionaryAttrsService;

  @lazyInject(ChecklistsEnumAttrsService)
  protected enumAttrsService: ChecklistsEnumAttrsService;

  @lazyInject(ChecklistsUserDictionaryAttrsService)
  protected userDictionaryAttrsService: ChecklistsUserDictionaryAttrsService;

  @lazyInject(ChecklistsFileAttrsService)
  protected fileAttrsService: ChecklistsFileAttrsService;

  @lazyInject(ChecklistAttrsService)
  protected checklistAttrsService: ChecklistAttrsService;

  @lazyInject(ChecklistsLongStringAttrsService)
  protected longStringAttrsService: ChecklistsLongStringAttrsService;

  @lazyInject(ChecklistsDateAttrsService)
  protected dateAttrsService: ChecklistsDateAttrsService;

  addAttrList = (
    groupId: string,
    attrList: IAttr[],
    initialValueList: IAttributeValue[],
    options?: {
      isBlocked?: boolean;
      nestedInstanceId?: string;
    }
  ): void => {
    const attrToDrawList = attrList.reduce<IAttrToDraw[]>((list, attr, index) => {
      // Находим зависимый атрибут-файл (если такой имеется, разумеется).
      const dependentFileAttrId = this.getDependentFileAttrId(index, attrList);

      switch (attr.attribute.type) {
        case EAttrType.Boolean: {
          const attrToDraw = this.booleanAttrsService.createAttrToDraw(
            groupId,
            attr,
            initialValueList,
            { ...options, dependentFileAttrId }
          );
          list.push(attrToDraw);
          break;
        }

        case EAttrType.Int: {
          const attrToDraw = this.integerAttrsService.createAttrToDraw(
            groupId,
            attr,
            initialValueList,
            { ...options, dependentFileAttrId }
          );
          list.push(attrToDraw);
          break;
        }

        case EAttrType.Double: {
          const attrToDraw = this.doubleAttrsService.createAttrToDraw(
            groupId,
            attr,
            initialValueList,
            { ...options, dependentFileAttrId }
          );
          list.push(attrToDraw);
          break;
        }

        case EAttrType.String: {
          const attrToDraw = this.stringAttrsService.createAttrToDraw(
            groupId,
            attr,
            initialValueList,
            { ...options, dependentFileAttrId }
          );
          list.push(attrToDraw);
          break;
        }

        case EAttrType.DictionaryLink: {
          const attrToDraw = this.dictionaryAttrsService.createAttrToDraw(
            groupId,
            attr,
            initialValueList,
            { ...options, dependentFileAttrId }
          );
          list.push(attrToDraw);
          break;
        }

        case EAttrType.Enum: {
          const attrToDraw = this.enumAttrsService.createAttrToDraw(
            groupId,
            attr,
            initialValueList,
            { ...options, dependentFileAttrId }
          );
          list.push(attrToDraw);
          break;
        }

        case EAttrType.UserDictionaryLink: {
          const attrToDraw = this.userDictionaryAttrsService.createAttrToDraw(
            groupId,
            attr,
            initialValueList,
            { ...options, dependentFileAttrId }
          );
          list.push(attrToDraw);
          break;
        }

        case EAttrType.FileLink: {
          const attrToDraw = this.fileAttrsService.createAttrToDraw(
            groupId,
            attr,
            initialValueList,
            options
          );
          list.push(attrToDraw);
          break;
        }

        case EAttrType.ChecklistInstanceLink: {
          const attrToDraw = this.checklistAttrsService.createAttrToDraw(
            groupId,
            attr,
            initialValueList,
            options
          );
          list.push(attrToDraw);
          break;
        }

        case EAttrType.LongString: {
          const attrToDraw = this.longStringAttrsService.createAttrToDraw(
            groupId,
            attr,
            initialValueList,
            { ...options, dependentFileAttrId }
          );
          list.push(attrToDraw);
          break;
        }

        case EAttrType.Date: {
          const attrToDraw = this.dateAttrsService.createAttrToDraw(
            groupId,
            attr,
            initialValueList,
            { ...options, dependentFileAttrId }
          );
          list.push(attrToDraw);
          break;
        }

        default:
      }

      return list;
    }, []);

    this.checklistsStore.setAttrToDrawList(groupId, attrToDrawList);
  };

  protected getDependentFileAttrId = (index: number, attrList: IAttr[]): string => {
    const nextAttr = attrList?.[index + 1];

    if (!nextAttr) return;

    const hasFileType = nextAttr.attribute.type === EAttrType.FileLink;

    if (!hasFileType) return;
    if (nextAttr.position?.separate) return;

    return nextAttr.id;
  };
}

export default ChecklistsAttrsDisplayService;
