import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
  margin: 20px 0;
  height: 56px;
`;

const ArrowButton = styled.button``;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Styled = {
  Wrapper,
  ArrowButton,
  CardRow,
};

export default Styled;
