import { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { EModalType, useModal } from '@farmlink/farmik-ui';

import { EChecklistMode } from '../../../../../operationsAndTasks/stores/checklist.instances.store';
import { useTaskPageGlobalControl } from '../../../../hooks';
import { Checklists } from '../Checklists';
import { TaskStore } from '../../../../mobx/stores';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { EModalSize } from '../../../../../../../modals/components/Modal/styles';
import { ChecklistsStore } from '../../../../modules/Checklists/mobx/stores';
import { ContentLoader } from '../../../../../../../shared/features/UI/loaders/ContentLoader';
import { Container } from '../../../../../../../shared/features/UI/Container';

interface IProps {
  mode: TaskStore['mode'];
}

const ChecklistsContainer: FC<IProps> = ({ mode }) => {
  const taskStore = useStore(TaskStore);
  const checklistsStore = useStore(ChecklistsStore);

  const modalActions = useModal();

  const control = useTaskPageGlobalControl(mode, {
    isIgnoreOnUnmountClear: true,
  });

  useEffect(() => {
    modalActions.registerModalList(
      [
        {
          name: 'resendChecklistData',
          title: 'Во время сохранения произошла ошибка. Попробуйте повторить попытку',
          type: EModalType.Warning,
          id: 'resendChecklistData',
          styledProps: {
            $size: EModalSize.Medium,
          },
          successButton: {
            title: 'Повторить',
            color: 'primary',
            preventClose: true,
            handler: () => modalActions.getModalPayload().resendHandler(),
          },
          denyButton: {
            title: 'Отменить',
            color: 'default',
          },
        },
      ],
      'checklistModals'
    );
  }, []);

  useEffect(() => {
    checklistsStore.setMode(mode === 'view' ? EChecklistMode.View : EChecklistMode.Edit);
  }, [mode]);

  useEffect(() => {
    return () => {
      control.clearStores({ isIgnoreTaskStore: true });
    };
  }, []);

  const isLoading = useMemo<boolean>(() => {
    return !taskStore.selectedTask;
  }, [taskStore.selectedTask]);

  const Loader = (
    <Container>
      <ContentLoader
        overlayStyles={{
          position: 'relative',
          height: '352px',
          width: '100%',
        }}
      />
    </Container>
  );

  return <>{isLoading ? Loader : <Checklists />}</>;
};

export default observer(ChecklistsContainer);
