import { LatLng } from 'leaflet';

export const clickMapEventName = 'map.clickMapEventName';

export const clickMapEvent = coords => {
  const event = new CustomEvent(clickMapEventName, {
    detail: {
      position: coords,
    },
  });
  window.dispatchEvent(event);
};
