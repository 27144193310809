import moment from 'moment/moment';

const checkIfDateIsInTheRange = (
  targetDate: string,
  startDate: string,
  endDate: string
): boolean => {
  const momentBindingDate = moment(targetDate);
  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);

  const isSameStartDate = momentBindingDate.isSame(momentStartDate);
  const isSameEndDate = momentBindingDate.isSame(momentEndDate);
  const isBetween = momentBindingDate.isBetween(momentStartDate, momentEndDate);

  const isInTheRange = isBetween || isSameStartDate || isSameEndDate;

  return isInTheRange;
};

export default checkIfDateIsInTheRange;
