import styled from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';

import infoIconSvg from './assets/images/info-icon-white-16x16px.svg';

const Icon = styled.div`
  width: 16px;
  height: 16px;
  background: url(${infoIconSvg});
`;

const Title = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  padding: 8px 12px;
  gap: 10px;

  height: 36px;

  background-color: ${Colors.red};
  border-radius: 12px;
`;

const StyledFieldErrorNotification = {
  Icon,
  Title,
  Wrapper,
};

export default StyledFieldErrorNotification;
