function validateCoordinates(coordinates: string): void {
  const regex = /^-?([1-8]?\d(\.\d{1,14})?|90(\.0{1,14})?)(\s|,\s?)(-?\d{1,3}(\.\d{1,14})?|180(\.0{1,14})?)$/;
  const match = coordinates.match(regex);
  if (!match) {
    throw new Error('Некорректный формат ввода координат');
  }
  const latitude = Number(match[1]);
  const longitude = Number(match[5]);
  if (isNaN(latitude) || isNaN(longitude)) {
    throw new Error(
      'Неверный формат координат: широта и долгота должны быть действительными числами'
    );
  }
  if (latitude < -90 || latitude > 90) {
    throw new Error('Неверное значение широты: должно быть между -90 и 90 градусами');
  }
  if (longitude < -180 || longitude > 180) {
    throw new Error('Неверное значение долготы: должно быть между -180 и 180 градусами');
  }
}

export default validateCoordinates;
